// FlowContext.jsx
import React, { createContext, useContext, useState, useCallback } from "react";

const FlowContext = createContext(null);

export function FlowProvider({ children }) {
  // Single place to store nodes+edges together
  const [flowData, setFlowData] = useState({
    nodes: [],
    edges: [],
  });

  // Additional “global” data
  const [orders, setOrders] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [routeSegments, setRouteSegments] = useState([]);
  const [locations, setLocations] = useState([]);

  // Selections
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);

  // Update nodes+edges in one call
  const updateFlowData = useCallback((newNodes, newEdges) => {
    setFlowData({ nodes: newNodes, edges: newEdges });
  }, []);

  return (
    <FlowContext.Provider
      value={{
        flowData,
        updateFlowData,
        orders,
        setOrders,
        couriers,
        setCouriers,
        routeSegments,
        setRouteSegments,
        locations,
        setLocations,

        // We store + provide these in context now
        selectedNode,
        setSelectedNode,
        selectedEdge,
        setSelectedEdge,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
}

export function useFlowContext() {
  const context = useContext(FlowContext);
  if (!context) {
    throw new Error("useFlowContext must be used inside FlowProvider");
  }
  return context;
}

export default FlowContext;
