// At the top of the file, after the imports
import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
  useRef,
} from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  IconButton,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Switch,
  FormControlLabel,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Box,
  Tooltip,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  Error as ErrorIcon,
  ReportProblem as ReportProblemIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import _ from "lodash";

// Replace with wherever your AuthContext (or other API context) resides
import { AuthContext } from "../../../../../components/Auth/AuthContext";

// If you have a custom context for orders, you could import/use that instead
import { useOrders } from "../OrdersContext";

import ItemPrintButton from "../../../../business/settings/printer/ItemPrintButton";
import ItemPreviewButton from "../../../../business/settings/printer/ItemPreviewButton";
import PackagePreviewButton from "../../../settings/printer/PackagePreviewButton";
import { useParams } from "react-router-dom";

const FIELD_PRECISION = {
  dimensions: 1,
  weight: 3,
  declared_value: 2,
  quantity: 0,
};

// Add this after FIELD_PRECISION
const formatNumericField = (
  value,
  precision = 2,
  allowDecimals = true,
  options = {}
) => {
  const { min = 0, max = Number.MAX_VALUE, allowNegative = false } = options;

  // Handle empty/null/undefined values by returning the minimum value as a string
  if (value === "" || value === null || value === undefined) {
    return min.toFixed(precision);
  }

  // Handle special cases during typing (e.g., '.', '-', '-.')
  const isTyping = [".", "-", "-."].includes(value);
  if (isTyping) return allowNegative ? value : "0";

  // Clean the input by removing any character that is not a digit, dot, or minus sign
  const cleanValue = value.toString().replace(/[^\d.-]/g, "");

  // Parse the cleaned value to a float
  const num = parseFloat(cleanValue);

  // If parsing fails, return the minimum value as a string
  if (isNaN(num)) return min.toFixed(precision);

  // If decimals are not allowed, round the number to the nearest integer
  if (!allowDecimals) {
    const rounded = Math.round(num);
    const bounded = Math.max(min, Math.min(max, rounded));
    return bounded.toString();
  }

  // Clamp the number within the specified min and max range
  const boundedNum = Math.max(min, Math.min(max, num));

  // Use toFixed to ensure the number has exactly 'precision' decimal places
  // This pads with zeros if necessary
  return boundedNum.toFixed(precision);
};

// Add this at the top level of your component
const LoadingIndicator = () => (
  <Box
    position="fixed"
    right={16}
    bottom={16}
    zIndex={2000}
    bgcolor="white"
    borderRadius={1}
    boxShadow={2}
    p={1}
  >
    <Box display="flex" alignItems="center" gap={1}>
      <CircularProgress size={20} />
      <Typography variant="body2">Saving changes...</Typography>
    </Box>
  </Box>
);

const useSaveQueue = () => {
  const [isSaving, setIsSaving] = useState(false);
  const saveQueue = useRef(Promise.resolve());
  const saveTimeout = useRef(null);

  const queueSave = useCallback(async (saveFunction) => {
    // Clear any pending saves
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current);
    }

    setIsSaving(true);

    return new Promise((resolve, reject) => {
      saveTimeout.current = setTimeout(async () => {
        try {
          // Wait for previous save to complete
          await saveQueue.current;
          // Execute new save
          saveQueue.current = saveFunction();
          const result = await saveQueue.current;
          setIsSaving(false);
          resolve(result);
        } catch (error) {
          setIsSaving(false);
          reject(error);
        }
      }, 100); // Small delay to batch rapid changes
    });
  }, []);

  return {
    isSaving,
    queueSave,
  };
};

// Update the PackageDetails component's state declarations
const PackageDetails = ({ isReadOnly = false }) => {
  const { businessId, orderId } = useParams();
  const { api } = useContext(AuthContext);
  const { orders, updateOrder } = useOrders();

  // Core state
  const [packages, setPackages] = useState([]);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [upcCodes, setUpcCodes] = useState({});
  const upcInputRefs = useRef({});
  const lastSavedPackagesRef = useRef([]);

  // Save queue management
  const { isSaving, queueSave } = useSaveQueue();

  // Core save function
  // Core save function
  const savePackages = useCallback(
    async (packagesToSave) => {
      return queueSave(async () => {
        try {
          console.log("Saving packages:", packagesToSave);
          console.log("Last saved packages:", lastSavedPackagesRef.current);
          console.log("Order ID:", orderId);
          console.log("Business ID:", businessId);

          const response = await updateOrder(
            orderId,
            {
              Packages: packagesToSave,
            },
            businessId
          );

          if (response && response.Packages) {
            // Store successful save state
            lastSavedPackagesRef.current = _.cloneDeep(packagesToSave);
            return response;
          } else {
            throw new Error("Invalid server response - missing package data");
          }
        } catch (error) {
          // Rollback to last known good state
          setPackages(lastSavedPackagesRef.current);

          // Propagate error with additional context
          throw new Error(`Failed to save packages: ${error.message}`);
        }
      });
    },
    [orderId, businessId, queueSave]
  );

  const handleDecimalInput = (value, precision = 2) => {
    // Allow empty string input
    if (value === "" || value === null) return "";

    // Remove any non-numeric characters except decimal point and minus
    const cleanValue = value.toString().replace(/[^\d.-]/g, "");

    // Handle special case where user is typing a decimal point
    if (cleanValue === "." || cleanValue === "-" || cleanValue === "-.") {
      return cleanValue;
    }

    // Parse as float
    const parsed = parseFloat(cleanValue);
    if (isNaN(parsed)) return "";

    // If user is mid-typing a decimal with less than 'precision' digits
    const [integerPart, decimalPart] = cleanValue.split(".");
    if (decimalPart && decimalPart.length <= precision) {
      return cleanValue;
    }

    return parsed.toFixed(precision);
  };

  // Error handling utility
  const handleSaveError = useCallback((error, context) => {
    console.error(`Save error (${context}):`, error);
    setAlertMessage({
      type: "error",
      message: `Failed to save ${context}: ${error.message}`,
    });
  }, []);

  // Success feedback utility
  const handleSaveSuccess = useCallback((message) => {
    setAlertMessage({
      type: "success",
      message,
    });
  }, []);

  // Initial data load
  useEffect(() => {
    const fetchData = async () => {
      if (order) {
        const fetchedPackages = order.Packages || [];
        setPackages(fetchedPackages);
        lastSavedPackagesRef.current = _.cloneDeep(fetchedPackages);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [order]);

  // Set order from orders context
  useEffect(() => {
    const order = orders.find((order) => order.shipping_order_id === orderId);
    setOrder(order);
  }, [orders, orderId]);

  // Navigation warning
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isSaving) {
        event.preventDefault();
        event.returnValue =
          "Changes are being saved. Are you sure you want to leave?";
        return event.returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isSaving]);

  const handleSwitchChange = useCallback(
    async (pkgIndex, field, checked) => {
      try {
        // Create the updated packages array first
        const updatedPackages = packages.map((pkg, index) => {
          if (index === pkgIndex) {
            return {
              ...pkg,
              [field]: checked,
            };
          }
          return pkg;
        });

        // Update local state
        setPackages(updatedPackages);

        // Save the updated packages
        await savePackages(updatedPackages);
        handleSaveSuccess(`Package ${field} updated`);
      } catch (error) {
        // Revert on error
        setPackages((prevPackages) => {
          return prevPackages.map((pkg, index) => {
            if (index === pkgIndex) {
              return {
                ...pkg,
                [field]: !checked,
              };
            }
            return pkg;
          });
        });
        handleSaveError(error, `package ${field} update`);
      }
    },
    [packages, savePackages, handleSaveSuccess, handleSaveError]
  );

  const handlePackageChange = useCallback(
    (pkgIndex, field, value) => {
      try {
        // Validate inputs
        if (pkgIndex < 0 || pkgIndex >= packages.length) {
          console.error(`Invalid package index: ${pkgIndex}`);
          return;
        }

        if (!field) {
          console.error("Field name is required");
          return;
        }

        console.log("handlePackageChange:", { pkgIndex, field, value });

        setPackages((prevPackages) => {
          const updatedPackages = [...prevPackages];
          const pkg = { ...updatedPackages[pkgIndex] };

          // Handle nested fields (like dimensions)
          if (field.includes(".")) {
            const [parent, child] = field.split(".");

            // Ensure parent object exists
            if (!pkg[parent]) {
              pkg[parent] = {};
            }

            // Validate nested field
            pkg[parent] = {
              ...pkg[parent],
              [child]: value,
            };
          } else {
            // Validate regular field
            pkg[field] = value;
          }

          updatedPackages[pkgIndex] = pkg;
          console.log("Updated package:", pkg);

          return updatedPackages;
        });
      } catch (error) {
        console.error("Error in handlePackageChange:", error);
      }
    },
    [packages]
  );

  const handlePackageBlur = useCallback(
    async (pkgIndex, field, value, currentValue) => {
      // Format the new value
      const formattedValue = formatNumericField(value, FIELD_PRECISION[field]);

      // Retrieve the saved value using lodash's get for nested fields
      const savedValue = _.get(lastSavedPackagesRef.current[pkgIndex], field);

      // Compare the formatted new value with the saved value
      const isValueChanged =
        parseFloat(formattedValue) !== parseFloat(savedValue);

      if (!isValueChanged) {
        return; // No change detected, do not save
      }

      try {
        await savePackages(packages);
        handleSaveSuccess("Package updated");
      } catch (error) {
        // On error, revert only this specific field
        setPackages((prevPackages) => {
          const newPackages = [...prevPackages];
          if (field.includes(".")) {
            const [parent, child] = field.split(".");
            newPackages[pkgIndex][parent][child] = currentValue;
          } else {
            newPackages[pkgIndex][field] = currentValue;
          }
          return newPackages;
        });
        handleSaveError(error, "package update");
      }
    },
    [packages, savePackages, handleSaveSuccess, handleSaveError]
  );

  const addPackage = useCallback(async () => {
    const newPackage = {
      package_id: null,
      PackageItems: [],
      dimensions: { length: 0, width: 0, height: 0 },
      weight: 0,
      declared_value: 0,
      fragile: false,
      hazardous: false,
      temperature_control: false,
      status: "pending",
    };

    try {
      // Save immediately to get ID
      // set the state in the frontend
      setPackages([...packages, newPackage]);
      await savePackages([...packages, newPackage]);
      handleSaveSuccess("New package created");
    } catch (error) {
      handleSaveError(error, "package creation");
    }
  }, [packages, savePackages, handleSaveSuccess, handleSaveError]);

  const removePackage = useCallback(
    async (pkgIndex) => {
      const updatedPackages = packages.filter((_, index) => index !== pkgIndex);

      try {
        await savePackages(updatedPackages);
        setPackages(updatedPackages);
        handleSaveSuccess("Package removed");
      } catch (error) {
        handleSaveError(error, "package deletion");
      }
    },
    [packages, savePackages, handleSaveSuccess, handleSaveError]
  );

  // Item level handlers
  const handleItemChange = useCallback((pkgIndex, itemIndex, field, value) => {
    setPackages((prevPackages) => {
      const updatedPackages = [...prevPackages];

      updatedPackages[pkgIndex] = {
        ...updatedPackages[pkgIndex],
        PackageItems: updatedPackages[pkgIndex].PackageItems.map((item, idx) =>
          idx === itemIndex ? { ...item, [field]: value } : item
        ),
      };

      return updatedPackages;
    });
  });

  const handleItemBlur = useCallback(
    async (pkgIndex, itemIndex, field, value, currentValue) => {
      // Format the new value if necessary
      const formattedValue = field === "quantity" ? parseInt(value) : value;

      // Retrieve the saved value using lodash's get for nested fields
      const savedValue = _.get(
        lastSavedPackagesRef.current[pkgIndex],
        `PackageItems[${itemIndex}].${field}`
      );

      // Compare the new value with the saved value
      const isValueChanged = formattedValue !== savedValue;

      if (!isValueChanged) {
        return; // No change detected, do not save
      }

      try {
        await savePackages(packages);
        handleSaveSuccess("Item updated");
      } catch (error) {
        // On error, revert only this specific item field
        setPackages((prevPackages) => {
          const newPackages = [...prevPackages];
          newPackages[pkgIndex].PackageItems[itemIndex][field] = currentValue;
          return newPackages;
        });
        handleSaveError(error, "item update");
      }
    },
    [packages, savePackages, handleSaveSuccess, handleSaveError]
  );

  const addManualItem = useCallback(
    async (pkgIndex) => {
      const newItem = {
        package_item_id: null,
        item_id: null,
        upc: "",
        name: "",
        description: "",
        quantity: 1,
        weight: 0,
        value: 0,
        status: "pending",
      };

      try {
        const updatedPackages = [...packages];
        if (!updatedPackages[pkgIndex].PackageItems) {
          updatedPackages[pkgIndex].PackageItems = [];
        }

        // Add item optimistically
        const newItemIndex = updatedPackages[pkgIndex].PackageItems.length;
        updatedPackages[pkgIndex].PackageItems.push(newItem);
        setPackages(updatedPackages);

        // Save and update with server response
        const savedPackages = await savePackages(updatedPackages);
        setExpandedItems((prev) => [...prev, `${pkgIndex}-${newItemIndex}`]);
        handleSaveSuccess("New item added");
      } catch (error) {
        // Revert on error
        setPackages(packages);
        handleSaveError(error, "item creation");
      }
    },
    [packages, savePackages, handleSaveSuccess, handleSaveError]
  );

  const removeItem = useCallback(
    async (pkgIndex, itemIndex) => {
      const updatedPackages = [...packages];
      updatedPackages[pkgIndex].PackageItems.splice(itemIndex, 1);

      try {
        await savePackages(updatedPackages);
        handleSaveSuccess("Item removed");
      } catch (error) {
        handleSaveError(error, "item deletion");
      }
    },
    [packages, savePackages, handleSaveSuccess, handleSaveError]
  );

  // UPC handling

  const [upcLoading, setUpcLoading] = useState({});

  const addUPCItem = useCallback(
    async (pkgIndex, upcCode) => {
      if (!packages || !packages[pkgIndex]) {
        console.error("Invalid package index:", pkgIndex);
        return;
      }
      setUpcLoading((prev) => ({ ...prev, [pkgIndex]: true }));
      try {
        console.log("Adding UPC item:", upcCode);
        console.log("Package index:", pkgIndex);
        console.log("Current packages:", packages);

        // if item with that upc code already exists sinmply increment the quantity
        const existingItemIndex = packages[pkgIndex].PackageItems.findIndex(
          (item) => item.Item.code === upcCode
        );
        if (existingItemIndex >= 0) {
          const updatedPackages = [...packages];
          updatedPackages[pkgIndex].PackageItems[existingItemIndex].quantity++;
          setPackages(updatedPackages);
          await savePackages(updatedPackages);
          setExpandedItems((prev) => [
            ...prev,
            `${pkgIndex}-${existingItemIndex}`,
          ]);
          handleSaveSuccess("UPC item quantity incremented");
          setUpcCodes((prev) => ({ ...prev, [pkgIndex]: "" }));
          return;
        }

        const upcResponse = await api.get(`/upc/${upcCode}`);
        const productData = upcResponse.data.product;

        if (!productData || !productData.name) {
          throw new Error("Invalid product data received");
        }

        const newItem = {
          package_item_id: null,
          item_id: productData.item_id,
          upc: upcCode,
          name: productData.name,
          description: productData.description || "",
          weight: parseFloat(productData.specs?.weight) || 0,
          value: parseFloat(productData.value) || 0,
          quantity: 1,
          Item: productData,
          status: "pending",
        };

        const updatedPackages = [...packages];
        if (!updatedPackages[pkgIndex].PackageItems) {
          updatedPackages[pkgIndex].PackageItems = [];
        }
        updatedPackages[pkgIndex].PackageItems.push(newItem);

        // Save immediately to get ID
        setPackages(updatedPackages);
        await savePackages(updatedPackages);
        const newItemIndex = updatedPackages[pkgIndex].PackageItems.length - 1;
        setExpandedItems((prev) => [...prev, `${pkgIndex}-${newItemIndex}`]);

        handleSaveSuccess("UPC item added");
        setUpcCodes((prev) => ({ ...prev, [pkgIndex]: "" }));
      } catch (error) {
        handleSaveError(error, "UPC item creation");
      } finally {
        setUpcLoading((prev) => ({ ...prev, [pkgIndex]: false }));
      }
    },
    [packages, api, savePackages, handleSaveSuccess, handleSaveError]
  );

  // Accordion handling
  const handleAccordionChange = useCallback((panel) => {
    setExpandedItems((prev) => {
      if (prev.includes(panel)) {
        return prev.filter((item) => item !== panel);
      }
      return [...prev, panel];
    });
  }, []);
  // Render functions
  const renderItemDetails = (item, pkgIndex, itemIndex) => {
    if (!item) return null;
    return (
      <AccordionDetails>
        <Grid container spacing={2}>
          {/** If item has an image */}
          {item.Item && item.Item.image_url && (
            <Grid item xs={12} sm={3}>
              <CardMedia
                component="img"
                height="140"
                image={`${process.env.REACT_APP_API_BASE_URL}${item.Item.image_url}`}
                alt={item.name}
                sx={{ objectFit: "contain", mb: 2, borderRadius: 1 }}
                crossOrigin="anonymous"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Name"
              multiline
              rows={6}
              disabled={isReadOnly || !!(item.item_id || item.upc)}
              value={item.name || ""}
              onChange={(e) =>
                handleItemChange(pkgIndex, itemIndex, "name", e.target.value)
              }
              onBlur={(e) =>
                handleItemBlur(
                  pkgIndex,
                  itemIndex,
                  "name",
                  e.target.value,
                  item.name || ""
                )
              }
              required
              InputProps={{
                readOnly: isReadOnly || !!(item.item_id || item.upc),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={6}
              disabled={isReadOnly || !!(item.item_id || item.upc)}
              value={item.description || ""}
              onChange={(e) =>
                handleItemChange(
                  pkgIndex,
                  itemIndex,
                  "description",
                  e.target.value
                )
              }
              onBlur={(e) =>
                handleItemBlur(
                  pkgIndex,
                  itemIndex,
                  "description",
                  e.target.value,
                  item.description || ""
                )
              }
              required
              InputProps={{
                readOnly: isReadOnly || !!(item.item_id || item.upc),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <TextField
              fullWidth
              label="Quantity"
              type="number"
              value={item.quantity}
              onChange={(e) =>
                handleItemChange(
                  pkgIndex,
                  itemIndex,
                  "quantity",
                  e.target.value
                )
              }
              onBlur={(e) => {
                const numValue = parseInt(e.target.value);
                const validValue =
                  isNaN(numValue) || numValue < 1 ? 1 : numValue;
                handleItemChange(pkgIndex, itemIndex, "quantity", validValue);
                if (validValue !== item.quantity) {
                  handleItemBlur(
                    pkgIndex,
                    itemIndex,
                    "quantity",
                    validValue,
                    item.quantity
                  );
                }
              }}
              required
              disabled={isReadOnly}
              inputProps={{
                min: 1,
                step: 1,
                onKeyDown: (e) => {
                  if (["-", "+", "e", "."].includes(e.key)) e.preventDefault();
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box display="flex" gap={1}>
              <Tooltip title="Print Item Label">
                <span>  {/* Span wrapper to prevent Tooltip issues with disabled buttons */}
                  <ItemPrintButton
                    item={item}
                    disabled={isReadOnly}
                    onCodesUpdated={(newCodes) => {
                      // Merge newCodes into parent's state
                      console.log("New codes:", newCodes);
                      setPackages((prev) => {
                        const updated = [...prev];
                        updated[pkgIndex].PackageItems[itemIndex] = {
                          ...item,
                          printed_codes: newCodes,
                        };
                        console.log(
                          "Updated item:",
                          updated[pkgIndex].PackageItems[itemIndex]
                        );
                        return updated;
                      });
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Preview Item Label">
                <span>  {/* Span wrapper for disabled button tooltip */}
                  <ItemPreviewButton
                    item={item}
                    disabled={isReadOnly}
                    onCodesUpdated={(newCodes) => {
                      setPackages((prev) => {
                        const updated = [...prev];
                        updated[pkgIndex].PackageItems[itemIndex] = {
                          ...item,
                          printed_codes: newCodes,
                        };
                        return updated;
                      });
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Remove Item">
                <span>  {/* Span wrapper for disabled button tooltip */}
                  <IconButton 
                    onClick={() => removeItem(pkgIndex, itemIndex)}
                    disabled={isReadOnly}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    );
  };

  const renderPackageItems = (pkg, pkgIndex) => {
    // Ensure PackageItems is an array before mapping
    if (!pkg || !pkg.PackageItems || !Array.isArray(pkg.PackageItems)) {
      return <Grid item xs={12} key={`items-${pkgIndex}`}></Grid>;
    }

    return (
      <Grid item xs={12} key={`items-${pkgIndex}`}>
        {pkg.PackageItems.map((item, itemIndex) => {
          if (!item) return null; // Skip null/undefined items

          const panelKey = `${pkgIndex}-${itemIndex}`;
          return (
            <Accordion
              key={panelKey}
              expanded={expandedItems.includes(panelKey)}
              onChange={() => handleAccordionChange(panelKey)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${panelKey}-content`}
                id={`panel${panelKey}-header`}
              >
                <Typography>
                  {item.name || `Item ${itemIndex + 1}`}
                  {(item.upc || item.item_id) && (
                    <Chip
                      label="UPC Item"
                      size="small"
                      color="primary"
                      sx={{ ml: 1 }}
                    />
                  )}
                </Typography>
              </AccordionSummary>
              {renderItemDetails(item, pkgIndex, itemIndex)}
            </Accordion>
          );
        })}
      </Grid>
    );
  };

  const renderPackage = (pkg, pkgIndex) => (
    <Grid item xs={12} key={`package-${pkgIndex}`}>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h6">Package {pkgIndex + 1}</Typography>
        <Box display="flex" gap={1}>
          <Tooltip title="Preview Package Label">
            <span>  {/* Span wrapper for disabled button tooltip */}
              <PackagePreviewButton order={order} pkg={pkg} disabled={isReadOnly} />
            </span>
          </Tooltip>
        </Box>
        {packages.length > 1 && (
          <Tooltip title="Remove Package">
            <span>  {/* Span wrapper for disabled button tooltip */}
              <IconButton 
                onClick={() => removePackage(pkgIndex)} 
                color="error"
                disabled={isReadOnly}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Items</Typography>
        </Grid>

        {renderPackageItems(pkg, pkgIndex)}

        <Grid item xs={12} sm={9}>
          <Grid item xs={12} sm={9}>
            <TextField
              fullWidth
              label="UPC Code"
              value={upcCodes[pkgIndex] || ""}
              helperText="Enter a UPC code to fetch product details"
              onChange={(e) => {
                // Only allow digits:
                const newValue = e.target.value.replace(/[^\d]/g, "");
                setUpcCodes((prev) => ({ ...prev, [pkgIndex]: newValue }));

                // Auto-submit when a valid UPC (e.g. 12 digits) is detected:
                if (newValue.length === 12) {
                  addUPCItem(pkgIndex, newValue);
                  // Clear the input immediately
                  setUpcCodes((prev) => ({ ...prev, [pkgIndex]: "" }));
                  // Use a short timeout so the cleared input is rendered before focusing it
                  setTimeout(() => {
                    if (upcInputRefs.current[pkgIndex]) {
                      upcInputRefs.current[pkgIndex].focus();
                      upcInputRefs.current[pkgIndex].select();
                    }
                  }, 100);
                }
              }}
              inputRef={(ref) => {
                upcInputRefs.current[pkgIndex] = ref;
              }}
              disabled={isReadOnly || upcLoading[pkgIndex]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={() =>
                        addUPCItem(pkgIndex, upcCodes[pkgIndex] || "")
                      }
                      variant="contained"
                      color="primary"
                      disabled={!upcCodes[pkgIndex] || upcLoading[pkgIndex] || isReadOnly}
                    >
                      {upcLoading[pkgIndex] ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Add UPC Item"
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Tooltip title={isReadOnly ? "Cannot add items in read-only mode" : "Add a manual item"}>
            <span>  {/* Span wrapper for disabled button tooltip */}
              <Button
                onClick={() => addManualItem(pkgIndex)}
                variant="outlined"
                startIcon={<AddIcon />}
                fullWidth
                sx={{ height: "70%" }}
                disabled={isReadOnly}
              >
                Add Manual Item
              </Button>
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Package Details</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
          fullWidth
          label="Length (in)"
          type="number"
          value={pkg.dimensions?.length ?? 0.0}
          onChange={(e) =>
          handlePackageChange(pkgIndex, "dimensions.length", e.target.value)
          }
          onBlur={(e) => {
          const formattedValue = formatNumericField(
          e.target.value,
          FIELD_PRECISION.dimensions
          );
          const currentValue = pkg.dimensions?.length ?? 0.0;
          handlePackageChange(
          pkgIndex,
          "dimensions.length",
          formattedValue
          );
          handlePackageBlur(
          pkgIndex,
          "dimensions.length",
          formattedValue,
          currentValue
          );
          }}
          inputProps={{
          min: 0,
          step: "any",
          onKeyDown: (e) => {
          if (["-", "+", "e"].includes(e.key)) e.preventDefault();
          },
          }}
            disabled={isReadOnly}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Width (in)"
            type="number"
            value={pkg.dimensions?.width ?? 0.0}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "dimensions.width", e.target.value)
            }
            onBlur={(e) => {
              const formattedValue = formatNumericField(
                e.target.value,
                FIELD_PRECISION.dimensions
              );
              const currentValue = pkg.dimensions?.width ?? 0.0;
              handlePackageChange(pkgIndex, "dimensions.width", formattedValue);
              handlePackageBlur(
                pkgIndex,
                "dimensions.width",
                formattedValue,
                currentValue
              );
            }}
            inputProps={{
              min: 0,
              step: "any",
              onKeyDown: (e) => {
                if (["-", "+", "e"].includes(e.key)) e.preventDefault();
              },
            }}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Height (in)"
            type="number"
            value={pkg.dimensions?.height ?? 0.0}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "dimensions.height", e.target.value)
            }
            onBlur={(e) => {
              const formattedValue = formatNumericField(
                e.target.value,
                FIELD_PRECISION.dimensions
              );
              const currentValue = pkg.dimensions?.height ?? 0.0;
              handlePackageChange(
                pkgIndex,
                "dimensions.height",
                formattedValue
              );
              handlePackageBlur(
                pkgIndex,
                "dimensions.height",
                formattedValue,
                currentValue
              );
            }}
            inputProps={{
              min: 0,
              step: "any",
              onKeyDown: (e) => {
                if (["-", "+", "e"].includes(e.key)) e.preventDefault();
              },
            }}
            disabled={isReadOnly}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Total Weight (lbs)"
            type="number"
            value={pkg.weight ?? 0.0}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "weight", e.target.value)
            }
            onBlur={(e) => {
              const formattedValue = formatNumericField(
                e.target.value,
                FIELD_PRECISION.weight
              );
              const currentValue = pkg.weight ?? 0.0;
              handlePackageChange(pkgIndex, "weight", formattedValue);
              handlePackageBlur(
                pkgIndex,
                "weight",
                formattedValue,
                currentValue
              );
            }}
            inputProps={{
              min: 0,
              step: 0.001,
              onKeyDown: (e) => {
                if (["-", "+", "e"].includes(e.key)) e.preventDefault();
              },
            }}
            disabled={isReadOnly}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Declared Value"
            type="number"
            value={pkg.declared_value ?? 0.0}
            onChange={(e) =>
              handlePackageChange(pkgIndex, "declared_value", e.target.value)
            }
            onBlur={(e) => {
              const formattedValue = formatNumericField(
                e.target.value,
                FIELD_PRECISION.declared_value
              );
              const currentValue = pkg.declared_value ?? 0.0;
              handlePackageChange(pkgIndex, "declared_value", formattedValue);
              handlePackageBlur(
                pkgIndex,
                "declared_value",
                formattedValue,
                currentValue
              );
            }}
            inputProps={{
              min: 0,
              step: 0.01,
              onKeyDown: (e) => {
                if (["-", "+", "e"].includes(e.key)) e.preventDefault();
              },
            }}
            disabled={isReadOnly}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">Additional Information</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
          control={
          <Switch
          checked={pkg.fragile || false}
          onChange={(e) =>
          handleSwitchChange(pkgIndex, "fragile", e.target.checked)
          }
            disabled={isReadOnly}
            />
          }
            label="Fragile"
            />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Switch
                checked={pkg.hazardous || false}
                onChange={(e) =>
                  handleSwitchChange(pkgIndex, "hazardous", e.target.checked)
                }
                disabled={isReadOnly}
              />
            }
            label="Hazardous"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Switch
                checked={pkg.temperature_control || false}
                onChange={(e) =>
                  handleSwitchChange(
                    pkgIndex,
                    "temperature_control",
                    e.target.checked
                  )
                }
                disabled={isReadOnly}
              />
            }
            label="Temperature Controlled"
          />
        </Grid>
      </Grid>
    </Grid>
  );

  // Final return
  return (
    <>
      {isSaving && <LoadingIndicator />}
      <Grid container spacing={3}>
        {packages?.map((pkg, pkgIndex) => renderPackage(pkg, pkgIndex))}

        <Grid item xs={12}>
          <Tooltip title={isReadOnly ? "Cannot add packages in read-only mode" : "Add another package"}>
            <span>  {/* Span wrapper for disabled button tooltip */}
              <Button
                onClick={addPackage}
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={isReadOnly}
              >
                Add Another Package
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <Snackbar
        open={!!alertMessage}
        autoHideDuration={3000}
        onClose={() => setAlertMessage(null)}
      >
        <Alert
          onClose={() => setAlertMessage(null)}
          severity={alertMessage?.type || "info"}
          sx={{ width: "100%" }}
        >
          {alertMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PackageDetails;
