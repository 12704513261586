import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const OrderMap = ({ selectedOrders, orders, locations, onOrderSelect }) => {
  const mapRef = useRef(null);
  const markersRef = useRef({});
  const mapInstanceRef = useRef(null);
  const initializedRef = useRef(false);
  const [mapWidth, setMapWidth] = useState("50%"); // Instead of tableWidth

  // Initialize map only once
  useEffect(() => {
    if (!initializedRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = L.map("map", {
        minZoom: 4,
        maxZoom: 18,
      }).setView([37.0902, -95.7129], 4);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
        mapInstanceRef.current
      );
      initializedRef.current = true;
    }

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
        initializedRef.current = false;
      }
    };
  }, []); // Empty dependency array - only run once

  // Handle markers separately
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    // Clear existing markers
    Object.values(markersRef.current).forEach((marker) => marker.remove());
    markersRef.current = {};

    // Add location markers
    locations.forEach((location) => {
      if (location.latitude && location.longitude) {
        const icon = L.divIcon({
          className: "business-location-marker",
          html: `
            <div style="
              background-color: ${getLocationColor(location.location_type)};
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: 2px solid white;
              box-shadow: 0 0 4px rgba(0,0,0,0.4);
            "></div>
          `,
        });

        const marker = L.marker([location.latitude, location.longitude], {
          icon,
        })
          .addTo(mapInstanceRef.current)
          .bindTooltip(`${location.name} (${location.location_type})`);

        markersRef.current[`loc-${location.location_id}`] = marker;
      }
    });

    // Add order markers
    orders.forEach((order) => {
      const recipientAddress = order.recipient?.Addresses?.find(
        (addr) => addr.address_id === order.recipient_address_id
      );

      if (recipientAddress?.latitude && recipientAddress?.longitude) {
        const marker = L.circleMarker(
          [recipientAddress.latitude, recipientAddress.longitude],
          {
            radius: 8,
            fillColor: selectedOrders.includes(order) ? "#4CAF50" : "#2196F3",
            color: "#fff",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.8,
          }
        )
          .addTo(mapInstanceRef.current)
          .bindTooltip(
            `Delivery for ${order.recipient?.first_name} ${order.recipient?.last_name}`
          )
          .on("click", () => onOrderSelect(order));

        markersRef.current[order.shipping_order_id] = marker;
      }
    });

    // Update the map size in case container changed
    mapInstanceRef.current.invalidateSize();
  }, [orders, selectedOrders, locations]); // Don't include onOrderSelect

  // Handle resize
  useEffect(() => {
    const handleResize = () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.invalidateSize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id="map"
      style={{
        height: "100%",
        width: "100%",
        minHeight: "400px",
        position: "relative",
      }}
    />
  );
};

const getLocationColor = (locationType) => {
  const colors = {
    warehouse: "#FF9800",
    store: "#9C27B0",
    start: "#FFD700",
    default: "#757575",
  };
  return colors[locationType] || colors.default;
};

export default React.memo(OrderMap);
