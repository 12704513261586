// /Users/noelsarduy/GitHub/marketplace-app/client/src/components/Icon/LoadingModal.js
import React from "react";
import { Modal, CircularProgress, Box, Typography } from "@mui/material";
import "./LoadingModal.css";

const LoadingModal = ({ open, message = "Loading..." }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal-title"
      className={open ? "fade-in" : "fade-out"}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        className="loading-modal-box"
      >
        <CircularProgress sx={{ mb: 2 }} />
        <Typography variant="body1" color="white" align="center">
          {message}
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
