// PublicRoute.jsx
import React, { useContext, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, AUTH_STATUS } from "./AuthContext";
import LoadingModal from "../Icon/LoadingModal";

const PublicRoute = ({ children }) => {
  const { authStatus, user, isProfileComplete } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || "/dashboard";
  const redirectedRef = useRef(false);

  // Handle auth errors for display on the login page
  const authErrorMessage = location.state?.authError;

  // Only redirect once authentication is confirmed and stable
  useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATED && !redirectedRef.current) {
      redirectedRef.current = true;

      // If profile is incomplete, redirect to complete profile
      if (!isProfileComplete(user)) {
        console.log("Redirecting to complete profile page");
        navigate("/complete-profile", { state: { from }, replace: true });
      } else {
        // Otherwise redirect to dashboard or original destination
        console.log("Redirecting authenticated user to:", from);
        navigate(from, { replace: true });
      }
    }
  }, [authStatus, from, isProfileComplete, navigate, user]);

  // Pass any error message to the children component
  return authErrorMessage
    ? React.cloneElement(children, { authErrorMessage })
    : children;
};

export default PublicRoute;
