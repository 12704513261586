import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Chip,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  ExpandMore,
  LocalShipping,
  ShoppingCart,
  People,
  Timeline,
  Inventory,
} from "@mui/icons-material";

// Skeleton loading component for ReportCard
export const ReportCardSkeleton = () => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardHeader
      avatar={<Skeleton variant="circular" width={40} height={40} />}
      title={<Skeleton variant="text" width="60%" />}
      subheader={<Skeleton variant="text" width="40%" />}
      action={<Skeleton variant="rectangular" width={80} height={32} />}
    />
    <CardContent>
      <Skeleton variant="rectangular" width="100%" height={100} />
    </CardContent>
  </Card>
);

const ReportCard = ({ report }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getTypeIcon = () => {
    switch (report.type) {
      case "sales":
        return <ShoppingCart color="primary" />;
      case "performance":
        return <Timeline color="secondary" />;
      case "customers":
        return <People color="info" />;
      case "inventory":
        return <Inventory color="warning" />;
      default:
        return <LocalShipping />;
    }
  };

  const getPeriodLabel = () => {
    switch (report.period) {
      case "7days":
        return "Last 7 Days";
      case "30days":
        return "Last 30 Days";
      case "90days":
        return "Last 90 Days";
      case "12months":
        return "Last 12 Months";
      default:
        return report.period;
    }
  };

  const renderReportContent = () => {
    switch (report.type) {
      case "sales":
        return renderSalesReport();
      case "performance":
        return renderPerformanceReport();
      case "customers":
        return renderCustomersReport();
      case "inventory":
        return renderInventoryReport();
      default:
        return (
          <Box sx={{ textAlign: "center", py: 2 }}>
            <Typography variant="body1" color="text.secondary">
              No detailed data available for this report type.
            </Typography>
          </Box>
        );
    }
  };

  const renderSalesReport = () => {
    const { totalOrders, totalRevenue, avgOrderValue, currency } = report.data;

    return (
      <>
        <List>
          <ListItem>
            <ListItemText
              primary="Total Orders"
              secondary={totalOrders}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ variant: "h6", fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Total Revenue"
              secondary={`${currency} ${parseFloat(
                totalRevenue
              ).toLocaleString()}`}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ variant: "h6", fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Average Order Value"
              secondary={`${currency} ${parseFloat(
                avgOrderValue
              ).toLocaleString()}`}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ variant: "h6", fontWeight: "medium" }}
            />
          </ListItem>
        </List>
      </>
    );
  };

  const renderPerformanceReport = () => {
    const {
      totalDelivered,
      onTimeDeliveries,
      onTimeRate,
      lateDeliveries,
      avgLateBy,
    } = report.data;

    return (
      <>
        <List>
          <ListItem>
            <ListItemText
              primary="Total Delivered Orders"
              secondary={totalDelivered}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="On-Time Deliveries"
              secondary={`${onTimeDeliveries} (${onTimeRate}%)`}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Late Deliveries"
              secondary={lateDeliveries}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Average Late By"
              secondary={avgLateBy}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
        </List>
      </>
    );
  };

  const renderCustomersReport = () => {
    const { newCustomers, repeatCustomers, topCustomers } = report.data;

    return (
      <>
        <List>
          <ListItem>
            <ListItemText
              primary="New Customers"
              secondary={newCustomers}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Repeat Customers"
              secondary={repeatCustomers}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
        </List>

        {topCustomers && topCustomers.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1, fontWeight: "medium" }}>
              Top Customers
            </Typography>
            <TableContainer component={Paper} variant="outlined" sx={{ boxShadow: 'none' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "medium" }}>Customer</TableCell>
                    <TableCell align="right" sx={{ fontWeight: "medium" }}>Orders</TableCell>
                    <TableCell align="right" sx={{ fontWeight: "medium" }}>Total Spent</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topCustomers.map((customer, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {customer.name}
                      </TableCell>
                      <TableCell align="right">{customer.orders}</TableCell>
                      <TableCell align="right">
                        ${customer.totalSpent.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  };

  const renderInventoryReport = () => {
    const {
      totalItems,
      lowStockItems,
      outOfStockItems,
      inventoryValue,
      topItems,
    } = report.data;

    return (
      <>
        <List>
          <ListItem>
            <ListItemText
              primary="Total Items"
              secondary={totalItems}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Low Stock Items"
              secondary={lowStockItems}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Out of Stock Items"
              secondary={outOfStockItems}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary="Inventory Value"
              secondary={`${inventoryValue.toFixed(2)}`}
              primaryTypographyProps={{ variant: "body2", color: "text.secondary" }}
              secondaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
        </List>

        {topItems && topItems.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1, fontWeight: "medium" }}>
              Top Inventory Items
            </Typography>
            <TableContainer component={Paper} variant="outlined" sx={{ boxShadow: 'none' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "medium" }}>Item</TableCell>
                    <TableCell align="right" sx={{ fontWeight: "medium" }}>Quantity</TableCell>
                    <TableCell align="right" sx={{ fontWeight: "medium" }}>Unit Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">
                        ${item.unitCost.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        avatar={getTypeIcon()}
        title={<Typography variant="h6">{report.title}</Typography>}
        subheader={`Generated: ${formatDate(report.generatedAt)}`}
        action={
          <Box display="flex" alignItems="center">
            <Chip
              label={getPeriodLabel()}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ mr: 1 }}
            />
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMore
                sx={{
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s",
                }}
              />
            </IconButton>
          </Box>
        }
      />
      {/* Display a summary when not expanded */}
      {!expanded && (
        <CardContent>
          <Box sx={{ opacity: 0.7 }}>
            {report.summary && (
              <Typography variant="body2" color="text.secondary">
                {report.summary}
              </Typography>
            )}
            {!report.summary && (
              <Typography variant="body2" color="text.secondary">
                Click to expand and view detailed report information.
              </Typography>
            )}
          </Box>
        </CardContent>
      )}
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {renderReportContent()}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ReportCard;
