// src/components/BillOfLadingDialog.jsx
import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@mui/material";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import { Print as PrintIcon } from "@mui/icons-material";
import html2pdf from "../../../../utils/patchedHtml2pdf";
import {
  getCategorySettings,
  updatePrintSettings,
  savePrintSettings,
  getPrinterConfig,
} from "../../settings/printer/PrintSettings";

const BillOfLadingDialog = ({ open, onClose, orders }) => {
  const { selectedBusiness } = useBusiness();
  const businessAddresses = selectedBusiness?.BusinessAddresses || [];
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Load print settings
  const [printSettings, setPrintSettings] = useState(() =>
    getCategorySettings(selectedBusiness?.business_id, "shippingDocuments")
  );

  // Print settings handlers
  const handleSettingsFieldChange = (field, value) => {
    const updated = updatePrintSettings(
      selectedBusiness?.business_id,
      "shippingDocuments",
      {
        ...printSettings,
        [field]: value,
      }
    );
    setPrintSettings(updated.shippingDocuments);
  };

  const handleFontSizeChange = (fontType, value) => {
    const updated = updatePrintSettings(
      selectedBusiness?.business_id,
      "shippingDocuments",
      {
        ...printSettings,
        fontSizes: {
          ...printSettings.fontSizes,
          [fontType]: Number(value),
        },
      }
    );
    setPrintSettings(updated.shippingDocuments);
  };

  const handleSaveSettings = () => {
    try {
      const updated = savePrintSettings(
        selectedBusiness?.business_id,
        "shippingDocuments",
        printSettings
      );
      setPrintSettings(updated.shippingDocuments);
    } catch (error) {
      setError("Failed to save print settings");
      setShowSnackbar(true);
    }
  };

  const calculatePreviewDimensions = () => {
    const sizeString = printSettings?.size || "8.5x11";
    const [width, height] = sizeString.split("x").map(Number);
    const DPI = 96;
    return {
      width: width * DPI,
      height: height * DPI,
    };
  };

  // Compute a list of unique recipients from the orders.
  // (This is retained if you need it elsewhere; however, consignee fields will remain empty.)
  const uniqueRecipients = useMemo(() => {
    if (!orders || orders.length === 0) return [];
    return orders.reduce((acc, order) => {
      const recipient = order.recipient;
      if (
        recipient &&
        !acc.find((r) => r.customer_id === recipient.customer_id)
      ) {
        acc.push(recipient);
      }
      return acc;
    }, []);
  }, [orders]);

  // Initialize state for the Bill of Lading data.
  const [billData, setBillData] = useState({
    businessName: selectedBusiness?.business_name || "",
    businessAddress: businessAddresses[0]?.formatted_address || "",
    shipperName: selectedBusiness?.business_name || "",
    shipperAddress: businessAddresses[0]?.formatted_address || "",
    consigneeName: "", // Always leave empty for manual fill
    consigneeAddress: "", // Always leave empty for manual fill
    carrier: "",
    vessel: "",
    portOfLoading: "",
    portOfDischarge: "",
    placeOfReceipt: "",
    placeOfDelivery: "",
    billOfLadingNumber: "",
    date: new Date().toLocaleDateString(),
    // Build an orders summary from the orders array.
    orders: orders
      ? orders.map((order) => ({
          orderId: order.shipping_order_id,
          trackingNumber: order.tracking_number,
          orderDate: new Date(order.createdAt).toLocaleDateString(),
          weight: `${order.total_weight} ${order.weight_unit}`,
        }))
      : [],
  });

  // When the selected business changes, update business-related fields.
  useEffect(() => {
    if (selectedBusiness) {
      setBillData((prev) => ({
        ...prev,
        businessName: selectedBusiness.business_name || "",
        businessAddress:
          selectedBusiness.BusinessAddresses?.[0]?.formatted_address || "",
        shipperName: selectedBusiness.business_name || "",
        shipperAddress:
          selectedBusiness.BusinessAddresses?.[0]?.formatted_address || "",
      }));
    }
  }, [selectedBusiness]);

  // When orders change, update the orders summary and clear consignee details.
  useEffect(() => {
    if (orders && orders.length > 0) {
      setBillData((prev) => ({
        ...prev,
        consigneeName: "", // Clear so user can manually fill
        consigneeAddress: "",
        orders: orders.map((order) => ({
          orderId: order.shipping_order_id,
          trackingNumber: order.tracking_number,
          orderDate: new Date(order.createdAt).toLocaleDateString(),
          weight: `${order.total_weight} ${order.weight_unit}`,
        })),
      }));
    }
  }, [orders]);

  // Generic change handler for text fields.
  const handleChange = (field) => (e) => {
    setBillData((prev) => ({ ...prev, [field]: e.target.value }));
  };

  // Handle dropdown selection for the business address.
  const handleBusinessAddressChange = (e) => {
    console.log("Selected business address ID:", e);
    const newAddress = e.target.value;
    console.log("Selected business address:", newAddress);
    setBillData((prev) => ({
      ...prev,
      businessAddress: newAddress,
      shipperAddress: newAddress, // Assume shipper address is the business location.
    }));
  };

  // Handle the print action (integrate your ZPL generator here).
  const handlePrint = async () => {
    try {
      setIsPrinting(true);
      setError(null);

      const printer = getPrinterConfig(
        selectedBusiness?.business_id,
        "shippingDocuments"
      );
      if (!printSettings) {
        throw new Error("Print settings not configured");
      }
      if (!printer) {
        throw new Error("No printer configured");
      }

      const previewElement = document.getElementById("bill-of-lading-preview");
      if (!previewElement) {
        throw new Error("Preview element not found");
      }

      // Create wrapper for PDF generation
      const wrapper = document.createElement("div");
      const [inWidth, inHeight] = printSettings.size.split("x").map(Number);
      const DPI = 96;
      const width = inWidth * DPI;
      const height = inHeight * DPI;

      wrapper.style.width = `${width}px`;
      wrapper.style.height = `${height}px`;
      wrapper.style.background = "white";
      wrapper.style.position = "relative";
      wrapper.style.overflow = "hidden";

      const previewClone = previewElement.cloneNode(true);
      previewClone.style.position = "absolute";
      previewClone.style.visibility = "visible";
      previewClone.style.display = "block";
      previewClone.style.background = "white";
      previewClone.style.color = "black";
      previewClone.style.width = "100%";
      previewClone.style.height = "100%";
      previewClone.style.margin = "0";
      previewClone.style.padding = "0";
      wrapper.appendChild(previewClone);
      document.body.appendChild(wrapper);

      try {
        const pdfOutput = await html2pdf()
          .set({
            margin: 0,
            filename: "bill-of-lading.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 4,
              backgroundColor: "#FFFFFF",
              width: wrapper.offsetWidth,
              height: wrapper.offsetHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "in",
              format: [width / DPI, height / DPI],
              orientation: printSettings.orientation,
              compress: false,
            },
          })
          .from(wrapper)
          .outputPdf("blob");

        const pdfUrl = URL.createObjectURL(pdfOutput);
        window.open(pdfUrl, "_blank");

        // Handle QZ Tray printing if needed
        // Similar to the receipt printing logic
      } catch (error) {
        throw new Error("Failed to generate PDF");
      } finally {
        document.body.removeChild(wrapper);
      }
    } catch (err) {
      setError(err.message);
      setShowSnackbar(true);
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>Edit Bill of Lading</DialogTitle>
        <DialogContent dividers>
          {/* Business Information Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Business Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Business Name"
                  fullWidth
                  value={billData.businessName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Business Location</InputLabel>
                  <Select
                    value={billData.businessAddress}
                    label="Business Location"
                    onChange={handleBusinessAddressChange}
                  >
                    {businessAddresses.map(
                      (address) => (
                        console.log("Address: ", address),
                        (
                          <MenuItem
                            key={address.business_address_id}
                            value={
                              address.business_address_street_address_1 +
                              " " +
                              address.business_address_city +
                              " " +
                              address.business_address_state_province +
                              " " +
                              address.business_address_postal_code
                            }
                          >
                            {address.business_address_name} -{" "}
                            {address.business_address_street_address_1 +
                              " " +
                              address.business_address_city +
                              " " +
                              address.business_address_state_province +
                              " " +
                              address.business_address_postal_code}
                          </MenuItem>
                        )
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  value={selectedBusiness?.business_phone || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label="Email"
                  fullWidth
                  value={selectedBusiness?.business_email || ""}
                  disabled
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Shipment / Bill of Lading Information Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Shipment Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Shipper Name"
                  fullWidth
                  value={billData.shipperName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Shipper Address"
                  fullWidth
                  value={billData.shipperAddress}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Leave consignee fields empty for manual fill */}
                <TextField
                  label="Consignee Name"
                  fullWidth
                  value={billData.consigneeName}
                  onChange={handleChange("consigneeName")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Consignee Address"
                  fullWidth
                  value={billData.consigneeAddress}
                  onChange={handleChange("consigneeAddress")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Carrier"
                  fullWidth
                  value={billData.carrier}
                  onChange={handleChange("carrier")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Vessel"
                  fullWidth
                  value={billData.vessel}
                  onChange={handleChange("vessel")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Bill of Lading Number"
                  fullWidth
                  value={billData.billOfLadingNumber}
                  onChange={handleChange("billOfLadingNumber")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Port of Loading"
                  fullWidth
                  value={billData.portOfLoading}
                  onChange={handleChange("portOfLoading")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Port of Discharge"
                  fullWidth
                  value={billData.portOfDischarge}
                  onChange={handleChange("portOfDischarge")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Place of Receipt"
                  fullWidth
                  value={billData.placeOfReceipt}
                  onChange={handleChange("placeOfReceipt")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Place of Delivery"
                  fullWidth
                  value={billData.placeOfDelivery}
                  onChange={handleChange("placeOfDelivery")}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Date"
                  fullWidth
                  value={billData.date}
                  onChange={handleChange("date")}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Orders Details Section */}
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Order Details
            </Typography>
            <Box
              component="table"
              width="100%"
              sx={{ borderCollapse: "collapse", fontFamily: "monospace" }}
            >
              <Box component="thead">
                <Box
                  component="tr"
                  sx={{ borderBottom: "2px solid #000", fontWeight: "bold" }}
                >
                  <Box component="th" sx={{ p: 1, textAlign: "left" }}>
                    Order ID
                  </Box>
                  <Box component="th" sx={{ p: 1, textAlign: "left" }}>
                    Tracking Number
                  </Box>
                  <Box component="th" sx={{ p: 1, textAlign: "left" }}>
                    Order Date
                  </Box>
                  <Box component="th" sx={{ p: 1, textAlign: "right" }}>
                    Weight
                  </Box>
                </Box>
              </Box>
              <Box component="tbody">
                {billData.orders.map((order, index) => (
                  <Box
                    component="tr"
                    key={index}
                    sx={{ borderBottom: "1px dashed #ccc" }}
                  >
                    <Box component="td" sx={{ p: 1 }}>
                      {order.orderId}
                    </Box>
                    <Box component="td" sx={{ p: 1 }}>
                      {order.trackingNumber}
                    </Box>
                    <Box component="td" sx={{ p: 1 }}>
                      {order.orderDate}
                    </Box>
                    <Box component="td" sx={{ p: 1, textAlign: "right" }}>
                      {order.weight}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          {/* Preview Section */}
          <Typography variant="subtitle1" gutterBottom>
            Preview
          </Typography>

          <Box
            id="bill-of-lading-preview"
            sx={{
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              color: "black",
              width: `${calculatePreviewDimensions().width}px`,
              height: `${calculatePreviewDimensions().height}px`,
              transform:
                printSettings.orientation === "landscape"
                  ? "rotate(90deg)"
                  : "none",
              transformOrigin: "center center",
              overflow: "visible",
              margin: "0 auto",
            }}
          >
            <Box sx={{ px: 2 }}>
              {" "}
              {/* Header */}
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: printSettings.fontSizes.title,
                  mb: 2,
                }}
              >
                Bill of Lading
              </Typography>
              {/* Business Information */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: printSettings.fontSizes.subtitle,
                      fontWeight: "bold",
                    }}
                  >
                    Shipper
                  </Typography>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    {billData.shipperName}
                  </Typography>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    {billData.shipperAddress}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: printSettings.fontSizes.subtitle,
                      fontWeight: "bold",
                    }}
                  >
                    Consignee
                  </Typography>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    {billData.consigneeName}
                  </Typography>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    {billData.consigneeAddress}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              {/* Shipment Details */}
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>Carrier:</strong> {billData.carrier}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>Vessel:</strong> {billData.vessel}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>B/L No:</strong> {billData.billOfLadingNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>Port of Loading:</strong> {billData.portOfLoading}
                  </Typography>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>Port of Discharge:</strong>{" "}
                    {billData.portOfDischarge}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>Place of Receipt:</strong> {billData.placeOfReceipt}
                  </Typography>
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details }}
                  >
                    <strong>Place of Delivery:</strong>{" "}
                    {billData.placeOfDelivery}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              {/* Order Details Table */}
              <Typography
                sx={{
                  fontSize: printSettings.fontSizes.subtitle,
                  mb: 1,
                  fontWeight: "bold",
                }}
              >
                Order Details
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: printSettings.fontSizes.details,
                        fontWeight: "bold",
                      }}
                    >
                      Order ID
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: printSettings.fontSizes.details,
                        fontWeight: "bold",
                      }}
                    >
                      Tracking Number
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: printSettings.fontSizes.details,
                        fontWeight: "bold",
                      }}
                    >
                      Order Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: printSettings.fontSizes.details,
                        fontWeight: "bold",
                      }}
                    >
                      Weight
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billData.orders.map((order, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{ fontSize: printSettings.fontSizes.details }}
                      >
                        {order.orderId}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: printSettings.fontSizes.details }}
                      >
                        {order.trackingNumber}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: printSettings.fontSizes.details }}
                      >
                        {order.orderDate}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: printSettings.fontSizes.details }}
                      >
                        {order.weight}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* Date and Signature */}
              <Box
                sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}
              >
                <Typography sx={{ fontSize: printSettings.fontSizes.details }}>
                  Date: {billData.date}
                </Typography>
                <Box sx={{ textAlign: "center", width: "200px" }}>
                  <Divider />
                  <Typography
                    sx={{ fontSize: printSettings.fontSizes.details, mt: 1 }}
                  >
                    Authorized Signature
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Print Settings Section */}
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              mb: 3,
              p: 5,
              // border: "1px solid #ccc",
              display: "inline",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{ color: "black", mb: 1 }}>
              Print Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel>Document Size</InputLabel>
                  <Select
                    value={printSettings.size}
                    label="Document Size"
                    onChange={(e) =>
                      handleSettingsFieldChange("size", e.target.value)
                    }
                  >
                    {["8.5x11", "11x17"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth>
                  <InputLabel>Orientation</InputLabel>
                  <Select
                    value={printSettings.orientation}
                    label="Orientation"
                    onChange={(e) =>
                      handleSettingsFieldChange("orientation", e.target.value)
                    }
                  >
                    <MenuItem value="portrait">Portrait</MenuItem>
                    <MenuItem value="landscape">Landscape</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Title Font Size"
                  type="number"
                  value={printSettings.fontSizes.title}
                  onChange={(e) =>
                    handleFontSizeChange("title", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 10, max: 32 } }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Subtitle Font Size"
                  type="number"
                  value={printSettings.fontSizes.subtitle}
                  onChange={(e) =>
                    handleFontSizeChange("subtitle", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 24 } }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Details Font Size"
                  type="number"
                  value={printSettings.fontSizes.details}
                  onChange={(e) =>
                    handleFontSizeChange("details", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 20 } }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button variant="outlined" onClick={handleSaveSettings}>
                  Save Print Settings
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isPrinting}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handlePrint}
            disabled={isPrinting || !billData.billOfLadingNumber}
            startIcon={
              isPrinting ? <CircularProgress size={20} /> : <PrintIcon />
            }
          >
            {isPrinting ? "Printing..." : "Print Bill of Lading"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%", backgroundColor: "black", color: "white" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BillOfLadingDialog;
