import React, { createContext, useContext, useMemo } from 'react';

// Define the permission scopes for different employee roles
export const ROLE_PERMISSIONS = {
  Owner: {
    canView: true,
    canEdit: true,
    canDelete: true,
    canAddOrders: true,
    canAssignOrders: true,
    canManageCollaborations: true,
    canImportBulk: true,
    canViewAnalytics: true,
    canManageCustomers: true,
    canViewEmployees: true,
    canManageEmployees: true,
    canManageSettings: true,
  },
  Administrator: {
    canView: true,
    canEdit: true,
    canDelete: true,
    canAddOrders: true,
    canAssignOrders: true,
    canManageCollaborations: true,
    canImportBulk: true,
    canViewAnalytics: true,
    canManageCustomers: true,
    canViewEmployees: true,
    canManageEmployees: true,
    canManageSettings: true,
  },
  Manager: {
    canView: true,
    canEdit: true,
    canDelete: true,
    canAddOrders: true,
    canAssignOrders: true,
    canManageCollaborations: true,
    canImportBulk: true,
    canViewAnalytics: true,
    canManageCustomers: true,
    canViewEmployees: true,
    canManageEmployees: true,
    canManageSettings: false,
  },
  Supervisor: {
    canView: true,
    canEdit: true,
    canDelete: false,
    canAddOrders: true,
    canAssignOrders: true,
    canManageCollaborations: false,
    canImportBulk: false,
    canViewAnalytics: true,
    canManageCustomers: true,
    canViewEmployees: true,
    canManageEmployees: false,
    canManageSettings: false,
  },
  Courier: {
    canView: true,
    canEdit: false,
    canDelete: false,
    canAddOrders: false,
    canAssignOrders: false,
    canManageCollaborations: false,
    canImportBulk: false,
    canViewAnalytics: false,
    canManageCustomers: false,
    canViewEmployees: false,
    canManageEmployees: false,
    canManageSettings: false,
  },
  "Customer Service": {
    canView: true,
    canEdit: true,
    canDelete: false,
    canAddOrders: true,
    canAssignOrders: false,
    canManageCollaborations: false,
    canImportBulk: false,
    canViewAnalytics: false,
    canManageCustomers: true,
    canViewEmployees: false,
    canManageEmployees: false,
    canManageSettings: false,
  },
  "Sales Representative": {
    canView: true,
    canEdit: true,
    canDelete: false,
    canAddOrders: true,
    canAssignOrders: false,
    canManageCollaborations: false,
    canImportBulk: false,
    canViewAnalytics: false,
    canManageCustomers: true,
    canViewEmployees: false,
    canManageEmployees: false,
    canManageSettings: false,
  },
  Accountant: {
    canView: true,
    canEdit: false,
    canDelete: false,
    canAddOrders: false,
    canAssignOrders: false,
    canManageCollaborations: false,
    canImportBulk: false,
    canViewAnalytics: true,
    canManageCustomers: false,
    canViewEmployees: false,
    canManageEmployees: false,
    canManageSettings: false,
  },
};

// Default permissions (no access)
const DEFAULT_PERMISSIONS = {
  canView: false,
  canEdit: false,
  canDelete: false,
  canAddOrders: false,
  canAssignOrders: false,
  canManageCollaborations: false,
  canImportBulk: false,
  canViewAnalytics: false,
  canManageCustomers: false,
  canViewEmployees: false,
  canManageEmployees: false,
  canManageSettings: false,
};

// Helper function to check permissions based on employee roles
export const getEmployeePermissions = (roles) => {
  if (!roles || roles.length === 0) {
    return DEFAULT_PERMISSIONS;
  }

  // Combine permissions from all roles
  const permissions = { ...DEFAULT_PERMISSIONS };

  roles.forEach((role) => {
    const rolePermissions = ROLE_PERMISSIONS[role] || {};
    Object.keys(permissions).forEach((perm) => {
      permissions[perm] = permissions[perm] || rolePermissions[perm] || false;
    });
  });

  return permissions;
};

// Create a context for employee permissions
const EmployeePermissionsContext = createContext(DEFAULT_PERMISSIONS);

export const EmployeePermissionsProvider = ({ children, roles = [] }) => {
  const permissions = useMemo(() => getEmployeePermissions(roles), [roles]);

  return (
    <EmployeePermissionsContext.Provider value={permissions}>
      {children}
    </EmployeePermissionsContext.Provider>
  );
};

export const useEmployeePermissions = () => {
  const context = useContext(EmployeePermissionsContext);
  if (!context) {
    throw new Error('useEmployeePermissions must be used within an EmployeePermissionsProvider');
  }
  return context;
};