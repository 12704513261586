import React from "react";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react"; // Ensure qrcode.react is installed
import styles from "./PrintPreview.module.css"; // Ensure this CSS module exists
import { ReactComponent as Logo } from "../../../../assets/black-logo-name.svg";
import ReactDOMServer from "react-dom/server";

/**
 * PrintPreview Component
 * @param {string} type - Type of the preview (e.g., 'receipts', 'shippingLabels').
 * @param {Object} settings - Current print settings.
 */
const PrintPreview = ({ type, settings }) => {
  // RenderLogo forces the logo to render in pure black.
  const RenderLogo = () => {
    return (
      <Box
        sx={{
          textAlign: "center",
          padding: 0,
          display: "inline-block",
          m: 0,
          p: 0,
        }}
      >
        <Logo style={{ fill: "black", width: "80px", height: "80px" }} />
      </Box>
    );
  };

  // Retrieve hardcoded sample data based on the print type.
  const getSampleData = (type) => {
    switch (type) {
      case "receipts":
        return {
          storeName: "Example Store",
          storeAddress: "1234 Market St., City, State ZIP",
          receiptId: "RCPT123456",
          date: "2025-01-21",
          customerName: "John Doe",
          items: [
            {
              name: "Widget A",
              quantity: 2,
              unitPrice: 10.0,
              totalPrice: 20.0,
            },
            {
              name: "Widget B",
              quantity: 1,
              unitPrice: 15.0,
              totalPrice: 15.0,
            },
          ],
          subtotal: 35.0,
          tax: 3.15,
          total: 38.15,
          paymentMethod: "Credit Card",
          thankYouMessage: "Thank you for shopping with us!",
        };
      case "shippingLabels":
        return {
          senderName: "John Doe",
          senderAddress: "1234 Market St., City, State ZIP",
          recipientName: "Jane Smith",
          recipientAddress: "5678 Elm St., City, State ZIP",
          shipmentMethod: "Ground",
          packageWeight: "5 lbs",
          packageDimensions: "10x5x8 inches",
          trackingNumber: "TRACK123456789",
          specialInstructions: "Leave at front door if no answer.",
        };
      case "shippingManifests":
        return {
          manifestId: "MFG123456",
          date: "2025-01-21",
          shipmentMethod: "Air",
          totalPackages: 10,
          totalWeight: "50 lbs",
          totalVolume: "500 cubic inches",
          sender: {
            name: "John Doe",
            address: "1234 Market St., City, State ZIP",
            contact: "john.doe@example.com",
          },
          consignee: {
            name: "Jane Smith",
            address: "5678 Elm St., City, State ZIP",
            contact: "jane.smith@example.com",
          },
          packages: [
            {
              packageNumber: "PKG1",
              trackingNumber: "TRACKPKG1",
              weight: "5 lbs",
              dimensions: "10x5x8 inches",
              contents: ["Widget A", "Widget B"],
            },
          ],
          specialInstructions: "Handle with care.",
        };
      case "shippingDocuments":
        return {
          manifestId: "MFG123456",
          date: "2025-01-21",
          shipmentMethod: "Express",
          sender: {
            name: "John Doe",
            address: "1234 Market St., City, State ZIP",
            contact: "john.doe@example.com",
          },
          consignee: {
            name: "Jane Smith",
            address: "5678 Elm St., City, State ZIP",
            contact: "jane.smith@example.com",
          },
          details: "This document contains shipping details and terms.",
        };
      case "individualItemLabels":
        return {
          name: "Widget A",
          sku: "WIDGET-A",
          quantity: 2,
          price: 20.0,
          itemUrl: "https://example.com/item/WIDGET-A",
        };
      default:
        return {};
    }
  };

  const data = getSampleData(type);
  if (!data) {
    return (
      <Typography variant="body1" sx={{ color: "black" }}>
        No data available for preview.
      </Typography>
    );
  }

  /**
   * Returns the style object based on type and settings.
   * All colors (borders, text) are forced to black.
   */
  const getPreviewStyle = (type, size, orientation) => {
    const DPI = 96; // Standard web DPI
    const getDimensions = (size) => {
      const [width, height] = size.split("x").map(Number);
      return { width, height };
    };

    const sizeMap = {
      receipts: {
        "4x6": getDimensions("4x6"),
        "6x4": getDimensions("6x4"),
      },
      shippingLabels: {
        "2x2": getDimensions("2x2"),
        "4x6": getDimensions("4x6"),
      },
      shippingManifests: {
        "8.5x11": getDimensions("8.5x11"),
        A4: getDimensions("8.27x11.69"),
        Letter: getDimensions("8.5x11"),
        Legal: getDimensions("8.5x14"),
      },
      shippingDocuments: {
        "8.5x11": getDimensions("8.5x11"),
        A4: getDimensions("8.27x11.69"),
        Letter: getDimensions("8.5x11"),
        Legal: getDimensions("8.5x14"),
      },
      individualItemLabels: {
        "0.75x0.75": getDimensions("0.75x0.75"),
        "1x1": getDimensions("1x1"),
        "1.5x1.5": getDimensions("1.5x1.5"),
        "2x2": getDimensions("2x2"),
        "2.5x2.5": getDimensions("2.5x2.5"),
        "3x3": getDimensions("3x3"),
        "3.5x3.5": getDimensions("3.5x3.5"),
        "4x4": getDimensions("4x4"),
      },
    };

    const typeSizes = sizeMap[type] || {};
    const dimensions = typeSizes[size];

    if (!dimensions) {
      return {
        width: "400px",
        height: "600px",
        boxSizing: "border-box",
        border: "1px solid black",
        padding: "0px",
        backgroundColor: "#fff",
        overflow: "visible",
      };
    }

    let widthPx = dimensions.width * DPI;
    let heightPx = dimensions.height * DPI;
    if (orientation === "landscape") {
      [widthPx, heightPx] = [heightPx, widthPx];
    }

    return {
      width: `${widthPx}px`,
      height: `${heightPx}px`,
      boxSizing: "border-box",
      border: "1px solid black",
      padding: "16px",
      backgroundColor: "#fff",
      overflow: "visible",
    };
  };

  /**
   * Formats the label type for display purposes.
   */
  const formatLabelType = (type) => {
    switch (type) {
      case "receipts":
        return "Receipt";
      case "shippingLabels":
        return "Shipping Label";
      case "shippingManifests":
        return "Shipping Manifest";
      case "shippingDocuments":
        return "Shipping Document";
      case "individualItemLabels":
        return "Individual Item Label";
      default:
        return type;
    }
  };

  /**
   * Renders preview content based on the type.
   * All inline colors are set to black for a purely binary (black and white) output.
   */
  const renderPreviewContent = () => {
    switch (type) {
      case "receipts":
        return (
          <Box
            className={styles.previewBox}
            style={getPreviewStyle(type, settings.size, settings.orientation)}
          >
            <RenderLogo />

            <Typography
              variant="h5"
              style={{
                fontSize: settings.fontSizes.title,
                textAlign: "center",
                color: "black",
              }}
            >
              {data.storeName}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: settings.fontSizes.subtitle,
                textAlign: "center",
                color: "black",
              }}
            >
              {data.storeAddress}
            </Typography>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Receipt ID: {data.receiptId}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Date: {data.date}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Customer: {data.customerName}
              </Typography>
            </Box>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Unit Price
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {item.quantity}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        ${item.unitPrice.toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        ${item.totalPrice.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Box sx={{ width: "50%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      fontSize: settings.fontSizes.details,
                      color: "black",
                    }}
                  >
                    Subtotal:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: settings.fontSizes.details,
                      color: "black",
                    }}
                  >
                    ${data.subtotal.toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      fontSize: settings.fontSizes.details,
                      color: "black",
                    }}
                  >
                    Tax:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: settings.fontSizes.details,
                      color: "black",
                    }}
                  >
                    ${data.tax.toFixed(2)}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1, borderColor: "black" }} />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    style={{
                      fontSize: settings.fontSizes.details,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: settings.fontSizes.details,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    ${data.total.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Payment Method: {data.paymentMethod}
              </Typography>
            </Box>
          </Box>
        );
      case "shippingLabels":
        return (
          <Box
            className={styles.previewBox}
            style={getPreviewStyle(type, settings.size, settings.orientation)}
          >
            <RenderLogo />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  From:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.senderName}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.senderAddress}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  To:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.recipientName}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.recipientAddress}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Shipment Method: {data.shipmentMethod}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Package Weight: {data.packageWeight}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Dimensions: {data.packageDimensions}
              </Typography>
              {data.specialInstructions && (
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Special Instructions: {data.specialInstructions}
                </Typography>
              )}
            </Box>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <QRCodeSVG
                value={`https://carrier.example.com/track/${data.trackingNumber}`}
                size={settings.qrCodeSize}
                bgColor="#ffffff"
                fgColor="#000000"
                level="Q"
                includeMargin={false}
              />
              <Typography
                variant="body2"
                style={{
                  fontSize: settings.fontSizes.details,
                  mt: 1,
                  color: "black",
                }}
              >
                Tracking Number: {data.trackingNumber}
              </Typography>
            </Box>
          </Box>
        );
      case "shippingManifests":
        return (
          <Box
            className={styles.previewBox}
            style={getPreviewStyle(type, settings.size, settings.orientation)}
          >
            <RenderLogo />
            <Typography
              variant="h5"
              style={{
                fontSize: settings.fontSizes.title,
                textAlign: "center",
                marginBottom: "16px",
                color: "black",
              }}
            >
              Shipping Manifest
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Manifest ID: {data.manifestId}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Date: {data.date}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Shipment Method: {data.shipmentMethod}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Total Packages: {data.totalPackages}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Total Weight: {data.totalWeight}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Total Volume: {data.totalVolume}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Shipper:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.sender.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.sender.address}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Contact: {data.sender.contact}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Consignee:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.consignee.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.consignee.address}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Contact: {data.consignee.contact}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Typography
              variant="h6"
              style={{
                fontSize: settings.fontSizes.subtitle,
                marginBottom: "8px",
                color: "black",
              }}
            >
              Package Details
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Package Number
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Tracking Number
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Weight
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Dimensions
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: settings.fontSizes.details,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Contents
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.packages.map((pkg, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {pkg.packageNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {pkg.trackingNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {pkg.weight}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {pkg.dimensions}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: settings.fontSizes.details,
                          color: "black",
                        }}
                      >
                        {pkg.contents.join(", ")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            {data.specialInstructions && (
              <Typography
                variant="body1"
                style={{ fontSize: settings.fontSizes.details, color: "black" }}
              >
                Special Instructions: {data.specialInstructions}
              </Typography>
            )}
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <QRCodeSVG
                value={`https://carrier.example.com/manifest/${data.manifestId}`}
                size={settings.qrCodeSize}
                bgColor="#ffffff"
                fgColor="#000000"
                level="Q"
                includeMargin={false}
              />
              <Typography
                variant="body2"
                style={{
                  fontSize: settings.fontSizes.details,
                  mt: 1,
                  color: "black",
                }}
              >
                Manifest ID: {data.manifestId}
              </Typography>
            </Box>
          </Box>
        );
      case "shippingDocuments":
        return (
          <Box
            className={styles.previewBox}
            style={getPreviewStyle(type, settings.size, settings.orientation)}
          >
            <Typography
              variant="h5"
              style={{
                fontSize: settings.fontSizes.title,
                textAlign: "center",
                marginBottom: "16px",
                color: "black",
              }}
            >
              Shipping Document
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Document ID: {data.documentId}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Date: {data.date}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Shipment Method: {data.shipmentMethod}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Shipper:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.sender.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.sender.address}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Contact: {data.sender.contact}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: settings.fontSizes.details,
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Consignee:
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.consignee.name}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  {data.consignee.address}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: settings.fontSizes.details,
                    color: "black",
                  }}
                >
                  Contact: {data.consignee.contact}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Typography
              variant="body1"
              style={{
                fontSize: settings.fontSizes.details,
                mb: 2,
                color: "black",
              }}
            >
              {data.details}
            </Typography>
            <Divider sx={{ my: 2, borderColor: "black" }} />
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <QRCodeSVG
                value={`https://carrier.example.com/document/${data.documentId}`}
                size={settings.qrCodeSize}
                bgColor="#ffffff"
                fgColor="#000000"
                level="Q"
                includeMargin={false}
              />
              <Typography
                variant="body2"
                style={{
                  fontSize: settings.fontSizes.details,
                  mt: 1,
                  color: "black",
                }}
              >
                Document ID: {data.documentId}
              </Typography>
            </Box>
          </Box>
        );
      case "individualItemLabels":
        return (
          <Box
            className={styles.previewBox}
            style={getPreviewStyle(type, settings.size, settings.orientation)}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <QRCodeSVG
              value={data.itemUrl}
              size={settings.qrCodeSize}
              bgColor="#ffffff"
              fgColor="#000000"
              level="Q"
              includeMargin={false}
            />
            <Typography
              variant="body1"
              style={{
                fontSize: settings.fontSizes.details,
                marginTop: "8px",
                textAlign: "center",
                color: "black",
              }}
            >
              {data.name}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: settings.fontSizes.details,
                marginTop: "4px",
                textAlign: "center",
                color: "black",
              }}
            >
              SKU: {data.sku}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: settings.fontSizes.details,
                marginTop: "2px",
                textAlign: "center",
                color: "black",
              }}
            >
              Quantity: {data.quantity}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: settings.fontSizes.details,
                marginTop: "2px",
                textAlign: "center",
                color: "black",
              }}
            >
              Price: ${data.price.toFixed(2)}
            </Typography>
          </Box>
        );
      default:
        return (
          <Typography variant="body1" style={{ color: "black" }}>
            No preview available for this type.
          </Typography>
        );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "visible",
        p: 0,
      }}
    >
      {renderPreviewContent()}
    </Box>
  );
};

export default PrintPreview;
