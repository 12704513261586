import React, { useState, useContext } from "react";
import {
  IconButton,
  Tooltip,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Link,
} from "@mui/material";
import { Print as PrintIcon } from "@mui/icons-material";
import html2pdf from "../../../../utils/patchedHtml2pdf";
import qz from "qz-tray";
import useQzTray from "../../../../hooks/useQzTray";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { QRCodeSVG } from "qrcode.react";
import ReactDOM from "react-dom";

/**
 * Fetch new label codes from the backend.
 * We'll return the entire `data.codes` array instead of just one code.
 */
async function generateItemLabels(api, itemId, orderId) {
  console.log("Generating label code(s) for item:", itemId, "order:", orderId);

  const response = await api.post(`/verify-package/items/${itemId}/labels`, {
    shipping_order_id: orderId,
    count: 1,
  });

  const data = response.data;
  if (!data || data.success !== true || !data.codes?.length) {
    throw new Error("Failed to generate item label codes");
  }
  return data.codes; // Return the entire array of new codes
}

const shortenName = (name, maxLen = 12) =>
  name?.length > maxLen ? name.slice(0, maxLen) + "…" : name || "";

/**
 * Renders a QRCodeSVG into an off-DOM <div> and returns the resulting <svg> string.
 */
async function createQRCodeSVG(value, size) {
  // Make this truly async
  const tempContainer = document.createElement("div");
  const root = ReactDOM.createRoot(tempContainer);

  // Wait for render to complete
  await new Promise((resolve) => {
    root.render(
      <QRCodeSVG
        value={value}
        size={size}
        level="Q"
        bgColor="#FFFFFF"
        fgColor="#000000"
        includeMargin={false}
      />
    );
    setTimeout(resolve, 100); // Give React time to render
  });

  const svgElement = tempContainer.querySelector("svg");
  const svgString = svgElement ? svgElement.outerHTML : "";
  root.unmount();

  return svgString;
}

function handlePrintError(err, businessId) {
  console.error("Printing error:", err);
  if (!err || !err.message) return "Unknown printing error.";
  if (err.message.includes("QZ Tray")) {
    return "Error connecting to printer. Ensure QZ Tray is running.";
  }
  if (
    err.message.includes("printer") ||
    err.message.includes("settings") ||
    err.message.includes("Not Found")
  ) {
    return `Printer not configured. Check your settings at /business/${businessId}/settings/printing-settings`;
  }
  if (err.response?.data?.msg) return err.response.data.msg;
  return err.message;
}

/**
 * Loads or defaults the label settings from localStorage
 */
function loadItemLabelSettings(businessId) {
  const storedSettings = localStorage.getItem(`printSettings_${businessId}`);
  if (!storedSettings) {
    return {
      size: "2x2",
      orientation: "portrait",
      qrCodeSize: 80,
      fontSizes: {
        title: 16,
        details: 12,
      },
    };
  }
  try {
    const parsed = JSON.parse(storedSettings);
    return (
      parsed?.individualItemLabels || {
        size: "2x2",
        orientation: "portrait",
        qrCodeSize: 80,
        fontSizes: { title: 16, details: 12 },
      }
    );
  } catch (err) {
    console.error("Error parsing item label settings:", err);
    return {
      size: "2x2",
      orientation: "portrait",
      qrCodeSize: 80,
      fontSizes: { title: 16, details: 12 },
    };
  }
}

/**
 * Builds the HTML string for a single label.
 * Note this is now an `async` function because we must `await` the QR code.
 */
async function createLabelHTML(labelSettings, item, code, dimensions) {
  const { widthPx, heightPx } = dimensions;
  const orientationTransform =
    labelSettings.orientation === "landscape" ? "rotate(90deg)" : "none";

  // Convert QR code to an inline <svg> (async!)
  const qrSvg = await createQRCodeSVG(code, labelSettings.qrCodeSize);

  // Return the complete HTML string
  return `
    <div
      style="
        border: 1px solid black;
        width: ${widthPx}px;
        height: ${heightPx}px;
        background-color: white;
        color: black;
        transform: ${orientationTransform};
        transform-origin: center center;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        margin: 0;
        padding: 8px;
      "
    >
      <!-- Current Date -->
      <div 
        style="
          color: black; 
          text-align: center;
          margin-bottom: 8px;
          font-weight: bold;
          font-size: 14px;
        "
      >
        ${new Date().toLocaleDateString()}
      </div>

      <!-- QR Code -->
      <div style="text-align: center;">
        ${qrSvg}
      </div>

      <!-- Item Name -->
      <div 
        style="
          color: black;
          text-align: center;
          font-size: ${labelSettings.fontSizes.title}px;
          font-weight: bold;
          margin-top: 8px;
        "
      >
        ${shortenName(item.name)}
      </div>

      <!-- Quantity -->
      <div 
        style="
          color: black;
          text-align: center;
          font-size: ${labelSettings.fontSizes.details}px;
          margin-top: 4px;
        "
      >
        QTY: ${item.quantity}
      </div>
    </div>
  `;
}

const ItemPrintButton = ({ item, onCodesUpdated }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState(null);
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };
  const { businessId } = useParams();
  const { api } = useContext(AuthContext);
  const { print: qzPrint, connect: qzConnect, isConnected: qzIsConnected } = useQzTray();

  // Track the local array of printed codes in state.
  const [printedCodes, setPrintedCodes] = useState(item.printed_codes || []);
  const [isPrinting, setIsPrinting] = useState(false);

  // For your “max” check, use the local printedCodes length
  const hasReachedMax =
    item.max_labels != null && printedCodes.length >= item.max_labels;

  const handlePrint = async () => {
    try {
      setIsPrinting(true);

      // 1) Generate new label codes from the backend
      const newCodes = await generateItemLabels(
        api,
        item.package_item_id,
        item.orderId
      );

      // 2) Merge them into local state
      setPrintedCodes((prev) => {
        const updated = [...prev, ...newCodes];
        // *** Notify the parent if provided ***
        if (onCodesUpdated) {
          onCodesUpdated(updated);
        }
        return updated;
      });

      // 3) We'll only print the *last* code (or loop over them if desired).
      const lastCodeObj = newCodes[newCodes.length - 1];
      const { code } = lastCodeObj;

      // 4) Load user’s label settings
      const labelSettings = loadItemLabelSettings(businessId);
      const [widthIn, heightIn] = labelSettings.size.split("x").map(Number);
      const DPI = 96;
      const dimensions = {
        widthPx: widthIn * DPI,
        heightPx: heightIn * DPI,
        widthIn,
        heightIn,
      };

      // 5) Build the label's HTML string (await QR code)
      const labelHTML = await createLabelHTML(
        labelSettings,
        item,
        code,
        dimensions
      );

      // 6) Generate PDF (using the HTML string, so no DOM element is displayed)
      let pdfBlob = await html2pdf()
        .set({
          margin: 0,
          filename: "item_label.pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            scale: 4,
            backgroundColor: "#FFFFFF",
            width: dimensions.widthPx,
            height: dimensions.heightPx,
            useCORS: true,
          },
          jsPDF: {
            unit: "in",
            format: [dimensions.widthIn, dimensions.heightIn],
            orientation: labelSettings.orientation,
            compress: true,
          },
        })
        .from(labelHTML) // Pass the raw HTML string
        .outputPdf("blob");

      // 7) OPTIONAL: Open the PDF in a new tab for review.
      // If you do NOT want the user to see the PDF, remove or comment out below:
      const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfFile);
      window.open(pdfUrl, "_blank");

      // 8) QZ Tray printing (optional)
      const printers = localStorage.getItem(`printers_${businessId}`);
      console.log("Stored printers:", printers);
      const qzPrinter = printers
        ? JSON.parse(printers).individualItemLabels
        : null;
      console.log("QZ Tray printer:", qzPrinter);

      if (qzPrinter) {
        console.log("Printing item label via QZ Tray...");
        
        // Convert PDF to base64
        const pdfBase64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.onerror = reject;
          reader.readAsDataURL(pdfFile);
        });

        // QZ config
        const printConfig = {
          size: { width: dimensions.widthIn, height: dimensions.heightIn },
          units: "in",
          orientation: labelSettings.orientation,
          density: "best",
          interpolation: "nearest-neighbor",
          margins: { top: 0, right: 0, bottom: 0, left: 0 },
          rasterize: "false",
        };
        
        const printData = [
          {
            type: "pixel",
            format: "pdf",
            flavor: "base64",
            data: pdfBase64,
          }
        ];

        // Use our robust QZ Tray manager for printing
        if (!qzIsConnected) {
          await qzConnect();
        }
        
        await qzPrint(qzPrinter, printConfig, printData);
      } else {
        // (9) Show a Snackbar if no printer is set
        setSnackbarContent(
          <Box>
            No printer configured. Configure your printer in{" "}
            <Link
              href={`/business/${businessId}/settings/printing-settings`}
              target="_blank"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "white", // Because our Alert background is black
                "&:hover": { color: "rgba(255, 255, 255, 0.8)" },
              }}
            >
              Printing Settings
            </Link>
          </Box>
        );
        setShowSnackbar(true);
      }
    } catch (err) {
      const msg = handlePrintError(err, businessId);
      // You can also display errors in the Snackbar if you wish
      setSnackbarContent(msg);
      setShowSnackbar(true);
      console.error("Error printing item label:", err);
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <>
      <Tooltip
        title={
          hasReachedMax
            ? `Max labels (${item.max_labels}) already printed`
            : "Print Item Label"
        }
      >
        <span>
          <IconButton
            onClick={handlePrint}
            disabled={isPrinting || hasReachedMax}
            size="small"
          >
            {isPrinting ? <CircularProgress size={20} /> : <PrintIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%", backgroundColor: "black", color: "white" }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ItemPrintButton;
