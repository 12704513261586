import React, { useState, useContext } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Box,
  ButtonGroup,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CompareArrows as CompareArrowsIcon,
  Receipt as ReceiptIcon,
  Payment as PaymentIcon,
  MoreVert as MoreVertIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import ReceiptDialog from './ReceiptDialog';
import { AuthContext } from "../../../../components/Auth/AuthContext";

const CollaborationActions = ({
  collaboration,
  businessId, // Passed in camelCase
  orderStatus,
  onActionComplete,
  disabled = false,
  allCollaborations = [],
  onStartAgain,
}) => {
  // We'll build a local snake_case version for URL usage
  const snakeBusinessId = businessId; // rename in the path to match :business_id

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [billingMenuAnchorEl, setBillingMenuAnchorEl] = useState(null);
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);
  const [isBillingDialogOpen, setIsBillingDialogOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(collaboration?.payment_status || 'unpaid');
  const { api } = useContext(AuthContext);

  const isOriginatingBusiness =
    snakeBusinessId === collaboration.originating_business_id;
  const isCollaboratingBusiness =
    snakeBusinessId === collaboration.collaborating_business_id;

  const handleAction = async (action) => {
    setActionType(action);
    setConfirmDialog(true);
  };

  const handleClose = () => {
    setConfirmDialog(false);
    setActionType(null);
    setReason("");
    setError(null);
  };

  const handleStartAgain = () => {
    if (onStartAgain) {
      onStartAgain(collaboration);
    }
  };
  
  const handleBillingMenuOpen = (event) => {
    setBillingMenuAnchorEl(event.currentTarget);
  };
  
  const handleBillingMenuClose = () => {
    setBillingMenuAnchorEl(null);
  };
  
  const handleGenerateReceipt = () => {
    handleBillingMenuClose();
    setIsReceiptDialogOpen(true);
  };
  
  const handleUpdatePaymentStatus = async (status) => {
    handleBillingMenuClose();
    setLoading(true);
    setError(null);
    
    try {
      const response = await api.put(
        `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/payment`,
        {
          payment_status: status
        }
      );
      
      setPaymentStatus(status);
      if (onActionComplete) {
        onActionComplete(response.data.collaboration);
      }
    } catch (err) {
      console.error('Error updating payment status:', err);
      setError('Failed to update payment status');
    } finally {
      setLoading(false);
    }
  };

  const executeAction = async () => {
    setLoading(true);
    setError(null);

    try {
      let response;

      if (actionType === "delete") {
        // DELETE /collaboration/:collaboration_id/:business_id
        response = await api.delete(
          `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${snakeBusinessId}`
        );
      } else if (isOriginatingBusiness) {
        // PUT /collaboration/:collaboration_id/:business_id/originating-business-status
        if (actionType === "cancel") {
          response = await api.put(
            `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${snakeBusinessId}/originating-business-status`,
            {
              status: "cancelled",
              reason,
            }
          );
        } else {
          response = await api.put(
            `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${snakeBusinessId}/originating-business-status`,
            {
              status: actionType,
              reason,
            }
          );
        }
      } else if (isCollaboratingBusiness) {
        // Collaborating business logic
        // PUT /collaboration/:collaboration_id/:business_id/collaborating-business-status
        if (actionType === "counter_offer" || actionType === "edit") {
          // We don't do the PUT directly here; you might open an edit/counter dialog
          if (onActionComplete) {
            onActionComplete({ action: actionType, collaboration });
          }
          handleClose();
          return;
        }

        response = await api.put(
          `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/${snakeBusinessId}/collaborating-business-status`,
          {
            status: actionType,
            reason,
          }
        );
      }

      handleClose();
      if (onActionComplete) {
        onActionComplete(response.data);
      }
    } catch (err) {
      console.error("Error executing action:", err);
      setError(
        err.response?.data?.msg || "Failed to execute action. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const renderActionButtons = () => {
    const buttons = [];
    
    // Enhanced to display payment status more prominently
    const paymentIndicator = (
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Chip
          icon={collaboration.payment_status === 'paid' ? <CheckCircleIcon /> : <PaymentIcon />}
          label={`Payment: ${collaboration.payment_status || 'unpaid'}`}
          color={collaboration.payment_status === 'paid' ? 'success' : 
                 collaboration.payment_status === 'disputed' ? 'error' : 
                 collaboration.payment_status === 'partial' ? 'warning' : 'default'}
          size="small"
          sx={{ mr: 1 }}
        />
      </Box>
    );

    if (isOriginatingBusiness) {
      if (
        collaboration.status === "rejected" ||
        collaboration.status === "cancelled"
      ) {
        // Check if there's a more recent active collaboration
        const hasActiveCollaboration = allCollaborations?.some(
          (collab) =>
            collab.shipping_order_id === collaboration.shipping_order_id &&
            ["proposed", "accepted", "counter_offered", "completed"].includes(
              collab.status
            ) &&
            new Date(collab.createdAt) > new Date(collaboration.createdAt)
        );

        if (!hasActiveCollaboration) {
          if (collaboration.status === "rejected") {
            // (Optional) "Delete" button for a rejected collaboration
            // ...
          }

          buttons.push(
            <Button
              key="start-again"
              startIcon={<CompareArrowsIcon />}
              variant="contained"
              color="primary"
              onClick={handleStartAgain}
              disabled={disabled}
            >
              Start Again
            </Button>
          );
        } else {
          buttons.push(
            <Button
              key="start-again"
              startIcon={<CompareArrowsIcon />}
              variant="contained"
              color="primary"
              disabled={true}
              title="Cannot start new collaboration while there is an active one"
              sx={{ cursor: "not-allowed" }}
            >
              Active Collaboration Exists
            </Button>
          );
        }
      } else {
        switch (collaboration.status) {
          case "counter_offered":
            buttons.push(
              <ButtonGroup variant="outlined" key="counter-actions">
                <Button
                  startIcon={<CheckCircleIcon />}
                  color="success"
                  onClick={() => handleAction("accepted")}
                  disabled={disabled}
                >
                  Confirm
                </Button>
                <Button
                  startIcon={<CancelIcon />}
                  color="error"
                  onClick={() => handleAction("rejected")}
                  disabled={disabled}
                >
                  Reject
                </Button>
              </ButtonGroup>
            );
            break;

          default:
            if (
              !["accepted", "rejected", "cancelled", "completed"].includes(
                collaboration.status
              )
            ) {
              buttons.push(
                <Button
                  key="cancel"
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  color="error"
                  onClick={() => handleAction("cancel")}
                  disabled={disabled}
                >
                  Cancel
                </Button>
              );
            }
            break;
        }
      }
    } else if (isCollaboratingBusiness) {
      if (collaboration.status === "proposed") {
        buttons.push(
          <ButtonGroup variant="outlined" key="collab-actions">
            <Button
              startIcon={<CheckCircleIcon />}
              color="success"
              onClick={() => handleAction("accepted")}
              disabled={disabled}
            >
              Confirm
            </Button>
            <Button
              startIcon={<CancelIcon />}
              color="error"
              onClick={() => handleAction("rejected")}
              disabled={disabled}
            >
              Reject
            </Button>
            <Button
              startIcon={<CompareArrowsIcon />}
              color="primary"
              onClick={() => handleAction("counter_offer")}
              disabled={disabled}
            >
              Counter
            </Button>
            <Button
              startIcon={<EditIcon />}
              color="info"
              onClick={() => handleAction("edit")}
              disabled={disabled}
            >
              Edit
            </Button>
          </ButtonGroup>
        );
      }
    }

    return buttons;
  };

  const getActionTitle = (action) => {
    switch (action) {
      case "delete":
        return "Delete Rejected Collaboration";
      case "cancel":
        return "Cancel Collaboration";
      case "accepted":
        return "Confirm Collaboration";
      case "rejected":
        return "Reject Collaboration";
      case "counter_offer":
        return "Counter Offer";
      case "edit":
        return "Edit Collaboration";
      default:
        return (
          action?.replace("_", " ").charAt(0).toUpperCase() + action?.slice(1)
        );
    }
  };

  // Render billing buttons
  const renderBillingActions = () => {
    // Don't show billing options for non-completed/non-accepted collaborations
    if (!['accepted', 'completed'].includes(collaboration.status)) {
      return null;
    }
    
    // Different actions based on business role
    if (isCollaboratingBusiness) {
      // Service provider - can record payment status and generate receipts
      return (
        <Button
          startIcon={<ReceiptIcon />}
          variant="outlined"
          color="info"
          onClick={handleBillingMenuOpen}
          disabled={disabled}
        >
          Payment Management
        </Button>
      );
    } else if (isOriginatingBusiness) {
      // Service requester - can view payment status and make payments
      return (
        <Button
          startIcon={<AttachMoneyIcon />}
          variant="outlined"
          color={paymentStatus === 'paid' ? "success" : "warning"}
          onClick={handleBillingMenuOpen}
          disabled={disabled}
        >
          {paymentStatus === 'paid' ? "Payment Complete" : "Payment Options"}
        </Button>
      );
    }
    
    return null;
  };

  // If user neither originator nor collaborator, hide the action buttons
  if (!isOriginatingBusiness && !isCollaboratingBusiness) {
    return null;
  }

  return (
    <>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        {/* Display payment status prominently */}
        {['accepted', 'completed'].includes(collaboration.status) && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Chip
              icon={collaboration.payment_status === 'paid' ? <CheckCircleIcon /> : <PaymentIcon />}
              label={`Payment Status: ${collaboration.payment_status || 'unpaid'}`}
              color={collaboration.payment_status === 'paid' ? 'success' : 
                     collaboration.payment_status === 'disputed' ? 'error' : 
                     collaboration.payment_status === 'partial' ? 'warning' : 'default'}
              size="small"
            />
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          {renderActionButtons()}
          {renderBillingActions()}
        </Box>
      </Box>

      {/* Billing Menu */}
      <Menu
        anchorEl={billingMenuAnchorEl}
        open={Boolean(billingMenuAnchorEl)}
        onClose={handleBillingMenuClose}
      >
        {isCollaboratingBusiness ? (
          /* Service provider options */
          <>
            <MenuItem onClick={handleGenerateReceipt}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText>Generate Receipt</ListItemText>
            </MenuItem>
            
            <MenuItem 
              onClick={() => handleUpdatePaymentStatus('paid')}
              disabled={paymentStatus === 'paid'}
            >
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <ListItemText>Mark as Paid</ListItemText>
            </MenuItem>
            
            <MenuItem 
              onClick={() => handleUpdatePaymentStatus('unpaid')}
              disabled={paymentStatus === 'unpaid'}
            >
              <ListItemIcon>
                <PaymentIcon color="warning" />
              </ListItemIcon>
              <ListItemText>Mark as Unpaid</ListItemText>
            </MenuItem>
            
            <MenuItem 
              onClick={() => handleUpdatePaymentStatus('disputed')}
              disabled={paymentStatus === 'disputed'}
            >
              <ListItemIcon>
                <CancelIcon color="error" />
              </ListItemIcon>
              <ListItemText>Mark as Disputed</ListItemText>
            </MenuItem>
          </>
        ) : (
          /* Service requester options */
          <>
            {paymentStatus === 'paid' ? (
              <MenuItem disabled>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText>Payment Confirmed</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText>Make Payment</ListItemText>
              </MenuItem>
            )}
            
            {collaboration.receipt_number && (
              <MenuItem>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText>View Receipt</ListItemText>
              </MenuItem>
            )}
            
            {paymentStatus === 'disputed' && (
              <MenuItem disabled>
                <ListItemIcon>
                  <CancelIcon color="error" />
                </ListItemIcon>
                <ListItemText>Payment Disputed</ListItemText>
              </MenuItem>
            )}
          </>
        )}
      </Menu>
      
      {/* Receipt Dialog */}
      <ReceiptDialog
        open={isReceiptDialogOpen}
        onClose={() => setIsReceiptDialogOpen(false)}
        collaboration={collaboration}
        businessInfo={null}
      />
      
      <Dialog
        open={confirmDialog}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{getActionTitle(actionType)}</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Typography variant="body1" gutterBottom>
            Are you sure you want to {actionType?.replace("_", " ")} this
            collaboration?
          </Typography>
          <TextField
            fullWidth
            label="Reason (Optional)"
            multiline
            rows={3}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={executeAction}
            variant="contained"
            color={
              ["rejected", "cancel", "delete"].includes(actionType)
                ? "error"
                : "primary"
            }
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollaborationActions;
