import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  TextField,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Search as SearchIcon,
  Add as AddIcon,
  PersonAdd as PersonAddIcon,
  Replay as ReplayIcon,
} from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";
import { Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import styles from "./EmployeeManagement.module.css";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const EmployeeManagement = () => {
  const { api } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  const [orderBy, setOrderBy] = useState("employee_first_name");
  const [order, setOrder] = useState("asc");
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [newEmployeeAlertMessage, setNewEmployeeAlertMessage] = useState(null);
  const [existingEmployeeAlertMessage, setExistingEmployeeAlertMessage] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [editEmployee, setEditEmployee] = useState(null);
  const [deleteEmployee, setDeleteEmployee] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { businessId } = useParams();
  const [isAddEmployeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);

  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });

  const [newEmployee, setNewEmployee] = useState({
    employee_first_name: "",
    employee_last_name: "",
    employee_email: "",
    employee_phone: "",
    employee_roles: [],
    employee_hire_date: "",
    employment_status: "",
    employee_notes: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [filters, setFilters] = useState({
    role: "",
    status: "",
  });

  const EMPLOYEE_ROLES = [
    "Manager",
    "Supervisor",
    "Courier",
    "Cashier",
    "Sales Representative",
    "Customer Service",
    "Administrator",
    "Accountant",
  ];

  const { getBusinessById, fetchBusinesses, updateCurrentBusinessContext } =
    useBusiness();

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filterOption, searchQuery, itemsPerPage]);

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/employees", {
        params: {
          business_id: businessId,
        },
      });
      console.log("Fetched employees:", response.data);
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to fetch employees. Please try again.",
      });
    }
    setIsLoading(false);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const validateFields = (employee) => {
    const errors = {};

    if (!employee.employee_first_name.trim())
      errors.employee_first_name = "First Name is required";
    if (!employee.employee_last_name.trim())
      errors.employee_last_name = "Last Name is required";
    if (!employee.employee_email.trim())
      errors.employee_email = "Email is required";

    if (
      employee.employee_email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(employee.employee_email)
    ) {
      errors.employee_email = "Email is not valid";
    }

    if (!employee.employee_roles || employee.employee_roles.length === 0)
      errors.employee_roles = "At least one role is required";

    if (!employee.employee_hire_date)
      errors.employee_hire_date = "Hire date is required";

    if (!employee.employment_status)
      errors.employment_status = "Employment status is required";

    return errors;
  };

  const handleSaveEmployee = async () => {
    setExistingEmployeeAlertMessage(null);
    const errors = validateFields(editEmployee);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.put(
        `/employees/${businessId}/${editEmployee.employee_id}`,
        editEmployee
      );
      setAlertMessage({
        type: "success",
        message: "Employee updated successfully.",
      });
      fetchEmployees();
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setExistingEmployeeAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
        if (error.response.data.msg.includes("Email")) {
          setFormErrors({ employee_email: "Email already exists" });
        }
        if (error.response.data.msg.includes("Phone")) {
          setFormErrors({ employee_phone: "Phone already exists" });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to update employee. Please try again.",
        });
      }
    }
  };

  const handleSaveNewEmployee = async () => {
    // Clear previous errors and messages
    setNewEmployeeAlertMessage(null);
    setFormErrors({});

    // Client-side validation
    const errors = validateFields(newEmployee);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setNewEmployeeAlertMessage({
        type: "error",
        message: "Please fix the highlighted fields",
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post(`/employees/${businessId}`, newEmployee);
      setAlertMessage({
        type: "success",
        message: `Employee ${newEmployee.employee_first_name} ${newEmployee.employee_last_name} added successfully. Invitation email sent.`,
      });
      fetchEmployees();
      handleCloseAddEmployeeDialog();
    } catch (error) {
      console.error("Error adding employee:", error);

      // Default error message
      let errorMessage = "Failed to add employee. Please try again.";
      let fieldErrors = {};

      if (error.response?.data) {
        // Get the error message from the response
        if (error.response.data.msg) {
          errorMessage = error.response.data.msg;
        }

        // Attempt multiple known formats for field errors
        if (
          error.response.data.formErrors &&
          typeof error.response.data.formErrors === "object"
        ) {
          fieldErrors = error.response.data.formErrors;
        } else if (
          error.response.data.fieldErrors &&
          typeof error.response.data.fieldErrors === "object"
        ) {
          fieldErrors = error.response.data.fieldErrors;
        } else if (
          error.response.data.fields &&
          typeof error.response.data.fields === "object"
        ) {
          fieldErrors = error.response.data.fields;
        } else if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          error.response.data.errors.forEach((err) => {
            if (err.param) {
              fieldErrors[err.param] = err.msg;
            } else if (err.path) {
              fieldErrors[err.path] = err.msg;
            }
          });
        } else if (error.response.data.employee_email) {
          // Special case for duplicate email
          fieldErrors.employee_email = "Email already exists";
        }
      }

      // Set the alert message
      setNewEmployeeAlertMessage({
        type: "error",
        message: errorMessage,
      });

      // Set field errors for form highlighting
      setFormErrors(fieldErrors);
    } finally {
      setIsLoading(false);
    }
  };

  // Helper for changing fields and clearing errors
  const handleFieldChange = (field, value, setEmployee) => {
    setEmployee((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        [field]: value,
      };
    });

    // Clear error for this specific field
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });

    // If there's a specific alert message related to this field, clear it
    if (
      field === "employee_email" &&
      newEmployeeAlertMessage?.message?.includes("Email")
    ) {
      setNewEmployeeAlertMessage(null);
    }
    if (
      field === "employee_phone" &&
      newEmployeeAlertMessage?.message?.includes("Phone")
    ) {
      setNewEmployeeAlertMessage(null);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
    setCurrentPage(1);
  };

  // Filtered employees
  const filteredEmployees = useMemo(() => {
    return employees.filter((employee) => {
      const matchesSearch = Object.values(employee).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );
      const matchesRole =
        !filters.role ||
        (employee.employee_roles &&
          employee.employee_roles.includes(filters.role));
      const matchesStatus =
        !filters.status || employee.employment_status === filters.status;

      return matchesSearch && matchesRole && matchesStatus;
    });
  }, [employees, searchQuery, filters]);

  // Sorted employees
  const sortedEmployees = useMemo(() => {
    return [...filteredEmployees].sort((a, b) => {
      if (orderBy === "employee_roles") {
        const rolesA = (a[orderBy] || []).join(", ");
        const rolesB = (b[orderBy] || []).join(", ");
        if (rolesA < rolesB) return order === "asc" ? -1 : 1;
        if (rolesA > rolesB) return order === "asc" ? 1 : -1;
        return 0;
      }
      if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredEmployees, orderBy, order]);

  // Paginated employees
  const paginatedEmployees = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedEmployees.slice(start, start + itemsPerPage);
  }, [sortedEmployees, currentPage, itemsPerPage]);

  // Unique roles and statuses (for filter dropdowns)
  const uniqueRoles = useMemo(() => {
    const allRoles = employees.reduce((acc, employee) => {
      if (employee.employee_roles && Array.isArray(employee.employee_roles)) {
        return [...acc, ...employee.employee_roles];
      }
      return acc;
    }, []);
    return [...new Set(allRoles)].filter(Boolean);
  }, [employees]);

  const uniqueStatuses = useMemo(() => {
    return [...new Set(employees.map((e) => e.employment_status))].filter(
      Boolean
    );
  }, [employees]);

  // Pagination controls
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1);
  };

  // Invitation resend
  const handleResendInvitation = async (employee) => {
    try {
      await api.post(`/employees/${businessId}/resend-invitation`, {
        employeeId: employee.employee_id,
      });
      setAlertMessage({
        type: "success",
        message: "Invitation resent successfully.",
      });
    } catch (error) {
      console.error("Error resending invitation:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to resend invitation. Please try again.",
      });
    }
  };

  // Dialog / edit / delete handling
  const handleEditEmployee = (employee) => {
    setEditEmployee(employee);
    setIsDialogOpen(true);
  };

  const handleDeleteEmployee = (employee) => {
    setDeleteEmployee(employee);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setExistingEmployeeAlertMessage(null);
    setNewEmployeeAlertMessage(null);
    setIsDialogOpen(false);
    setEditEmployee(null);
    setDeleteEmployee(null);
  };

  const handleAddEmployee = () => {
    setIsAddEmployeeDialogOpen(true);
  };

  const handleCloseAddEmployeeDialog = () => {
    setNewEmployeeAlertMessage(null);
    setFormErrors({});
    setIsAddEmployeeDialogOpen(false);
    setNewEmployee({
      employee_first_name: "",
      employee_last_name: "",
      employee_email: "",
      employee_phone: "",
      employee_roles: [],
      employee_hire_date: "",
      employment_status: "",
      employee_notes: "",
    });
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(
        `/employees/${businessId}/${deleteEmployee.employee_id}`
      );
      setAlertMessage({
        type: "success",
        message: "Employee deleted successfully.",
      });
      fetchEmployees();
    } catch (error) {
      console.error("Error deleting employee:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete employee. Please try again.",
      });
    }
    handleCloseDialog();
  };

  // Reusable multi-select for roles
  const renderRolesField = (employee, setEmployee, isEditMode = false) => (
    <FormControl
      fullWidth
      margin="dense"
      error={!!formErrors.employee_roles}
      disabled={employee?.employee_roles?.includes("Owner")}
    >
      <InputLabel>Roles</InputLabel>
      <Select
        multiple
        value={employee?.employee_roles || []}
        onChange={(e) =>
          handleFieldChange("employee_roles", e.target.value, setEmployee)
        }
        renderValue={(selected) => selected.join(", ")}
      >
        {EMPLOYEE_ROLES.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
      {formErrors.employee_roles && (
        <FormHelperText error>{formErrors.employee_roles}</FormHelperText>
      )}
    </FormControl>
  );

  // Table headers
  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        {[
          { id: "employee_first_name", label: "First Name" },
          { id: "employee_last_name", label: "Last Name" },
          { id: "employee_email", label: "Email" },
          { id: "employee_phone", label: "Phone" },
          { id: "employee_roles", label: "Roles" },
          { id: "employee_hire_date", label: "Hire Date" },
          { id: "employment_status", label: "Status" },
          { id: "employee_status", label: "Employee Status" },
        ].map((column) => (
          <TableCell key={column.id}>
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : "asc"}
              onClick={() => handleRequestSort(column.id)}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  // Table body
  const renderTableBody = () => (
    <TableBody>
      {paginatedEmployees.map((employee) => (
        <TableRow key={employee.employee_id}>
          <TableCell>{employee.employee_first_name}</TableCell>
          <TableCell>{employee.employee_last_name}</TableCell>
          <TableCell>{employee.employee_email}</TableCell>
          <TableCell>{employee.employee_phone}</TableCell>
          <TableCell>{employee.employee_roles?.join(", ") || ""}</TableCell>
          <TableCell>{employee.employee_hire_date}</TableCell>
          <TableCell>{employee.employment_status}</TableCell>
          <TableCell>{employee.employee_status}</TableCell>
          <TableCell>
            {employee.employee_status === "pending" && (
              <Tooltip
                title="Resend Invitation"
                className={styles["icon-button"]}
              >
                <IconButton onClick={() => handleResendInvitation(employee)}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Edit" className={styles["icon-button"]}>
              <IconButton onClick={() => handleEditEmployee(employee)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Delete"
              className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
            >
              <IconButton
                onClick={() => handleDeleteEmployee(employee)}
                disabled={
                  employee.employee_roles &&
                  employee.employee_roles.includes("Owner")
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  // Edit Employee Dialog
  const renderEditEmployeeDialog = () => (
    <Dialog open={isDialogOpen && editEmployee} onClose={handleCloseDialog}>
      <DialogTitle>Edit Employee</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="First Name"
          fullWidth
          required
          value={editEmployee?.employee_first_name || ""}
          error={!!formErrors.employee_first_name}
          disabled={!!editEmployee?.user_id}
          helperText={formErrors.employee_first_name}
          onChange={(e) =>
            handleFieldChange(
              "employee_first_name",
              e.target.value,
              setEditEmployee
            )
          }
        />
        <TextField
          margin="dense"
          label="Last Name"
          fullWidth
          required
          value={editEmployee?.employee_last_name || ""}
          error={!!formErrors.employee_last_name}
          disabled={!!editEmployee?.user_id}
          helperText={formErrors.employee_last_name}
          onChange={(e) =>
            handleFieldChange(
              "employee_last_name",
              e.target.value,
              setEditEmployee
            )
          }
        />
        <TextField
          margin="dense"
          label="Email"
          fullWidth
          required
          value={editEmployee?.employee_email || ""}
          error={!!formErrors.employee_email}
          helperText={formErrors.employee_email}
          // Disable if user_id is non-null
          disabled={!!editEmployee?.user_id}
          onChange={(e) =>
            handleFieldChange("employee_email", e.target.value, setEditEmployee)
          }
        />
        <MuiTelInput
          margin="dense"
          label="Phone"
          fullWidth
          value={editEmployee?.employee_phone || ""}
          error={!!formErrors.employee_phone}
          helperText={formErrors.employee_phone}
          // Disable if user_id is non-null
          disabled={!!editEmployee?.user_id}
          onChange={(value) =>
            handleFieldChange("employee_phone", value, setEditEmployee)
          }
        />
        {renderRolesField(editEmployee, setEditEmployee, true)}
        <TextField
          margin="dense"
          label="Hire Date"
          type="date"
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          value={editEmployee?.employee_hire_date || ""}
          error={!!formErrors.employee_hire_date}
          helperText={formErrors.employee_hire_date}
          onChange={(e) =>
            handleFieldChange(
              "employee_hire_date",
              e.target.value,
              setEditEmployee
            )
          }
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Employment Status</InputLabel>
          <Select
            value={editEmployee?.employment_status || ""}
            onChange={(e) =>
              handleFieldChange(
                "employment_status",
                e.target.value,
                setEditEmployee
              )
            }
            error={!!formErrors.employment_status}
          >
            <MenuItem value="full-time">Full Time</MenuItem>
            <MenuItem value="part-time">Part Time</MenuItem>
            <MenuItem value="contract">Contract</MenuItem>
            <MenuItem value="intern">Intern</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Notes"
          fullWidth
          multiline
          rows={4}
          value={editEmployee?.employee_notes || ""}
          onChange={(e) =>
            handleFieldChange("employee_notes", e.target.value, setEditEmployee)
          }
        />
        {existingEmployeeAlertMessage && (
          <Alert
            severity={existingEmployeeAlertMessage.type}
            variant="outlined"
            sx={{ mb: 2, mt: 2 }}
            onClose={() => setExistingEmployeeAlertMessage(null)}
          >
            {existingEmployeeAlertMessage.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <IconButton
          aria-label="save"
          onClick={handleSaveEmployee}
          className={styles["icon-button"]}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          aria-label="cancel"
          onClick={handleCloseDialog}
          className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
        >
          <CancelIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );

  // Add Employee Dialog
  const renderAddEmployeeDialog = () => (
    <Dialog
      open={isAddEmployeeDialogOpen}
      onClose={handleCloseAddEmployeeDialog}
    >
      <DialogTitle>Add New Employee</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              margin="dense"
              label="First Name"
              fullWidth
              required
              value={newEmployee.employee_first_name}
              error={!!formErrors.employee_first_name}
              helperText={formErrors.employee_first_name || ""}
              onChange={(e) =>
                handleFieldChange(
                  "employee_first_name",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Last Name"
              fullWidth
              required
              value={newEmployee.employee_last_name}
              error={!!formErrors.employee_last_name}
              helperText={formErrors.employee_last_name || ""}
              onChange={(e) =>
                handleFieldChange(
                  "employee_last_name",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Email"
              fullWidth
              required
              value={newEmployee.employee_email}
              error={!!formErrors.employee_email}
              helperText={formErrors.employee_email || ""}
              // For new employees, user_id doesn't exist yet, so no need to disable
              onChange={(e) =>
                handleFieldChange(
                  "employee_email",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              margin="dense"
              label="Phone"
              fullWidth
              value={newEmployee.employee_phone}
              error={!!formErrors.employee_phone}
              helperText={formErrors.employee_phone || ""}
              // For new employees, user_id doesn't exist yet, so no need to disable
              onChange={(value) =>
                handleFieldChange("employee_phone", value, setNewEmployee)
              }
            />
          </Grid>
          <Grid item xs={12}>
            {renderRolesField(newEmployee, setNewEmployee)}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              label="Hire Date"
              type="date"
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              value={newEmployee.employee_hire_date}
              error={!!formErrors.employee_hire_date}
              helperText={formErrors.employee_hire_date || ""}
              onChange={(e) =>
                handleFieldChange(
                  "employee_hire_date",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="dense"
              error={!!formErrors.employment_status}
            >
              <InputLabel>Employment Status</InputLabel>
              <Select
                value={newEmployee.employment_status}
                onChange={(e) =>
                  handleFieldChange(
                    "employment_status",
                    e.target.value,
                    setNewEmployee
                  )
                }
              >
                <MenuItem value="full-time">Full Time</MenuItem>
                <MenuItem value="part-time">Part Time</MenuItem>
                <MenuItem value="contract">Contract</MenuItem>
                <MenuItem value="intern">Intern</MenuItem>
              </Select>
              {formErrors.employment_status && (
                <FormHelperText error>
                  {formErrors.employment_status}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Notes"
              fullWidth
              multiline
              rows={4}
              value={newEmployee.employee_notes}
              onChange={(e) =>
                handleFieldChange(
                  "employee_notes",
                  e.target.value,
                  setNewEmployee
                )
              }
            />
          </Grid>
        </Grid>
        {newEmployeeAlertMessage && (
          <Alert
            severity={newEmployeeAlertMessage.type}
            onClose={() => setNewEmployeeAlertMessage(null)}
            variant="outlined"
            sx={{
              mb: 2,
              mt: 2,
              width: "100%",
              "& .MuiAlert-message": { width: "100%" },
            }}
          >
            {newEmployeeAlertMessage.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2 }}>
        <Button
          onClick={handleCloseAddEmployeeDialog}
          color="secondary"
          variant="outlined"
          startIcon={<CancelIcon />}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveNewEmployee}
          color="primary"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Save Employee"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Final return
  return (
    <div className="business-page">
      <main className="business-page-content">
        <h1 className={styles.title}>Manage Employees</h1>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => setAlertMessage(null)}
          >
            {alertMessage.message}
          </Alert>
        )}
        <Button
          variant="outlined"
          startIcon={<PersonAddIcon />}
          onClick={handleAddEmployee}
          className={styles["add-employee-IconButton"]}
          sx={{
            display: "block",
            mt: 4,
            mb: 4,
            color: "midnightblue",
            borderColor: "midnightblue",
            "&:hover": {
              color: "var(--soft-blue)",
              borderColor: "var(--soft-blue)",
            },
            cursor: "pointer",
          }}
        >
          Add Employee
        </Button>

        <div className={styles["filter-search"]}>
          <TextField
            name="search"
            label="Search Employees"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            className={styles["search-field"]}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
          <FormControl className={styles["filter-control"]}>
            <InputLabel id="role-filter-label">Role</InputLabel>
            <Select
              labelId="role-filter-label"
              value={filters.role}
              onChange={(e) => handleFilterChange("role", e.target.value)}
              label="Role"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueRoles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles["filter-control"]}>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={styles["items-per-page"]}>
            <InputLabel id="items-per-page-label">Items Per Page</InputLabel>
            <Select
              labelId="items-per-page-label"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items Per Page"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>

        <TableContainer
          component={Paper}
          sx={{
            mt: 2,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            width: "90%",
          }}
        >
          <Table>
            {renderTableHeader()}
            {renderTableBody()}
          </Table>
        </TableContainer>

        <Pagination
          count={Math.ceil(sortedEmployees.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          className={styles.pagination}
        />
      </main>

      {/* Delete Employee Dialog */}
      <Dialog open={isDialogOpen && deleteEmployee} onClose={handleCloseDialog}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {deleteEmployee?.employee_first_name}{" "}
            {deleteEmployee?.employee_last_name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="delete"
            onClick={handleConfirmDelete}
            className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label="cancel"
            onClick={handleCloseDialog}
            className={styles["icon-button"]}
          >
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/* Edit Employee Dialog */}
      {renderEditEmployeeDialog()}

      {/* Add Employee Dialog */}
      {renderAddEmployeeDialog()}
    </div>
  );
};

export default EmployeeManagement;
