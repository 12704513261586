import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Button,
  MenuItem,
  Box,
  Switch,
  Collapse,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  CircularProgress,
  Paper,
  Divider,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  DeleteForever as DeleteForeverIcon,
  PlayArrow as StartIcon,
  Check as CompleteIcon,
  Cancel as CancelIcon,
  Visibility as ViewIcon,
  Receipt as InvoiceIcon,
  Undo as ReturnIcon,
  Refresh as ReactivateIcon,
  NoteAdd as AddNoteIcon,
  Search as SearchIcon,
  GetApp as ExportIcon,
  MoreVert as MoreVertIcon,
  Print as PrintIcon,
  Update as UpdateIcon,
  GroupWork as GroupIcon,
  Widgets as WidgetsIcon,
  ArrowDropDown as ArrowDropDownIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Add as AddIcon,
  Handshake as CollaborationIcon,
  Payment as PaymentIcon,
} from "@mui/icons-material";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import { AuthContext } from "../../../../components/Auth/AuthContext"; // <-- Import AuthContext
import { useOrders } from "./OrdersContext";
import {
  useOrderActions,
  OrderActionMenu,
} from "../../../../components/Actions/ShippingOrderActions";
import GroupActionMenu from "../../../../components/Actions/GroupActionMenu";
// Replace the multi-order print dialog with the Bill of Lading dialog:
import BillOfLadingDialog from "./BillOfLadingDialog";
import CollaborationComponent from "./CollaborationComponent";
import CollaborationDetailsDialog from "./CollaborationDetailsDialog";

const STATUS_TRANSITIONS = {
  pending: ["processing", "cancelled"],
  processing: ["shipped", "cancelled"],
  shipped: ["out_for_delivery", "delivered", "cancelled"],
  out_for_delivery: ["delivered", "cancelled"],
  delivered: [], // Terminal state
  cancelled: [], // Terminal state
};

const OrderList = ({
  onCompleteOrder,
  onDeleteDraftOrder,
  initialSearchQuery,
  initialFilter,
}) => {
  // -----------------------------------------------------------
  // 1) Basic Hooks & Context
  // -----------------------------------------------------------
  const { businessId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { api } = useContext(AuthContext); // <-- useContext for your Auth
  const {
    orders,
    groups,
    loading,
    error,
    updateOrderStatus,
    addOrderToGroup,
    removeOrderFromGroup,
    createGroup,
    updateGroup,
    deleteGroup,
    setOrders,
    deleteOrderFromLocalStorage,
    fetchOrders,
    couriers,
  } = useOrders();

  const tableRef = useRef(null);
  const { handleAction } = useOrderActions(setOrders);

  // -----------------------------------------------------------
  // 2) Payment Method Check States
  // -----------------------------------------------------------
  const [checkingPaymentMethod, setCheckingPaymentMethod] = useState(true);
  const [hasValidPaymentMethod, setHasValidPaymentMethod] = useState(false);

  // We'll show this warning if user clicks Collaboration but lacks a valid payment method
  const [noPaymentMethodWarning, setNoPaymentMethodWarning] = useState(false);
  // Check business payment info once on mount
  useEffect(() => {
    if (!businessId) return;

    const checkPaymentMethod = async () => {
      try {
        setCheckingPaymentMethod(true);
        const response = await api.get(
          `/payments/business-payment-methods?businessId=${businessId}`
        );

        if (response.data) {
          // Check if the business has any payment methods
          setHasValidPaymentMethod(
            response.data.hasPaymentMethods ||
              (response.data.paymentMethods &&
                response.data.paymentMethods.length > 0)
          );
        }
      } catch (err) {
        console.error("Error checking payment method:", err);
      } finally {
        setCheckingPaymentMethod(false);
      }
    };

    checkPaymentMethod();
  }, [businessId, api]);

  // -----------------------------------------------------------
  // 3) Existing Local States
  // -----------------------------------------------------------
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filters, setFilters] = useState({
    status: initialFilter || "",
    serviceLevel: "",
    orderCount: "",
    isAssigned: "",
    employee: "",
    orderType: "all",
  });
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isGroupedView, setIsGroupedView] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [groupMenuAnchorEl, setGroupMenuAnchorEl] = useState(null);
  const [activeOrderForGroupMenu, setActiveOrderForGroupMenu] = useState(null);
  const [loadingOrderId, setLoadingOrderId] = useState(null);

  // Bulk-action states
  const [isBulkStatusDialogOpen, setIsBulkStatusDialogOpen] = useState(false);
  const [isMultiPrintDialogOpen, setIsMultiPrintDialogOpen] = useState(false);
  const [groupManagementAnchorEl, setGroupManagementAnchorEl] = useState(null);

  // Collaboration states
  const [isCollaborationDetailsOpen, setIsCollaborationDetailsOpen] =
    useState(false);
  const [selectedCollaborations, setSelectedCollaborations] = useState([]);
  const [selectedCollaboration, setSelectedCollaboration] = useState(null);
  const [isCollaborationComponentOpen, setIsCollaborationComponentOpen] =
    useState(false);
  const [currentOrderForCollaboration, setCurrentOrderForCollaboration] =
    useState(null);

  // Draft menu states
  const [draftMenuAnchorEl, setDraftMenuAnchorEl] = useState(null);
  const [activeDraftOrder, setActiveDraftOrder] = useState(null);

  // Sort config
  const [sortConfig, setSortConfig] = useState({
    field: "createdAt",
    direction: "desc",
  });
  const sortOptions = [
    {
      value: "createdAt_desc",
      label: "Newest First",
      field: "createdAt",
      direction: "desc",
    },
    {
      value: "createdAt_asc",
      label: "Oldest First",
      field: "createdAt",
      direction: "asc",
    },
    {
      value: "total_cost_desc",
      label: "Highest Cost",
      field: "total_cost",
      direction: "desc",
    },
    {
      value: "total_cost_asc",
      label: "Lowest Cost",
      field: "total_cost",
      direction: "asc",
    },
    {
      value: "order_status_asc",
      label: "Status (A-Z)",
      field: "order_status",
      direction: "asc",
    },
    {
      value: "order_status_desc",
      label: "Status (Z-A)",
      field: "order_status",
      direction: "desc",
    },
  ];

  // -----------------------------------------------------------
  // 4) Data Fetching
  // -----------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      if (!businessId) return;
      try {
        const needsFetch = !orders.length || !couriers.length;
        const isNestedRoute = location.pathname.split("/").length > 4;
        if (needsFetch || isNestedRoute) {
          await Promise.all([
            fetchOrders(businessId),
            // ... any other initial fetch calls ...
          ]);
        }
      } catch (fetchError) {
        console.error("Error fetching data in OrderList:", fetchError);
      }
    };
    fetchData();
  }, [
    businessId,
    location.pathname,
    orders.length,
    couriers.length,
    fetchOrders,
  ]);

  // -----------------------------------------------------------
  // 5) Collaboration-Related Handlers
  // -----------------------------------------------------------
  const getLatestCollaboration = (collaborations) => {
    if (!collaborations || collaborations.length === 0) return null;
    return collaborations.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0];
  };

  // Single-order “Collaboration” chip:
  const handleOpenCollaborationComponent = (orderId) => {
    // Check if user has a valid payment method first
    if (!hasValidPaymentMethod) {
      // Show a warning; do not open collaboration
      setNoPaymentMethodWarning(true);
      return;
    }

    const order = orders.find((o) => o.shipping_order_id === orderId);
    if (!order) return;

    if (order.ShippingCollaborations?.length > 0) {
      const latestCollaboration = getLatestCollaboration(
        order.ShippingCollaborations
      );
      const isActive = [
        "proposed",
        "accepted",
        "counter_offered",
        "completed",
      ].includes(latestCollaboration.status);

      if (isActive) {
        // They already have an active collaboration => normal warning
        alert("Cannot start new collaboration while there is an active one.");
        return;
      }
    }

    setSelectedOrders([orderId]);
    setCurrentOrderForCollaboration(order);
    setIsCollaborationComponentOpen(true);
  };

  // Bulk “Collaborate” button:
  const handleBulkAction = (action, event) => {
    switch (action) {
      case "updateStatus":
        setIsBulkStatusDialogOpen(true);
        break;
      case "delete":
        console.log("Deleting orders:", selectedOrders);
        // Implement delete logic here
        break;
      case "print":
        setIsMultiPrintDialogOpen(true);
        break;
      case "manageGroups":
        setGroupManagementAnchorEl(event.currentTarget);
        break;
      case "removeFromGroups":
        handleRemoveFromAllGroups(selectedOrders);
        break;
      case "collaborate":
        // Check payment method
        if (!hasValidPaymentMethod) {
          setNoPaymentMethodWarning(true);
          return;
        }
        if (isCollaborateDisabled) {
          setIneligibleOrderWarning(
            "Some selected orders are not pending or already have collaborators. Please deselect these orders to proceed."
          );
          return;
        }
        setIneligibleOrderWarning(""); // Clear any existing warning
        setIsCollaborationComponentOpen(true);
        break;
      default:
        console.log(`Unhandled bulk action: ${action}`);
    }
  };

  // Close and refresh
  const silentRefreshOrderData = useCallback(async () => {
    try {
      await fetchOrders(businessId, false);
    } catch (error) {
      console.error("Failed to refresh orders:", error);
    }
  }, [businessId, fetchOrders]);

  const handleCloseCollaborationComponent = async (wasUpdated = false) => {
    setIsCollaborationComponentOpen(false);
    setCurrentOrderForCollaboration(null);
    if (wasUpdated) {
      console.log("Collaboration was updated, refreshing orders...");
      await silentRefreshOrderData();
    }
  };

  // Check if a user can collaborate or not (for bulk)
  const isCollaborateDisabled = useMemo(() => {
    return selectedOrders.some((orderId) => {
      const order = orders.find((o) => o.shipping_order_id === orderId);
      if (!order || order.order_status !== "pending") return true;
      if (order.ShippingCollaborations?.length > 0) {
        const latestCollab = getLatestCollaboration(
          order.ShippingCollaborations
        );
        const isActive = [
          "proposed",
          "accepted",
          "counter_offered",
          "completed",
        ].includes(latestCollab.status);
        return isActive;
      }
      return false;
    });
  }, [selectedOrders, orders]);

  // -----------------------------------------------------------
  // 6) Refresh, Sorting, Filters, Pagination
  // -----------------------------------------------------------
  const refreshOrderData = useCallback(async () => {
    try {
      await fetchOrders(businessId, true); // Pass true to force refresh
    } catch (error) {
      console.error("Failed to refresh orders:", error);
    }
  }, [businessId, fetchOrders]);

  // Searching & Filtering
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const toggleGroupView = () => {
    setIsGroupedView(!isGroupedView);
    setCurrentPage(1);
  };

  // -----------------------------------------------------------
  // 7) Handling Orders vs. Groups (expanded/collapsed)
  // -----------------------------------------------------------
  const handleExpandGroup = (groupId) => {
    setExpandedGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
  };

  // Single order click => navigate or open draft
  const handleOrderClick = useCallback(
    (orderId) => {
      const order = orders.find((o) => o.shipping_order_id === orderId);
      if (order && order.order_status === "draft") {
        // If draft => jump to your "complete order" logic
        onCompleteOrder(order);
      } else {
        // Otherwise, route to order detail
        const currentPath = location.pathname;
        navigate(`${currentPath}/${orderId}`);
      }
    },
    [orders, navigate, onCompleteOrder, location.pathname]
  );

  // -----------------------------------------------------------
  // 8) Group Management
  // -----------------------------------------------------------
  const handleCreateGroup = async () => {
    try {
      await createGroup({ name: "New Group" }, businessId);
    } catch (err) {
      console.error("Failed to create group:", err);
    }
  };

  const handleAddOrderToGroup = async (groupId, orderId) => {
    try {
      // Remove from old group if needed
      const currentGroup = groups.find((group) =>
        group.ShippingOrders.some((o) => o.shipping_order_id === orderId)
      );
      if (currentGroup) {
        await removeOrderFromGroup(currentGroup.group_id, orderId, businessId);
      }
      await addOrderToGroup(groupId, orderId, businessId);
    } catch (err) {
      console.error("Failed to add order to group:", err);
    }
  };

  const handleRemoveOrderFromGroup = async (groupId, orderId) => {
    try {
      await removeOrderFromGroup(groupId, orderId, businessId);
    } catch (err) {
      console.error("Failed to remove order from group:", err);
    }
  };

  const handleRemoveFromAllGroups = async (orderIds) => {
    try {
      for (const orderId of orderIds) {
        const groupsContainingOrder = groups.filter((g) =>
          g.ShippingOrders.some((o) => o.shipping_order_id === orderId)
        );
        for (const group of groupsContainingOrder) {
          await removeOrderFromGroup(group.group_id, orderId, businessId);
        }
      }
    } catch (err) {
      console.error("Failed to remove orders from groups:", err);
    }
  };

  const handleGroupMenuOpen = (event, orderId) => {
    setGroupMenuAnchorEl(event.currentTarget);
    setActiveOrderForGroupMenu(orderId);
  };
  const handleGroupMenuClose = () => {
    setGroupMenuAnchorEl(null);
    setActiveOrderForGroupMenu(null);
  };

  const handleChangeOrderGroup = async (groupId) => {
    if (activeOrderForGroupMenu) {
      try {
        const currentGroup = groups.find((g) =>
          g.ShippingOrders.some(
            (o) => o.shipping_order_id === activeOrderForGroupMenu
          )
        );
        if (currentGroup) {
          await removeOrderFromGroup(
            currentGroup.group_id,
            activeOrderForGroupMenu,
            businessId
          );
        }
        if (groupId) {
          await addOrderToGroup(groupId, activeOrderForGroupMenu, businessId);
        }
      } catch (err) {
        console.error("Failed to change order group:", err);
      }
    }
    handleGroupMenuClose();
  };

  const handleAddToExistingGroup = (groupId) => {
    selectedOrders.forEach((orderId) => {
      handleAddOrderToGroup(groupId, orderId);
    });
    handleGroupManagementClose();
  };

  const handleCreateNewGroupWithOrders = async () => {
    try {
      const groupCount = groups.length;
      const newGroupName = `Group ${groupCount + 1}`;
      const newGroup = await createGroup({ name: newGroupName }, businessId);
      selectedOrders.forEach((orderId) => {
        handleAddOrderToGroup(newGroup.group_id, orderId);
      });
      handleGroupManagementClose();
    } catch (err) {
      console.error("Failed to create new group and add orders:", err);
    }
  };

  const handleGroupManagementClose = () => {
    setGroupManagementAnchorEl(null);
  };

  // -----------------------------------------------------------
  // 9) Status Updates (bulk or individual)
  // -----------------------------------------------------------
  const [statusMenuState, setStatusMenuState] = useState({
    anchorEl: null,
    orderId: null,
  });

  const setIneligibleOrderWarning = (message) => {
    alert(message);
  };

  const handleStatusMenuOpen = (event, orderId) => {
    event.preventDefault();
    event.stopPropagation();
    setStatusMenuState({
      anchorEl: event.currentTarget,
      orderId,
    });
  };
  const handleStatusMenuClose = () => {
    setStatusMenuState({ anchorEl: null, orderId: null });
  };

  const validateStatusTransition = (currentStatus, newStatus) => {
    const allowedTransitions = STATUS_TRANSITIONS[currentStatus] || [];
    return allowedTransitions.includes(newStatus);
  };

  const handleStatusChange = async (newStatus) => {
    if (!statusMenuState.orderId || !newStatus) return;
    handleStatusMenuClose();

    const currentOrder = orders.find(
      (o) => o.shipping_order_id === statusMenuState.orderId
    );
    if (!validateStatusTransition(currentOrder.order_status, newStatus)) {
      console.error("Invalid status transition");
      return;
    }

    try {
      setLoadingOrderId(statusMenuState.orderId);
      await updateOrderStatus(statusMenuState.orderId, newStatus);
      setOrders((prev) =>
        prev.map((o) =>
          o.shipping_order_id === statusMenuState.orderId
            ? { ...o, order_status: newStatus }
            : o
        )
      );
    } catch (error) {
      console.error("Failed to update order status:", error);
    } finally {
      setLoadingOrderId(null);
    }
  };

  // Bulk status
  const handleBulkStatusUpdate = async (newStatus) => {
    try {
      const updatePromises = selectedOrders.map(async (orderId) => {
        const order = orders.find((o) => o.shipping_order_id === orderId);
        if (!order) return null;

        // Only update if transition is valid
        if (!validateStatusTransition(order.order_status, newStatus)) {
          return null;
        }
        return updateOrderStatus(orderId, newStatus);
      });

      const updatedOrders = await Promise.all(updatePromises);

      setOrders((prevOrders) =>
        prevOrders.map((order) => {
          const updated = updatedOrders.find(
            (u) => u?.shipping_order_id === order.shipping_order_id
          );
          return updated ? { ...order, ...updated } : order;
        })
      );
    } catch (error) {
      console.error("Failed bulk status update:", error);
    } finally {
      setIsBulkStatusDialogOpen(false);
      setSelectedOrders([]);
    }
  };

  // Group orders status
  const handleUpdateGroupOrdersStatus = async (groupId, newStatus) => {
    try {
      const group = groups.find((g) => g.group_id === groupId);
      if (!group) return;

      const updatePromises = group.ShippingOrders.map(async (orderInfo) => {
        try {
          return await updateOrderStatus(
            orderInfo.shipping_order_id,
            newStatus
          );
        } catch (err) {
          console.error(
            `Failed to update order ${orderInfo.shipping_order_id}:`,
            err
          );
          return null;
        }
      });

      const updatedOrders = await Promise.all(updatePromises);

      setOrders((prevOrders) =>
        prevOrders.map((order) => {
          const updatedOrder = updatedOrders.find(
            (updated) => updated?.shipping_order_id === order.shipping_order_id
          );
          return updatedOrder ? { ...order, ...updatedOrder } : order;
        })
      );
    } catch (err) {
      console.error("Failed to update group orders status:", err);
    }
  };

  // -----------------------------------------------------------
  // 10) Draft Orders
  // -----------------------------------------------------------
  const handleDraftMenuOpen = (event, order) => {
    setDraftMenuAnchorEl(event.currentTarget);
    setActiveDraftOrder(order);
  };
  const handleDraftMenuClose = () => {
    setDraftMenuAnchorEl(null);
    setActiveDraftOrder(null);
  };
  const handleDraftAction = (action) => {
    if (activeDraftOrder) {
      if (action === "complete") {
        onCompleteOrder(activeDraftOrder);
      } else if (action === "delete") {
        onDeleteDraftOrder(activeDraftOrder.shipping_order_id);
      }
    }
    handleDraftMenuClose();
  };

  // -----------------------------------------------------------
  // 11) Selection
  // -----------------------------------------------------------
  const handleSelectOrder = (orderId) => {
    const order = orders.find((o) => o.shipping_order_id === orderId);
    // Prevent selecting draft orders for normal bulk actions
    if (order && order.order_status === "draft") return;
    setSelectedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handleSelectAllOrders = (event) => {
    if (event.target.checked) {
      const allOrderIds = paginatedItems
        .filter((item) => !isGroupedView && item.order_status !== "draft")
        .map((item) => item.shipping_order_id);
      setSelectedOrders(allOrderIds);
    } else {
      setSelectedOrders([]);
    }
  };

  // -----------------------------------------------------------
  // 12) Memoized Filtering & Sorting
  // -----------------------------------------------------------
  const filteredOrders = useMemo(() => {
    if (loading || !orders.length) return [];

    let result = orders;
    // Filter by order type (own vs collaborating)
    if (filters.orderType === "collaborating") {
      result = result.filter((o) => o.ShippingCollaborations?.length > 0);
    } else if (filters.orderType === "own") {
      result = result.filter((o) => !o.ShippingCollaborations?.length);
    }

    // Status
    if (filters.status) {
      result = result.filter((o) => o.order_status === filters.status);
    }

    // Service
    if (filters.serviceLevel) {
      result = result.filter(
        (o) => o.ShippingTier?.service_level === filters.serviceLevel
      );
    }

    // Assignment
    if (filters.isAssigned) {
      result = result.filter((o) => {
        const hasSegments = (o.RouteSegments || []).length > 0;
        return filters.isAssigned === "assigned" ? hasSegments : !hasSegments;
      });
    }

    // Employee
    if (filters.employee) {
      result = result.filter((o) =>
        (o.RouteSegments || []).some(
          (seg) => seg.courier_id === filters.employee
        )
      );
    }

    // Text search
    if (searchQuery) {
      const q = searchQuery.toLowerCase();
      result = result.filter(
        (o) =>
          o.shipping_order_id.toLowerCase().includes(q) ||
          o.tracking_number?.toLowerCase().includes(q) ||
          o.sender?.first_name?.toLowerCase().includes(q) ||
          o.sender?.last_name?.toLowerCase().includes(q) ||
          o.recipient?.first_name?.toLowerCase().includes(q) ||
          o.recipient?.last_name?.toLowerCase().includes(q)
      );
    }

    return result;
  }, [orders, filters, searchQuery, loading]);

  const filteredItems = useMemo(() => {
    // If grouped, we filter groups; otherwise, we filter orders
    if (loading || !orders.length) return [];
    if (!isGroupedView) return filteredOrders;

    // Group filtering
    let result = groups;
    if (filters.status) {
      result = result.filter((g) => g.status === filters.status);
    }

    // Search for group name
    if (searchQuery) {
      const q = searchQuery.toLowerCase();
      result = result.filter((g) => g.name.toLowerCase().includes(q));
    }

    return result;
  }, [
    loading,
    orders,
    groups,
    isGroupedView,
    filteredOrders,
    filters.status,
    searchQuery,
  ]);

  const sortedAndFilteredItems = useMemo(() => {
    // Sort the filtered items
    let list = [...filteredItems];
    list.sort((a, b) => {
      let aValue, bValue;
      if (isGroupedView) {
        // Sorting groups
        switch (sortConfig.field) {
          case "total_cost":
            aValue = parseFloat(a.total_value || 0);
            bValue = parseFloat(b.total_value || 0);
            break;
          case "order_status":
            aValue = a.status?.toLowerCase() || "";
            bValue = b.status?.toLowerCase() || "";
            break;
          case "createdAt":
            aValue = new Date(a.createdAt || 0);
            bValue = new Date(b.createdAt || 0);
            break;
          default:
            aValue = a[sortConfig.field];
            bValue = b[sortConfig.field];
        }
      } else {
        // Sorting orders
        switch (sortConfig.field) {
          case "total_cost":
            aValue = parseFloat(a.total_cost || 0);
            bValue = parseFloat(b.total_cost || 0);
            break;
          case "order_status":
            aValue = a.order_status?.toLowerCase() || "";
            bValue = b.order_status?.toLowerCase() || "";
            break;
          case "createdAt":
            aValue = new Date(a.createdAt || 0);
            bValue = new Date(b.createdAt || 0);
            break;
          default:
            aValue = a[sortConfig.field];
            bValue = b[sortConfig.field];
        }
      }

      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      if (sortConfig.direction === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });
    return list;
  }, [filteredItems, sortConfig, isGroupedView]);

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedAndFilteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedAndFilteredItems, currentPage, itemsPerPage]);

  // -----------------------------------------------------------
  // 13) Rendering
  // -----------------------------------------------------------
  const renderFilters = () => (
    <>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Search Orders"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by ID, name, status..."
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Order Type</InputLabel>
            <Select
              name="orderType"
              value={filters.orderType}
              onChange={handleFilterChange}
              label="Order Type"
            >
              <MenuItem value="all">All Orders</MenuItem>
              <MenuItem value="own">Own Orders</MenuItem>
              <MenuItem value="collaborating">Collaborating Orders</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="shipped">Shipped</MenuItem>
              <MenuItem value="out_for_delivery">Out for Delivery</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Assignment Status</InputLabel>
            <Select
              name="isAssigned"
              value={filters.isAssigned}
              onChange={handleFilterChange}
              label="Assignment Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="assigned">Assigned</MenuItem>
              <MenuItem value="unassigned">Unassigned</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Employee</InputLabel>
            <Select
              name="employee"
              value={filters.employee}
              onChange={handleFilterChange}
              label="Employee"
            >
              <MenuItem value="">All</MenuItem>
              {couriers?.map((c) => (
                <MenuItem key={c.employee_id} value={c.employee_id}>
                  {c.employee_first_name} {c.employee_last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Service Level</InputLabel>
            <Select
              name="serviceLevel"
              value={filters.serviceLevel}
              onChange={handleFilterChange}
              label="Service Level"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Standard">Standard</MenuItem>
              <MenuItem value="Express">Express</MenuItem>
              <MenuItem value="Priority">Priority</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Items per page</InputLabel>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per page"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={`${sortConfig.field}_${sortConfig.direction}`}
              onChange={(e) => {
                const selectedOption = sortOptions.find(
                  (opt) => opt.value === e.target.value
                );
                if (selectedOption) {
                  setSortConfig({
                    field: selectedOption.field,
                    direction: selectedOption.direction,
                  });
                }
              }}
              label="Sort By"
            >
              {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {(Object.values(filters).some((v) => v !== "") ||
        sortConfig.field !== "createdAt") && (
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Active Filters:
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {Object.entries(filters).map(([key, value]) => {
              if (!value) return null;
              return (
                <Chip
                  key={key}
                  label={`${key
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}: ${value}`}
                  onDelete={() =>
                    handleFilterChange({
                      target: { name: key, value: "" },
                    })
                  }
                  size="small"
                />
              );
            })}
            {sortConfig.field !== "createdAt" && (
              <Chip
                label={(() => {
                  const currentSortOption = sortOptions.find(
                    (opt) =>
                      opt.field === sortConfig.field &&
                      opt.direction === sortConfig.direction
                  );
                  return currentSortOption
                    ? currentSortOption.label
                    : `${sortConfig.field} ${sortConfig.direction}`;
                })()}
                onDelete={() =>
                  setSortConfig({ field: "createdAt", direction: "desc" })
                }
                size="small"
              />
            )}
            <Chip
              label="Clear All"
              onClick={() => {
                setFilters({
                  status: "",
                  serviceLevel: "",
                  orderCount: "",
                  isAssigned: "",
                  employee: "",
                  orderType: "all",
                });
                setSortConfig({ field: "createdAt", direction: "desc" });
              }}
              size="small"
              color="secondary"
            />
          </Box>
        </Box>
      )}
    </>
  );

  const renderStatusChip = (status, shipping_order_id, isGroup = false) => {
    const statusColors = {
      pending: "warning",
      processing: "primary",
      shipped: "info",
      out_for_delivery: "warning",
      delivered: "success",
      cancelled: "error",
      active: "success",
      inactive: "error",
      draft: "default",
    };

    const formatStatus = (s) =>
      s
        .split("_")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(" ");

    if (loadingOrderId === shipping_order_id) {
      return <Skeleton variant="rect" width={100} height={24} />;
    }
    if (isGroup || status === "draft") {
      return (
        <Chip
          label={formatStatus(status)}
          color={statusColors[status] || "default"}
          size="small"
        />
      );
    }
    return (
      <Box component="span" sx={{ display: "inline-block" }}>
        <Chip
          label={formatStatus(status)}
          color={statusColors[status] || "default"}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleStatusMenuOpen(e, shipping_order_id);
          }}
          sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
        />
      </Box>
    );
  };

  const getCollaborationStatusColor = (status) => {
    switch (status) {
      case "proposed":
        return "warning";
      case "counter_offered":
        return "primary";
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      case "completed":
        return "info";
      default:
        return "default";
    }
  };

  const handleViewCollaboration = (collaborationData) => {
    setSelectedCollaboration({
      allCollaborations: collaborationData.ShippingCollaborations || [],
    });
    setIsCollaborationDetailsOpen(true);
  };

  const renderCollaborationStatus = (order) => {
    if (order.order_status === "draft") {
      return (
        <Tooltip title="Order is a draft">
          <Chip icon={<CollaborationIcon />} label="None" color="default" />
        </Tooltip>
      );
    }

    if (order.ShippingCollaborations?.length > 0) {
      const latestCollaboration = getLatestCollaboration(
        order.ShippingCollaborations
      );
      const isActive = [
        "proposed",
        "accepted",
        "counter_offered",
        "completed",
      ].includes(latestCollaboration.status);

      return (
        <Tooltip
          title={`Latest Collaboration Status: ${latestCollaboration.status}${
            isActive ? " (Active)" : ""
          }`}
        >
          <Chip
            icon={<CollaborationIcon />}
            label={latestCollaboration.status}
            color={getCollaborationStatusColor(latestCollaboration.status)}
            onClick={() =>
              handleViewCollaboration({
                ...latestCollaboration,
                order_status: order.order_status,
                ShippingCollaborations: order.ShippingCollaborations,
              })
            }
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="No Collaboration">
        <Chip
          icon={<CollaborationIcon />}
          onClick={() =>
            order.order_status === "pending"
              ? handleOpenCollaborationComponent(order.shipping_order_id)
              : null
          }
          label="None"
          color="default"
          sx={{
            cursor: order.order_status === "pending" ? "pointer" : "default",
          }}
        />
      </Tooltip>
    );
  };

  const renderOrderRow = (order) => {
    const senderAddress = order.sender?.Addresses?.find(
      (addr) => addr.address_id === order.sender_address_id
    );
    const recipientAddress = order.recipient?.Addresses?.find(
      (addr) => addr.address_id === order.recipient_address_id
    );

    return (
      <TableRow key={order.shipping_order_id}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedOrders.includes(order.shipping_order_id)}
            onChange={() => handleSelectOrder(order.shipping_order_id)}
            disabled={order.order_status === "draft"}
          />
        </TableCell>
        <TableCell>
          <Button onClick={() => handleOrderClick(order.shipping_order_id)}>
            {order.shipping_order_id}
          </Button>
        </TableCell>
        <TableCell>
          {order.sender
            ? `${order.sender.first_name} ${order.sender.last_name}`
            : "N/A"}
        </TableCell>
        <TableCell>
          {senderAddress
            ? `${senderAddress.city}, ${senderAddress.state}`
            : "N/A"}
        </TableCell>
        <TableCell>
          {recipientAddress
            ? `${recipientAddress.city}, ${recipientAddress.state}`
            : "N/A"}
        </TableCell>
        <TableCell>
          {renderStatusChip(order.order_status, order.shipping_order_id)}
        </TableCell>
        <TableCell>{order.ShippingTier?.service_level}</TableCell>
        <TableCell>{renderCollaborationStatus(order)}</TableCell>
        <TableCell>
          <Chip
            label={
              groups?.find((g) =>
                g.ShippingOrders?.some(
                  (o) => o.shipping_order_id === order.shipping_order_id
                )
              )?.name || "No Group"
            }
            variant={
              groups?.find((g) =>
                g.ShippingOrders?.some(
                  (o) => o.shipping_order_id === order.shipping_order_id
                )
              )
                ? "filled"
                : "outlined"
            }
            onClick={
              order.order_status !== "draft"
                ? (event) => handleGroupMenuOpen(event, order.shipping_order_id)
                : undefined
            }
          />
        </TableCell>
        <TableCell>
          {order.order_status === "draft" ? (
            <IconButton onClick={(event) => handleDraftMenuOpen(event, order)}>
              <MoreVertIcon />
            </IconButton>
          ) : (
            <OrderActionMenu
              order={order}
              onAction={handleAction}
              isDraft={false}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderGroupRow = (group) => {
    const isExpanded = expandedGroups[group.group_id];
    return (
      <React.Fragment key={group.group_id}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedOrders.includes(group.group_id)}
              onChange={() => handleSelectOrder(group.group_id)}
            />
          </TableCell>
          <TableCell>
            <IconButton
              size="small"
              onClick={() => handleExpandGroup(group.group_id)}
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{group?.name}</TableCell>
          <TableCell>{group?.ShippingOrders.length}</TableCell>
          <TableCell>${parseFloat(group?.total_value).toFixed(2)}</TableCell>
          <TableCell>
            {new Date(group?.createdAt).toLocaleDateString()}
          </TableCell>
          <TableCell>{renderStatusChip(group?.status, true)}</TableCell>
          <TableCell>
            <GroupActionMenu
              group={group}
              onEdit={(groupId, newName) =>
                updateGroup(groupId, { name: newName }, businessId)
              }
              onDelete={(groupId) => deleteGroup(groupId, businessId)}
              onUpdateGroupStatus={(groupId, status) =>
                updateGroup(groupId, { status }, businessId)
              }
              onUpdateOrdersStatus={(groupId, status) =>
                handleUpdateGroupOrdersStatus(groupId, status)
              }
              onPrint={() => {}}
              orders={orders}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Orders in {group?.name}
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Sender</TableCell>
                      <TableCell>Recipient</TableCell>
                      <TableCell>Origin</TableCell>
                      <TableCell>Destination</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Level</TableCell>
                      <TableCell>Collaboration</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group?.ShippingOrders.map((orderInfo) => {
                      const order = orders.find(
                        (o) =>
                          o.shipping_order_id === orderInfo.shipping_order_id
                      );
                      return order ? renderOrderRow(order) : null;
                    })}
                  </TableBody>
                </Table>
                <Autocomplete
                  options={orders.filter(
                    (o) =>
                      !group?.ShippingOrders.some(
                        (go) => go.shipping_order_id === o.shipping_order_id
                      )
                  )}
                  getOptionLabel={(option) =>
                    `${option.shipping_order_id} - ${option.sender?.first_name} ${option.sender?.last_name}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Order to Group"
                      margin="normal"
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleAddOrderToGroup(
                        group.group_id,
                        newValue.shipping_order_id
                      );
                    }
                  }}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  // -----------------------------------------------------------
  // 14) The Return (JSX)
  // -----------------------------------------------------------
  if (checkingPaymentMethod) {
    // While checking for payment method, show a spinner or skeleton
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {/* Show normal warnings or errors */}
      {error && <Alert severity="error">{error}</Alert>}

      {/* Show a warning if user tries to collaborate but has no payment method */}
      {noPaymentMethodWarning && (
        <Alert
          severity="warning"
          onClose={() => setNoPaymentMethodWarning(false)}
          sx={{ mb: 2 }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => navigate(`/business/${businessId}/finances`)}
              startIcon={<PaymentIcon />}
            >
              Add Payment Method
            </Button>
          }
        >
          Collaboration is unavailable because you have **no valid payment
          method**. Please add or update your payment method to continue.
        </Alert>
      )}

      <Card>
        <CardHeader
          title={isGroupedView ? "Order Groups" : "Orders"}
          action={
            <Box display="flex" alignItems="center">
              {isGroupedView && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleCreateGroup}
                  sx={{ ml: 2 }}
                >
                  Create Group
                </Button>
              )}
              <Typography variant="body2" sx={{ ml: 1 }}>
                Group View
              </Typography>
              <Switch checked={isGroupedView} onChange={toggleGroupView} />
            </Box>
          }
        />
        <CardContent>
          {renderFilters()}

          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {selectedOrders.length} item(s) selected
            </Typography>
          </Box>

          {/* Bulk action buttons */}
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<UpdateIcon />}
              onClick={() => handleBulkAction("updateStatus")}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Update Status
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => handleBulkAction("print")}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Print
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<WidgetsIcon />}
              onClick={(event) => handleBulkAction("manageGroups", event)}
              disabled={selectedOrders.length === 0}
              sx={{ mr: 1 }}
            >
              Manage Groups
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GroupIcon />}
              onClick={(event) => handleBulkAction("collaborate", event)}
              disabled={selectedOrders.length === 0 || isCollaborateDisabled}
              sx={{ mr: 1 }}
            >
              Collaborate
            </Button>
            <CSVLink
              data={isGroupedView ? groups : filteredOrders}
              filename={isGroupedView ? "groups.csv" : "orders.csv"}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<ExportIcon />}
                disabled={paginatedItems.length === 0}
                sx={{ mr: 1 }}
              >
                Export CSV
              </Button>
            </CSVLink>
          </Box>

          {/* The main Table */}
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={handleSelectAllOrders}
                      checked={
                        selectedOrders.length === paginatedItems.length &&
                        paginatedItems.length > 0
                      }
                      indeterminate={
                        selectedOrders.length > 0 &&
                        selectedOrders.length < paginatedItems.length
                      }
                    />
                  </TableCell>
                  {isGroupedView ? (
                    <>
                      <TableCell />
                      <TableCell>Group Name</TableCell>
                      <TableCell>Order Count</TableCell>
                      <TableCell>Total Value</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>Origin</TableCell>
                      <TableCell>Destination</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Service Level</TableCell>
                      <TableCell>Collaboration</TableCell>
                      <TableCell>Group</TableCell>
                      <TableCell>Actions</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={isGroupedView ? 7 : 9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedItems.map((item) =>
                    isGroupedView ? renderGroupRow(item) : renderOrderRow(item)
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Pagination
              count={Math.ceil(
                (isGroupedView ? groups : filteredOrders).length / itemsPerPage
              )}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        </CardContent>
      </Card>

      {/* Status Change Menu */}
      <Menu
        anchorEl={statusMenuState.anchorEl}
        open={Boolean(statusMenuState.anchorEl)}
        onClose={handleStatusMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        MenuListProps={{ "aria-labelledby": "status-button" }}
        slotProps={{ paper: { sx: { maxHeight: "300px" } } }}
      >
        {[
          "pending",
          "processing",
          "shipped",
          "out_for_delivery",
          "delivered",
          "cancelled",
        ].map((status) => {
          const currentOrder = orders.find(
            (o) => o.shipping_order_id === statusMenuState.orderId
          );
          const isValid =
            currentOrder &&
            validateStatusTransition(currentOrder.order_status, status);

          return (
            <MenuItem
              key={status}
              onClick={() => handleStatusChange(status)}
              disabled={!isValid}
              sx={{
                minWidth: "150px",
                opacity: isValid ? 1 : 0.5,
              }}
            >
              {status
                .split("_")
                .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                .join(" ")}
            </MenuItem>
          );
        })}
      </Menu>

      {/* Group Change Menu */}
      <Menu
        anchorEl={groupMenuAnchorEl}
        open={Boolean(groupMenuAnchorEl)}
        onClose={handleGroupMenuClose}
      >
        {(() => {
          const currentGroup = groups.find((g) =>
            g.ShippingOrders.some(
              (o) => o.shipping_order_id === activeOrderForGroupMenu
            )
          );
          return (
            <>
              <MenuItem
                onClick={() =>
                  currentGroup &&
                  handleRemoveOrderFromGroup(
                    currentGroup.group_id,
                    activeOrderForGroupMenu
                  )
                }
                disabled={!currentGroup}
                sx={{
                  opacity: currentGroup ? 1 : 0.5,
                  "&.Mui-disabled": { color: "text.disabled" },
                }}
              >
                Remove from Group
              </MenuItem>
              <Divider />
              {groups.map((g) => (
                <MenuItem
                  key={g.group_id}
                  onClick={() => handleChangeOrderGroup(g.group_id)}
                >
                  {g.name}
                </MenuItem>
              ))}
            </>
          );
        })()}
      </Menu>

      {/* Bill of Lading Dialog for multi-print */}
      <BillOfLadingDialog
        open={isMultiPrintDialogOpen}
        onClose={() => setIsMultiPrintDialogOpen(false)}
        orders={selectedOrders.map((id) =>
          orders.find((o) => o.shipping_order_id === id)
        )}
      />

      {/* Group management menu for Bulk Actions */}
      <Menu
        anchorEl={groupManagementAnchorEl}
        open={Boolean(groupManagementAnchorEl)}
        onClose={handleGroupManagementClose}
      >
        <MenuItem onClick={handleCreateNewGroupWithOrders}>
          Create New Group
        </MenuItem>
        <MenuItem disabled>Add to Existing Group:</MenuItem>
        {groups.map((g) => (
          <MenuItem
            key={g.group_id}
            onClick={() => handleAddToExistingGroup(g.group_id)}
          >
            {g.name}
          </MenuItem>
        ))}
        <MenuItem onClick={() => handleBulkAction("removeFromGroups")}>
          Remove from Group(s)
        </MenuItem>
      </Menu>

      {/* Draft Order Menu */}
      <Menu
        anchorEl={draftMenuAnchorEl}
        open={Boolean(draftMenuAnchorEl)}
        onClose={handleDraftMenuClose}
      >
        <MenuItem onClick={() => handleDraftAction("complete")}>
          Complete Draft
        </MenuItem>
        <MenuItem onClick={() => handleDraftAction("delete")}>
          Delete Draft
        </MenuItem>
      </Menu>

      {/* Bulk Status Dialog */}
      <Dialog
        open={isBulkStatusDialogOpen}
        onClose={() => setIsBulkStatusDialogOpen(false)}
      >
        <DialogTitle>Update Status for Selected Orders</DialogTitle>
        <DialogContent>
          <List>
            {[
              "pending",
              "processing",
              "shipped",
              "out_for_delivery",
              "delivered",
              "cancelled",
            ].map((status) => (
              <ListItem
                button
                key={status}
                onClick={() => handleBulkStatusUpdate(status)}
              >
                <ListItemText>
                  {status
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBulkStatusDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Collaboration (Single or Bulk) */}
      <CollaborationComponent
        open={isCollaborationComponentOpen}
        onClose={(wasUpdated) => handleCloseCollaborationComponent(wasUpdated)}
        selectedOrders={selectedOrders.filter((id) => {
          const o = orders.find((ord) => ord.shipping_order_id === id);
          return o && o.order_status === "pending";
        })}
        businessId={businessId}
        onCollaborationUpdate={silentRefreshOrderData}
      />

      <CollaborationDetailsDialog
        open={isCollaborationDetailsOpen}
        onClose={() => {
          setIsCollaborationDetailsOpen(false);
          setSelectedCollaboration(null);
        }}
        collaborations={selectedCollaboration?.allCollaborations}
        businessId={businessId}
        onActionComplete={async () => {
          await silentRefreshOrderData();
          setIsCollaborationDetailsOpen(false);
          setSelectedCollaboration(null);
        }}
      />
    </>
  );
};

export default OrderList;
