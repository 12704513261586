import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Checkbox,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
  Pagination,
  Box,
  Card,
  CardHeader,
  CardContent,
  Menu,
  Divider,
} from "@mui/material";
import { debounce } from "lodash";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import {
  Visibility as VisibilityIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  History as HistoryIcon,
  MonetizationOn as MonetizationOnIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Payment as PaymentIcon,
  Create as CreateIcon,
  Receipt as ReceiptIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import CollaborationDetailsDialog from "./CollaborationDetailsDialog";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const CollaborationManagementScreen = ({ businessId, onRefresh }) => {
  const [collaborations, setCollaborations] = useState([]);
  const [selectedCollaborations, setSelectedCollaborations] = useState([]);
  const [selectedCollaboration, setSelectedCollaboration] = useState(null);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedCollaboration, setEditedCollaboration] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [collaborationHistory, setCollaborationHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCollaborations, setFilteredCollaborations] = useState([]);
  const [sortOrder, setSortOrder] = useState("newest");
  const [filterByBusiness, setFilterByBusiness] = useState("all");
  const [availableBusinesses, setAvailableBusinesses] = useState([]);
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0);
  const [sentCollaborations, setSentCollaborations] = useState([]);
  const [receivedCollaborations, setReceivedCollaborations] = useState([]);
  const [allCollaborations, setAllCollaborations] = useState([]);

  // Bulk action states
  const [bulkActionMenuAnchorEl, setBulkActionMenuAnchorEl] = useState(null);
  const [bulkStatusDialogOpen, setBulkStatusDialogOpen] = useState(false);
  const [bulkPaymentDialogOpen, setBulkPaymentDialogOpen] = useState(false);
  const [bulkPaymentData, setBulkPaymentData] = useState({
    payment_status: "paid",
    payment_details: {
      payment_method: "Bank Transfer",
      payment_amount: "",
      notes: "",
    },
  });
  const [actionInProgress, setActionInProgress] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const fetchCollaborations = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/shipping-collaboration/collaborations?business_id=${businessId}`
      );

      console.log("Collaborations data:", response.data);

      // Handle response format with sent/received/all collections
      if (response.data.sent && response.data.received) {
        // Store each collection separately for tab filtering
        setSentCollaborations(response.data.sent || []);
        setReceivedCollaborations(response.data.received || []);
        setAllCollaborations(
          response.data.all || [
            ...response.data.sent,
            ...response.data.received,
          ]
        );

        // Initialize with all collaborations
        setCollaborations(
          response.data.all || [
            ...response.data.sent,
            ...response.data.received,
          ]
        );
      } else {
        // Fallback for old API format if needed
        const sent = response.data.filter(
          (c) => c.originating_business_id === businessId
        );
        const received = response.data.filter(
          (c) => c.collaborating_business_id === businessId
        );

        setSentCollaborations(sent);
        setReceivedCollaborations(received);
        setAllCollaborations(response.data);
        setCollaborations(response.data);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching collaborations:", error);
      setError("Failed to fetch collaborations. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollaborations();
  }, []);

  // Clear success message after 5 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  // Extract unique businesses from all collaborations
  useEffect(() => {
    if (allCollaborations.length > 0) {
      const businesses = new Set();
      const businessesList = [];

      allCollaborations.forEach((collab) => {
        // Add businesses we're collaborating with
        if (collab.originating_business_id === businessId) {
          if (
            collab.CollaboratingBusiness &&
            !businesses.has(collab.collaborating_business_id)
          ) {
            businesses.add(collab.collaborating_business_id);
            businessesList.push({
              id: collab.collaborating_business_id,
              name: collab.CollaboratingBusiness.business_name,
              role: "collaborator",
            });
          }
        } else {
          if (
            collab.OriginatingBusiness &&
            !businesses.has(collab.originating_business_id)
          ) {
            businesses.add(collab.originating_business_id);
            businessesList.push({
              id: collab.originating_business_id,
              name: collab.OriginatingBusiness.business_name,
              role: "requester",
            });
          }
        }
      });

      setAvailableBusinesses(businessesList);
    }
  }, [allCollaborations, businessId]);

  // Switch tab content when tab changes
  useEffect(() => {
    // Set the appropriate collaborations based on active tab
    if (activeTab === 0) {
      setCollaborations(allCollaborations);
    } else if (activeTab === 1) {
      setCollaborations(sentCollaborations);
    } else if (activeTab === 2) {
      setCollaborations(receivedCollaborations);
    }

    // Reset selection and page when switching tabs
    setSelectedCollaborations([]);
    setCurrentPage(1);
  }, [
    activeTab,
    allCollaborations,
    sentCollaborations,
    receivedCollaborations,
  ]);

  // Filter collaborations whenever filters or data change
  useEffect(() => {
    if (!collaborations) return;

    let filtered = [...collaborations];

    // Apply status filter
    if (filterStatus !== "all") {
      filtered = filtered.filter((collab) => collab.status === filterStatus);
    }

    // Apply business filter
    if (filterByBusiness !== "all") {
      filtered = filtered.filter(
        (collab) =>
          collab.originating_business_id === filterByBusiness ||
          collab.collaborating_business_id === filterByBusiness
      );
    }

    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (collab) =>
          (collab.shipping_order_id &&
            collab.shipping_order_id.toLowerCase().includes(query)) ||
          (collab.CollaboratingBusiness?.business_name &&
            collab.CollaboratingBusiness.business_name
              .toLowerCase()
              .includes(query)) ||
          (collab.OriginatingBusiness?.business_name &&
            collab.OriginatingBusiness.business_name
              .toLowerCase()
              .includes(query)) ||
          (collab.collaboration_type &&
            collab.collaboration_type.toLowerCase().includes(query))
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      switch (sortOrder) {
        case "newest":
          return new Date(b.createdAt) - new Date(a.createdAt);
        case "oldest":
          return new Date(a.createdAt) - new Date(b.createdAt);
        case "compensation_high":
          return (
            parseFloat(b.agreed_compensation || 0) -
            parseFloat(a.agreed_compensation || 0)
          );
        case "compensation_low":
          return (
            parseFloat(a.agreed_compensation || 0) -
            parseFloat(b.agreed_compensation || 0)
          );
        default:
          return 0;
      }
    });

    setFilteredCollaborations(filtered);
  }, [collaborations, filterStatus, searchQuery, filterByBusiness, sortOrder]);

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredCollaborations.slice(startIndex, endIndex);
  };

  const handleResponseClick = (collaboration) => {
    setSelectedCollaboration(collaboration);
    setResponseDialogOpen(true);
  };

  const handleAcceptCollaboration = async (collaborationId) => {
    try {
      await api.put(
        `/shipping-collaboration/collaboration/${collaborationId}/${businessId}/collaborating-business-status`,
        { status: "accepted" }
      );
      fetchCollaborations();
      onRefresh();
      setSuccessMessage("Collaboration accepted successfully");
    } catch (error) {
      console.error("Error accepting collaboration:", error);
      setError("Failed to accept collaboration. Please try again.");
    }
  };

  const handleResponseSubmit = async () => {
    try {
      await api.post(
        `/shipping-collaboration/collaboration-response/${selectedCollaboration.collaboration_id}/${businessId}`,
        {
          status: responseStatus,
        }
      );
      setResponseDialogOpen(false);
      fetchCollaborations();
      onRefresh();
      setSuccessMessage(`Collaboration ${responseStatus} successfully`);
    } catch (error) {
      console.error("Error responding to collaboration:", error);
      setError("Failed to respond to collaboration. Please try again.");
    }
  };

  const handleStatusUpdate = async (collaborationId, newStatus) => {
    try {
      await api.put(
        `/shipping-collaboration/collaboration/${collaborationId}/${businessId}/collaborating-business-status`,
        { status: newStatus }
      );
      fetchCollaborations();
      onRefresh();
      setSuccessMessage(`Collaboration status updated to ${newStatus}`);
    } catch (error) {
      console.error("Error updating collaboration status:", error);
      setError("Failed to update collaboration status. Please try again.");
    }
  };

  const handleViewHistory = async (collaboration) => {
    setCollaborationHistory(collaboration.collaboration_history || []);
    setSelectedCollaboration(collaboration);
    setHistoryDialogOpen(true);
  };

  const handleEditClick = (collaboration) => {
    setSelectedCollaboration(collaboration);
    setEditedCollaboration({
      ...collaboration,
      additional_charges: collaboration.additional_charges || [],
    });
    setEditDialogOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      await api.put(
        `/shipping-collaboration/collaboration-request/${editedCollaboration.collaboration_id}/${businessId}/edit`,
        {
          agreed_compensation: editedCollaboration.agreed_compensation,
          additional_charges: editedCollaboration.additional_charges,
          special_instructions: editedCollaboration.special_instructions,
        }
      );
      setEditDialogOpen(false);
      fetchCollaborations();
      onRefresh();
      setSuccessMessage("Collaboration edited successfully");
    } catch (error) {
      console.error("Error editing collaboration:", error);
      setError("Failed to edit collaboration. Please try again.");
    }
  };

  const handleAddCharge = () => {
    setEditedCollaboration({
      ...editedCollaboration,
      additional_charges: [
        ...editedCollaboration.additional_charges,
        { description: "", amount: "" },
      ],
    });
  };

  const handleRemoveCharge = (index) => {
    const updatedCharges = editedCollaboration.additional_charges.filter(
      (_, i) => i !== index
    );
    setEditedCollaboration({
      ...editedCollaboration,
      additional_charges: updatedCharges,
    });
  };

  const handleChargeChange = (index, field, value) => {
    const updatedCharges = editedCollaboration.additional_charges.map(
      (charge, i) => (i === index ? { ...charge, [field]: value } : charge)
    );
    setEditedCollaboration({
      ...editedCollaboration,
      additional_charges: updatedCharges,
    });
  };

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setCurrentPage(1);
    }, 500),
    []
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "proposed":
        return "warning";
      case "counter_offered":
        return "info";
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      case "cancelled":
        return "error";
      case "completed":
        return "success";
      default:
        return "default";
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "success";
      case "partial":
        return "warning";
      case "disputed":
        return "error";
      case "unpaid":
      default:
        return "default";
    }
  };

  // BULK ACTIONS
  const handleBulkActionOpen = (event) => {
    setBulkActionMenuAnchorEl(event.currentTarget);
  };

  const handleBulkActionClose = () => {
    setBulkActionMenuAnchorEl(null);
  };

  // Function to determine if a bulk action is allowed based on selected collaborations
  const isBulkStatusUpdateAllowed = (status) => {
    if (selectedCollaborations.length === 0) return false;

    // Get actual collaboration objects
    const selectedItems = collaborations.filter((collab) =>
      selectedCollaborations.includes(collab.collaboration_id)
    );

    // Check eligibility based on status and business role
    return selectedItems.every((collab) => {
      const isOriginator = collab.originating_business_id === businessId;
      const isCollaborator = collab.collaborating_business_id === businessId;

      if (status === "accepted") {
        // Only collaborators can accept proposed collaborations
        return isCollaborator && collab.status === "proposed";
      }

      if (status === "rejected") {
        // Only collaborators can reject proposed collaborations
        return isCollaborator && collab.status === "proposed";
      }

      if (status === "cancelled") {
        // Only originators can cancel collaborations (any status)
        return isOriginator;
      }

      if (status === "completed") {
        // Only originators can complete accepted collaborations
        return isOriginator && collab.status === "accepted";
      }

      return false;
    });
  };

  const isBulkPaymentUpdateAllowed = (status) => {
    if (selectedCollaborations.length === 0) return false;

    // Get actual collaboration objects
    const selectedItems = collaborations.filter((collab) =>
      selectedCollaborations.includes(collab.collaboration_id)
    );

    // For marking as paid, only completed collaborations should be allowed
    if (status === "paid") {
      return selectedItems.every(
        (collab) =>
          collab.status === "completed" && collab.payment_status !== "paid"
      );
    }

    // For other payment statuses like unpaid/disputed, any collaboration can be marked
    return true;
  };

  const handleBulkAction = (action) => {
    handleBulkActionClose();

    switch (action) {
      case "accept":
        if (isBulkStatusUpdateAllowed("accepted")) {
          handleBulkStatusUpdate("accepted");
        } else {
          setError("Cannot accept some or all of the selected collaborations");
        }
        break;
      case "reject":
        if (isBulkStatusUpdateAllowed("rejected")) {
          handleBulkStatusUpdate("rejected");
        } else {
          setError("Cannot reject some or all of the selected collaborations");
        }
        break;
      case "cancel":
        if (isBulkStatusUpdateAllowed("cancelled")) {
          handleBulkStatusUpdate("cancelled");
        } else {
          setError("Cannot cancel some or all of the selected collaborations");
        }
        break;
      case "complete":
        if (isBulkStatusUpdateAllowed("completed")) {
          handleBulkStatusUpdate("completed");
        } else {
          setError(
            "Cannot complete some or all of the selected collaborations"
          );
        }
        break;
      case "markAsPaid":
        if (isBulkPaymentUpdateAllowed("paid")) {
          setBulkPaymentDialogOpen(true);
        } else {
          setError(
            "Cannot mark as paid some or all of the selected collaborations"
          );
        }
        break;
      case "markAsDisputed":
        if (isBulkPaymentUpdateAllowed("disputed")) {
          handleBulkPaymentUpdate("disputed");
        } else {
          setError(
            "Cannot mark as disputed some or all of the selected collaborations"
          );
        }
        break;
      default:
        console.log("Unknown action:", action);
    }
  };

  const handleOrderClick = (orderId) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}/${orderId}`);
  };

  const handleBulkStatusUpdate = async (status) => {
    if (selectedCollaborations.length === 0) return;

    setActionInProgress(true);

    try {
      const response = await api.put(
        `/shipping-collaboration/collaborations/${businessId}/bulk-status-update`,
        {
          collaboration_ids: selectedCollaborations,
          status,
          reason: "Bulk update",
        }
      );

      console.log("Bulk status update response:", response.data);

      // If we have successful updates, refresh the data
      if (response.data.successful && response.data.successful.length > 0) {
        setSuccessMessage(
          `Successfully updated status of ${response.data.successful.length} collaborations`
        );
        fetchCollaborations();
        onRefresh();
      }

      // If we have failures, show them
      if (response.data.failed && response.data.failed.length > 0) {
        setError(
          `Failed to update ${response.data.failed.length} collaborations`
        );
      }

      // Clear selection
      setSelectedCollaborations([]);
    } catch (error) {
      console.error("Error performing bulk status update:", error);
      setError("Failed to update collaboration statuses. Please try again.");
    } finally {
      setActionInProgress(false);
    }
  };

  const handleBulkPaymentUpdate = async (
    status = bulkPaymentData.payment_status
  ) => {
    if (selectedCollaborations.length === 0) return;

    setActionInProgress(true);
    setBulkPaymentDialogOpen(false);

    try {
      const payload = {
        collaboration_ids: selectedCollaborations,
        payment_status: status,
      };

      // If marking as paid, include payment details
      if (status === "paid") {
        payload.payment_details = bulkPaymentData.payment_details;
      }

      const response = await api.put(
        `/shipping-collaboration/collaborations/${businessId}/bulk-payment-update`,
        payload
      );

      console.log("Bulk payment update response:", response.data);

      // If we have successful updates, refresh the data
      if (response.data.successful && response.data.successful.length > 0) {
        setSuccessMessage(
          `Successfully updated payment status of ${response.data.successful.length} collaborations`
        );
        fetchCollaborations();
        onRefresh();
      }

      // If we have failures, show them
      if (response.data.failed && response.data.failed.length > 0) {
        setError(
          `Failed to update payment for ${response.data.failed.length} collaborations`
        );
      }

      // Clear selection
      setSelectedCollaborations([]);
    } catch (error) {
      console.error("Error performing bulk payment update:", error);
      setError("Failed to update payment statuses. Please try again.");
    } finally {
      setActionInProgress(false);
    }
  };

  const renderFilters = useMemo(() => {
    return (
      <>
        <Grid container spacing={2} alignItems="center" marginBottom={2}>
          {/* Search */}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search order ID, business name..."
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </Grid>

          {/* Status Filter */}
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="all">All Statuses</MenuItem>
                <MenuItem value="proposed">Proposed</MenuItem>
                <MenuItem value="counter_offered">Counter Offered</MenuItem>
                <MenuItem value="accepted">Accepted</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Business Filter */}
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Collaborating Business</InputLabel>
              <Select
                value={filterByBusiness}
                onChange={(e) => setFilterByBusiness(e.target.value)}
                label="Collaborating Business"
              >
                <MenuItem value="all">All Businesses</MenuItem>
                {availableBusinesses.map((business) => (
                  <MenuItem key={business.id} value={business.id}>
                    {business.name} ({business.role})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Sort Order */}
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                label="Sort By"
              >
                <MenuItem value="newest">Newest First</MenuItem>
                <MenuItem value="oldest">Oldest First</MenuItem>
                <MenuItem value="compensation_high">
                  Highest Compensation
                </MenuItem>
                <MenuItem value="compensation_low">
                  Lowest Compensation
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Items per page */}
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Items per page</InputLabel>
              <Select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                label="Items per page"
              >
                <MenuItem value={10}>10 per page</MenuItem>
                <MenuItem value={25}>25 per page</MenuItem>
                <MenuItem value={50}>50 per page</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {(filterStatus !== "all" ||
          searchQuery ||
          sortOrder !== "newest" ||
          filterByBusiness !== "all") && (
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary" mb={1}>
              Active Filters:
            </Typography>
            <Box display="flex" gap={1} flexWrap="wrap">
              {filterStatus !== "all" && (
                <Chip
                  key="status"
                  label={`Status: ${filterStatus
                    .replace(/_/g, " ")
                    .toLowerCase()}`}
                  onDelete={() => setFilterStatus("all")}
                  size="small"
                />
              )}
              {searchQuery && (
                <Chip
                  key="search"
                  label={`Search: ${searchQuery}`}
                  onDelete={() => setSearchQuery("")}
                  size="small"
                />
              )}
              {sortOrder !== "newest" && (
                <Chip
                  key="sort"
                  label={`Sort: ${sortOrder.replace(/_/g, " ").toLowerCase()}`}
                  onDelete={() => setSortOrder("newest")}
                  size="small"
                />
              )}
              {filterByBusiness !== "all" && (
                <Chip
                  key="business"
                  label={`Business: ${
                    availableBusinesses.find((b) => b.id === filterByBusiness)
                      ?.name || filterByBusiness
                  }`}
                  onDelete={() => setFilterByBusiness("all")}
                  size="small"
                />
              )}
              <Chip
                label="Clear All"
                onClick={() => {
                  setFilterStatus("all");
                  setSearchQuery("");
                  setSortOrder("newest");
                  setFilterByBusiness("all");
                  setCurrentPage(1);
                }}
                size="small"
                color="secondary"
              />
            </Box>
          </Box>
        )}
      </>
    );
  }, [
    filterStatus,
    searchQuery,
    itemsPerPage,
    sortOrder,
    filterByBusiness,
    availableBusinesses,
  ]);

  const handleViewDetails = (collaboration) => {
    setSelectedCollaboration(collaboration);
    setDetailsDialogOpen(true);
  };

  const getActionButtons = (collaboration) => {
    const isOriginator = collaboration.originating_business_id === businessId;
    const isCollaborator =
      collaboration.collaborating_business_id === businessId;

    return (
      <>
        <Tooltip title="View Details">
          <IconButton onClick={() => handleViewDetails(collaboration)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View History">
          <IconButton onClick={() => handleViewHistory(collaboration)}>
            <HistoryIcon />
          </IconButton>
        </Tooltip>
        {collaboration.status === "proposed" && isCollaborator && (
          <>
            <Tooltip title="Accept">
              <IconButton
                onClick={() =>
                  handleAcceptCollaboration(collaboration.collaboration_id)
                }
              >
                <CheckIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditClick(collaboration)}>
                <MonetizationOnIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton
                onClick={() =>
                  handleStatusUpdate(collaboration.collaboration_id, "rejected")
                }
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </>
        )}
        {collaboration.status === "counter_offered" && isOriginator && (
          <>
            <Tooltip title="Accept Counter">
              <IconButton
                onClick={() =>
                  handleStatusUpdate(collaboration.collaboration_id, "accepted")
                }
              >
                <CheckIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditClick(collaboration)}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                onClick={() =>
                  handleStatusUpdate(
                    collaboration.collaboration_id,
                    "cancelled"
                  )
                }
              >
                <CloseIcon color="error" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  const paginatedData = useMemo(() => {
    return getPaginatedData();
  }, [filteredCollaborations, currentPage, itemsPerPage]);

  // Get the business to display based on the active tab
  const getBusinessToDisplay = (collaboration) => {
    if (activeTab === 1) {
      // In the "Sent" tab, display the collaborating business
      return (
        collaboration.CollaboratingBusiness?.business_name || "Unknown Business"
      );
    } else if (activeTab === 2) {
      // In the "Received" tab, display the originating business
      return (
        collaboration.OriginatingBusiness?.business_name || "Unknown Business"
      );
    } else {
      // In the "All" tab, display the business that's not us
      return collaboration.originating_business_id === businessId
        ? collaboration.CollaboratingBusiness?.business_name ||
            "Unknown Business"
        : collaboration.OriginatingBusiness?.business_name ||
            "Unknown Business";
    }
  };

  return (
    <div>
      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert
          severity="success"
          onClose={() => setSuccessMessage("")}
          sx={{ mb: 2 }}
        >
          {successMessage}
        </Alert>
      )}

      <Card>
        <CardHeader
          title="Collaboration Management"
          action={
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                onClick={fetchCollaborations}
                sx={{ mr: 1 }}
              >
                Refresh
              </Button>
            </Box>
          }
        />
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
          >
            <Tab label="All Collaborations" />
            <Tab label={`Sent (${sentCollaborations.length})`} />
            <Tab label={`Received (${receivedCollaborations.length})`} />
          </Tabs>
        </Box>
        <CardContent>
          {renderFilters}

          {/* Bulk Action Buttons */}

          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {selectedCollaborations.length} item(s) selected
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBulkActionOpen}
              disabled={actionInProgress || selectedCollaborations.length === 0}
              startIcon={<MoreVertIcon />}
              sx={{ mr: 1 }}
            >
              Bulk Actions
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setSelectedCollaborations([])}
              disabled={actionInProgress || selectedCollaborations.length === 0}
              sx={{ mr: 1 }}
            >
              Clear Selection
            </Button>
          </Box>

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedCollaborations.length > 0 &&
                          selectedCollaborations.length < paginatedData.length
                        }
                        checked={
                          paginatedData.length > 0 &&
                          selectedCollaborations.length === paginatedData.length
                        }
                        onChange={() => {
                          if (
                            selectedCollaborations.length ===
                            paginatedData.length
                          ) {
                            setSelectedCollaborations([]);
                          } else {
                            setSelectedCollaborations(
                              paginatedData.map((c) => c.collaboration_id)
                            );
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>
                      {activeTab === 1
                        ? "Collaborator"
                        : activeTab === 2
                        ? "Requester"
                        : "Business"}
                    </TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Compensation</TableCell>
                    <TableCell>Additional Charges</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        No collaborations found
                      </TableCell>
                    </TableRow>
                  ) : (
                    paginatedData.map((collaboration) => (
                      <TableRow
                        key={collaboration.collaboration_id}
                        selected={selectedCollaborations.includes(
                          collaboration.collaboration_id
                        )}
                        onClick={() => {
                          if (
                            selectedCollaborations.includes(
                              collaboration.collaboration_id
                            )
                          ) {
                            setSelectedCollaborations((prev) =>
                              prev.filter(
                                (id) => id !== collaboration.collaboration_id
                              )
                            );
                          } else {
                            setSelectedCollaborations((prev) => [
                              ...prev,
                              collaboration.collaboration_id,
                            ]);
                          }
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedCollaborations.includes(
                              collaboration.collaboration_id
                            )}
                            onChange={(e) => {
                              e.stopPropagation();
                              if (
                                selectedCollaborations.includes(
                                  collaboration.collaboration_id
                                )
                              ) {
                                setSelectedCollaborations((prev) =>
                                  prev.filter(
                                    (id) =>
                                      id !== collaboration.collaboration_id
                                  )
                                );
                              } else {
                                setSelectedCollaborations((prev) => [
                                  ...prev,
                                  collaboration.collaboration_id,
                                ]);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            onClick={() =>
                              handleOrderClick(collaboration.shipping_order_id)
                            }
                          >
                            {collaboration.shipping_order_id}
                          </Button>
                        </TableCell>
                        <TableCell>
                          {getBusinessToDisplay(collaboration)}
                        </TableCell>
                        <TableCell>
                          {collaboration.collaboration_type}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={collaboration.status}
                            color={getStatusColor(collaboration.status)}
                          />
                        </TableCell>
                        <TableCell>
                          $
                          {parseFloat(
                            collaboration.agreed_compensation || 0
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          $
                          {collaboration.additional_charges
                            ? collaboration.additional_charges
                                .reduce(
                                  (sum, charge) =>
                                    sum + parseFloat(charge.amount || 0),
                                  0
                                )
                                .toFixed(2)
                            : "0.00"}
                        </TableCell>
                        <TableCell>
                          {new Date(collaboration.createdAt).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={collaboration.payment_status || "unpaid"}
                            color={getPaymentStatusColor(
                              collaboration.payment_status
                            )}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{getActionButtons(collaboration)}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Pagination
          count={Math.ceil(filteredCollaborations.length / itemsPerPage)}
          page={currentPage}
          onChange={(e, page) => setCurrentPage(page)}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Box>

      {/* Bulk Action Menu */}
      <Menu
        anchorEl={bulkActionMenuAnchorEl}
        open={Boolean(bulkActionMenuAnchorEl)}
        onClose={handleBulkActionClose}
      >
        <MenuItem
          onClick={() => handleBulkAction("accept")}
          disabled={!isBulkStatusUpdateAllowed("accepted")}
        >
          <ListItemText primary="Accept Collaborations" />
        </MenuItem>
        <MenuItem
          onClick={() => handleBulkAction("reject")}
          disabled={!isBulkStatusUpdateAllowed("rejected")}
        >
          <ListItemText primary="Reject Collaborations" />
        </MenuItem>
        {/* <MenuItem
          onClick={() => handleBulkAction("cancel")}
          disabled={!isBulkStatusUpdateAllowed("cancelled")}
        >
          <ListItemText primary="Cancel Collaborations" />
        </MenuItem> */}
        {/* <MenuItem
          onClick={() => handleBulkAction("complete")}
          disabled={!isBulkStatusUpdateAllowed("completed")}
        >
          <ListItemText primary="Complete Collaborations" />
        </MenuItem> */}
        <Divider />
        <MenuItem
          onClick={() => handleBulkAction("markAsPaid")}
          disabled={!isBulkPaymentUpdateAllowed("paid")}
        >
          <ListItemText primary="Mark as Paid" />
        </MenuItem>
        <MenuItem onClick={() => handleBulkAction("markAsDisputed")}>
          <ListItemText primary="Mark as Disputed" />
        </MenuItem>
      </Menu>

      {/* Bulk Payment Dialog */}
      <Dialog
        open={bulkPaymentDialogOpen}
        onClose={() => setBulkPaymentDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Mark Collaborations as Paid</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Update payment status for {selectedCollaborations.length} selected
            collaboration(s).
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Payment Method"
                fullWidth
                margin="normal"
                value={bulkPaymentData.payment_details.payment_method}
                onChange={(e) =>
                  setBulkPaymentData({
                    ...bulkPaymentData,
                    payment_details: {
                      ...bulkPaymentData.payment_details,
                      payment_method: e.target.value,
                    },
                  })
                }
              >
                <MenuItem value="Credit Card">Credit Card</MenuItem>
                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="Check">Check</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Payment Amount (leave empty to use collaboration amounts)"
                fullWidth
                type="number"
                margin="normal"
                value={bulkPaymentData.payment_details.payment_amount}
                onChange={(e) =>
                  setBulkPaymentData({
                    ...bulkPaymentData,
                    payment_details: {
                      ...bulkPaymentData.payment_details,
                      payment_amount: e.target.value,
                    },
                  })
                }
                placeholder="Optional: Enter amount if same for all"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Notes"
                fullWidth
                multiline
                rows={3}
                margin="normal"
                value={bulkPaymentData.payment_details.notes}
                onChange={(e) =>
                  setBulkPaymentData({
                    ...bulkPaymentData,
                    payment_details: {
                      ...bulkPaymentData.payment_details,
                      notes: e.target.value,
                    },
                  })
                }
                placeholder="Optional payment notes"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkPaymentDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => handleBulkPaymentUpdate("paid")}
            color="primary"
            variant="contained"
            disabled={actionInProgress}
          >
            Mark as Paid
          </Button>
        </DialogActions>
      </Dialog>

      {/* Response Dialog */}
      <Dialog
        open={responseDialogOpen}
        onClose={() => setResponseDialogOpen(false)}
      >
        <DialogTitle>Respond to Collaboration Request</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Response"
            value={responseStatus}
            onChange={(e) => setResponseStatus(e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="accepted">Accept</MenuItem>
            <MenuItem value="rejected">Reject</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResponseDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleResponseSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* History Dialog */}
      <Dialog
        open={historyDialogOpen}
        onClose={() => setHistoryDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Collaboration History</DialogTitle>
        <DialogContent>
          {collaborationHistory.length === 0 ? (
            <Typography variant="body1" align="center" sx={{ py: 2 }}>
              No history available
            </Typography>
          ) : (
            <Timeline>
              {collaborationHistory.map((entry, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color={getStatusColor(entry.action)} />
                    {index < collaborationHistory.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="h6">
                      {entry.action.charAt(0).toUpperCase() +
                        entry.action.slice(1).replace("_", " ")}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(entry.timestamp).toLocaleString()}
                    </Typography>
                    {entry.business_id && (
                      <Typography variant="body2">
                        By:{" "}
                        {entry.business_id === businessId
                          ? "You"
                          : entry.business_id ===
                            selectedCollaboration?.originating_business_id
                          ? selectedCollaboration?.OriginatingBusiness
                              ?.business_name
                          : selectedCollaboration?.CollaboratingBusiness
                              ?.business_name}
                      </Typography>
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHistoryDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Collaboration Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Collaboration Request</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Agreed Compensation"
                type="number"
                value={editedCollaboration?.agreed_compensation || ""}
                onChange={(e) =>
                  setEditedCollaboration({
                    ...editedCollaboration,
                    agreed_compensation: e.target.value,
                  })
                }
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Special Instructions"
                multiline
                rows={4}
                value={editedCollaboration?.special_instructions || ""}
                onChange={(e) =>
                  setEditedCollaboration({
                    ...editedCollaboration,
                    special_instructions: e.target.value,
                  })
                }
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Additional Charges</Typography>
              {editedCollaboration?.additional_charges?.map((charge, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Description"
                      value={charge.description}
                      onChange={(e) =>
                        handleChargeChange(index, "description", e.target.value)
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Amount"
                      type="number"
                      value={charge.amount}
                      onChange={(e) =>
                        handleChargeChange(index, "amount", e.target.value)
                      }
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => handleRemoveCharge(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddCharge}
                variant="outlined"
                color="primary"
              >
                Add Charge
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Collaboration Details Dialog */}
      {selectedCollaboration && (
        <CollaborationDetailsDialog
          open={detailsDialogOpen}
          onClose={() => {
            setDetailsDialogOpen(false);
            setSelectedCollaboration(null);
          }}
          collaborations={
            Array.isArray(selectedCollaboration)
              ? selectedCollaboration
              : [selectedCollaboration]
          }
          businessId={businessId}
          onActionComplete={fetchCollaborations}
        />
      )}
    </div>
  );
};

export default CollaborationManagementScreen;
