import React, { useState, useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { useBusiness } from "../components/Auth/BusinessContext";
import { useSidebar } from "../components/Sidebar/SidebarContext";
import BusinessHeader from "../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../components/Footer/DashboardFooter";
import "./styles/LayoutStyles.css";
import "./styles/spinner.css";

const BusinessLayout = () => {
  const { businessId } = useParams();
  const { isBusinessSidebarActive } = useSidebar();
  const { getBusinessById } = useBusiness();

  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch business data once at the layout level
  useEffect(() => {
    try {
      setLoading(true);
      if (businessId) {
        const business = getBusinessById(businessId);
        setCurrentBusiness(business);

        // Save business data to localStorage
        if (business) {
          localStorage.setItem(
            `business_${businessId}`,
            JSON.stringify(business)
          );
        }
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching business data:", err);
      setError("Failed to load business data");
      setLoading(false);
    }
  }, [businessId, getBusinessById]);

  return (
    <div className="layout-container business-dashboard-page">
      <BusinessHeader business={currentBusiness} />
      <div className="dashboard-main-content">
        {/* The sidebar is now only rendered once at the layout level */}
        <BusinessSidebar />
        {loading ? (
          <div className="layout-loading">
            <div className="spinner"></div>
          </div>
        ) : error ? (
          <div className="layout-error">
            <h3>Error loading data</h3>
            <p>{error}</p>
          </div>
        ) : (
          <div className="dashboard-container">
            <main
              className={`dashboard-content ${
                isBusinessSidebarActive ? "sidebar-active" : ""
              }`}
            >
              <div className="page-transition-wrapper">
                <Outlet context={{ currentBusiness }} />
              </div>
            </main>
          </div>
        )}
      </div>
      <DashboardFooter />
    </div>
  );
};

export default BusinessLayout;
