import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Autocomplete,
  Alert,
  CircularProgress,
  Menu,
  Chip,
  Skeleton,
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Pagination,
  TableSortLabel,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  DirectionsCar as CarIcon,
} from "@mui/icons-material";

import { useAssets } from "./AssetsContext";

const VehicleList = ({
  onAddVehicle,
  onUpdateVehicle,
  onDeleteVehicle,
  createVehicleAssignment,
  unassignVehicle,
  vehicles,
  couriers,
}) => {
  const [assignmentMenuAnchorEl, setAssignmentMenuAnchorEl] = useState(null);
  const [activeVehicleForAssignment, setActiveVehicleForAssignment] =
    useState(null);
  const [open, setOpen] = useState(false);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssignMenu, setIsLoadingAssignMenu] = useState(false);
  const [loadingAssignMenuId, setLoadingAssignMenuId] = useState(null);
  const { businessId } = useParams();
  const [formData, setFormData] = useState({
    vehicle_type: "",
    body_type: "",
    make: "",
    model: "",
    year: new Date().getFullYear().toString(),
    license_plate: "",
    insurance_policy_number: "",
    vin: "",
    color: "",
    fuel_level: "",
    odometer: "",
    passenger_capacity: "",
    package_capacity: "",
    max_payload_weight: "",
    max_payload_volume: "",
    features: "",
    status: "Active",
    last_maintenance_date: "",
    next_maintenance_date: "",
    assigned_courier: null,
  });
  const [errors, setErrors] = useState({});

  // Add search and filtering capabilities
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    status: "",
    vehicleType: "",
  });
  const [sortConfig, setSortConfig] = useState({
    field: "fleet_number",
    direction: "asc",
  });

  // Handle sort change
  const handleSortChange = (field) => {
    setSortConfig((prevConfig) => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  // Filter and paginate vehicles
  const filteredVehicles = useMemo(() => {
    let result = [...vehicles];

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(
        (vehicle) =>
          vehicle.make?.toLowerCase().includes(query) ||
          vehicle.model?.toLowerCase().includes(query) ||
          vehicle.license_plate?.toLowerCase().includes(query) ||
          vehicle.fleet_number?.toLowerCase().includes(query) ||
          vehicle.vin?.toLowerCase().includes(query)
      );
    }

    // Filter by status
    if (filters.status) {
      result = result.filter((vehicle) => vehicle.status === filters.status);
    }

    // Filter by vehicle type
    if (filters.vehicleType) {
      result = result.filter(
        (vehicle) => vehicle.vehicle_type === filters.vehicleType
      );
    }

    // Sort the results
    if (sortConfig.field) {
      result.sort((a, b) => {
        let aValue = a[sortConfig.field] || "";
        let bValue = b[sortConfig.field] || "";

        if (typeof aValue === "string") aValue = aValue.toLowerCase();
        if (typeof bValue === "string") bValue = bValue.toLowerCase();

        if (sortConfig.direction === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return bValue > aValue ? 1 : -1;
        }
      });
    }

    return result;
  }, [vehicles, searchQuery, filters, sortConfig]);

  // Get paginated data
  const paginatedVehicles = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredVehicles.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredVehicles, currentPage, itemsPerPage]);

  // Handle search change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Listen for the custom event to open the add form
  useEffect(() => {
    const handleOpenAddForm = (e) => {
      if (e.detail.type === "vehicle") {
        handleOpen();
      }
    };

    document.addEventListener("openAddAssetForm", handleOpenAddForm);

    return () => {
      document.removeEventListener("openAddAssetForm", handleOpenAddForm);
    };
  }, []);

  const handleAssignmentMenuOpen = (event, vehicle) => {
    setAssignmentMenuAnchorEl(event.currentTarget);
    setActiveVehicleForAssignment(vehicle);
  };

  const handleAssignmentMenuClose = () => {
    setAssignmentMenuAnchorEl(null);
    setActiveVehicleForAssignment(null);
  };
  const handleAssignDriver = async (courier) => {
    if (!activeVehicleForAssignment || !courier) return;
    handleAssignmentMenuClose();
    setIsLoadingAssignMenu(true);
    setLoadingAssignMenuId(activeVehicleForAssignment.vehicle_id);
    try {
      const assignmentData = {
        vehicle_id: activeVehicleForAssignment.vehicle_id,
        employee_id: courier.employee_id,
        assignment_start_date: new Date().toISOString(),
        assignment_status: "Active",
        odometer_at_assignment: activeVehicleForAssignment.odometer,
        fuel_level_at_assignment: activeVehicleForAssignment.fuel_level,
        assignment_type: "Primary",
      };

      await createVehicleAssignment(assignmentData);

      setAlertMessage({
        type: "success",
        message: "Driver assigned successfully",
      });
    } catch (error) {
      console.error("Error assigning driver:", error);
      setAlertMessage({
        type: "error",
        message:
          "Failed to assign driver: " +
          (error.response?.data?.msg || error.message),
      });
    } finally {
      setIsLoadingAssignMenu(false);
      setLoadingAssignMenuId(null);
    }
  };

  const handleUnassignDriver = async () => {
    if (!activeVehicleForAssignment) return;
    handleAssignmentMenuClose();
    setIsLoadingAssignMenu(true);
    setLoadingAssignMenuId(activeVehicleForAssignment.vehicle_id);
    try {
      await unassignVehicle(activeVehicleForAssignment.vehicle_id);
      setAlertMessage({
        type: "success",
        message: "Driver unassigned successfully",
      });
    } catch (error) {
      console.error("Error unassigning driver:", error);
      setAlertMessage({
        type: "error",
        message:
          "Failed to unassign driver: " +
          (error.response?.data?.msg || error.message),
      });
    } finally {
      setIsLoadingAssignMenu(false);
      setLoadingAssignMenuId(null);
    }
  };

  const renderAssignedCourierChip = (vehicle) => {
    if (isLoadingAssignMenu && loadingAssignMenuId === vehicle.vehicle_id) {
      return <Skeleton variant="rect" width={100} height={32} />;
    }

    if (
      vehicle.ShippingVehicleAssignments &&
      vehicle.ShippingVehicleAssignments.length > 0
    ) {
      const courier = couriers.find(
        (courier) =>
          courier.employee_id ===
          vehicle.ShippingVehicleAssignments[0].employee_id
      );
      return (
        <Chip
          icon={<PersonIcon />}
          label={
            courier
              ? `${courier.employee_first_name} ${courier.employee_last_name}`
              : "Unknown"
          }
          color="primary"
          variant="outlined"
          onClick={(e) => handleAssignmentMenuOpen(e, vehicle)}
        />
      );
    } else {
      return (
        <Chip
          icon={<PersonIcon />}
          label="Unassigned"
          color="secondary"
          variant="outlined"
          onClick={(e) => handleAssignmentMenuOpen(e, vehicle)}
        />
      );
    }
  };

  const vehicleTypes = [
    "Bicycle",
    "Motorcycle",
    "Car",
    "Van",
    "Truck",
    "Drone",
    "Other",
  ];

  useEffect(() => {
    console.log("Vehicles updated in VehicleList:", vehicles);
  }, [vehicles]);

  const bodyTypesByVehicleType = {
    Bicycle: ["Standard", "Electric", "Cargo"],
    Motorcycle: ["Standard", "Scooter", "Moped"],
    Car: ["Sedan", "Hatchback", "SUV", "Coupe", "Convertible"],
    Van: ["Cargo", "Passenger", "Mini"],
    Truck: ["Pickup", "Box", "Flatbed", "Refrigerated"],
    Drone: ["Quadcopter", "Hexacopter", "Octocopter"],
    Other: ["Custom"],
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear + 1; year >= 1900; year--) {
      years.push(year.toString());
    }
    return years;
  };

  const handleOpen = (vehicle = null) => {
    if (vehicle) {
      setEditingVehicle(vehicle);
      setFormData({
        ...vehicle,
        assigned_courier: vehicle.assigned_courier || null,
      });
    } else {
      setEditingVehicle(null);
      setFormData({
        vehicle_type: "",
        body_type: "",
        make: "",
        model: "",
        year: new Date().getFullYear().toString(),
        license_plate: "",
        insurance_policy_number: "",
        vin: "",
        color: "",
        fuel_level: "",
        odometer: "",
        passenger_capacity: "",
        package_capacity: "",
        max_payload_weight: "",
        max_payload_volume: "",
        features: "",
        status: "Active",
        last_maintenance_date: "",
        next_maintenance_date: "",
        assigned_courier: null,
      });
    }
    setErrors({});
    setOpen(true);
  };

  useEffect(() => {
    console.log("couriers", couriers);
  }, [couriers]);

  const handleClose = () => {
    setOpen(false);
    setEditingVehicle(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Clear the error for the field being changed
    setErrors((prev) => ({ ...prev, [name]: "" }));

    // Special handling for vehicle_type
    if (name === "vehicle_type") {
      setFormData((prev) => ({ ...prev, body_type: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields
    const requiredFields = [
      "vehicle_type",
      "body_type",
      "make",
      "model",
      "year",
      "license_plate",
      "vin",
      "color",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    });

    // VIN validation (17 characters, alphanumeric)
    if (formData.vin && !/^[A-HJ-NPR-Z0-9]{17}$/.test(formData.vin)) {
      newErrors.vin = "Invalid VIN format";
    }

    // License plate validation (alphanumeric, allow spaces and dashes)
    if (
      formData.license_plate &&
      !/^[A-Z0-9 -]+$/.test(formData.license_plate)
    ) {
      newErrors.license_plate = "Invalid license plate format";
    }

    // Numeric fields validation
    const numericFields = [
      "fuel_level",
      "odometer",
      "passenger_capacity",
      "package_capacity",
      "max_payload_weight",
      "max_payload_volume",
    ];
    numericFields.forEach((field) => {
      if (formData[field] && isNaN(formData[field])) {
        newErrors[field] = "Must be a number";
      }
    });

    // fuel level validation (0-100)
    if (
      formData.fuel_level &&
      (formData.fuel_level < 0 || formData.fuel_level > 100)
    ) {
      newErrors.fuel_level = "Must be between 0 and 100";
    }

    // Date fields validation
    const dateFields = ["last_maintenance_date", "next_maintenance_date"];
    dateFields.forEach((field) => {
      if (formData[field] && isNaN(Date.parse(formData[field]))) {
        newErrors[field] = "Invalid date";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    try {
      const vehicleData = { ...formData };
      let updatedVehicle;

      if (editingVehicle) {
        updatedVehicle = await onUpdateVehicle(
          editingVehicle.vehicle_id,
          vehicleData
        );
      } else {
        updatedVehicle = await onAddVehicle(vehicleData);
      }
      setAlertMessage({
        type: "success",
        message: "Vehicle updated successfully",
      });

      handleClose();
    } catch (error) {
      console.error("Error submitting vehicle:", error);
      const message = error.response?.data?.message || error.message;
      setAlertMessage({
        type: "error",
        message: `Failed to submit vehicle: ${message}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (vehicleId) => {
    if (window.confirm("Are you sure you want to delete this vehicle?")) {
      setIsLoading(true);
      try {
        await onDeleteVehicle(vehicleId);
        setAlertMessage({
          type: "success",
          message: "Vehicle deleted successfully",
        });
      } catch (error) {
        console.error("Error deleting vehicle:", error);
        const message = error.response?.data?.message || error.message;
        setAlertMessage({
          type: "error",
          message: `Failed to delete vehicle: ${message}`,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          onClose={() => setAlertMessage(null)}
          sx={{ mb: 2 }}
        >
          {alertMessage.message}
        </Alert>
      )}

      <Card>
        <CardHeader title="Vehicles" />
        <CardContent>
          {/* Search and Filters */}
          <Grid container spacing={2} alignItems="center" marginBottom={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Search Vehicles"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search by make, model, license plate..."
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      fontSize="small"
                      sx={{ mr: 1, color: "text.secondary" }}
                    />
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  label="Status"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Maintenance">Maintenance</MenuItem>
                  <MenuItem value="Out of Service">Out of Service</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Vehicle Type</InputLabel>
                <Select
                  name="vehicleType"
                  value={filters.vehicleType}
                  onChange={handleFilterChange}
                  label="Vehicle Type"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="Car">Car</MenuItem>
                  <MenuItem value="Van">Van</MenuItem>
                  <MenuItem value="Truck">Truck</MenuItem>
                  <MenuItem value="Motorcycle">Motorcycle</MenuItem>
                  <MenuItem value="Bicycle">Bicycle</MenuItem>
                  <MenuItem value="Drone">Drone</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Active Filters */}
          {(filters.status || filters.vehicleType || searchQuery) && (
            <Box mb={2}>
              <Typography variant="body2" color="textSecondary" mb={1}>
                Active Filters:
              </Typography>
              <Box display="flex" gap={1} flexWrap="wrap">
                {filters.status && (
                  <Chip
                    label={`Status: ${filters.status}`}
                    onDelete={() => setFilters({ ...filters, status: "" })}
                    size="small"
                  />
                )}
                {filters.vehicleType && (
                  <Chip
                    label={`Type: ${filters.vehicleType}`}
                    onDelete={() => setFilters({ ...filters, vehicleType: "" })}
                    size="small"
                  />
                )}
                {searchQuery && (
                  <Chip
                    label={`Search: ${searchQuery}`}
                    onDelete={() => setSearchQuery("")}
                    size="small"
                  />
                )}
                {(filters.status || filters.vehicleType || searchQuery) && (
                  <Chip
                    label="Clear All"
                    onClick={() => {
                      setFilters({ status: "", vehicleType: "" });
                      setSearchQuery("");
                    }}
                    size="small"
                    color="secondary"
                  />
                )}
              </Box>
            </Box>
          )}

          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={sortConfig.field === "fleet_number" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "fleet_number"}
                        direction={sortConfig.field === "fleet_number" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("fleet_number")}
                      >
                        Fleet Number
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "vehicle_type" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "vehicle_type"}
                        direction={sortConfig.field === "vehicle_type" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("vehicle_type")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "body_type" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "body_type"}
                        direction={sortConfig.field === "body_type" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("body_type")}
                      >
                        Body Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "make" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "make"}
                        direction={sortConfig.field === "make" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("make")}
                      >
                        Make
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "model" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "model"}
                        direction={sortConfig.field === "model" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("model")}
                      >
                        Model
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "year" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "year"}
                        direction={sortConfig.field === "year" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("year")}
                      >
                        Year
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "license_plate" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "license_plate"}
                        direction={sortConfig.field === "license_plate" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("license_plate")}
                      >
                        License Plate
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "status" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "status"}
                        direction={sortConfig.field === "status" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Assigned Courier</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedVehicles.map((vehicle) => (
                    <TableRow key={vehicle.vehicle_id}>
                      <TableCell>{vehicle.fleet_number}</TableCell>
                      <TableCell>{vehicle.vehicle_type}</TableCell>
                      <TableCell>{vehicle.body_type}</TableCell>
                      <TableCell>{vehicle.make}</TableCell>
                      <TableCell>{vehicle.model}</TableCell>
                      <TableCell>{vehicle.year}</TableCell>
                      <TableCell>{vehicle.license_plate}</TableCell>
                      <TableCell>
                        <Chip
                          label={vehicle.status}
                          color={
                            vehicle.status === "Active"
                              ? "success"
                              : vehicle.status === "Maintenance"
                              ? "warning"
                              : "error"
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        {renderAssignedCourierChip(vehicle)}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleOpen(vehicle)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(vehicle.vehicle_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {paginatedVehicles.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <Typography variant="body1">
                          No vehicles found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination */}
          {filteredVehicles.length > 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Pagination
                count={Math.ceil(filteredVehicles.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingVehicle ? "Edit Vehicle" : "Add Vehicle"}
        </DialogTitle>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            onClose={() => setAlertMessage(null)}
            sx={{ mb: 2, mr: 2, ml: 2 }}
          >
            {alertMessage.message}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.vehicle_type}>
                  <InputLabel>Vehicle Type *</InputLabel>
                  <Select
                    name="vehicle_type"
                    value={formData.vehicle_type}
                    onChange={handleChange}
                    required
                  >
                    {vehicleTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.vehicle_type && (
                    <FormHelperText>{errors.vehicle_type}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.body_type}>
                  <InputLabel>Body Type *</InputLabel>
                  <Select
                    name="body_type"
                    value={formData.body_type}
                    onChange={handleChange}
                    required
                    disabled={!formData.vehicle_type}
                  >
                    {formData.vehicle_type &&
                      bodyTypesByVehicleType[formData.vehicle_type].map(
                        (type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        )
                      )}
                  </Select>
                  {errors.body_type && (
                    <FormHelperText>{errors.body_type}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  name="make"
                  label="Make"
                  value={formData.make}
                  onChange={handleChange}
                  error={!!errors.make}
                  helperText={errors.make}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  name="model"
                  label="Model"
                  value={formData.model}
                  onChange={handleChange}
                  error={!!errors.model}
                  helperText={errors.model}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.year}>
                  <InputLabel>Year *</InputLabel>
                  <Select
                    name="year"
                    value={formData.year}
                    onChange={handleChange}
                    required
                  >
                    {generateYearOptions().map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.year && (
                    <FormHelperText>{errors.year}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  name="license_plate"
                  label="License Plate"
                  value={formData.license_plate}
                  onChange={handleChange}
                  error={!!errors.license_plate}
                  helperText={errors.license_plate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="insurance_policy_number"
                  label="Insurance Policy Number"
                  value={formData.insurance_policy_number}
                  onChange={handleChange}
                  error={!!errors.insurance_policy_number}
                  helperText={errors.insurance_policy_number}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  name="vin"
                  label="VIN"
                  value={formData.vin}
                  onChange={handleChange}
                  error={!!errors.vin}
                  helperText={errors.vin}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  name="color"
                  label="Color"
                  value={formData.color}
                  onChange={handleChange}
                  error={!!errors.color}
                  helperText={errors.color}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="fuel_level"
                  label="Fuel Level (%)"
                  type="number"
                  value={formData.fuel_level}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    inputProps: { min: 0, max: 100 },
                  }}
                  error={!!errors.fuel_level}
                  helperText={errors.fuel_level}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="odometer"
                  label="Odometer"
                  type="number"
                  value={formData.odometer}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  error={!!errors.odometer}
                  helperText={errors.odometer}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="passenger_capacity"
                  label="Passenger Capacity"
                  type="number"
                  value={formData.passenger_capacity}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  error={!!errors.passenger_capacity}
                  helperText={errors.passenger_capacity}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="package_capacity"
                  label="Package Capacity"
                  type="number"
                  value={formData.package_capacity}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  error={!!errors.package_capacity}
                  helperText={errors.package_capacity}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="max_payload_weight"
                  label="Max Payload Weight (kg)"
                  type="number"
                  value={formData.max_payload_weight}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  error={!!errors.max_payload_weight}
                  helperText={errors.max_payload_weight}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="max_payload_volume"
                  label="Max Payload Volume (m³)"
                  type="number"
                  value={formData.max_payload_volume}
                  onChange={handleChange}
                  InputProps={{ inputProps: { min: 0 } }}
                  error={!!errors.max_payload_volume}
                  helperText={errors.max_payload_volume}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="features"
                  label="Features"
                  multiline
                  rows={4}
                  value={formData.features}
                  onChange={handleChange}
                  error={!!errors.features}
                  helperText={errors.features}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.status}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Maintenance">Maintenance</MenuItem>
                    <MenuItem value="Out of Service">Out of Service</MenuItem>
                  </Select>
                  {errors.status && (
                    <FormHelperText>{errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="last_maintenance_date"
                  label="Last Maintenance Date"
                  type="date"
                  value={formData.last_maintenance_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.last_maintenance_date}
                  helperText={errors.last_maintenance_date}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="next_maintenance_date"
                  label="Next Maintenance Date"
                  type="date"
                  value={formData.next_maintenance_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.next_maintenance_date}
                  helperText={errors.next_maintenance_date}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={couriers}
                  getOptionLabel={(option) =>
                    option
                      ? `${option.employee_first_name} ${option.employee_last_name}`
                      : ""
                  }
                  value={formData.assigned_courier}
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, assigned_courier: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assigned Driver"
                      error={!!errors.assigned_courier}
                      helperText={errors.assigned_courier}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {`${option.employee_first_name} ${option.employee_last_name} (${option.employee_email})`}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.employee_id === value?.employee_id
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {editingVehicle ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Menu
        anchorEl={assignmentMenuAnchorEl}
        open={Boolean(assignmentMenuAnchorEl)}
        onClose={handleAssignmentMenuClose}
      >
        {couriers.map((courier) => (
          <MenuItem
            key={courier.employee_id}
            onClick={() => handleAssignDriver(courier)}
          >
            {`${courier.employee_first_name} ${courier.employee_last_name}`}
          </MenuItem>
        ))}
        <MenuItem onClick={handleUnassignDriver}>Unassign Driver</MenuItem>
      </Menu>
    </>
  );
};

export default VehicleList;
