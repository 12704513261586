/**
 * Batch route validation and manipulation utilities
 */

import { validateRoute, canCreateConnection } from "../RouteBuilder/RouteLogic";

/**
 * Validates whether a new connection can be created in batch mode
 */
export const canCreateBatchConnection = (
  sourceId,
  targetId,
  nodes = [],
  existingEdges = []
) => {
  // Find the actual nodes from the nodes array
  const sourceNode = nodes.find((node) => node.id === sourceId);
  const targetNode = nodes.find((node) => node.id === targetId);

  if (!sourceNode || !targetNode) {
    return {
      valid: false,
      message: "Could not find one or both nodes for the connection",
    };
  }

  // Don't allow connections between two consolidated nodes
  if (sourceNode.data?.isConsolidated && targetNode.data?.isConsolidated) {
    return {
      valid: false,
      message: "Cannot connect between consolidated nodes",
    };
  }

  // Don't allow connections where source is a delivery node (consolidated or not)
  if (sourceNode.data?.type === "delivery") {
    return {
      valid: false,
      message: "Cannot start a connection from a delivery location",
    };
  }

  // Don't allow connections to start locations
  if (targetNode.data?.isStart) {
    return {
      valid: false,
      message: "Cannot connect to a start location",
    };
  }

  // Check if this connection already exists
  const existingConnection = existingEdges.some(
    (edge) => edge.source === sourceId && edge.target === targetId
  );

  if (existingConnection) {
    return {
      valid: false,
      message: "Connection already exists between these locations",
    };
  }

  return {
    valid: true,
    message: "Valid connection",
  };
};

/**
 * Validates the complete batch route configuration
 */

// Update validateBatchRoute to work with route segments
export const validateBatchRoute = (
  routeSegments = [],
  nodes = [],
  orders = []
) => {
  const validationResults = {
    isValid: true,
    orderValidations: {},
    globalErrors: [],
  };

  // Validate each order's segments array
  routeSegments.forEach((orderSegments) => {
    if (!orderSegments?.length) return;

    const orderId = orderSegments[0].shipping_order_id;
    const order = orders.find((o) => o.shipping_order_id === orderId);

    if (!order) {
      validationResults.globalErrors.push(`Invalid order ID: ${orderId}`);
      validationResults.isValid = false;
      return;
    }

    const validation = validateOrderPath(
      orderSegments,
      order.start_location_id,
      order.recipient_address_id,
      nodes
    );

    validationResults.orderValidations[orderId] = validation;
    if (!validation.isValid) {
      validationResults.isValid = false;
    }
  });

  // Check for loops in all segments combined
  const allSegments = routeSegments.flat();
  const hasLoops = detectLoops(
    allSegments.map((seg) => ({
      source: seg.start_location_id,
      target: seg.end_location_id,
    }))
  );

  if (hasLoops) {
    validationResults.globalErrors.push("Route contains loops");
    validationResults.isValid = false;
  }

  // Check consolidated node constraints (e.g. no uncouriered incoming edges)
  const consolidatedNodes = nodes.filter((n) => n.data?.isConsolidated);
  consolidatedNodes.forEach((node) => {
    const incomingSegments = allSegments.filter(
      (seg) => seg.end_location_id === node.id
    );
    if (incomingSegments.some((seg) => !seg.courier_id)) {
      validationResults.globalErrors.push(
        `Consolidated node ${node.data.label} has uncouriered incoming segments`
      );
      validationResults.isValid = false;
    }
  });

  // Check for orphaned orders (orders without any segments)
  const ordersWithSegments = new Set(
    routeSegments
      .map((segments) => segments[0]?.shipping_order_id)
      .filter(Boolean)
  );
  const orphanedOrders = orders.filter(
    (order) => !ordersWithSegments.has(order.shipping_order_id)
  );
  if (orphanedOrders.length) {
    validationResults.globalErrors.push(
      `Orders without routes: ${orphanedOrders
        .map((o) => o.shipping_order_id)
        .join(", ")}`
    );
    validationResults.isValid = false;
  }

  // Additional validations
  routeSegments.forEach((orderSegments) => {
    if (!orderSegments?.length) return;

    const orderId = orderSegments[0].shipping_order_id;

    // Check segment_order continuity
    const hasInvalidOrder = orderSegments.some(
      (segment, index) => segment.segment_order !== index + 1
    );
    if (hasInvalidOrder) {
      validationResults.globalErrors.push(
        `Order ${orderId} has invalid segment ordering`
      );
      validationResults.isValid = false;
    }

    // Exactly one last_segment
    const lastSegmentCount = orderSegments.filter(
      (seg) => seg.is_last_segment
    ).length;
    if (lastSegmentCount !== 1) {
      validationResults.globalErrors.push(
        `Order ${orderId} has ${lastSegmentCount} segments marked as last (should be 1)`
      );
      validationResults.isValid = false;
    }

    // Verify last segment is actually flagged as last
    const lastSegment = orderSegments[orderSegments.length - 1];
    if (!lastSegment.is_last_segment) {
      validationResults.globalErrors.push(
        `Order ${orderId}'s final segment is not marked as last`
      );
      validationResults.isValid = false;
    }
  });

  console.log("Validation results", validationResults);

  return validationResults;
};

const validateOrderPath = (segments, startLocationId, endLocationId, nodes) => {
  const validation = {
    isValid: true,
    errors: [],
  };
  if (!segments.length) {
    validation.isValid = false;
    validation.errors.push("No route segments found");
    return validation;
  }

  // 1) Check that first segment starts at the order's start location
  if (segments[0].start_location_id !== startLocationId) {
    validation.isValid = false;
    validation.errors.push("Route doesn't start at order's start location");
  }

  // 2) Check path connectivity between segments
  for (let i = 0; i < segments.length - 1; i++) {
    if (segments[i].end_location_id !== segments[i + 1].start_location_id) {
      validation.isValid = false;
      validation.errors.push(
        `Disconnected path between segments ${i + 1} and ${i + 2}`
      );
    }
  }

  // 3) Check final segment's node
  const lastSegment = segments[segments.length - 1];
  const lastTargetNode = nodes.find(
    (n) => n.id === lastSegment.end_location_id
  );
  const orderId = segments[0].shipping_order_id;

  // a) If last node is a “delivery” node, figure out if it's single, shared, or consolidated
  if (lastTargetNode?.data?.type === "delivery") {
    // i) consolidated node
    if (lastTargetNode.data.isConsolidated) {
      const hasMatchingChild = lastTargetNode.data.details.childNodes.some(
        (child) => {
          // Single child?
          if (child.details?.order?.shipping_order_id) {
            return child.details.order.shipping_order_id === orderId;
          }
          // Shared child?
          if (
            child.details?.isSharedDelivery &&
            Array.isArray(child.details.orders)
          ) {
            return child.details.orders.some(
              (o) => o.shipping_order_id === orderId
            );
          }
          return false;
        }
      );
      if (!hasMatchingChild) {
        validation.isValid = false;
        validation.errors.push(
          `Route doesn't end in the correct consolidated node for order ${orderId}`
        );
      }
    }
    // ii) shared-delivery node
    else if (lastTargetNode.data.isSharedDelivery) {
      // Check if the node’s orders array includes this order
      const sharedOrders = lastTargetNode.data.details?.orders || [];
      const inShared = sharedOrders.some(
        (o) => o.shipping_order_id === orderId
      );
      if (!inShared) {
        validation.isValid = false;
        validation.errors.push(
          `Route doesn't end at the correct shared delivery location (missing order ${orderId})`
        );
      }
    }
    // iii) single-delivery node
    else {
      const nodeOrderId = lastTargetNode.data.details?.order?.shipping_order_id;
      if (nodeOrderId !== orderId) {
        validation.isValid = false;
        validation.errors.push(
          `Route doesn't end at the correct single-delivery location for order ${orderId}`
        );
      }
    }
  }
  // b) Otherwise, if last node is not a “delivery” type, just check it matches the order’s end_location_id
  else {
    if (lastSegment.end_location_id !== endLocationId) {
      validation.isValid = false;
      validation.errors.push(
        `Route doesn't end at the final address for order ${orderId}`
      );
    }
  }

  // 4) Check each segment for a courier
  segments.forEach((segment, idx) => {
    if (!segment.courier_id) {
      validation.isValid = false;
      validation.errors.push(`Segment ${idx + 1} has no assigned courier`);
    }
  });

  // 5) Verify location types (optional, if you want to ensure shipping_location vs. customer_address)
  // segments.forEach((segment, index) => {
  //   const sourceNode = nodes.find((n) => n.id === segment.start_location_id);
  //   const targetNode = nodes.find((n) => n.id === segment.end_location_id);

  //   if (
  //     segment.start_location_type !== getLocationType(sourceNode) ||
  //     segment.end_location_type !== getLocationType(targetNode)
  //   ) {
  //     validation.isValid = false;
  //     validation.errors.push(
  //       `Segment ${index + 1} has mismatched location types`
  //     );
  //   }
  // });

  return validation;
};

function getLocationType(node) {
  if (!node) return null;
  return node.data?.type === "delivery"
    ? "customer_address"
    : "shipping_location";
}

function detectLoops(edges) {
  const graph = {};
  edges.forEach(({ source, target }) => {
    if (!graph[source]) graph[source] = new Set();
    graph[source].add(target);
  });

  const visited = new Set();
  const recursionStack = new Set();

  function hasCycle(node) {
    if (!visited.has(node)) {
      visited.add(node);
      recursionStack.add(node);

      const neighbors = graph[node] || [];
      for (const nbr of neighbors) {
        if (!visited.has(nbr) && hasCycle(nbr)) {
          return true;
        } else if (recursionStack.has(nbr)) {
          return true;
        }
      }
    }
    recursionStack.delete(node);
    return false;
  }

  // Check every node in the graph
  for (const node in graph) {
    if (hasCycle(node)) return true;
  }
  return false;
}

/**
 * Splits orders from a consolidated node
 */
export const splitConsolidatedNode = (
  consolidatedNodeId,
  orderIds = [],
  locations = [],
  routePaths = {}
) => {
  // Find the consolidated node
  const consolidatedNode = locations.find(
    (node) => node?.id === consolidatedNodeId && node.isConsolidated
  );

  if (!consolidatedNode) {
    throw new Error("Consolidated node not found");
  }

  // Create new individual nodes for the split orders
  const newNodes = orderIds.map((orderId) => {
    const orderDetails = consolidatedNode.details?.orders?.find(
      (order) => order.order_id === orderId
    );
    const address = consolidatedNode.details?.addresses?.find(
      (addr) => addr.address_id === orderDetails?.recipient_address_id
    );

    if (!orderDetails || !address) {
      throw new Error(`Invalid order or address data for order ${orderId}`);
    }

    return {
      id: `delivery-${orderId}`,
      type: "delivery",
      label: `Delivery ${orderId}`,
      details: {
        ...address,
        order: orderDetails,
      },
    };
  });

  // Update the consolidated node's orders
  const remainingOrders = (consolidatedNode.details?.orders || []).filter(
    (order) => !orderIds.includes(order.order_id)
  );
  const remainingAddresses = (consolidatedNode.details?.addresses || []).filter(
    (addr) =>
      remainingOrders.some(
        (order) => order.recipient_address_id === addr.address_id
      )
  );

  const updatedConsolidatedNode = {
    ...consolidatedNode,
    label: `All Deliveries (${remainingOrders.length})`,
    details: {
      ...consolidatedNode.details,
      orders: remainingOrders,
      addresses: remainingAddresses,
    },
  };

  // Remove split orders from any edges connected to the consolidated node
  const updatedRoutePaths = { ...routePaths };
  Object.entries(updatedRoutePaths).forEach(([pathId, path]) => {
    if (!Array.isArray(path)) return;

    updatedRoutePaths[pathId] = path.map((edge) => {
      if (edge.to === consolidatedNodeId && Array.isArray(edge.orders)) {
        return {
          ...edge,
          orders: edge.orders.filter((id) => !orderIds.includes(id)),
        };
      }
      return edge;
    });
  });

  return {
    newNodes,
    updatedConsolidatedNode,
    updatedRoutePaths,
  };
};

/**
 * Merges individual delivery nodes back into a consolidated node
 */
export const mergeDeliveryNodes = (
  nodeIds = [],
  locations = [],
  routePaths = {}
) => {
  // Get the nodes to merge
  const nodesToMerge = locations.filter(
    (node) => nodeIds.includes(node?.id) && node?.type === "delivery"
  );

  if (nodesToMerge.length !== nodeIds.length) {
    throw new Error("Invalid nodes for merging");
  }

  // Create consolidated node
  const consolidatedNode = {
    id: "consolidated-deliveries",
    type: "delivery",
    label: `All Deliveries (${nodesToMerge.length})`,
    isConsolidated: true,
    details: {
      orders: nodesToMerge.map((node) => node.details?.order).filter(Boolean),
      addresses: nodesToMerge.map((node) => ({
        ...node.details,
        order: undefined,
      })),
    },
  };

  // Update route paths to point to consolidated node
  const updatedRoutePaths = { ...routePaths };
  Object.entries(updatedRoutePaths).forEach(([pathId, path]) => {
    if (!Array.isArray(path)) return;

    updatedRoutePaths[pathId] = path.map((edge) => {
      if (nodeIds.includes(edge.to)) {
        return {
          ...edge,
          to: consolidatedNode.id,
          orders: [
            ...(Array.isArray(edge.orders) ? edge.orders : []),
            edge.orderId,
          ].filter(Boolean),
        };
      }
      return edge;
    });
  });

  return {
    consolidatedNode,
    updatedRoutePaths,
  };
};

/**
 * Reassigns orders between edges
 */
export const reassignOrders = (
  sourceEdgeId,
  targetEdgeId,
  orderIds = [],
  routePaths = {}
) => {
  const updatedPaths = { ...routePaths };

  // Remove orders from source edge
  if (updatedPaths[sourceEdgeId]) {
    updatedPaths[sourceEdgeId] = updatedPaths[sourceEdgeId]
      .map((segment) => {
        const updatedOrders = segment.orders.filter(
          (id) => !orderIds.includes(id)
        );
        return {
          ...segment,
          orders: updatedOrders,
          // Set isConsolidated based on orders count
          isConsolidated: updatedOrders.length > 1,
        };
      })
      .filter((segment) => segment.orders.length > 0); // Remove segments with no orders
  }

  // Add orders to target edge
  if (updatedPaths[targetEdgeId]) {
    updatedPaths[targetEdgeId] = updatedPaths[targetEdgeId].map((segment) => {
      const updatedOrders = [...new Set([...segment.orders, ...orderIds])];
      return {
        ...segment,
        orders: updatedOrders,
        // Set isConsolidated based on orders count
        isConsolidated: updatedOrders.length > 1,
      };
    });
  } else {
    // If target edge doesn't exist, create it
    updatedPaths[targetEdgeId] = orderIds.map((orderId) => ({
      from: targetEdgeId.split("-")[0],
      to: targetEdgeId.split("-")[1],
      courier: null,
      orders: [orderId],
      // Set isConsolidated based on orders count
      isConsolidated: orderIds.length > 1,
    }));
  }

  return updatedPaths;
};
