import React, { useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { useEmployeePermissions } from "../../components/Auth/EmployeePermissions";
import { EmployeePageLayout, PermissionActionButton } from "../../components/Employee/PageComponents";
import UnifiedCustomerManager from "../../components/Customer/UnifiedCustomerManager";
import { Alert, Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button, FormHelperText } from "@mui/material";
import { PersonAdd as PersonAddIcon, Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";
import { useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext";

const EmployeeCustomersPage = () => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const {
    currentBusiness,
    dashboardData,
    loading: layoutLoading,
    error: layoutError,
  } = useOutletContext();
  const permissions = useEmployeePermissions();
  
  // State for the add customer dialog
  const [isAddCustomerDialogOpen, setIsAddCustomerDialogOpen] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // If employee doesn't have permission to view customers
  if (!permissions.canManageCustomers) {
    return (
      <Alert severity="warning">
        You don't have permission to access customer management.
      </Alert>
    );
  }

  // Get role-specific title
  const getPageTitle = () => {
    const employeeRoles = dashboardData?.employee?.roles || [];
    if (employeeRoles.includes("Sales Representative")) {
      return "Customer Accounts";
    } else if (employeeRoles.includes("Customer Service")) {
      return "Customer Support";
    }
    return "Customer Management";
  };

  // Add Customer button as title action
  const titleActions = (
    <PermissionActionButton
      permission="canManageCustomers"
      icon={PersonAddIcon}
      label="Add Customer"
      onClick={() => setIsAddCustomerDialogOpen(true)}
    />
  );

  // Validate customer fields
  const validateFields = (customer) => {
    const errors = {};
    if (!customer.first_name?.trim())
      errors.first_name = "First Name is required";
    if (!customer.last_name?.trim()) 
      errors.last_name = "Last Name is required";
    if (!customer.email && !customer.phone) {
      errors.email = "Either email or phone is required";
      errors.phone = "Either email or phone is required";
    }
    if (
      customer.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customer.email)
    ) {
      errors.email = "Email is not valid";
    }
    return errors;
  };

  // Handle field changes
  const handleFieldChange = (field, value) => {
    setNewCustomer((prev) => ({
      ...prev,
      [field]: value,
    }));
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setIsAddCustomerDialogOpen(false);
    setNewCustomer({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    });
    setFormErrors({});
    setAlertMessage(null);
  };

  // Handle save new customer
  const handleSaveNewCustomer = async () => {
    // Validate fields
    const errors = validateFields(newCustomer);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsSubmitting(true);

    try {
      await api.post("/customers/add", {
        business_id: businessId,
        ...newCustomer,
      });
      
      handleCloseDialog();
      setRefreshTrigger(prev => prev + 1);
      
    } catch (error) {
      console.error("Error adding customer:", error);
      
      setAlertMessage({
        type: "error",
        message: error.response?.data?.msg || "Failed to add customer. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <EmployeePageLayout
      title={getPageTitle()}
      titleActions={titleActions}
      loading={layoutLoading}
      error={layoutError}
      maxWidth="wide"
    >
      <UnifiedCustomerManager 
        businessId={businessId} 
        isEmployeePortal={true} 
        refreshTrigger={refreshTrigger}
      />

      {/* Add New Customer Dialog */}
      <Dialog
        open={isAddCustomerDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
      >
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={newCustomer.first_name}
                error={!!formErrors.first_name}
                helperText={formErrors.first_name}
                onChange={(e) => handleFieldChange("first_name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={newCustomer.last_name}
                error={!!formErrors.last_name}
                helperText={formErrors.last_name}
                onChange={(e) => handleFieldChange("last_name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={newCustomer.email}
                error={!!formErrors.email}
                helperText={formErrors.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                margin="dense"
                label="Phone"
                fullWidth
                value={newCustomer.phone}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
                onChange={(value) => handleFieldChange("phone", value)}
              />
            </Grid>
          </Grid>
          
          {alertMessage && (
            <Alert
              severity={alertMessage.type}
              sx={{ mt: 2 }}
              onClose={() => setAlertMessage(null)}
            >
              {alertMessage.message}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            startIcon={<CancelIcon />}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSaveNewCustomer} 
            variant="contained" 
            color="primary"
            startIcon={<SaveIcon />}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </EmployeePageLayout>
  );
};

export default EmployeeCustomersPage;
