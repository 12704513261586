import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import styles from "./EmployeeBusinessesList.module.css";

const EmployeeBusinessesList = () => {
  const [employeeBusinesses, setEmployeeBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { api } = useContext(AuthContext);

  useEffect(() => {
    fetchEmployeeBusinesses();
  }, []);

  const fetchEmployeeBusinesses = async () => {
    try {
      const response = await api.get("/employees/my-businesses");
      console.log("Employee businesses:", response.data.businesses);
      // filter out businesses where employee_roles includes "Owner"
      response.data.businesses = response.data.businesses.filter(
        (business) => !business.employee_roles?.includes("Owner")
      );
      setEmployeeBusinesses(response.data.businesses);
    } catch (error) {
      console.error("Error fetching employee businesses:", error);
      setError("Failed to fetch businesses you work for. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to format roles for display
  const formatRoles = (roles) => {
    if (!roles || roles.length === 0) return "No roles assigned";
    return roles.join(", ");
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (employeeBusinesses.length === 0) {
    return null;
  }

  return (
    <div>
      <h1 className={styles.title}>Businesses You Work For</h1>
      <div className={styles.businessesList}>
        {employeeBusinesses.map((business) => (
          <Card
            key={business.employee_id}
            className={styles.businessCard}
            onClick={() =>
              navigate(`/employee/${business.business_id}/dashboard`)
            }
          >
            <CardContent>
              <Typography variant="h6">
                {business.business_name || "Unnamed Business"}
              </Typography>
              <div className={styles.rolesContainer}>
                {business.employee_roles?.map((role) => (
                  <Chip
                    key={role}
                    label={role}
                    size="small"
                    className={styles.roleChip}
                  />
                )) || <Chip label="No roles" size="small" />}
              </div>
              <Typography variant="body2">
                Status: {business.employee_status || "Unknown"}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default EmployeeBusinessesList;
