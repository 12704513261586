import React, { useEffect, useContext, useMemo, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  FaTachometerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaStore,
  FaTruck,
  FaWarehouse,
  FaUsers,
  FaClipboardList,
  FaMoneyBillWave,
  FaCog,
  FaChartLine,
  FaShoppingCart,
  FaTools,
  FaGlobe,
  FaBoxOpen,
  FaBox,
  FaRoad,
  FaUserTie,
  FaUserShield,
} from "react-icons/fa";
import logo from "../../assets/logo-plain.svg";
import "./BusinessSidebar.css";
import { AuthContext } from "../Auth/AuthContext";
import { useBusiness } from "../Auth/BusinessContext";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "./SidebarContext";

const BusinessSidebar = () => {
  const location = useLocation();
  const { businessId } = useParams();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { getBusinessById, businessCategories, fetchBusinessCategories } =
    useBusiness();
  const { isBusinessSidebarActive, toggleBusinessSidebar } = useSidebar();

  // Reference for the logo image
  const logoRef = useRef(null);
  // Memoize business category
  const businessCategory = useMemo(() => {
    const business = getBusinessById(businessId);
    return (
      businessCategories.find(
        (category) => category.category_id === business?.business_category_id
      )?.category_name || business?.business_category_name
    );
  }, [businessId, getBusinessById, businessCategories]);

  const isLinkActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLogout = async () => {
    await logout();
    navigate("/auth");
  };

  const commonLinks = [
    {
      path: `/business/${businessId}/dashboard`,
      icon: <FaTachometerAlt />,
      text: "Dashboard",
    },
    {
      path: `/business/${businessId}/profile`,
      icon: <FaStore />,
      text: "Business Profile",
    },
    {
      path: `/business/${businessId}/employees`,
      icon: <FaUserTie />,
      text: "Employees",
    },
    {
      path: `/business/${businessId}/customers`,
      icon: <FaUsers />,
      text: "Customers",
    },
    {
      path: `/business/${businessId}/finances`,
      icon: <FaMoneyBillWave />,
      text: "Finances",
    },
    {
      path: `/business/${businessId}/analytics`,
      icon: <FaChartLine />,
      text: "Analytics",
    },
    {
      path: `/business/${businessId}/settings`,
      icon: <FaCog />,
      text: "Settings",
    },
  ];

  const categorySpecificLinks = {
    "Shipping and Logistics": [
      {
        path: `/business/${businessId}/shipments`,
        icon: <FaBox />,
        text: "Shipments",
      },
      {
        path: `/business/${businessId}/assets/`,
        icon: <FaTruck />,
        text: "Asset Management",
      },
      {
        path: `/business/${businessId}/location-tracking`,
        icon: <FaRoad />,
        text: "Location Tracking",
      },
      {
        path: `/business/${businessId}/payments`,
        icon: <FaMoneyBillWave />,
        text: "Collaboration Payments",
      },
    ],
    "Local Delivery": [
      {
        path: `/business/${businessId}/shipments`,
        icon: <FaBox />,
        text: "Shipments",
      },
      {
        path: `/business/${businessId}/assets/`,
        icon: <FaTruck />,
        text: "Asset Management",
      },
      {
        path: `/business/${businessId}/location-tracking`,
        icon: <FaRoad />,
        text: "Location Tracking",
      },
      {
        path: `/business/${businessId}/payments`,
        icon: <FaMoneyBillWave />,
        text: "Collaboration Payments",
      },
    ],
    "International Shipping": [
      {
        path: `/business/${businessId}/shipments`,
        icon: <FaBox />,
        text: "Shipments",
      },
      {
        path: `/business/${businessId}/assets/`,
        icon: <FaTruck />,
        text: "Asset Management",
      },
      {
        path: `/business/${businessId}/location-tracking`,
        icon: <FaRoad />,
        text: "Location Tracking",
      },
    ],
    "Warehousing and Storage": [
      {
        path: `/business/${businessId}/inventory`,
        icon: <FaWarehouse />,
        text: "Inventory",
      },
      {
        path: `/business/${businessId}/stock-management`,
        icon: <FaBoxOpen />,
        text: "Stock Management",
      },
      {
        path: `/business/${businessId}/space-utilization`,
        icon: <FaChartLine />,
        text: "Space Utilization",
      },
    ],
    Shopping: [
      {
        path: `/business/${businessId}/products`,
        icon: <FaShoppingCart />,
        text: "Products",
      },
      {
        path: `/business/${businessId}/orders`,
        icon: <FaClipboardList />,
        text: "Orders",
      },
      {
        path: `/business/${businessId}/inventory`,
        icon: <FaWarehouse />,
        text: "Inventory",
      },
    ],
    Rentals: [
      {
        path: `/business/${businessId}/equipment`,
        icon: <FaTools />,
        text: "Equipment",
      },
      {
        path: `/business/${businessId}/bookings`,
        icon: <FaClipboardList />,
        text: "Bookings",
      },
      {
        path: `/business/${businessId}/maintenance`,
        icon: <FaTools />,
        text: "Maintenance",
      },
    ],
    "Service Business": [
      {
        path: `/business/${businessId}/appointments`,
        icon: <FaClipboardList />,
        text: "Appointments",
      },
      {
        path: `/business/${businessId}/services`,
        icon: <FaTools />,
        text: "Services",
      },
      {
        path: `/business/${businessId}/staff`,
        icon: <FaUsers />,
        text: "Staff Management",
      },
    ],
  };

  const renderLinks = (links) => {
    return links.map(({ path, icon, text }) => (
      <Link key={path} to={path} className={isLinkActive(path)}>
        {icon}
        <span>{isBusinessSidebarActive && text}</span>
      </Link>
    ));
  };

  // Prepare the sidebar logo - memoized to prevent rerenders
  const sidebarLogo = useMemo(
    () => (
      <div className="sidebar-logo">
        <img
          ref={logoRef}
          src={logo}
          alt="Cexpress Logo"
          className="logo"
          style={{
            transition: "opacity 0.3s ease, width 0.3s ease", // Smooth transitions
            width: isBusinessSidebarActive ? "80%" : "70%", // Adjust size based on sidebar state
            maxHeight: "40px",
            objectFit: "contain", // Maintain aspect ratio
          }}
          onError={(e) => {
            // If the main image fails, use the inline version
            if (e.target.src !== e.target.dataset.src) {
              e.target.src = e.target.dataset.src;
            }
          }}
        />
      </div>
    ),
    [isBusinessSidebarActive]
  ); // Only re-render when sidebar state changes

  return (
    <aside
      className={`business-sidebar ${isBusinessSidebarActive ? "active" : ""}`}
    >
      <div className="sidebar-top">
        {sidebarLogo}
        <button onClick={toggleBusinessSidebar} className="sidebar-toggle">
          {isBusinessSidebarActive ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      {isBusinessSidebarActive && (
        <div className="employee-role-banner">
          <FaUserShield className="role-icon" />
          <span>{businessCategory}</span>
        </div>
      )}
      <nav>
        {renderLinks(commonLinks)}
        {businessCategory && categorySpecificLinks[businessCategory] && (
          <>
            <div className="sidebar-divider"></div>
            {renderLinks(categorySpecificLinks[businessCategory])}
          </>
        )}
      </nav>
      <div className="sidebar-bottom">
        <button onClick={handleLogout} className="sidebar-link">
          <FaSignOutAlt />
          <span>{isBusinessSidebarActive && "Log Out"}</span>
        </button>
      </div>
    </aside>
  );
};

export default BusinessSidebar;
