import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  styled,
  FormControlLabel,
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemText,
  Alert,
  Link,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import "./LoginForm.css";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessageContainer = styled("div")({
  color: "#856404",
  backgroundColor: "#fff3cd",
  padding: "15px",
  borderRadius: "5px",
  border: "1px solid #ffeeba",
  marginTop: "15px",
  marginBottom: "15px",
  textAlign: "left",
  width: "100%",
  boxSizing: "border-box",
});

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  const { login, api } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessages([]);
    setNeedsVerification(false);

    try {
      console.log("Attempting login with:", { email, password, rememberMe });
      // Attempt login
      await login(email, password, rememberMe);
      console.log("Login successful - PublicRoute should redirect automatically");
    } catch (error) {
      console.error("Login error:", error);
      console.error("Response data:", error.response?.data);

      if (error.response) {
        // Handle specific error types
        if (
          error.response.status === 403 &&
          error.response.data.error === "Email not verified"
        ) {
          setNeedsVerification(true);
        } else if (error.response.status === 429) {
          // Rate limiting error
          setErrorMessages([
            "Too many login attempts. Please try again later.",
          ]);
        } else if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          // Multiple validation errors from the server
          setErrorMessages(
            error.response.data.errors.map((err) => err.msg || err)
          );
        } else if (error.response.data.error) {
          // Single error message
          setErrorMessages([
            typeof error.response.data.error === "string"
              ? error.response.data.error
              : "Login failed. Please check your credentials.",
          ]);
        } else {
          // Fallback error message
          setErrorMessages(["Login failed. Please check your credentials."]);
        }
      } else {
        // Network or other error
        setErrorMessages([
          error.message || "An unexpected error occurred. Please try again.",
        ]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      // Implement resend verification email functionality here
      setIsLoading(true);
      await api.post("/auth/resend-verification", { email });
      setErrorMessages([
        "Verification email has been resent. Please check your inbox.",
      ]);
      setNeedsVerification(false);
    } catch (error) {
      setErrorMessages([
        "Failed to resend verification email. Please try again.",
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" className="login-form" onSubmit={handleSubmit}>
      {needsVerification ? (
        <Alert severity="warning" sx={{ mb: 2 }}>
          <Typography variant="body1" gutterBottom>
            Your email is not verified. Please check your inbox for the
            verification email.
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={handleResendVerification}
            disabled={isLoading}
          >
            Resend Verification Email
          </Button>
        </Alert>
      ) : (
        errorMessages.length > 0 && (
          <ErrorMessageContainer>
            <Typography variant="subtitle1" gutterBottom>
              Login failed:
            </Typography>
            <List dense>
              {errorMessages.map((msg, idx) => (
                <ListItem key={idx} disableGutters>
                  <ListItemText primary={`• ${msg}`} />
                </ListItem>
              ))}
            </List>
          </ErrorMessageContainer>
        )
      )}

      <TextField
        variant="outlined"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        fullWidth
        margin="normal"
        error={errorMessages.length > 0}
      />
      <TextField
        variant="outlined"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        fullWidth
        margin="normal"
        error={errorMessages.length > 0}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
        }
        label="Remember Me"
      />
      <CustomButton
        type="submit"
        variant="contained"
        fullWidth
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Log In"}
      </CustomButton>
    </Box>
  );
};

export default LoginForm;
