import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useEmployeePermissions } from "../../components/Auth/EmployeePermissions";
import { EmployeePageLayout, PermissionActionButton } from "../../components/Employee/PageComponents";
import {
  TextField,
  Alert,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Pagination,
  CircularProgress,
  Box,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  PersonAdd as PersonAddIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Replay as ReplayIcon,
} from "@mui/icons-material";

const EmployeeEmployeesPage = () => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  
  // Get context from layout
  const {
    currentBusiness,
    dashboardData,
    loading: layoutLoading,
    error: layoutError,
  } = useOutletContext();
  
  // Use our centralized permissions system
  const permissions = useEmployeePermissions();

  // State
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState("employee_first_name");
  const [order, setOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isAddEmployeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);
  const [editEmployee, setEditEmployee] = useState(null);
  const [deleteEmployee, setDeleteEmployee] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [newEmployeeAlertMessage, setNewEmployeeAlertMessage] = useState(null);
  const [existingEmployeeAlertMessage, setExistingEmployeeAlertMessage] = useState(null);
  const [newEmployee, setNewEmployee] = useState({
    employee_first_name: "",
    employee_last_name: "",
    employee_email: "",
    employee_phone: "",
    employee_roles: [],
    employee_hire_date: "",
    employment_status: "",
    employee_notes: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [filters, setFilters] = useState({
    role: "",
    status: "",
  });
  
  const EMPLOYEE_ROLES = [
    "Manager",
    "Supervisor",
    "Courier",
    "Cashier",
    "Sales Representative",
    "Customer Service",
    "Administrator",
    "Accountant",
  ];

  // Define the fetchEmployees function
  const fetchEmployees = useCallback(async () => {
    try {
      setLoading(true);
      
      const response = await api.get("/employees", {
        params: {
          business_id: businessId,
        },
      });
      
      setEmployees(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching employees:", err);
      setError(err.response?.data?.error || "Failed to load employees");
    } finally {
      setLoading(false);
    }
  }, [api, businessId]);
  
  // Load employees data from the API
  useEffect(() => {
    if (businessId) {
      fetchEmployees();
    }
  }, [businessId, fetchEmployees]);

  const handleRetry = () => {
    setLoading(true);
    fetchEmployees();
  };

  // Get role-specific title
  const getPageTitle = () => {
    const employeeRoles = dashboardData?.employee?.roles || [];
    if (employeeRoles.includes("Administrator")) {
      return "Employee Administration";
    } else if (employeeRoles.includes("Manager")) {
      return "Team Management";
    }
    return "Manage Employees";
  };

  // If employee doesn't have permission to manage employees
  if (!permissions.canManageEmployees) {
    return (
      <Alert severity="warning">
        You don't have permission to access employee management.
      </Alert>
    );
  }

  // Add Employee button as title action
  const titleActions = (
    <PermissionActionButton
      permission="canManageEmployees"
      icon={PersonAddIcon}
      label="Add Employee"
      onClick={() => setIsAddEmployeeDialogOpen(true)}
    />
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleAddEmployee = () => {
    setIsAddEmployeeDialogOpen(true);
  };

  const handleEditEmployee = (employee) => {
    setEditEmployee(employee);
    setIsDialogOpen(true);
  };

  const handleDeleteEmployee = (employee) => {
    setDeleteEmployee(employee);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setExistingEmployeeAlertMessage(null);
    setNewEmployeeAlertMessage(null);
    setIsDialogOpen(false);
    setEditEmployee(null);
    setDeleteEmployee(null);
  };

  const handleCloseAddEmployeeDialog = () => {
    setNewEmployeeAlertMessage(null);
    setFormErrors({});
    setIsAddEmployeeDialogOpen(false);
    setNewEmployee({
      employee_first_name: "",
      employee_last_name: "",
      employee_email: "",
      employee_phone: "",
      employee_roles: [],
      employee_hire_date: "",
      employment_status: "",
      employee_notes: "",
    });
  };

  const validateFields = (employee) => {
    const errors = {};
    if (!employee.employee_first_name?.trim())
      errors.employee_first_name = "First Name is required";
    if (!employee.employee_last_name?.trim())
      errors.employee_last_name = "Last Name is required";
    if (!employee.employee_email?.trim())
      errors.employee_email = "Email is required";
    if (
      employee.employee_email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(employee.employee_email)
    ) {
      errors.employee_email = "Email is not valid";
    }
    if (!employee.employee_roles || employee.employee_roles.length === this)
      errors.employee_roles = "At least one role is required";
    if (!employee.employee_hire_date)
      errors.employee_hire_date = "Hire date is required";
    if (!employee.employment_status)
      errors.employment_status = "Employment status is required";
    return errors;
  };

  const handleFieldChange = (field, value, setEmployee) => {
    setEmployee((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        [field]: value,
      };
    });

    // Clear error for this specific field
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleResendInvitation = async (employee) => {
    try {
      await api.post(`/employees/${businessId}/resend-invitation`, {
        employeeId: employee.employee_id,
      });
      setAlertMessage({
        type: "success",
        message: "Invitation resent successfully.",
      });
    } catch (error) {
      console.error("Error resending invitation:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to resend invitation. Please try again.",
      });
    }
  };

  const handleSaveEmployee = async () => {
    setExistingEmployeeAlertMessage(null);
    const errors = validateFields(editEmployee);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.put(
        `/employees/${businessId}/${editEmployee.employee_id}`,
        editEmployee
      );
      setAlertMessage({
        type: "success",
        message: "Employee updated successfully.",
      });
      fetchEmployees();
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setExistingEmployeeAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to update employee. Please try again.",
        });
      }
    }
  };

  const handleSaveNewEmployee = async () => {
    setNewEmployeeAlertMessage(null);
    setFormErrors({});

    const errors = validateFields(newEmployee);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setNewEmployeeAlertMessage({
        type: "error",
        message: "Please fix the highlighted fields",
      });
      return;
    }

    setLoading(true);
    try {
      await api.post(
        `/employees/${businessId}`,
        newEmployee,
        {}
      );
      setAlertMessage({
        type: "success",
        message: `Employee ${newEmployee.employee_first_name} ${newEmployee.employee_last_name} added successfully. Invitation email sent.`,
      });
      fetchEmployees();
      handleCloseAddEmployeeDialog();
    } catch (error) {
      console.error("Error adding employee:", error);
      
      let errorMessage = "Failed to add employee. Please try again.";
      let fieldErrors = {};

      if (error.response?.data) {
        if (error.response.data.msg) {
          errorMessage = error.response.data.msg;
        }
        
        if (error.response.data.formErrors) {
          fieldErrors = error.response.data.formErrors;
        } else if (error.response.data.fieldErrors) {
          fieldErrors = error.response.data.fieldErrors;
        }
      }

      setNewEmployeeAlertMessage({
        type: "error",
        message: errorMessage,
      });

      setFormErrors(fieldErrors);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(
        `/employees/${businessId}/${deleteEmployee.employee_id}`
      );
      setAlertMessage({
        type: "success",
        message: "Employee deleted successfully.",
      });
      fetchEmployees();
    } catch (error) {
      console.error("Error deleting employee:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete employee. Please try again.",
      });
    }
    handleCloseDialog();
  };

  // Filtered and sorted employees
  const filteredEmployees = employees.filter((employee) => {
    // Search filter
    const matchesSearch = 
      employee.employee_first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.employee_last_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.employee_email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      employee.employee_phone?.toLowerCase().includes(searchQuery.toLowerCase());
      
    // Role filter
    const matchesRole = 
      !filters.role || 
      (employee.employee_roles && employee.employee_roles.includes(filters.role));
      
    // Status filter
    const matchesStatus = 
      !filters.status || 
      employee.employment_status === filters.status;
      
    return matchesSearch && matchesRole && matchesStatus;
  });

  // Sort employees
  const sortedEmployees = [...filteredEmployees].sort((a, b) => {
    if (orderBy === "employee_roles") {
      const rolesA = (a[orderBy] || []).join(", ");
      const rolesB = (b[orderBy] || []).join(", ");
      if (rolesA < rolesB) return order === "asc" ? -1 : 1;
      if (rolesA > rolesB) return order === "asc" ? 1 : -1;
      return 0;
    }
    if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    return 0;
  });

  // Paginated employees
  const paginatedEmployees = (() => {
    const start = (currentPage - 1) * itemsPerPage;
    return sortedEmployees.slice(start, start + itemsPerPage);
  })();

  // Get unique roles for filter
  const uniqueRoles = (() => {
    const allRoles = employees.reduce((acc, employee) => {
      if (employee.employee_roles && Array.isArray(employee.employee_roles)) {
        return [...acc, ...employee.employee_roles];
      }
      return acc;
    }, []);
    return [...new Set(allRoles)].filter(Boolean);
  })();

  // Get unique statuses for filter
  const uniqueStatuses = (() => {
    return [...new Set(employees.map((e) => e.employment_status))].filter(Boolean);
  })();

  // Render roles field for forms
  const renderRolesField = (employee, setEmployee, isEditMode = false) => (
    <FormControl fullWidth margin="dense" error={!!formErrors.employee_roles}>
      <InputLabel>Roles</InputLabel>
      <Select
        multiple
        value={employee?.employee_roles || []}
        onChange={(e) =>
          handleFieldChange("employee_roles", e.target.value, setEmployee)
        }
        renderValue={(selected) => selected.join(", ")}
      >
        {EMPLOYEE_ROLES.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </Select>
      {formErrors.employee_roles && (
        <FormHelperText error>{formErrors.employee_roles}</FormHelperText>
      )}
    </FormControl>
  );
  
  // Content to render in the employee page
  const renderEmployeeContent = () => (
    <>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          variant="outlined"
          sx={{ mb: 3 }}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage.message}
        </Alert>
      )}
      
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Search Employees"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
        
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={filters.role}
              onChange={(e) => handleFilterChange("role", e.target.value)}
              label="Role"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueRoles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel>Items Per Page</InputLabel>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items Per Page"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ mb: 3, width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              {[
                { id: "employee_first_name", label: "First Name" },
                { id: "employee_last_name", label: "Last Name" },
                { id: "employee_email", label: "Email" },
                { id: "employee_phone", label: "Phone" },
                { id: "employee_roles", label: "Roles" },
                { id: "employee_hire_date", label: "Hire Date" },
                { id: "employment_status", label: "Status" },
                { id: "employee_status", label: "Employee Status" },
              ].map((column) => (
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {paginatedEmployees.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No employees found
                </TableCell>
              </TableRow>
            ) : (
              paginatedEmployees.map((employee) => (
                <TableRow key={employee.employee_id}>
                  <TableCell>{employee.employee_first_name}</TableCell>
                  <TableCell>{employee.employee_last_name}</TableCell>
                  <TableCell>{employee.employee_email}</TableCell>
                  <TableCell>{employee.employee_phone}</TableCell>
                  <TableCell>{employee.employee_roles?.join(", ") || ""}</TableCell>
                  <TableCell>{employee.employee_hire_date}</TableCell>
                  <TableCell>{employee.employment_status}</TableCell>
                  <TableCell>{employee.employee_status}</TableCell>
                  <TableCell>
                    {employee.employee_status === "pending" && (
                      <Tooltip title="Resend Invitation">
                        <IconButton 
                          onClick={() => handleResendInvitation(employee)}
                          size="small"
                        >
                          <ReplayIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Edit">
                      <IconButton 
                        onClick={() => handleEditEmployee(employee)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteEmployee(employee)}
                        disabled={
                          employee.employee_roles &&
                          employee.employee_roles?.includes("Owner")
                        }
                        size="small"
                        sx={{ 
                          '&:not(:disabled)': { 
                            color: 'error.main' 
                          } 
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(sortedEmployees.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      </Box>

      {/* Edit Employee Dialog */}
      <Dialog open={isDialogOpen && editEmployee} onClose={handleCloseDialog}>
        <DialogTitle>Edit Employee</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={editEmployee?.employee_first_name || ""}
                error={!!formErrors.employee_first_name}
                helperText={formErrors.employee_first_name}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_first_name",
                    e.target.value,
                    setEditEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={editEmployee?.employee_last_name || ""}
                error={!!formErrors.employee_last_name}
                helperText={formErrors.employee_last_name}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_last_name",
                    e.target.value,
                    setEditEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                required
                value={editEmployee?.employee_email || ""}
                error={!!formErrors.employee_email}
                helperText={formErrors.employee_email}
                onChange={(e) =>
                  handleFieldChange("employee_email", e.target.value, setEditEmployee)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                margin="dense"
                label="Phone"
                fullWidth
                value={editEmployee?.employee_phone || ""}
                error={!!formErrors.employee_phone}
                helperText={formErrors.employee_phone}
                onChange={(value) =>
                  handleFieldChange("employee_phone", value, setEditEmployee)
                }
              />
            </Grid>
            <Grid item xs={12}>
              {renderRolesField(editEmployee, setEditEmployee, true)}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Hire Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={editEmployee?.employee_hire_date || ""}
                error={!!formErrors.employee_hire_date}
                helperText={formErrors.employee_hire_date}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_hire_date",
                    e.target.value,
                    setEditEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Employment Status</InputLabel>
                <Select
                  value={editEmployee?.employment_status || ""}
                  onChange={(e) =>
                    handleFieldChange(
                      "employment_status",
                      e.target.value,
                      setEditEmployee
                    )
                  }
                  error={!!formErrors.employment_status}
                >
                  <MenuItem value="full-time">Full Time</MenuItem>
                  <MenuItem value="part-time">Part Time</MenuItem>
                  <MenuItem value="contract">Contract</MenuItem>
                  <MenuItem value="intern">Intern</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                multiline
                rows={4}
                value={editEmployee?.employee_notes || ""}
                onChange={(e) =>
                  handleFieldChange("employee_notes", e.target.value, setEditEmployee)
                }
              />
            </Grid>
          </Grid>
          
          {existingEmployeeAlertMessage && (
            <Alert
              severity={existingEmployeeAlertMessage.type}
              variant="outlined"
              sx={{ mt: 2 }}
              onClose={() => setExistingEmployeeAlertMessage(null)}
            >
              {existingEmployeeAlertMessage.message}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleSaveEmployee} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Employee Dialog */}
      <Dialog open={isDialogOpen && deleteEmployee} onClose={handleCloseDialog}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete{" "}
            {deleteEmployee?.employee_first_name}{" "}
            {deleteEmployee?.employee_last_name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Employee Dialog */}
      <Dialog
        open={isAddEmployeeDialogOpen}
        onClose={handleCloseAddEmployeeDialog}
        maxWidth="md"
      >
        <DialogTitle>Add New Employee</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={newEmployee.employee_first_name}
                error={!!formErrors.employee_first_name}
                helperText={formErrors.employee_first_name || ""}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_first_name",
                    e.target.value,
                    setNewEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={newEmployee.employee_last_name}
                error={!!formErrors.employee_last_name}
                helperText={formErrors.employee_last_name || ""}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_last_name",
                    e.target.value,
                    setNewEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                required
                value={newEmployee.employee_email}
                error={!!formErrors.employee_email}
                helperText={formErrors.employee_email || ""}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_email",
                    e.target.value,
                    setNewEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                margin="dense"
                label="Phone"
                fullWidth
                value={newEmployee.employee_phone}
                error={!!formErrors.employee_phone}
                helperText={formErrors.employee_phone || ""}
                onChange={(value) =>
                  handleFieldChange("employee_phone", value, setNewEmployee)
                }
              />
            </Grid>
            <Grid item xs={12}>
              {renderRolesField(newEmployee, setNewEmployee)}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Hire Date"
                type="date"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                value={newEmployee.employee_hire_date}
                error={!!formErrors.employee_hire_date}
                helperText={formErrors.employee_hire_date || ""}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_hire_date",
                    e.target.value,
                    setNewEmployee
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                margin="dense"
                error={!!formErrors.employment_status}
              >
                <InputLabel>Employment Status</InputLabel>
                <Select
                  value={newEmployee.employment_status}
                  onChange={(e) =>
                    handleFieldChange(
                      "employment_status",
                      e.target.value,
                      setNewEmployee
                    )
                  }
                >
                  <MenuItem value="full-time">Full Time</MenuItem>
                  <MenuItem value="part-time">Part Time</MenuItem>
                  <MenuItem value="contract">Contract</MenuItem>
                  <MenuItem value="intern">Intern</MenuItem>
                </Select>
                {formErrors.employment_status && (
                  <FormHelperText error>
                    {formErrors.employment_status}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                multiline
                rows={4}
                value={newEmployee.employee_notes}
                onChange={(e) =>
                  handleFieldChange(
                    "employee_notes",
                    e.target.value,
                    setNewEmployee
                  )
                }
              />
            </Grid>
          </Grid>
          
          {newEmployeeAlertMessage && (
            <Alert
              severity={newEmployeeAlertMessage.type}
              onClose={() => setNewEmployeeAlertMessage(null)}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              {newEmployeeAlertMessage.message}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button
            onClick={handleCloseAddEmployeeDialog}
            color="inherit"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveNewEmployee}
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Save Employee
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  return (
    <EmployeePageLayout
      title={getPageTitle()}
      titleActions={titleActions}
      loading={layoutLoading || loading}
      error={layoutError || error}
      onRetry={handleRetry}
      maxWidth="wide"
    >
      {renderEmployeeContent()}
    </EmployeePageLayout>
  );
};

export default EmployeeEmployeesPage;
