import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Chip,
  Card,
  CardContent,
  CardHeader,
  Pagination,
  TableSortLabel,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

const CourierList = ({
  couriers,
  businessId,
  onCourierAdded,
  onCourierUpdated,
  onCourierDeleted,
}) => {
  const [open, setOpen] = useState(false);
  const [editingCourier, setEditingCourier] = useState(null);
  const [formData, setFormData] = useState({
    employee_first_name: "",
    employee_last_name: "",
    employee_email: "",
    employee_phone: "",
    employee_hire_date: "",
    employment_status: "",
    employee_notes: "",
    employee_roles: ["Courier"], // Changed from employee_role to employee_roles array
  });
  const [formErrors, setFormErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    field: "employee_first_name",
    direction: "asc",
  });
  const [filters, setFilters] = useState({
    status: "",
  });

  // Filter and sort couriers based on search query, filters, and sort config
  const filteredCouriers = useMemo(() => {
    // First, filter out non-couriers
    let result = couriers.filter(
      (courier) =>
        courier.employee_roles && courier.employee_roles.includes("Courier")
    );

    // Filter by search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(
        (courier) =>
          courier.employee_first_name?.toLowerCase().includes(query) ||
          courier.employee_last_name?.toLowerCase().includes(query) ||
          courier.employee_email?.toLowerCase().includes(query) ||
          courier.employee_phone?.includes(query)
      );
    }

    // Filter by employment status
    if (filters.status) {
      result = result.filter(
        (courier) => courier.employment_status === filters.status
      );
    }

    // Sort the results
    result.sort((a, b) => {
      let aValue = a[sortConfig.field] || "";
      let bValue = b[sortConfig.field] || "";

      if (typeof aValue === "string") aValue = aValue.toLowerCase();
      if (typeof bValue === "string") bValue = bValue.toLowerCase();

      if (sortConfig.direction === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return bValue > aValue ? 1 : -1;
      }
    });

    return result;
  }, [couriers, searchQuery, filters, sortConfig]);

  const handleOpen = (courier = null) => {
    if (courier) {
      setEditingCourier(courier);
      setFormData(courier);
    } else {
      setEditingCourier(null);
      setFormData({
        employee_first_name: "",
        employee_last_name: "",
        employee_email: "",
        employee_phone: "",
        employee_hire_date: "",
        employment_status: "",
        employee_notes: "",
        employee_roles: ["Courier"], // Initialize with Courier role
      });
    }
    setOpen(true);
    setAlertMessage(null);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCourier(null);
    setFormErrors({});
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setFormErrors({ ...formErrors, [field]: "" });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.employee_first_name)
      errors.employee_first_name = "First name is required";
    if (!formData.employee_last_name)
      errors.employee_last_name = "Last name is required";
    if (!formData.employee_email) errors.employee_email = "Email is required";
    if (!formData.employee_phone) errors.employee_phone = "Phone is required";
    if (!formData.employee_hire_date)
      errors.employee_hire_date = "Hire date is required";
    if (!formData.employment_status)
      errors.employment_status = "Employment status is required";
    if (!formData.employee_roles || formData.employee_roles.length === 0)
      errors.employee_roles = "At least one role is required";
    if (!formData.employee_roles.includes("Courier"))
      errors.employee_roles = "Courier must be one of the roles";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      // setIsLoading(true);
      try {
        if (editingCourier) {
          await onCourierUpdated(editingCourier.employee_id, formData);
          setAlertMessage({
            type: "success",
            message: "Courier updated successfully.",
          });
        } else {
          await onCourierAdded(formData);
          setAlertMessage({
            type: "success",
            message: "Courier added successfully.",
          });
        }

        // Clear alert after 3 seconds
        setTimeout(() => {
          setAlertMessage(null);
        }, 3000);

        handleClose();
      } catch (error) {
        console.error("Error saving courier:", error);

        let errorMessage = "Failed to save courier. Please try again.";
        const newFormErrors = { ...formErrors };

        if (error.response && error.response.data) {
          if (error.response.data.msg) {
            errorMessage = error.response.data.msg;
          }

          if (error.response.data.employee_email) {
            newFormErrors.employee_email = "Email already exists";
          }

          if (error.response.data.employee_phone) {
            newFormErrors.employee_phone = "Phone already exists";
          }
        }

        setAlertMessage({
          type: "error",
          message: errorMessage,
        });

        setFormErrors(newFormErrors);
      }
      setIsLoading(false);
    }
  };

  const handleDelete = async (employeeId) => {
    setIsLoading(true);
    try {
      await onCourierDeleted(employeeId);
      setAlertMessage({
        type: "success",
        message: "Courier deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting courier:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete courier. Please try again.",
      });
    }
    setIsLoading(false);
  };

  // Get paginated data
  const paginatedCouriers = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredCouriers.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredCouriers, currentPage, itemsPerPage]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle search change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  // Handle sort change
  const handleSortChange = (field) => {
    setSortConfig((prevConfig) => ({
      field,
      direction:
        prevConfig.field === field && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  // Listen for the custom event to open the add form
  useEffect(() => {
    const handleOpenAddForm = (e) => {
      if (e.detail.type === "courier") {
        handleOpen();
      }
    };

    document.addEventListener("openAddAssetForm", handleOpenAddForm);

    return () => {
      document.removeEventListener("openAddAssetForm", handleOpenAddForm);
    };
  }, []);

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          onClose={() => setAlertMessage(null)}
          sx={{ mb: 2 }}
        >
          {alertMessage.message}
        </Alert>
      )}

      <Card>
        <CardHeader title="Couriers" />
        <CardContent>
          {/* Search and Filters */}
          <Grid container spacing={2} alignItems="center" marginBottom={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Search Couriers"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search by name, email, phone..."
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      fontSize="small"
                      sx={{ mr: 1, color: "text.secondary" }}
                    />
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Employment Status</InputLabel>
                <Select
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  label="Employment Status"
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="full-time">Full Time</MenuItem>
                  <MenuItem value="part-time">Part Time</MenuItem>
                  <MenuItem value="contract">Contract</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Active Filters */}
          {(filters.status || searchQuery) && (
            <Box mb={2}>
              <Typography variant="body2" color="textSecondary" mb={1}>
                Active Filters:
              </Typography>
              <Box display="flex" gap={1} flexWrap="wrap">
                {filters.status && (
                  <Chip
                    label={`Status: ${filters.status}`}
                    onDelete={() => setFilters({ ...filters, status: "" })}
                    size="small"
                  />
                )}
                {searchQuery && (
                  <Chip
                    label={`Search: ${searchQuery}`}
                    onDelete={() => setSearchQuery("")}
                    size="small"
                  />
                )}
                {(filters.status || searchQuery) && (
                  <Chip
                    label="Clear All"
                    onClick={() => {
                      setFilters({ status: "" });
                      setSearchQuery("");
                    }}
                    size="small"
                    color="secondary"
                  />
                )}
              </Box>
            </Box>
          )}

          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={sortConfig.field === "employee_first_name" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "employee_first_name"}
                        direction={sortConfig.field === "employee_first_name" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("employee_first_name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "employee_email" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "employee_email"}
                        direction={sortConfig.field === "employee_email" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("employee_email")}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "employee_hire_date" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "employee_hire_date"}
                        direction={sortConfig.field === "employee_hire_date" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("employee_hire_date")}
                      >
                        Hire Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={sortConfig.field === "employment_status" ? sortConfig.direction : false}
                    >
                      <TableSortLabel
                        active={sortConfig.field === "employment_status"}
                        direction={sortConfig.field === "employment_status" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortChange("employment_status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCouriers.length > 0 ? (
                    paginatedCouriers.map((courier) => (
                      <TableRow key={courier.employee_id}>
                        <TableCell>{`${courier.employee_first_name} ${courier.employee_last_name}`}</TableCell>
                        <TableCell>{courier.employee_email}</TableCell>
                        <TableCell>{courier.employee_phone}</TableCell>
                        <TableCell>{courier.employee_hire_date}</TableCell>
                        <TableCell>
                          <Chip
                            label={courier.employment_status}
                            color={
                              courier.employment_status === "full-time"
                                ? "primary"
                                : courier.employment_status === "part-time"
                                ? "info"
                                : "default"
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpen(courier)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(courier.employee_id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Typography align="center">
                          No couriers found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination */}
          {filteredCouriers.length > 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Pagination
                count={Math.ceil(filteredCouriers.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editingCourier ? "Edit Courier" : "Add Courier"}
        </DialogTitle>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            onClose={() => setAlertMessage(null)}
            sx={{ mb: 2, mr: 2, ml: 2 }}
          >
            {alertMessage.message}
          </Alert>
        )}
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            fullWidth
            required
            value={formData.employee_first_name}
            onChange={(e) =>
              handleChange("employee_first_name", e.target.value)
            }
            error={!!formErrors.employee_first_name}
            helperText={formErrors.employee_first_name}
          />
          <TextField
            margin="dense"
            label="Last Name"
            fullWidth
            required
            value={formData.employee_last_name}
            onChange={(e) => handleChange("employee_last_name", e.target.value)}
            error={!!formErrors.employee_last_name}
            helperText={formErrors.employee_last_name}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            required
            value={formData.employee_email}
            onChange={(e) => handleChange("employee_email", e.target.value)}
            error={!!formErrors.employee_email}
            helperText={formErrors.employee_email}
          />
          <MuiTelInput
            margin="dense"
            label="Phone"
            fullWidth
            value={formData.employee_phone}
            onChange={(value) => handleChange("employee_phone", value)}
            error={!!formErrors.employee_phone}
            helperText={formErrors.employee_phone}
          />
          <TextField
            margin="dense"
            label="Hire Date"
            type="date"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            value={formData.employee_hire_date}
            onChange={(e) => handleChange("employee_hire_date", e.target.value)}
            error={!!formErrors.employee_hire_date}
            helperText={formErrors.employee_hire_date}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Employment Status</InputLabel>
            <Select
              value={formData.employment_status}
              onChange={(e) =>
                handleChange("employment_status", e.target.value)
              }
              error={!!formErrors.employment_status}
            >
              <MenuItem value="full-time">Full Time</MenuItem>
              <MenuItem value="part-time">Part Time</MenuItem>
              <MenuItem value="contract">Contract</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={4}
            value={formData.employee_notes}
            onChange={(e) => handleChange("employee_notes", e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            dusabled={isLoading}
          >
            Save
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CourierList;
