import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const AssetsContext = createContext();

export const useAssets = () => {
  const context = useContext(AssetsContext);
  if (!context) {
    throw new Error("useAssets must be used within an AssetsProvider");
  }
  return context;
};

export const AssetsProvider = ({ children }) => {
  const [couriers, setCouriers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [locations, setLocations] = useState([]); // Stores raw BusinessAddress records
  const [orders, setOrders] = useState([]);
  const [groups, setGroups] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groupsError, setGroupsError] = useState(null);

  const { api } = useContext(AuthContext);

  // --------------------------------------
  // COURIERS
  // --------------------------------------
  const fetchCouriers = useCallback(
    async (businessId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get("/employees", {
          params: { business_id: businessId, roles: ["Courier"] },
        });
        setCouriers(response.data);
      } catch (err) {
        console.error("Error fetching couriers:", err);
        setError("Failed to fetch couriers.");
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  const addCourier = useCallback(
    async (courierData, businessId) => {
      try {
        const payload = {
          ...courierData,
          employee_roles: ["Courier", ...(courierData.employee_roles || [])],
          employee_status: "pending",
          employee_is_active: false,
        };
        const res = await api.post(`/employees/${businessId}`, payload);
        setCouriers((prev) => [...prev, res.data.employee]);
        return res.data;
      } catch (err) {
        console.error("Error adding courier:", err);
        throw err;
      }
    },
    [api]
  );

  const updateCourier = useCallback(
    async (courierId, courierData, businessId) => {
      try {
        // Ensure Courier is in roles
        const payload = {
          ...courierData,
          employee_roles: courierData.employee_roles.includes("Courier")
            ? courierData.employee_roles
            : ["Courier", ...courierData.employee_roles],
        };
        const res = await api.put(
          `/employees/${businessId}/${courierId}`,
          payload
        );
        const updated = res.data.employee;
        setCouriers((prev) =>
          prev.map((c) => (c.employee_id === courierId ? updated : c))
        );
        return updated;
      } catch (err) {
        console.error("Error updating courier:", err);
        throw err;
      }
    },
    [api]
  );

  const deleteCourier = useCallback(
    async (courierId, businessId) => {
      try {
        await api.delete(`/employees/${businessId}/${courierId}`);
        setCouriers((prev) => prev.filter((c) => c.employee_id !== courierId));
      } catch (err) {
        console.error("Error deleting courier:", err);
        throw err;
      }
    },
    [api]
  );

  // --------------------------------------
  // VEHICLES
  // --------------------------------------
  const fetchVehicles = useCallback(
    async (businessId) => {
      setLoading(true);
      setError(null);
      try {
        const res = await api.get(`/vehicles/get-all/${businessId}`);
        setVehicles(res.data);
      } catch (err) {
        console.error("Error fetching vehicles:", err);
        setError("Failed to fetch vehicles.");
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  const addVehicle = useCallback(
    async (vehicleData, businessId) => {
      try {
        const formData = new FormData();
        for (const key in vehicleData) {
          formData.append(key, vehicleData[key]);
        }
        formData.append("business_id", businessId);

        const res = await api.post(`/vehicles/${businessId}`, formData);
        setVehicles((prev) => [...prev, res.data]);
        return res.data;
      } catch (err) {
        console.error("Error adding vehicle:", err);
        throw err;
      }
    },
    [api]
  );

  const updateVehicle = useCallback(
    async (vehicleId, vehicleData, businessId) => {
      try {
        const formData = new FormData();
        for (const key in vehicleData) {
          formData.append(key, vehicleData[key]);
        }
        formData.append("business_id", businessId);

        const res = await api.put(
          `/vehicles/${businessId}/${vehicleId}`,
          formData
        );
        setVehicles((prev) =>
          prev.map((v) => (v.vehicle_id === vehicleId ? res.data : v))
        );
        return res.data;
      } catch (err) {
        console.error("Error updating vehicle:", err);
        throw err;
      }
    },
    [api]
  );

  const deleteVehicle = useCallback(
    async (vehicleId, businessId) => {
      try {
        await api.delete(`/vehicles/${businessId}/${vehicleId}`);
        setVehicles((prev) => prev.filter((v) => v.vehicle_id !== vehicleId));
      } catch (err) {
        console.error("Error deleting vehicle:", err);
        throw err;
      }
    },
    [api]
  );

  // Vehicle assignment
  const createVehicleAssignment = useCallback(
    async (businessId, assignmentData) => {
      try {
        const res = await api.post(
          `/vehicles/${businessId}/${assignmentData.vehicle_id}/assignments`,
          assignmentData
        );
        // returns updated vehicle
        setVehicles((prev) =>
          prev.map((v) => (v.vehicle_id === res.data.vehicle_id ? res.data : v))
        );
        return res.data;
      } catch (err) {
        console.error("Error creating vehicle assignment:", err);
        throw err;
      }
    },
    [api]
  );

  const unassignVehicle = useCallback(
    async (businessId, vehicleId) => {
      try {
        await api.delete(`/vehicles/${businessId}/${vehicleId}/unassign`);
        setVehicles((prev) =>
          prev.map((v) =>
            v.vehicle_id === vehicleId
              ? { ...v, ShippingVehicleAssignments: [] }
              : v
          )
        );
      } catch (err) {
        console.error("Error unassigning vehicle:", err);
        throw err;
      }
    },
    [api]
  );

  // --------------------------------------
  // ORDERS
  // --------------------------------------
  const fetchOrders = useCallback(
    async (businessId, showLoading = true) => {
      if (showLoading) setLoading(true);
      setError(null);
      try {
        const res = await api.get("/shipping-orders", {
          params: { business_id: businessId },
        });
        setOrders(res.data);
        return res.data;
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders.");
        return [];
      } finally {
        if (showLoading) setLoading(false);
      }
    },
    [api]
  );

  // --------------------------------------
  // LOCATIONS (Business Addresses)
  // --------------------------------------
  // 1) Fetch addresses from backend & store them as-is
  const fetchLocations = useCallback(
    async (businessId) => {
      setLoading(true);
      setError(null);
      try {
        const res = await api.get(`/business/${businessId}/addresses`);
        // Store them directly
        setLocations(res.data); // each item = raw BusinessAddress from DB

        // Convert old operating hours format to new format if needed
        const processedLocations = res.data.map((location) => {
          // Check if the location has the old operating_hours format
          if (
            location.operating_hours &&
            !location.operating_hours.Monday?.hasOwnProperty("is_open")
          ) {
            // Convert from old format (is_closed, open_time, close_time)
            // to new format (is_open, shifts:[{open, close}])
            const updatedHours = {};

            Object.entries(location.operating_hours).forEach(([day, data]) => {
              updatedHours[day] = {
                is_open: !data.is_closed,
                shifts: data.is_closed
                  ? []
                  : [
                      {
                        open: data.open_time || "09:00",
                        close: data.close_time || "17:00",
                      },
                    ],
              };
            });

            return {
              ...location,
              operating_hours: updatedHours,
            };
          }

          return location;
        });

        setLocations(processedLocations);
      } catch (err) {
        console.error("Error fetching locations:", err);
        setError("Failed to fetch locations.");
      } finally {
        setLoading(false);
      }
    },
    [api]
  );

  // 2) Add a new BusinessAddress
  const addLocation = useCallback(
    async (locationData, businessId) => {
      try {
        // Make sure operating hours are in the correct format
        const processedData = processLocationData(locationData);

        const res = await api.post(
          `/business/${businessId}/addresses`,
          processedData
        );
        // push the newly created address
        setLocations((prev) => [...prev, res.data]);
        return res.data;
      } catch (err) {
        console.error("Error adding location:", err);
        throw new Error(
          "Failed to add location: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  // 3) Update an existing BusinessAddress
  const updateLocation = useCallback(
    async (locationId, updatedData, businessId) => {
      try {
        // Make sure operating hours are in the correct format
        const processedData = processLocationData(updatedData);

        const res = await api.put(
          `/business/${businessId}/addresses/${locationId}`,
          processedData
        );
        const updatedAddr = res.data;
        // Update local state
        setLocations((prev) =>
          prev.map((loc) =>
            loc.business_address_id === locationId ? updatedAddr : loc
          )
        );
        return updatedAddr;
      } catch (err) {
        console.error("Error updating location:", err);
        throw new Error(
          "Failed to update location: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  // Helper function to ensure location data is in the correct format
  const processLocationData = (data) => {
    let processedData = { ...data };

    // Handle operating hours conversion if needed
    if (data.operating_hours) {
      const isOldFormat = Object.values(data.operating_hours).some((day) =>
        day.hasOwnProperty("is_closed")
      );

      if (isOldFormat) {
        // Convert from old format to new format
        const updatedHours = {};

        Object.entries(data.operating_hours).forEach(([day, dayData]) => {
          updatedHours[day] = {
            is_open: !dayData.is_closed,
            shifts: dayData.is_closed
              ? []
              : [
                  {
                    open: dayData.open_time || "09:00",
                    close: dayData.close_time || "17:00",
                  },
                ],
          };
        });

        processedData.operating_hours = updatedHours;
      }
    }

    return processedData;
  };

  // 4) Delete a BusinessAddress
  const deleteLocation = useCallback(
    async (locationId, businessId) => {
      try {
        await api.delete(`/business/${businessId}/addresses/${locationId}`);
        setLocations((prev) =>
          prev.filter((loc) => loc.business_address_id !== locationId)
        );
      } catch (err) {
        console.error("Error deleting location:", err);
        throw new Error(
          "Failed to delete location: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  // --------------------------------------
  // ORDER ASSIGNMENTS
  // --------------------------------------
  const assignOrderToCourier = useCallback(
    async (orderId, employeeId) => {
      try {
        const c = couriers.find((emp) => emp.employee_id === employeeId);
        if (!c || !c.employee_roles.includes("Courier")) {
          throw new Error("Selected employee is not a courier");
        }

        const res = await api.post(`/assets/assign-order`, {
          order_id: orderId,
          employee_id: employeeId,
        });
        const updatedOrder = res.data.order;
        setOrders((prev) =>
          prev.map((o) =>
            o.shipping_order_id === updatedOrder.shipping_order_id
              ? updatedOrder
              : o
          )
        );
        return updatedOrder;
      } catch (err) {
        console.error("Error assigning order to courier:", err);
        throw new Error(
          "Failed to assign order: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api, couriers]
  );

  const assignOrder = useCallback(
    async (orderId, assignmentData) => {
      try {
        const res = await api.post(`/assets/assign-order`, {
          order_id: orderId,
          ...assignmentData,
        });
        const updOrder = res.data.order;
        setOrders((prev) =>
          prev.map((o) =>
            o.shipping_order_id === updOrder.shipping_order_id ? updOrder : o
          )
        );
        return updOrder;
      } catch (err) {
        console.error("Error assigning order:", err);
        throw new Error(
          "Failed to assign order: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  // --------------------------------------
  // GROUPS
  // --------------------------------------
  const fetchGroups = useCallback(
    async (businessId) => {
      setGroupsLoading(true);
      setGroupsError(null);
      try {
        const res = await api.get("/shipping-groups", {
          params: { business_id: businessId },
        });
        setGroups(res.data);
      } catch (err) {
        console.error("Error fetching groups:", err);
        setGroupsError("Failed to fetch groups. Please try again.");
      } finally {
        setGroupsLoading(false);
      }
    },
    [api]
  );

  const createGroup = useCallback(
    async (groupData, businessId) => {
      try {
        const res = await api.post("/shipping-groups", {
          ...groupData,
          business_id: businessId,
        });
        const newGroup = res.data;
        setGroups((prev) => [...prev, newGroup]);
        return newGroup;
      } catch (err) {
        console.error("Error creating group:", err);
        throw new Error(
          "Failed to create group: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const updateGroup = useCallback(
    async (groupId, updatedData, businessId) => {
      try {
        const res = await api.put(`/shipping-groups/${groupId}`, {
          ...updatedData,
          business_id: businessId,
        });
        const upd = res.data;
        setGroups((prev) =>
          prev.map((g) => (g.group_id === upd.group_id ? upd : g))
        );
        return upd;
      } catch (err) {
        console.error("Error updating group:", err);
        throw new Error(
          "Failed to update group: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const deleteGroup = useCallback(
    async (groupId, businessId) => {
      try {
        await api.delete(`/shipping-groups/${groupId}`, {
          params: { business_id: businessId },
        });
        setGroups((prev) => prev.filter((g) => g.group_id !== groupId));
      } catch (err) {
        console.error("Error deleting group:", err);
        throw new Error(
          "Failed to delete group: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const addOrderToGroup = useCallback(
    async (groupId, orderId, businessId) => {
      try {
        const res = await api.post(`/shipping-groups/${groupId}/orders`, {
          order_id: orderId,
          business_id: businessId,
        });
        const updatedGroup = res.data;
        setGroups((prev) =>
          prev.map((g) =>
            g.group_id === updatedGroup.group_id ? updatedGroup : g
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error adding order to group:", err);
        throw new Error(
          "Failed to add order to group: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const removeOrderFromGroup = useCallback(
    async (groupId, orderId, businessId) => {
      try {
        const res = await api.delete(
          `/shipping-groups/${groupId}/orders/${orderId}`,
          {
            params: { business_id: businessId },
          }
        );
        const updatedGroup = res.data;
        setGroups((prev) =>
          prev.map((g) =>
            g.group_id === updatedGroup.group_id ? updatedGroup : g
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error removing order from group:", err);
        throw new Error(
          "Failed to remove order from group: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  // --------------------------------------
  // Context value
  // --------------------------------------
  const value = {
    // Data
    couriers,
    vehicles,
    locations, // Raw addresses from backend
    orders,
    groups,
    loading,
    error,
    groupsLoading,
    groupsError,

    // Courier
    fetchCouriers,
    addCourier,
    updateCourier,
    deleteCourier,

    // Vehicles
    fetchVehicles,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    createVehicleAssignment,
    unassignVehicle,

    // Locations
    fetchLocations,
    addLocation,
    updateLocation,
    deleteLocation,
    processLocationData,

    // Orders
    fetchOrders,

    // Groups
    fetchGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    addOrderToGroup,
    removeOrderFromGroup,

    // Order assignment
    assignOrderToCourier,
    assignOrder,
  };

  return (
    <AssetsContext.Provider value={value}>{children}</AssetsContext.Provider>
  );
};

export default AssetsContext;
