import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Chip,
  Container,
  Divider,
} from "@mui/material";
import {
  BusinessCenter as BusinessIcon,
  Store as StoreIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../components/Auth/AuthContext";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const FALLBACK_IMAGE = "https://via.placeholder.com/300x200?text=Business";

const BusinessSelector = () => {
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();

  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        setLoading(true);
        const response = await api.get("/employee-portal/businesses");
        setBusinesses(response.data.businesses || []);

        // If only one business, redirect directly to its dashboard
        if (response.data.businesses?.length === 1) {
          navigate(
            `/employee/${response.data.businesses[0].business_id}/dashboard`
          );
        }
      } catch (err) {
        console.error("Error fetching businesses:", err);
        setError(err.response?.data?.error || "Failed to load businesses");
      } finally {
        setLoading(false);
      }
    };

    fetchBusinesses();
  }, [api, navigate]);

  const handleBusinessSelect = (businessId) => {
    navigate(`/employee/${businessId}/dashboard`);
  };

  const renderBusinessTypeIcon = (category) => {
    // This can be expanded later to show different icons for different business types
    if (category?.toLowerCase().includes('shipping') || 
        category?.toLowerCase().includes('delivery')) {
      return <LocalShippingIcon sx={{ mr: 1 }} />;
    }
    return <StoreIcon sx={{ mr: 1 }} />;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
          <Alert severity="error" sx={{ mb: 4 }}>
            {error}
          </Alert>
        </Container>
        <Footer />
      </>
    );
  }

  if (businesses.length === 0) {
    return (
      <>
        <Header />
        <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
          <Alert severity="info" sx={{ mb: 4 }}>
            You don't have access to any businesses as an employee. Please
            contact your administrator.
          </Alert>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        <Box textAlign="center" mb={6}>
          <BusinessIcon sx={{ fontSize: 60, color: "primary.main", mb: 2 }} />
          <Typography variant="h3" component="h1" gutterBottom>
            Employee Portal
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Select a business to access its employee portal:
          </Typography>
        </Box>

        <Divider sx={{ mb: 6 }} />

        <Grid container spacing={4}>
          {businesses.map((business) => (
            <Grid item xs={12} sm={6} md={4} key={business.business_id}>
              <Card
                elevation={3}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: (theme) => theme.shadows[8],
                  },
                }}
              >
                <CardActionArea
                  onClick={() => handleBusinessSelect(business.business_id)}
                  sx={{ height: "100%" }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={business.business_profile_picture || FALLBACK_IMAGE}
                    alt={business.business_name}
                    onError={(e) => {
                      e.target.src = FALLBACK_IMAGE;
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mb={1}
                    >
                      <Typography variant="h5" component="div" gutterBottom>
                        {business.business_name}
                      </Typography>
                      <Chip
                        icon={<StoreIcon />}
                        label={business.relationship}
                        color={
                          business.relationship === "owner"
                            ? "secondary"
                            : "primary"
                        }
                        size="small"
                      />
                    </Box>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, display: 'flex', alignItems: 'center' }}
                    >
                      {renderBusinessTypeIcon(business.business_category)}
                      {business.business_category || "Business"}
                    </Typography>

                    <Box display="flex" flexWrap="wrap" gap={0.5} mt={2}>
                      {business.roles &&
                        business.roles.map((role) => (
                          <Chip
                            key={role}
                            label={role}
                            size="small"
                            variant="outlined"
                            color={role === "Owner" ? "secondary" : "default"}
                          />
                        ))}
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default BusinessSelector;
