// src/pages/Business/PrintingSettingsPage.jsx
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import {
  TextField,
  CircularProgress,
  Box,
  Alert,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { AuthContext } from "../../../../components/Auth/AuthContext";
import { useParams } from "react-router-dom";
import qz from "qz-tray"; // Ensure qz-tray is properly installed and configured
import styles from "./PrintingSettingsPage.module.css"; // Ensure this CSS module exists
import PrintPreview from "./PrintPreview"; // Import the PrintPreview component
import html2pdf from "../../../../utils/patchedHtml2pdf";

const PrintingSettingsPage = () => {
  const { user } = useContext(AuthContext);
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });
  const [printers, setPrinters] = useState([]);
  const [selectedPrinters, setSelectedPrinters] = useState({
    receipts: "",
    shippingLabels: "",
    shippingManifests: "",
    shippingDocuments: "",
    individualItemLabels: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  // Define allowed size options per print type
  const sizeOptionsPerType = {
    receipts: ["4x6", "6x4"],
    shippingLabels: ["2x2", "4x6"],
    shippingManifests: ["8.5x11", "A4", "Letter", "Legal"],
    shippingDocuments: ["8.5x11", "A4", "Letter", "Legal"],
    individualItemLabels: [
      "0.75x0.75",
      "1x1",
      "1.5x1.5",
      "2x2",
      "2.5x2.5",
      "3x3",
      "3.5x3.5",
      "4x4",
    ],
  };

  // Centralized Print Settings per type
  // Define your default settings as a constant:
  const defaultPrintSettings = {
    receipts: {
      size: "4x6",
      qrCodeSize: 120,
      includeDetails: true,
      fontSizes: {
        title: 16,
        subtitle: 14,
        details: 12,
      },
      orientation: "portrait",
    },
    shippingLabels: {
      size: "4x6",
      qrCodeSize: 120,
      includeDetails: true,
      fontSizes: {
        title: 16,
        subtitle: 14,
        details: 12,
      },
      orientation: "portrait",
    },
    shippingManifests: {
      size: "8.5x11",
      qrCodeSize: 120,
      includeDetails: true,
      fontSizes: {
        title: 16,
        subtitle: 14,
        details: 12,
      },
      orientation: "portrait",
    },
    shippingDocuments: {
      size: "8.5x11",
      qrCodeSize: 120,
      includeDetails: true,
      fontSizes: {
        title: 16,
        subtitle: 14,
        details: 12,
      },
      orientation: "portrait",
    },
    individualItemLabels: {
      size: "2x2",
      qrCodeSize: 120,
      includeDetails: true,
      fontSizes: {
        title: 16,
        subtitle: 14,
        details: 12,
      },
      orientation: "portrait",
    },
  };

  // Helper function: for each type, use the default and override only if a valid (non-null/undefined) value exists.
  const cleanSettings = (loadedSettings, defaults) => {
    const cleaned = {};
    for (const type in defaults) {
      // Start with the defaults for this type.
      cleaned[type] = { ...defaults[type] };
      if (loadedSettings && loadedSettings[type]) {
        // For each key in the default settings, if loadedSettings has a valid value, use it.
        for (const key in defaults[type]) {
          if (
            loadedSettings[type][key] !== null &&
            loadedSettings[type][key] !== undefined
          ) {
            cleaned[type][key] = loadedSettings[type][key];
          }
        }
      }
    }
    return cleaned;
  };

  // In your state initialization for printSettings:
  const [printSettings, setPrintSettings] = useState(() => {
    // Clear printer settings on load.
    const savedSettings = localStorage.getItem(`printSettings_${businessId}`);
    if (savedSettings) {
      try {
        const parsed = JSON.parse(savedSettings);
        // Clean the loaded settings by merging with the defaults.
        return cleanSettings(parsed, defaultPrintSettings);
      } catch (error) {
        console.error("Error parsing saved settings:", error);
        return defaultPrintSettings;
      }
    }
    return defaultPrintSettings;
  });

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  // Discover available printers
  const discoverPrinters = useCallback(async () => {
    try {
      setIsLoading(true);
      const printerList = await qz.printers.find();
      console.log("Available Printers:", printerList);
      setPrinters(printerList || []);

      // Load saved printer preferences
      const savedPrinters = JSON.parse(
        localStorage.getItem(`printers_${businessId}`) || "{}"
      );
      setSelectedPrinters((prev) => ({
        ...prev,
        ...savedPrinters,
      }));

      if (!printerList || printerList.length === 0) {
        setAlertMessage({
          type: "warning",
          message:
            "No printers were found. Please ensure your printers are connected and try again.",
        });
      }
    } catch (err) {
      console.error("Error discovering printers:", err);
      setPrinters([]);
      setAlertMessage({
        type: "error",
        message: "Error discovering printers. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [businessId]);

  // Initialize QZ Tray
  const connectionAttemptRef = useRef(null);
  const initializeQZ = useCallback(async () => {
    try {
      if (connectionAttemptRef.current) {
        await connectionAttemptRef.current;
      }
      await qz.api.setPromiseType((resolver) => new Promise(resolver));
      if (!qz.websocket.isActive()) {
        connectionAttemptRef.current = qz.websocket.connect();
        await connectionAttemptRef.current;
        console.log("Connected to QZ Tray");
        setIsConnected(true);
        await discoverPrinters();
      }
    } catch (err) {
      console.error("Failed to connect to QZ Tray:", err);
      setAlertMessage({
        type: "error",
        message:
          "Failed to connect to QZ Tray. Please ensure QZ Tray is installed and running.",
      });
      setIsConnected(false);
    } finally {
      connectionAttemptRef.current = null;
    }
  }, [discoverPrinters]);

  useEffect(() => {
    let mounted = true;
    const init = async () => {
      try {
        if (mounted) {
          await initializeQZ();
        }
      } catch (error) {
        if (mounted) {
          console.error("Failed to initialize QZ:", error);
        }
      }
    };
    init();
    return () => {
      mounted = false;
      if (connectionAttemptRef.current) {
        connectionAttemptRef.current = null;
      }
      if (qz.websocket.isActive()) {
        qz.websocket.disconnect().catch((err) => {
          console.error("Error during cleanup:", err);
        });
      }
    };
  }, [initializeQZ]);

  const handlePrinterChange = (event) => {
    const { name, value } = event.target;
    setSelectedPrinters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSettingsChange = (type, field, value) => {
    if (field === "size" && !sizeOptionsPerType[type].includes(value)) {
      console.warn(`Invalid size "${value}" for type "${type}".`);
      return;
    }
    setPrintSettings((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: value,
      },
    }));
  };

  const handleFontSizeChange = (type, fontType, value) => {
    setPrintSettings((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        fontSizes: {
          ...prev[type].fontSizes,
          [fontType]: value,
        },
      },
    }));
  };

  // Save all settings globally
  const savePrinterSettings = () => {
    localStorage.setItem(
      `printers_${businessId}`,
      JSON.stringify(selectedPrinters)
    );
    localStorage.setItem(
      `printSettings_${businessId}`,
      JSON.stringify(printSettings)
    );
    setAlertMessage({
      type: "success",
      message: "All print settings saved successfully!",
    });
  };

  // Save settings for an individual print type
  const saveSettingsForType = (type) => {
    // Update global printer selections
    const currentPrinters = { ...selectedPrinters };
    localStorage.setItem(
      `printers_${businessId}`,
      JSON.stringify(currentPrinters)
    );

    // Destructure only the keys we want to keep
    const { size, qrCodeSize, includeDetails, fontSizes, orientation } =
      printSettings[type] || {};
    const newSettings = {
      size,
      qrCodeSize,
      includeDetails,
      fontSizes,
      orientation,
    };

    // Retrieve existing settings, update this type, and save back
    const currentSettings =
      JSON.parse(localStorage.getItem(`printSettings_${businessId}`)) || {};
    currentSettings[type] = newSettings;
    localStorage.setItem(
      `printSettings_${businessId}`,
      JSON.stringify(currentSettings)
    );

    setAlertMessage({
      type: "success",
      message: `${formatLabelType(type)} settings saved successfully!`,
    });
  };

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const handleTestPrint = async (type, isDryRun = true) => {
    try {
      console.group("Print Debug Information");
      console.log(`Print type: ${type}, Dry Run: ${isDryRun}`);
      console.log("Print Settings:", printSettings[type]);
      setIsPrinting(true);
      setAlertMessage(null);

      // Get and validate the preview container
      const previewContainer = document.querySelector(`#preview-${type}`);
      if (!previewContainer) {
        throw new Error(`Preview element not found for type: ${type}`);
      }

      // Get the actual PrintPreview content (first child)
      const previewElement = previewContainer.firstElementChild;
      if (!previewElement) {
        throw new Error("PrintPreview content not found");
      }
      console.log("Preview Content:", previewElement.outerHTML);

      // Parse dimensions from the size setting (e.g., "4x6" becomes [4, 6])
      const [width, height] = printSettings[type].size.split("x").map(Number);
      console.log("Parsed dimensions:", { width, height });

      // Create a wrapper with proper dimensions (in pixels, using 96 DPI)
      const wrapper = document.createElement("div");
      wrapper.style.width = `${width * 96}px`;
      wrapper.style.height = `${height * 96}px`;
      wrapper.style.background = "white";
      wrapper.style.position = "relative";
      wrapper.style.overflow = "hidden";

      // Clone the preview element
      const previewClone = previewElement.cloneNode(true);
      previewClone.style.position = "absolute";
      previewClone.style.visibility = "visible";
      previewClone.style.display = "block";
      previewClone.style.background = "white";
      previewClone.style.width = "100%";
      previewClone.style.height = "100%";
      previewClone.style.margin = "0";
      previewClone.style.padding = "0";
      wrapper.appendChild(previewClone);

      // Add wrapper to document temporarily (needed by html2pdf)
      document.body.appendChild(wrapper);

      let pdfOutput;
      try {
        // Calculate proper PDF dimensions and orientation
        const pdfWidth = width;
        const pdfHeight = height;
        const orientation = pdfWidth > pdfHeight ? "landscape" : "portrait";
        console.log("PDF Dimensions:", {
          width: pdfWidth,
          height: pdfHeight,
          orientation,
        });

        // Generate PDF with html2pdf
        pdfOutput = await html2pdf()
          .set({
            margin: 0,
            filename: `${type}-preview.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 4,
              logging: true,
              backgroundColor: "#FFFFFF",
              width: wrapper.offsetWidth,
              height: wrapper.offsetHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "in",
              format: [pdfWidth, pdfHeight],
              orientation: orientation,
              compress: true,
            },
          })
          .from(wrapper)
          .outputPdf("blob");

        // Open PDF in new tab as a preview
        const pdfUrl = URL.createObjectURL(pdfOutput);
        window.open(pdfUrl, "_blank");
        console.log("PDF Generation Successful");
      } catch (pdfError) {
        console.error("PDF Generation Error:", pdfError);
        throw new Error(`PDF preview generation failed: ${pdfError.message}`);
      } finally {
        // Clean up the temporary wrapper
        document.body.removeChild(wrapper);
      }

      // Now, use a different print branch based on dry run:
      if (isDryRun) {
        // For dry runs, print the generated PDF via QZ Tray.
        if (!selectedPrinters?.[type]) {
          throw new Error(`No printer selected for type: ${type}`);
        }
        const config = qz.configs.create(selectedPrinters[type], {
          size: { width, height },
          units: "in",
          density: "best",
          orientation: printSettings[type].orientation,
          colorType: "blackwhite",
          interpolation: "nearest-neighbor",
          margins: { top: 0, right: 0, bottom: 0, left: 0 },
          rasterize: "false",
        });
        const pdfBase64 = await blobToBase64(pdfOutput);

        console.log("PDF base64 data:", pdfBase64);

        const data = [
          {
            type: "pixel",
            format: "pdf",
            flavor: "base64",
            data: pdfBase64,
          },
        ];
        await qz.print(config, data);

        console.log("PDF Print Job Completed Successfully (Dry Run)");
        setAlertMessage({
          type: "success",
          message: `Dry run print for ${formatLabelType(
            type
          )} executed successfully!`,
        });
      } else {
        // For non-dry runs, print the HTML content.
        if (!selectedPrinters?.[type]) {
          throw new Error(`No printer selected for type: ${type}`);
        }
        const config = qz.configs.create(selectedPrinters[type], {
          size: { width, height, units: "in" },
          orientation: printSettings[type].orientation,
          density: "300",
          margins: { top: 0, right: 0, bottom: 0, left: 0 },
        });
        const data = [
          "^XA\n",
          {
            type: "raw",
            format: "html",
            flavor: "plain",
            data: previewElement.innerHTML,
            options: {
              language: "ZPL",
              pageWidth: width,
              pageHeight: height,
            },
          },
          "^XZ\n",
        ];
        await qz.print(config, data);
        console.log("HTML Print Job Completed Successfully");
        setAlertMessage({
          type: "success",
          message: `Print for ${formatLabelType(type)} executed successfully!`,
        });
      }
    } catch (error) {
      console.error("Fatal Error:", error);
      console.error("Error Stack:", error.stack);
      setAlertMessage({
        type: "error",
        message: `Failed to ${
          isDryRun ? "preview (PDF)" : "print (HTML)"
        } ${formatLabelType(type)}: ${error.message}`,
      });
    } finally {
      setIsPrinting(false);
      console.groupEnd();
    }
  };

  // Utility function to format label types for display
  const formatLabelType = (type) => {
    switch (type) {
      case "receipts":
        return "Receipts";
      case "shippingLabels":
        return "Shipping Labels";
      case "shippingManifests":
        return "Shipping Manifests";
      case "shippingDocuments":
        return "Shipping Documents";
      case "individualItemLabels":
        return "Individual Item Labels";
      default:
        return type;
    }
  };

  // Define all print types for easy iteration
  const printTypes = [
    "receipts",
    "shippingLabels",
    "shippingManifests",
    "shippingDocuments",
    "individualItemLabels",
  ];

  return (
    <div className="business-page">
      <main className="business-page-content">
            <Typography variant="h4" className={styles.title}>
              Printing Settings
            </Typography>
            {alertMessage && (
              <Alert
                severity={alertMessage.type}
                variant="outlined"
                sx={{ mb: 2 }}
                onClose={() => setAlertMessage(null)}
              >
                {alertMessage.message}
              </Alert>
            )}
            {/* 1. Download & Install QZ Tray */}
            <Box className={styles.section}>
              <Typography variant="h6">
                1. Download & Install QZ Tray
              </Typography>
              <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                To enable direct printing from this application, please download
                and install QZ Tray on your computer.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href="https://qz.io/download/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.downloadButton}
              >
                Download QZ Tray
              </Button>
              <Typography variant="body2" sx={{ mt: 1 }}>
                After downloading, run the installer and ensure that QZ Tray is
                running in the background.
              </Typography>
            </Box>
            {/* 3. Manage QZ Tray Connection */}
            <Box className={styles.section}>
              <Typography variant="h6">2. Manage QZ Tray Connection</Typography>
              {/* 3. Manage QZ Tray Connection */}

              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={initializeQZ}
                  disabled={isLoading || isConnected} // disable if already connected
                >
                  Reconnect QZ Tray
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={discoverPrinters}
                  disabled={isLoading}
                >
                  Refresh Printers
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={async () => {
                    try {
                      if (qz && qz.websocket && qz.websocket.isActive()) {
                        await qz.websocket.disconnect();
                        setPrinters([]);
                        setSelectedPrinters({
                          receipts: "",
                          shippingLabels: "",
                          shippingManifests: "",
                          shippingDocuments: "",
                          individualItemLabels: "",
                        });
                        setIsConnected(false);
                        setAlertMessage({
                          type: "info",
                          message: "Disconnected from QZ Tray.",
                        });
                      }
                    } catch (err) {
                      console.error("Error disconnecting:", err);
                      setAlertMessage({
                        type: "error",
                        message: "Error disconnecting from QZ Tray.",
                      });
                    }
                  }}
                  disabled={isLoading || !isConnected}
                >
                  Disconnect QZ Tray
                </Button>
              </Box>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Available Printers
              </Typography>
              <ul>
                {printers?.map((printer) => (
                  <li key={printer}>{printer}</li>
                ))}
              </ul>
            </Box>

            {/* 2. Configure Printers */}
            <Box className={styles.section}>
              <Typography variant="h6">3. Configure Printers</Typography>
              {isLoading && (
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <CircularProgress size={24} sx={{ mr: 2 }} />
                  <Typography variant="body1">
                    Discovering printers...
                  </Typography>
                </Box>
              )}
              {!isLoading && (
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  {printTypes.map((type) => (
                    <Grid item xs={12} key={type}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${type}-content`}
                          id={`${type}-header`}
                        >
                          <Typography variant="h6">
                            {formatLabelType(type)} Printer
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            {/* Printer Selection */}
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth>
                                <InputLabel id={`${type}-printer-label`}>
                                  {formatLabelType(type)} Printer
                                </InputLabel>
                                <Select
                                  labelId={`${type}-printer-label`}
                                  id={`${type}-printer`}
                                  name={type}
                                  value={selectedPrinters[type]}
                                  label={`${formatLabelType(type)} Printer`}
                                  onChange={handlePrinterChange}
                                >
                                  {printers.length > 0 ? (
                                    printers.map((printer) => (
                                      <MenuItem key={printer} value={printer}>
                                        {printer}
                                      </MenuItem>
                                    ))
                                  ) : (
                                    <MenuItem value="" disabled>
                                      No Printers Found
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* Settings Options */}
                            <Grid item xs={12} sm={12}>
                              <Typography variant="subtitle1">
                                {formatLabelType(type)} Settings
                              </Typography>
                              <Grid container spacing={2} sx={{ mt: 1 }}>
                                {/* Label Size */}
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id={`${type}-size-label`}>
                                      Size
                                    </InputLabel>
                                    <Select
                                      labelId={`${type}-size-label`}
                                      value={printSettings[type].size}
                                      label="Size"
                                      onChange={(e) =>
                                        handleSettingsChange(
                                          type,
                                          "size",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {sizeOptionsPerType[type].map((size) => (
                                        <MenuItem key={size} value={size}>
                                          {size}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/* QR Code Size */}
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id={`${type}-qr-size-label`}>
                                      QR Code Size
                                    </InputLabel>
                                    <Select
                                      labelId={`${type}-qr-size-label`}
                                      value={printSettings[type].qrCodeSize}
                                      label="QR Code Size"
                                      onChange={(e) =>
                                        handleSettingsChange(
                                          type,
                                          "qrCodeSize",
                                          Number(e.target.value)
                                        )
                                      }
                                    >
                                      <MenuItem value={80}>
                                        Small (80px)
                                      </MenuItem>
                                      <MenuItem value={120}>
                                        Medium (120px)
                                      </MenuItem>
                                      <MenuItem value={160}>
                                        Large (160px)
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/* Orientation */}
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id={`${type}-orientation-label`}
                                    >
                                      Orientation
                                    </InputLabel>
                                    <Select
                                      labelId={`${type}-orientation-label`}
                                      value={printSettings[type].orientation}
                                      label="Orientation"
                                      onChange={(e) =>
                                        handleSettingsChange(
                                          type,
                                          "orientation",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <MenuItem value="portrait">
                                        Portrait
                                      </MenuItem>
                                      <MenuItem value="landscape">
                                        Landscape
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                {/* Font Sizes */}
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    Font Sizes
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        fullWidth
                                        label="Title Font Size"
                                        type="number"
                                        value={
                                          printSettings[type].fontSizes.title
                                        }
                                        onChange={(e) =>
                                          handleFontSizeChange(
                                            type,
                                            "title",
                                            Number(e.target.value)
                                          )
                                        }
                                        InputProps={{
                                          inputProps: { min: 10, max: 32 },
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        fullWidth
                                        label="Subtitle Font Size"
                                        type="number"
                                        value={
                                          printSettings[type].fontSizes.subtitle
                                        }
                                        onChange={(e) =>
                                          handleFontSizeChange(
                                            type,
                                            "subtitle",
                                            Number(e.target.value)
                                          )
                                        }
                                        InputProps={{
                                          inputProps: { min: 8, max: 24 },
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextField
                                        fullWidth
                                        label="Details Font Size"
                                        type="number"
                                        value={
                                          printSettings[type].fontSizes.details
                                        }
                                        onChange={(e) =>
                                          handleFontSizeChange(
                                            type,
                                            "details",
                                            Number(e.target.value)
                                          )
                                        }
                                        InputProps={{
                                          inputProps: { min: 8, max: 20 },
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {/* Include Details Switch */}
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          printSettings[type].includeDetails
                                        }
                                        onChange={(e) =>
                                          setPrintSettings((prev) => ({
                                            ...prev,
                                            [type]: {
                                              ...prev[type],
                                              includeDetails: e.target.checked,
                                            },
                                          }))
                                        }
                                      />
                                    }
                                    label="Include Item Details"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Print Preview Section */}
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{ mt: 2 }}
                              >
                                {formatLabelType(type)} Preview
                              </Typography>
                              {/* You can uncomment and pass an id to PrintPreview so it can be selected */}
                              <div id={`preview-${type}`}>
                                <PrintPreview
                                  type={type}
                                  settings={printSettings[type]}
                                />
                              </div>
                            </Grid>
                            {/* Test Print and Preview Buttons */}
                            <Grid item xs={12}>
                              <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => handleTestPrint(type)}
                                  disabled={
                                    isPrinting || !selectedPrinters[type]
                                  }
                                >
                                  Test {formatLabelType(type)} Print
                                </Button>
                              </Box>
                            </Grid>
                            {/* Save Settings Button for this type */}
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => saveSettingsForType(type)}
                                sx={{ mt: 1 }}
                              >
                                Save {formatLabelType(type)} Settings
                              </Button>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))}
                </Grid>
              )}
              {/* Global Save All Settings Button */}
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                onClick={savePrinterSettings}
                disabled={isPrinting}
              >
                Save All Settings
              </Button>
            </Box>
      </main>
      <Modal
        open={isPrinting || isLoading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box className={styles.loadingModal}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            {isPrinting
              ? "Sending print job..."
              : "Discovering printers, please wait..."}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

// Utility function to get sample data based on type
const getSampleData = (type) => {
  switch (type) {
    case "receipts":
      return {
        storeName: "Example Store",
        storeAddress: "1234 Market St.",
        customerName: "John Doe",
        orderId: "ORD123456",
        date: "2025-01-21",
        items: [
          { name: "Widget A", price: 10.0 },
          { name: "Widget B", price: 15.0 },
        ],
        total: 25.0,
      };
    case "shippingLabels":
      return {
        orderId: "ORD123456",
        recipientName: "Jane Smith",
        recipientAddress: "5678 Elm St., City, State ZIP",
        senderName: "John Doe",
        senderAddress: "1234 Market St., City, State ZIP",
      };
    case "shippingManifests":
      return {
        manifestId: "MFG123456",
        date: "2025-01-21",
        orders: [
          {
            orderId: "ORD123456",
            items: ["Widget A", "Widget B"],
          },
          { orderId: "ORD123457", items: ["Widget C"] },
        ],
      };
    case "shippingDocuments":
      return {
        documentId: "DOC123456",
        date: "2025-01-21",
        details: "This document contains shipping details and terms.",
      };
    case "individualItemLabels":
      return {
        name: "Widget A",
        sku: "WIDGET-A",
        quantity: 2,
        price: 20.0,
        itemUrl: "https://example.com/item/WIDGET-A",
      };
    default:
      return {};
  }
};

export default PrintingSettingsPage;
