import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Divider,
  Paper,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "./Auth/AuthContext";

const StartLocationSelector = ({
  open,
  onClose,
  onSelect,
  businessId,
  currentStartLocationId = null,
  orderId = null,
  title = "Select Start Location",
}) => {
  const { api } = useContext(AuthContext);
  const [locations, setLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(
    currentStartLocationId
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch locations when dialog opens
  useEffect(() => {
    if (open && businessId) {
      console.log('StartLocationSelector: Using businessId for fetch:', businessId);
      fetchLocations();
    }
  }, [open, businessId]);

  const fetchLocations = async () => {
    setLoading(true);
    setError(null);
    let allLocations = [];

    try {
      // Get business addresses that are shipping locations
      console.log('Fetching addresses for business:', businessId);
      const addressesResponse = await api.get(
        `/business/${businessId}/addresses`,
        { params: { businessId } } // Explicitly include businessId in params
      );

      // Filter and convert business addresses to location format
      // Look for addresses marked as shipping locations or with appropriate types
      const shippingAddresses = addressesResponse.data.filter(
        (address) =>
          address.is_shipping_location ||
          ["warehouse", "dropoff_point", "pickup_point", "store"].includes(
            address.business_address_type.toLowerCase()
          )
      );

      const locationMappings = {
        warehouse: "Warehouse",
        dropoff_point: "DropOffPoint",
        pickup_point: "PickUpPoint",
        store: "Store",
        primary: "Store",
      };

      // Map business addresses to location format
      allLocations = shippingAddresses.map((address) => {
        // Debug log the raw address to see all available properties
        console.log('Raw business address data:', address);
        
        return {
          location_id: address.business_address_id, // Now using the direct ID
          business_id: businessId,
          location_type:
            address.shipping_location_type ||
            locationMappings[address.business_address_type?.toLowerCase()] ||
            "Store",
          name: address.business_address_name || "Business Address",
          address_line1: address.business_address_street_address_1,
          address_line2: address.business_address_street_address_2,
          city: address.business_address_city,
          state_province: address.business_address_state_province,
          postal_code: address.business_address_postal_code,
          country: address.business_address_country,
          latitude: address.business_address_latitude,
          longitude: address.business_address_longitude,
          contact_phone: address.business_address_phone,
          isBusinessAddress: true,
          // Keep the original ID format - this may help debug the issue
          original_id: address.id,
          business_address_id: address.business_address_id,
        };
      });

      setLocations(allLocations);

      // Set default selection if we have locations
      if (allLocations.length > 0 && !selectedLocationId) {
        // Prefer warehouses first
        const warehouse = allLocations.find(
          (loc) => loc.location_type === "Warehouse"
        );
        setSelectedLocationId(
          warehouse?.location_id || allLocations[0].location_id
        );
      }
    } catch (err) {
      console.error("Error fetching locations:", err);
      setError("Failed to load locations. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async () => {
    if (!selectedLocationId) return;

    try {
      // If we have an orderId, update the start location on the server
      if (orderId) {
        // Find the selected location object to log more details
        const selectedLocation = locations.find(loc => loc.location_id === selectedLocationId);
        console.log("Updating start location for order:", orderId);
        console.log("Selected location details:", selectedLocation);
        console.log("Using explicit business ID:", businessId);
        
        // Use the correct API path that matches the backend route
        await api.put(
          `/start-location/${orderId}/start-location`, 
          {
            start_location_id: selectedLocationId,
            business_id: businessId,
          },
          // Add both headers and params to ensure correct business context
          {
            headers: {
              'X-Business-ID': businessId
            },
            params: {
              businessId: businessId
            }
          }
        );
      }

      // Call the onSelect callback with the selected location
      onSelect(selectedLocationId);
    } catch (err) {
      console.error("Error setting start location:", err);
      // Show more detailed error if available from the response
      if (err.response && err.response.data && err.response.data.message) {
        setError(`Failed to set start location: ${err.response.data.message}`);
      } else {
        setError("Failed to set start location. Please try again.");
      }
    }
  };

  const handleCancel = () => {
    // Make sure we call the onClose prop to close the dialog
    if (onClose) onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <CircularProgress />
          </Box>
        ) : locations.length === 0 ? (
          <Alert severity="warning">
            No shipping locations found. Please create a shipping location
            before continuing.
          </Alert>
        ) : (
          <Box sx={{ mt: 2 }}>
            <Paper sx={{ p: 2, mb: 3, bgcolor: "#f5f5f5" }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Why is a start location needed?
              </Typography>
              <Typography variant="body2" paragraph>
                The start location is where this shipment will begin its
                journey. This is needed to:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body2">
                    Create accurate routes and track the shipment properly
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Calculate proper distances and delivery times
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    Allow couriers to know where to pick up the package
                  </Typography>
                </li>
              </ul>
            </Paper>

            <Typography variant="body1" sx={{ mb: 2 }}>
              Please select a starting location for this shipment:
            </Typography>

            <FormControl fullWidth>
              <InputLabel>Start Location</InputLabel>
              <Select
                value={selectedLocationId || ""}
                onChange={(e) => setSelectedLocationId(e.target.value)}
                label="Start Location"
              >
                {locations.map((location) => (
                  <MenuItem
                    key={location.location_id}
                    value={location.location_id}
                  >
                    {location.name}{" "}
                    {location.isBusinessAddress
                      ? "(Business Address)"
                      : `(${location.location_type})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSelect}
          variant="contained"
          color="primary"
          disabled={loading || !selectedLocationId || locations.length === 0}
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartLocationSelector;
