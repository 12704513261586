import React, { useContext, useMemo, useRef, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  FaTachometerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaStore,
  FaTruck,
  FaUsers,
  FaCog,
  FaChartLine,
  FaBox,
  FaUserTie,
  FaPaste,
  FaHeadset,
  FaUserShield,
} from "react-icons/fa";
import logo from "../../assets/logo-plain.svg";
import "./BusinessSidebar.css"; // Reuse the same CSS
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "./SidebarContext";
import {
  getEmployeePermissions,
  ROLE_PERMISSIONS,
} from "../Auth/EmployeePermissions";

// Use an inline SVG placeholder logo to prevent loading delays
const inlineLogo = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjUwIiB2aWV3Qm94PSIwIDAgMTAwIDUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iNTAiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMjAgMTVIMzBWMzVIMjBWMTVaIiBmaWxsPSIjMDA3QkZGIi8+PHBhdGggZD0iTTQwIDE1SDgwVjI1SDQwVjE1WiIgZmlsbD0iIzAwN0JGRiIvPjxwYXRoIGQ9Ik00MCAyNUg1MFYzNUg0MFYyNVoiIGZpbGw9IiMwMDdCRkYiLz48cGF0aCBkPSJNNzAgMjVIODBWMzVINzBWMjVaIiBmaWxsPSIjMDA3QkZGIi8+PC9zdmc+`;

const EmployeeSidebar = ({ businessId, employeeRoles = [] }) => {
  const location = useLocation();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isEmployeeSidebarActive, toggleEmployeeSidebar } = useSidebar();

  // Reference for the logo image
  const logoRef = useRef(null);

  // Get employee permissions based on roles
  const permissions = useMemo(
    () => getEmployeePermissions(employeeRoles),
    [employeeRoles]
  );

  // Preload the logo image
  useEffect(() => {
    // Set the initial image source to the inline logo
    if (logoRef.current) {
      // Start with inline logo to avoid flash
      logoRef.current.src = inlineLogo;

      // Then load the actual logo
      const img = new Image();
      img.src = logo;
      img.onload = () => {
        // Once the real logo is loaded, switch to it
        if (logoRef.current) {
          logoRef.current.src = logo;
        }
      };
    }
  }, []);

  const isLinkActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLogout = async () => {
    await logout();
    navigate("/auth");
  };

  // Get employee role characteristics
  const getRoleInfo = useMemo(() => {
    const roleInfo = {
      isAdmin:
        employeeRoles.includes("Owner") ||
        employeeRoles.includes("Administrator"),
      isManager: employeeRoles.includes("Manager"),
      isCourier: employeeRoles.includes("Courier"),
      isCustomerService: employeeRoles.includes("Customer Service"),
      isSalesRep: employeeRoles.includes("Sales Representative"),
    };

    return roleInfo;
  }, [employeeRoles]);

  // Define all possible links based on permissions
  const getAccessibleLinks = () => {
    const links = [
      {
        path: `/employee/${businessId}/dashboard`,
        icon: <FaTachometerAlt />,
        text: "Dashboard",
        show: true, // Everyone gets dashboard
      },
    ];

    // Order management
    if (permissions.canView) {
      links.push({
        path: `/employee/${businessId}/orders`,
        icon: <FaBox />,
        text: getRoleInfo.isCourier ? "My Deliveries" : "Orders",
        show: true,
      });
    }

    // Customer management
    if (permissions.canManageCustomers) {
      links.push({
        path: `/employee/${businessId}/customers`,
        icon: <FaUsers />,
        text: "Customers",
        show: true,
      });
    }

    // Employee management (for managers and admins)
    if (permissions.canViewEmployees) {
      links.push({
        path: `/employee/${businessId}/employees`,
        icon: <FaUserTie />,
        text: "Employees",
        show: true,
      });
    }

    // Analytics and reports
    if (permissions.canViewAnalytics) {
      links.push({
        path: `/employee/${businessId}/reports`,
        icon: <FaChartLine />,
        text: "Reports & Analytics",
        show: true,
      });
    }

    // Settings - only for admins
    if (permissions.canManageSettings) {
      links.push({
        path: `/employee/${businessId}/settings`,
        icon: <FaCog />,
        text: "Settings",
        show: true,
      });
    }

    // Only show links where show is true (for future flexibility)
    return links.filter((link) => link.show);
  };

  const renderLinks = (links) => {
    return links.map(({ path, icon, text }) => (
      <Link key={path} to={path} className={isLinkActive(path)}>
        {icon}
        <span>{isEmployeeSidebarActive && text}</span>
      </Link>
    ));
  };

  // Get employee role banner text
  const getRoleBanner = () => {
    if (employeeRoles.includes("Owner")) return "Owner Portal";
    if (employeeRoles.includes("Administrator")) return "Admin Portal";
    if (employeeRoles.includes("Manager")) return "Manager Portal";
    if (employeeRoles.includes("Supervisor")) return "Supervisor Portal";
    if (employeeRoles.includes("Courier")) return "Courier Portal";
    if (employeeRoles.includes("Customer Service")) return "Customer Support";
    if (employeeRoles.includes("Sales Representative")) return "Sales Portal";
    return "Employee Portal";
  };

  // Get role icon
  const getRoleIcon = () => {
    if (
      employeeRoles.includes("Owner") ||
      employeeRoles.includes("Administrator")
    )
      return <FaUserShield className="role-icon" />;
    if (employeeRoles.includes("Manager"))
      return <FaUserTie className="role-icon" />;
    if (employeeRoles.includes("Courier"))
      return <FaTruck className="role-icon" />;
    if (employeeRoles.includes("Customer Service"))
      return <FaHeadset className="role-icon" />;
    if (employeeRoles.includes("Sales Representative"))
      return <FaStore className="role-icon" />;
    return <FaPaste className="role-icon" />;
  };

  // Prepare the sidebar logo - memoized to prevent rerenders
  const sidebarLogo = useMemo(
    () => (
      <div className="sidebar-logo">
        <img
          ref={logoRef}
          src={logo}
          data-src={inlineLogo} // Fallback data URI version
          alt="Cexpress Logo"
          className="logo"
          style={{
            transition: "opacity 0.3s ease, width 0.3s ease", // Smooth transitions
            width: isEmployeeSidebarActive ? "80%" : "70%", // Adjust size based on sidebar state
            maxHeight: "40px",
            objectFit: "contain", // Maintain aspect ratio
          }}
          onError={(e) => {
            // If the main image fails, use the inline version
            if (e.target.src !== e.target.dataset.src) {
              e.target.src = e.target.dataset.src;
            }
          }}
        />
      </div>
    ),
    [isEmployeeSidebarActive]
  );

  return (
    <aside
      className={`business-sidebar employee-sidebar ${
        isEmployeeSidebarActive ? "active" : ""
      }`}
    >
      <div className="sidebar-top">
        {sidebarLogo}
        <button onClick={toggleEmployeeSidebar} className="sidebar-toggle">
          {isEmployeeSidebarActive ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>

      {/* Role indicator banner */}
      {isEmployeeSidebarActive && (
        <div className="employee-role-banner">
          {getRoleIcon()}
          <span>{getRoleBanner()}</span>
        </div>
      )}

      <nav>
        {/* Render links based on permissions */}
        {renderLinks(getAccessibleLinks())}
      </nav>

      <div className="sidebar-bottom">
        <button onClick={handleLogout} className="sidebar-link">
          <FaSignOutAlt />
          <span>{isEmployeeSidebarActive && "Log Out"}</span>
        </button>
      </div>
    </aside>
  );
};

export default EmployeeSidebar;
