import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import LoginForm from "../../components/Form/LoginForm/LoginForm";
import SignUpForm from "../../components/Form/SignUpForm/SignUpForm";
import ResetForm from "../../components/Form/ResetForm/ResetForm";
import { Alert } from "@mui/material";
import { AuthContext, AUTH_STATUS } from "../../components/Auth/AuthContext";
import "./LoginPage.css";

const LoginPage = ({ authErrorMessage }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("login");
  const { authStatus } = useContext(AuthContext);

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  // Monitor authentication status for debugging
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.debug("LoginPage: Auth status is", authStatus);
    }
  }, [authStatus]);

  return (
    <div className="login-page" id="wrapper">
      <div id="form-content" className="form-content">
        {/* Display auth error from route or context */}
        {authErrorMessage && (
          <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
            {authErrorMessage}
          </Alert>
        )}
        {location.state?.authError && (
          <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
            {location.state.authError}
          </Alert>
        )}
        <div className="tabs">
          <button
            className={activeTab === "login" ? "active" : ""}
            onClick={() => setActiveTab("login")}
          >
            Sign In
          </button>
          <button
            className={activeTab === "signup" ? "active" : ""}
            onClick={() => setActiveTab("signup")}
          >
            Sign Up
          </button>
          <button
            className={activeTab === "reset" ? "active" : ""}
            onClick={() => setActiveTab("reset")}
          >
            Reset
          </button>
        </div>
        <div className="tab-descriptions">
          {activeTab === "login" && <p>Log in to your account.</p>}
          {activeTab === "signup" && <p>Create a new account.</p>}
          {activeTab === "reset" && <p>Reset your password.</p>}
        </div>
        <div className="form-container">
          {activeTab === "login" && <LoginForm />}
          {activeTab === "signup" && <SignUpForm />}
          {activeTab === "reset" && <ResetForm />}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
