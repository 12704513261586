import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import styles from "./BusinessProfilePage.module.css";
import { Skeleton } from "@mui/material";

const defaultProfilePicture = "/images/background-image.jpg";
const defaultBannerPicture = "/images/background-image.jpg";

const ProfileSkeleton = () => (
  <div className={styles["profile-info"]}>
    <div className={styles["profile-picture"]}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        animation="wave"
      />
    </div>
    <div className={styles["banner-picture"]}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={200}
        animation="wave"
      />
    </div>
    {/* ...other skeleton placeholders... */}
  </div>
);

const BusinessProfilePage = () => {
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Initialize state
  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const initialBiz = getBusinessById(businessId);
    console.log("[INIT] businessId:", businessId, "=> initialBiz:", initialBiz);
    return initialBiz;
  });

  // Fetch business details when businessId changes
  useEffect(() => {
    if (!businessId) {
      console.warn("[useEffect] No businessId in route params.");
      return;
    }
    const business = getBusinessById(businessId);
    console.log(
      "[useEffect] Fetched business for ID:",
      businessId,
      "=>",
      business
    );
    setCurrentBusiness(business);
  }, [businessId, getBusinessById]);

  // Log whenever currentBusiness changes
  useEffect(() => {
    console.log("[currentBusiness changed]", currentBusiness);
  }, [currentBusiness]);

  // No longer need to handle sidebar state as we're using context

  const profilePictureSrc = currentBusiness?.business_profile_picture_url
    ? `${baseUrl}${currentBusiness.business_profile_picture_url}`
    : defaultProfilePicture;

  const bannerPictureSrc = currentBusiness?.business_banner_url
    ? `${baseUrl}${currentBusiness.business_banner_url}`
    : defaultBannerPicture;

  const handleImageError = (e, fallbackSrc) => {
    console.error("Image failed to load:", e.target.src);
    e.target.src = fallbackSrc;
  };

  return (
    <div className="business-page">
      <main className="business-page-content">
        <h1 className={styles.title}>Business Profile</h1>

        {/* Show skeleton if we don't have a currentBusiness */}
        {!currentBusiness ? (
          <ProfileSkeleton />
        ) : (
          <>
            <div className={styles["profile-info"]}>
              <div className={styles["profile-picture"]}>
                <img
                  src={profilePictureSrc}
                  alt="Business Profile"
                  crossOrigin="anonymous"
                  loading="lazy"
                />
              </div>
              <div className={styles["banner-picture"]}>
                <img
                  src={bannerPictureSrc}
                  alt="Business Banner"
                  crossOrigin="anonymous"
                  loading="lazy"
                />
              </div>
              <div className={styles["business-details"]}>
                <h2>{currentBusiness.business_name}</h2>
                <p>Email: {currentBusiness.business_email}</p>
                <p>Phone: {currentBusiness.business_phone || "Not provided"}</p>
                <p>Category: {currentBusiness.business_category_name}</p>
                <p>Status: {currentBusiness.business_status}</p>
              </div>
            </div>
            <div className={styles["profile-grid"]}>
              <Link
                to={`/business/${businessId}/edit`}
                className={styles["profile-card"]}
              >
                <h3>Edit Business</h3>
                <p>Update your business information</p>
              </Link>
              <Link
                to={`/business/${businessId}/services`}
                className={styles["profile-card"]}
              >
                <h3>Services</h3>
                <p>Manage your service offerings</p>
              </Link>
              <Link
                to={`/business/${businessId}/addresses`}
                className={styles["profile-card"]}
              >
                <h3>Addresses</h3>
                <p>Manage business addresses</p>
              </Link>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default BusinessProfilePage;
