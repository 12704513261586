import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  Chip,
  Button,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  LocalShipping,
  AttachMoney,
  Schedule,
  CheckCircleOutline,
  ErrorOutline,
  Security,
  Receipt,
  Inventory,
  Scale,
  PriceCheck,
  Print as PrintIcon,
} from "@mui/icons-material";

import PrintDialog from "./PrintDialog";

const formatCurrency = (value) => {
  return parseFloat(value || 0).toFixed(2);
};

const formatPercentage = (value) => {
  return parseFloat(value || 0).toFixed(1);
};

const OrderSummaryCard = ({ order, packages }) => {
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const totalWeight = useMemo(() => {
    return packages.reduce((sum, pkg) => sum + parseFloat(pkg.weight || 0), 0);
  }, [packages]);

  const totalVolume = useMemo(() => {
    return packages.reduce(
      (sum, pkg) =>
        sum +
        parseFloat(pkg.dimensions?.length || 0) *
          parseFloat(pkg.dimensions?.width || 0) *
          parseFloat(pkg.dimensions?.height || 0),
      0
    );
  }, [packages]);

  const additionalChargesTotal = useMemo(() => {
    return (order?.additional_charges || []).reduce(
      (sum, charge) => sum + parseFloat(charge.amount || 0),
      0
    );
  }, [order?.additional_charges]);

  return (
    <Grid container spacing={2}>
      {/* Shipping Tier Card */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Shipping Service</Typography>
              <Box display="flex" gap={1}>
                <Chip
                  icon={<LocalShipping />}
                  label={order?.ShippingTier?.service_level || "Standard"}
                  color="primary"
                  size="small"
                />
                <Chip
                  label={order?.ShippingTier?.is_active ? "Active" : "Inactive"}
                  color={order?.ShippingTier?.is_active ? "success" : "default"}
                  size="small"
                />
              </Box>
            </Box>

            <Typography variant="body2" color="textSecondary" paragraph>
              {order?.ShippingTier?.description || "No description available"}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" mb={1}>
                  <LocalShipping fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Modes:{" "}
                    {order?.ShippingTier?.transportation_modes?.join(", ") ||
                      "Not specified"}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Schedule fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Delivery:{" "}
                    {order?.ShippingTier?.estimated_delivery_time
                      ? `${order.ShippingTier.estimated_delivery_time.min}-${order.ShippingTier.estimated_delivery_time.max} ${order.ShippingTier.estimated_delivery_time.unit}`
                      : "Not specified"}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Scale fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Weight Range: {order?.ShippingTier?.min_weight || 0} -{" "}
                    {order?.ShippingTier?.max_weight || "∞"}{" "}
                    {packages[0]?.weight_unit || "lb"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Insurance Card */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Insurance Details</Typography>
              <Chip
                icon={<Security />}
                label={order?.Insurance ? "Insured" : "No Insurance"}
                color={order?.Insurance ? "success" : "default"}
                size="small"
              />
            </Box>

            {order?.Insurance ? (
              <>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {order.Insurance.description || "No description available"}
                </Typography>
                <Box display="flex" alignItems="center" mb={1}>
                  <PriceCheck fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Premium Rate:{" "}
                    {formatPercentage(order.Insurance.premium_rate)}%
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <AttachMoney fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Max Coverage: $
                    {formatCurrency(order.Insurance.max_insurance_coverage)}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No insurance selected for this shipment
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Costs Summary Card */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Cost Breakdown</Typography>
              <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                onClick={() => setIsPrintDialogOpen(true)}
                sx={{
                  borderRadius: 1,
                  textTransform: "none",
                }}
              >
                Print Receipt
              </Button>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Scale fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Total Weight: {formatCurrency(totalWeight)}{" "}
                    {packages[0]?.weight_unit || "lb"}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Inventory fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Total Volume: {formatCurrency(totalVolume)} cubic{" "}
                    {packages[0]?.dimensions_unit || "in"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center" mb={1}>
                  <LocalShipping fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Shipping Cost: ${formatCurrency(order?.shipping_cost)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Security fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Insurance Cost: ${formatCurrency(order?.insurance_cost)}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {order?.additional_charges?.length > 0 && (
                  <Box>
                    <Typography variant="subtitle2" gutterBottom>
                      Additional Charges:
                    </Typography>
                    {order.additional_charges.map((charge, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        mb={1}
                      >
                        <Receipt fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="body2">
                          {charge.description}: ${formatCurrency(charge.amount)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    bgcolor: "primary.main",
                    color: "primary.contrastText",
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="h6">Total Cost</Typography>
                  <Typography variant="h4">
                    ${formatCurrency(order?.total_cost)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <PrintDialog
        open={isPrintDialogOpen}
        onClose={() => setIsPrintDialogOpen(false)}
        orderData={order}
      />
    </Grid>
  );
};

export default OrderSummaryCard;
