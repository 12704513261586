import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Collapse,
  Box,
  Typography,
  Switch,
  Grid,
  Chip,
  IconButton,
  CircularProgress,
  Alert,
  Checkbox,
  Pagination,
  Card,
  CardContent,
  CardHeader,
  Tooltip,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import _ from "lodash";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { useOrders } from "./OrdersContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import RouteBuilder from "../AssetsPage/RouteBuilder/RouteBuilder";
import BatchRouteBuilderWithLocationCheck from "../AssetsPage/BatchRouteBuilder/BatchRouteBuilder";
import OrderMap from "./OrderMap";
import Split from "react-split";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

const OrderAssignment = () => {
  const {
    orders,
    groups,
    couriers,
    locations,
    loading,
    error: contextError,
    fetchOrders,
    addOrderToGroup,
    removeOrderFromGroup,
  } = useOrders();
  const [locationMap, setLocationMap] = useState({});
  const [isFullScreenMap, setIsFullScreenMap] = useState(false);
  const [isFullScreenTable, setIsFullScreenTable] = useState(false);
  const [hoveredOrder, setHoveredOrder] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(true);
  const [isTableOpen, setIsTableOpen] = useState(true);
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const [addresses, setAddresses] = useState([]);
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [showGroups, setShowGroups] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [batchBuilderKey, setBatchBuilderKey] = useState(0);
  const [filters, setFilters] = useState({
    status: "",
    serviceLevel: "",
    orderCount: "",
    isAssigned: "",
    employee: "",
    orderType: "all",
  });
  const tableContainerRef = useRef(null);
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [error, setError] = useState(contextError);
  const [collaboratingOrders, setCollaboratingOrders] = useState([]);
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [tableWidth, setTableWidth] = useState("50%");

  // Helper function to check if an order can be assigned
  const canAssignOrder = useCallback(
    (order) => {
      // Cannot assign delivered or cancelled orders
      if (
        order.order_status === "delivered" ||
        order.order_status === "cancelled"
      ) {
        return false;
      }

      // For collaborative orders, check if we are the collaborating business
      if (order.is_collaborative) {
        // Only the collaborating business can assign routes
        return (
          order.ShippingCollaborations?.[0]?.collaborating_business_id ===
          businessId
        );
      }

      // Regular orders can be assigned
      return true;
    },
    [businessId]
  );

  const [sortConfig, setSortConfig] = useState({
    field: "createdAt",
    direction: "desc",
  });
  const sortOptions = [
    {
      value: "createdAt_desc",
      label: "Newest First",
      field: "createdAt",
      direction: "desc",
    },
    {
      value: "createdAt_asc",
      label: "Oldest First",
      field: "createdAt",
      direction: "asc",
    },
    {
      value: "total_cost_desc",
      label: "Highest Cost",
      field: "total_cost",
      direction: "desc",
    },
    {
      value: "total_cost_asc",
      label: "Lowest Cost",
      field: "total_cost",
      direction: "asc",
    },
    {
      value: "order_status_asc",
      label: "Status (A-Z)",
      field: "order_status",
      direction: "asc",
    },
    {
      value: "order_status_desc",
      label: "Status (Z-A)",
      field: "order_status",
      direction: "desc",
    },
  ];

  // Track expanded rows by ID
  const [expandedRows, setExpandedRows] = useState({});

  // State to track which items have been sorted
  const [sortedItemsCache, setSortedItemsCache] = useState({});

  const handleOrderClick = (orderId) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}/${orderId}`);
  };

  useEffect(() => {
    if (businessId) {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
    }
  }, [businessId, getBusinessById]);

  // Initialize collaborating orders when orders change
  useEffect(() => {
    if (orders && orders.length > 0) {
      // Set collaborating orders based on the is_collaborative field or ShippingCollaborations
      const collaborative = orders.filter(
        (order) =>
          order.is_collaborative ||
          (order.ShippingCollaborations &&
            order.ShippingCollaborations.length > 0)
      );
      setCollaboratingOrders(collaborative);
      console.log("Found", collaborative.length, "collaborating orders");
    }
  }, [orders]);

  // Effect to update location map and reset sorting cache when data changes
  useEffect(() => {
    console.log("Data dependencies changed - rebuilding location map");

    // Build location map
    const newLocationMap = {};
    if (Array.isArray(locations)) {
      locations.forEach((location) => {
        if (location && location.location_id) {
          newLocationMap[location.location_id] = {
            location_type: location.location_type,
            name: location.name,
            address_line1: location.address_line1,
            address_line2: location.address_line2,
            city: location.city,
            state_province: location.state_province,
            postal_code: location.postal_code,
            country: location.country,
            latitude: location.latitude,
            longitude: location.longitude,
          };
        }
      });
    }

    setLocationMap(newLocationMap);

    // Clear sort cache when data changes to prevent stale sorting
    setSortedItemsCache({});
  }, [orders, groups, couriers, locations]);

  const filteredItems = useMemo(() => {
    let items = showGroups ? groups || [] : orders || [];

    if (!showGroups) {
      if (filters.orderType === "collaborating") {
        items = collaboratingOrders;
      } else if (filters.orderType === "all") {
        // No need to add collaboratingOrders - they're already included in items
        // since they're part of the orders array from the backend
      } else if (filters.orderType === "own") {
        // Filter out collaborative orders when 'own' is selected
        items = items.filter(
          (order) =>
            !order.is_collaborative &&
            !(
              order.ShippingCollaborations &&
              order.ShippingCollaborations.length > 0
            )
        );
      }
    }

    if (filters.status) {
      items = items.filter((item) => {
        if (showGroups) {
          return item.status === filters.status;
        } else {
          const orderStatus = item.order_status
            ? item.order_status.toLowerCase()
            : "";
          return orderStatus === filters.status.toLowerCase();
        }
      });
    }

    if (!showGroups && filters.serviceLevel) {
      items = items.filter((order) => {
        const serviceLevelMatch =
          order.ShippingTier?.service_level === filters.serviceLevel;
        return serviceLevelMatch;
      });
    }

    if (showGroups && filters.orderCount) {
      items = items.filter((group) => {
        const orderCount = group.ShippingOrders?.length || 0;
        switch (filters.orderCount) {
          case "low":
            return orderCount < 5;
          case "medium":
            return orderCount >= 5 && orderCount < 10;
          case "high":
            return orderCount >= 10;
          default:
            return true;
        }
      });
    }

    if (filters.isAssigned !== "") {
      items = items.filter((item) => {
        const hasSegments = (item.RouteSegments || []).length > 0;
        return filters.isAssigned === "assigned" ? hasSegments : !hasSegments;
      });
    }

    if (filters.employee) {
      items = items.filter((item) => {
        return (item.RouteSegments || []).some(
          (segment) => segment.courier_id === filters.employee
        );
      });
    }

    if (searchQuery) {
      items = items.filter((item) => {
        const searchFields = showGroups
          ? [item.name, item.group_id, String(item.ShippingOrders?.length || 0)]
          : [
              item.shipping_order_id,
              item.order_status,
              item.sender?.first_name,
              item.sender?.last_name,
              item.recipient?.first_name,
              item.recipient?.last_name,
              item.is_collaborative ? "collaborating" : "own",
              item.tracking_number,
            ];

        return searchFields.some(
          (field) =>
            field &&
            field.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    return items;
  }, [showGroups, groups, orders, collaboratingOrders, searchQuery, filters]);

  // Get the value to sort by for an item, based on field name and item type
  const getSortValue = useCallback(
    (item, field) => {
      // Skip sorting for invalid items
      if (!item) return undefined;

      // Helper to safely get nested properties
      const getNestedValue = (obj, path) => {
        if (!obj) return undefined;

        const keys = path.split(".");
        return keys.reduce(
          (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
          obj
        );
      };

      if (showGroups) {
        // Handle special group fields
        switch (field) {
          case "total_cost":
            return parseFloat(item.total_value || 0);
          case "order_status":
            return (item.status || "").toLowerCase();
          case "createdAt":
            return item.createdAt ? new Date(item.createdAt).getTime() : 0;
          default:
            return getNestedValue(item, field);
        }
      } else {
        // Handle order fields with proper type handling
        switch (field) {
          case "total_cost":
            return typeof item.total_cost === "string"
              ? parseFloat(item.total_cost)
              : typeof item.total_cost === "number"
              ? item.total_cost
              : 0;
          case "order_status":
            return (item.order_status || "").toLowerCase();
          case "createdAt":
            return item.createdAt ? new Date(item.createdAt).getTime() : 0;
          default:
            return getNestedValue(item, field);
        }
      }
    },
    [showGroups]
  );

  // Completely restructured sorting logic with caching of IDs
  const sortedAndFilteredItems = useMemo(() => {
    console.log("Computing sorted items");

    // Create a cache key based on current sort config and filter state
    const cacheKey = JSON.stringify({
      sortField: sortConfig.field,
      sortDirection: sortConfig.direction,
      filterHash: JSON.stringify(filters),
      showGroups,
      filteredItems: filteredItems.length,
    });

    // Check if we already have this sorted order cached
    if (sortedItemsCache[cacheKey]) {
      console.log("Using cached sort result");
      return sortedItemsCache[cacheKey];
    }

    // Extract the sort values once before sorting to improve performance
    const itemsWithSortValues = filteredItems.map((item) => {
      // Store the unique ID for tracking
      const uniqueId = showGroups ? item.group_id : item.shipping_order_id;

      // Get and normalize the sort value
      let sortValue = getSortValue(item, sortConfig.field);

      // Convert dates to timestamps for easy comparison
      if (sortValue instanceof Date) {
        sortValue = sortValue.getTime();
      }

      // Convert strings to lowercase for case-insensitive sorting
      if (typeof sortValue === "string") {
        sortValue = sortValue.toLowerCase();
      }

      return {
        item,
        uniqueId,
        sortValue,
      };
    });

    // Stable sort with improved type handling
    const sortedItems = _.sortBy(itemsWithSortValues, (item) => {
      // For undefined/null values, use special comparison values
      if (item.sortValue === undefined || item.sortValue === null) {
        return sortConfig.direction === "asc"
          ? Number.NEGATIVE_INFINITY
          : Number.POSITIVE_INFINITY;
      }
      return item.sortValue;
    });

    // Reverse if descending order is required
    if (sortConfig.direction === "desc") {
      sortedItems.reverse();
    }

    // Extract just the items from the sorted array
    const result = sortedItems.map((wrapper) => wrapper.item);

    // Cache the result for future renders
    setSortedItemsCache((prev) => ({
      ...prev,
      [cacheKey]: result,
    }));

    return result;
  }, [filteredItems, sortConfig, filters, showGroups, getSortValue]);

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedAndFilteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedAndFilteredItems, currentPage, itemsPerPage]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Get the assignable items from the current paginated items
  const getAssignableItems = () => {
    return paginatedItems.filter((item) => {
      // For groups, we assume they can be assigned as long as they contain at least one order
      if (showGroups) {
        return item.ShippingOrders?.length > 0;
      }
      // For individual orders, check if they can be assigned
      return canAssignOrder(item);
    });
  };

  // Check if all assignable items on the current page are selected
  const areAllAssignableItemsSelected = () => {
    const assignableItems = getAssignableItems();

    // If there are no assignable items, return false
    if (assignableItems.length === 0) return false;

    // Check if all assignable items are in the selectedItems array
    return assignableItems.every((item) => {
      const idField = showGroups ? "group_id" : "shipping_order_id";
      return selectedItems.some(
        (selected) => selected[idField] === item[idField]
      );
    });
  };

  const handleSelectAllItems = (event) => {
    if (event.target.checked) {
      // Only select items that can be assigned from the current page
      const assignableItems = getAssignableItems();

      // Add new assignable items to selection, preserving items from other pages
      const newSelectedItems = [...selectedItems];

      // Loop through assignable items and add them if not already in selected items
      assignableItems.forEach((item) => {
        const itemId = showGroups ? item.group_id : item.shipping_order_id;
        const isAlreadySelected = selectedItems.some(
          (selectedItem) =>
            selectedItem[showGroups ? "group_id" : "shipping_order_id"] ===
            itemId
        );

        if (!isAlreadySelected) {
          newSelectedItems.push(item);
        }
      });

      setSelectedItems(newSelectedItems);
    } else {
      // Deselect all items on current page, but keep items from other pages
      const currentPageIds = paginatedItems.map(
        (item) => item[showGroups ? "group_id" : "shipping_order_id"]
      );

      // Filter out items from the current page
      const itemsFromOtherPages = selectedItems.filter(
        (item) =>
          !currentPageIds.includes(
            item[showGroups ? "group_id" : "shipping_order_id"]
          )
      );

      setSelectedItems(itemsFromOtherPages);
    }
  };

  const handleSelectItem = (item) => {
    // Check if this is an order that can't be assigned (delivered, cancelled, or collaboration where we're not the collaborator)
    if (!showGroups && !canAssignOrder(item)) {
      // Skip selection of orders that can't be assigned
      return;
    }

    // Get the unique ID for this item
    const itemId = showGroups ? item.group_id : item.shipping_order_id;

    setSelectedItems((prevSelected) => {
      // Check if this item is already selected by comparing IDs, not references
      const isSelected = prevSelected.some(
        (selectedItem) =>
          selectedItem[showGroups ? "group_id" : "shipping_order_id"] === itemId
      );

      if (isSelected) {
        // Remove the item by ID
        return prevSelected.filter(
          (selectedItem) =>
            selectedItem[showGroups ? "group_id" : "shipping_order_id"] !==
            itemId
        );
      } else {
        // Add the item
        return [...prevSelected, item];
      }
    });
  };

  const handleOpenAssignmentDialog = (item) => {
    setCurrentAssignment(item);
    setOpenAssignmentDialog(true);
  };

  const handleCloseAssignmentDialog = () => {
    setOpenAssignmentDialog(false);
    setCurrentAssignment(null);
  };

  const handleSubmitAssignment = async (data) => {
    setOpenAssignmentDialog(false);
    setCurrentAssignment(null);
    // refresh data
    if (businessId) {
      await fetchOrders(businessId, true); // Force refresh
    }
  };

  // Collect the shipping_order_ids from the selected items
  const handleOpenBatchAssignment = () => {
    if (!selectedItems.length) return;

    // Extract all shipping_order_ids, handling both groups and individual orders
    const selectedOrderIds = selectedItems.flatMap((item) => {
      if (showGroups) {
        // For groups, get all order IDs in the group
        return (
          item.ShippingOrders?.map((order) => order.shipping_order_id) || []
        );
      }
      // For individual orders, get the single ID
      return [item.shipping_order_id];
    });

    // Remove duplicates in case same order appears in multiple groups
    const uniqueOrderIds = [...new Set(selectedOrderIds)];

    // Increment the key to force a fresh instance
    setBatchBuilderKey((prev) => prev + 1);

    setCurrentAssignment({
      orderIds: uniqueOrderIds,
    });
    setOpenBatchDialog(true);
  };

  const handleCloseBatchAssignment = () => {
    setOpenBatchDialog(false);
    setCurrentAssignment(null);
    // Increment key to ensure a fresh instance next time
    setBatchBuilderKey((prev) => prev + 1);
    // Any pending state from the BatchRouteBuilderWithLocationCheck will be cleared
    // because our onClose handler is properly passed through to the component
  };

  // After saving in the batch route builder, we can refetch or do something
  const handleBatchSubmit = async () => {
    if (businessId) {
      await fetchOrders(businessId, true); // Force refresh
    }
    setOpenBatchDialog(false);
    setCurrentAssignment(null);
  };

  const renderFilters = () => (
    <>
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label={showGroups ? "Search Groups" : "Search Orders"}
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by ID, name, status..."
          />
        </Grid>

        {!showGroups && (
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Order Type</InputLabel>
              <Select
                name="orderType"
                value={filters.orderType}
                onChange={handleFilterChange}
                label="Order Type"
              >
                <MenuItem value="all">All Orders</MenuItem>
                <MenuItem value="own">Own Orders</MenuItem>
                <MenuItem value="collaborating">Collaborating Orders</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              {showGroups
                ? [
                    <MenuItem key="active" value="active">
                      Active
                    </MenuItem>,
                    <MenuItem key="inactive" value="inactive">
                      Inactive
                    </MenuItem>,
                  ]
                : [
                    <MenuItem key="pending" value="pending">
                      Pending
                    </MenuItem>,
                    <MenuItem key="processing" value="processing">
                      Processing
                    </MenuItem>,
                    <MenuItem key="shipped" value="shipped">
                      Shipped
                    </MenuItem>,
                    <MenuItem key="delivered" value="delivered">
                      Delivered
                    </MenuItem>,
                    <MenuItem key="cancelled" value="cancelled">
                      Cancelled
                    </MenuItem>,
                  ]}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Assignment Status</InputLabel>
            <Select
              name="isAssigned"
              value={filters.isAssigned}
              onChange={handleFilterChange}
              label="Assignment Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="assigned">Assigned</MenuItem>
              <MenuItem value="unassigned">Unassigned</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Employee</InputLabel>
            <Select
              name="employee"
              value={filters.employee}
              onChange={handleFilterChange}
              label="Employee"
            >
              <MenuItem value="">All</MenuItem>
              {couriers.map((courier) => (
                <MenuItem key={courier.employee_id} value={courier.employee_id}>
                  {courier.employee_first_name} {courier.employee_last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {showGroups ? (
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Order Count</InputLabel>
              <Select
                name="orderCount"
                value={filters.orderCount}
                onChange={handleFilterChange}
                label="Order Count"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="low">Low (0-4)</MenuItem>
                <MenuItem value="medium">Medium (5-9)</MenuItem>
                <MenuItem value="high">High (10+)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Service Level</InputLabel>
              <Select
                name="serviceLevel"
                value={filters.serviceLevel}
                onChange={handleFilterChange}
                label="Service Level"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Standard">Standard</MenuItem>
                <MenuItem value="Express">Express</MenuItem>
                <MenuItem value="Priority">Priority</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Items per page</InputLabel>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per page"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={`${sortConfig.field}_${sortConfig.direction}`}
              onChange={(e) => {
                // Find the matching option and use its field and direction directly
                const selectedOption = sortOptions.find(
                  (option) => option.value === e.target.value
                );
                if (selectedOption) {
                  setSortConfig({
                    field: selectedOption.field,
                    direction: selectedOption.direction,
                  });
                }
              }}
              label="Sort By"
            >
              {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {(Object.values(filters).some((value) => value !== "") ||
        sortConfig.field !== "createdAt") && (
        <Box mb={2}>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Active Filters:
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {Object.entries(filters).map(([key, value]) => {
              if (!value) return null;
              return (
                <Chip
                  key={key}
                  label={`${key
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()}: ${value}`}
                  onDelete={() =>
                    handleFilterChange({
                      target: { name: key, value: "" },
                    })
                  }
                  size="small"
                />
              );
            })}
            {sortConfig.field !== "createdAt" && (
              <Chip
                label={`Sort: ${(() => {
                  // Find the matching sort option and use its label
                  const currentSortOption = sortOptions.find(
                    (option) =>
                      option.field === sortConfig.field &&
                      option.direction === sortConfig.direction
                  );
                  if (currentSortOption) return currentSortOption.label;

                  // If not found, provide a readable fallback
                  if (sortConfig.field === "total_cost") {
                    return sortConfig.direction === "asc"
                      ? "Lowest Cost"
                      : "Highest Cost";
                  } else if (sortConfig.field === "order_status") {
                    return sortConfig.direction === "asc"
                      ? "Status (A-Z)"
                      : "Status (Z-A)";
                  } else {
                    return `${sortConfig.field
                      .replace(/([A-Z])/g, " $1")
                      .toLowerCase()} (${
                      sortConfig.direction === "asc"
                        ? "ascending"
                        : "descending"
                    })`;
                  }
                })()}`}
                onDelete={() =>
                  setSortConfig({ field: "createdAt", direction: "desc" })
                }
                size="small"
              />
            )}
            <Chip
              label="Clear All"
              onClick={() => {
                setFilters({
                  status: "",
                  serviceLevel: "",
                  orderCount: "",
                  isAssigned: "",
                  employee: "",
                  orderType: "all",
                });
                setSortConfig({ field: "createdAt", direction: "desc" });
              }}
              size="small"
              color="secondary"
            />
          </Box>
        </Box>
      )}
    </>
  );

  const AssignmentRow = ({ item }) => {
    // Get the unique ID for this item - using safe access
    const itemId = showGroups
      ? item?.group_id || `group-${Math.random()}`
      : item?.shipping_order_id || `order-${Math.random()}`;

    // Check if this row is expanded using the ID-based tracking
    const isOpen = Boolean(expandedRows[itemId]);

    // Custom handler for toggling with event prevention
    const toggleOpen = (e) => {
      // Prevent event bubbling
      if (e) e.stopPropagation();

      console.log(`Toggling row ${itemId}, current state: ${isOpen}`);

      setExpandedRows((prev) => {
        const newState = { ...prev };
        if (isOpen) {
          delete newState[itemId];
        } else {
          newState[itemId] = true;
        }
        return newState;
      });
    };

    const isItemSelected = selectedItems.some(
      (selectedItem) =>
        selectedItem[showGroups ? "group_id" : "shipping_order_id"] ===
        item[showGroups ? "group_id" : "shipping_order_id"]
    );

    const isAssigned = (item.RouteSegments || []).length > 0;
    const assignedEmployees = showGroups
      ? [
          ...new Set(
            item.ShippingOrders?.flatMap((order) =>
              (order.RouteSegments || []).map((seg) => seg.courier_id)
            )
          ),
        ]
      : item.RouteSegments?.map((segment) => segment.courier_id);

    // For groups, calculate assignment status
    const getGroupAssignmentStatus = (group) => {
      if (!group.ShippingOrders?.length) return "none";

      const totalOrders = group.ShippingOrders.length;
      const assignedOrders = group.ShippingOrders.filter(
        (order) => (order.RouteSegments || []).length > 0
      ).length;

      if (assignedOrders === 0) return "none";
      if (assignedOrders === totalOrders) return "full";
      return "partial";
    };

    const assignmentStatus = showGroups
      ? getGroupAssignmentStatus(item)
      : (item.RouteSegments || []).length > 0
      ? "full"
      : "none";

    const handleGroupBatchAssign = (group) => {
      const groupOrderIds =
        group.ShippingOrders?.map((order) => order.shipping_order_id) || [];
      setCurrentAssignment({
        orderIds: groupOrderIds,
      });
      setOpenBatchDialog(true);
    };

    return (
      <>
        <TableRow
          sx={
            !showGroups
              ? {
                  backgroundColor: isAssigned
                    ? "rgba(76, 175, 80, 0.1)"
                    : item.is_collaborative
                    ? "rgba(33, 150, 243, 0.1)"
                    : "inherit",
                  "&:hover": {
                    backgroundColor: isAssigned
                      ? "rgba(76, 175, 80, 0.2)"
                      : item.is_collaborative
                      ? "rgba(33, 150, 243, 0.2)"
                      : "rgba(0, 0, 0, 0.04)",
                  },
                }
              : {
                  backgroundColor:
                    assignmentStatus === "full"
                      ? "rgba(76, 175, 80, 0.1)"
                      : assignmentStatus === "partial"
                      ? "rgba(255, 152, 0, 0.1)"
                      : item.is_collaborative
                      ? "rgba(33, 150, 243, 0.1)"
                      : "inherit",
                  "&:hover": {
                    backgroundColor:
                      assignmentStatus === "full"
                        ? "rgba(76, 175, 80, 0.2)"
                        : assignmentStatus === "partial"
                        ? "rgba(255, 152, 0, 0.2)"
                        : item.is_collaborative
                        ? "rgba(33, 150, 243, 0.2)"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                }
          }
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              onChange={() => handleSelectItem(item)}
              disabled={!showGroups && !canAssignOrder(item)}
            />
          </TableCell>
          <TableCell>
            <IconButton size="small" onClick={toggleOpen}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>
            {showGroups ? (
              <>
                {item.name}
                {assignmentStatus === "full" && (
                  <Chip
                    label="Fully Assigned"
                    size="small"
                    color="success"
                    sx={{ ml: 1 }}
                  />
                )}
                {assignmentStatus === "partial" && (
                  <Chip
                    label="Partially Assigned"
                    size="small"
                    color="warning"
                    sx={{ ml: 1 }}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  onClick={() => handleOrderClick(item.shipping_order_id)}
                >
                  {item.shipping_order_id}
                </Button>
                {item.is_collaborative && (
                  <Chip
                    label="Collaborating"
                    size="small"
                    color="primary"
                    sx={{ ml: 1 }}
                  />
                )}
                {isAssigned && (
                  <Chip
                    label="Assigned"
                    size="small"
                    color="success"
                    sx={{ ml: 1 }}
                  />
                )}
              </>
            )}
          </TableCell>
          <TableCell>
            {showGroups ? item.ShippingOrders?.length : item.order_status}
          </TableCell>
          <TableCell>
            {showGroups
              ? `$${parseFloat(item.total_value || 0).toFixed(2)}`
              : `$${parseFloat(item.total_cost || 0).toFixed(2)}`}
          </TableCell>
          <TableCell>
            {assignedEmployees?.map((employeeId) => {
              const employee = couriers.find(
                (c) => c.employee_id === employeeId
              );
              return employee ? (
                <Chip
                  key={employeeId}
                  label={`${employee.employee_first_name} ${employee.employee_last_name}`}
                  size="small"
                  sx={{ mr: 1, mb: 1 }}
                />
              ) : null;
            })}
          </TableCell>
          <TableCell>
            {showGroups ? (
              // For groups, main action is batch assign
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleGroupBatchAssign(item)}
                disabled={!item.ShippingOrders?.length}
              >
                Batch Assign Group
              </Button>
            ) : item.is_collaborative &&
              item.ShippingCollaborations?.[0]?.collaborating_business_id ===
                businessId ? (
              // We are the collaborating business - we should be able to assign routes
              <Button
                variant="contained"
                color={isAssigned ? "secondary" : "primary"}
                onClick={() => handleOpenAssignmentDialog(item)}
                disabled={
                  item.order_status === "cancelled" ||
                  item.order_status === "delivered"
                }
              >
                {isAssigned ? "Edit" : "Assign"}
              </Button>
            ) : item.is_collaborative ? (
              // Check if current business is the originator or the collaborator
              item.ShippingCollaborations?.[0]?.originating_business_id ===
              businessId ? (
                // We are the originator/requester - can't assign
                <Tooltip title="This is a collaboration order where you are the requester. The collaborating business will handle the route assignment.">
                  <span>
                    {" "}
                    {/* Tooltip requires a wrapper element that can receive the event handlers */}
                    <Button variant="contained" color="info" disabled={true}>
                      Collaborating
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                // We must be the collaborating business - we can assign
                <Button
                  variant="contained"
                  color={isAssigned ? "secondary" : "primary"}
                  onClick={() => handleOpenAssignmentDialog(item)}
                  disabled={
                    item.order_status === "cancelled" ||
                    item.order_status === "delivered"
                  }
                >
                  {isAssigned ? "Edit" : "Assign"}
                </Button>
              )
            ) : (
              <Button
                variant="contained"
                color={isAssigned ? "secondary" : "primary"}
                onClick={() => handleOpenAssignmentDialog(item)}
                disabled={
                  item.order_status === "cancelled" ||
                  item.order_status === "delivered"
                }
              >
                {isAssigned ? "Edit" : "Assign"}
              </Button>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {showGroups ? "Group Details" : "Order Details"}
                </Typography>
                {showGroups ? (
                  <Table size="small" aria-label="group orders">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Total Cost</TableCell>
                        <TableCell>Assignment</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Use a stable key for order rows */}
                      {item.ShippingOrders?.map((order) => {
                        const orderIsAssigned =
                          (order.RouteSegments || []).length > 0;
                        return (
                          <TableRow
                            key={order.shipping_order_id}
                            sx={{
                              backgroundColor: orderIsAssigned
                                ? "rgba(76, 175, 80, 0.1)"
                                : "inherit",
                              "&:hover": {
                                backgroundColor: orderIsAssigned
                                  ? "rgba(76, 175, 80, 0.2)"
                                  : "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                          >
                            <TableCell>
                              <Button
                                onClick={() =>
                                  handleOrderClick(order.shipping_order_id)
                                }
                              >
                                {order.shipping_order_id}
                              </Button>
                            </TableCell>
                            <TableCell>{order.order_status}</TableCell>
                            <TableCell>
                              ${parseFloat(order.total_cost || 0).toFixed(2)}
                            </TableCell>
                            <TableCell>
                              {orderIsAssigned && (
                                <Chip
                                  label="Assigned"
                                  size="small"
                                  color="success"
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  handleOpenAssignmentDialog(order)
                                }
                                disabled={
                                  order.order_status === "cancelled" ||
                                  order.order_status === "delivered"
                                }
                              >
                                {orderIsAssigned ? "Edit" : "Assign"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <Grid container spacing={2}>
                    {/* Sender Information */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Sender Information
                      </Typography>
                      <Typography>
                        Name: {item.sender?.first_name} {item.sender?.last_name}
                      </Typography>
                      <Typography>
                        Address: {item.sender?.Addresses?.[0]?.address_line1}
                      </Typography>
                      <Typography>
                        {item.sender?.Addresses?.[0]?.city},{" "}
                        {item.sender?.Addresses?.[0]?.state}{" "}
                        {item.sender?.Addresses?.[0]?.postal_code}
                      </Typography>
                      <Typography>Phone: {item.sender?.phone}</Typography>
                    </Grid>

                    {/* Recipient Information */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Recipient Information
                      </Typography>
                      <Typography>
                        Name: {item.recipient?.first_name}{" "}
                        {item.recipient?.last_name}
                      </Typography>
                      <Typography>
                        Address: {item.recipient?.Addresses?.[0]?.address_line1}
                      </Typography>
                      <Typography>
                        {item.recipient?.Addresses?.[0]?.city},{" "}
                        {item.recipient?.Addresses?.[0]?.state}{" "}
                        {item.recipient?.Addresses?.[0]?.postal_code}
                      </Typography>
                      <Typography>Phone: {item.recipient?.phone}</Typography>
                    </Grid>

                    {/* Shipping Details */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Shipping Details
                      </Typography>
                      <Typography>
                        Service Level: {item.ShippingTier?.service_level}
                      </Typography>
                      <Typography>Status: {item.order_status}</Typography>
                      <Typography>
                        Tracking Number:{" "}
                        {item.tracking_number || "Not available"}
                      </Typography>
                      <Typography>
                        Created: {new Date(item.createdAt).toLocaleString()}
                      </Typography>
                    </Grid>

                    {/* Package Details */}
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" gutterBottom>
                        Package Details
                      </Typography>
                      <Typography>
                        Weight: {item.Packages?.[0]?.weight || "N/A"}
                      </Typography>
                      <Typography>
                        Declared Value: $
                        {parseFloat(item.total_declared_value || 0).toFixed(2)}
                      </Typography>
                      <Typography>
                        Shipping Cost: $
                        {parseFloat(item.shipping_cost || 0).toFixed(2)}
                      </Typography>
                      <Typography>
                        Total Cost: $
                        {parseFloat(item.total_cost || 0).toFixed(2)}
                      </Typography>
                    </Grid>

                    {/* Collaboration Information */}
                    {item.is_collaborative && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                          Collaboration Information
                        </Typography>
                        <Box
                          sx={{
                            p: 1,
                            bgcolor: "primary.light",
                            color: "primary.contrastText",
                            borderRadius: 1,
                            mb: 1,
                          }}
                        >
                          {item.ShippingCollaborations?.[0]
                            ?.originating_business_id === businessId ? (
                            <Typography>
                              Collaborating with:{" "}
                              <strong>
                                {item.ShippingCollaborations?.[0]
                                  ?.CollaboratingBusiness?.business_name ||
                                  "Unknown business"}
                              </strong>
                            </Typography>
                          ) : (
                            <Typography>
                              Collaboration from:{" "}
                              <strong>
                                {item.ShippingCollaborations?.[0]
                                  ?.OriginatingBusiness?.business_name ||
                                  "Unknown business"}
                              </strong>
                            </Typography>
                          )}
                        </Box>
                        <Typography variant="body2" gutterBottom>
                          Collaboration Type:{" "}
                          {item.ShippingCollaborations?.[0]?.collaboration_type?.replace(
                            "_",
                            " "
                          ) || "N/A"}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Status:{" "}
                          {item.ShippingCollaborations?.[0]?.status?.replace(
                            "_",
                            " "
                          ) || "N/A"}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Agreed Compensation: $
                          {parseFloat(
                            item.ShippingCollaborations?.[0]
                              ?.agreed_compensation || 0
                          ).toFixed(2)}
                        </Typography>
                      </Grid>
                    )}

                    {/* Route Segments */}
                    {item.RouteSegments && item.RouteSegments.length > 0 && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                          Route Information
                        </Typography>
                        {item.RouteSegments.map((segment, index) => (
                          <Box key={segment.segment_id} sx={{ mb: 1 }}>
                            <Typography>
                              Segment {index + 1}:{" "}
                              {segment.start_location_type ===
                              "shipping_location"
                                ? segment.startBusinessLocation?.name
                                : `${segment.startCustomerAddress?.address_line1}, ${segment.startCustomerAddress?.city}`}
                              →
                              {segment.end_location_type === "shipping_location"
                                ? segment.endBusinessLocation?.name
                                : `${segment.endCustomerAddress?.address_line1}, ${segment.endCustomerAddress?.city}`}
                            </Typography>
                          </Box>
                        ))}
                      </Grid>
                    )}

                    {/* Special Instructions */}
                    {item.special_instructions && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                          Special Instructions
                        </Typography>
                        <Typography>{item.special_instructions}</Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="300px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box m={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <>
      <Card>
        <CardHeader
          title={showGroups ? "Group Assignment" : "Order Assignment"}
          action={
            <Box display="flex" alignItems="center">
              <Typography variant="body2" sx={{ mr: 1 }}>
                Group View
              </Typography>
              <Switch
                checked={showGroups}
                onChange={() => setShowGroups(!showGroups)}
              />
            </Box>
          }
        />
        <CardContent>
          {renderFilters()}

          <Box
            display="flex"
            flexDirection="column"
            height="calc(100vh - 260px)"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenBatchAssignment}
                disabled={selectedItems.length === 0}
              >
                {showGroups
                  ? `Batch Assign ${selectedItems.length} Group${
                      selectedItems.length !== 1 ? "s" : ""
                    }`
                  : `Batch Assign ${selectedItems.length} Order${
                      selectedItems.length !== 1 ? "s" : ""
                    }`}
              </Button>
            </Box>

            <Box
              sx={{
                position: "relative",
                display: "flex",
                height: "100%",
                overflow: "hidden",
              }}
            >
              {/* Table Panel */}
              <Box
                sx={{
                  width: tableWidth,
                  height: "100%",
                  backgroundColor: "white",
                  overflowY: "auto",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  transition: "width 0.3s ease",
                  flexShrink: 0,
                  position: "relative",
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={areAllAssignableItemsSelected()}
                            indeterminate={
                              selectedItems.length > 0 &&
                              paginatedItems.some((item) => {
                                const idField = showGroups
                                  ? "group_id"
                                  : "shipping_order_id";
                                return selectedItems.some(
                                  (selected) =>
                                    selected[idField] === item[idField]
                                );
                              }) &&
                              !areAllAssignableItemsSelected()
                            }
                            onChange={handleSelectAllItems}
                          />
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          {showGroups ? "Group Name" : "Order ID"}
                        </TableCell>
                        <TableCell>
                          {showGroups ? "Orders Count" : "Status"}
                        </TableCell>
                        <TableCell>
                          {showGroups ? "Total Value" : "Total Cost"}
                        </TableCell>
                        <TableCell>Assigned Employees</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedItems.map((item) => {
                        // Create stable unique keys even if data is incomplete
                        const uniqueKey = showGroups
                          ? item?.group_id || `group-${Math.random()}`
                          : item?.shipping_order_id || `order-${Math.random()}`;

                        return (
                          <AssignmentRow
                            key={uniqueKey}
                            item={item}
                            onMouseEnter={() => setHoveredOrder(item)}
                            onMouseLeave={() => setHoveredOrder(null)}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredItems.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>

                {/* Chevron button overlay */}
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    borderRadius: "4px 0 0 4px",
                    boxShadow: "-2px 0 4px rgba(0,0,0,0.1)",
                    zIndex: 2,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      if (tableWidth === "100%") {
                        setTableWidth("50%");
                      } else {
                        setTableWidth("100%");
                      }
                    }}
                  >
                    {tableWidth === "100%" ? <ChevronLeft /> : <ChevronRight />}
                  </IconButton>
                </Box>
              </Box>

              {/* Map Panel */}
              <Box
                sx={{
                  flex: 1,
                  height: "100%",
                  display: tableWidth === "100%" ? "none" : "block",
                }}
              >
                <OrderMap
                  selectedOrders={selectedItems}
                  hoveredOrder={hoveredOrder}
                  orders={filteredItems}
                  locations={locations}
                  onOrderSelect={handleSelectItem}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <RouteBuilder
        open={openAssignmentDialog}
        onClose={handleCloseAssignmentDialog}
        onSubmit={handleSubmitAssignment}
        orderId={currentAssignment?.shipping_order_id}
      />

      <BatchRouteBuilderWithLocationCheck
        key={batchBuilderKey}
        open={openBatchDialog}
        onClose={handleCloseBatchAssignment}
        onSubmit={handleBatchSubmit}
        selectedOrders={currentAssignment?.orderIds || []}
      />
    </>
  );
};

export default OrderAssignment;
