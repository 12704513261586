import React from "react";
import { Box, Typography, Card, CardContent, Chip, Stack } from "@mui/material";
import { AccessTime, Person, Business, Home } from "@mui/icons-material";

const RouteSegments = ({ segments, selectedSegmentId, onSegmentSelect }) => {
  const formatDateTime = (timestamp) => {
    if (!timestamp) return "Not scheduled";
    return new Date(timestamp).toLocaleString();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "success";
      case "in_progress":
        return "primary";
      case "pending":
        return "default";
      default:
        return "default";
    }
  };

  // Helper function to get location name based on location type
  const getLocationName = (location) => {
    if (!location) return "Unknown";

    // For business addresses
    if (location.business_address_id) {
      return location.business_address_name || "Business Location";
    }

    // For customer addresses
    if (location.address_id) {
      if (location.address_name) return location.address_name;
      if (location.recipient_name) return location.recipient_name;
      return location.address_line1 || "Customer Address";
    }

    return "Unknown Location";
  };

  // Helper function to determine location type icon
  const getLocationTypeIcon = (location) => {
    if (!location) return null;

    // Business location
    if (location.business_address_id) {
      return <Business fontSize="small" />;
    }

    // Customer location
    if (location.address_id) {
      return <Home fontSize="small" />;
    }

    return null;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom component="div">
        Route Segments
      </Typography>

      {segments.map((segment, index) => {
        const isSelected = selectedSegmentId === segment.route_segment_id;

        // Skip segments without valid locations
        if (!segment.startLocation || !segment.endLocation) {
          console.warn(`Segment ${index} missing location data`);
          return null;
        }

        return (
          <Card
            key={
              segment.route_segment_id ||
              `segment-${segment.segment_order || index}`
            }
            sx={{
              mb: 2,
              cursor: "pointer",
              border: isSelected ? "2px solid #2196F3" : "none",
              backgroundColor: isSelected
                ? "rgba(33, 150, 243, 0.08)"
                : "inherit",
              "&:hover": {
                boxShadow: 3,
              },
            }}
            onClick={() => onSegmentSelect(segment.route_segment_id)}
          >
            <CardContent>
              <Stack spacing={2}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      component="div"
                      sx={{ fontWeight: 600 }}
                    >
                      Segment {segment.segment_order || index + 1}
                    </Typography>

                    <Chip
                      icon={getLocationTypeIcon(segment.startLocation)}
                      label={getLocationName(segment.startLocation)}
                      size="small"
                      variant="outlined"
                    />
                    <Typography component="div">→</Typography>
                    <Chip
                      icon={getLocationTypeIcon(segment.endLocation)}
                      label={getLocationName(segment.endLocation)}
                      size="small"
                      variant="outlined"
                    />
                  </Stack>

                  <Chip
                    label={segment.status || "pending"}
                    color={getStatusColor(segment.status)}
                    size="small"
                  />
                </Box>

                <Stack direction="row" spacing={2}>
                  <Chip
                    icon={<AccessTime />}
                    label={`Estimated Duration: ${
                      segment.estimated_duration || 0
                    } mins`}
                    variant="outlined"
                    size="small"
                  />
                </Stack>

                <Stack direction="row" spacing={2}>
                  <Chip
                    icon={<AccessTime />}
                    label={`Expected: ${formatDateTime(
                      segment.expected_start
                    )} - ${formatDateTime(segment.expected_end)}`}
                    variant="outlined"
                    size="small"
                  />
                  {segment.courier && (
                    <Chip
                      icon={<Person />}
                      label={`${segment.courier.employee_first_name} ${segment.courier.employee_last_name}`}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

export default RouteSegments;
