import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QRCodeSVG } from "qrcode.react";
import appStoreIcon from "../../assets/app-store-badge.svg";
import googlePlayIcon from "../../assets/google-play-badge.png";
import mobileAppScreenshot from "../../assets/mobile-app-screenshot.png";
import "./DownloadPage.css";

/**
 * DownloadPage - Display information about the mobile app and download options
 * Following the marketplace app style guide
 */
const DownloadPage = () => {
  return (
    <div className="user-page-wrapper">
      <div className="user-page-content">
        {/* Header */}
        <div className="download-header">
          <Typography variant="h1" className="user-page-title">
            Cexpress Hub Mobile App <span className="beta-badge">BETA</span>
          </Typography>
          <Typography variant="subtitle1" className="user-page-subtitle">
            Access your business on the go with our powerful mobile application
            for employees and business owners – currently in the testing phase.
          </Typography>
        </div>

        {/* Main Content */}
        <div className="business-content-section">
          {/* App Info Card */}
          <Card variant="outlined" className="user-card" sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Manage Your Business Anywhere
              </Typography>
              <Typography variant="body1" paragraph>
                The Cexpress Hub mobile app gives you and your employees full
                access to your business operations from anywhere, at any time.
                Track orders, manage deliveries, access customer data, and stay
                connected with your team.
              </Typography>

              <Box
                sx={{
                  border: "1px solid",
                  borderColor: "grey.300",
                  borderRadius: 1,
                  p: 2,
                  my: 2,
                  backgroundColor: "rgba(236, 240, 241, 0.5)",
                }}
              >
                <Typography variant="body1">
                  <strong>Note:</strong> This application is currently in beta
                  testing. We invite you to try it out and provide feedback to
                  help us improve.
                </Typography>
              </Box>

              <Typography variant="h6" gutterBottom className="section-title">
                Key Features
              </Typography>

              <List sx={{ listStyleType: "disc", pl: 4, mb: 3 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Real-time order tracking and updates" />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Instant notifications for new orders" />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Customer management on the go" />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Secure login with biometric authentication" />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Location-based services for delivery optimization" />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Offline capabilities for uninterrupted workflow" />
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Quick access to business analytics and reports" />
                </ListItem>
              </List>

              {/* Store Buttons */}
              <Box className="button-container" sx={{ mt: 4 }}>
                <Button
                  className="user-button-secondary"
                  component="a"
                  href="https://testflight.apple.com/join/KgaRPysB"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ flex: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}
                  startIcon={
                    <Box
                      component="img"
                      src={appStoreIcon}
                      alt="Test on TestFlight"
                      sx={{ height: "30px" }}
                    />
                  }
                >
                  Test on TestFlight
                </Button>

                <Button
                  className="user-button-secondary"
                  component="a"
                  href="https://play.google.com/apps/internaltest/4700162424047681806"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ flex: 1, mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}
                  startIcon={
                    <Box
                      component="img"
                      src={googlePlayIcon}
                      alt="Join Beta on Google Play"
                      sx={{ height: "30px" }}
                    />
                  }
                >
                  Join Beta on Google Play
                </Button>
              </Box>
            </CardContent>
          </Card>
        </div>

        {/* App Screenshot - Uncomment when screenshot is available */}
        {/* <div className="business-content-section mt-30">
          <Card variant="outlined" className="user-card">
            <CardMedia
              component="img"
              image={mobileAppScreenshot}
              alt="Cexpress Hub Mobile App (Beta)"
              sx={{ objectFit: "contain", maxHeight: "500px" }}
            />
          </Card>
        </div> */}

        {/* QR Code Section */}
        <div className="business-content-section mt-30">
          <Card variant="outlined" className="user-card">
            <CardContent>
              <Typography variant="h6" gutterBottom className="section-title">
                Scan to Join Testing
              </Typography>
              <Grid
                container
                spacing={4}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} sm={6} md={3}>
                  <Box textAlign="center">
                    <QRCodeSVG
                      value="https://testflight.apple.com/join/KgaRPysB"
                      size={150}
                      className="qr-code-image"
                    />
                    <Typography variant="body1" mt={1}>
                      iOS TestFlight
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box textAlign="center">
                    <QRCodeSVG
                      value="https://play.google.com/apps/internaltest/4700162424047681806"
                      size={150}
                      className="qr-code-image"
                    />
                    <Typography variant="body1" mt={1}>
                      Android Beta Testing
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>

        {/* FAQ Section */}
        <div className="business-content-section mt-30">
          <Typography variant="h5" gutterBottom className="section-title">
            Frequently Asked Questions
          </Typography>

          <div className="faq-container">
            {/* FAQ #1 */}
            <Accordion className="faq-item">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <Typography>Is the mobile app free to download?</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="body1">
                  Yes, the Cexpress Hub mobile app is free to download. You will
                  need your Cexpress account credentials to log in.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* FAQ #2 */}
            <Accordion className="faq-item mt-10">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <Typography>Which devices are supported?</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="body1">
                  The app supports iOS 12.0+ and Android 8.0+ devices. It's
                  optimized for both phones and tablets.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* FAQ #3 */}
            <Accordion className="faq-item mt-10">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <Typography>How do I join the testing program?</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="body1">
                  For iOS, you'll need to accept the TestFlight invitation using
                  the link provided. For Android, join our internal testing
                  program with the link or QR code above. As a tester, you may
                  encounter bugs – please report any issues you find.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* FAQ #4 */}
            <Accordion className="faq-item mt-10">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <Typography>Is my data secure on the mobile app?</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="body1">
                  Yes. We use industry-standard encryption and security
                  practices to protect your data. The app also supports
                  biometric authentication for an added layer of security.
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* FAQ #5 */}
            <Accordion className="faq-item mt-10">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  background: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <Typography>
                  Will my test data persist to the production version?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography variant="body1">
                  Test data may be reset before the final release. We recommend
                  not relying on test data for critical business operations
                  until the full release.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
