import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PanToolIcon from "@mui/icons-material/PanTool";
import SaveIcon from "@mui/icons-material/Save";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import { useParams } from "react-router-dom";
import StartLocationSelector from "../../../../../components/StartLocationSelector";

// Components
import BatchRouteMapManager from "./BatchRouteMapManager";
import BatchRouteSidebar from "./BatchRouteSidebar";

// Example Logic
import {
  consolidateNodes,
  splitConsolidatedNode,
  mergeNodes,
  calculateDistance,
  LOCATION_THRESHOLD_METERS,
} from "./NodeConsolidation";

import { FlowProvider, useFlowContext } from "./FlowContext";

import {
  formatNewEdge,
  findOrderDestinationNodeId,
  findPathBFS,
  getEdgesForPath,
  canOrderArriveAtSource,
  getAvailableSharedOrders,
} from "./edgeUtils";

import {
  validateBatchRoute,
  canCreateBatchConnection,
  reassignOrders,
} from "./BatchRouteLogic";

const EDGE_STYLES = {
  default: {
    stroke: "#999",
    strokeWidth: 2,
    opacity: 0.7,
  },
  assigned: {
    stroke: "#2196f3",
    strokeWidth: 2.5,
    opacity: 1,
  },
  selected: {
    stroke: "#4caf50",
    strokeWidth: 3,
    opacity: 1,
  },
  multiOrder: {
    stroke: "#673AB7",
    strokeWidth: 3,
    opacity: 0.9,
  },
};

/**
 * BatchRouteBuilder:
 * - Fetches shipping orders + their routeSegments, plus location & courier data.
 * - Handles missing start locations by showing selectors before proceeding.
 * - Converts to React Flow `nodes` + `edges`.
 * - Renders a dialog with the map manager + sidebar.
 * - On edge create, handle consolidated node logic.
 * - On courier assign, handle consolidated node logic.
 * - On **order** assign, ensure the order truly belongs to that location (or consolidated children).
 */
const BatchRouteBuilder = ({
  open,
  onClose,
  selectedOrders = [],
  onSubmit,
  setOrdersWithoutStart,
  setCurrentOrderBeingFixed,
  setShowStartLocationSelector,
}) => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();

  // Loading / error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Edit mode
  const [editMode, setEditMode] = useState("edge");

  // For specialized node categories
  const [startNodeIds, setStartNodeIds] = useState([]);
  const [deliveryNodeIds, setDeliveryNodeIds] = useState([]);

  // Validation
  const [validationState, setValidationState] = useState({
    isValid: false,
    orderValidations: {},
    globalErrors: [],
  });

  // All data from context
  const {
    flowData,
    updateFlowData,
    orders,
    setOrders,
    couriers,
    setCouriers,
    routeSegments,
    setRouteSegments,
    locations,
    setLocations,

    selectedNode,
    setSelectedNode,
    selectedEdge,
    setSelectedEdge,
  } = useFlowContext();

  const { nodes, edges } = flowData;

  // ----------------------------------------------------
  //   Data Fetch + Transform
  // ----------------------------------------------------
  const fetchBatchData = useCallback(async () => {
    if (!open || !selectedOrders?.length) return;

    setLoading(true);
    setError(null);

    try {
      // 1) Fetch shipping orders first
      const ordersResponses = await Promise.all(
        selectedOrders.map((orderId) => api.get(`/shipping-orders/${orderId}`))
      );
      const fetchedOrders = ordersResponses.map((r) => r.data);

      // Check if any orders are missing start locations
      const ordersWithoutStartLocation = fetchedOrders.filter(
        (order) => !order.start_location_id
      );

      if (ordersWithoutStartLocation.length > 0) {
        // Use the parent component's state setters to handle the missing start locations
        setOrdersWithoutStart(ordersWithoutStartLocation);
        setCurrentOrderBeingFixed(ordersWithoutStartLocation[0]);
        setShowStartLocationSelector(true);
        setLoading(false);
        return;
      }

      // 2) Extract all start location IDs
      const startLocationIds = [
        ...new Set(fetchedOrders.map((order) => order.start_location_id)),
      ].filter(Boolean);

      // 3) Fetch business locations and couriers
      const [locationsResponse, couriersResponse] = await Promise.all([
        api.get(`/business/${businessId}/addresses`),
        api.get(`/employees/couriers/${businessId}`),
      ]);

      // Filter business addresses to get shipping locations
      const businessLocations = locationsResponse.data.filter(
        (addr) =>
          addr.is_shipping_location ||
          ["warehouse", "dropoff_point", "pickup_point", "store"].includes(
            addr.business_address_type.toLowerCase()
          )
      );
      const businessLocationIds = businessLocations.map(
        (loc) => loc.business_address_id
      );
      const externalStartLocations = startLocationIds.filter(
        (id) => !businessLocationIds.includes(id)
      );

      // 5) Fetch external start locations if needed
      let additionalLocations = [];
      if (externalStartLocations.length > 0) {
        const externalLocationResponses = await Promise.all(
          externalStartLocations.map((locationId) => {
            // Get the business ID from the order with this start location
            const order = fetchedOrders.find(
              (o) => o.start_location_id === locationId
            );
            const externalBusinessId =
              order?.business_id || order?.collaboration_business_id;

            return api.get(`/business//addresses/${locationId}`);
          })
        );

        // Add external locations directly
        additionalLocations = externalLocationResponses.map(
          (response) => response.data
        );
      }

      // 6) Combine all locations
      const fetchedLocations = [...businessLocations, ...additionalLocations];

      // Set state
      setOrders(fetchedOrders);
      setLocations(fetchedLocations);
      setCouriers(couriersResponse.data);

      // 7) Flatten route segments
      const combinedSegments = [];
      fetchedOrders.forEach((order) => {
        const orderId = order.shipping_order_id;
        if (Array.isArray(order.RouteSegments)) {
          order.RouteSegments.forEach((seg) => {
            combinedSegments.push({
              orderId,
              from: seg.start_location_id,
              to: seg.end_location_id,
              courierId: seg.courier_id || null,
              segmentOrder: seg.segment_order,
              status: seg.status,
              isLastSegment: seg.is_last_segment,
              estimatedDuration: seg.estimated_duration,
            });
          });
        }
      });
      setRouteSegments(combinedSegments);

      // 8) Identify "start" node IDs
      const startIds = startLocationIds;
      setStartNodeIds(startIds);

      // 9) Build initial nodes + edges with existing function
      const { rfNodes, rfEdges, deliveryIds } = buildNodesAndEdges({
        orders: fetchedOrders,
        segments: combinedSegments,
        locations: fetchedLocations,
        couriers: couriersResponse.data,
        startIds: startIds,
      });

      const formattedNodes = getFormattedNodes(rfNodes);
      const formattedEdges = rfEdges.map((edge) =>
        formatNewEdge(edge, selectedEdge?.id, couriersResponse.data)
      );

      updateFlowData(formattedNodes, formattedEdges);
      setDeliveryNodeIds(deliveryIds);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching batch data:", err);
      setError("Failed to load route data. Please try again.");
      setLoading(false);
    }
  }, [
    open,
    selectedOrders,
    api,
    businessId,
    setOrders,
    setCouriers,
    setRouteSegments,
    updateFlowData,
  ]);

  /**
   * Helper: buildNodesAndEdges
   */
  function buildNodesAndEdges({
    orders,
    segments,
    locations,
    couriers,
    startIds,
  }) {
    const locMap = {};
    locations.forEach((loc) => {
      locMap[loc.business_address_id] = loc;
    });

    const rfNodes = [];
    const deliveryIds = [];
    const deliveryLocationMap = new Map();

    // 1) Create nodes for known locations
    locations.forEach((loc) => {
      const isStart = startIds.includes(loc.business_address_id);
      rfNodes.push({
        id: loc.business_address_id,
        type: "locationNode",
        position: { x: 0, y: 0 },
        data: {
          isConsolidated: false,
          type: isStart
            ? "start"
            : (
                loc.shipping_location_type ||
                loc.business_address_type ||
                "unknown"
              ).toLowerCase(),
          label: loc.business_address_name || "(Unnamed)",
          isStart,
          details: {
            ...loc,
          },
        },
      });
    });

    // 2) Group orders by delivery location
    orders.forEach((order) => {
      const recipientId = order.recipient_address_id;
      if (!recipientId || locMap[recipientId]) return;

      const recipientAddresses = order.recipient.Addresses;
      const recipientAddress = recipientAddresses.find(
        (address) => address.address_id === recipientId
      );

      // Create a unique key for the delivery location
      const key = `${recipientAddress.address_line_1}-${recipientAddress.postal_code}`;

      if (!deliveryLocationMap.has(key)) {
        deliveryLocationMap.set(key, {
          id: recipientId,
          orders: [],
          address: recipientAddress,
          recipient: order.recipient,
          startLocationMap: new Map(), // Track orders by start location
        });
      }

      const location = deliveryLocationMap.get(key);
      location.orders.push(order);

      // Group by start location
      if (!location.startLocationMap.has(order.start_location_id)) {
        location.startLocationMap.set(order.start_location_id, []);
      }
      location.startLocationMap.get(order.start_location_id).push(order);
    });

    // 3) Create delivery nodes
    deliveryLocationMap.forEach((location) => {
      const {
        id,
        orders: locationOrders,
        address,
        recipient,
        startLocationMap,
      } = location;

      // Check if this is a shared delivery point (orders from different start points)
      const isSharedDelivery = startLocationMap.size > 1;

      if (isSharedDelivery) {
        // Create a delivery node with shared delivery info
        rfNodes.push({
          id: id,
          type: "locationNode",
          position: { x: 0, y: 0 },
          data: {
            type: "delivery",
            isSharedDelivery: true,
            label: `Delivery for ${recipient.first_name} ${recipient.last_name}`,
            details: {
              ...address,
              orders: locationOrders,
              recipient,
              startLocationGroups: Array.from(startLocationMap).map(
                ([startId, orders]) => ({
                  startLocationId: startId,
                  orders,
                })
              ),
            },
          },
        });
      } else if (locationOrders.length > 1) {
        // Create consolidated node (multiple orders from same start)
        rfNodes.push({
          id: id,
          type: "locationNode",
          position: { x: 0, y: 0 },
          data: {
            isConsolidated: true,
            type: "delivery",
            label: `Consolidated Delivery (${locationOrders.length} orders)`,
            details: {
              ...address,
              orders: locationOrders,
              recipient,
              childNodes: locationOrders.map((order) => ({
                id: `${id}-${order.shipping_order_id}`,
                details: {
                  ...address,
                  order,
                },
              })),
            },
          },
        });
      } else {
        // Single order delivery node
        rfNodes.push({
          id: id,
          type: "locationNode",
          position: { x: 0, y: 0 },
          data: {
            isConsolidated: false,
            type: "delivery",
            label: `Delivery for ${recipient.first_name} ${recipient.last_name}`,
            details: {
              ...address,
              order: locationOrders[0],
              recipient,
            },
          },
        });
      }
      deliveryIds.push(id);
    });

    // 4) Build initial edges (unchanged)
    const initialEdges = segments.map((seg) => {
      const assignedCourier = couriers.find(
        (c) => c.employee_id === seg.courierId
      );
      const orderId =
        typeof seg.orderId === "string"
          ? seg.orderId
          : seg.orderId.shipping_order_id;

      const targetNode = rfNodes.find((n) => n.id === seg.to);
      const isDeliveryEdge = targetNode?.data?.type === "delivery";

      return {
        id: `${seg.orderId}-${seg.from}-${seg.to}-${seg.segmentOrder || ""}`,
        source: seg.from,
        target: seg.to,
        type: "simplebezier",
        data: {
          orders: [orderId],
          courier: assignedCourier || null,
          status: seg.status,
          isLastSegment: seg.isLastSegment,
          estimatedDuration: seg.estimatedDuration,
          isConsolidated: false,
          isDeliveryEdge,
        },
      };
    });

    // 5) Collapse edges (unchanged)
    const rfEdges = initialEdges.reduce((collapsed, edge) => {
      const key = `${edge.source}-${edge.target}-${
        edge.data?.courier?.employee_id || "unassigned"
      }`;

      if (!collapsed[key]) {
        collapsed[key] = {
          ...edge,
          data: {
            ...edge.data,
            orders: edge.data.orders.map((order) =>
              typeof order === "string" ? order : order.shipping_order_id
            ),
          },
        };
      } else {
        const newOrders = edge.data.orders.map((order) =>
          typeof order === "string" ? order : order.shipping_order_id
        );
        collapsed[key].data.orders = [
          ...new Set([...collapsed[key].data.orders, ...newOrders]),
        ];
        collapsed[key].data.isConsolidated =
          collapsed[key].data.orders.length > 1;
      }

      return collapsed;
    }, {});

    return {
      rfNodes,
      rfEdges: Object.values(rfEdges),
      deliveryIds,
    };
  }

  // Clear when dialog closes
  useEffect(() => {
    if (!open) {
      // reset everything
      setOrders([]);
      setLocations([]);
      setCouriers([]);
      setRouteSegments([]);
      updateFlowData([], []);
      setStartNodeIds([]);
      setDeliveryNodeIds([]);
      setSelectedNode(null);
      setSelectedEdge(null);
      setValidationState({
        isValid: false,
        orderValidations: {},
        globalErrors: [],
      });
      setError(null);
    }
  }, [open]);

  // Fetch if needed
  useEffect(() => {
    if (open && selectedOrders.length > 0) {
      fetchBatchData();
    }
  }, [open, selectedOrders, fetchBatchData]);

  // ----------------------------------------------------
  //   Edge / Node Creation & Modification
  // ----------------------------------------------------

  /**
   * onEdgeCreate: If target is consolidated, replicate edges to each child;
   * otherwise create one edge. We embed the correct orderId based on the target node’s data.
   */
  const collapseEdges = useCallback((edgeList) => {
    const edgeGroups = {};

    edgeList.forEach((edge) => {
      const courierId = edge.data?.courier?.employee_id || "unassigned";
      const key = `${edge.source}-${edge.target}-${courierId}`;

      // Standardize orders to string IDs
      const standardizedOrders = (edge.data.orders || []).map((order) =>
        typeof order === "string" ? order : order.shipping_order_id
      );

      if (!edgeGroups[key]) {
        edgeGroups[key] = {
          ...edge,
          data: {
            ...edge.data,
            orders: standardizedOrders,
          },
        };
      } else {
        // Merge orders from this edge into the existing group
        edgeGroups[key].data.orders = [
          ...new Set([...edgeGroups[key].data.orders, ...standardizedOrders]),
        ];
      }

      // Set isConsolidated if there are multiple orders
      const currentOrders = edgeGroups[key].data.orders;
      edgeGroups[key].data.isConsolidated = currentOrders.length > 1;
    });

    return Object.values(edgeGroups);
  }, []);

  const handleEdgeCreate = useCallback(
    (sourceId, targetId) => {
      console.log("handleEdgeCreate:", sourceId, "-->", targetId);

      // 1) Find the target node
      const targetNode = nodes.find((n) => n.id === targetId);
      if (!targetNode) return [];

      let newEdges = [];
      const edgeId = `temp-${sourceId}-${targetId}-${Date.now()}`;

      // 2) If connecting to a delivery node:
      if (targetNode.data?.type === "delivery") {
        // A. SHARED DELIVERY LOGIC (top-level shared)
        if (targetNode.data.isSharedDelivery) {
          const orderIds = getAvailableSharedOrders({
            sourceId,
            targetNode,
            edges,
          });

          newEdges.push({
            id: edgeId,
            source: sourceId,
            target: targetId,
            type: "simplebezier",
            data: {
              orders: orderIds,
              courier: null,
              status: "pending",
              isDeliveryEdge: true,
              isSharedDelivery: true,
              startLocationId: sourceId,
            },
          });
        }

        // B. CONSOLIDATED DELIVERY LOGIC
        else if (targetNode.data.isConsolidated) {
          // We collect orders for *each child node* (some might be shared, some single)
          const ordersFromAllChildren = new Set();

          // 1) Go through each child node
          const childNodes = targetNode.data.details.childNodes || [];
          childNodes.forEach((child) => {
            // child.details might store whether that child is shared or single
            if (child.details?.isSharedDelivery) {
              // Use the same logic as top-level shared
              const childSharedOrders = getAvailableSharedOrders({
                sourceId,
                // we treat the `child` as if it's a "shared node"
                targetNode: {
                  data: {
                    details: child.details,
                    isSharedDelivery: true,
                  },
                },
                edges,
              });
              childSharedOrders.forEach((oid) =>
                ordersFromAllChildren.add(oid)
              );
            } else {
              // Single‐delivery logic for the child
              const singleOrderId = child?.details?.order?.shipping_order_id;
              if (!singleOrderId) return;

              // Check if that single order can come from sourceId:
              //  => (a) is it already on an incoming edge to sourceId?
              //  => (b) or does it start at sourceId?
              const canArrive = canOrderArriveAtSource({
                orderId: singleOrderId,
                sourceId,
                edges,
                startLocationId: child.details.order.start_location_id,
              });
              if (canArrive) {
                ordersFromAllChildren.add(singleOrderId);
              }
            }
          });

          // 2) Remove any that are already used on other outgoing edges from the same source
          const outgoingEdges = edges.filter((e) => e.source === sourceId);
          outgoingEdges.forEach((outEdge) => {
            (outEdge.data.orders || []).forEach((oid) => {
              if (ordersFromAllChildren.has(oid)) {
                ordersFromAllChildren.delete(oid);
              }
            });
          });

          // 3) Create a single new edge with that final subset
          const finalOrderIds = [...ordersFromAllChildren];

          newEdges.push({
            id: edgeId,
            source: sourceId,
            target: targetId,
            type: "simplebezier",
            data: {
              orders: finalOrderIds,
              courier: null,
              status: "pending",
              isDeliveryEdge: true,
              isConsolidated: true,
            },
          });
        }

        // C. SINGLE-DELIVERY LOGIC
        else {
          const singleOrderId =
            targetNode.data.details.order?.shipping_order_id;
          const orderIds = singleOrderId ? [singleOrderId] : [];

          newEdges.push({
            id: edgeId,
            source: sourceId,
            target: targetId,
            type: "simplebezier",
            data: {
              orders: orderIds,
              courier: null,
              status: "pending",
              isDeliveryEdge: true,
            },
          });
        }
      }

      // 3) If it's NOT a delivery node, create a simple edge with no assigned orders
      else {
        newEdges.push({
          id: edgeId,
          source: sourceId,
          target: targetId,
          type: "bezier",
          data: {
            orders: [],
            courier: null,
            status: "pending",
            isDeliveryEdge: false,
          },
        });
      }

      console.log("New edges created:", newEdges);

      // 4) Merge with existing edges and collapse
      const allEdges = [...edges, ...newEdges];
      const collapsedEdges = collapseEdges(allEdges);

      // 5) Format edges
      const finalEdges = collapsedEdges.map((edge) =>
        formatNewEdge(edge, selectedEdge?.id, couriers)
      );

      // 6) Update context
      updateFlowData(nodes, finalEdges);
      return finalEdges;
    },
    [nodes, edges, selectedEdge, couriers, updateFlowData]
  );

  const getFormattedNodes = useCallback((nodeList) => {
    if (!nodeList?.length) return [];

    const COLUMN_WIDTH = 300;
    const VERTICAL_SPACING = 200;

    // Sort nodes by type to ensure consistent ordering
    const startNodes = nodeList.filter((n) => n.data?.type === "start");
    const warehouseNodes = nodeList.filter((n) => n.data?.type === "warehouse");
    const deliveryNodes = nodeList.filter(
      (n) => n.data?.type === "delivery" || n.data?.isConsolidated
    );

    // Apply layout to each group
    return [
      ...startNodes.map((node, index) => ({
        ...node,
        position: { x: 0, y: index * VERTICAL_SPACING },
      })),
      ...warehouseNodes.map((node, index) => ({
        ...node,
        position: { x: COLUMN_WIDTH, y: index * VERTICAL_SPACING },
      })),
      ...deliveryNodes.map((node, index) => ({
        ...node,
        position: { x: COLUMN_WIDTH * 3, y: index * VERTICAL_SPACING },
      })),
    ];
  }, []);

  const handleCourierAssign = useCallback(
    (edgeId, newCourierId) => {
      console.log("handleCourierAssign:", edgeId, newCourierId);

      // Create a *fresh* copy of edges
      const updatedEdges = edges.map((edge) => {
        if (edge.id === edgeId) {
          // Find the actual courier object
          const newCourier =
            couriers.find((c) => c.employee_id === newCourierId) || null;
          return {
            ...edge,
            data: {
              ...edge.data,
              courier: newCourier,
            },
          };
        }
        return edge;
      });

      // (Optional) If you do “collapseEdges” or “formatNewEdge,” do it here:
      const collapsed = collapseEdges(updatedEdges);
      const finalEdges = collapsed.map((e) =>
        formatNewEdge(e, selectedEdge?.id, couriers)
      );

      // Update context => triggers re-render
      updateFlowData(nodes, finalEdges);
    },
    [edges, nodes, couriers, selectedEdge, collapseEdges, updateFlowData]
  );

  const updateRouteSegments = useCallback((updatedEdges) => {
    // Convert edges back to route segments format
    const newSegments = updatedEdges.flatMap((edge) => {
      return edge.data.orders.map((orderId) => ({
        orderId,
        from: edge.source,
        to: edge.target,
        courierId: edge.data.courier?.employee_id || null,
        status: edge.data.status,
        isLastSegment: edge.data.isLastSegment,
        estimatedDuration: edge.data.estimatedDuration,
      }));
    });

    setRouteSegments(newSegments);
  }, []);

  /**
   * onOrderAssign: Assign a certain order to an edge
   *  - If target is consolidated, we replicate that assignment for each child edge
   *    that actually belongs to that “delivery location.”
   *  - If the edge is a direct “delivery node,” we just update that one edge.
   */
  const handleOrderAssign = useCallback(
    (edgeId, newOrderIds, replaceExisting = false) => {
      console.log("handleOrderAssign:", edgeId, newOrderIds);

      // 1) Copy current edges so we can modify
      const updatedEdges = edges.map((edge) => ({ ...edge }));

      // 2) Find the “edge” object in updatedEdges
      const edgeIndex = updatedEdges.findIndex((e) => e.id === edgeId);
      console.log("Edge index:", edgeIndex);
      if (edgeIndex < 0) return; // no such edge

      // 3) Add/merge newOrderIds into that edge’s .data.orders
      const edgeToAssign = updatedEdges[edgeIndex];
      const existingOrders = Array.isArray(edgeToAssign.data.orders)
        ? [...edgeToAssign.data.orders]
        : [];
      const merged = replaceExisting
        ? newOrderIds
        : [...new Set([...existingOrders, ...newOrderIds])];

      edgeToAssign.data = {
        ...edgeToAssign.data,
        orders: merged,
      };

      // 4) For each newly assigned order, BFS from this edge’s .target to the final node
      newOrderIds.forEach((orderId) => {
        // (a) find the actual order object
        const orderObj = orders.find((o) => o.shipping_order_id === orderId);
        console.log("Order object:", orderObj);
        if (!orderObj) return;

        // (b) find final node for this order
        const destNodeId = findOrderDestinationNodeId(orderObj, nodes);
        console.log("Final node for order", orderId, "is", destNodeId);
        if (!destNodeId) return;

        console.log("Edge to assign:", edgeToAssign);

        // If the edge’s target == the final node, there's no path to fill downstream
        if (edgeToAssign.target === destNodeId) return;

        // (c) BFS from edgeToAssign.target => destNodeId
        const pathNodeIds = findPathBFS(
          edgeToAssign.target,
          destNodeId,
          updatedEdges
        );
        console.log("Path Node ids", orderId, ":", pathNodeIds);
        if (!pathNodeIds || pathNodeIds.length < 2) {
          console.log("No path found to final node for order", orderId);
          return;
        }

        // (d) Convert that node path => edges
        const pathEdges = getEdgesForPath(pathNodeIds, updatedEdges);

        // (e) On each edge in that path, add the order if not present
        pathEdges.forEach((pe) => {
          const existing = new Set(pe.data.orders || []);
          existing.add(orderId);
          pe.data.orders = [...existing];
        });
      });

      // 5) Optionally collapse + format edges
      const collapsed = collapseEdges(updatedEdges);
      const finalEdges = collapsed.map((e) =>
        formatNewEdge(e, selectedEdge?.id, couriers)
      );

      updateFlowData(nodes, finalEdges);
    },
    [
      edges,
      nodes,
      orders,
      couriers,
      selectedEdge,
      collapseEdges,
      updateFlowData,
    ]
  );

  const handleOrderRemove = useCallback(
    (edgeId, orderIdToRemove) => {
      console.log("handleOrderRemove:", edgeId, orderIdToRemove);

      const standardizedOrderId =
        typeof orderIdToRemove === "string"
          ? orderIdToRemove
          : orderIdToRemove.shipping_order_id;

      // 1) Copy edges
      const updatedEdges = edges.map((edge) => ({ ...edge }));

      // 2) Find the edge
      const edgeIndex = updatedEdges.findIndex((e) => e.id === edgeId);
      if (edgeIndex < 0) return;

      const edgeToModify = updatedEdges[edgeIndex];

      // 3) Remove the order from this edge and remove edge if empty
      edgeToModify.data.orders = (edgeToModify.data.orders || []).filter(
        (o) => o !== standardizedOrderId
      );

      // If edge has no orders, remove it
      if (edgeToModify.data.orders.length === 0) {
        updatedEdges.splice(edgeIndex, 1);
      }

      // 4) BFS to remove from downstream as well
      // (a) find the actual order object
      const orderObj = orders.find(
        (o) => o.shipping_order_id === standardizedOrderId
      );
      if (!orderObj) {
        const collapsed = collapseEdges(updatedEdges);
        const finalEdges = collapsed.map((e) =>
          formatNewEdge(e, selectedEdge?.id, couriers)
        );
        updateFlowData(nodes, finalEdges);
        return;
      }

      // (b) final node
      const destNodeId = findOrderDestinationNodeId(orderObj, nodes);
      console.log(
        "Final node for order",
        standardizedOrderId,
        "is",
        destNodeId
      );
      if (!destNodeId) {
        const collapsed = collapseEdges(updatedEdges);
        const finalEdges = collapsed.map((e) =>
          formatNewEdge(e, selectedEdge?.id, couriers)
        );
        updateFlowData(nodes, finalEdges);
        return;
      }

      // if target==dest, no BFS
      if (edgeToModify.target !== destNodeId) {
        console.log("Removing order", standardizedOrderId, "from downstream");
        console.log("Target node:", destNodeId);
        console.log("Target: ", edgeToModify.target);
        console.log("Updated edges:", updatedEdges);
        const pathNodeIds = findPathBFS(
          edgeToModify.target,
          destNodeId,
          updatedEdges
        );
        if (pathNodeIds && pathNodeIds.length > 1) {
          const pathEdges = getEdgesForPath(pathNodeIds, updatedEdges);

          // Remove order from each path edge and remove edges with no orders
          pathEdges.forEach((pe) => {
            pe.data.orders = (pe.data.orders || []).filter(
              (id) => id !== standardizedOrderId
            );
            // If edge now has no orders, remove it
            if (pe.data.orders.length === 0) {
              const emptyEdgeIndex = updatedEdges.findIndex(
                (e) => e.id === pe.id
              );
              if (emptyEdgeIndex !== -1) {
                updatedEdges.splice(emptyEdgeIndex, 1);
              }
            }
          });
        }
      }

      // finalize
      const collapsed = collapseEdges(updatedEdges);
      const finalEdges = collapsed.map((e) =>
        formatNewEdge(e, selectedEdge?.id, couriers)
      );
      updateFlowData(nodes, finalEdges);
    },
    [
      edges,
      nodes,
      orders,
      couriers,
      selectedEdge,
      collapseEdges,
      updateFlowData,
    ]
  );

  /**
   * Called by map manager when user merges nodes
   */
  const handleConsolidateNodes = useCallback(
    (threshold) => {
      // Step 1: Split existing consolidated nodes
      const { allNodes, allEdges } = nodes.reduce(
        (acc, node) => {
          if (node.data?.isConsolidated) {
            const { childNodes, updatedEdges } = splitConsolidatedNode(
              node,
              edges
            );
            return {
              allNodes: [...acc.allNodes, ...childNodes],
              allEdges: [...acc.allEdges, ...updatedEdges],
            };
          }
          return {
            allNodes: [...acc.allNodes, node],
            allEdges: acc.allEdges,
          };
        },
        { allNodes: [], allEdges: edges }
      );

      // Step 2: Consolidate nodes with threshold
      const {
        consolidatedNodes,
        individualNodes,
        nonDeliveryNodes,
        updatedEdges,
      } = consolidateNodes(allNodes, allEdges, threshold);

      // Step 3: Create final node array and apply layout
      const newNodes = [
        ...nonDeliveryNodes,
        ...consolidatedNodes,
        ...individualNodes,
      ];

      // format the edges
      const formattedEdges = updatedEdges.map((edge) =>
        formatNewEdge(edge, selectedEdge?.id, couriers)
      );

      // format the nodes
      const formattedNodes = getFormattedNodes(newNodes);

      // Update state
      updateFlowData(formattedNodes, formattedEdges);
      // Update routeSegments if needed based on edge changes
      // updateRouteSegments(updatedEdges);
    },
    [nodes, edges, orders, routeSegments]
  );

  const handleEdgeDelete = useCallback(
    (edgeId) => {
      console.log("Deleting edge:", edgeId);

      // 1) Copy edges
      const updatedEdges = edges.map((edge) => ({ ...edge }));

      // 2) Find the edge to delete
      const edgeIndex = updatedEdges.findIndex((e) => e.id === edgeId);
      if (edgeIndex < 0) return;

      const edgeToDelete = updatedEdges[edgeIndex];
      console.log("Edge to delete:", edgeToDelete);
      const edgeOrders = edgeToDelete.data?.orders || [];
      const target = edgeToDelete.target;

      // Create a working copy of edges that won't be modified during iterations
      let workingEdges = [...updatedEdges];

      // Track edges to remove after processing all orders
      const edgesToRemove = new Set([edgeId]);

      // 3) For each order on this edge, identify downstream edges to clear
      console.log("Processing orders:", edgeOrders);
      edgeOrders.forEach((orderId) => {
        console.log("Processing order", orderId, "from edge", edgeId);

        const standardizedOrderId =
          typeof orderId === "string" ? orderId : orderId.shipping_order_id;

        const orderObj = orders.find(
          (o) => o.shipping_order_id === standardizedOrderId
        );
        if (!orderObj) return;

        const destNodeId = findOrderDestinationNodeId(orderObj, nodes);
        console.log("Final node for order", orderId, "is", destNodeId);
        if (!destNodeId || target === destNodeId) return;

        console.log("Working edges:", workingEdges);
        console.log("Removing order", orderId, "from downstream edges");
        console.log("Target node:", destNodeId);
        console.log("Target: ", target);

        const pathNodeIds = findPathBFS(target, destNodeId, workingEdges);
        console.log("Path Node ids", orderId, ":", pathNodeIds);
        if (pathNodeIds && pathNodeIds.length > 1) {
          const pathEdges = getEdgesForPath(pathNodeIds, workingEdges);

          pathEdges.forEach((pe) => {
            const edgeToUpdate = workingEdges.find((e) => e.id === pe.id);
            if (edgeToUpdate) {
              edgeToUpdate.data.orders = (
                edgeToUpdate.data.orders || []
              ).filter((id) => {
                const compareId =
                  typeof id === "string" ? id : id.shipping_order_id;
                return compareId !== standardizedOrderId;
              });

              if (edgeToUpdate.data.orders.length === 0) {
                edgesToRemove.add(edgeToUpdate.id);
              }
            }
          });
        }
      });

      // 4) Remove all identified empty edges at once
      const finalEdges = workingEdges.filter((e) => !edgesToRemove.has(e.id));

      // 5) Collapse and format
      const collapsed = collapseEdges(finalEdges);
      const formatted = collapsed.map((e) =>
        formatNewEdge(e, selectedEdge?.id, couriers)
      );

      updateFlowData(nodes, formatted);

      if (selectedEdge?.id === edgeId) {
        setSelectedEdge(null);
      }

      updateRouteSegments(formatted);
    },
    [
      edges,
      nodes,
      orders,
      selectedEdge,
      collapseEdges,
      updateFlowData,
      updateRouteSegments,
    ]
  );
  // First, add a helper function to transform edges into route segments
  const transformEdgesToRouteSegments = (edges, nodes, orders) => {
    const orderSegmentsMap = new Map();
    const orderSegmentCounters = new Map();

    // Initialize maps for each order
    orders.forEach((order) => {
      orderSegmentsMap.set(order.shipping_order_id, []);
      orderSegmentCounters.set(order.shipping_order_id, 1);
    });

    // Process edges to create segments
    edges.forEach((edge) => {
      if (!edge.data?.orders?.length) return;

      const sourceNode = nodes.find((n) => n.id === edge.source);
      const targetNode = nodes.find((n) => n.id === edge.target);
      if (!sourceNode || !targetNode) return;

      edge.data.orders.forEach((orderId) => {
        const order = orders.find((o) => o.shipping_order_id === orderId);
        if (!order) return;

        const segmentOrder = orderSegmentCounters.get(orderId);
        orderSegmentCounters.set(orderId, segmentOrder + 1);

        // Handle consolidated target nodes
        let effectiveTargetNode = targetNode;
        let effectiveEndLocationId = edge.target;

        if (targetNode.data?.isConsolidated) {
          // Find the corresponding child node for this order
          const childNode = targetNode.data.details.childNodes.find((child) => {
            // Single-delivery child?
            if (child.details?.order?.shipping_order_id) {
              return child.details.order.shipping_order_id === orderId;
            }
            // Shared-delivery child?
            else if (
              child.details?.isSharedDelivery &&
              Array.isArray(child.details.orders)
            ) {
              return child.details.orders.some(
                (o) => o.shipping_order_id === orderId
              );
            }
            return false;
          });

          if (childNode) {
            effectiveTargetNode = childNode;
            effectiveEndLocationId = childNode.id;
          }
        }

        const segment = {
          segment_id: `temp-${orderId}-${segmentOrder}-${Date.now()}`,
          shipping_order_id: orderId,
          type: edge.data?.type || "courier",
          courier_id: edge.data?.courier?.employee_id,
          vehicle_id: null,
          start_location_type:
            sourceNode.data?.type === "delivery"
              ? "customer_address"
              : "shipping_location",
          start_location_id: edge.source,
          end_location_type:
            effectiveTargetNode.data?.type === "delivery"
              ? "customer_address"
              : "shipping_location",
          end_location_id: effectiveEndLocationId,
          is_last_segment: false,
          estimated_duration: edge.data?.estimatedDuration,
          segment_order: segmentOrder,
          status: "pending",
          actual_start_time: null,
          actual_end_time: null,
          notes: null,
          sourceNode,
          targetNode: effectiveTargetNode,
          order,
        };

        orderSegmentsMap.get(orderId).push(segment);
      });
    });

    // Process segments and mark last segments
    const finalOrderSegments = [];
    orderSegmentsMap.forEach((segments, orderId) => {
      if (segments.length === 0) return;

      const sortedSegments = segments.sort(
        (a, b) => a.segment_order - b.segment_order
      );
      sortedSegments[sortedSegments.length - 1].is_last_segment = true;
      finalOrderSegments.push(sortedSegments);
    });

    return finalOrderSegments;
  };

  useEffect(() => {
    if (!loading && orders.length && nodes.length) {
      console.log("Starting validation with:", {
        edges,
        nodes,
        orders,
      });

      // 1) Convert consolidated nodes back to original child nodes
      const updatedNodes = nodes.flatMap((node) => {
        if (node.data?.isConsolidated) {
          return node.data.details.childNodes.map((childNode) => {
            if (childNode.details?.isSharedDelivery) {
              // Handle shared delivery nodes
              const firstOrder = childNode.details.orders[0] || {};
              const firstName = firstOrder.recipient?.first_name ?? "";
              const lastName = firstOrder.recipient?.last_name ?? "";

              return {
                id: childNode.id,
                type: "locationNode",
                position: childNode.position,
                data: {
                  isConsolidated: false,
                  type: "delivery",
                  isSharedDelivery: true,
                  label:
                    `Shared Delivery for ${firstName} ${lastName}`.trim() ||
                    "Shared Delivery",
                  details: childNode.details,
                },
              };
            } else {
              // Handle regular delivery nodes
              const recipient = childNode.details?.order?.recipient;
              const firstName = recipient?.first_name ?? "";
              const lastName = recipient?.last_name ?? "";

              return {
                id: childNode.id,
                type: "locationNode",
                position: childNode.position,
                data: {
                  isConsolidated: false,
                  type: "delivery",
                  label:
                    `Delivery for ${firstName} ${lastName}`.trim() ||
                    "Delivery (No Recipient)",
                  details: childNode.details,
                },
              };
            }
          });
        }
        return node;
      });

      // 2) Expand edges
      const updatedEdges = edges.flatMap((edge) => {
        const targetNode = nodes.find((n) => n.id === edge.target);
        if (!targetNode) return edge;

        // A) Consolidated node logic
        if (targetNode.data?.isConsolidated) {
          return edge.data.orders
            .map((orderData) => {
              const orderId =
                typeof orderData === "string"
                  ? orderData
                  : orderData.shipping_order_id;

              // Find the specific child node for this order
              const childNode = targetNode.data.details.childNodes.find(
                (child) => {
                  if (child.details?.isSharedDelivery) {
                    // Check if order exists in shared delivery node's orders
                    return child.details.orders.some(
                      (order) => order.shipping_order_id === orderId
                    );
                  } else {
                    // Check regular delivery node
                    return child.details?.order?.shipping_order_id === orderId;
                  }
                }
              );

              if (!childNode) return null;

              return {
                ...edge,
                id: `${edge.id}-${orderId}`,
                target: childNode.id,
                data: {
                  ...edge.data,
                  orders: [orderId],
                },
              };
            })
            .filter(Boolean);
        }

        // B) Shared delivery node logic
        else if (targetNode.data?.isSharedDelivery) {
          return edge.data.orders.map((orderData) => {
            const orderId =
              typeof orderData === "string"
                ? orderData
                : orderData.shipping_order_id;

            return {
              ...edge,
              id: `${edge.id}-${orderId}`,
              data: {
                ...edge.data,
                orders: [orderId],
              },
            };
          });
        }

        // C) Multiple orders on normal edge
        else if (edge.data?.orders?.length > 1) {
          return edge.data.orders.map((orderData) => {
            const orderId =
              typeof orderData === "string"
                ? orderData
                : orderData.shipping_order_id;
            return {
              ...edge,
              id: `${edge.id}-${orderId}`,
              data: {
                ...edge.data,
                orders: [orderId],
              },
            };
          });
        }

        return edge;
      });

      console.log("Expanded nodes:", updatedNodes);
      console.log("Expanded edges:", updatedEdges);

      // Rest of the code remains the same...
      const routeSegments = transformEdgesToRouteSegments(
        updatedEdges,
        updatedNodes,
        orders
      );
      console.log("Transformed route segments:", routeSegments);

      const validation = validateBatchRoute(
        routeSegments,
        updatedNodes,
        orders
      );
      setValidationState(validation);

      if (!validation.isValid) {
        const errorMessages = [
          ...validation.globalErrors,
          ...Object.entries(validation.orderValidations)
            .filter(([_, v]) => !v.isValid)
            .map(([orderId, v]) => `Order ${orderId}: ${v.errors.join(", ")}`),
        ];
        console.error("Validation errors:", errorMessages);
      } else {
        setError(null);
      }
    }
  }, [edges, nodes, orders, loading]);

  // ----------------------------------------------------
  //   Save
  // ----------------------------------------------------

  /**
   * transformEdgesForBatch:
   *  1) Group edges by each orderId that appears in edge.data.orders
   *  2) For each edge that has that order, create a "segment" object
   *     - start_location_id = edge.source
   *     - end_location_id   = edge.target
   *     - courier_id        = edge.data?.courier?.employee_id
   *     - type              = edge.data?.type || "courier"
   *     - status            = edge.data?.status || "pending"
   *  3) Return array of { orderId, segments: [...sorted by segment_order...] }
   */
  function transformEdgesForBatch(orders, edges, nodes) {
    const segmentsMap = new Map();

    // Initialize all known orders
    orders.forEach((o) => {
      segmentsMap.set(o.shipping_order_id, []);
    });

    // Step through each edge
    edges.forEach((edge) => {
      const {
        orders: edgeOrderIds = [],
        courier,
        type,
        status,
        isConsolidated,
      } = edge.data || {};

      // Find the target node to determine how to handle the edge
      const targetNode = nodes.find((n) => n.id === edge.target);

      edgeOrderIds.forEach((orderId) => {
        if (!segmentsMap.has(orderId)) {
          segmentsMap.set(orderId, []);
        }

        let effectiveEndLocationId = edge.target;

        // If this is a consolidated delivery node
        if (targetNode?.data?.isConsolidated) {
          // Find the child node that contains this order
          const childNode = targetNode.data.details.childNodes.find((child) => {
            if (child.details?.order?.shipping_order_id === orderId) {
              return true;
            }
            if (child.details?.isSharedDelivery) {
              return child.details.orders?.some(
                (o) => o.shipping_order_id === orderId
              );
            }
            return false;
          });

          if (childNode) {
            effectiveEndLocationId =
              childNode.details.order?.recipient_address_id ||
              childNode.details.address_id;
          }
        }
        // If this is a shared delivery node
        else if (targetNode?.data?.isSharedDelivery) {
          // Find the order in the node's orders to get its delivery address
          const orderData = targetNode.data.details.orders?.find(
            (o) => o.shipping_order_id === orderId
          );
          if (orderData) {
            effectiveEndLocationId = orderData.recipient_address_id;
          }
        }

        const seg = {
          start_location_id: edge.source,
          end_location_id: effectiveEndLocationId,
          courier_id: courier?.employee_id || null,
          type: type || "courier",
          status: status || "pending",
        };
        segmentsMap.get(orderId).push(seg);
      });
    });

    // Build final output array
    const output = [];
    segmentsMap.forEach((segments, orderId) => {
      output.push({
        orderId,
        segments,
      });
    });

    return output;
  }

  /**
   * Expand consolidated or shared-delivery nodes into child nodes
   * and expand edges to match those child nodes.
   */
  function expandNodesAndEdges(nodes, edges) {
    // 1) Expand nodes
    const expandedNodes = nodes.flatMap((node) => {
      if (node.data?.isConsolidated) {
        // Return each child node as its own "delivery" node
        return node.data.details.childNodes.map((childNode) => {
          if (childNode.details?.isSharedDelivery) {
            // A shared-delivery child
            return {
              id: childNode.id,
              type: "locationNode",
              position: childNode.position || { x: 0, y: 0 },
              data: {
                isConsolidated: false,
                type: "delivery",
                isSharedDelivery: true,
                label: `Shared Delivery`,
                details: childNode.details,
              },
            };
          } else {
            // A single-delivery child
            const recipient = childNode.details?.order?.recipient;
            return {
              id: childNode.id,
              type: "locationNode",
              position: childNode.position || { x: 0, y: 0 },
              data: {
                isConsolidated: false,
                type: "delivery",
                label: recipient
                  ? `Delivery for ${recipient.first_name} ${recipient.last_name}`
                  : "Delivery Node",
                details: childNode.details,
              },
            };
          }
        });
      }

      // Not consolidated => leave node as-is
      return node;
    });

    // 2) Expand edges
    const expandedEdges = edges.flatMap((edge) => {
      // Retrieve existing data
      const { orders = [], courier, status, isConsolidated } = edge.data || {};

      // If target node is a consolidated or shared-delivery node,
      // replicate the edge for each order to the correct child ID
      const targetNode = nodes.find((n) => n.id === edge.target);
      if (!targetNode) {
        // If we can’t find a node for the target, just return the edge as-is
        return edge;
      }

      // Handle consolidated or shared delivery expansions
      if (targetNode.data?.isConsolidated) {
        // For each order, find the matching child node that truly represents it
        return orders
          .map((orderId) => {
            const childNode = targetNode.data.details.childNodes.find(
              (child) => {
                // Shared-delivery child?
                if (child.details?.isSharedDelivery && child.details.orders) {
                  return child.details.orders.some(
                    (o) => o.shipping_order_id === orderId
                  );
                }
                // Single-delivery child?
                return child.details?.order?.shipping_order_id === orderId;
              }
            );

            if (!childNode) {
              // No matching child => omit or keep the original target.
              // Usually we omit it because it’s invalid for that order.
              return null;
            }

            return {
              ...edge,
              id: `${edge.id}-${orderId}`,
              target: childNode.id,
              data: {
                ...edge.data,
                orders: [orderId],
              },
            };
          })
          .filter(Boolean);
      } else if (targetNode.data?.isSharedDelivery) {
        // If target is top-level shared-delivery, create one edge per order
        return orders.map((orderId) => ({
          ...edge,
          id: `${edge.id}-${orderId}`,
          data: {
            ...edge.data,
            orders: [orderId],
          },
        }));
      } else if (orders.length > 1) {
        // If multiple orders on a plain edge, expand them into separate edges
        return orders.map((orderId) => ({
          ...edge,
          id: `${edge.id}-${orderId}`,
          data: {
            ...edge.data,
            orders: [orderId],
          },
        }));
      }

      // Otherwise, normal single-edge
      return edge;
    });

    return { expandedNodes, expandedEdges };
  }

  const handleSubmit = async () => {
    try {
      // -----------------------------------
      // 1) Expand consolidated nodes/edges
      // -----------------------------------
      const { expandedNodes, expandedEdges } = expandNodesAndEdges(
        nodes,
        edges
      );

      // -----------------------------------
      // 2) Convert to route segments
      // -----------------------------------
      const routeSegments = transformEdgesToRouteSegments(
        expandedEdges,
        expandedNodes,
        orders
      );

      // -----------------------------------
      // 3) Validate the expanded route
      // -----------------------------------
      const validation = validateBatchRoute(
        routeSegments,
        expandedNodes,
        orders
      );
      if (!validation.isValid) {
        console.error(
          "Validation errors. Please fix them and try again.",
          validation
        );
        return;
      }

      // If you want your final back-end payload to also reflect
      // the “expanded” version, do a similar process:
      const ordersPayload = transformEdgesForBatch(orders, edges, nodes);

      console.log("Payload to backend:", ordersPayload);

      // -----------------------------------
      // 4) Call your API
      // -----------------------------------
      await api.put("/route-segments/batch-segments", {
        orders: ordersPayload,
      });

      // -----------------------------------
      // 5) Done
      // -----------------------------------
      onSubmit?.();
      onClose?.();
    } catch (err) {
      console.error("Error saving batch routes:", err);
      setError(
        err?.response?.data?.msg ||
          err.message ||
          "Failed to save routes. Please try again."
      );
    }
  };

  const getNodeLabel = useCallback(
    (nodeId) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (!node) return nodeId;

      if (node.data?.type === "delivery") {
        if (node.data.isConsolidated) {
          return `Consolidated Delivery (${node.data.details.orders.length} orders)`;
        }
        const recipient = node.data.details?.order?.recipient;
        return recipient
          ? `Delivery for ${recipient.first_name} ${recipient.last_name}`
          : "Delivery Location";
      }

      return node.data?.label || node.data?.details?.name || nodeId;
    },
    [nodes]
  );

  // ----------------------------------------------------
  //   Render
  // ----------------------------------------------------
  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { height: "90vh" } }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Batch Route Builder</Typography>
          <ToggleButtonGroup
            value={editMode}
            exclusive
            onChange={(e, mode) => mode && setEditMode(mode)}
            size="small"
          >
            <ToggleButton value="edge" aria-label="edge creation">
              <EditIcon />
            </ToggleButton>
            <ToggleButton value="move" aria-label="move nodes">
              <PanToolIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error" sx={{ m: 2 }}>
            {error}
          </Alert>
        ) : (
          <Box display="flex" height="calc(100% - 80px)">
            {/* Left: the map */}
            <Box flex={1} height="100%" sx={{ contain: "strict" }}>
              <BatchRouteMapManager
                editMode={editMode}
                onEdgeCreate={handleEdgeCreate}
                onConsolidate={handleConsolidateNodes}
                validateConnection={canCreateBatchConnection}
              />
            </Box>

            {/* Right: the sidebar */}
            <Box width={350} borderLeft="1px solid #ccc">
              <BatchRouteSidebar
                onCourierAssign={handleCourierAssign}
                onOrderAssign={handleOrderAssign}
                onOrderRemove={handleOrderRemove}
                onEdgeDelete={handleEdgeDelete}
                getNodeLabel={getNodeLabel}
              />
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
          variant="contained"
          disabled={loading || !!error || !validationState.isValid}
        >
          Save Routes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Handler for start location selection
const BatchRouteBuilderWithLocationCheck = (props) => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const [ordersWithoutStart, setOrdersWithoutStart] = useState([]);
  const [showStartLocationSelector, setShowStartLocationSelector] =
    useState(false);
  const [currentOrderBeingFixed, setCurrentOrderBeingFixed] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Handle selection of start location for an order
  const handleStartLocationSelect = async (locationId) => {
    if (!currentOrderBeingFixed) return;

    try {
      // Update the order's start location on the server
      await api.put(
        `/shipping-orders/${currentOrderBeingFixed.shipping_order_id}/start-location`,
        { start_location_id: locationId }
      );

      // Remove this order from the list that needs fixing
      const remainingOrders = ordersWithoutStart.filter(
        (order) =>
          order.shipping_order_id !== currentOrderBeingFixed.shipping_order_id
      );

      setOrdersWithoutStart(remainingOrders);

      // If there are more orders that need start locations, show dialog for the next one
      if (remainingOrders.length > 0) {
        setCurrentOrderBeingFixed(remainingOrders[0]);
      } else {
        // All orders have start locations now, close the dialog and trigger refresh
        setShowStartLocationSelector(false);
        setCurrentOrderBeingFixed(null);
        setRefreshTrigger((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Error setting start location:", error);
    }
  };

  // Handle cancel - close all dialogs and return to previous screen
  const handleCancel = () => {
    // Clear all state related to start location selection
    setShowStartLocationSelector(false);
    setCurrentOrderBeingFixed(null);
    setOrdersWithoutStart([]);

    // Close the main BatchRouteBuilder dialog as well
    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  // Pass the ordersWithoutStart setter to the BatchRouteBuilder
  // so it can update this component when it detects orders missing start locations
  return (
    <>
      <StartLocationSelector
        open={showStartLocationSelector}
        onClose={handleCancel}
        onSelect={handleStartLocationSelect}
        businessId={businessId}
        orderId={currentOrderBeingFixed?.shipping_order_id}
        title={`Select Start Location for Order #${
          currentOrderBeingFixed?.order_number ||
          currentOrderBeingFixed?.shipping_order_id ||
          "Unknown"
        } (${ordersWithoutStart.length} order${
          ordersWithoutStart.length > 1 ? "s" : ""
        } remaining)`}
      />

      <FlowProvider>
        <BatchRouteBuilder
          {...props}
          setOrdersWithoutStart={setOrdersWithoutStart}
          setCurrentOrderBeingFixed={setCurrentOrderBeingFixed}
          setShowStartLocationSelector={setShowStartLocationSelector}
          key={refreshTrigger} // Force refresh when start locations are set
        />
      </FlowProvider>
    </>
  );
};

export default BatchRouteBuilderWithLocationCheck;
