// /Users/noelsarduy/GitHub/marketplace-app/client/src/utils/stripe.js

import { loadStripe } from "@stripe/stripe-js";

// Get the API key from environment variables
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Create a more reliable initialization that explicitly tracks loading state
let stripePromiseState = {
  promise: null,
  loading: false,
  error: null,
  resolved: false,
};

// Define a function to get or initialize Stripe
export const getStripePromise = () => {
  // If we already have a promise, return it
  if (stripePromiseState.promise) {
    return stripePromiseState.promise;
  }

  // Make sure we have an API key
  if (!stripeKey) {
    console.error("Stripe API key is missing! Check your .env file");
    stripePromiseState.error = new Error("Missing Stripe API key");

    // Return a rejected promise
    return Promise.reject(stripePromiseState.error);
  }

  // Set loading state
  stripePromiseState.loading = true;

  // Initialize Stripe
  stripePromiseState.promise = loadStripe(stripeKey)
    .then((stripeInstance) => {
      stripePromiseState.loading = false;
      stripePromiseState.resolved = true;
      console.log("Stripe initialized successfully");
      return stripeInstance;
    })
    .catch((error) => {
      stripePromiseState.loading = false;
      stripePromiseState.error = error;
      console.error("Failed to initialize Stripe:", error);
      throw error;
    });

  return stripePromiseState.promise;
};

export const resetStripe = () => {
  // Reset all state
  stripePromiseState = {
    promise: null,
    loading: false,
    error: null,
    resolved: false,
  };

  console.log("Stripe state has been reset");
  return null;
};

// Export for legacy support - initialize immediately
export const stripePromise = getStripePromise();

// Export the state for checking status
export const getStripeState = () => ({ ...stripePromiseState });
