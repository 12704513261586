import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useParams } from "react-router-dom";
import styles from "./SettingsPage.module.css";
import { useBusiness } from "../../../components/Auth/BusinessContext";

const SettingsPage = () => {
  const { businessId } = useParams();

  const {
    getBusinessById,
    businessCategories,
    fetchBusinessCategories,
    selectedBusiness,
  } = useBusiness();

  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [businessCategory, setBusinessCategory] = useState(null);

  // Fetch business and category data on mount
  useEffect(() => {
    const initializeData = async () => {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);

      // Fetch categories if needed
      if (businessCategories.length === 0) {
        await fetchBusinessCategories();
      }

      // Update category name
      const categoryName =
        businessCategories.find(
          (category) => category.category_id === business?.business_category_id
        )?.category_name || business?.business_category_name;

      setBusinessCategory(categoryName);
    };

    initializeData();
  }, [
    businessId,
    getBusinessById,
    businessCategories,
    fetchBusinessCategories,
  ]);

  // Update business category when categories or business changes
  useEffect(() => {
    if (currentBusiness && businessCategories.length > 0) {
      const categoryName =
        businessCategories.find(
          (category) =>
            category.category_id === currentBusiness?.business_category_id
        )?.category_name || currentBusiness?.business_category_name;

      setBusinessCategory(categoryName);
    }
  }, [currentBusiness, businessCategories]);

  // No need for sidebar useEffect - removed

  return (
    <div className="business-page">
      <main className="business-page-content">
            <h1 className={styles.title}>Settings</h1>
            <div className={styles["settings-grid"]}>
              <Link
                to={`/business/${businessId}/settings/printing-settings`}
                className={styles["settings-card"]}
              >
                <h3>Printing Settings</h3>
                <p>
                  Configure your printing options, including receipts, shipping
                  labels, and more.
                </p>
              </Link>

              <Link
                to={`/business/${businessId}/settings/profile`}
                className={styles["settings-card"]}
              >
                <h3>Profile Settings</h3>
                <p>Update your profile information and preferences.</p>
              </Link>

              <Link
                to={`/business/${businessId}/settings/security`}
                className={styles["settings-card"]}
              >
                <h3>Security Settings</h3>
                <p>
                  Manage your security options, such as passwords and two-factor
                  authentication.
                </p>
              </Link>

              <Link
                to={`/business/${businessId}/settings/notifications`}
                className={styles["settings-card"]}
              >
                <h3>Notification Settings</h3>
                <p>Customize how you receive notifications from the app.</p>
              </Link>

              <Link
                to={`/business/${businessId}/settings/integrations`}
                className={styles["settings-card"]}
              >
                <h3>Integrations</h3>
                <p>Connect with third-party services and tools.</p>
              </Link>
            </div>
      </main>
    </div>
  );
};

export default SettingsPage;
