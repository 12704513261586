import React, { useState, useEffect } from "react";
import "./ScrollFooter.css";

const ScrollFooter = () => {
  const [visible, setVisible] = useState(true);
  const [scrollDirection, setScrollDirection] = useState("up");
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const direction = prevScrollY > currentScrollY ? "up" : "down";
      
      // Threshold before we consider showing/hiding the footer
      const isVisible = direction === "up" || 
                        currentScrollY < 100 || 
                        currentScrollY + window.innerHeight >= document.body.scrollHeight - 50;
      
      setScrollDirection(direction);
      setPrevScrollY(currentScrollY);
      setVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollY, scrollDirection]);

  return (
    <footer className={`scroll-footer ${visible ? "visible" : "hidden"}`}>
      <p>&copy; {new Date().getFullYear()} Cexpress. All rights reserved.</p>
    </footer>
  );
};

export default ScrollFooter;