import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";
import {
  useParams,
  useLocation,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import {
  Typography,
  Tabs,
  Tab,
  Alert,
  Button,
  Box,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import {
  AddCircle as AddIcon,
  NoteAdd as NoteAddIcon,
  Refresh as RefreshIcon,
  Payment as PaymentIcon,
} from "@mui/icons-material";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { useBusiness } from "../../../../components/Auth/BusinessContext";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import { OrdersProvider, useOrders } from "./OrdersContext";

// Child components
import DashboardOverview from "./DashboardOverview";
import QuickAddOrder from "./QuickAddOrder";
import BulkImport from "./BulkImport";
import OrderList from "./OrderList";
import OrderEditPage from "./OrderEditPage/OrderEditPage";
import CollaborationManagementScreen from "./CollaborationManagementScreen";
import OrderAssignment from "./OrderAssignment";

import "./ShipmentsPage.css";

const ShipmentsContent = () => {
  // -----------------------------
  // Hooks & Context
  // -----------------------------
  const { businessId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { getBusinessById } = useBusiness();
  const { api } = useContext(AuthContext);

  const {
    orders,
    groups,
    locations,
    couriers,
    loading,
    error,
    customers,
    customersError,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    fetchLocations,
    fetchCouriers,
    addOrder,
    deleteOrderFromLocalStorage,
  } = useOrders();

  // -----------------------------
  // Tabs & sessionStorage Key
  // -----------------------------
  const SESSION_STORAGE_TAB_KEY = `shipments_active_tab_${
    businessId || "default"
  }`;
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = sessionStorage.getItem(SESSION_STORAGE_TAB_KEY);
    return savedTab !== null ? parseInt(savedTab, 10) : 0;
  });

  // -----------------------------
  // Payment Method Check
  // -----------------------------
  const [checkingPaymentMethod, setCheckingPaymentMethod] = useState(true);
  const [hasValidPaymentMethod, setHasValidPaymentMethod] = useState(false);

  useEffect(() => {
    if (!businessId) return;

    const checkPaymentMethod = async () => {
      try {
        setCheckingPaymentMethod(true);
        const response = await api.get(
          `/payments/business-payment-methods?businessId=${businessId}`
        );

        if (response.data) {
          // Check if the business has any payment methods
          setHasValidPaymentMethod(
            response.data.hasPaymentMethods ||
              (response.data.paymentMethods &&
                response.data.paymentMethods.length > 0)
          );
        }
      } catch (error) {
        console.error("Error checking payment method:", error);
      } finally {
        setCheckingPaymentMethod(false);
      }
    };

    checkPaymentMethod();
  }, [businessId, api]);

  // -----------------------------
  // Data fetching for orders, etc.
  // -----------------------------
  const [refreshCount, setRefreshCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialFilter, setInitialFilter] = useState("");
  const [completingOrder, setCompletingOrder] = useState(null);
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  // Load orders, customers, etc. initially
  useEffect(() => {
    const fetchData = async () => {
      if (!businessId) return;
      try {
        // Avoid redundant fetches if data is already present
        const needsFetch =
          !orders.length ||
          !customers.length ||
          !locations.length ||
          !couriers.length;

        // Also fetch if we are in a nested route
        const isNestedRoute = location.pathname.split("/").length > 4;

        if (needsFetch || isNestedRoute) {
          await Promise.all([
            fetchOrders(businessId),
            fetchCustomers(businessId),
            fetchGroups(businessId),
            fetchLocations(businessId),
            fetchCouriers(businessId),
          ]);
        }
      } catch (fetchError) {
        console.error("Error fetching initial data:", fetchError);
      }
    };

    fetchData();
  }, [
    businessId,
    location.pathname,
    orders.length,
    customers.length,
    locations.length,
    couriers.length,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    fetchLocations,
    fetchCouriers,
  ]);

  // -----------------------------
  // Restore scroll position on route change
  // -----------------------------
  const prevLocationRef = useRef(null);
  useLayoutEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0; // Safari
      document.documentElement.scrollTop = 0; // Chrome/Firefox/IE
      prevLocationRef.current = location.pathname;
    }
  }, [location.pathname]);

  // -----------------------------
  // Handle manual refresh
  // -----------------------------
  const handleRefresh = useCallback(async () => {
    setRefreshCount((prev) => prev + 1);
    console.log("Manual refresh triggered");
    try {
      const results = await Promise.all([
        fetchOrders(businessId, true),
        fetchCustomers(businessId),
        fetchGroups(businessId),
        fetchLocations(businessId),
        fetchCouriers(businessId),
      ]);

      console.log("Refresh completed:", {
        orders: results[0]?.length,
        customers: results[1]?.length,
        groups: results[2]?.length,
        locations: results[3]?.length,
        couriers: results[4]?.length,
      });
    } catch (error) {
      console.error("Error during refresh:", error);
    }
  }, [
    businessId,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    fetchLocations,
    fetchCouriers,
  ]);

  // -----------------------------
  // URL/State: filter & tab from location.state or query params
  // -----------------------------
  useEffect(() => {
    const handleInitialParams = () => {
      // If we've already processed
      if (location.state?.processed) return false;

      // Check location.state
      if (location.state) {
        const { activeTab: tab, searchQuery: search, filter } = location.state;
        if (tab !== undefined) {
          setActiveTab(tab);
          sessionStorage.setItem(SESSION_STORAGE_TAB_KEY, tab.toString());
        }
        if (search) setSearchQuery(search);
        if (filter) setInitialFilter(filter);

        // Mark processed
        navigate(location.pathname, {
          replace: true,
          state: { processed: true },
        });
        return true;
      }

      // Check URL query ?status= if no location.state
      const params = new URLSearchParams(location.search);
      const statusParam = params.get("status");
      if (statusParam && !location.state?.processedParams) {
        setInitialFilter(statusParam);
        params.delete("status");
        const newSearch = params.toString();

        navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ""}`, {
          replace: true,
          state: { processedParams: true },
        });
        return true;
      }

      return false;
    };

    handleInitialParams();
  }, [location, navigate]);

  // -----------------------------
  // Handlers for order completion, tab change
  // -----------------------------
  const handleCompleteOrder = useCallback(
    (order) => {
      setCompletingOrder(order);
      // Switch to OrderAssignment tab
      setActiveTab(1);
      sessionStorage.setItem(SESSION_STORAGE_TAB_KEY, "1");
    },
    [SESSION_STORAGE_TAB_KEY]
  );

  const handleTabChange = useCallback(
    (event, newValue) => {
      setActiveTab(newValue);
      sessionStorage.setItem(SESSION_STORAGE_TAB_KEY, newValue.toString());
    },
    [SESSION_STORAGE_TAB_KEY]
  );

  // -----------------------------
  // If still checking payment method, show spinner or skeleton
  // -----------------------------
  if (checkingPaymentMethod) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  // -----------------------------
  // Render
  // -----------------------------
  return (
    <div className="business-page business-analytics-page">
      <main className="business-page-content">
        <div className="business-page-header">
          <Typography variant="h4" gutterBottom>
            Shipments Management
          </Typography>
          <Box>
            <Button
              variant="contained"
              startIcon={<NoteAddIcon />}
              onClick={() => setQuickAddOpen(true)}
              sx={{
                mr: 2,
                fontSize: "1rem",
                padding: "12px 24px",
                borderRadius: "60px",
                fontWeight: "bold",
              }}
            >
              New Order
            </Button>
          </Box>
        </div>

        {/* Dashboard Overview */}
        <DashboardOverview orders={orders} />

        {/* Top-level Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Order List" />
          <Tab label="Order Assignment" />
          <Tab label="Collaboration Management" />
          <Tab label="Bulk Import" />
        </Tabs>

        <div className="tab-content">
          {/* 0: Order List */}
          {activeTab === 0 && (
            <OrderList
              onCompleteOrder={handleCompleteOrder}
              onDeleteDraftOrder={deleteOrderFromLocalStorage}
              initialSearchQuery={searchQuery}
              initialFilter={initialFilter}
            />
          )}

          {/* 1: Order Assignment */}
          {activeTab === 1 && <OrderAssignment />}

          {/* 2: Collaboration Management */}
          {activeTab === 2 &&
            (hasValidPaymentMethod ? (
              <CollaborationManagementScreen
                businessId={businessId}
                orders={orders}
                onRefresh={handleRefresh}
              />
            ) : (
              // If no valid payment method, show a warning sign/card instead:
              <Card variant="outlined" sx={{ mt: 2, p: 2 }}>
                <CardContent>
                  <Typography variant="h6" color="error" gutterBottom>
                    <PaymentIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                    Collaboration Unavailable
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    To access Collaboration Management, you need a valid payment
                    method on file. Please add or update your payment method to
                    continue.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/business/${businessId}/finances`)}
                  >
                    Add Payment Method
                  </Button>
                </CardContent>
              </Card>
            ))}

          {/* 3: Bulk Import */}
          {activeTab === 3 && (
            <BulkImport
              onOrdersImport={(importedOrders) => {
                importedOrders.forEach(addOrder);
              }}
            />
          )}

          {/* Quick Add Order dialog */}
          <QuickAddOrder
            open={quickAddOpen}
            onClose={() => setQuickAddOpen(false)}
            existingCustomers={customers}
            onOrderCreate={(order) => {
              addOrder(order);
            }}
            initialOrder={completingOrder}
          />
        </div>
      </main>
    </div>
  );
};

const ShipmentsPage = () => {
  const { businessId } = useParams();

  return (
    <OrdersProvider>
      <Routes>
        {/* The main ShipmentsContent */}
        <Route path="/" element={<ShipmentsContent />} />

        {/* Nested route for editing a specific order */}
        <Route path="/:orderId" element={<OrderEditPage />} />
      </Routes>
    </OrdersProvider>
  );
};

export default ShipmentsPage;
