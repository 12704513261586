import qz from 'qz-tray';

/**
 * QZ Tray Connection Manager
 * 
 * This utility provides a centralized way to manage connections to QZ Tray,
 * handling connection errors, retries, and providing status information.
 */
class QzTrayManager {
  constructor() {
    this.isConnecting = false;
    this.connectionPromise = null;
    this.connectionListeners = [];
    this.reconnectTimeout = null;
    this.maxRetries = 3;
    this.currentRetry = 0;
    this.reconnectDelay = 2000; // 2 seconds
    
    // Configure QZ websocket listeners
    qz.websocket.setClosedCallbacks(this.handleConnectionClosed.bind(this));
    qz.websocket.setErrorCallbacks(this.handleConnectionError.bind(this));
  }

  /**
   * Get a connection to QZ Tray, creating one if needed
   * @returns {Promise<boolean>} Promise resolving to true if connection successful
   */
  connect() {
    // If already connected, return immediately
    if (qz.websocket.isActive()) {
      return Promise.resolve(true);
    }
    
    // If already connecting, return the existing promise
    if (this.connectionPromise) {
      return this.connectionPromise;
    }
    
    // Start new connection
    this.isConnecting = true;
    this.connectionPromise = new Promise((resolve, reject) => {
      console.log('Initiating QZ Tray connection...');
      
      qz.websocket.connect()
        .then(() => {
          console.log('QZ Tray connected successfully');
          this.currentRetry = 0;
          this.isConnecting = false;
          this.notifyListeners(true);
          resolve(true);
        })
        .catch(error => {
          console.error('QZ Tray connection error:', error);
          this.isConnecting = false;
          
          // Retry connection if we haven't reached max retries
          if (this.currentRetry < this.maxRetries) {
            this.currentRetry++;
            console.log(`Retrying connection (${this.currentRetry}/${this.maxRetries})...`);
            
            // Clear any existing timeout
            if (this.reconnectTimeout) {
              clearTimeout(this.reconnectTimeout);
            }
            
            // Set timeout for reconnection
            this.reconnectTimeout = setTimeout(() => {
              this.connectionPromise = null;
              resolve(this.connect());
            }, this.reconnectDelay);
          } else {
            this.notifyListeners(false, error);
            reject(error);
          }
        })
        .finally(() => {
          // Reset promise after resolution or rejection
          setTimeout(() => {
            this.connectionPromise = null;
          }, 100);
        });
    });
    
    return this.connectionPromise;
  }
  
  /**
   * Disconnect from QZ Tray 
   */
  disconnect() {
    if (qz.websocket.isActive()) {
      return qz.websocket.disconnect()
        .then(() => {
          console.log('QZ Tray disconnected successfully');
          this.notifyListeners(false);
          return true;
        })
        .catch(error => {
          console.error('QZ Tray disconnect error:', error);
          return false;
        });
    }
    return Promise.resolve(true);
  }
  
  /**
   * Handle connection closure events from QZ Tray
   */
  handleConnectionClosed(evt) {
    console.log('QZ Tray connection closed', evt);
    // Don't try to reconnect on normal closure (code 1000)
    if (evt && evt.code !== 1000) {
      this.connectionPromise = null;
      setTimeout(() => {
        // Only auto-reconnect if we were previously connected
        if (this.connectionListeners.length > 0) {
          this.connect().catch(console.error);
        }
      }, this.reconnectDelay);
    }
  }
  
  /**
   * Handle connection errors from QZ Tray
   */
  handleConnectionError(error) {
    console.error('QZ Tray websocket error:', error);
    this.notifyListeners(false, error);
  }
  
  /**
   * Add a listener for connection status changes
   * @param {Function} listener Callback function that receives (isConnected, error)
   * @returns {Function} Function to remove the listener
   */
  addConnectionListener(listener) {
    this.connectionListeners.push(listener);
    
    // Return function to remove this listener
    return () => {
      this.connectionListeners = this.connectionListeners
        .filter(l => l !== listener);
    };
  }
  
  /**
   * Notify all listeners of connection status changes
   * @param {boolean} isConnected Whether QZ Tray is connected
   * @param {Error} error Error if connection failed
   */
  notifyListeners(isConnected, error = null) {
    this.connectionListeners.forEach(listener => {
      try {
        listener(isConnected, error);
      } catch (e) {
        console.error('Error in QZ Tray connection listener:', e);
      }
    });
  }
  
  /**
   * Check if QZ Tray is currently connected
   * @returns {boolean} True if connected
   */
  isConnected() {
    return qz.websocket.isActive();
  }
  
  /**
   * Print data to a printer using QZ Tray
   * Automatically connects if not already connected
   * 
   * @param {string} printer Printer name
   * @param {Object} config Print configuration
   * @param {Array} data Print data
   * @returns {Promise} Promise resolving when print is complete
   */
  async print(printer, config, data) {
    try {
      // Ensure we're connected
      await this.connect();
      
      // Create printer configuration
      const printConfig = qz.configs.create(printer, config);
      
      // Send print job
      return await qz.print(printConfig, data);
    } catch (error) {
      console.error('QZ Tray print error:', error);
      throw error;
    }
  }
}

// Create and export a singleton instance
const qzTrayManagerInstance = new QzTrayManager();
export default qzTrayManagerInstance;