import React from "react";
import { Outlet } from "react-router-dom";
import ScrollHeader from "../components/Header/ScrollHeader";
import ScrollFooter from "../components/Footer/ScrollFooter";
import "./styles/LayoutStyles.css";

/**
 * PublicLayout component for pages that are publicly accessible
 * This layout includes the standard header and footer
 * Can be used with Outlet or with direct children
 */
const PublicLayout = ({ children }) => {
  return (
    <div className="layout-container public-page">
      <ScrollHeader />
      <main className="public-content">
        <div className="page-transition-wrapper content-centered">
          {children || <Outlet />}
        </div>
      </main>
      <ScrollFooter />
    </div>
  );
};

export default PublicLayout;
