import React from 'react';
import { Card, CardContent, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Divider, Chip } from '@mui/material';
import { Warning, Error, Info } from '@mui/icons-material';

const PerformanceAlertsCard = ({ alerts }) => {
  const getAlertIcon = (type) => {
    switch(type) {
      case 'error':
        return <Error color="error" />;
      case 'warning':
        return <Warning color="warning" />;
      case 'info':
        return <Info color="info" />;
      default:
        return <Info />;
    }
  };

  const getImpactChip = (impact) => {
    switch(impact) {
      case 'high':
        return <Chip label="High Impact" size="small" color="error" />;
      case 'medium':
        return <Chip label="Medium Impact" size="small" color="warning" />;
      case 'low':
        return <Chip label="Low Impact" size="small" color="info" />;
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Performance Alerts
        </Typography>
        <List sx={{ width: '100%' }}>
          {alerts.map((alert, index) => (
            <React.Fragment key={alert.id || index}>
              {index > 0 && <Divider component="li" />}
              <ListItem
                alignItems="flex-start"
                secondaryAction={alert.impact && getImpactChip(alert.impact)}
              >
                <ListItemIcon>
                  {getAlertIcon(alert.type)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="text.primary"
                      fontWeight="medium"
                    >
                      {alert.metric}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {alert.message}
                      </Typography>
                      {alert.value && alert.threshold && (
                        <Box mt={1}>
                          <Typography variant="caption" color="text.secondary">
                            Current: {alert.value}{typeof alert.value === 'number' ? '%' : ''} | 
                            Target: {alert.threshold}{typeof alert.threshold === 'number' ? '%' : ''}
                          </Typography>
                        </Box>
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PerformanceAlertsCard;
