import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import _, { set } from "lodash";

const OrdersContext = createContext();

export const useOrders = () => {
  const context = useContext(OrdersContext);
  if (context === undefined) {
    throw new Error("useOrders must be used within an OrdersProvider");
  }
  return context;
};

export const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groupsError, setGroupsError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(true);
  const [customersError, setCustomersError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);
  const [locationsError, setLocationsError] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [couriersLoading, setCouriersLoading] = useState(true);
  const [couriersError, setCouriersError] = useState(null);
  const { api } = useContext(AuthContext);

  const fetchLocations = useCallback(async (businessId) => {
    setLocationsLoading(true);
    setLocationsError(null);
    try {
      // Fetch business addresses that are shipping locations
      const response = await api.get(`/business/${businessId}/addresses`);
      
      // Filter for shipping locations
      const shippingAddresses = response.data.filter(addr => 
        addr.is_shipping_location || ['warehouse', 'dropoff_point', 'pickup_point', 'store'].includes(addr.business_address_type.toLowerCase())
      );
      
      setLocations(shippingAddresses);
      return shippingAddresses;
    } catch (err) {
      console.error("Error fetching locations:", err);
      setLocationsError("Failed to fetch locations. Please try again later.");
      throw err;
    } finally {
      setLocationsLoading(false);
    }
  }, []);

  const fetchCouriers = useCallback(async (businessId) => {
    console.log("Fetching couriers for business ID:", businessId);
    setCouriersLoading(true);
    setCouriersError(null);
    try {
      const response = await api.get(`/employees/couriers/${businessId}`);
      setCouriers(response.data);
      console.log("Couriers:", response.data);
      return response.data;
    } catch (err) {
      console.error("Error fetching couriers:", err);
      setCouriersError("Failed to fetch couriers. Please try again later.");
      throw err;
    } finally {
      setCouriersLoading(false);
    }
  }, []);

  const fetchOrders = useCallback(
    async (businessId, refresh = false) => {
      if (!loaded || refresh) {
        setLoading(true);
        setError(null);
        try {
          const response = await api.get("/shipping-orders", {
            params: { business_id: businessId },
          });
          const backendOrders = response.data;
          const draftOrders = JSON.parse(
            localStorage.getItem("incompleteOrders") || "[]"
          );
          setOrders([...draftOrders, ...backendOrders]);
          setLoaded(true);
        } catch (err) {
          console.error("Error fetching orders:", err);
          setError("Failed to fetch orders. Please try again later.");
        } finally {
          setLoading(false);
        }
      } else if (!refresh) {
        setLoading(false);
        setError(null);
        try {
          const response = await api.get("/shipping-orders", {
            params: { business_id: businessId },
          });
          const backendOrders = response.data;
          const draftOrders = JSON.parse(
            localStorage.getItem("incompleteOrders") || "[]"
          );
          setOrders([...draftOrders, ...backendOrders]);
          setLoaded(true);
        } catch (err) {
          console.error("Error fetching orders:", err);
          setError("Failed to fetch orders. Please try again later.");
        }
      }
    },
    [loaded, api]
  );

  const fetchSingleOrder = useCallback(
    async (orderId) => {
      try {
        // First check if we have it in current orders
        const existingOrder = orders.find(
          (order) => order.shipping_order_id === orderId
        );
        if (existingOrder) {
          console.log("Returning cached order:", existingOrder);
          return existingOrder;
        }

        console.log("Fetching order from API:", orderId);
        const response = await api.get(`/shipping-orders/${orderId}`);
        const fetchedOrder = response.data;

        // Update orders state with the fetched order
        setOrders((prevOrders) => {
          const orderIndex = prevOrders.findIndex(
            (o) => o.shipping_order_id === orderId
          );
          if (orderIndex >= 0) {
            // Replace existing order
            const newOrders = [...prevOrders];
            newOrders[orderIndex] = fetchedOrder;
            return newOrders;
          } else {
            // Add new order
            return [...prevOrders, fetchedOrder];
          }
        });

        return fetchedOrder;
      } catch (err) {
        console.error("Error fetching single order:", err);
        throw new Error(
          "Failed to fetch order details: " +
            (err.response?.data?.message || err.message)
        );
      }
    },
    [orders, api]
  );

  // fetch customers
  const fetchCustomers = useCallback(
    async (businessId) => {
      setCustomersLoading(true);
      setCustomersError(null);
      try {
        const response = await api.get("/customers", {
          params: {
            business_id: businessId,
          },
        });
        setCustomers(response.data.customers);
      } catch (err) {
        console.error("Error fetching customers:", err);
        setCustomersError("Failed to fetch customers. Please try again later.");
      } finally {
        setCustomersLoading(false);
      }
    },
    [api]
  );

  const updateOrder = async (orderId, updatedData, businessId) => {
    try {
      console.log("Updating order with data: ", updatedData);

      // Clean and prepare data for API
      const dataToSend = {
        ...updatedData,
        business_id: businessId,
        Packages: updatedData.Packages,
      };

      console.log("Sending data to API:", JSON.stringify(dataToSend, null, 2));

      const response = await api.patch(
        `/shipping-orders/${orderId}`,
        dataToSend
      );

      if (!response.data || !response.data.success) {
        throw new Error(response.data?.msg || "Update failed");
      }

      console.log("Response data:", response);

      const updatedOrderData = response.data.updatedOrder;

      // Update orders in context with proper merging
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.shipping_order_id === orderId
            ? {
                ...order,
                ...updatedOrderData,
                Packages: updatedOrderData.Packages?.map((pkg) => ({
                  ...pkg,
                  PackageItems: pkg.PackageItems?.map((item) => ({
                    ...item,
                    // Preserve any necessary UI state
                    isExpanded: order.Packages?.find(
                      (p) => p.package_id === pkg.package_id
                    )?.PackageItems?.find(
                      (i) => i.package_item_id === item.package_item_id
                    )?.isExpanded,
                  })),
                })),
              }
            : order
        )
      );

      return updatedOrderData;
    } catch (err) {
      console.error("Error updating order:", err);

      // Enhanced error logging
      if (err.response) {
        console.error("Response status:", err.response.status);
        console.error("Response data:", err.response.data);
        console.error("Response headers:", err.response.headers);
      } else if (err.request) {
        console.error("No response received:", err.request);
      } else {
        console.error("Error setting up request:", err.message);
      }

      // Structured error message
      const errorMessage =
        err.response?.data?.msg ||
        err.response?.data?.message ||
        err.response?.data?.error ||
        err.message ||
        "An unexpected error occurred";

      throw new Error(`${errorMessage}`);
    }
  };

  const updateOrderAddress = async (orderId, role, addressId, businessId) => {
    try {
      const response = await api.patch(`/shipping-orders/${orderId}/address`, {
        role,
        business_id: businessId,
        address_id: addressId,
      });

      return response.data; // Return the updated order data
    } catch (error) {
      console.error(
        `Error updating ${role} address for order ${orderId}:`,
        error.response?.data || error.message
      );
      throw error;
    }
  };

  const addOrder = useCallback((newOrder) => {
    setOrders((prevOrders) => [...prevOrders, newOrder]);
  }, []);

  const deleteOrder = useCallback((orderId) => {
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.shipping_order_id !== orderId)
    );
  }, []);

  const deleteOrderFromLocalStorage = useCallback((orderId) => {
    const draftOrders = JSON.parse(
      localStorage.getItem("incompleteOrders") || "[]"
    );
    localStorage.setItem(
      "incompleteOrders",
      JSON.stringify(
        draftOrders.filter((order) => order.shipping_order_id !== orderId)
      )
    );
    setOrders((prevOrders) =>
      prevOrders.filter((order) => order.shipping_order_id !== orderId)
    );
  }, []);

  // save customer info

  const saveCustomerInfo = useCallback(
    async (customerId, customerData, businessId) => {
      try {
        console.log("customerData: ", customerData);
        console.log("customerId: ", customerId);
        console.log("businessId: ", businessId);
        const response = await api.put(`/customers/update`, {
          business_id: businessId,
          customer_id: customerId,
          ...customerData,
        });
        const updatedCustomer = response.data.customer;

        // Update the customer in the orders state if necessary
        setOrders((prevOrders) =>
          prevOrders.map((order) => {
            if (order.sender.customer_id === customerId) {
              return { ...order, sender: updatedCustomer };
            }
            if (order.recipient.customer_id === customerId) {
              return { ...order, recipient: updatedCustomer };
            }
            return order;
          })
        );
        console.log("Updated customer:", updatedCustomer);
        return updatedCustomer;
      } catch (err) {
        console.error("Error updating customer information:", err);
        throw new Error("Failed to update customer information");
      }
    },
    [api]
  );

  // updatePackages

  const updatePackages = useCallback(
    async (orderId, packages) => {
      try {
        const response = await api.put(`/shipping-orders/${orderId}/packages`, {
          packages,
        });
        return response.data;
      } catch (err) {
        console.error("Error updating packages:", err);
        throw new Error("Failed to update packages");
      }
    },
    [api]
  );

  const updateOrderStatus = useCallback(async (orderId, newStatus) => {
    try {
      console.log("Updating order status to: ", newStatus);
      console.log("Order ID: ", orderId);

      const response = await api.put(`/shipping-orders/${orderId}/status`, {
        status: newStatus,
      });

      const updatedOrder = response.data;

      setOrders((prevOrders) => {
        // Create a deep copy of the previous orders
        const newOrders = [...prevOrders];

        // Find the order to update
        const orderToUpdate = newOrders.find(
          (order) => order.shipping_order_id === orderId
        );

        if (!orderToUpdate) {
          console.warn(`Order with ID ${orderId} not found in current state`);
          return prevOrders;
        }

        // Update the order with all the new data from the response
        Object.assign(orderToUpdate, {
          ...orderToUpdate,
          ...updatedOrder,
          order_status: newStatus,
        });

        return newOrders;
      });

      setUpdateError(null);
      return updatedOrder;
    } catch (err) {
      const errorMessage = err.response?.data?.msg || err.message;
      console.error("Error updating order status:", errorMessage);
      setUpdateError(`Failed to update order status: ${errorMessage}`);
      throw new Error(`Failed to update order status: ${errorMessage}`);
    }
  }, []);

  const fetchGroups = useCallback(
    async (businessId) => {
      setGroupsLoading(true);
      setGroupsError(null);
      try {
        const response = await api.get("/shipping-groups", {
          params: { business_id: businessId },
        });
        setGroups(response.data);
      } catch (err) {
        console.error("Error fetching groups:", err);
        setGroupsError("Failed to fetch groups. Please try again later.");
      } finally {
        setGroupsLoading(false);
      }
    },
    [api]
  );

  const createGroup = useCallback(
    async (groupData, businessId) => {
      try {
        const response = await api.post("/shipping-groups", {
          ...groupData,
          business_id: businessId,
        });
        const newGroup = response.data;
        // Ensure the new group has all necessary properties
        const completeNewGroup = {
          ...newGroup,
          ShippingOrders: [],
          total_value: 0,
          createdAt: new Date().toISOString(),
          status: "active",
        };
        setGroups((prevGroups) => [...prevGroups, completeNewGroup]);
        return completeNewGroup;
      } catch (err) {
        console.error("Error creating group:", err);
        throw new Error(
          "Failed to create group: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const updateGroup = useCallback(
    async (groupId, updatedData, businessId) => {
      try {
        const response = await api.put(`/shipping-groups/${groupId}`, {
          ...updatedData,
          business_id: businessId,
        });
        const updatedGroup = response.data;
        setGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === updatedGroup.group_id
              ? { ...group, ...updatedGroup }
              : group
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error updating group:", err);
        throw new Error(
          "Failed to update group: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const deleteGroup = useCallback(
    async (groupId, businessId) => {
      try {
        await api.delete(`/shipping-groups/${groupId}`, {
          params: { business_id: businessId },
        });
        setGroups((prevGroups) =>
          prevGroups.filter((group) => group.group_id !== groupId)
        );
      } catch (err) {
        console.error("Error deleting group:", err);
        throw new Error(
          "Failed to delete group: " + (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const addOrderToGroup = useCallback(
    async (groupId, orderId, businessId) => {
      try {
        const response = await api.post(`/shipping-groups/${groupId}/orders`, {
          order_id: orderId,
          business_id: businessId,
        });
        const updatedGroup = response.data;
        setGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === updatedGroup.group_id ? updatedGroup : group
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error adding order to group:", err);
        throw new Error(
          "Failed to add order to group: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const removeOrderFromGroup = useCallback(
    async (groupId, orderId, businessId) => {
      try {
        const response = await api.delete(
          `/shipping-groups/${groupId}/orders/${orderId}`,
          {
            params: { business_id: businessId },
          }
        );
        const updatedGroup = response.data;
        setGroups((prevGroups) =>
          prevGroups.map((group) =>
            group.group_id === updatedGroup.group_id ? updatedGroup : group
          )
        );
        return updatedGroup;
      } catch (err) {
        console.error("Error removing order from group:", err);
        throw new Error(
          "Failed to remove order from group: " +
            (err.response?.data?.msg || err.message)
        );
      }
    },
    [api]
  );

  const value = {
    orders,
    setOrders,
    groups,
    groupsLoading,
    groupsError,
    loading,
    error,
    customers,
    customersLoading,
    customersError,
    fetchOrders,
    fetchCustomers,
    fetchSingleOrder,
    updateOrder,
    addOrder,
    deleteOrder,
    deleteOrderFromLocalStorage,
    saveCustomerInfo,
    updatePackages,
    updateOrderStatus,
    // New group-related functions
    fetchGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    addOrderToGroup,
    removeOrderFromGroup,
    updateOrderAddress,
    locations,
    locationsLoading,
    locationsError,
    couriers,
    couriersLoading,
    couriersError,
    fetchLocations,
    fetchCouriers,
  };

  return (
    <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
  );
};
