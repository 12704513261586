import React, { useEffect } from "react";
import "./ParallaxBackground.css";

const ParallaxBackground = () => {
  useEffect(() => {
    // Cache all layers on mount
    const layers = document.querySelectorAll(".parallax-layer");
    let animationFrameId = null;

    const handleMouseMove = (e) => {
      if (animationFrameId) return;
      animationFrameId = requestAnimationFrame(() => {
        const pageX = e.clientX - window.innerWidth / 2;
        const pageY = e.clientY - window.innerHeight / 2;
        layers.forEach((layer) => {
          const speed = parseFloat(layer.getAttribute("data-speed"));
          // Use translate3d for potential GPU acceleration
          layer.style.transform = `translate3d(${pageX * speed}px, ${
            pageY * speed
          }px, 0)`;
        });
        animationFrameId = null;
      });
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <section className="parallax" style={{ zIndex: '-1' }}>
      <div className="parallax-layer" data-speed="0.05" id="layer-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="metadata"
          poster="/images/background-image.jpg"
          style={{ opacity: 0.85 }}
        >
          <source src="/images/background-video.mp4" type="video/mp4" />
          {/* Optionally add another source: */}
          <source src="/images/background-video.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default ParallaxBackground;
