import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  MarkerType,
  Panel,
  Handle,
} from "reactflow";
import "reactflow/dist/style.css";
import { Box, Tooltip, Alert, Snackbar, Typography } from "@mui/material";
import {
  LocalShipping,
  Home,
  Store,
  Warehouse,
  LocalShippingOutlined,
  LocationOn,
  Flag,
} from "@mui/icons-material";
import {
  transformCoordinates,
  calculateHaversineDistance,
  calculateTravelDuration,
} from "./MapUtils";

// Node styling configuration
const NODE_COLORS = {
  start: "#FFD700",
  delivery: "#FF4444",
  warehouse: "#4CAF50",
  dropoff: "#2196F3",
  pickup: "#9C27B0",
  store: "#FF9800",
  default: "#757575",
};

const EDGE_STYLES = {
  default: {
    stroke: "#999",
    strokeWidth: 2,
    opacity: 0.7,
  },
  assigned: {
    stroke: "#2196f3",
    strokeWidth: 2.5,
    opacity: 1,
  },
  selected: {
    stroke: "#4caf50",
    strokeWidth: 3,
    opacity: 1,
  },
  invalid: {
    stroke: "#ff0000",
    strokeWidth: 2,
    opacity: 0.5,
    strokeDasharray: "5,5",
  },
};

// Location node component with enhanced styling
const LocationNode = ({ data }) => {
  const getNodeType = () => {
    switch (data.type) {
      case "start":
        return "input";
      case "delivery":
        return "output";
      default:
        return "default";
    }
  };

  const getIcon = () => {
    switch (data.type) {
      case "start":
        return <Flag sx={{ fontSize: 24, color: "#fff" }} />;
      case "delivery":
        return <Home sx={{ fontSize: 24, color: "#fff" }} />;
      case "warehouse":
        return <Warehouse sx={{ fontSize: 24, color: "#fff" }} />;
      case "dropoff":
        return <LocalShipping sx={{ fontSize: 24, color: "#fff" }} />;
      case "pickup":
        return <LocalShippingOutlined sx={{ fontSize: 24, color: "#fff" }} />;
      case "store":
        return <Store sx={{ fontSize: 24, color: "#fff" }} />;
      default:
        return <LocationOn sx={{ fontSize: 24, color: "#fff" }} />;
    }
  };

  const nodeType = getNodeType();

  return (
    <Tooltip
      title={
        <Box>
          <Typography variant="subtitle2">{data.label}</Typography>
          <Typography variant="body2">
            {data.details.business_address_street_address_1 || data.details.address_line1}
            <br />
            {data.details.business_address_city || data.details.city}, {data.details.business_address_state_province || data.details.state_province}
          </Typography>
          {data.type === "delivery" && data.details.Customer && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Recipient: {data.details.Customer.first_name}{" "}
              {data.details.Customer.last_name}
            </Typography>
          )}
        </Box>
      }
    >
      <Box
        sx={{
          position: "relative",
          background: data.color,
          p: 1.5,
          borderRadius: 1,
          width: 80,
          height: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid rgba(0,0,0,0.2)",
          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
          cursor: "pointer",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
          },
        }}
      >
        {nodeType !== "output" && (
          <Handle
            type="source"
            position="right"
            style={{
              background: nodeType === "input" ? "#4CAF50" : "#555",
              width: nodeType === "input" ? "12px" : "8px",
              height: nodeType === "input" ? "12px" : "8px",
              border: nodeType === "input" ? "2px solid #fff" : "none",
            }}
          />
        )}
        {nodeType !== "input" && (
          <Handle
            type="target"
            position="left"
            style={{
              background: nodeType === "output" ? "#FF4444" : "#555",
              width: nodeType === "output" ? "12px" : "8px",
              height: nodeType === "output" ? "12px" : "8px",
              border: nodeType === "output" ? "2px solid #fff" : "none",
            }}
          />
        )}
        {getIcon()}
        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            color: "#fff",
            textAlign: "center",
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {data.label}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const nodeTypes = { location: LocationNode };

// Flow content component with React Flow functionality
const ReactFlowContent = ({
  markers,
  routePath,
  onMarkerSelect,
  onEdgeSelect,
  onRoutePathUpdate,
  editMode,
  validateConnection,
  selectedEdge,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [validationMessage, setValidationMessage] = useState(null);
  const [viewport, setViewport] = useState({ width: 1000, height: 800 });

  const showMessage = (message, severity = "error") => {
    setValidationMessage({ message, severity });
    setTimeout(() => setValidationMessage(null), 3000);
  };

  // Transform coordinates and set up nodes
  const transformedData = useMemo(() => {
    if (!markers?.length) return null;

    const validMarkers = markers.filter((marker) => {
      const lat = parseFloat(marker.details.business_address_latitude || marker.details.latitude || marker.details.lat);
      const lng = parseFloat(marker.details.business_address_longitude || marker.details.longitude || marker.details.lng);

      if (isNaN(lat) || isNaN(lng)) {
        showMessage(
          `Marker "${marker.label}" has invalid coordinates.`,
          "warning"
        );
        return false;
      }
      return true;
    });

    if (!validMarkers.length) {
      showMessage("No valid markers with coordinates available.", "error");
      return null;
    }

    const points = validMarkers.map((marker) => ({
      lat: parseFloat(marker.details.business_address_latitude || marker.details.latitude || marker.details.lat),
      lng: parseFloat(marker.details.business_address_longitude || marker.details.longitude || marker.details.lng),
    }));

    return transformCoordinates(points, viewport.width, viewport.height);
  }, [markers, viewport]);

  // Update nodes when coordinates change
  useEffect(() => {
    if (!transformedData) return;

    const flowNodes = transformedData.transformedPoints.map((point, index) => ({
      id: markers[index].id.toString(),
      type: "location",
      position: { x: point.x, y: point.y },
      draggable: editMode === "move",
      data: {
        label: markers[index].label,
        color: NODE_COLORS[markers[index].type] || NODE_COLORS.default,
        type: markers[index].type,
        details: markers[index].details,
      },
    }));

    setNodes(flowNodes);
  }, [transformedData, markers, setNodes, editMode]);

  const handleEdgesDelete = useCallback(
    (deletedEdges) => {
      // Remove those edges from your routePath
      onRoutePathUpdate((currentPaths) => {
        const deletedIds = new Set(deletedEdges.map((e) => e.id));
        return currentPaths.filter((p) => !deletedIds.has(p.id));
      });
    },
    [onRoutePathUpdate]
  );

  // Update edges when route changes
  useEffect(() => {
    if (!routePath?.length) {
      setEdges([]);
      return;
    }

    const flowEdges = routePath
      .map((path) => {
        const sourceNode = nodes.find((n) => n.id === path.from.toString());
        const targetNode = nodes.find((n) => n.id === path.to.toString());

        if (!sourceNode || !targetNode) return null;

        const distance = calculateHaversineDistance(
          sourceNode.data.details.business_address_latitude || sourceNode.data.details.latitude,
          sourceNode.data.details.business_address_longitude || sourceNode.data.details.longitude,
          targetNode.data.details.business_address_latitude || targetNode.data.details.latitude,
          targetNode.data.details.business_address_longitude || targetNode.data.details.longitude
        );

        const formattedDistance = `${Math.round(distance)} km`;
        const label = path.courier
          ? `${path.courier.employee_first_name} • ${formattedDistance}`
          : formattedDistance;

        // Determine the edge style based on selection and assignment state
        const isSelected =
          selectedEdge &&
          selectedEdge.from === path.from &&
          selectedEdge.to === path.to;

        let edgeStyle = EDGE_STYLES.default;
        if (isSelected) {
          edgeStyle = EDGE_STYLES.selected;
        } else if (path.courier) {
          edgeStyle = EDGE_STYLES.assigned;
        }

        return {
          id: `${path.from}-${path.to}`,
          source: path.from.toString(),
          target: path.to.toString(),
          type: "simplebezier",
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
          },
          style: {
            ...edgeStyle,
          },
          data: { ...path, distance },
          label,
          labelBgStyle: {
            fill: isSelected
              ? "rgba(76, 175, 80, 0.1)"
              : "rgba(255, 255, 255, 0.9)",
            stroke: isSelected ? "#4caf50" : "transparent",
            strokeWidth: isSelected ? 1 : 0,
          },
          labelBgPadding: [8, 4],
          labelBgBorderRadius: 4,
          labelStyle: {
            fill: isSelected ? "#1a531c" : "#333",
            fontSize: 12,
            fontWeight: isSelected ? 500 : 400,
          },
        };
      })
      .filter(Boolean);

    setEdges(flowEdges);
  }, [routePath, nodes, setEdges, selectedEdge]);
  // Handle connection creation

  // Update this section in the ReactFlowContent component
  const onConnect = useCallback(
    (params) => {
      console.log("[onConnect] fired with:", params);

      // if (editMode !== "edge") {
      //   console.log("[onConnect] editMode != edge, returning early.");
      //   return;
      // }

      const validation = validateConnection(params.source, params.target);
      console.log("[onConnect] validation result:", validation);

      if (!validation.valid) {
        showMessage(validation.message);
        console.warn("[onConnect] Connection rejected:", validation.message);
        return;
      }

      // If we get here, it was valid
      console.log("[onConnect] Adding edge to routePath");
      onRoutePathUpdate((currentPaths) => [
        ...currentPaths,
        {
          id: `${params.source}-${params.target}`,
          from: params.source,
          to: params.target,
          courier: null,
        },
      ]);

      showMessage("Connection created successfully", "success");
    },
    [editMode, validateConnection, onRoutePathUpdate]
  );

  const onEdgeClick = useCallback(
    (event, edge) => {
      // Find the matching route path entry that corresponds to this edge
      const pathEntry = routePath.find(
        (path) => path.from === edge.source && path.to === edge.target
      );

      // If we found the matching path entry, send it to the parent
      if (pathEntry) {
        onEdgeSelect(pathEntry);
      }
    },
    [routePath, onEdgeSelect]
  );

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={handleEdgesDelete}
        onConnect={onConnect}
        onNodeClick={(_, node) => onMarkerSelect(node.data)}
        onEdgeClick={(_, edge) =>
          onEdgeClick(_, { source: edge.source, target: edge.target })
        }
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={{ padding: 0.2 }}
        defaultEdgeOptions={{
          type: "default",
          animated: true,
        }}
        connectionMode="loose"
        snapToGrid={false}
        nodesDraggable={editMode === "move"}
        selectNodesOnDrag={false}
        minZoom={0.1}
        maxZoom={2}
      >
        <Background color="#aaa" gap={16} />
        <Controls />
        <Panel position="top-left">
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 1.5,
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Typography variant="body2">
              <strong>Mode:</strong>{" "}
              {editMode === "edge"
                ? "Create connections by dragging between locations"
                : "View and select locations"}
            </Typography>
          </Box>
        </Panel>
      </ReactFlow>

      <Snackbar
        open={!!validationMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={validationMessage?.severity || "error"}>
          {validationMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

// Main RouteMapManager component
const RouteMapManager = ({
  markers,
  routePath,
  onMarkerSelect,
  onEdgeSelect,
  onRoutePathUpdate,
  editMode,
  validateConnection,
  selectedEdge,
  startLocationId,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        border: "1px solid #ddd",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <ReactFlowProvider>
        <ReactFlowContent
          markers={markers}
          routePath={routePath}
          onMarkerSelect={onMarkerSelect}
          onEdgeSelect={onEdgeSelect}
          onRoutePathUpdate={onRoutePathUpdate}
          editMode={editMode}
          validateConnection={validateConnection}
          selectedEdge={selectedEdge}
        />
      </ReactFlowProvider>
    </Box>
  );
};

export default RouteMapManager;
