import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import CustomButton from "../../../components/Button/Button";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { Alert, CircularProgress, Box, Paper } from "@mui/material";
import styles from "./BusinessPaymentsPage.module.css";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Typography,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Edit,
  Delete,
  CheckCircle,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaPaypal,
  FaCreditCard,
  FaCcJcb,
  FaCcDinersClub,
} from "react-icons/fa";

import AddCardIcon from "@mui/icons-material/AddCard";
import { Dialog, DialogContent } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { stripePromise } from "../../../utils/stripe";
import {
  BusinessPaymentsDashboard,
  BusinessPaymentSettings,
} from "../../../components/Payments";

const PaymentMethodForm = ({ onSubmit, onCancel, editingPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("card");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const paymentElement = elements.getElement(PaymentElement);

    if (!paymentElement) {
      setError("Payment element not found");
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      return;
    }

    if (editingPaymentMethod) {
      // Handle update
      onSubmit({ id: editingPaymentMethod.id, update: true });
    } else {
      // Handle new payment method creation
      const { error, paymentMethod: stripePaymentMethod } =
        await stripe.createPaymentMethod({
          elements,
        });

      if (error) {
        setError(error.message);
      } else if (stripePaymentMethod) {
        onSubmit(stripePaymentMethod);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.paymentForm}>
      <h2>
        {editingPaymentMethod
          ? "Edit Payment Method"
          : "Add New Payment Method"}
      </h2>
      <div className={styles.cardElementWrapper}>
        <PaymentElement
          options={{
            fields: {
              billingDetails: editingPaymentMethod ? "never" : "auto",
            },
            defaultValues: editingPaymentMethod
              ? {
                  billingDetails: {
                    name: editingPaymentMethod.billing_details.name,
                    email: editingPaymentMethod.billing_details.email,
                    phone: editingPaymentMethod.billing_details.phone,
                    address: editingPaymentMethod.billing_details.address,
                  },
                }
              : undefined,
          }}
        />
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles["form-buttons"]}>
        <CustomButton
          type="submit"
          text={
            editingPaymentMethod
              ? "Update Payment Method"
              : "Add Payment Method"
          }
          disabled={!stripe}
        />
        <CustomButton onClick={onCancel} text="Cancel" />
      </div>
    </form>
  );
};

const PaymentMethodsList = ({
  paymentMethods,
  defaultPaymentMethodId,
  onSetDefault,
  onEdit,
  onRemove,
}) => {
  if (!paymentMethods || paymentMethods.length === 0) {
    return <Typography>No payment methods found.</Typography>;
  }

  const CardIcon = ({ brand }) => {
    const iconProps = { className: styles.cardIcon, size: 32 };
    switch (brand.toLowerCase()) {
      case "visa":
        return <FaCcVisa {...iconProps} color="#1A1F71" />;
      case "mastercard":
        return <FaCcMastercard {...iconProps} color="#EB001B" />;
      case "amex":
        return <FaCcAmex {...iconProps} color="#2E77BC" />;
      case "discover":
        return <FaCcDiscover {...iconProps} color="#FF6000" />;
      case "jcb":
        return <FaCcJcb {...iconProps} color="#0E4C96" />;
      case "diners":
        return <FaCcDinersClub {...iconProps} color="#004A97" />;
      default:
        return <FaCreditCard {...iconProps} color="#6C757D" />;
    }
  };

  return (
    <List className={styles.paymentMethodsList}>
      {paymentMethods.map((method) => (
        <ListItem key={method.id} divider className={styles.paymentMethodItem}>
          <ListItemIcon>
            <CardIcon brand={method.card.brand} />
          </ListItemIcon>
          <ListItemText
            primary={`${method.card.brand} **** ${method.card.last4}`}
            secondary={`Expires: ${method.card.exp_month}/${method.card.exp_year}`}
          />
          <div className={styles.paymentMethodActions}>
            {method.id === defaultPaymentMethodId ? (
              <Chip
                icon={<CheckCircle style={{ color: "white" }} />}
                label="Default"
                size="small"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                className={styles.defaultChip}
              />
            ) : (
              <Button
                variant="filled"
                size="small"
                onClick={() => onSetDefault(method.id)}
                className={styles.setDefaultButton}
                startIcon={<CheckCircleOutlineIcon />}
                sx={{
                  color: "var(--primary-color)",
                  borderColor: "var(--primary-color)",
                  "&:hover": {
                    color: "var(--primary-light)",
                    borderColor: "var(--primary-light)",
                  },
                }}
              >
                Set as Default
              </Button>
            )}
            <IconButton
              aria-label="edit"
              onClick={() => onEdit(method)}
              className={styles.actionIcon}
              sx={{ color: "var(--primary-color)" }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => onRemove(method.id)}
              className={styles.deleteIcon}
              sx={{ color: "var(--primary-color)" }}
            >
              <Delete />
            </IconButton>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

const BusinessPaymentsPage = () => {
  // Create a session storage key for saving tab state
  const SESSION_STORAGE_TAB_KEY = "payments_active_tab";

  const { api, user } = useContext(AuthContext);
  const { businesses, getBusinessById } = useBusiness();
  const { businessId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [refreshCount, setRefreshCount] = useState(0);

  // State variables
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [editingPaymentMethod, setEditingPaymentMethod] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false);
  const [error, setError] = useState(null);

  // Initialize activeTab from sessionStorage or default to 0
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = sessionStorage.getItem(SESSION_STORAGE_TAB_KEY);
    return savedTab !== null ? parseInt(savedTab, 10) : 0;
  });

  // Get business from context
  const currentBusiness = getBusinessById(businessId);

  // Check URL params for initial tab state
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("tab") === "settings") {
      setActiveTab(2); // Payment Settings tab
      sessionStorage.setItem(SESSION_STORAGE_TAB_KEY, "2");
    } else if (searchParams.get("tab") === "dashboard") {
      setActiveTab(1); // Payment Dashboard tab
      sessionStorage.setItem(SESSION_STORAGE_TAB_KEY, "1");
    }
  }, [location]);

  // Check if user has permission to access this business
  const hasPermission = () => {
    if (!user || !currentBusiness) return false;
    return currentBusiness.business_owner_user_id === user.user_id;
  };

  // Show error if business is not found
  useEffect(() => {
    if (businessId && !currentBusiness) {
      setError("Business not found");
    } else {
      setError(null);
    }
  }, [businessId, currentBusiness]);

  // Tab handling function
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Save the active tab to sessionStorage
    sessionStorage.setItem(SESSION_STORAGE_TAB_KEY, newValue.toString());

    // Update URL to reflect the current tab
    const searchParams = new URLSearchParams(location.search);
    if (newValue === 2) {
      searchParams.set("tab", "settings");
    } else if (newValue === 1) {
      searchParams.set("tab", "dashboard");
    } else {
      searchParams.delete("tab");
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  // Handle refresh
  const handleRefresh = useCallback(() => {
    setRefreshCount((prev) => prev + 1);
    fetchPaymentMethods();
  }, []);

  // Payment method management functions
  const handleEditPaymentMethod = (method) => {
    setEditingPaymentMethod(method);
    setShowAddPaymentForm(true);
  };

  const handleRemovePaymentMethod = async (paymentMethodId) => {
    if (!currentBusiness) return;

    setIsLoading(true);
    setAlertMessage(null);
    try {
      if (paymentMethodId === defaultPaymentMethodId) {
        const confirmRemove = window.confirm(
          "This is your default payment method. Are you sure you want to remove it? If you have other payment methods, a new one will be set as default."
        );
        if (!confirmRemove) {
          setIsLoading(false);
          return;
        }
      }

      const response = await api.delete(
        `/payments/business-payment-methods/${paymentMethodId}`,
        {
          data: { businessId: currentBusiness.business_id },
        }
      );

      if (response.data.newDefaultPaymentMethodId) {
        setDefaultPaymentMethodId(response.data.newDefaultPaymentMethodId);
      }

      setReloadTrigger((prev) => prev + 1);
      setAlertMessage({
        type: "success",
        message: "Payment method removed successfully",
      });
    } catch (error) {
      console.error("Error removing payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
    if (!currentBusiness) return;

    setIsLoading(true);
    setAlertMessage(null);
    try {
      await api.post("/payments/set-default-business-payment-method", {
        paymentMethodId,
        businessId: currentBusiness.business_id,
      });
      setReloadTrigger((prev) => prev + 1);
      setAlertMessage({
        type: "success",
        message: "Default payment method set successfully",
      });
    } catch (error) {
      console.log("Error setting default payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleUpdatePaymentMethod = async (paymentMethod) => {
    if (!currentBusiness) return;

    setIsLoading(true);
    setAlertMessage(null);

    try {
      const response = await api.put(
        `/payments/business-payment-methods/${paymentMethod.id}`,
        {
          businessId: currentBusiness.business_id,
        }
      );
      setReloadTrigger((prev) => prev + 1);
      setShowAddPaymentForm(false);
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
    } catch (error) {
      console.log("Error updating payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  };

  // Save sidebar state
  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
  }, [sidebarActive, reloadTrigger]);

  // Fetch client secret and payment methods
  useEffect(() => {
    if (!currentBusiness) return;

    const fetchClientSecret = async () => {
      setIsLoadingClientSecret(true);
      try {
        const response = await api.post(
          "/payments/create-business-setup-intent",
          { businessId: currentBusiness.business_id }
        );
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to initialize payment form. Please try again.",
        });
      } finally {
        setIsLoadingClientSecret(false);
      }
    };

    fetchClientSecret();
    fetchPaymentMethods();
  }, [currentBusiness, reloadTrigger, refreshCount]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("businessSidebarActive", JSON.stringify(isActive));
  }, []);

  const fetchPaymentMethods = async () => {
    if (!currentBusiness) return;

    setIsLoading(true);
    try {
      const response = await api.get(
        `/payments/business-payment-methods?businessId=${currentBusiness.business_id}`
      );
      setPaymentMethods(response.data.paymentMethods);
      setDefaultPaymentMethodId(response.data.defaultPaymentMethodId);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleAddPaymentMethod = async (paymentMethod) => {
    if (!currentBusiness) return;

    setIsLoading(true);
    setAlertMessage(null);

    const cardExists = paymentMethods.some(
      (existingMethod) =>
        existingMethod.card.last4 === paymentMethod.card.last4 &&
        existingMethod.card.brand === paymentMethod.card.brand &&
        existingMethod.card.exp_month === paymentMethod.card.exp_month &&
        existingMethod.card.exp_year === paymentMethod.card.exp_year
    );

    if (cardExists) {
      setAlertMessage({
        type: "error",
        message: "This card has already been added to your account.",
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post("/payments/business-payment-methods", {
        paymentMethodId: paymentMethod.id,
        businessId: currentBusiness.business_id,
      });
      setReloadTrigger((prev) => prev + 1);
      setShowAddPaymentForm(false);
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
    } catch (error) {
      console.log("Error adding payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  };

  // If business is not found, show error
  if (businessId && !currentBusiness) {
    return (
      <div className="business-page">
        <main className="business-page-content">
          <h1 className="page-title">Payment Management</h1>
          <div className="content-card">
            <Alert severity="error">
              Business not found. Please check if the business ID is correct.
            </Alert>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="business-page">
      <main className="business-page-content">
        <div className="business-page-header">
          <Typography variant="h4" gutterBottom>
            Payment Management
          </Typography>
          <Box>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              sx={{
                borderRadius: "60px",
                fontWeight: "bold",
              }}
            >
              Refresh
            </Button>
          </Box>
        </div>

        {/* Styled tabs to match the application design */}
        <Paper
          sx={{
            width: "100%",
            mb: 3,
            bgcolor: "transparent",
            boxShadow: "none",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
            aria-label="payment management tabs"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              "& .MuiTab-root": {
                fontSize: "1rem",
                fontWeight: 600,
                textTransform: "none",
                color: "var(--dark-gray)",
                "&.Mui-selected": {
                  color: "var(--soft-blue)",
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "var(--soft-blue)",
                height: 3,
              },
            }}
          >
            <Tab
              label="Payment Methods"
              id="tab-0"
              aria-controls="tabpanel-0"
            />
            <Tab
              label="Payment Dashboard"
              id="tab-1"
              aria-controls="tabpanel-1"
            />
            <Tab
              label="Payment Settings"
              id="tab-2"
              aria-controls="tabpanel-2"
            />
          </Tabs>
        </Paper>

        <div className="tab-content">
          {activeTab === 0 && (
            <div role="tabpanel" id="tabpanel-0" aria-labelledby="tab-0">
              {alertMessage && (
                <Alert
                  severity={alertMessage.type}
                  variant="outlined"
                  sx={{ mb: 2 }}
                >
                  {alertMessage.message}
                </Alert>
              )}

              {paymentMethods ? (
                <div>
                  {isLoading ? (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", my: 4 }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <PaymentMethodsList
                      paymentMethods={paymentMethods}
                      defaultPaymentMethodId={defaultPaymentMethodId}
                      onSetDefault={handleSetDefaultPaymentMethod}
                      onEdit={handleEditPaymentMethod}
                      onRemove={handleRemovePaymentMethod}
                    />
                  )}
                </div>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
                  <CircularProgress />
                </Box>
              )}

              {/* Centered Add Payment Method button or form */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                {!showAddPaymentForm ? (
                  <Button
                    variant="contained"
                    startIcon={<AddCardIcon />}
                    onClick={() => {
                      setEditingPaymentMethod(null);
                      setShowAddPaymentForm(true);
                    }}
                    sx={{
                      fontSize: "1rem",
                      padding: "12px 24px",
                      borderRadius: "60px",
                      fontWeight: "bold",
                      bgcolor: "var(--soft-blue)",
                      "&:hover": {
                        bgcolor: "var(--primary-dark)",
                      },
                    }}
                  >
                    Add New Payment Method
                  </Button>
                ) : isLoadingClientSecret ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", my: 4 }}
                  >
                    <CircularProgress />
                  </Box>
                ) : clientSecret ? (
                  <Box sx={{ width: "100%", maxWidth: "600px" }}>
                    <Elements
                      stripe={stripePromise}
                      options={{
                        clientSecret,
                        appearance: {
                          theme: "night",
                          labels: "floating",
                        },
                        paymentMethodCreation: "manual",
                      }}
                    >
                      <PaymentMethodForm
                        onSubmit={
                          editingPaymentMethod
                            ? handleUpdatePaymentMethod
                            : handleAddPaymentMethod
                        }
                        onCancel={() => {
                          setShowAddPaymentForm(false);
                          setEditingPaymentMethod(null);
                        }}
                        editingPaymentMethod={editingPaymentMethod}
                      />
                    </Elements>
                  </Box>
                ) : (
                  <Dialog open={isLoadingClientSecret} disableEscapeKeyDown>
                    <DialogContent>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "20px",
                        }}
                      >
                        <CircularProgress style={{ marginBottom: "20px" }} />
                        <Typography>Initializing payment form...</Typography>
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
              </Box>
            </div>
          )}

          {activeTab === 1 && (
            <div role="tabpanel" id="tabpanel-1" aria-labelledby="tab-1">
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
                  <CircularProgress />
                </Box>
              ) : !hasPermission() ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                  You don't have permission to access this business's payment
                  dashboard.
                </Alert>
              ) : (
                <BusinessPaymentsDashboard businessId={businessId} />
              )}
            </div>
          )}

          {activeTab === 2 && (
            <div role="tabpanel" id="tabpanel-2" aria-labelledby="tab-2">
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
                  <CircularProgress />
                </Box>
              ) : !hasPermission() ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                  You don't have permission to access this business's payment
                  settings.
                </Alert>
              ) : (
                <BusinessPaymentSettings businessId={businessId} />
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default BusinessPaymentsPage;
