import React, { useState, useEffect, useCallback, useContext } from "react";
import { AuthContext } from "../../components/Auth/AuthContext";
import {
  CircularProgress,
  Alert,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle,
} from "@mui/icons-material";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
  FaCreditCard,
  FaCcJcb,
  FaCcDinersClub,
} from "react-icons/fa";
import AddCardIcon from "@mui/icons-material/AddCard";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { stripePromise } from "../../utils/stripe";

const PaymentMethodForm = ({ onSubmit, onCancel, editingPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const paymentElement = elements.getElement(PaymentElement);

    if (!paymentElement) {
      setError("Payment element not found");
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      return;
    }

    if (editingPaymentMethod) {
      // Handle update
      onSubmit({ id: editingPaymentMethod.id, update: true });
    } else {
      // Handle new payment method creation
      const { error, paymentMethod: stripePaymentMethod } =
        await stripe.createPaymentMethod({
          elements,
        });

      if (error) {
        setError(error.message);
      } else if (stripePaymentMethod) {
        onSubmit(stripePaymentMethod);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="security-form"
      style={{
        width: "100%",
        maxWidth: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <h3>
        {editingPaymentMethod
          ? "Edit Payment Method"
          : "Add New Payment Method"}
      </h3>
      <div className="form-row" style={{ marginBottom: "20px" }}>
        <PaymentElement
          options={{
            fields: {
              billingDetails: editingPaymentMethod ? "never" : "auto",
            },
            defaultValues: editingPaymentMethod
              ? {
                  billingDetails: {
                    name: editingPaymentMethod.billing_details.name,
                    email: editingPaymentMethod.billing_details.email,
                    phone: editingPaymentMethod.billing_details.phone,
                    address: editingPaymentMethod.billing_details.address,
                  },
                }
              : undefined,
            layout: {
              type: "accordion",
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: true,
            },
            style: {
              base: {
                fontSize: "16px",
                lineHeight: "1.5",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
            },
          }}
        />
      </div>
      {error && (
        <Alert severity="error" sx={{ my: 2, width: "100%" }}>
          {error}
        </Alert>
      )}
      <div
        className="form-actions"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <button onClick={onCancel} className="secondary-button" type="button">
          Cancel
        </button>
        <button type="submit" className="primary-button" disabled={!stripe}>
          {editingPaymentMethod
            ? "Update Payment Method"
            : "Add Payment Method"}
        </button>
      </div>
    </form>
  );
};

const PaymentMethodsList = ({
  paymentMethods,
  defaultPaymentMethodId,
  onSetDefault,
  onEdit,
  onRemove,
}) => {
  if (!paymentMethods || paymentMethods.length === 0) {
    return <Typography>No payment methods found.</Typography>;
  }

  const CardIcon = ({ brand }) => {
    const iconProps = { style: { fontSize: 32, marginRight: "15px" } };

    switch (brand.toLowerCase()) {
      case "visa":
        return <FaCcVisa {...iconProps} color="#1A1F71" />;
      case "mastercard":
        return <FaCcMastercard {...iconProps} color="#EB001B" />;
      case "amex":
        return <FaCcAmex {...iconProps} color="#2E77BC" />;
      case "discover":
        return <FaCcDiscover {...iconProps} color="#FF6000" />;
      case "jcb":
        return <FaCcJcb {...iconProps} color="#0E4C96" />;
      case "diners":
        return <FaCcDinersClub {...iconProps} color="#004A97" />;
      default:
        return <FaCreditCard {...iconProps} color="#6C757D" />;
    }
  };

  return (
    <div className="payment-methods-list" style={{ width: "100%" }}>
      {paymentMethods.map((method) => (
        <div
          key={method.id}
          className="payment-method-item"
          style={{
            borderRadius: "8px",
            marginBottom: "16px",
            padding: "16px",
            backgroundColor: "white",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <div className="payment-method-info">
            <CardIcon brand={method.card.brand} />
            <ListItemText
              primary={`${method.card.brand} **** ${method.card.last4}`}
              secondary={`Expires: ${method.card.exp_month}/${method.card.exp_year}`}
            />
          </div>
          <div className="payment-method-actions">
            {method.id === defaultPaymentMethodId ? (
              <Chip
                icon={<CheckCircle style={{ color: "white" }} />}
                label="Default"
                size="small"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
              />
            ) : (
              <button
                className="secondary-button"
                onClick={() => onSetDefault(method.id)}
                style={{
                  marginRight: "8px",
                  fontSize: "12px",
                  padding: "4px 8px",
                }}
              >
                <CheckCircleOutlineIcon style={{ fontSize: "16px" }} />
                Set as Default
              </button>
            )}
            <IconButton
              aria-label="edit"
              onClick={() => onEdit(method)}
              size="small"
              style={{ color: "var(--soft-blue)", marginLeft: "8px" }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => onRemove(method.id)}
              size="small"
              style={{ color: "var(--light-coral)", marginLeft: "8px" }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const PaymentsPage = () => {
  const { api, user } = useContext(AuthContext);
  const [showAddPaymentForm, setShowAddPaymentForm] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [editingPaymentMethod, setEditingPaymentMethod] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false);

  const handleEditPaymentMethod = (method) => {
    setEditingPaymentMethod(method);
    setShowAddPaymentForm(true);
  };

  const handleRemovePaymentMethod = async (paymentMethodId) => {
    setIsLoading(true);
    try {
      if (paymentMethodId === defaultPaymentMethodId) {
        const confirmRemove = window.confirm(
          "This is your default payment method. Are you sure you want to remove it? If you have other payment methods, a new one will be set as default."
        );
        if (!confirmRemove) {
          setIsLoading(false);
          return;
        }
      }

      const response = await api.delete(
        `/payments/payment-methods/${paymentMethodId}`
      );

      if (response.data.newDefaultPaymentMethodId) {
        setDefaultPaymentMethodId(response.data.newDefaultPaymentMethodId);
      }

      setReloadTrigger((prev) => prev + 1);
      setAlertMessage({
        type: "success",
        message: "Payment method removed successfully",
      });
    } catch (error) {
      console.error("Error removing payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleUpdatePaymentMethod = async (paymentMethod) => {
    setIsLoading(true);
    try {
      const response = await api.put(
        `/payments/payment-methods/${paymentMethod.id}`,
        {}
      );
      setReloadTrigger((prev) => prev + 1);
      setShowAddPaymentForm(false);
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
    } catch (error) {
      console.log("Error updating payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      setIsLoadingClientSecret(true);
      try {
        const response = await api.post("/payments/create-setup-intent", {});
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to initialize payment form. Please try again.",
        });
      } finally {
        setIsLoadingClientSecret(false);
      }
    };

    fetchClientSecret();
    fetchPaymentMethods();
  }, [reloadTrigger]);

  const fetchPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/payments/payment-methods", {});
      setPaymentMethods(response.data.paymentMethods);
      setDefaultPaymentMethodId(response.data.defaultPaymentMethodId);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  const handleAddPaymentMethod = async (paymentMethod) => {
    setIsLoading(true);

    const cardExists = paymentMethods.some(
      (existingMethod) =>
        existingMethod.card.last4 === paymentMethod.card.last4 &&
        existingMethod.card.brand === paymentMethod.card.brand &&
        existingMethod.card.exp_month === paymentMethod.card.exp_month &&
        existingMethod.card.exp_year === paymentMethod.card.exp_year
    );

    if (cardExists) {
      setAlertMessage({
        type: "error",
        message: "This card has already been added to your account.",
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post("/payments/payment-methods", {
        paymentMethodId: paymentMethod.id,
      });
      setReloadTrigger((prev) => prev + 1);
      setShowAddPaymentForm(false);
      setAlertMessage({
        type: "success",
        message: response.data.message,
      });
    } catch (error) {
      console.log("Error adding payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;

      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }

    setIsLoading(false);
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
    setIsLoading(true);
    try {
      await api.post("/payments/set-default-payment-method", {
        paymentMethodId,
      });
      setReloadTrigger((prev) => prev + 1);
      setAlertMessage({
        type: "success",
        message: "Default payment method set successfully",
      });
    } catch (error) {
      console.log("Error setting default payment method:", error);
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message;
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="user-page-wrapper">
      <div className="user-page-content-narrow">
        <h1 className="user-page-title">Payment Methods</h1>

        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            variant="outlined"
            style={{ marginBottom: "16px", width: "100%" }}
            onClose={() => setAlertMessage(null)}
          >
            {alertMessage.message}
          </Alert>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {isLoading ? (
            <div className="user-loading">
              <CircularProgress />
            </div>
          ) : (
            <PaymentMethodsList
              paymentMethods={paymentMethods}
              defaultPaymentMethodId={defaultPaymentMethodId}
              onSetDefault={handleSetDefaultPaymentMethod}
              onEdit={handleEditPaymentMethod}
              onRemove={handleRemovePaymentMethod}
            />
          )}

          {!showAddPaymentForm ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <button
                className="primary-button"
                onClick={() => {
                  setEditingPaymentMethod(null);
                  setShowAddPaymentForm(true);
                }}
              >
                <AddCardIcon style={{ fontSize: "16px" }} />
                Add New Payment Method
              </button>
            </div>
          ) : isLoadingClientSecret ? (
            <div className="user-loading" style={{ padding: "40px" }}>
              <CircularProgress />
            </div>
          ) : clientSecret ? (
            <div
              style={{ width: "100%", marginTop: "20px", padding: "0 10px" }}
            >
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret,
                  appearance: {
                    theme: "flat",
                    labels: "floating",
                    variables: {
                      colorPrimary: "#3498db",
                      colorBackground: "#ffffff",
                      colorText: "#2c3e50",
                      colorDanger: "#e74c3c",
                      fontFamily: "Roboto, sans-serif",
                      spacingUnit: "4px",
                      borderRadius: "8px",
                    },
                  },
                  paymentMethodCreation: "manual",
                }}
              >
                <PaymentMethodForm
                  onSubmit={
                    editingPaymentMethod
                      ? handleUpdatePaymentMethod
                      : handleAddPaymentMethod
                  }
                  onCancel={() => {
                    setShowAddPaymentForm(false);
                    setEditingPaymentMethod(null);
                  }}
                  editingPaymentMethod={editingPaymentMethod}
                />
              </Elements>
            </div>
          ) : (
            <Dialog open={isLoadingClientSecret} disableEscapeKeyDown>
              <DialogContent>
                <div className="user-loading">
                  <CircularProgress style={{ marginBottom: "20px" }} />
                  <Typography>Initializing payment form...</Typography>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentsPage;
