import React, { useState, useEffect, useContext } from "react";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  Link as MuiLink,
} from "@mui/material";
import { Visibility as PreviewIcon } from "@mui/icons-material";
import { Print as PrintIcon } from "@mui/icons-material";
import { QRCodeSVG } from "qrcode.react";
import { useParams } from "react-router-dom";
import html2pdf from "../../../../utils/patchedHtml2pdf";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import qz from "qz-tray"; // If QZ Tray printing is needed
import {
  getCategorySettings,
  updatePrintSettings,
  savePrintSettings,
} from "./PrintSettings";

const shortenName = (name, maxLen = 12) =>
  name?.length > maxLen ? name.slice(0, maxLen) + "…" : name || "";

const ItemPreviewButton = ({ item, onCodesUpdated }) => {
  const [open, setOpen] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState(null);
  const { businessId } = useParams();
  const { api } = useContext(AuthContext);

  // For the "no printer" Snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState(null);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    setSnackbarContent(null);
  };

  const [labelSettings, setLabelSettings] = useState(() =>
    getCategorySettings(businessId, "individualItemLabels")
  );
  const [qrCodeValue, setQrCodeValue] = useState(item.package_item_id || []);

  // Keep a local printedCodes in state, synced with parent’s item
  const [printedCodes, setPrintedCodes] = useState(item.printed_codes || []);
  useEffect(() => {
    setPrintedCodes(item.printed_codes || []);
  }, [item.printed_codes]);

  const handlePrintError = (err, businessId) => {
    if (!err || !err.message) return "Unknown printing error.";
    if (err.message.includes("QZ Tray")) {
      return "Error connecting to printer. Ensure QZ Tray is running.";
    }
    if (
      err.message.includes("printer") ||
      err.message.includes("settings") ||
      err.message.includes("Not Found")
    ) {
      return `Printer not configured. Check your settings at /business/${businessId}/settings/printing-settings`;
    }
    return err.message;
  };

  const onSaveSettings = () => {
    try {
      const updated = savePrintSettings(
        businessId,
        "individualItemLabels",
        labelSettings
      );
      setLabelSettings(updated.individualItemLabels);
    } catch (error) {
      setError("Failed to save label settings");
      setShowSnackbar(true);
    }
  };

  const handleSettingsChange = (field, value) => {
    const updated = updatePrintSettings(businessId, "individualItemLabels", {
      ...labelSettings,
      [field]: value,
    });
    setLabelSettings(updated.individualItemLabels);
  };

  const handleFontSizeChange = (fontType, value) => {
    const updated = updatePrintSettings(businessId, "individualItemLabels", {
      ...labelSettings,
      fontSizes: {
        ...labelSettings.fontSizes,
        [fontType]: Number(value),
      },
    });
    setLabelSettings(updated.individualItemLabels);
  };

  // Helper to parse printers from localStorage
  const getPrinter = () => {
    const printers = localStorage.getItem(`printers_${businessId}`);
    if (!printers) return null;
    try {
      return JSON.parse(printers).individualItemLabels;
    } catch (err) {
      console.error("Error parsing printer info:", err);
      return null;
    }
  };

  const doPrintLogic = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    const qzPrinter = getPrinter();
    const previewElement = document.getElementById("item-label-preview");
    if (!previewElement) {
      throw new Error("Item label preview element not found");
    }

    // Build offscreen wrapper
    const wrapper = document.createElement("div");
    const [inWidth, inHeight] = labelSettings.size.split("x").map(Number);
    const DPI = 96;
    const width = inWidth * DPI;
    const height = inHeight * DPI;

    wrapper.style.width = `${width}px`;
    wrapper.style.height = `${height}px`;
    wrapper.style.background = "white";
    wrapper.style.position = "relative";
    wrapper.style.overflow = "hidden";

    const clone = previewElement.cloneNode(true);
    clone.style.position = "absolute";
    clone.style.display = "block";
    clone.style.background = "white";
    clone.style.color = "black";
    clone.style.width = "100%";
    clone.style.height = "100%";
    wrapper.appendChild(clone);
    document.body.appendChild(wrapper);

    // Generate PDF
    let pdfBlob;
    try {
      pdfBlob = await html2pdf()
        .set({
          margin: 0,
          filename: "item_label.pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: {
            scale: 4,
            backgroundColor: "#FFFFFF",
            width: wrapper.offsetWidth,
            height: wrapper.offsetHeight,
            useCORS: true,
          },
          jsPDF: {
            unit: "in",
            format: [width / DPI, height / DPI],
            orientation: labelSettings.orientation || "portrait",
            compress: true,
          },
        })
        .from(wrapper)
        .outputPdf("blob");
    } finally {
      document.body.removeChild(wrapper);
    }

    // Show PDF in new tab
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const printWindow = window.open(pdfUrl, "_blank");
    if (printWindow) {
      printWindow.document.close();
      printWindow.focus();
      // optionally printWindow.print();
    }

    // Attempt QZ Tray printing
    if (qzPrinter) {
      if (!qz.websocket.isActive()) {
        await qz.websocket.connect();
      }
      const pdfBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(pdfBlob);
      });

      const config = qz.configs.create(qzPrinter, {
        size: { width: inWidth, height: inHeight },
        units: "in",
        orientation: labelSettings.orientation,
        density: "best",
        interpolation: "nearest-neighbor",
        margins: { top: 0, right: 0, bottom: 0, left: 0 },
        rasterize: "false",
      });

      await qz.print(config, [
        {
          type: "pixel",
          format: "pdf",
          flavor: "base64",
          data: pdfBase64,
        },
      ]);
    } else {
      // Show a Snackbar if no printer is set
      setSnackbarContent(
        <Box>
          No printer configured. Configure your printer in{" "}
          <MuiLink
            href={`/business/${businessId}/settings/printing-settings`}
            target="_blank"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "white",
              "&:hover": { color: "rgba(255, 255, 255, 0.8)" },
            }}
          >
            Printing Settings
          </MuiLink>
        </Box>
      );
      setShowSnackbar(true);
    }
  };

  const handleReprint = async (code) => {
    try {
      setIsPrinting(true);
      setError(null);
      setQrCodeValue(code);
      await doPrintLogic();
    } catch (err) {
      const msg = handlePrintError(err, businessId);
      setError(msg);
      setSnackbarContent(msg);
      setShowSnackbar(true);
    } finally {
      setIsPrinting(false);
    }
  };

  const handleGenerateNewLabel = async (count = 1) => {
    try {
      setIsPrinting(true);
      setError(null);

      if (printedCodes.length >= item.max_labels) {
        setSnackbarContent(
          "Max labels already generated. Cannot print more, please reprint existing labels."
        );
        setShowSnackbar(true);
        setIsPrinting(false);
        return;
      }

      const response = await api.post(
        `/verify-package/items/${item.package_item_id}/labels`,
        { count }
      );
      const data = response.data;
      if (!data.success) {
        throw new Error(data.msg || "Failed to generate new labels.");
      }

      if (Array.isArray(data.codes) && data.codes.length > 0) {
        // Merge into local state
        const newCodes = data.codes;
        setPrintedCodes((prev) => {
          const updated = [...prev, ...newCodes];
          // notify parent if needed
          onCodesUpdated?.(updated);
          return updated;
        });

        // Automatically print the last new code
        const lastCode = newCodes[newCodes.length - 1].code;
        setQrCodeValue(lastCode);
        await doPrintLogic();
      } else {
        throw new Error("No codes returned from server.");
      }
    } catch (err) {
      const msg = handlePrintError(err, businessId);
      setError(msg);
      setSnackbarContent(msg);
      setShowSnackbar(true);
    } finally {
      setIsPrinting(false);
    }
  };

  const handlePrintNewLabel = () => {
    handleGenerateNewLabel(1);
  };

  return (
    <>
      <Tooltip title="Preview Item Label">
        <IconButton onClick={() => setOpen(true)} size="small">
          <PreviewIcon />
        </IconButton>
      </Tooltip>

      {/* Dialog for preview & printing */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { minHeight: "80vh" } }}
      >
        <DialogTitle sx={{ color: "black" }}>Item Label Preview</DialogTitle>
        <DialogContent sx={{ color: "black" }}>
          {/* Label Print Settings */}
          <Box
            sx={{
              mb: 3,
              p: 2,
              border: "1px solid black",
              borderRadius: 1,
            }}
          >
            <Typography variant="h6" sx={{ color: "black", mb: 1 }}>
              Label Print Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "black" }}>Label Size</InputLabel>
                  <Select
                    value={labelSettings.size}
                    label="Label Size"
                    onChange={(e) =>
                      handleSettingsChange("size", e.target.value)
                    }
                    sx={{ color: "black" }}
                  >
                    {["2x2", "3x3", "4x4", "4x6"].map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ color: "black" }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "black" }}>Orientation</InputLabel>
                  <Select
                    value={labelSettings.orientation}
                    label="Orientation"
                    onChange={(e) =>
                      handleSettingsChange("orientation", e.target.value)
                    }
                    sx={{ color: "black" }}
                  >
                    <MenuItem value="portrait" sx={{ color: "black" }}>
                      Portrait
                    </MenuItem>
                    <MenuItem value="landscape" sx={{ color: "black" }}>
                      Landscape
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Font sizes */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Title Font Size"
                  type="number"
                  value={labelSettings.fontSizes.title}
                  onChange={(e) =>
                    handleFontSizeChange("title", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 32 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Details Font Size"
                  type="number"
                  value={labelSettings.fontSizes.details}
                  onChange={(e) =>
                    handleFontSizeChange("details", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 24 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              {/* QR code size */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="QR Code Size"
                  type="number"
                  value={labelSettings.qrCodeSize}
                  onChange={(e) =>
                    handleSettingsChange("qrCodeSize", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 40, max: 400 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={onSaveSettings}
                sx={{ color: "black" }}
              >
                Save Label Settings
              </Button>
            </Box>
          </Box>

          {/* Existing Labels */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ color: "black", mb: 1 }}>
              Existing Labels ({printedCodes.length}/{item.max_labels})
            </Typography>
            {printedCodes.length === 0 ? (
              <Typography variant="body2" sx={{ color: "black" }}>
                No labels have been generated for this item yet.
              </Typography>
            ) : (
              <List dense>
                {printedCodes.map((labelObj, index) => (
                  <ListItem
                    key={labelObj.code}
                    secondaryAction={
                      <Button
                        onClick={() => handleReprint(labelObj.code)}
                        variant="outlined"
                        size="small"
                      >
                        Reprint
                      </Button>
                    }
                  >
                    <ListItemText
                      primary={`Label #${index + 1}: ${labelObj.code}`}
                      secondary={
                        labelObj.printed_at
                          ? `Printed: ${new Date(
                              labelObj.printed_at
                            ).toLocaleString()}`
                          : "Not recorded"
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
          <Divider sx={{ mb: 2 }} />

          {/* Label Preview Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "20vh",
            }}
          >
            <Box
              id="item-label-preview"
              sx={{
                border: "1px solid black",
                // read the size from settings
                width: `${labelSettings.size.split("x")[0]}in`,
                height: `${labelSettings.size.split("x")[1]}in`,
                backgroundColor: "white",
                color: "black",
                margin: "0 auto",
                position: "relative",
                overflow: "visible",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  textAlign: "center",
                  mb: 1,
                  fontWeight: "bold",
                }}
              >
                {new Date().toLocaleDateString()}
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <QRCodeSVG
                  value={qrCodeValue}
                  size={labelSettings.qrCodeSize}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  includeMargin={false}
                />
              </Box>
              <Typography
                sx={{
                  color: "black",
                  fontSize: labelSettings.fontSizes.title,
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 1,
                }}
              >
                {shortenName(item.name)}
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontSize: labelSettings.fontSizes.details,
                  mt: 0.5,
                  textAlign: "center",
                }}
              >
                QTY: {item.quantity}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() =>
              window.open(
                `/business/${businessId}/settings/printing-settings`,
                "_blank"
              )
            }
          >
            Label Settings Page
          </Button>

          <Button
            onClick={handlePrintNewLabel}
            variant="contained"
            startIcon={
              isPrinting ? <CircularProgress size={20} /> : <PrintIcon />
            }
            disabled={isPrinting || printedCodes.length >= item.max_labels}
            sx={{ backgroundColor: "black", color: "white" }}
          >
            {isPrinting ? "Printing..." : "Generate & Print New Label"}
          </Button>

          <Button onClick={() => setOpen(false)} sx={{ color: "black" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for "no printer" or other messages */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: "100%", backgroundColor: "black", color: "white" }}
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ItemPreviewButton;
