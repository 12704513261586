import React, { useState, useCallback, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  Timeline,
  Assessment,
  Description,
  TrendingUp,
  TrendingDown,
  Warning,
  CheckCircle,
  BarChart as BarChartIcon,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import PerformanceMetricsCard from "./components/PerformanceMetricsCard";
import PerformanceAlertsCard from "./components/PerformanceAlertsCard";
import ReportCard from "./components/ReportCard";
import "./BusinessAnalyticsPage.css";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
];

// Skeleton components for loading states
const MetricsCardSkeleton = () => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="circular" width={24} height={24} />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <Skeleton variant="text" width="40%" height={40} />
      </Box>
    </CardContent>
  </Card>
);

const ChartSkeleton = ({ height = 300 }) => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardHeader title={<Skeleton variant="text" width="60%" height={30} />} />
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, minHeight: height }}>
        <Skeleton variant="rectangular" width="100%" height="100%" sx={{ minHeight: height }} />
      </Box>
    </CardContent>
  </Card>
);

const AlertsCardSkeleton = () => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
      <Box sx={{ flexGrow: 1, minHeight: 120 }}>
        <Skeleton variant="rectangular" width="100%" height="100%" sx={{ minHeight: 120 }} />
      </Box>
    </CardContent>
  </Card>
);

const ReportCardSkeleton = () => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardHeader
      avatar={<Skeleton variant="circular" width={40} height={40} />}
      title={<Skeleton variant="text" width="60%" />}
      subheader={<Skeleton variant="text" width="40%" />}
      action={<Skeleton variant="rectangular" width={80} height={32} />}
    />
    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, minHeight: 100 }}>
        <Skeleton variant="rectangular" width="100%" height="100%" sx={{ minHeight: 100 }} />
      </Box>
    </CardContent>
  </Card>
);

const PerformanceSkeletonLoader = () => (
  <Box className="performance-dashboard" sx={{ width: '100%' }}>
    <Grid container spacing={3} sx={{ mb: 4 }}>
      {[...Array(4)].map((_, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <MetricsCardSkeleton />
        </Grid>
      ))}
    </Grid>

    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12}>
        <AlertsCardSkeleton />
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <ChartSkeleton height={300} />
      </Grid>

      <Grid item xs={12} md={4}>
        <ChartSkeleton height={300} />
      </Grid>

      <Grid item xs={12}>
        <ChartSkeleton height={300} />
      </Grid>
    </Grid>
  </Box>
);

const ReportsSkeletonLoader = () => (
  <Box className="reports-dashboard" sx={{ width: '100%' }}>
    <Grid container spacing={3}>
      {[...Array(4)].map((_, index) => (
        <Grid item xs={12} md={6} key={index}>
          <ReportCardSkeleton />
        </Grid>
      ))}
    </Grid>
  </Box>
);

const BusinessAnalyticsPage = () => {
  const { api } = useContext(AuthContext);

  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();

  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });

  const [currentTab, setCurrentTab] = useState(0);
  const [timeframe, setTimeframe] = useState("30days");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [performanceData, setPerformanceData] = useState(null);
  const [reports, setReports] = useState([]);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        console.log(`Fetching business data for ID: ${businessId}`);
        // Fetch business data
        const business = getBusinessById(businessId);
        console.log("Business data:", business);

        setCurrentBusiness(business);

        // Save business data to localStorage
        localStorage.setItem(
          `business_${businessId}`,
          JSON.stringify(business)
        );
      } catch (err) {
        console.error("Error fetching business data:", err);
        setError(err.response?.data?.message || err.message);
      }
    };

    if (businessId) {
      fetchBusinessData();
    } else {
      setError("No business ID provided");
    }
  }, [businessId, getBusinessById]);

  const fetchPerformanceData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      setNoData(false);
      console.log(
        `Fetching performance data for business ${businessId} with timeframe ${timeframe}`
      );

      const response = await api.get(
        `/business-analytics/${businessId}/performance?period=${timeframe}`
      );

      console.log("Performance data response:", response.data);

      if (response.data.success) {
        const data = response.data.data;
        setPerformanceData(data);

        // Check if there's no data (orders) available
        if (data.summary.totalOrders === 0) {
          setNoData(true);
        }
      } else {
        setError(response.data.message || "Failed to fetch performance data");
      }
    } catch (err) {
      console.error("Error fetching performance data:", err);
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [api, businessId, timeframe]);

  const fetchReports = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      setNoData(false);
      console.log(
        `Fetching reports for business ${businessId} with timeframe ${timeframe}`
      );

      const response = await api.get(
        `/business-analytics/${businessId}/reports?period=${timeframe}`
      );

      console.log("Reports response:", response.data);

      if (response.data.success) {
        const fetchedReports = response.data.data.reports;
        setReports(fetchedReports);

        // Check if there's no reports available
        if (fetchedReports.length === 0) {
          setNoData(true);
        } else {
          // Check if the reports have empty data
          const hasData = fetchedReports.some((report) => {
            if (report.type === "sales") {
              return report.data.totalOrders > 0;
            } else if (report.type === "customers") {
              return report.data.totalCustomers > 0;
            }
            return true;
          });

          setNoData(!hasData);
        }
      } else {
        setError(response.data.message || "Failed to fetch reports");
      }
    } catch (err) {
      console.error("Error fetching reports:", err);
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [api, businessId, timeframe]);

  useEffect(() => {
    if (!currentBusiness) {
      console.warn("No current business data available for analytics");
      return;
    }

    if (currentTab === 0) {
      fetchPerformanceData();
    } else if (currentTab === 1) {
      fetchReports();
    }
  }, [currentTab, fetchPerformanceData, fetchReports, currentBusiness]);

  // No longer need handleSidebarStateChange as we're using context

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const renderNoDataMessage = () => (
    <Box sx={{ my: 4, textAlign: "center" }}>
      <BarChartIcon sx={{ fontSize: 60, color: "text.secondary", mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        No Data Available
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        There are no shipping orders for this business during the selected
        timeframe.
      </Typography>
      <Typography variant="body2" paragraph>
        Try selecting a different timeframe or create some shipping orders to
        see analytics.
      </Typography>
    </Box>
  );

  return (
    <div className="business-page business-analytics-page">
      <main className="business-page-content">
        <div className="business-page-header">
          <Typography variant="h4" gutterBottom>
            Business Analytics & Reports
          </Typography>
          <FormControl variant="outlined" sx={{ minWidth: 150, mr: 2, ml: 2 }}>
            <InputLabel>Timeframe</InputLabel>
            <Select
              value={timeframe}
              onChange={handleTimeframeChange}
              label="Timeframe"
            >
              <MenuItem value="7days">Last 7 Days</MenuItem>
              <MenuItem value="30days">Last 30 Days</MenuItem>
              <MenuItem value="90days">Last 90 Days</MenuItem>
              <MenuItem value="12months">Last 12 Months</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="analytics tabs"
          >
            <Tab
              icon={<Assessment />}
              label="Performance"
              iconPosition="start"
            />
            <Tab icon={<Description />} label="Reports" iconPosition="start" />
          </Tabs>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                currentTab === 0 ? fetchPerformanceData() : fetchReports()
              }
              sx={{ ml: 2 }}
            >
              Retry
            </Button>
          </Alert>
        )}

        {!currentBusiness && !error && (
          <Alert severity="info" sx={{ mb: 2 }}>
            No business data available. Please make sure you have selected a
            valid business.
          </Alert>
        )}

        {loading ? (
          // Skeleton loading UI based on current tab
          currentTab === 0 ? (
            <PerformanceSkeletonLoader />
          ) : (
            <ReportsSkeletonLoader />
          )
        ) : (
          <>
            {noData ? (
              renderNoDataMessage()
            ) : (
              <>
                {currentTab === 0 && performanceData && (
                  // Performance Dashboard
                  <Box className="performance-dashboard">
                    {/* Key Metrics Summary */}
                    <Grid container spacing={3} sx={{ mb: 4 }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{ height: '100%' }}>
                          <PerformanceMetricsCard
                            title="Total Orders"
                            value={performanceData.summary.totalOrders}
                            icon={<Assessment color="primary" />}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{ height: '100%' }}>
                          <PerformanceMetricsCard
                            title="Total Revenue"
                            value={"$" + parseFloat(performanceData.summary.totalRevenue).toLocaleString()}
                            icon={<TrendingUp color="success" />}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{ height: '100%' }}>
                          <PerformanceMetricsCard
                            title="On-Time Delivery"
                            value={`${performanceData.summary.onTimeDeliveryRate}%`}
                            icon={<CheckCircle color="info" />}
                            status={
                              performanceData.summary.onTimeDeliveryRate >= 90
                                ? "success"
                                : "warning"
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box sx={{ height: '100%' }}>
                          <PerformanceMetricsCard
                            title="Customer Satisfaction"
                            value={performanceData.summary.customerSatisfaction}
                            subtext="/5"
                            icon={<TrendingUp color="success" />}
                            status={
                              performanceData.summary.customerSatisfaction >= 4
                                ? "success"
                                : "warning"
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    {/* Performance Alerts */}
                    {performanceData.alerts &&
                      performanceData.alerts.length > 0 && (
                        <Grid container spacing={3} sx={{ mb: 4 }}>
                          <Grid item xs={12}>
                            <Box sx={{ height: '100%' }}>
                              <PerformanceAlertsCard
                                alerts={performanceData.alerts}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      )}

                    {/* Data Visualizations */}
                    {performanceData.intervalData &&
                      performanceData.intervalData.length > 0 && (
                        <Grid container spacing={3}>
                          {/* Orders & Revenue Chart */}
                          <Grid item xs={12} md={8}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <CardHeader title="Orders & Revenue Over Time" />
                              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
                                  <ResponsiveContainer
                                    width="100%"
                                    height="100%"
                                  >
                                    <LineChart
                                      data={performanceData.intervalData}
                                      margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                      }}
                                    >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis
                                        dataKey="date"
                                        angle={-45}
                                        textAnchor="end"
                                        height={70}
                                      />
                                      <YAxis yAxisId="left" />
                                      <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                      />
                                      <Tooltip />
                                      <Legend />
                                      <Line
                                        yAxisId="left"
                                        type="monotone"
                                        dataKey="orders"
                                        name="Orders"
                                        stroke="#8884d8"
                                        activeDot={{ r: 8 }}
                                      />
                                      <Line
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="revenue"
                                        name="Revenue ($)"
                                        stroke="#82ca9d"
                                      />
                                    </LineChart>
                                  </ResponsiveContainer>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>

                          {/* Order Status Distribution */}
                          <Grid item xs={12} md={4}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <CardHeader title="Order Status" />
                              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
                                  <ResponsiveContainer
                                    width="100%"
                                    height="100%"
                                  >
                                    <PieChart>
                                      <Pie
                                        data={[
                                          {
                                            name: "Pending",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.pending,
                                          },
                                          {
                                            name: "Processing",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown
                                                .processing,
                                          },
                                          {
                                            name: "Shipped",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.shipped,
                                          },
                                          {
                                            name: "Out for Delivery",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown
                                                .outForDelivery,
                                          },
                                          {
                                            name: "Delivered",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.delivered,
                                          },
                                          {
                                            name: "Cancelled",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.cancelled,
                                          },
                                        ]}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={({ name, percent }) =>
                                          `${name}: ${(percent * 100).toFixed(
                                            0
                                          )}%`
                                        }
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="value"
                                      >
                                        {[
                                          {
                                            name: "Pending",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.pending,
                                          },
                                          {
                                            name: "Processing",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown
                                                .processing,
                                          },
                                          {
                                            name: "Shipped",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.shipped,
                                          },
                                          {
                                            name: "Out for Delivery",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown
                                                .outForDelivery,
                                          },
                                          {
                                            name: "Delivered",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.delivered,
                                          },
                                          {
                                            name: "Cancelled",
                                            value:
                                              performanceData
                                                .orderStatusBreakdown.cancelled,
                                          },
                                        ].map((entry, index) => (
                                          <Cell
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                          />
                                        ))}
                                      </Pie>
                                      <Tooltip
                                        formatter={(value) => `${value} orders`}
                                      />
                                    </PieChart>
                                  </ResponsiveContainer>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>

                          {/* On-Time Delivery Performance */}
                          <Grid item xs={12}>
                            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <CardHeader title="On-Time Delivery Performance" />
                              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
                                  <ResponsiveContainer
                                    width="100%"
                                    height="100%"
                                  >
                                    <BarChart
                                      data={performanceData.intervalData}
                                      margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                      }}
                                    >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis
                                        dataKey="date"
                                        angle={-45}
                                        textAnchor="end"
                                        height={70}
                                      />
                                      <YAxis
                                        yAxisId="left"
                                        label={{
                                          value: "Order Count",
                                          angle: -90,
                                          position: "insideLeft",
                                        }}
                                      />
                                      <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        domain={[0, 100]}
                                        label={{
                                          value: "On-Time %",
                                          angle: 90,
                                          position: "insideRight",
                                        }}
                                      />
                                      <Tooltip />
                                      <Legend />
                                      <Bar
                                        yAxisId="left"
                                        dataKey="delivered"
                                        name="Delivered"
                                        fill="#8884d8"
                                      />
                                      <Bar
                                        yAxisId="left"
                                        dataKey="onTime"
                                        name="On Time"
                                        fill="#82ca9d"
                                      />
                                      <Line
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="onTimeRate"
                                        name="On-Time %"
                                        stroke="#ff7300"
                                      />
                                    </BarChart>
                                  </ResponsiveContainer>
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      )}
                  </Box>
                )}

                {currentTab === 1 && reports && reports.length > 0 && (
                  // Reports Dashboard
                  <Box className="reports-dashboard">
                    <Grid container spacing={3}>
                      {reports.map((report) => (
                        <Grid item xs={12} md={6} key={report.id}>
                          <ReportCard report={report} />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default BusinessAnalyticsPage;
