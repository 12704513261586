import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  styled,
  Alert,
  TextField,
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import styles from "./EmployeeOnboarding.module.css";
import { AuthContext } from "../../../components/Auth/AuthContext";
import axios from "axios";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessageContainer = styled("div")({
  color: "#856404",
  backgroundColor: "#fff3cd",
  padding: "15px",
  borderRadius: "5px",
  border: "1px solid #ffeeba",
  marginTop: "15px",
  marginBottom: "15px",
  textAlign: "left",
  width: "100%",
  boxSizing: "border-box",
});

const RoleChip = styled("span")({
  display: "inline-block",
  padding: "2px 8px",
  margin: "0 4px",
  backgroundColor: "#e3f2fd",
  color: "#1976d2",
  borderRadius: "12px",
  fontSize: "0.9em",
});

const PasswordStrengthIndicator = styled("div")(({ theme, strength }) => ({
  height: "4px",
  borderRadius: "2px",
  marginTop: "8px",
  marginBottom: "4px",
  background: 
    strength === 0 ? "#e0e0e0" :
    strength === 1 ? "#f44336" : // weak
    strength === 2 ? "#ff9800" : // medium
    strength === 3 ? "#4caf50" : // strong
    "#2e7d32", // very strong
  width: "100%",
}));

const PasswordRequirement = styled(FormHelperText)(({ theme, isMet }) => ({
  color: isMet ? theme.palette.success.main : theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  margin: "2px 0",
  "& svg": {
    marginRight: theme.spacing(0.5),
    fontSize: "0.875rem",
  },
}));

const EmployeeOnboarding = () => {
  // Create direct API instance outside of auth context to avoid redirect loops
  const directApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { login, isProfileComplete, user, loading } = useContext(AuthContext);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState({
    business_name: "",
    employee_roles: [],
  });
  const [userData, setUserData] = useState({
    user_email: "",
    user_password: "",
    confirm_password: "",
  });
  const [accountType, setAccountType] = useState(0); // 0 for existing, 1 for new
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    noCommonPatterns: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  // Fetch employee data on component mount
  useEffect(() => {
    const fetchEmployeeData = async (token) => {
      setErrorMessages([]);
      try {
        const response = await directApi.get(`/employees/onboarding/${token}`);
        setEmployee({
          business_name: response.data.employee.business_name,
          employee_roles: response.data.employee.employee_roles || [],
        });
      } catch (error) {
        console.error("Error fetching employee data:", error);

        if (error.response?.status === 401) {
          setErrorMessages([
            "Invalid or expired invitation link. Please contact your employer.",
          ]);
        } else {
          setErrorMessages([
            "Failed to load invitation details. Please try again later.",
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    const token = location.pathname.split("/").pop();
    if (token) {
      fetchEmployeeData(token);
    } else {
      setErrorMessages(["Invitation link is invalid. Missing token."]);
      setIsLoading(false);
    }
  }, [location.pathname]);

  // Check password strength and requirements
  useEffect(() => {
    if (accountType === 1) {
      const password = userData.user_password;
      
      // Check requirements
      const requirements = {
        length: password.length >= 12,
        lowercase: /[a-z]/.test(password),
        uppercase: /[A-Z]/.test(password),
        number: /\d/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        noCommonPatterns: !/(password|12345|qwerty|admin|login|welcome|letmein|abc123)/i.test(password),
      };
      
      setPasswordRequirements(requirements);
      
      // Calculate strength (0-4)
      let strength = 0;
      if (password.length > 0) {
        strength = 1; // Start with weak
        
        // Count how many requirements are met
        const metRequirements = Object.values(requirements).filter(Boolean).length;
        
        if (metRequirements >= 3) strength = 2; // Medium
        if (metRequirements >= 5) strength = 3; // Strong
        if (metRequirements === 6 && password.length >= 14) strength = 4; // Very strong
      }
      
      setPasswordStrength(strength);
    }
  }, [userData.user_password, accountType]);

  const handleUserDataChange = (field, value) => {
    setUserData((prev) => ({ ...prev, [field]: value }));

    // Clear password errors when field changes
    if (field === "user_password" || field === "confirm_password") {
      setErrorMessages((prev) =>
        prev.filter(
          (msg) => !msg.includes("Password") && !msg.includes("password")
        )
      );
    }

    if (field === "user_email") {
      setErrorMessages((prev) =>
        prev.filter((msg) => !msg.includes("email") && !msg.includes("Email"))
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setAccountType(newValue);
    // Reset form errors when switching tabs
    setErrorMessages([]);
    // Keep the email but reset password fields
    setUserData({
      user_email: userData.user_email,
      user_password: "",
      confirm_password: "",
    });
    setPasswordStrength(0);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = [];

    // Email validation
    if (!userData.user_email) {
      errors.push("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(userData.user_email)) {
      errors.push("Email address is invalid");
    }

    // Password validation - Different rules for signup vs login
    if (!userData.user_password) {
      errors.push("Password is required");
    } else if (accountType === 1) {
      // New account - perform stronger validation based on requirements
      if (!passwordRequirements.length) {
        errors.push("Password must be at least 12 characters long");
      }
      if (!passwordRequirements.lowercase) {
        errors.push("Password must contain at least one lowercase letter");
      }
      if (!passwordRequirements.uppercase) {
        errors.push("Password must contain at least one uppercase letter");
      }
      if (!passwordRequirements.number) {
        errors.push("Password must contain at least one number");
      }
      if (!passwordRequirements.special) {
        errors.push("Password must contain at least one special character");
      }
      if (!passwordRequirements.noCommonPatterns) {
        errors.push("Password contains common patterns that are easily guessed");
      }
    } else if (userData.user_password.length < 8) {
      // Existing account - basic length requirement only
      errors.push("Password must be at least 8 characters long");
    }

    // Confirm password for new accounts
    if (accountType === 1) {
      if (!userData.confirm_password) {
        errors.push("Please confirm your password");
      } else if (userData.user_password !== userData.confirm_password) {
        errors.push("Passwords do not match");
      }
    }

    setErrorMessages(errors);
    return errors.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    setErrorMessages([]);

    try {
      const token = location.pathname.split("/").pop();

      // Add account type to the request
      const dataToSend = {
        ...userData,
        account_type: accountType === 0 ? "existing" : "new",
      };

      console.log("Submitting onboarding data:", dataToSend);

      const response = await directApi.post(
        `/employees/onboarding/${token}`,
        dataToSend
      );

      // Display success message
      setAlertMessage({
        type: "success",
        message: response.data.msg,
      });

      // If token was returned, use it for authentication
      if (response.data.token) {
        // Store token in localStorage
        localStorage.setItem("accessToken", response.data.token);

        // Remove any lingering tokens for security
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("rememberMeToken");

        // Pause to show the success message
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Use the login function or handle manually
        if (accountType === 0) {
          // For existing users, use the login function
          try {
            await login(userData.user_email, userData.user_password, false);

            // Redirect based on profile completion
            if (response.data.profileComplete) {
              navigate("/dashboard");
            } else {
              navigate("/complete-profile");
            }
          } catch (loginError) {
            console.error("Error during post-onboarding login:", loginError);
            navigate("/auth");
          }
        } else {
          // For new users, we already have the token, redirect based on profile status
          if (response.data.profileComplete) {
            navigate("/dashboard");
          } else {
            navigate("/complete-profile");
          }
        }
      }
    } catch (error) {
      console.error("Error during onboarding:", error);
      if (error.response?.status === 401) {
        setErrorMessages(["Invalid email or password. Please try again."]);
      } else if (error.response?.status === 429) {
        // Rate limiting error
        setErrorMessages(["Too many login attempts. Please try again later."]);
      } else if (error.response?.status === 409) {
        setErrorMessages([
          error.response.data.msg || "Account with this email already exists.",
        ]);
      } else if (error.response?.data?.msg) {
        setErrorMessages([error.response.data.msg]);
      } else if (
        error.response?.data?.errors &&
        Array.isArray(error.response.data.errors)
      ) {
        // Multiple validation errors from the server
        setErrorMessages(
          error.response.data.errors.map((err) => err.msg || err)
        );
      } else {
        setErrorMessages([
          "An error occurred during onboarding. Please try again.",
        ]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPasswordStrengthLabel = () => {
    if (passwordStrength === 0) return "";
    if (passwordStrength === 1) return "Weak";
    if (passwordStrength === 2) return "Medium";
    if (passwordStrength === 3) return "Strong";
    return "Very Strong";
  };

  // Redirect to dashboard if user is already logged in and profile is complete
  if (!loading && user && isProfileComplete(user)) {
    return navigate("/dashboard");
  }

  return (
    <div className={styles.mainContent}>
      <Header />
      <main className={styles.container}>
        <Box component="div" className={styles.card}>
          <h2>Employee Onboarding</h2>

          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              {errorMessages.length > 0 && (
                <ErrorMessageContainer>
                  <Typography variant="subtitle1" gutterBottom>
                    {errorMessages.length > 1
                      ? "Please address the following:"
                      : "Error:"}
                  </Typography>
                  <List dense>
                    {errorMessages.map((msg, idx) => (
                      <ListItem key={idx} disableGutters>
                        <ListItemText primary={`• ${msg}`} />
                      </ListItem>
                    ))}
                  </List>
                </ErrorMessageContainer>
              )}

              {employee.business_name ? (
                <>
                  <p>
                    Welcome to {employee.business_name}! You have been invited
                    to join as:
                    <div style={{ margin: "8px 0" }}>
                      {employee.employee_roles?.map((role) => (
                        <RoleChip key={role}>{role}</RoleChip>
                      ))}
                    </div>
                  </p>
                  <p>
                    Please create an account or link your existing account to
                    complete the onboarding process.
                  </p>

                  <Tabs
                    value={accountType}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="account type tabs"
                    sx={{ mb: 2 }}
                  >
                    <Tab label="I have an account" />
                    <Tab label="Create new account" />
                  </Tabs>

                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Email"
                      type="email"
                      value={userData.user_email}
                      onChange={(e) =>
                        handleUserDataChange("user_email", e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                      error={errorMessages.some((msg) =>
                        msg.toLowerCase().includes("email")
                      )}
                      helperText={
                        errorMessages.find((msg) =>
                          msg.toLowerCase().includes("email")
                        ) || ""
                      }
                    />
                    <TextField
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={userData.user_password}
                      onChange={(e) =>
                        handleUserDataChange("user_password", e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      required
                      error={errorMessages.some(
                        (msg) =>
                          msg.toLowerCase().includes("password") &&
                          !msg.toLowerCase().includes("confirm")
                      )}
                      helperText={
                        errorMessages.find(
                          (msg) =>
                            msg.toLowerCase().includes("password") &&
                            !msg.toLowerCase().includes("confirm")
                        ) || ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* Password strength indicator for new accounts */}
                    {accountType === 1 && userData.user_password && (
                      <Box sx={{ mt: 1, mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                          <Typography variant="caption" color="textSecondary">
                            Password Strength
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {getPasswordStrengthLabel()}
                          </Typography>
                        </Box>
                        <PasswordStrengthIndicator strength={passwordStrength} />
                        
                        <Box sx={{ mt: 1 }}>
                          <PasswordRequirement isMet={passwordRequirements.length}>
                            {passwordRequirements.length ? "✓" : "○"} At least 12 characters
                          </PasswordRequirement>
                          <PasswordRequirement isMet={passwordRequirements.lowercase}>
                            {passwordRequirements.lowercase ? "✓" : "○"} One lowercase letter
                          </PasswordRequirement>
                          <PasswordRequirement isMet={passwordRequirements.uppercase}>
                            {passwordRequirements.uppercase ? "✓" : "○"} One uppercase letter
                          </PasswordRequirement>
                          <PasswordRequirement isMet={passwordRequirements.number}>
                            {passwordRequirements.number ? "✓" : "○"} One number
                          </PasswordRequirement>
                          <PasswordRequirement isMet={passwordRequirements.special}>
                            {passwordRequirements.special ? "✓" : "○"} One special character
                          </PasswordRequirement>
                          <PasswordRequirement isMet={passwordRequirements.noCommonPatterns}>
                            {passwordRequirements.noCommonPatterns ? "✓" : "○"} No common patterns
                          </PasswordRequirement>
                        </Box>
                      </Box>
                    )}

                    {/* Show confirm password field only for new accounts */}
                    {accountType === 1 && (
                      <TextField
                        label="Confirm Password"
                        type={showPassword ? "text" : "password"}
                        value={userData.confirm_password}
                        onChange={(e) =>
                          handleUserDataChange(
                            "confirm_password",
                            e.target.value
                          )
                        }
                        fullWidth
                        margin="normal"
                        required
                        error={errorMessages.some(
                          (msg) =>
                            msg.toLowerCase().includes("match") ||
                            msg.toLowerCase().includes("confirm")
                        )}
                        helperText={
                          errorMessages.find(
                            (msg) =>
                              msg.toLowerCase().includes("match") ||
                              msg.toLowerCase().includes("confirm")
                          ) || ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}

                    <CustomButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        `${
                          accountType === 0 ? "Sign In" : "Create Account"
                        } & Complete Onboarding`
                      )}
                    </CustomButton>
                  </form>
                </>
              ) : (
                <Typography variant="body1">
                  Unable to load invitation details. Please check your
                  invitation link or contact your employer.
                </Typography>
              )}

              {alertMessage && (
                <Alert
                  severity={alertMessage.type}
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                >
                  {alertMessage.message}
                </Alert>
              )}
            </>
          )}
        </Box>
      </main>
      <Footer />
    </div>
  );
};

export default EmployeeOnboarding;
