import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Paper,
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  Grid,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  LocalShipping as LocalShippingIcon,
  People as PeopleIcon,
  Assessment as AssessmentIcon,
  OpenInNew as OpenInNewIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

const EmployeeActionItems = ({ businessId, actionItems, employee }) => {
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState({});

  const getAccessibleModules = () => {
    // Determine accessible modules based on employee roles
    const roles = employee?.roles || [];
    
    // Basic modules that most employees can access
    const modules = ["dashboard", "orders"];
    
    // Add additional modules based on roles
    if (roles.includes("Manager") || roles.includes("Supervisor") || roles.includes("Administrator")) {
      modules.push("customers");
      modules.push("reports");
    }
    
    if (roles.includes("Manager") || roles.includes("Administrator")) {
      modules.push("employees");
    }
    
    return modules;
  };

  const getModuleIcon = (module) => {
    switch (module) {
      case "dashboard":
        return <DashboardIcon fontSize="large" color="primary" />;
      case "orders":
        return <LocalShippingIcon fontSize="large" color="primary" />;
      case "customers":
        return <PeopleIcon fontSize="large" color="primary" />;
      case "reports":
        return <AssessmentIcon fontSize="large" color="primary" />;
      case "employees":
        return <PeopleIcon fontSize="large" color="primary" />;
      default:
        return <DashboardIcon fontSize="large" color="primary" />;
    }
  };

  const getModuleDescription = (module) => {
    switch (module) {
      case "dashboard":
        return "View key metrics and performance indicators";
      case "orders":
        return "Manage and track all shipping orders";
      case "customers":
        return "View and manage customer information";
      case "employees":
        return "Manage employee accounts and permissions";
      case "reports":
        return "Generate and view business analytics reports";
      default:
        return "";
    }
  };

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleModuleClick = (module) => {
    switch (module) {
      case "dashboard":
        navigate(`/employee/${businessId}/dashboard`);
        break;
      case "orders":
        navigate(`/employee/${businessId}/orders`);
        break;
      case "customers":
        navigate(`/employee/${businessId}/customers`);
        break;
      case "employees":
        navigate(`/employee/${businessId}/employees`);
        break;
      case "reports":
        navigate(`/employee/${businessId}/reports`);
        break;
      default:
        navigate(`/employee/${businessId}/dashboard`);
    }
  };

  // Render action item card
  const renderActionItemCard = (item) => {
    const isExpanded = expandedItems[item.id] || false;
    
    return (
      <Paper
        key={item.id}
        elevation={2}
        sx={{
          mb: 2,
          p: 2,
          borderLeft: `4px solid ${
            item.priority === "high"
              ? "var(--error-color)"
              : item.priority === "medium"
              ? "var(--warning-color)"
              : "var(--success-color)"
          }`,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h6">{item.title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {item.date}
            </Typography>
          </Box>
          <Box>
            <Chip
              size="small"
              label={item.status}
              color={
                item.status === "Pending"
                  ? "error"
                  : item.status === "In Progress"
                  ? "warning"
                  : "success"
              }
            />
            <IconButton
              size="small"
              onClick={() => toggleExpand(item.id)}
              sx={{ ml: 1 }}
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        
        {isExpanded && (
          <>
            <Divider sx={{ my: 1 }} />
            <Box mt={1}>
              <Typography variant="body2">{item.description}</Typography>
              {item.actionLink && (
                <Button
                  variant="text"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => navigate(item.actionLink)}
                  sx={{ mt: 1 }}
                >
                  Take Action
                </Button>
              )}
            </Box>
          </>
        )}
      </Paper>
    );
  };

  // Render module card
  const renderModuleCard = (title, icon, description, module) => {
    return (
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            cursor: "pointer",
          },
        }}
        onClick={() => handleModuleClick(module)}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            {icon}
            <Typography variant="h6" component="div" ml={1}>
              {title}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const accessibleModules = getAccessibleModules();

  return (
    <div className="action-items-container">
      {/* Action Items Section */}
      {actionItems && actionItems.length > 0 && (
        <Box mt={6} mb={4}>
          <Typography variant="h5" gutterBottom>
            Action Items
          </Typography>
          <Box>
            {actionItems.map((item) => renderActionItemCard(item))}
          </Box>
        </Box>
      )}

      {/* Quick Access Section */}
      <Box mt={6} mb={4}>
        <Typography variant="h5" gutterBottom>
          Quick Access
        </Typography>
        <Grid container spacing={3}>
          {accessibleModules
            .filter(module => module !== "dashboard") // Exclude dashboard from quick access
            .map((module) => (
              <Grid item xs={12} sm={6} md={4} key={module}>
                {renderModuleCard(
                  module.charAt(0).toUpperCase() + module.slice(1),
                  getModuleIcon(module),
                  getModuleDescription(module),
                  module
                )}
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );
};

export default EmployeeActionItems;
