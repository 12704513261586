import React from "react";
import { Grid, Paper, Typography, Box, Chip } from "@mui/material";
import {
  LocalShipping as LocalShippingIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";

const EmployeeDashboardOverview = ({ business, dashboardData }) => {
  // Format metrics display
  const renderMetricCard = (title, value, icon, color = "primary") => (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        height: "100%",
        borderLeft: 4,
        borderColor: `${color}.main`,
        display: "flex",
        flexDirection: "column",
        transition: "transform 0.2s, box-shadow 0.2s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box display="flex" alignItems="center" mb={1}>
        {icon}
        <Typography variant="subtitle2" color="textSecondary" ml={1}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" component="div" color={color}>
        {value}
      </Typography>
    </Paper>
  );

  return (
    <div className="dashboard-overview">
      <Box mb={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Employee Dashboard
        </Typography>
        <Typography variant="h6" gutterBottom>
          Welcome back, {dashboardData?.employee?.first_name || "Employee"}!
        </Typography>

        {dashboardData?.employee?.roles?.length > 0 && (
          <Box mt={1} display="flex" flexWrap="wrap" gap={0.5}>
            {dashboardData.employee.roles.map((role) => (
              <Chip
                key={role}
                label={role}
                size="small"
                color="primary"
                variant="outlined"
              />
            ))}
          </Box>
        )}
      </Box>

      {/* Metrics */}
      {Object.keys(dashboardData?.metrics || {}).length > 0 && (
        <>
          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
            Overview
          </Typography>

          <Grid container spacing={3}>
            {dashboardData?.metrics?.orders && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  {renderMetricCard(
                    "Total Orders",
                    dashboardData.metrics.orders.total,
                    <LocalShippingIcon color="primary" />,
                    "primary"
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {renderMetricCard(
                    "Pending Orders",
                    dashboardData.metrics.orders.pending,
                    <AssignmentIcon color="warning" />,
                    "warning"
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {renderMetricCard(
                    "In Progress",
                    dashboardData.metrics.orders.inProgress,
                    <LocalShippingIcon color="info" />,
                    "info"
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {renderMetricCard(
                    "Today's Orders",
                    dashboardData.metrics.orders.today,
                    <ReceiptIcon color="success" />,
                    "success"
                  )}
                </Grid>
              </>
            )}

            {dashboardData?.metrics?.customers && (
              <Grid item xs={12} sm={6} md={3}>
                {renderMetricCard(
                  "Total Customers",
                  dashboardData.metrics.customers.total,
                  <PeopleIcon color="secondary" />,
                  "secondary"
                )}
              </Grid>
            )}

            {dashboardData?.metrics?.employees && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  {renderMetricCard(
                    "Team Members",
                    dashboardData.metrics.employees.total,
                    <PeopleIcon color="primary" />,
                    "primary"
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default EmployeeDashboardOverview;
