import React, { useState, useEffect, useContext, useCallback } from "react";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
  Tab,
  Tabs,
  Table,
  TableContainer,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  GetApp as GetAppIcon,
  Refresh as RefreshIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon,
  Timeline as TimelineIcon,
  LocationOn as LocationOnIcon,
  AttachMoney as AttachMoneyIcon,
  ArrowDropDown as ArrowDropDownIcon,
  FilterList as FilterListIcon,
  LocalShipping as LocalShippingIcon,
  People as PeopleIcon,
  Assessment,
  CheckCircle,
  Warning,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useBusiness } from "../../components/Auth/BusinessContext";
import "./dashboard/EmployeeDashboardPage.css";

// Chart colors
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
];

// Skeleton components for loading states
const MetricsCardSkeleton = () => (
  <Card sx={{ height: "100%" }}>
    <CardContent>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="circular" width={24} height={24} />
      </Box>
      <Skeleton variant="text" width="40%" height={40} />
    </CardContent>
  </Card>
);

const ChartSkeleton = ({ height = 300 }) => (
  <Card sx={{ height: "100%" }}>
    <CardHeader title={<Skeleton variant="text" width="60%" height={30} />} />
    <CardContent>
      <Box sx={{ height: height }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
    </CardContent>
  </Card>
);

const TableSkeleton = () => (
  <Card sx={{ height: "100%" }}>
    <CardHeader title={<Skeleton variant="text" width="60%" height={30} />} />
    <CardContent>
      <Skeleton variant="rectangular" width="100%" height={30} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={200} />
    </CardContent>
  </Card>
);

// Format currency
const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

// Format date
const formatDate = (date) => {
  return dayjs(date).format("MMM D, YYYY");
};

const EmployeeReportsPage = () => {
  const { businessId } = useParams();
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();
  const { getBusinessById } = useBusiness();

  // State
  const [dashboardData, setDashboardData] = useState(null);
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportType, setReportType] = useState("orders");
  const [timeRange, setTimeRange] = useState("week");
  const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [anchorEl, setAnchorEl] = useState(null);
  const [chartType, setChartType] = useState("bar");
  const [tabValue, setTabValue] = useState(0);
  const [noData, setNoData] = useState(false);

  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });

  // Fetch data
  const fetchReportData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      setNoData(false);

      console.log(
        `Fetching reports for business ${businessId} with type ${reportType} and time range ${timeRange}`
      );

      // Fetch report data
      const reportResponse = await api.get(
        `/employee-portal/reports/${businessId}`,
        {
          params: {
            reportType,
            timeRange,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        }
      );

      console.log("Report response:", reportResponse.data);

      if (reportResponse.data && reportResponse.data.success) {
        const reportData = reportResponse.data.data;
        setReports(reportData);

        // Check if there's no orders/data available
        if (
          (reportType === "orders" || reportType === "revenue") &&
          reportData.summary &&
          reportData.summary.totalOrders === 0
        ) {
          setNoData(true);
        } else if (
          reportType === "customers" &&
          reportData.summary &&
          reportData.summary.totalCustomers === 0
        ) {
          setNoData(true);
        }
      } else {
        setError(reportResponse.data?.message || "Failed to fetch report data");
      }
    } catch (err) {
      console.error("Error fetching reports:", err);
      setError(
        err.response?.data?.message || err.message || "Failed to load reports"
      );
    } finally {
      setLoading(false);
    }
  }, [api, businessId, reportType, timeRange, startDate, endDate]);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch business data
        const business = getBusinessById(businessId);
        setCurrentBusiness(business);

        // Fetch employee dashboard data for role info
        const dashboardResponse = await api.get(
          `/employee-portal/dashboard/${businessId}`
        );
        setDashboardData(dashboardResponse.data);

        // Fetch report data
        await fetchReportData();
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load reports. Please try again later.");
        setLoading(false);
      }
    };

    if (businessId) {
      fetchData();
    }
  }, [api, businessId, getBusinessById, fetchReportData]);

  // Handle time range change
  const handleTimeRangeChange = (event) => {
    const range = event.target.value;
    setTimeRange(range);

    // Update date range based on selected time range
    const today = dayjs();
    let start = dayjs();

    switch (range) {
      case "today":
        start = today.startOf("day");
        break;
      case "week":
        start = today.subtract(7, "day");
        break;
      case "month":
        start = today.subtract(1, "month");
        break;
      case "quarter":
        start = today.subtract(3, "month");
        break;
      case "year":
        start = today.subtract(1, "year");
        break;
      default:
        // Custom range - don't change dates
        return;
    }

    setStartDate(start);
    setEndDate(dayjs());
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setReportType("orders");
        break;
      case 1:
        setReportType("revenue");
        break;
      case 2:
        setReportType("customers");
        break;
      default:
        setReportType("orders");
    }
  };

  // Handle export
  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleExport = (format) => {
    // In a real app, this would trigger an API call to generate and download the report
    alert(`Exporting report in ${format} format...`);
    handleExportClose();
  };

  // Handle chart type change
  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  // Handle refresh data
  const handleRefreshData = () => {
    fetchReportData();
  };

  // Apply filters
  const handleApplyFilters = () => {
    fetchReportData();
  };

  // Render order/revenue charts
  const renderOrdersChart = () => {
    if (
      !reports ||
      !reports.intervalData ||
      reports.intervalData.length === 0
    ) {
      return (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography variant="body1" color="textSecondary">
            No data available for the selected time period.
          </Typography>
        </Box>
      );
    }

    if (chartType === "bar") {
      return (
        <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={reports.intervalData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" angle={-45} textAnchor="end" height={70} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip
                formatter={(value, name) => [
                  name === "revenue" ? formatCurrency(value) : value,
                  name === "revenue" ? "Revenue" : "Orders",
                ]}
              />
              <Legend />
              <Bar
                yAxisId="left"
                dataKey="orders"
                name="Orders"
                fill="#8884d8"
                radius={[4, 4, 0, 0]}
              />
              <Bar
                yAxisId="right"
                dataKey="revenue"
                name="Revenue"
                fill="#82ca9d"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      );
    } else if (chartType === "line") {
      return (
        <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={reports.intervalData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" angle={-45} textAnchor="end" height={70} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip
                formatter={(value, name) => [
                  name === "revenue" ? formatCurrency(value) : value,
                  name === "revenue" ? "Revenue" : "Orders",
                ]}
              />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="orders"
                name="Orders"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="revenue"
                name="Revenue"
                stroke="#82ca9d"
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      );
    } else {
      // Pie chart for order status breakdown
      const pieData = [
        { name: "Delivered", value: reports.orderStatusBreakdown.delivered },
        { name: "In Progress", value: reports.orderStatusBreakdown.inProgress },
        { name: "Pending", value: reports.orderStatusBreakdown.pending },
        { name: "Cancelled", value: reports.orderStatusBreakdown.cancelled },
      ];

      return (
        <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) =>
                  `${name}: ${(percent * 100).toFixed(0)}%`
                }
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => [`${value} orders`, "Count"]} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      );
    }
  };

  // Render customer charts
  const renderCustomersChart = () => {
    if (
      !reports ||
      !reports.summary ||
      !reports.summary.customerStatusBreakdown
    ) {
      return (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Typography variant="body1" color="textSecondary">
            No customer data available for the selected time period.
          </Typography>
        </Box>
      );
    }

    // Convert customer status breakdown to chart data
    const statusData = Object.entries(
      reports.summary.customerStatusBreakdown
    ).map(([status, count]) => ({
      name: status.charAt(0).toUpperCase() + status.slice(1),
      value: count,
    }));

    if (chartType === "pie") {
      return (
        <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={statusData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) =>
                  `${name}: ${(percent * 100).toFixed(0)}%`
                }
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {statusData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => [`${value} customers`, "Count"]} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      );
    } else {
      // For bar or line, use top customers data
      if (!reports.topCustomers || reports.topCustomers.length === 0) {
        return (
          <Box sx={{ textAlign: "center", py: 4 }}>
            <Typography variant="body1" color="textSecondary">
              No top customer data available for the selected time period.
            </Typography>
          </Box>
        );
      }

      return (
        <Box sx={{ height: 300, flexGrow: 1, minHeight: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={reports.topCustomers}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" angle={-45} textAnchor="end" height={70} />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip
                formatter={(value, name) => [
                  name === "totalSpent" ? formatCurrency(value) : value,
                  name === "totalSpent" ? "Total Spent" : "Orders",
                ]}
              />
              <Legend />
              <Bar
                yAxisId="left"
                dataKey="orders"
                name="Orders"
                fill="#8884d8"
                radius={[4, 4, 0, 0]}
              />
              <Bar
                yAxisId="right"
                dataKey="totalSpent"
                name="Total Spent"
                fill="#82ca9d"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      );
    }
  };

  // Render the appropriate chart based on report type
  const renderChart = () => {
    if (reportType === "orders" || reportType === "revenue") {
      return renderOrdersChart();
    } else if (reportType === "customers") {
      return renderCustomersChart();
    }
    return null;
  };

  const renderNoDataMessage = () => (
    <Box sx={{ my: 4, textAlign: "center" }}>
      <BarChartIcon sx={{ fontSize: 60, color: "text.secondary", mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        No Data Available
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        There are no{" "}
        {reportType === "customers" ? "customers" : "shipping orders"} for this
        business during the selected timeframe.
      </Typography>
      <Typography variant="body2" paragraph>
        Try selecting a different timeframe or create some{" "}
        {reportType === "customers" ? "customers" : "shipping orders"} to see
        analytics.
      </Typography>
    </Box>
  );

  // Get page title based on role
  const getPageTitle = () => {
    if (dashboardData?.employee?.roles?.includes("Accountant")) {
      return "Financial Reports";
    } else if (dashboardData?.employee?.roles?.includes("Manager")) {
      return "Business Analytics";
    }
    return "Reports & Analytics";
  };

  // Render loading skeleton
  const renderSkeleton = () => (
    <>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {[...Array(4)].map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <MetricsCardSkeleton />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <ChartSkeleton height={300} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TableSkeleton />
        </Grid>
        <Grid item xs={12} md={6}>
          <TableSkeleton />
        </Grid>
      </Grid>
    </>
  );

  return (
    <div className="business-page business-analytics-page">
      <main className="business-page-content">
        <div className="business-page-header">
          <Typography variant="h4" component="h1" gutterBottom>
            {getPageTitle()}
          </Typography>
        </div>

        {/* Reports toolbar */}

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={7}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab icon={<LocalShippingIcon />} label="Orders" />
              <Tab icon={<AttachMoneyIcon />} label="Revenue" />
              <Tab icon={<PeopleIcon />} label="Customers" />
            </Tabs>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              gap={1}
              justifyContent={{ xs: "flex-start", md: "flex-end" }}
            >
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={handleRefreshData}
                size="small"
                disabled={loading}
              >
                Refresh
              </Button>

              <Button
                variant="outlined"
                startIcon={<GetAppIcon />}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleExportClick}
                size="small"
                disabled={loading || noData}
              >
                Export
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleExportClose}
              >
                <MenuItem onClick={() => handleExport("pdf")}>PDF</MenuItem>
                <MenuItem onClick={() => handleExport("csv")}>CSV</MenuItem>
                <MenuItem onClick={() => handleExport("excel")}>Excel</MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>

        {/* Date filters */}
        <Paper sx={{ p: 2, mb: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="time-range-label">Time Range</InputLabel>
                <Select
                  labelId="time-range-label"
                  value={timeRange}
                  label="Time Range"
                  onChange={handleTimeRangeChange}
                  disabled={loading}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="week">Last 7 Days</MenuItem>
                  <MenuItem value="month">Last 30 Days</MenuItem>
                  <MenuItem value="quarter">Last 3 Months</MenuItem>
                  <MenuItem value="year">Last 12 Months</MenuItem>
                  <MenuItem value="custom">Custom Range</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  disabled={timeRange !== "custom" || loading}
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={3} md={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  disabled={timeRange !== "custom" || loading}
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="chart-type-label">Chart Type</InputLabel>
                <Select
                  labelId="chart-type-label"
                  value={chartType}
                  label="Chart Type"
                  onChange={(e) => handleChartTypeChange(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value="bar">Bar Chart</MenuItem>
                  <MenuItem value="line">Line Chart</MenuItem>
                  <MenuItem value="pie">Pie Chart</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={6} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleApplyFilters}
                startIcon={<FilterListIcon />}
                disabled={loading}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Paper>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
            <Button
              size="small"
              variant="outlined"
              onClick={handleRefreshData}
              sx={{ ml: 2 }}
            >
              Retry
            </Button>
          </Alert>
        )}

        {loading ? (
          renderSkeleton()
        ) : noData ? (
          renderNoDataMessage()
        ) : reports ? (
          <>
            {/* KPI Metrics */}
            {(reportType === "orders" || reportType === "revenue") &&
              reports.summary && (
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          gutterBottom
                        >
                          Total Orders
                        </Typography>
                        <Typography variant="h4">
                          {reports.summary.totalOrders}
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                          {reports.summary.ordersGrowth >= 0 ? (
                            <TrendingUpIcon color="success" fontSize="small" />
                          ) : (
                            <TrendingDownIcon color="error" fontSize="small" />
                          )}
                          <Typography
                            variant="body2"
                            color={
                              reports.summary.ordersGrowth >= 0
                                ? "success.main"
                                : "error.main"
                            }
                            sx={{ ml: 0.5 }}
                          >
                            {reports.summary.ordersGrowth}%
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          gutterBottom
                        >
                          Revenue
                        </Typography>
                        <Typography variant="h4">
                          {formatCurrency(reports.summary.totalRevenue)}
                        </Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                          {reports.summary.revenueGrowth >= 0 ? (
                            <TrendingUpIcon color="success" fontSize="small" />
                          ) : (
                            <TrendingDownIcon color="error" fontSize="small" />
                          )}
                          <Typography
                            variant="body2"
                            color={
                              reports.summary.revenueGrowth >= 0
                                ? "success.main"
                                : "error.main"
                            }
                            sx={{ ml: 0.5 }}
                          >
                            {reports.summary.revenueGrowth}%
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          gutterBottom
                        >
                          Avg. Order Value
                        </Typography>
                        <Typography variant="h4">
                          {formatCurrency(reports.summary.avgOrderValue)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          gutterBottom
                        >
                          Completed Orders
                        </Typography>
                        <Typography variant="h4">
                          {reports.orderStatusBreakdown?.delivered || 0}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {reports.summary.totalOrders > 0
                            ? Math.round(
                                ((reports.orderStatusBreakdown?.delivered ||
                                  0) /
                                  reports.summary.totalOrders) *
                                  100
                              )
                            : 0}
                          % completion rate
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}

            {reportType === "customers" && reports.summary && (
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Total Customers
                      </Typography>
                      <Typography variant="h4">
                        {reports.summary.totalCustomers}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Active Customers
                      </Typography>
                      <Typography variant="h4">
                        {reports.summary.activeCustomers}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {reports.summary.totalCustomers > 0
                          ? Math.round(
                              (reports.summary.activeCustomers /
                                reports.summary.totalCustomers) *
                                100
                            )
                          : 0}
                        % of total
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card sx={{ height: "100%" }}>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Customer Status
                      </Typography>
                      <Typography variant="h6" sx={{ mt: 1 }}>
                        <Chip
                          size="small"
                          label={`Active: ${
                            reports.summary.customerStatusBreakdown?.active || 0
                          }`}
                          color="success"
                          sx={{ mr: 1, mb: 1 }}
                        />
                        <Chip
                          size="small"
                          label={`Inactive: ${
                            reports.summary.customerStatusBreakdown?.inactive ||
                            0
                          }`}
                          color="error"
                          sx={{ mr: 1, mb: 1 }}
                        />
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}

            {/* Chart */}
            <Card sx={{ mb: 3 }}>
              <CardHeader
                title={`${
                  reportType.charAt(0).toUpperCase() + reportType.slice(1)
                } Over Time`}
              />
              <Divider />
              <CardContent sx={{ height: 350, width: "100%" }}>
                {renderChart()}
              </CardContent>
            </Card>

            {/* Data breakdown */}
            {(reportType === "orders" || reportType === "revenue") &&
              reports.orderStatusBreakdown && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardHeader title="Order Status Breakdown" />
                      <Divider />
                      <CardContent>
                        <TableContainer>
                          <Table size="small">
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th align="right">Count</th>
                                <th align="right">Percentage</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Chip
                                    size="small"
                                    label="Delivered"
                                    color="success"
                                  />
                                </td>
                                <td align="right">
                                  {reports.orderStatusBreakdown.delivered}
                                </td>
                                <td align="right">
                                  {reports.summary.totalOrders > 0
                                    ? Math.round(
                                        (reports.orderStatusBreakdown
                                          .delivered /
                                          reports.summary.totalOrders) *
                                          100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Chip
                                    size="small"
                                    label="In Progress"
                                    color="info"
                                  />
                                </td>
                                <td align="right">
                                  {reports.orderStatusBreakdown.inProgress}
                                </td>
                                <td align="right">
                                  {reports.summary.totalOrders > 0
                                    ? Math.round(
                                        (reports.orderStatusBreakdown
                                          .inProgress /
                                          reports.summary.totalOrders) *
                                          100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Chip
                                    size="small"
                                    label="Pending"
                                    color="warning"
                                  />
                                </td>
                                <td align="right">
                                  {reports.orderStatusBreakdown.pending}
                                </td>
                                <td align="right">
                                  {reports.summary.totalOrders > 0
                                    ? Math.round(
                                        (reports.orderStatusBreakdown.pending /
                                          reports.summary.totalOrders) *
                                          100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Chip
                                    size="small"
                                    label="Cancelled"
                                    color="error"
                                  />
                                </td>
                                <td align="right">
                                  {reports.orderStatusBreakdown.cancelled}
                                </td>
                                <td align="right">
                                  {reports.summary.totalOrders > 0
                                    ? Math.round(
                                        (reports.orderStatusBreakdown
                                          .cancelled /
                                          reports.summary.totalOrders) *
                                          100
                                      )
                                    : 0}
                                  %
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardHeader title="Top Performing Regions" />
                      <Divider />
                      <CardContent>
                        <TableContainer>
                          <Table size="small">
                            <thead>
                              <tr>
                                <th>Region</th>
                                <th align="right">Orders</th>
                                <th align="right">Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reports.topPerformingRegions?.map(
                                (region, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Box display="flex" alignItems="center">
                                        <LocationOnIcon
                                          fontSize="small"
                                          sx={{ mr: 1, color: "action.active" }}
                                        />
                                        {region.name}
                                      </Box>
                                    </td>
                                    <td align="right">{region.orders}</td>
                                    <td align="right">
                                      {formatCurrency(region.revenue)}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}

            {reportType === "customers" && reports.topCustomers && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Top Customers" />
                    <Divider />
                    <CardContent>
                      <TableContainer>
                        <Table size="small">
                          <thead>
                            <tr>
                              <th>Customer</th>
                              <th align="right">Orders</th>
                              <th align="right">Total Spent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reports.topCustomers.map((customer, index) => (
                              <tr key={index}>
                                <td>
                                  <Box display="flex" alignItems="center">
                                    <PeopleIcon
                                      fontSize="small"
                                      sx={{ mr: 1, color: "action.active" }}
                                    />
                                    {customer.name}
                                  </Box>
                                </td>
                                <td align="right">{customer.orders}</td>
                                <td align="right">
                                  {formatCurrency(customer.totalSpent)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </>
        ) : null}
      </main>
    </div>
  );
};

export default EmployeeReportsPage;
