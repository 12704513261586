import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, Outlet } from "react-router-dom";
import { AuthContext } from "../components/Auth/AuthContext";
import { useBusiness } from "../components/Auth/BusinessContext";
import { useSidebar } from "../components/Sidebar/SidebarContext";
import { EmployeePermissionsProvider } from "../components/Auth/EmployeePermissions";
import BusinessDashboardHeader from "../components/Header/BusinessDashboardHeader";
import EmployeeSidebar from "../components/Sidebar/EmployeeSidebar";
import "./styles/LayoutStyles.css";
import "./styles/spinner.css";
import "./styles/EmployeeLayout.css";

const EmployeeLayout = () => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const { isEmployeeSidebarActive, toggleEmployeeSidebar } = useSidebar();
  const { getBusinessById } = useBusiness();

  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch employee and business data once at the layout level
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch business data
        const business = getBusinessById(businessId);
        setCurrentBusiness(business);

        // Save business data to localStorage for persistence
        if (business) {
          localStorage.setItem(
            `business_${businessId}`,
            JSON.stringify(business)
          );
        }

        // Fetch employee dashboard data
        const dashboardResponse = await api.get(
          `/employee-portal/dashboard/${businessId}`
        );
        setDashboardData(dashboardResponse.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError(err.response?.data?.error || "Failed to load dashboard data");
      } finally {
        setLoading(false);
      }
    };

    if (businessId) {
      fetchData();
    }
  }, [api, businessId, getBusinessById]);

  // Trigger a refresh of the data
  const refreshData = useCallback(async () => {
    try {
      setLoading(true);
      
      // Fetch business data
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);

      // Fetch employee dashboard data
      const dashboardResponse = await api.get(
        `/employee-portal/dashboard/${businessId}`
      );
      setDashboardData(dashboardResponse.data);
      setError(null);
    } catch (err) {
      console.error("Error refreshing dashboard data:", err);
      setError(err.response?.data?.error || "Failed to refresh dashboard data");
    } finally {
      setLoading(false);
    }
  }, [api, businessId, getBusinessById]);

  // Content to pass to child components 
  const contextValue = {
    currentBusiness,
    dashboardData,
    loading,
    error,
    refreshData
  };

  // Get employee roles for permissions
  const employeeRoles = dashboardData?.employee?.roles || [];

  // Render the layout with permissions provider
  const renderContent = () => {
    if (loading) {
      return (
        <div className="layout-loading">
          <div className="spinner"></div>
        </div>
      );
    }
    
    if (error) {
      return (
        <div className="layout-error">
          <h3>Error loading data</h3>
          <p>{error}</p>
        </div>
      );
    }
    
    return (
      <EmployeePermissionsProvider roles={employeeRoles}>
        <div className="dashboard-container">
          <main
            className={`dashboard-content ${
              isEmployeeSidebarActive ? "sidebar-active" : ""
            }`}
          >
            <div className="page-transition-wrapper">
              <Outlet context={contextValue} />
            </div>
          </main>
        </div>
      </EmployeePermissionsProvider>
    );
  };

  return (
    <div className="layout-container employee-layout">
      <BusinessDashboardHeader
        business={currentBusiness}
        isEmployeePortal={true}
      />
      <div className="dashboard-main-content">
        {/* The sidebar is rendered once at the layout level */}
        <EmployeeSidebar
          businessId={businessId}
          employeeRoles={employeeRoles}
        />
        {renderContent()}
      </div>
      {/* <DashboardFooter /> */}
    </div>
  );
};

export default EmployeeLayout;
