// BatchLocationNode.jsx
import React from "react";
import { Handle } from "reactflow";
import { Box, Tooltip, Typography, Badge } from "@mui/material";
import {
  LocalShipping,
  Home,
  Store,
  Warehouse,
  LocalShippingOutlined,
  LocationOn,
  Flag,
  People,
} from "@mui/icons-material";
import { useCallback } from "react";

const NODE_TYPES = {
  start: Flag,
  delivery: Home,
  warehouse: Warehouse,
  dropoff: LocalShipping,
  pickup: LocalShippingOutlined,
  store: Store,
  shared_delivery: Home,
  default: LocationOn,
};

const NODE_COLORS = {
  start: "#FFD700",
  delivery: "#FF4444",
  warehouse: "#4CAF50",
  dropoff: "#2196F3",
  pickup: "#9C27B0",
  store: "#FF9800",
  shared_delivery: "#E91E63",
  default: "#757575",
};

const BatchLocationNode = ({
  data,
  selected,
  isDragging,
  isValidTarget,
  edges,
  ...props
}) => {
  const Icon = NODE_TYPES[data.type] || NODE_TYPES.default;
  const isConsolidated = data.isConsolidated;
  const isSharedDelivery = data.isSharedDelivery;
  const childNodes = data.details?.childNodes || [];

  // Get order count based on node type and edge data
  const getOrderCount = useCallback(() => {
    if (isSharedDelivery) {
      return data.details.orders.length;
    }
    // Don't show badge for regular delivery nodes
    if (data.type === "delivery" && !data.isConsolidated) {
      return null;
    }

    // For consolidated delivery nodes, show total orders
    if (data.isConsolidated) {
      return data.details.orders.length;
    }

    // For other nodes, count available orders that can be routed from here
    const availableOrders = new Set();

    // First check orders that start at this node and haven't been routed
    props.orders?.forEach((order) => {
      if (order.start_location_id === props.id) {
        if (
          !edges?.some(
            (e) =>
              e.source === props.id &&
              e.data.orders?.includes(order.shipping_order_id)
          )
        ) {
          availableOrders.add(order.shipping_order_id);
        }
      }
    });

    // Then check orders that have arrived but haven't left
    edges?.forEach((edge) => {
      if (edge.data?.orders) {
        edge.data.orders.forEach((orderId) => {
          const orderIdStr =
            typeof orderId === "string" ? orderId : orderId.shipping_order_id;

          const hasIncoming = edges.some(
            (e) => e.target === props.id && e.data.orders?.includes(orderIdStr)
          );

          const hasOutgoing = edges.some(
            (e) => e.source === props.id && e.data.orders?.includes(orderIdStr)
          );

          if (hasIncoming && !hasOutgoing) {
            availableOrders.add(orderIdStr);
          }
        });
      }
    });

    return availableOrders.size > 0 ? availableOrders.size : null;
  }, [data, props.id, props.orders, edges]);

  const orderCount = getOrderCount();

  // Determine opacity based on drag state and validity
  const nodeOpacity = isDragging ? (isValidTarget ? 1 : 0.3) : 1;
  const pointerEvents = isDragging && !isValidTarget ? "none" : "all";

  // Tooltip content
  const TooltipContent = () => {
    // If consolidated, show child count, possibly assigned courier
    if (isSharedDelivery) {
      return (
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle2">{data.label}</Typography>
          <Typography variant="body2" color="text.secondary">
            {data.details.orders.length} order(s) from{" "}
            {data.details.startLocationId}
          </Typography>
          <Typography variant="body2">
            {data.details.address_line1 || data.details.business_address_street_address_1}
            {(data.details.address_line2 || data.details.business_address_street_address_2) && (
              <>
                <br />
                {data.details.address_line2 || data.details.business_address_street_address_2}
              </>
            )}
            <br />
            {data.details.city || data.details.business_address_city},{" "}
            {data.details.state || data.details.state_province || data.details.business_address_state_province}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Part of shared delivery point
          </Typography>
        </Box>
      );
    }
    if (isConsolidated) {
      const nodeCount = childNodes.length;
      return (
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle2">{data.label}</Typography>
          <Typography variant="body2">
            {nodeCount} delivery point{nodeCount > 1 ? "s" : ""}
          </Typography>
          {/* If you store courier in data.details.courier */}
          {data.details?.courier ? (
            <Typography variant="body2" color="text.secondary">
              Assigned to:{" "}
              {data.details.courier.employee_first_name || "Unknown Courier"}
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Unassigned deliveries
            </Typography>
          )}

          <Typography variant="caption">Click to view or split</Typography>
        </Box>
      );
    }

    // Otherwise, standard node
    const addr1 = data.details?.business_address_street_address_1 || data.details?.address_line1;
    const addr2 = data.details?.business_address_street_address_2 || data.details?.address_line2;
    const city = data.details?.business_address_city || data.details?.city;
    const state = data.details?.business_address_state_province || data.details?.state_province || data.details?.state;

    return (
      <Box sx={{ p: 1 }}>
        <Typography variant="subtitle2">{data.label}</Typography>
        {addr1 && (
          <Typography variant="body2">
            {addr1}
            {addr2 && (
              <>
                <br />
                {addr2}
              </>
            )}
            <br />
            {city}
            {state ? `, ${state}` : ""}
          </Typography>
        )}
        {(data.details?.business_address_phone || data.details?.contact_phone) && (
          <Typography variant="body2">
            Phone: {data.details.business_address_phone || data.details.contact_phone}
          </Typography>
        )}
      </Box>
    );
  };

  // Badge content: for consolidated nodes, show number of child nodes
  const badgeContent = orderCount > 0 ? orderCount : null;

  return (
    <Tooltip title={<TooltipContent />} placement="top">
      <Badge
        badgeContent={orderCount}
        color={isSharedDelivery ? "secondary" : "primary"}
        invisible={orderCount === null}
        sx={{
          "& .MuiBadge-badge": {
            fontSize: "0.8rem",
            minWidth: "22px",
            height: "22px",
            borderRadius: "11px",
            backgroundColor: (theme) =>
              isSharedDelivery
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            background: isSharedDelivery
              ? NODE_COLORS.shared_delivery
              : NODE_COLORS[data.type] || NODE_COLORS.default,
            p: isConsolidated ? 2 : 1.5,
            borderRadius: 2,
            width: isConsolidated || isSharedDelivery ? "120px" : "100px",
            height: isConsolidated || isSharedDelivery ? "120px" : "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: (theme) =>
              selected
                ? `3px solid ${theme.palette.primary.main}`
                : isSharedDelivery
                ? `3px solid ${theme.palette.secondary.main}`
                : isConsolidated
                ? "3px solid rgba(0,0,0,0.3)"
                : "2px solid rgba(0,0,0,0.2)",
            boxShadow: (theme) =>
              selected
                ? `0 0 0 2px ${theme.palette.primary.main}`
                : "0 2px 5px rgba(0,0,0,0.2)",
            cursor: "pointer",
            transition: "transform 0.2s, box-shadow 0.2s, opacity 0.2s",
            "&:hover": {
              transform: isDragging && !isValidTarget ? "none" : "scale(1.05)",
              boxShadow:
                isDragging && !isValidTarget
                  ? "none"
                  : "0 4px 8px rgba(0,0,0,0.3)",
            },
            opacity: nodeOpacity,
            pointerEvents,
          }}
        >
          {/* Only show source handle for non-delivery nodes */}
          {data.type !== "delivery" && !isConsolidated && !isSharedDelivery && (
            <Handle
              type="source"
              position="right"
              style={{
                background: "#4CAF50",
                width: "10px",
                height: "10px",
                border: "2px solid #fff",
                opacity: nodeOpacity,
              }}
            />
          )}

          <Handle
            type="target"
            position="left"
            style={{
              background: "#FF4444",
              width: "10px",
              height: "10px",
              border: "2px solid #fff",
              opacity: nodeOpacity,
            }}
          />

          <Icon
            sx={{
              fontSize: isConsolidated || isSharedDelivery ? 40 : 32,
              color: "#fff",
              mb: 1,
            }}
          />

          {/* Show "shared" icon for shared delivery nodes */}
          {isSharedDelivery && (
            <People
              sx={{
                fontSize: 20,
                color: "rgba(255,255,255,0.8)",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            />
          )}

          <Typography
            variant="caption"
            sx={{
              color: "#fff",
              textAlign: "center",
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              px: 1,
              fontSize:
                isConsolidated || isSharedDelivery ? "0.75rem" : "0.7rem",
              fontWeight: isConsolidated || isSharedDelivery ? 500 : 400,
            }}
          >
            {data.label}
          </Typography>

          {/* Show orders info for non-consolidated nodes */}
          {orderCount > 0 && !isConsolidated && (
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                bottom: "-20px",
                left: "50%",
                transform: "translateX(-50%)",
                color: "text.secondary",
                whiteSpace: "nowrap",
                fontSize: "0.7rem",
              }}
            >
              {isSharedDelivery
                ? `${orderCount} order${orderCount !== 1 ? "s" : ""} from ${
                    data.details.startLocationId
                  }`
                : data.type === "delivery"
                ? "1 order"
                : `${orderCount} order${orderCount !== 1 ? "s" : ""} waiting`}
            </Typography>
          )}
        </Box>
      </Badge>
    </Tooltip>
  );
};

export default BatchLocationNode;