import React, { useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaUser,
  FaTachometerAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaEdit,
  FaLock,
  FaCreditCard,
  FaMapMarkerAlt,
  FaShoppingCart,
  FaBriefcase,
} from "react-icons/fa";
import logo from "../../assets/logo-plain.svg";
import "./DashboardSidebar.css";
import { AuthContext } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "./SidebarContext";

// Use an inline SVG placeholder logo to prevent loading delays
const inlineLogo = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjUwIiB2aWV3Qm94PSIwIDAgMTAwIDUwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iNTAiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMjAgMTVIMzBWMzVIMjBWMTVaIiBmaWxsPSIjMDA3QkZGIi8+PHBhdGggZD0iTTQwIDE1SDgwVjI1SDQwVjE1WiIgZmlsbD0iIzAwN0JGRiIvPjxwYXRoIGQ9Ik00MCAyNUg1MFYzNUg0MFYyNVoiIGZpbGw9IiMwMDdCRkYiLz48cGF0aCBkPSJNNzAgMjVIODBWMzVINzBWMjVaIiBmaWxsPSIjMDA3QkZGIi8+PC9zdmc+`;

const DashboardSidebar = ({ currentPath, user }) => {
  const location = useLocation();
  const { logout } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const { isUserSidebarActive, toggleUserSidebar } = useSidebar();
  
  // Reference for the logo image
  const logoRef = useRef(null);
  
  // Preload the logo image
  useEffect(() => {
    // Set the initial image source to the inline logo
    if (logoRef.current) {
      // Start with inline logo to avoid flash
      logoRef.current.src = inlineLogo;
      
      // Then load the actual logo
      const img = new Image();
      img.src = logo;
      img.onload = () => {
        // Once the real logo is loaded, switch to it
        if (logoRef.current) {
          logoRef.current.src = logo;
        }
      };
    }
  }, []);

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path) ? "active" : "";
  };

  const handleLogout = async () => {
    await logout();
    navigate("/auth");
  };

  // Prepare the sidebar logo - memoized to prevent rerenders
  const sidebarLogo = useMemo(() => (
    <div className="sidebar-logo">
      <img 
        ref={logoRef} 
        src={logo}
        data-src={inlineLogo} // Fallback data URI version
        alt="App Logo" 
        className="logo"
        style={{
          transition: "opacity 0.3s ease, width 0.3s ease", // Smooth transitions
          width: isUserSidebarActive ? '80%' : '70%', // Adjust size based on sidebar state
          maxHeight: "40px",
          objectFit: "contain", // Maintain aspect ratio
        }} 
        onError={(e) => {
          // If the main image fails, use the inline version
          if (e.target.src !== e.target.dataset.src) {
            e.target.src = e.target.dataset.src;
          }
        }}
      />
    </div>
  ), [isUserSidebarActive]);

  return (
    <aside className={`dashboard-sidebar ${isUserSidebarActive ? "active" : ""}`}>
      <div className="sidebar-top">
        {sidebarLogo}
        <button onClick={toggleUserSidebar} className="sidebar-toggle">
          {isUserSidebarActive ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>
      <nav>
        <Link to="/dashboard" className={isLinkActive("/dashboard")}>
          <FaTachometerAlt />
          <span>{isUserSidebarActive && "Dashboard"}</span>
        </Link>
        <Link
          to="/profile"
          className={`${isLinkActive("/profile")} parent-link`}
        >
          <FaUser />
          <span>{isUserSidebarActive && "Profile"}</span>
        </Link>
        <div className="sidebar-submenu">
          <Link to="/profile/edit" className={isLinkActive("/profile/edit")}>
            <FaEdit />
            <span>{isUserSidebarActive && "Edit Profile"}</span>
          </Link>
          <Link
            to="/profile/security"
            className={isLinkActive("/profile/security")}
          >
            <FaLock />
            <span>{isUserSidebarActive && "Login & Security"}</span>
          </Link>
          <Link
            to="/profile/payments"
            className={isLinkActive("/profile/payments")}
          >
            <FaCreditCard />
            <span>{isUserSidebarActive && "Payments"}</span>
          </Link>
          <Link
            to="/profile/addresses"
            className={isLinkActive("/profile/addresses")}
          >
            <FaMapMarkerAlt />
            <span>{isUserSidebarActive && "Addresses"}</span>
          </Link>
          <Link
            to="/profile/orders"
            className={isLinkActive("/profile/orders")}
          >
            <FaShoppingCart />
            <span>{isUserSidebarActive && "Orders"}</span>
          </Link>
          <Link
            to="/profile/businesses"
            className={isLinkActive("/profile/businesses")}
          >
            <FaBriefcase />
            <span>{isUserSidebarActive && "Business Account"}</span>
          </Link>
        </div>
      </nav>
      <div className="sidebar-bottom">
        <button onClick={handleLogout} className="sidebar-link">
          <FaSignOutAlt />
          <span>{isUserSidebarActive && "Log Out"}</span>
        </button>
      </div>
    </aside>
  );
};

export default DashboardSidebar;
