import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  ExpandMore as ExpandMoreIcon,
  Create as CreateIcon,
  Update as UpdateIcon,
  AddBox as AddBoxIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  CompareArrows as CompareArrowsIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  MonetizationOn as MonetizationOnIcon,
} from "@mui/icons-material";

// First, let's define our utility functions for handling number formatting
const numberPrecision = {
  weight: 3,
  value: 2,
  dimensions: 2,
};

const formatNumber = (value, type) => {
  if (value === null || value === undefined) return 0;
  const precision = numberPrecision[type] || 2;
  return Number(parseFloat(value).toFixed(precision));
};

// This function handles deep equality comparison between values
const compareValues = (a, b) => {
  // Handle simple equality cases
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (typeof a !== typeof b) return false;

  // For numbers, account for floating point precision
  if (typeof a === "number" && typeof b === "number") {
    return Math.abs(a - b) < 0.001;
  }

  // Handle objects (including arrays)
  if (typeof a === "object") {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    return keysA.every((key) => compareValues(a[key], b[key]));
  }

  return false;
};

// This function analyzes changes between package items
const getPackageItemChanges = (prevItems = [], nextItems = [], packageId) => {
  const changes = [];
  const prevMap = new Map(
    prevItems.map((item) => [item.package_item_id, item])
  );
  const nextMap = new Map(
    nextItems.map((item) => [item.package_item_id, item])
  );

  // Check for modified and added items
  for (const [itemId, nextItem] of nextMap.entries()) {
    const prevItem = prevMap.get(itemId);

    if (!prevItem) {
      // New item was added
      changes.push({
        type: "item_added",
        packageId,
        itemId,
        details: {
          name: nextItem.name,
          description: nextItem.description,
          quantity: nextItem.quantity,
        },
      });
      continue;
    }

    // Compare item properties for modifications
    const propertiesToCheck = [
      "name",
      "description",
      "quantity",
      "weight",
      "value",
    ];

    for (const prop of propertiesToCheck) {
      if (!compareValues(prevItem[prop], nextItem[prop])) {
        changes.push({
          type: "item_modified",
          packageId,
          itemId,
          field: prop,
          previous: prevItem[prop],
          new: nextItem[prop],
        });
      }
    }
  }

  // Check for removed items
  for (const [itemId, prevItem] of prevMap.entries()) {
    if (!nextMap.has(itemId)) {
      changes.push({
        type: "item_removed",
        packageId,
        itemId,
        details: {
          name: prevItem.name,
          description: prevItem.description,
          quantity: prevItem.quantity,
        },
      });
    }
  }

  return changes;
};

// This function analyzes changes between packages
const getPackageChanges = (prevPackages = [], nextPackages = []) => {
  const changes = [];
  const prevMap = new Map(prevPackages.map((pkg) => [pkg.package_id, pkg]));
  const nextMap = new Map(nextPackages.map((pkg) => [pkg.package_id, pkg]));

  // Check for modified and added packages
  for (const [packageId, nextPackage] of nextMap.entries()) {
    const prevPackage = prevMap.get(packageId);

    if (!prevPackage) {
      changes.push({
        type: "package_added",
        packageId,
        details: {
          weight: nextPackage.weight,
          dimensions: nextPackage.dimensions,
          declared_value: nextPackage.declared_value,
        },
      });
      continue;
    }

    // Compare package properties
    const propertiesToCheck = [
      "weight",
      "dimensions",
      "declared_value",
      "fragile",
      "hazardous",
    ];

    for (const prop of propertiesToCheck) {
      if (!compareValues(prevPackage[prop], nextPackage[prop])) {
        changes.push({
          type: "package_modified",
          packageId,
          field: prop,
          previous: prevPackage[prop],
          new: nextPackage[prop],
        });
      }
    }

    // Compare package items
    const itemChanges = getPackageItemChanges(
      prevPackage.PackageItems,
      nextPackage.PackageItems,
      packageId
    );
    changes.push(...itemChanges);
  }

  // Check for removed packages
  for (const [packageId, prevPackage] of prevMap.entries()) {
    if (!nextMap.has(packageId)) {
      changes.push({
        type: "package_removed",
        packageId,
        details: {
          weight: prevPackage.weight,
          dimensions: prevPackage.dimensions,
          declared_value: prevPackage.declared_value,
        },
      });
    }
  }

  return changes;
};

// This function formats values for display
const formatDisplayValue = (value, field) => {
  if (value === null || value === undefined) return "N/A";

  if (field.includes("weight")) {
    return `${formatNumber(value, "weight")} lbs`;
  }

  if (field.includes("value")) {
    return `$${formatNumber(value, "value")}`;
  }

  if (field === "dimensions") {
    if (typeof value === "object") {
      const { length = 0, width = 0, height = 0 } = value;
      return `${length}" × ${width}" × ${height}"`;
    }
    return `${formatNumber(value, "dimensions")} in`;
  }

  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  return value.toString();
};

// The main component
const AuditLogTimeline = () => {
  const { orderId } = useParams();
  const { api } = useContext(AuthContext);
  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAuditLogs = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/shipping-order-audit/${orderId}`);

        // Process and sort the logs
        const processedLogs = response.data
          .map((log) => ({
            ...log,
            changes: getPackageChanges(
              log.previous_value?.Packages,
              log.new_value?.Packages
            ),
          }))
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setAuditLogs(processedLogs);
      } catch (err) {
        setError("Failed to fetch audit logs. Please try again.");
        console.error("Error fetching audit logs:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAuditLogs();
  }, [api, orderId]);

  const getEventIcon = (eventType) => {
    // Collaboration event types
    if (eventType.includes('COLLABORATION')) {
      if (eventType.includes('REQUESTED')) return <CompareArrowsIcon />;
      if (eventType.includes('ACCEPTED')) return <CheckCircleIcon color="success" />;
      if (eventType.includes('REJECTED') || eventType.includes('CANCELLED')) return <CancelIcon color="error" />;
      if (eventType.includes('COMPLETED') || eventType.includes('AUTO_COMPLETED')) return <CheckCircleIcon color="success" />;
      if (eventType.includes('PAYMENT') || eventType.includes('RECEIPT')) return <MonetizationOnIcon color="primary" />;
      return <CompareArrowsIcon />;
    }
    
    // Regular event types
    switch (eventType) {
      case 'create':
        return <AddBoxIcon />;
      case 'update':
        return <UpdateIcon />;
      case 'delete':
        return <DeleteForeverIcon />;
      case 'edit':
        return <EditIcon />;
      default:
        return <CreateIcon />;
    }
  };

  // Get color for event type
  const getEventColor = (eventType) => {
    // Only use valid Material UI timeline colors: 'inherit', 'grey', 'primary', 'secondary', 'error', 'info', 'success', 'warning'
    if (eventType.includes('COLLABORATION')) {
      if (eventType.includes('REQUESTED')) return 'primary';
      if (eventType.includes('ACCEPTED')) return 'success';
      if (eventType.includes('REJECTED') || eventType.includes('CANCELLED')) return 'error';
      if (eventType.includes('COMPLETED') || eventType.includes('AUTO_COMPLETED')) return 'success';
      if (eventType.includes('PAYMENT') || eventType.includes('RECEIPT')) return 'info';
      return 'grey'; // Use 'grey' instead of 'default' as default is not a valid color
    }
    
    return 'primary';
  };

  // This function renders collaboration-specific event details
  const renderCollaborationDetails = (log) => {
    console.log('Rendering collaboration details for log:', log);
    if (!log.event_type.includes('COLLABORATION')) return null;
    
    let details = null;
    
    // Try to parse values
    const previousValue = log.previous_value ? 
      (typeof log.previous_value === 'string' ? JSON.parse(log.previous_value) : log.previous_value) : null;
    
    const newValue = log.new_value ? 
      (typeof log.new_value === 'string' ? JSON.parse(log.new_value) : log.new_value) : null;
    
    console.log('Previous value:', previousValue);
    console.log('New value:', newValue);

    // Extract financial details from a collaboration
    const formatFinancialDetails = (value) => {
      if (!value) return null;
      
      return (
        <List dense>
          {value.agreed_compensation && (
            <ListItem>
              <ListItemText 
                primary="Agreed Compensation" 
                secondary={`$${parseFloat(value.agreed_compensation).toFixed(2)}`} 
              />
            </ListItem>
          )}
          {value.calculated_price && (
            <ListItem>
              <ListItemText 
                primary="Calculated Price" 
                secondary={`$${parseFloat(value.calculated_price).toFixed(2)}`} 
              />
            </ListItem>
          )}
          {value.total_cost && (
            <ListItem>
              <ListItemText 
                primary="Total Cost" 
                secondary={`$${parseFloat(value.total_cost).toFixed(2)}`} 
              />
            </ListItem>
          )}
          {value.payment_status && (
            <ListItem>
              <ListItemText 
                primary="Payment Status" 
                secondary={
                  <Chip 
                    label={value.payment_status} 
                    color={
                      value.payment_status === 'paid' ? 'success' :
                      value.payment_status === 'unpaid' ? 'warning' :
                      value.payment_status === 'disputed' ? 'error' : 'default'
                    }
                    size="small"
                  />
                } 
              />
            </ListItem>
          )}
          {value.receipt_number && (
            <ListItem>
              <ListItemText 
                primary="Receipt Number" 
                secondary={value.receipt_number} 
              />
            </ListItem>
          )}
        </List>
      );
    };
    
    // Render based on event type
    if (log.event_type.includes('REQUESTED')) {
      details = (
        <Box>
          <Typography variant="subtitle2" gutterBottom>Collaboration Details:</Typography>
          {formatFinancialDetails(newValue)}
          {newValue && newValue.special_instructions && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2">Special Instructions:</Typography>
              <Paper elevation={0} sx={{ p: 1, bgcolor: 'grey.100' }}>
                <Typography variant="body2">{newValue.special_instructions}</Typography>
              </Paper>
            </Box>
          )}
        </Box>
      );
    } else if (log.event_type.includes('STATUS_UPDATED') || 
              log.event_type.includes('ACCEPTED') || 
              log.event_type.includes('REJECTED') || 
              log.event_type.includes('CANCELLED') ||
              log.event_type.includes('COMPLETED')) {
      // Status change
      console.log('Handling status change event');
      console.log('Previous value status:', previousValue?.status);
      console.log('New value status:', newValue?.status);
      
      // First, try to find status in the most common locations
      let prevStatus = previousValue?.status;
      let newStatus = newValue?.status;
      
      // If status isn't found directly, look for it in various property names
      if (previousValue) {
        if (!prevStatus) {
          // Check camelCase and snake_case variations
          prevStatus = previousValue.oldStatus || previousValue.old_status || 
                      previousValue.previousStatus || previousValue.previous_status;
          
          // Log which property we found it in
          if (previousValue.oldStatus) console.log('Found previous status in oldStatus:', previousValue.oldStatus);
          else if (previousValue.old_status) console.log('Found previous status in old_status:', previousValue.old_status);
          else if (previousValue.previousStatus) console.log('Found previous status in previousStatus:', previousValue.previousStatus);
          else if (previousValue.previous_status) console.log('Found previous status in previous_status:', previousValue.previous_status);
        }
      }
      
      if (newValue) {
        if (!newStatus) {
          // Check camelCase and snake_case variations
          newStatus = newValue.newStatus || newValue.new_status || 
                     newValue.currentStatus || newValue.current_status;
          
          // Log which property we found it in
          if (newValue.newStatus) console.log('Found new status in newStatus:', newValue.newStatus);
          else if (newValue.new_status) console.log('Found new status in new_status:', newValue.new_status);
          else if (newValue.currentStatus) console.log('Found new status in currentStatus:', newValue.currentStatus);
          else if (newValue.current_status) console.log('Found new status in current_status:', newValue.current_status);
        }
      }
      
      // For COLLABORATION_REQUESTED, extract status directly from event description
      if (!prevStatus && !newStatus && log.event_type === 'COLLABORATION_REQUESTED') {
        newStatus = 'proposed';
        console.log('Using default status "proposed" for COLLABORATION_REQUESTED event');
      }
      
      details = (
        <Box>
          <Typography variant="subtitle2" gutterBottom>Status Change:</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Chip 
                label={prevStatus || 'unknown'} 
                color={prevStatus === 'accepted' ? 'success' : 
                      prevStatus === 'rejected' ? 'error' : 'default'}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Chip 
                label={newStatus || 'unknown'} 
                color={newStatus === 'accepted' ? 'success' : 
                      newStatus === 'rejected' ? 'error' : 
                      newStatus === 'completed' ? 'success' : 'default'}
                size="small"
              />
            </Grid>
          </Grid>
          {newValue && log.event_type.includes('AUTO_COMPLETED') && (
            <Alert severity="info" sx={{ mt: 1 }}>
              This collaboration was automatically completed when the order was marked as delivered.
            </Alert>
          )}
        </Box>
      );
    } else if (log.event_type.includes('PAYMENT') || log.event_type.includes('RECEIPT')) {
      // Payment or receipt details
      details = (
        <Box>
          <Typography variant="subtitle2" gutterBottom>Payment Details:</Typography>
          {formatFinancialDetails(newValue)}
          {newValue?.payment_details && (
            <Box sx={{ mt: 1 }}>
              <Divider sx={{ my: 1 }} />
              <Typography variant="subtitle2">Payment Information:</Typography>
              <List dense>
                <ListItem>
                  <ListItemText 
                    primary="Payment Method" 
                    secondary={newValue.payment_details.payment_method} 
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Payment Date" 
                    secondary={new Date(newValue.payment_details.payment_date).toLocaleDateString()} 
                  />
                </ListItem>
                {newValue.payment_details.notes && (
                  <ListItem>
                    <ListItemText 
                      primary="Notes" 
                      secondary={newValue.payment_details.notes} 
                    />
                  </ListItem>
                )}
              </List>
            </Box>
          )}
        </Box>
      );
    } else if (log.event_type.includes('EDITED')) {
      // Collaboration edit
      const changes = {};
      
      if (previousValue && newValue) {
        if (previousValue.agreed_compensation !== newValue.agreed_compensation) {
          changes.agreed_compensation = {
            previous: previousValue.agreed_compensation,
            new: newValue.agreed_compensation
          };
        }
        
        if (previousValue.special_instructions !== newValue.special_instructions) {
          changes.special_instructions = {
            previous: previousValue.special_instructions,
            new: newValue.special_instructions
          };
        }
        
        // Handle additional charges changes
        if (JSON.stringify(previousValue.additional_charges) !== JSON.stringify(newValue.additional_charges)) {
          changes.additional_charges = {
            previous: previousValue.additional_charges,
            new: newValue.additional_charges
          };
        }
      }
      
      details = (
        <Box>
          <Typography variant="subtitle2" gutterBottom>Collaboration Edits:</Typography>
          {Object.keys(changes).length > 0 ? (
            Object.entries(changes).map(([field, values]) => (
              <Paper key={field} sx={{ p: 1, mb: 1 }}>
                <Typography variant="body2" color="primary">
                  {field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">Previous:</Typography>
                    {field === 'additional_charges' ? (
                      <List dense>
                        {(values.previous || []).map((charge, i) => (
                          <ListItem key={i}>
                            <ListItemText 
                              primary={charge.description} 
                              secondary={`$${parseFloat(charge.amount).toFixed(2)}`} 
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography variant="body2">{values.previous}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">New:</Typography>
                    {field === 'additional_charges' ? (
                      <List dense>
                        {(values.new || []).map((charge, i) => (
                          <ListItem key={i}>
                            <ListItemText 
                              primary={charge.description} 
                              secondary={`$${parseFloat(charge.amount).toFixed(2)}`} 
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography variant="body2">{values.new}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            ))
          ) : (
            <Typography variant="body2">No specific changes detected</Typography>
          )}
        </Box>
      );
    }
    
    return details;
  };

  // This function renders the details of a change
  const renderChangeDetails = (change) => {
    const renderValue = (label, value, field) => (
      <Box sx={{ mb: 1 }}>
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="body1">
          {formatDisplayValue(value, field)}
        </Typography>
      </Box>
    );

    switch (change.type) {
      case "package_added":
      case "package_removed":
        return (
          <Box sx={{ mt: 1 }}>
            {renderValue("Weight", change.details.weight, "weight")}
            {renderValue("Dimensions", change.details.dimensions, "dimensions")}
            {renderValue(
              "Declared Value",
              change.details.declared_value,
              "value"
            )}
          </Box>
        );

      case "package_modified":
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderValue("Previous", change.previous, change.field)}
            </Grid>
            <Grid item xs={6}>
              {renderValue("New", change.new, change.field)}
            </Grid>
          </Grid>
        );

      case "item_added":
      case "item_removed":
        return (
          <Box sx={{ mt: 1 }}>
            {renderValue("Name", change.details.name, "text")}
            {renderValue("Description", change.details.description, "text")}
            {renderValue("Quantity", change.details.quantity, "number")}
          </Box>
        );

      case "item_modified":
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {renderValue("Previous", change.previous, change.field)}
            </Grid>
            <Grid item xs={6}>
              {renderValue("New", change.new, change.field)}
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  // Render loading state
  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Render error state
  if (error) {
    return (
      <Box sx={{ mt: 5 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  // Render empty state
  if (!auditLogs.length) {
    return (
      <Box sx={{ mt: 5 }}>
        <Alert severity="info">No audit logs available.</Alert>
      </Box>
    );
  }

  // Render the timeline
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "60vh",
        flex: 1,
        width: "100%",
        p: 2,
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Typography variant="h6">Audit Log Timeline</Typography>
      </Box>
      <Timeline position="alternate" sx={{ width: "100%" }}>
        {auditLogs.map((log) => (
          <TimelineItem key={log.audit_id}>
            <TimelineOppositeContent color="text.secondary">
              {new Date(log.createdAt).toLocaleString()}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={getEventColor(log.event_type)}>
                {getEventIcon(log.event_type)}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  >
                    {log.event_type}
                    <Typography variant="body2" color="text.secondary">
                      {log.event_description}
                    </Typography>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {log.event_type.includes('COLLABORATION') ? (
                    // Render collaboration-specific details
                    renderCollaborationDetails(log)
                  ) : (
                    // Render regular package changes
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                      {log.changes.map((change, index) => (
                        <Paper key={index} sx={{ p: 2 }}>
                          <Typography color="primary" gutterBottom>
                            {`Package ${change.packageId.slice(
                              0,
                              8
                            )} - ${change.type.replace(/_/g, " ")}`}
                          </Typography>
                          {renderChangeDetails(change)}
                        </Paper>
                      ))}
                    </Box>
                  )}
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ mt: 2, display: "block" }}
                  >
                    Performed by: {log.performed_by}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default AuditLogTimeline;