import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import { EmployeePageLayout } from "../../components/Employee/PageComponents";
import EmployeeDashboardOverview from "./dashboard/EmployeeDashboardOverview";
import EmployeeActionItems from "./dashboard/EmployeeActionItems";
import "./dashboard/EmployeeDashboardPage.css";

const EmployeeDashboard = () => {
  const { businessId } = useParams();
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();
  
  // Get context from layout
  const { currentBusiness, dashboardData, loading: layoutLoading, error: layoutError } = useOutletContext();

  const [actionItems, setActionItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Define the fetchActionItems function
  const fetchActionItems = useCallback(async () => {
    try {
      setLoading(true);
      
      // Fetch employee action items
      const actionItemsResponse = await api.get(
        `/employee-portal/${businessId}/action-items`
      );
      
      if (actionItemsResponse.data) {
        setActionItems(actionItemsResponse.data);
      }

      setError(null);
    } catch (err) {
      console.error("Error fetching action items:", err);
      setError(err.response?.data?.error || "Failed to load action items");
    } finally {
      setLoading(false);
    }
  }, [api, businessId, setLoading, setActionItems, setError]);
  
  // Load dashboard action items from the API
  useEffect(() => {
    if (businessId && dashboardData) {
      fetchActionItems();
    }
  }, [businessId, dashboardData, fetchActionItems]);

  const handleRetry = () => {
    setLoading(true);
    fetchActionItems();
  };

  // Get role-specific title
  const getPageTitle = () => {
    const roles = dashboardData?.employee?.roles || [];
    
    if (roles.includes("Owner")) return "Owner Dashboard";
    if (roles.includes("Administrator")) return "Admin Dashboard";
    if (roles.includes("Manager")) return "Manager Dashboard";
    if (roles.includes("Courier")) return "Courier Dashboard";
    if (roles.includes("Customer Service")) return "Support Dashboard";
    
    return "Employee Dashboard";
  };

  return (
    <EmployeePageLayout
      title={getPageTitle()}
      loading={layoutLoading || loading}
      error={layoutError || error}
      onRetry={handleRetry}
    >
      <EmployeeDashboardOverview
        business={currentBusiness}
        dashboardData={dashboardData}
      />
      
      <EmployeeActionItems
        businessId={businessId}
        actionItems={actionItems}
        employee={dashboardData?.employee}
      />
    </EmployeePageLayout>
  );
};

export default EmployeeDashboard;
