import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  TextField,
  Button,
  Chip,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
  Typography,
  Divider,
  InputAdornment,
  Alert,
  IconButton,
} from "@mui/material";
import {
  Search as SearchIcon,
  Business as BusinessIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../components/Auth/AuthContext";

/**
 * Component for searching and selecting businesses
 * @param {Array} selectedBusinessIds - Array of currently selected business IDs
 * @param {Function} onChange - Callback function when selection changes
 * @param {String} businessId - Current business ID (to exclude from results)
 */
const BusinessSelector = ({
  selectedBusinessIds = [],
  onChange,
  businessId,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [error, setError] = useState(null);
  const [debug, setDebug] = useState(false);
  const { api } = useContext(AuthContext);
  const searchTimeout = useRef(null);

  // Initialize selected businesses from IDs
  useEffect(() => {
    const fetchSelectedBusinesses = async () => {
      if (selectedBusinessIds && selectedBusinessIds.length > 0) {
        console.log(
          "Fetching details for selected businesses:",
          selectedBusinessIds
        );
        try {
          // Fetch details for the selected businesses
          const response = await api.get("/shipping-collaboration/businesses", {
            params: { business_ids: selectedBusinessIds.join(',') },
          });
          console.log("Selected businesses API response:", response.data);
          setSelectedBusinesses(response.data || []);
        } catch (error) {
          console.error("Error fetching selected businesses:", error);
          setError(`Error fetching selected businesses: ${error.message}`);
        }
      } else {
        console.log("No selected business IDs to fetch");
      }
    };

    fetchSelectedBusinesses();
  }, [selectedBusinessIds, api]);

  // Fetch initial list of businesses when component mounts
  useEffect(() => {
    fetchTopBusinesses();
  }, [businessId]); // Re-fetch if businessId changes

  // Function to fetch some businesses without a search query
  const fetchTopBusinesses = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      // Use an empty query to fetch recent/active businesses
      const response = await api.get("/business/search-eligible-businesses", {
        params: {
          business_id: businessId,
          page: 1,
          limit: 10, // Limit initial results
          sort: "active", // Optional - if your API supports sorting
        },
      });

      console.log("Initial businesses response:", response.data);

      if (!response.data) {
        console.error("API response missing data property");
        setError("Invalid API response format");
        setSearchResults([]);
        return;
      }

      const businesses = response.data.businesses || [];
      console.log(`Found ${businesses.length} initial businesses`);

      setSearchResults(businesses);
    } catch (error) {
      console.error("Error fetching initial businesses:", error);
      setError(`Error fetching businesses: ${error.message}`);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  }, [api, businessId]);

  // Search for businesses with enhanced logging
  const searchBusinesses = useCallback(async (query = searchQuery) => {
    if (!query.trim()) {
      console.log("Empty search query, fetching top businesses instead");
      fetchTopBusinesses();
      return;
    }

    setLoading(true);
    setError(null);

    const requestParams = {
      query,
      business_id: businessId,
      page: 1,
      limit: 20,
    };

    console.log("Searching businesses with params:", requestParams);

    try {
      const response = await api.get("/business/search-eligible-businesses", {
        params: requestParams,
      });

      console.log("Business search API response:", response);

      if (!response.data) {
        console.error("API response missing data property");
        setError("Invalid API response format");
        setSearchResults([]);
        return;
      }

      const businesses = response.data.businesses || [];
      console.log(`Found ${businesses.length} businesses:`, businesses);

      setSearchResults(businesses);

      if (businesses.length === 0) {
        console.log("No businesses found matching the search query");
      }
    } catch (error) {
      console.error("Error searching businesses:", error);
      console.error("Error details:", error.response || error.message);
      setError(`Error searching businesses: ${error.message}`);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  }, [api, businessId, searchQuery, fetchTopBusinesses]);

  // Handle search query change with debounce
  const handleSearchChange = useCallback((e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    // Clear any existing timeout
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    
    // Set a new timeout for the search
    searchTimeout.current = setTimeout(() => {
      searchBusinesses(query);
    }, 500); // 500ms debounce
  }, [searchBusinesses]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  // Add a business to the selected list
  const addBusiness = useCallback(
    (business) => {
      console.log("Adding business to selection:", business);

      if (!selectedBusinessIds.includes(business.business_id)) {
        const newSelectedIds = [...selectedBusinessIds, business.business_id];
        const newSelectedBusinesses = [...selectedBusinesses, business];

        console.log("Updated selected business IDs:", newSelectedIds);
        console.log("Updated selected businesses:", newSelectedBusinesses);

        setSelectedBusinesses(newSelectedBusinesses);
        onChange(newSelectedIds);
      } else {
        console.log("Business already selected, skipping");
      }
    },
    [selectedBusinessIds, selectedBusinesses, onChange]
  );

  // Remove a business from the selected list
  const removeBusiness = useCallback(
    (businessId) => {
      console.log("Removing business from selection:", businessId);

      const newSelectedIds = selectedBusinessIds.filter(
        (id) => id !== businessId
      );
      const newSelectedBusinesses = selectedBusinesses.filter(
        (b) => b.business_id !== businessId
      );

      console.log(
        "Updated selected business IDs after removal:",
        newSelectedIds
      );
      console.log(
        "Updated selected businesses after removal:",
        newSelectedBusinesses
      );

      setSelectedBusinesses(newSelectedBusinesses);
      onChange(newSelectedIds);
    },
    [selectedBusinessIds, selectedBusinesses, onChange]
  );

  // Toggle debug mode
  const toggleDebug = useCallback(() => {
    setDebug((prev) => !prev);
  }, []);

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="subtitle1">
          Select Businesses with Access
        </Typography>
        <IconButton
          size="small"
          onClick={toggleDebug}
          title="Toggle Debug Info"
        >
          <InfoIcon fontSize="small" color={debug ? "primary" : "action"} />
        </IconButton>
      </Box>

      {/* Search Input */}
      <Box sx={{ display: "flex", mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search for businesses..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyPress={(e) => e.key === "Enter" && searchBusinesses()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          onClick={() => searchBusinesses()}
          sx={{ ml: 1 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </Box>

      {/* Debug Info */}
      {debug && (
        <Box sx={{ mb: 2 }}>
          <Alert severity="info" sx={{ mb: 1 }}>
            <Typography variant="subtitle2">Debug Information:</Typography>
            <Typography variant="body2">
              Business ID: {businessId || "Not set"}
            </Typography>
            <Typography variant="body2">
              Search Query: {searchQuery || "Empty"}
            </Typography>
            <Typography variant="body2">
              Results Count: {searchResults.length}
            </Typography>
            <Typography variant="body2">
              Selected Count: {selectedBusinessIds.length}
            </Typography>
            <Typography variant="body2">
              Loading State: {loading ? "True" : "False"}
            </Typography>
          </Alert>
        </Box>
      )}

      {/* Error Message */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Selected Businesses */}
      {selectedBusinesses.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Selected Businesses:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selectedBusinesses.map((business) => (
              <Chip
                key={business.business_id}
                label={business.business_name}
                onDelete={() => removeBusiness(business.business_id)}
                avatar={
                  business.business_profile_picture_url ? (
                    <Avatar src={business.business_profile_picture_url} />
                  ) : (
                    <Avatar>
                      <BusinessIcon />
                    </Avatar>
                  )
                }
              />
            ))}
          </Box>
        </Box>
      )}

      {/* Search Results */}
      {searchResults.length > 0 ? (
        <Paper sx={{ mt: 2, maxHeight: 300, overflow: "auto" }}>
          <List dense>
            {searchResults.map((business) => (
              <React.Fragment key={business.business_id}>
                <ListItem
                  button
                  onClick={() => addBusiness(business)}
                  disabled={selectedBusinessIds.includes(business.business_id)}
                  sx={{
                    cursor: selectedBusinessIds.includes(business.business_id)
                      ? "default"
                      : "pointer",
                    bgcolor: selectedBusinessIds.includes(business.business_id)
                      ? "rgba(0, 0, 0, 0.04)"
                      : "inherit",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={business.business_profile_picture_url || ""}>
                      <BusinessIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={business.business_name || "Unnamed Business"}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {business.business_email || "No email available"}
                        </Typography>
                        {business.formattedLocation && (
                          <Typography
                            component="span"
                            variant="body2"
                            style={{ display: "block" }}
                          >
                            {business.formattedLocation}
                          </Typography>
                        )}
                        {debug && (
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            ID: {business.business_id}
                          </Typography>
                        )}
                      </React.Fragment>
                    }
                  />
                  {selectedBusinessIds.includes(business.business_id) ? (
                    <Chip size="small" label="Selected" color="primary" />
                  ) : (
                    <Button size="small" variant="outlined">
                      Add
                    </Button>
                  )}
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      ) : (
        searchQuery &&
        !loading && (
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              border: "1px dashed #ccc",
              borderRadius: 1,
              mt: 2,
            }}
          >
            <Typography color="text.secondary">
              No businesses found matching "{searchQuery}".
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Try a different search term or check the business name spelling.
            </Typography>
          </Box>
        )
      )}

      {/* Loading State */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
          <CircularProgress size={40} />
        </Box>
      )}
    </Box>
  );
};

export default BusinessSelector;
