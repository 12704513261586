import { useState, useEffect } from 'react';
import qzTrayManager from '../utils/printing/qzTrayManager';

/**
 * React hook for interacting with QZ Tray
 * Provides connection status and printing functions
 */
const useQzTray = () => {
  const [isConnected, setIsConnected] = useState(qzTrayManager.isConnected());
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(null);
  
  // Listen for connection status changes
  useEffect(() => {
    const removeListener = qzTrayManager.addConnectionListener((connected, err) => {
      setIsConnected(connected);
      setIsConnecting(false);
      setError(err);
    });
    
    // Clean up listener on unmount
    return () => removeListener();
  }, []);
  
  /**
   * Connect to QZ Tray
   */
  const connect = async () => {
    try {
      setIsConnecting(true);
      setError(null);
      await qzTrayManager.connect();
      return true;
    } catch (err) {
      setError(err);
      return false;
    } finally {
      setIsConnecting(false);
    }
  };
  
  /**
   * Disconnect from QZ Tray
   */
  const disconnect = async () => {
    try {
      await qzTrayManager.disconnect();
      return true;
    } catch (err) {
      setError(err);
      return false;
    }
  };
  
  /**
   * Print data to a printer
   * @param {string} printer Printer name
   * @param {Object} config Printer configuration
   * @param {Array} data Print data
   */
  const print = async (printer, config, data) => {
    try {
      setError(null);
      return await qzTrayManager.print(printer, config, data);
    } catch (err) {
      setError(err);
      throw err;
    }
  };
  
  return {
    isConnected,
    isConnecting,
    error,
    connect,
    disconnect,
    print,
    
    // For advanced usage:
    manager: qzTrayManager
  };
};

export default useQzTray;