const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    try {
      import("web-vitals")
        .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
          getCLS(onPerfEntry);
          getFID(onPerfEntry);
          getFCP(onPerfEntry);
          getLCP(onPerfEntry);
          getTTFB(onPerfEntry);
        })
        .catch((error) => {
          console.warn("Could not load web-vitals:", error);
        });
    } catch (error) {
      console.warn("Error in reportWebVitals:", error);
    }
  }
};

export default reportWebVitals;
