import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Typography, Button, Box, Skeleton } from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import LocationTrackingView from "./LocationTrackingView";
import { useLocationTracking } from "./LocationTrackingContext";

const BUSINESS_CATEGORY_CACHE_KEY = "business_categories";

const LocationTrackingContent = () => {
  const { businessId } = useParams();
  const [refreshing, setRefreshing] = useState(false);
  const { fetchLocationData } = useLocationTracking();

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await fetchLocationData(businessId);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <Box sx={{ height: "100%", overflowY: "auto", padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h4">Location Tracking</Typography>
        <Button
          variant="contained"
          startIcon={
            refreshing ? (
              <RefreshIcon className="animate-spin" />
            ) : (
              <RefreshIcon />
            )
          }
          onClick={handleRefresh}
          disabled={refreshing}
        >
          {refreshing ? "Refreshing..." : "Refresh Data"}
        </Button>
      </Box>

      <LocationTrackingView />
    </Box>
  );
};

const LoadingSkeleton = () => (
  <Box sx={{ padding: 2 }}>
    <Skeleton variant="rectangular" width="100%" height={118} />
    <Skeleton variant="text" sx={{ marginTop: 2 }} />
    <Skeleton variant="text" />
    <Skeleton variant="text" width="60%" />
  </Box>
);

const LocationTrackingPage = () => {
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const [currentBusiness] = useState(() => getBusinessById(businessId));
  const location = useLocation();

  // Memoize the business data lookup
  const businessData = useMemo(
    () => getBusinessById(businessId),
    [businessId, getBusinessById]
  );

  return (
    <div className="business-page">
      <main className="business-page-content">
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                classNames="page"
                timeout={300}
              >
                <Routes>
                  <Route path="/" element={<LocationTrackingContent />} />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
      </main>
    </div>
  );
};

export default LocationTrackingPage;
