import React, { useState, useEffect, useCallback, useContext } from "react";
import BusinessSelector from "../../../components/BusinessSelector/BusinessSelector";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthContext";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
  Chip,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Switch,
  ListItemText,
  FormHelperText,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Slider,
  Tooltip,
  List,
  ListItem,
  Avatar,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
  CreditCard as CreditCardIcon,
  Business as BusinessIcon,
  LocalShipping,
  AttachMoney,
  Schedule,
  Scale,
  Straighten,
  Security,
  AcUnit,
  Gavel,
  EnergySavingsLeaf as EnergySavingsLeafIcon,
  Restore,
  FlightTakeoff,
  Weekend,
} from "@mui/icons-material";

import BusinessHeader from "../../../components/Header/BusinessDashboardHeader";
import BusinessSidebar from "../../../components/Sidebar/BusinessSidebar";
import DashboardFooter from "../../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../../components/Background/ParallaxBackground/ParallaxBackground";
import { useBusiness } from "../../../components/Auth/BusinessContext";
import InsuranceManagement from "../../../components/Insurance/InsuranceManagement";
import styles from "./BusinessServicePage.module.css";

const priceModelOptions = [
  { label: "Flat Rate", value: "FlatRate" },
  { label: "Weight-Based", value: "WeightBased" },
  { label: "Distance-Based", value: "DistanceBased" },
  // { label: "Value-Based", value: "ValueBased" },
  { label: "Dimensional", value: "Dimensional" },
];

const BusinessServicesPage = () => {
  const { api } = useContext(AuthContext);
  const { businessId } = useParams();
  const { getBusinessById } = useBusiness();
  const [shippingTiers, setShippingTiers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);
  const [editingTierId, setEditingTierId] = useState(null);
  const [newTier, setNewTier] = useState(null);
  const [errors, setErrors] = useState({});
  const [localErrors, setLocalErrors] = useState({});
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  // Component to display allowed businesses for restricted tiers
  const AllowedBusinessesList = ({ tierId }) => {
    const { api } = useContext(AuthContext);
    const [businesses, setBusinesses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [accessLevel, setAccessLevel] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
      const fetchAllowedBusinesses = async () => {
        setLoading(true);
        try {
          const response = await api.get(
            `/shipping-tiers/${tierId}/allowed-businesses`
          );
          setBusinesses(response.data.businesses || []);
          setAccessLevel(response.data.access_level);
          setMessage(response.data.message);
        } catch (err) {
          console.error("Error fetching allowed businesses:", err);
          setError("Failed to load businesses with access to this tier");
        } finally {
          setLoading(false);
        }
      };

      if (tierId) {
        fetchAllowedBusinesses();
      }
    }, [tierId, api]);

    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (error) {
      return <Alert severity="error">{error}</Alert>;
    }

    if (accessLevel !== "restricted") {
      return <Alert severity="info">{message}</Alert>;
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Alert severity="info" sx={{ mb: 2 }}>
          {message}
        </Alert>
        {businesses.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No businesses currently have access to this tier.
          </Typography>
        ) : (
          <Grid container spacing={1}>
            {businesses.map((business) => (
              <Grid item xs={12} key={business.business_id}>
                <Paper sx={{ p: 1, display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={business.business_profile_picture_url}
                    sx={{ mr: 2 }}
                  >
                    <BusinessIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle2">
                      {business.business_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {business.formattedLocation}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  };

  const [currentBusiness, setCurrentBusiness] = useState(() => {
    const savedBusiness = localStorage.getItem(`business_${businessId}`);
    return savedBusiness ? JSON.parse(savedBusiness) : null;
  });

  useEffect(() => {
    localStorage.setItem(
      "businessSidebarActive",
      JSON.stringify(sidebarActive)
    );
  }, [sidebarActive]);

  useEffect(() => {
    if (businessId) {
      const business = getBusinessById(businessId);
      setCurrentBusiness(business);
    }
  }, [businessId, getBusinessById]);

  useEffect(() => {
    const fetchShippingTiers = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching shipping tiers...");
        console.log("businessId", businessId);
        const response = await api.get(`/shipping-tiers`, {
          params: {
            business_id: businessId,
            requesting_business_id: businessId,
          },
        });
        console.log("Response data:", response.data);
        setShippingTiers(response.data.tiers);

        // Update payment method status based on API response
        if (response.data.business_payment_status) {
          setHasValidPaymentMethod(
            response.data.business_payment_status.has_valid_payment_method
          );

          // Display warning if there are active services but no payment method
          if (
            !response.data.business_payment_status.has_valid_payment_method &&
            response.data.tiers.some((tier) => tier.is_active_service)
          ) {
            setAlertMessage({
              type: "warning",
              message:
                "Your services are not visible to others because you don't have a valid payment method. Please add a payment method to make your services visible.",
              finances_url: response.data.business_payment_status.finances_url,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching shipping tiers:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to fetch shipping tiers. Please try again.",
        });
      }
      setIsLoading(false);
    };

    fetchShippingTiers();
  }, [businessId, api]);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
    localStorage.setItem("sidebarActive", JSON.stringify(isActive));
  }, []);

  const handleAddNewTier = () => {
    setNewTier({
      tier_name: "",
      description: "",
      transportation_modes: [],
      estimated_delivery_time: { min: 1, max: 3, unit: "days" },
      price_model: [],
      base_price_flat_rate: 0,
      base_price_weight: 0,
      base_price_distance: 0,
      base_price_value: 0,
      base_price_dimensions: 0,
      min_weight: 0,
      max_weight: 0,
      min_dimensions: { length: 0, width: 0, height: 0 },
      max_dimensions: { length: 0, width: 0, height: 0 },
      service_level: "Standard",
      signature_required: false,
      insurance_option: "NotAvailable",
      insurance_fee: 0,
      max_insurance_coverage: 0,
      temperature_controlled: false,
      temperature_range: { min: 0, max: 0, unit: "Celsius" },
      customs_clearance_included: false,
      // tracking_frequency: "LimitedMilestones",
      allowed_contents: [],
      restrictions: [],
      hazardous_materials_handling: false,
      white_glove_services: false,
      eco_friendly_options: [],
      carbon_offset_available: false,
      packaging_options: [],
      value_added_services: [],
      return_service_included: false,
      priority_boarding: false,
      saturday_delivery: false,
      sunday_delivery: false,
      is_active: true,
      is_active_service: false, // Add this line
    });
    setEditingTierId("new");
  };

  const handleChange = (e, tierId) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (
      [
        "transportation_modes",
        "allowed_contents",
        "restrictions",
        "eco_friendly_options",
        "packaging_options",
        "value_added_services",
      ].includes(name)
    ) {
      newValue = Array.isArray(value)
        ? value
        : value.split(",").map((item) => item.trim());
    } else if (
      [
        "estimated_delivery_time",
        "price_factors",
        "min_dimensions",
        "max_dimensions",
        "temperature_range",
      ].includes(name)
    ) {
      newValue = typeof value === "object" ? value : JSON.parse(value);
    }

    if (tierId === "new") {
      setNewTier((prev) => ({ ...prev, [name]: newValue }));
    } else {
      setShippingTiers((prevTiers) =>
        prevTiers.map((tier) =>
          tier.tier_id === tierId ? { ...tier, [name]: newValue } : tier
        )
      );
    }
  };

  // 1) State for checking payment method
  const [checkingPaymentMethod, setCheckingPaymentMethod] = useState(true);
  const [hasValidPaymentMethod, setHasValidPaymentMethod] = useState(false);

  // 2) useEffect to fetch payment method info
  useEffect(() => {
    if (!businessId) return;

    const checkPaymentMethod = async () => {
      try {
        setCheckingPaymentMethod(true);
        const response = await api.get(
          `/payments/business-payment-methods?businessId=${businessId}`
        );

        if (response.data) {
          // Check if the business has any payment methods
          const hasPaymentMethods =
            response.data.hasPaymentMethods ||
            (response.data.paymentMethods &&
              response.data.paymentMethods.length > 0);

          setHasValidPaymentMethod(hasPaymentMethods);

          // Check if any tier is active without a payment method
          if (
            !hasPaymentMethods &&
            shippingTiers.some((tier) => tier.is_active_service)
          ) {
            setAlertMessage({
              type: "warning",
              message:
                "Your services are not visible to others because you don't have a valid payment method. Please add a payment method to make your services visible.",
            });
          }
        }
      } catch (error) {
        console.error("Error checking payment method:", error);
      } finally {
        setCheckingPaymentMethod(false);
      }
    };

    checkPaymentMethod();
  }, [businessId, api, shippingTiers]); // Added shippingTiers as dependency

  const validateField = (fieldName, value, tier) => {
    let error = "";
    switch (fieldName) {
      case "tier_name":
        if (!value.trim()) error = "Tier name is required";
        break;
      case "description":
        if (!value.trim()) error = "Description is required";
        break;
      case "price_model":
        if (value.length === 0)
          error = "At least one price model must be selected";
        break;
      case "base_price_flat_rate":
      case "base_price_weight":
      case "base_price_distance":
      case "base_price_dimensions":
        if (
          tier &&
          tier.price_model &&
          tier.price_model.includes(fieldName.split("base_price_")[1])
        ) {
          if (value === "" || isNaN(value) || Number(value) <= 0)
            error = "Base price must be a positive number";
        }
        break;
      case "min_weight":
      case "max_weight":
        if (value === "" || isNaN(value) || Number(value) < 0)
          error = "Weight must be a non-negative number";
        break;
      case "estimated_delivery_time_min":
        if (value === "" || isNaN(value) || Number(value) < 0)
          error = "Minimum delivery time must be a non-negative number";
        else if (
          tier &&
          tier.estimated_delivery_time &&
          tier.estimated_delivery_time.max !== undefined &&
          Number(value) > Number(tier.estimated_delivery_time.max)
        )
          error = "Minimum delivery time cannot exceed maximum delivery time";
        break;
      case "estimated_delivery_time_max":
        if (value === "" || isNaN(value) || Number(value) < 0)
          error = "Maximum delivery time must be a non-negative number";
        else if (
          tier &&
          tier.estimated_delivery_time &&
          tier.estimated_delivery_time.min !== undefined &&
          Number(value) < Number(tier.estimated_delivery_time.min)
        )
          error =
            "Maximum delivery time cannot be less than minimum delivery time";
        break;
      default:
        break;
    }
    return error;
  };

  const validateForm = (tier) => {
    const newErrors = {};

    // Required field validations
    [
      "tier_name",
      "description",
      "price_model",
      ...tier.price_model.map((model) => `base_price_${model.toLowerCase()}`),
      "min_weight",
      "max_weight",
      "min_dimensions",
      "max_dimensions",
    ].forEach((field) => {
      const error = validateField(field, tier[field], tier);
      if (error) newErrors[field] = error;
    });

    // Additional checks for min/max dimensions
    // for each convert to float and check if min < max
    ["length", "width", "height"].forEach((dim) => {
      if (
        tier.min_dimensions[dim] !== "" &&
        tier.max_dimensions[dim] !== "" &&
        parseFloat(tier.min_dimensions[dim]) >=
          parseFloat(tier.max_dimensions[dim])
      ) {
        newErrors[
          `min_dimensions.${dim}`
        ] = `Minimum ${dim} must be less than maximum ${dim}`;
        newErrors[
          `max_dimensions.${dim}`
        ] = `Maximum ${dim} must be greater than minimum ${dim}`;
      }
    });

    // Validation for estimated delivery time
    if (
      tier.estimated_delivery_time.min === "" ||
      isNaN(tier.estimated_delivery_time.min) ||
      tier.estimated_delivery_time.min < 0
    ) {
      newErrors.estimated_delivery_time_min =
        "Minimum delivery time must be a non-negative number.";
    }

    if (
      tier.estimated_delivery_time.max === "" ||
      isNaN(tier.estimated_delivery_time.max) ||
      tier.estimated_delivery_time.max < 0
    ) {
      newErrors.estimated_delivery_time_max =
        "Maximum delivery time must be a non-negative number.";
    }

    if (
      tier.estimated_delivery_time.min !== "" &&
      tier.estimated_delivery_time.max !== "" &&
      tier.estimated_delivery_time.min > tier.estimated_delivery_time.max
    ) {
      newErrors.estimated_delivery_time =
        "Minimum delivery time should not exceed maximum delivery time.";
    }

    // Validate active service requirements
    if (tier.is_active_service) {
      if (!tier.price_model || tier.price_model.length === 0) {
        newErrors.price_model =
          "At least one pricing model is required for active services";
      } else {
        // Check if at least one price model has a valid price
        let hasValidPrice = false;
        tier.price_model.forEach((model) => {
          // Map from model name to field name
          let priceField;
          switch (model) {
            case "FlatRate":
              priceField = "base_price_flat_rate";
              break;
            case "WeightBased":
              priceField = "base_price_weight";
              break;
            case "DistanceBased":
              priceField = "base_price_distance";
              break;
            case "Dimensional":
              priceField = "base_price_dimensions";
              break;
            case "ValueBased":
              priceField = "base_price_value";
              break;
            default:
              priceField = `base_price_${model.toLowerCase()}`;
          }

          if (tier[priceField] && parseFloat(tier[priceField]) > 0) {
            hasValidPrice = true;
          }
        });

        if (!hasValidPrice) {
          newErrors.pricing =
            "At least one pricing model must have a valid price greater than zero";
        }
      }

      if (!tier.tier_name.trim()) {
        newErrors.tier_name = "Service name is required";
      }
      if (!tier.description.trim()) {
        newErrors.description = "Service description is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Save (create/update) shipping tier
  const handleSave = async (tierId) => {
    const tierToSave =
      tierId === "new"
        ? newTier
        : shippingTiers.find((tier) => tier.tier_id === tierId);

    if (!validateForm(tierToSave)) {
      setAlertMessage({
        type: "error",
        message: "Please fix the validation errors before submitting.",
      });
      return;
    }

    // Check if user is trying to save a tier with active service but doesn't have payment method
    if (tierToSave.is_active_service && !hasValidPaymentMethod) {
      setAlertMessage({
        type: "warning",
        message:
          "You must add a valid payment method before listing a service.",
      });
      window.open(`/business/${businessId}/finances`, "_blank");
      return;
    }

    // Ensure at least one pricing model has a value
    let hasPricing = false;
    if (tierToSave.is_active_service) {
      tierToSave.price_model.forEach((model) => {
        // Map from model name to field name
        let priceField;
        switch (model) {
          case "FlatRate":
            priceField = "base_price_flat_rate";
            break;
          case "WeightBased":
            priceField = "base_price_weight";
            break;
          case "DistanceBased":
            priceField = "base_price_distance";
            break;
          case "Dimensional":
            priceField = "base_price_dimensions";
            break;
          case "ValueBased":
            priceField = "base_price_value";
            break;
          default:
            priceField = `base_price_${model.toLowerCase()}`;
        }

        if (tierToSave[priceField] && parseFloat(tierToSave[priceField]) > 0) {
          hasPricing = true;
        }
      });

      if (!hasPricing) {
        setAlertMessage({
          type: "error",
          message:
            "At least one pricing model must have a valid price for active services.",
        });
        return;
      }
    }

    setIsLoading(true);
    try {
      let response;
      if (tierId === "new") {
        response = await api.post(`/shipping-tiers/create`, {
          ...tierToSave,
          business_id: businessId,
          is_active_service: tierToSave.is_active_service,
        });
        setShippingTiers((prevTiers) => [...prevTiers, response.data.tier]);
      } else {
        response = await api.put(`/shipping-tiers/update`, {
          ...tierToSave,
          business_id: businessId,
          tier_id: tierId,
          is_active_service: tierToSave.is_active_service,
        });
        setShippingTiers((prevTiers) =>
          prevTiers.map((tier) =>
            tier.tier_id === tierId ? response.data.tier : tier
          )
        );
      }

      setEditingTierId(null);
      setNewTier(null);
      setErrors({});
      setAlertMessage({
        type: "success",
        message: `Shipping tier ${
          tierId === "new" ? "added" : "updated"
        } successfully!${
          tierToSave.is_active_service
            ? " Service has been " +
              (tierToSave.service_id ? "updated" : "created") +
              "."
            : ""
        }`,
      });
    } catch (error) {
      console.error("Error saving shipping tier:", error);

      // Handle standardized error format
      if (error.response?.data?.code === "VALIDATION_ERROR") {
        // Handle validation errors from API
        const validationErrors = {};
        error.response.data.details.forEach((detail) => {
          validationErrors[detail.field] = detail.message;
        });
        setErrors(validationErrors);

        setAlertMessage({
          type: "error",
          message: "Please fix the validation errors before submitting.",
        });
      } else if (error.response?.data?.code === "PAYMENT_METHOD_REQUIRED") {
        // Handle payment method required error
        setAlertMessage({
          type: "warning",
          message:
            error.response?.data?.message ||
            "You must add a valid payment method before listing a service.",
        });
        // Redirect to finances page if URL is provided
        if (error.response?.data?.redirect_url) {
          window.open(error.response.data.redirect_url, "_blank");
        }
      } else {
        setAlertMessage({
          type: "error",
          message: `Failed to ${
            tierId === "new" ? "add" : "update"
          } shipping tier. ${
            error.response?.data?.message ||
            error.response?.data?.error ||
            "Please try again."
          }`,
        });
      }
    }
    setIsLoading(false);
  };

  // Delete shipping tier
  const DeleteConfirmationDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    message,
    type = "delete",
  }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="error"
            variant={type === "delete" ? "contained" : "outlined"}
          >
            {type === "delete" ? "Delete" : "Deactivate"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Update the delete handler
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    tier: null,
    title: "",
    message: "",
    type: "delete",
  });

  const handleDeleteClick = (tier) => {
    setDeleteDialog({
      open: true,
      tier,
      title: "Confirm Deletion",
      message:
        "Are you sure you want to delete this shipping tier? This action cannot be undone.",
      type: "delete",
    });
  };

  const handleDelete = async (tier) => {
    setIsLoading(true);
    console.log("Deleting shipping tier:", tier.tier_id);
    try {
      const response = await api.delete(`/shipping-tiers/delete`, {
        data: { business_id: businessId, tier_id: tier.tier_id },
      });

      if (response.status === 200 && response.data.type === "deactivated") {
        // Handle soft deletion
        setShippingTiers((prevTiers) =>
          prevTiers.map((t) =>
            t.tier_id === tier.tier_id
              ? { ...t, is_active: false, is_active_service: false }
              : t
          )
        );
        setAlertMessage({
          type: "info",
          message: `Shipping tier has been deactivated. ${response.data.completedOrders} completed orders exist.`,
        });
      } else {
        // Handle hard deletion
        setShippingTiers((prevTiers) =>
          prevTiers.filter((t) => t.tier_id !== tier.tier_id)
        );
        setAlertMessage({
          type: "success",
          message: "Shipping tier deleted successfully!",
        });
      }
    } catch (error) {
      console.error("Error deleting shipping tier:", error);

      if (error.response?.status === 409) {
        if (error.response.data.reason === "active_orders") {
          setAlertMessage({
            type: "error",
            message: `Cannot delete: ${error.response.data.message}`,
          });
        } else if (error.response.data.reason === "foreign_key_constraint") {
          setDeleteDialog({
            open: true,
            tier,
            title: "Cannot Delete Shipping Tier",
            message:
              "This tier has associated orders. Would you like to deactivate it instead?",
            type: "deactivate",
          });
        }
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to delete shipping tier. Please try again.",
        });
      }
    }
    setIsLoading(false);
    setDeleteDialog((prev) => ({ ...prev, open: false }));
  };

  const handleEdit = (tier) => {
    setEditingTierId(tier.tier_id);
  };

  const handleCancelEdit = (tierId) => {
    if (tierId === "new") {
      setNewTier(null);
    }
    setEditingTierId(null);
    setErrors({});
  };

  const handleLocalChange = (e, tierId) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (
      [
        "transportation_modes",
        "allowed_contents",
        "restrictions",
        "eco_friendly_options",
        "packaging_options",
        "value_added_services",
      ].includes(name)
    ) {
      newValue = Array.isArray(value)
        ? value
        : value.split(",").map((item) => item.trim());
    } else if (
      [
        "estimated_delivery_time",
        "price_factors",
        "min_dimensions",
        "max_dimensions",
        "temperature_range",
      ].includes(name)
    ) {
      newValue = typeof value === "object" ? value : JSON.parse(value);
    }

    // Update the tier data
    let updatedTier;
    if (tierId === "new") {
      updatedTier = { ...newTier, [name]: newValue };
      setNewTier(updatedTier);
    } else {
      updatedTier = {
        ...shippingTiers.find((t) => t.tier_id === tierId),
        [name]: newValue,
      };
      setShippingTiers((prevTiers) =>
        prevTiers.map((tier) =>
          tier.tier_id === tierId ? { ...tier, [name]: newValue } : tier
        )
      );
    }

    // Validate field
    const error = validateField(name, value, updatedTier);

    // If this is the is_active_service field, validate pricing as well
    if (name === "is_active_service" && newValue === true) {
      // Check if any price model has a valid price
      let hasValidPrice = false;
      if (updatedTier.price_model && updatedTier.price_model.length > 0) {
        updatedTier.price_model.forEach((model) => {
          // Map from model name to field name
          let priceField;
          switch (model) {
            case "FlatRate":
              priceField = "base_price_flat_rate";
              break;
            case "WeightBased":
              priceField = "base_price_weight";
              break;
            case "DistanceBased":
              priceField = "base_price_distance";
              break;
            case "Dimensional":
              priceField = "base_price_dimensions";
              break;
            case "ValueBased":
              priceField = "base_price_value";
              break;
            default:
              priceField = `base_price_${model.toLowerCase()}`;
          }

          if (
            updatedTier[priceField] &&
            parseFloat(updatedTier[priceField]) > 0
          ) {
            hasValidPrice = true;
          }
        });
      }

      if (!hasValidPrice) {
        setLocalErrors((prev) => ({
          ...prev,
          [name]: error,
          pricing:
            "At least one pricing model must have a valid price greater than zero",
        }));
      } else {
        // Clear pricing error when valid
        setLocalErrors((prev) => {
          const newErrors = { ...prev, [name]: error };
          delete newErrors.pricing;
          return newErrors;
        });
      }
    }
    // Also validate pricing when price model or price fields change
    else if (
      (name === "price_model" || name.startsWith("base_price_")) &&
      updatedTier.is_active_service
    ) {
      // Check if any price model has a valid price
      let hasValidPrice = false;
      const priceModels =
        name === "price_model" ? newValue : updatedTier.price_model;

      if (priceModels && priceModels.length > 0) {
        priceModels.forEach((model) => {
          // Map from model name to field name
          let priceField;
          switch (model) {
            case "FlatRate":
              priceField = "base_price_flat_rate";
              break;
            case "WeightBased":
              priceField = "base_price_weight";
              break;
            case "DistanceBased":
              priceField = "base_price_distance";
              break;
            case "Dimensional":
              priceField = "base_price_dimensions";
              break;
            case "ValueBased":
              priceField = "base_price_value";
              break;
            default:
              priceField = `base_price_${model.toLowerCase()}`;
          }

          // If we're updating a price field, check the new value for the current field
          const priceValue =
            name === priceField ? newValue : updatedTier[priceField];
          if (priceValue && parseFloat(priceValue) > 0) {
            hasValidPrice = true;
          }
        });
      }

      if (!hasValidPrice) {
        setLocalErrors((prev) => ({
          ...prev,
          [name]: error,
          pricing:
            "At least one pricing model must have a valid price greater than zero",
        }));
      } else {
        // Clear pricing error when valid
        setLocalErrors((prev) => {
          const newErrors = { ...prev, [name]: error };
          delete newErrors.pricing;
          return newErrors;
        });
      }
    } else {
      setLocalErrors((prev) => ({ ...prev, [name]: error }));
    }

    // Still need to call the original handleChange
    handleChange(
      {
        target: {
          name,
          value: newValue,
          type,
          checked,
        },
      },
      tierId
    );
  };

  // Add these helper functions in your component
  const getPriceForModel = (tier, modelValue) => {
    switch (modelValue) {
      case "FlatRate":
        return tier.base_price_flat_rate
          ? parseFloat(tier.base_price_flat_rate).toFixed(2)
          : "0.00";
      case "WeightBased":
        return tier.base_price_weight
          ? parseFloat(tier.base_price_weight).toFixed(2)
          : "0.00";
      case "DistanceBased":
        return tier.base_price_distance
          ? parseFloat(tier.base_price_distance).toFixed(2)
          : "0.00";
      case "Dimensional":
        return tier.base_price_dimensions
          ? parseFloat(tier.base_price_dimensions).toFixed(2)
          : "0.00";
      default:
        return "0.00";
    }
  };

  const getPriceUnit = (modelValue) => {
    switch (modelValue) {
      case "FlatRate":
        return "per shipment";
      case "WeightBased":
        return "per gram";
      case "DistanceBased":
        return "per kilometer";
      case "Dimensional":
        return "per cubic cm";
      default:
        return "";
    }
  };

  const getPriceDescription = (model) => {
    switch (model) {
      case "FlatRate":
        return "Fixed price per shipment";
      case "WeightBased":
        return "Price per gram";
      case "DistanceBased":
        return "Price per kilometer";
      case "Dimensional":
        return "Price per cubic centimeter";
      default:
        return "";
    }
  };

  const validatePricing = (tier) => {
    const warnings = {};

    if (tier.is_active_service) {
      if (!tier.active_price_models?.length) {
        warnings.noPricing =
          "No pricing models are activated for the service catalog";
      }

      tier.active_price_models?.forEach((model) => {
        const price = parseFloat(getPriceForModel(tier, model));
        if (price <= 0) {
          warnings[model] = `${
            priceModelOptions.find((m) => m.value === model).label
          } has no valid price configured`;
        }
      });

      if (!tier.description?.trim()) {
        warnings.description =
          "Service description is required for catalog listing";
      }
    }

    return warnings;
  };

  const renderShippingTierCard = (tier) => {
    const isEditing = tier.tier_id === editingTierId || tier.tier_id === "new";

    const validateNumericInput = (value) => {
      return value === "" || /^([1-9]\d*(\.\d*)?|0?(\.\d*)?)?$/.test(value);
    };

    const validateTemperatureInput = (value) => {
      return value === "" || /^-?\d*\.?\d*$/.test(value);
    };

    const handleNumericChange = (e, field, subfield = null) => {
      const value = e.target.value;
      const isTemperatureField = field === "temperature_range";

      if (
        (isTemperatureField && validateTemperatureInput(value)) ||
        (!isTemperatureField && validateNumericInput(value))
      ) {
        // Don't convert empty strings to 0, and preserve decimal places
        let newValue = value === "" ? "" : value;
        let updatedTier;

        if (subfield) {
          updatedTier = {
            ...tier,
            [field]: {
              ...tier[field],
              [subfield]: newValue,
            },
          };
        } else {
          updatedTier = {
            ...tier,
            [field]: newValue,
          };
        }

        // Perform logical checks and update errors
        let newErrors = { ...localErrors };

        // Update validation logic to handle string comparisons
        if (field === "max_weight") {
          const minWeight = parseFloat(updatedTier.min_weight) || 0;
          const maxWeight = parseFloat(newValue) || 0;

          if (maxWeight <= minWeight && maxWeight !== 0) {
            newErrors.max_weight =
              "Maximum weight must be greater than minimum weight";
          } else {
            delete newErrors.max_weight;
          }

          if (minWeight < maxWeight) {
            delete newErrors.min_weight;
          }
        }

        if (field === "min_weight") {
          const minWeight = parseFloat(newValue) || 0;
          const maxWeight = parseFloat(updatedTier.max_weight) || 0;

          if (minWeight >= maxWeight && maxWeight !== 0) {
            newErrors.min_weight =
              "Minimum weight must be less than maximum weight";
          } else {
            delete newErrors.min_weight;
          }

          if (maxWeight > minWeight) {
            delete newErrors.max_weight;
          }
        }

        // Similar updates for dimensions
        if (subfield && field === "max_dimensions") {
          const minDim = parseFloat(tier.min_dimensions[subfield]) || 0;
          const maxDim = parseFloat(newValue) || 0;

          if (maxDim <= minDim && maxDim !== 0) {
            newErrors[
              `max_dimensions.${subfield}`
            ] = `Maximum ${subfield} must be greater than minimum ${subfield}`;
          } else {
            delete newErrors[`max_dimensions.${subfield}`];
          }

          if (minDim < maxDim) {
            delete newErrors[`min_dimensions.${subfield}`];
          }
        }

        if (subfield && field === "min_dimensions") {
          const minDim = parseFloat(newValue) || 0;
          const maxDim = parseFloat(tier.max_dimensions[subfield]) || 0;

          if (minDim >= maxDim && maxDim !== 0) {
            newErrors[
              `min_dimensions.${subfield}`
            ] = `Minimum ${subfield} must be less than maximum ${subfield}`;
          } else {
            delete newErrors[`min_dimensions.${subfield}`];
          }

          if (maxDim > minDim) {
            delete newErrors[`max_dimensions.${subfield}`];
          }
        }

        setLocalErrors(newErrors);

        // Update the form with the raw string value
        handleChange(
          {
            target: {
              name: field,
              value: updatedTier[field],
            },
          },
          tier.tier_id
        );
      }
    };

    const handleDeliveryTimeChange = (e, tierId, field) => {
      let value = e.target.value;

      // Remove leading zeros
      if (value.startsWith("0")) {
        value = value.replace(/^0+/, "");
      }

      // If value is empty after removing leading zeros, default it to "0"
      if (value === "") {
        value = "0";
      }

      if (!isNaN(value) && value >= 0) {
        let updatedTier;
        let error = "";

        if (tierId === "new") {
          updatedTier = {
            ...newTier,
            estimated_delivery_time: {
              ...newTier.estimated_delivery_time,
              [field]: value === "" ? "" : Number(value),
            },
          };

          // Validate the current field
          error = validateField(
            `estimated_delivery_time_${field}`,
            value,
            updatedTier
          );

          // Check if related errors should be removed
          const relatedField = field === "min" ? "max" : "min";
          const relatedValue =
            updatedTier.estimated_delivery_time[relatedField];

          if (
            relatedValue !== undefined &&
            relatedValue !== "" &&
            ((field === "min" && Number(value) <= Number(relatedValue)) ||
              (field === "max" &&
                Number(value) >=
                  Number(updatedTier.estimated_delivery_time.min)))
          ) {
            setLocalErrors((prevErrors) => ({
              ...prevErrors,
              [`estimated_delivery_time_${relatedField}`]: "",
            }));
          }

          setNewTier(updatedTier);
        } else {
          setShippingTiers((prevTiers) =>
            prevTiers.map((tier) => {
              if (tier.tier_id === tierId) {
                updatedTier = {
                  ...tier,
                  estimated_delivery_time: {
                    ...tier.estimated_delivery_time,
                    [field]: value === "" ? "" : Number(value),
                  },
                };

                error = validateField(
                  `estimated_delivery_time_${field}`,
                  value,
                  updatedTier
                );

                const relatedField = field === "min" ? "max" : "min";
                const relatedValue =
                  updatedTier.estimated_delivery_time[relatedField];

                if (
                  relatedValue !== undefined &&
                  relatedValue !== "" &&
                  ((field === "min" && Number(value) <= Number(relatedValue)) ||
                    (field === "max" &&
                      Number(value) >=
                        Number(updatedTier.estimated_delivery_time.min)))
                ) {
                  setLocalErrors((prevErrors) => ({
                    ...prevErrors,
                    [`estimated_delivery_time_${relatedField}`]: "",
                  }));
                }

                return updatedTier;
              }
              return tier;
            })
          );
        }

        setLocalErrors((prevErrors) => ({
          ...prevErrors,
          [`estimated_delivery_time_${field}`]: error,
        }));
      }
    };

    return (
      <>
        <Card className={styles["shipping-tier-card"]}>
          <CardContent
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflowY: "auto",
            }}
          >
            {isEditing ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSave(tier.tier_id);
                }}
              >
                <TextField
                  name="tier_name"
                  label="Tier Name"
                  value={tier.tier_name}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  required
                  error={!!localErrors.tier_name}
                  helperText={
                    localErrors.tier_name ||
                    "Enter a unique name for this shipping tier"
                  }
                />
                <TextField
                  name="description"
                  label="Description"
                  value={tier.description}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                  required
                  error={!!localErrors.description}
                  helperText={
                    localErrors.description ||
                    "Provide a detailed description of this shipping tier"
                  }
                />
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!localErrors.price_model}
                >
                  <InputLabel>Price Model</InputLabel>
                  <Select
                    multiple
                    name="price_model"
                    value={tier.price_model}
                    onChange={(e) => handleLocalChange(e, tier.tier_id)}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (val) =>
                            priceModelOptions.find((opt) => opt.value === val)
                              .label
                        )
                        .join(", ")
                    }
                  >
                    {priceModelOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={tier.price_model.includes(option.value)}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {localErrors.price_model ||
                      "Select one or more price models"}
                  </FormHelperText>
                </FormControl>

                {/* Conditionally display fields based on the selected price model */}
                {tier.price_model.includes("FlatRate") && (
                  <Alert severity="info" style={{ marginBottom: "1rem" }}>
                    The flat rate price is a fixed price that applies to the
                    entire shipment.
                  </Alert>
                )}
                {tier.price_model.includes("FlatRate") && (
                  <TextField
                    name="base_price_flat_rate"
                    label="Flat Rate Price"
                    value={tier.base_price_flat_rate}
                    onChange={(e) =>
                      handleNumericChange(e, "base_price_flat_rate")
                    }
                    fullWidth
                    margin="normal"
                    required
                    type="text"
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*\\.?[0-9]*",
                    }}
                    error={!!localErrors.base_price_flat_rate}
                    helperText="Enter the flat rate base price"
                  />
                )}
                {tier.price_model.includes("WeightBased") && (
                  <Alert severity="info" style={{ marginBottom: "1rem" }}>
                    The weight-based price is calculated based on the weight of
                    the shipment.
                  </Alert>
                )}
                {tier.price_model.includes("WeightBased") && (
                  <TextField
                    name="base_price_weight"
                    label="Weight-Based Price"
                    value={tier.base_price_weight}
                    onChange={(e) =>
                      handleNumericChange(e, "base_price_weight")
                    }
                    onKeyDown={(e) => {
                      if (["-", "+", "e"].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    margin="normal"
                    required
                    type="text"
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*\\.?[0-9]*",
                    }}
                    helperText="Enter the weight-based base price"
                  />
                )}
                {tier.price_model.includes("DistanceBased") && (
                  <Alert severity="info" style={{ marginBottom: "1rem" }}>
                    The distance-based price is calculated based on the distance
                    between the origin and destination.
                  </Alert>
                )}
                {tier.price_model.includes("DistanceBased") && (
                  <TextField
                    name="base_price_distance"
                    label="Distance-Based Price"
                    value={tier.base_price_distance}
                    onChange={(e) =>
                      handleNumericChange(e, "base_price_distance")
                    }
                    onKeyDown={(e) => {
                      if (["-", "+", "e"].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    margin="normal"
                    required
                    type="text"
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*\\.?[0-9]*",
                    }}
                    error={!!localErrors.base_price_distance}
                    helperText="Enter the distance-based base price"
                  />
                )}
                {tier.price_model.includes("ValueBased") && (
                  <Alert severity="info" style={{ marginBottom: "1rem" }}>
                    The value-based price is calculated based on the declared
                    value of the shipment.
                  </Alert>
                )}
                {/* {tier.price_model.includes("ValueBased") && (
                <TextField
                  name="base_price_value"
                  label="Value-Based Price"
                  value={tier.base_price_value}
                  onChange={(e) => handleNumericChange(e, "base_price_value")}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                  margin="normal"
                  required
                  type="number"
                  inputProps={{ min: 0, step: "0.01" }}
                  error={!!localErrors.base_price_value}
                  helperText="Enter the value-based base price"
                />
              )} */}
                {tier.price_model.includes("Dimensional") && (
                  <Alert severity="info" style={{ marginBottom: "1rem" }}>
                    The dimensional price is calculated based on the dimensions
                    of the shipment.
                  </Alert>
                )}
                {tier.price_model.includes("Dimensional") && (
                  <TextField
                    name="base_price_dimensions"
                    label="Dimensional Price"
                    value={tier.base_price_dimensions}
                    onChange={(e) =>
                      handleNumericChange(e, "base_price_dimensions")
                    }
                    onKeyDown={(e) => {
                      if (["-", "+", "e"].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    fullWidth
                    margin="normal"
                    required
                    type="text"
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*\\.?[0-9]*",
                    }}
                    error={!!localErrors.base_price_dimensions}
                    helperText="Enter the dimensional base price"
                  />
                )}
                <FormControl fullWidth margin="normal">
                  <Typography variant="subtitle1" gutterBottom>
                    Estimated Delivery Time (days)
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name="estimated_delivery_time_min"
                        label="Min Days"
                        required
                        value={tier.estimated_delivery_time.min}
                        onChange={(e) =>
                          handleDeliveryTimeChange(e, tier.tier_id, "min")
                        }
                        onKeyDown={(e) => {
                          if (["-", "+", "e"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        type="number"
                        fullWidth
                        margin="normal"
                        inputProps={{ min: 0, step: "1" }}
                        error={!!localErrors.estimated_delivery_time_min}
                        helperText={
                          localErrors.estimated_delivery_time_min ||
                          "Minimum estimated days"
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="estimated_delivery_time_max"
                        label="Max Days"
                        value={tier.estimated_delivery_time.max}
                        onChange={(e) =>
                          handleDeliveryTimeChange(e, tier.tier_id, "max")
                        }
                        onKeyDown={(e) => {
                          if (["-", "+", "e"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        type="number"
                        fullWidth
                        required
                        margin="normal"
                        inputProps={{ min: 0, step: "1" }}
                        error={!!localErrors.estimated_delivery_time_max}
                        helperText={
                          localErrors.estimated_delivery_time_max ||
                          "Maximum estimated days"
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!localErrors.transportation_modes}
                >
                  <InputLabel>Transportation Modes</InputLabel>
                  <Select
                    multiple
                    name="transportation_modes"
                    value={tier.transportation_modes}
                    onChange={(e) => handleLocalChange(e, tier.tier_id)}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {["Road", "Air", "Sea", "Rail"].map((mode) => (
                      <MenuItem key={mode} value={mode}>
                        <Checkbox
                          checked={tier.transportation_modes.indexOf(mode) > -1}
                        />
                        <ListItemText primary={mode} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {localErrors.transportation_modes ||
                      "Select one or more transportation modes"}
                  </FormHelperText>
                </FormControl>
                <TextField
                  name="min_weight"
                  label="Minimum Weight (lbs)"
                  value={tier.min_weight}
                  onChange={(e) => handleNumericChange(e, "min_weight")}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                  margin="normal"
                  required
                  type="text"
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*\\.?[0-9]*",
                  }}
                  error={!!localErrors.min_weight}
                  helperText={
                    localErrors.min_weight ||
                    "Enter the minimum weight this tier can handle"
                  }
                />
                <TextField
                  name="max_weight"
                  label="Maximum Weight (lbs)"
                  value={tier.max_weight}
                  onChange={(e) => handleNumericChange(e, "max_weight")}
                  onKeyDown={(e) => {
                    if (["-", "+", "e"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                  margin="normal"
                  required
                  type="text"
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*\\.?[0-9]*",
                  }}
                  error={!!localErrors.max_weight}
                  helperText={
                    localErrors.max_weight ||
                    "Enter the maximum weight this tier can handle"
                  }
                />
                <Typography variant="subtitle1" gutterBottom>
                  Minimum Dimensions (in)
                </Typography>
                <Grid container spacing={2}>
                  {["length", "width", "height"].map((dim) => (
                    <Grid item xs={4} key={dim}>
                      <TextField
                        name={`min_dimensions.${dim}`}
                        label={dim.charAt(0).toUpperCase() + dim.slice(1)}
                        value={tier.min_dimensions[dim]}
                        onChange={(e) =>
                          handleNumericChange(e, "min_dimensions", dim)
                        }
                        onKeyDown={(e) => {
                          if (["-", "+", "e"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        fullWidth
                        margin="normal"
                        type="text"
                        inputProps={{
                          inputMode: "decimal",
                          pattern: "[0-9]*\\.?[0-9]*",
                        }}
                        error={!!localErrors[`min_dimensions.${dim}`]}
                        helperText={
                          localErrors[`min_dimensions.${dim}`] ||
                          `Minimum ${dim}`
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <Typography variant="subtitle1" gutterBottom>
                  Maximum Dimensions (in)
                </Typography>
                <Grid container spacing={2}>
                  {["length", "width", "height"].map((dim) => (
                    <Grid item xs={4} key={dim}>
                      <TextField
                        name={`max_dimensions.${dim}`}
                        label={dim.charAt(0).toUpperCase() + dim.slice(1)}
                        value={tier.max_dimensions[dim]}
                        onChange={(e) =>
                          handleNumericChange(e, "max_dimensions", dim)
                        }
                        onKeyDown={(e) => {
                          if (["-", "+", "e"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        fullWidth
                        margin="normal"
                        type="text"
                        inputProps={{
                          inputMode: "decimal",
                          pattern: "[0-9]*\\.?[0-9]*",
                        }}
                        error={!!localErrors[`max_dimensions.${dim}`]}
                        helperText={
                          localErrors[`max_dimensions.${dim}`] ||
                          `Maximum ${dim}`
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Service Level</InputLabel>
                  <Select
                    name="service_level"
                    value={tier.service_level}
                    onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  >
                    <MenuItem value="Economy">Economy</MenuItem>
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Express">Express</MenuItem>
                    <MenuItem value="SameDay">Same Day</MenuItem>
                    <MenuItem value="Overnight">Overnight</MenuItem>
                    <MenuItem value="Custom">Custom</MenuItem>
                  </Select>
                  <FormHelperText>
                    Select the service level for this shipping tier
                  </FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.signature_required}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="signature_required"
                    />
                  }
                  label="Signature Required"
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Insurance Option</InputLabel>
                  <Select
                    name="insurance_option"
                    value={tier.insurance_option}
                    onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  >
                    <MenuItem value="NotAvailable">Not Available</MenuItem>
                    <MenuItem value="Optional">Optional</MenuItem>
                    <MenuItem value="Included">Included</MenuItem>
                  </Select>
                  <FormHelperText>
                    Select the insurance option for this shipping tier
                  </FormHelperText>
                </FormControl>
                {/* {tier.insurance_option !== "NotAvailable" && (
                  <>
                    <TextField
                      name="insurance_fee"
                      label="Insurance Fee"
                      value={tier.insurance_fee}
                      onChange={(e) => handleNumericChange(e, "insurance_fee")}
                      fullWidth
                      margin="normal"
                      type="text"
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "[0-9]*\\.?[0-9]*",
                      }}
                      helperText="Enter the insurance fee (if applicable)"
                    />
                    <TextField
                      name="max_insurance_coverage"
                      label="Max Insurance Coverage"
                      value={tier.max_insurance_coverage}
                      onChange={(e) =>
                        handleNumericChange(e, "max_insurance_coverage")
                      }
                      fullWidth
                      margin="normal"
                      type="text"
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "[0-9]*\\.?[0-9]*",
                      }}
                      helperText="Enter the maximum insurance coverage amount"
                    />
                  </>
                )} */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.temperature_controlled}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="temperature_controlled"
                    />
                  }
                  label="Temperature Controlled"
                />
                {tier.temperature_controlled && (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        name="temperature_range.min"
                        label="Min Temperature"
                        value={tier.temperature_range.min}
                        onChange={(e) =>
                          handleNumericChange(e, "temperature_range", "min")
                        }
                        fullWidth
                        margin="normal"
                        type="text"
                        inputProps={{
                          inputMode: "decimal",
                          pattern: "[0-9]*\\.?[0-9]*",
                        }}
                        helperText="Minimum temperature"
                        onKeyDown={(e) => {
                          if (["-", "+", "e"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="temperature_range.max"
                        label="Max Temperature"
                        value={tier.temperature_range.max}
                        onChange={(e) =>
                          handleNumericChange(e, "temperature_range", "max")
                        }
                        fullWidth
                        margin="normal"
                        type="text"
                        inputProps={{
                          inputMode: "decimal",
                          pattern: "[0-9]*\\.?[0-9]*",
                        }}
                        helperText="Maximum temperature"
                        onKeyDown={(e) => {
                          if (["-", "+", "e"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Unit</InputLabel>
                        <Select
                          name="temperature_range.unit"
                          value={tier.temperature_range.unit}
                          onChange={(e) => handleLocalChange(e, tier.tier_id)}
                        >
                          <MenuItem value="Celsius">Celsius</MenuItem>
                          <MenuItem value="Fahrenheit">Fahrenheit</MenuItem>
                        </Select>
                        <FormHelperText>Temperature unit</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.customs_clearance_included}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="customs_clearance_included"
                    />
                  }
                  label="Customs Clearance Included"
                />
                {/* <FormControl fullWidth margin="normal">
                <InputLabel>Tracking Frequency</InputLabel>
                <Select
                  name="tracking_frequency"
                  value={tier.tracking_frequency}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="LimitedMilestones">
                    Limited Milestones
                  </MenuItem>
                  <MenuItem value="FrequentUpdates">Frequent Updates</MenuItem>
                  <MenuItem value="RealTime">Real Time</MenuItem>
                </Select>
                <FormHelperText>
                  Select the tracking frequency for this shipping tier
                </FormHelperText>
              </FormControl> */}
                <TextField
                  name="allowed_contents"
                  label="Allowed Contents"
                  value={tier.allowed_contents.join(", ")}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  helperText="Enter comma-separated values for allowed contents"
                />
                <TextField
                  name="restrictions"
                  label="Restrictions"
                  value={tier.restrictions.join(", ")}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  helperText="Enter comma-separated values for restrictions"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.hazardous_materials_handling}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="hazardous_materials_handling"
                    />
                  }
                  label="Hazardous Materials Handling"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.white_glove_services}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="white_glove_services"
                    />
                  }
                  label="White Glove Services"
                />
                <TextField
                  name="eco_friendly_options"
                  label="Eco-Friendly Options"
                  value={tier.eco_friendly_options.join(", ")}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  helperText="Enter comma-separated values for eco-friendly options"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.carbon_offset_available}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="carbon_offset_available"
                    />
                  }
                  label="Carbon Offset Available"
                />
                <TextField
                  name="packaging_options"
                  label="Packaging Options"
                  value={tier.packaging_options.join(", ")}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  helperText="Enter comma-separated values for packaging options"
                />
                <TextField
                  name="value_added_services"
                  label="Value Added Services"
                  value={tier.value_added_services.join(", ")}
                  onChange={(e) => handleLocalChange(e, tier.tier_id)}
                  fullWidth
                  margin="normal"
                  helperText="Enter comma-separated values for value-added services"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.return_service_included}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="return_service_included"
                    />
                  }
                  label="Return Service Included"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.priority_boarding}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="priority_boarding"
                    />
                  }
                  label="Priority Boarding"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.saturday_delivery}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="saturday_delivery"
                    />
                  }
                  label="Saturday Delivery"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.sunday_delivery}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="sunday_delivery"
                    />
                  }
                  label="Sunday Delivery"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={tier.is_active}
                      onChange={(e) => handleLocalChange(e, tier.tier_id)}
                      name="is_active"
                    />
                  }
                  label="Is Active"
                />

                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    fontWeight="medium"
                  >
                    Service Availability
                  </Typography>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={tier.is_active_service}
                        onChange={(e) => {
                          // If toggling ON without a valid payment method:
                          if (e.target.checked && !hasValidPaymentMethod) {
                            setAlertMessage({
                              type: "warning",
                              message:
                                "You must add a valid payment method before listing a service.",
                              showFinancesLink: true, // Flag to show the link
                            });
                            return; // Don't toggle the switch
                          }
                          handleLocalChange(e, tier.tier_id);
                        }}
                        name="is_active_service"
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="body1">
                          Make Available as Service
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Make this shipping tier visible to other businesses
                        </Typography>
                      </Box>
                    }
                  />

                  {tier.is_active_service && localErrors.pricing && (
                    <Alert severity="warning" style={{ marginTop: "1rem" }}>
                      {localErrors.pricing}
                    </Alert>
                  )}
                  {tier.is_active_service && (
                    <Box sx={{ mt: 2 }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Access Level</InputLabel>
                        <Select
                          name="tier_access_level"
                          value={tier.tier_access_level || "public"}
                          onChange={(e) => handleLocalChange(e, tier.tier_id)}
                        >
                          <MenuItem value="public">
                            Public (available to all)
                          </MenuItem>
                          <MenuItem value="private">
                            Private (only for your business)
                          </MenuItem>
                          <MenuItem value="restricted">
                            Restricted (select specific businesses)
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          Control which businesses can see and use this service
                        </FormHelperText>
                      </FormControl>

                      {/* Business selector - only show if restricted access is selected */}
                      {tier.tier_access_level === "restricted" && (
                        <BusinessSelector
                          selectedBusinessIds={tier.allowed_business_ids || []}
                          onChange={(selectedIds) =>
                            handleLocalChange(
                              {
                                target: {
                                  name: "allowed_business_ids",
                                  value: selectedIds,
                                },
                              },
                              tier.tier_id
                            )
                          }
                          businessId={businessId}
                        />
                      )}
                    </Box>
                  )}

                  {/* show the preview of what the service availability is going to look like */}
                  {tier.is_active_service && (
                    <Box
                      sx={{
                        mt: 2,
                        p: 2,
                        bgcolor: "background.default",
                        borderRadius: 1,
                      }}
                    >
                      <Alert severity="info" style={{ marginBottom: "1rem" }}>
                        The following details will be displayed to customers
                        when they select this shipping tier as a service.
                      </Alert>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        fontWeight="medium"
                      >
                        Service Availability Preview
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {tier.tier_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tier.description}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tier.transportation_modes.join(", ")}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tier.estimated_delivery_time.min}-
                        {tier.estimated_delivery_time.max}{" "}
                        {tier.estimated_delivery_time.unit}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tier.min_weight}g - {tier.max_weight}g
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tier.min_dimensions.length}x{tier.min_dimensions.width}
                        x{tier.min_dimensions.height} -{" "}
                        {tier.max_dimensions.length}x{tier.max_dimensions.width}
                        x{tier.max_dimensions.height}
                      </Typography>

                      {/* display the pricing options */}
                      <Typography variant="subtitle2" gutterBottom mt={1}>
                        Pricing Options:
                      </Typography>
                      {tier.price_model.length === 0 && (
                        <Typography variant="body2" color="error">
                          No pricing models configured
                        </Typography>
                      )}
                      <List dense disablePadding>
                        {tier.price_model.includes("FlatRate") && (
                          <ListItem disablePadding>
                            <Typography
                              variant="body2"
                              color={
                                parseFloat(tier.base_price_flat_rate) > 0
                                  ? "text.secondary"
                                  : "error"
                              }
                            >
                              • Flat Rate: $
                              {parseFloat(
                                tier.base_price_flat_rate || 0
                              ).toFixed(2)}
                              {parseFloat(tier.base_price_flat_rate || 0) <=
                                0 && " (invalid price)"}
                            </Typography>
                          </ListItem>
                        )}
                        {tier.price_model.includes("WeightBased") && (
                          <ListItem disablePadding>
                            <Typography
                              variant="body2"
                              color={
                                parseFloat(tier.base_price_weight) > 0
                                  ? "text.secondary"
                                  : "error"
                              }
                            >
                              • Weight-Based: $
                              {parseFloat(tier.base_price_weight || 0).toFixed(
                                2
                              )}
                              /g
                              {parseFloat(tier.base_price_weight || 0) <= 0 &&
                                " (invalid price)"}
                            </Typography>
                          </ListItem>
                        )}
                        {tier.price_model.includes("DistanceBased") && (
                          <ListItem disablePadding>
                            <Typography
                              variant="body2"
                              color={
                                parseFloat(tier.base_price_distance) > 0
                                  ? "text.secondary"
                                  : "error"
                              }
                            >
                              • Distance-Based: $
                              {parseFloat(
                                tier.base_price_distance || 0
                              ).toFixed(2)}
                              /km
                              {parseFloat(tier.base_price_distance || 0) <= 0 &&
                                " (invalid price)"}
                            </Typography>
                          </ListItem>
                        )}
                        {tier.price_model.includes("Dimensional") && (
                          <ListItem disablePadding>
                            <Typography
                              variant="body2"
                              color={
                                parseFloat(tier.base_price_dimensions) > 0
                                  ? "text.secondary"
                                  : "error"
                              }
                            >
                              • Dimensional: $
                              {parseFloat(
                                tier.base_price_dimensions || 0
                              ).toFixed(2)}
                              /cm³
                              {parseFloat(tier.base_price_dimensions || 0) <=
                                0 && " (invalid price)"}
                            </Typography>
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  )}
                </Box>
                {Object.keys(errors).length > 0 && (
                  <Alert
                    severity="error"
                    variant="outlined"
                    sx={{ mb: 2 }}
                    onClose={() => setErrors({})}
                  >
                    {Object.values(errors).map((err, index) => (
                      <div key={index}>{err}</div>
                    ))}
                  </Alert>
                )}

                <div className={styles["card-actions"]}>
                  <IconButton
                    aria-label="save"
                    type="submit"
                    size="small"
                    variant="contained"
                    className={styles["icon-button"]}
                    onClick={() => handleSave(tier.tier_id)}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    aria-label="cancel"
                    type="reset"
                    onClick={() => handleCancelEdit(tier.tier_id)}
                    startIcon={<CancelIcon />}
                    margin="dense"
                    size="small"
                    className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </form>
            ) : (
              <div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <Typography variant="h5" component="h3">
                      {tier.tier_name}
                    </Typography>
                    <Box>
                      <Chip
                        label={tier.is_active ? "Active" : "Inactive"}
                        color={tier.is_active ? "success" : "default"}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      {tier.is_active_service && (
                        <Chip
                          label="Available as Service"
                          color="primary"
                          size="small"
                          icon={<LocalShipping />}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Typography variant="body2" color="textSecondary" paragraph>
                  {tier.description}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <LocalShipping fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2">
                        Modes: {tier.transportation_modes.join(", ")}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <AttachMoney fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2">
                        Price Model: {tier.price_model.join(", ")}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" mb={1}>
                      <Typography variant="body2" fontWeight="bold">
                        Base Prices:
                      </Typography>
                      {tier.price_model.includes("FlatRate") && (
                        <Typography variant="body2" ml={2}>
                          Flat Rate: $
                          {parseFloat(tier.base_price_flat_rate).toFixed(2)}
                        </Typography>
                      )}
                      {tier.price_model.includes("WeightBased") && (
                        <Typography variant="body2" ml={2}>
                          Weight-Based: $
                          {parseFloat(tier.base_price_weight).toFixed(2)}/g
                        </Typography>
                      )}
                      {tier.price_model.includes("DistanceBased") && (
                        <Typography variant="body2" ml={2}>
                          Distance-Based: $
                          {parseFloat(tier.base_price_distance).toFixed(2)}/km
                          /km
                        </Typography>
                      )}
                      {tier.price_model.includes("Dimensional") && (
                        <Typography variant="body2" ml={2}>
                          Dimensional: $
                          {parseFloat(tier.base_price_dimensions).toFixed(2)}
                          /cm³
                        </Typography>
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Schedule fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2">
                        Estimated Delivery: {tier.estimated_delivery_time.min}-
                        {tier.estimated_delivery_time.max}{" "}
                        {tier.estimated_delivery_time.unit}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Scale fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2">
                        Weight Range: {tier.min_weight.toFixed(2)}g -{" "}
                        {tier.max_weight.toFixed(2)}g
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" mb={1}>
                      <Typography variant="body2" fontWeight="bold">
                        Dimensions (in):
                      </Typography>
                      <Typography variant="body2" ml={2}>
                        Min: {tier.min_dimensions.length}x
                        {tier.min_dimensions.width}x{tier.min_dimensions.height}
                      </Typography>
                      <Typography variant="body2" ml={2}>
                        Max: {tier.max_dimensions.length}x
                        {tier.max_dimensions.width}x{tier.max_dimensions.height}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                      <Security fontSize="small" sx={{ mr: 1 }} />
                      <Typography variant="body2">
                        Insurance: {tier.insurance_option}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />

                <Grid container spacing={1}>
                  {tier.temperature_controlled && (
                    <Grid item>
                      <Chip
                        icon={<AcUnit />}
                        label={`Temp Controlled: ${tier.temperature_range.min}°${tier.temperature_range.unit} - ${tier.temperature_range.max}°${tier.temperature_range.unit}`}
                        color="primary"
                        size="small"
                      />
                    </Grid>
                  )}
                  {tier.customs_clearance_included && (
                    <Grid item>
                      <Chip
                        icon={<Gavel />}
                        label="Customs Clearance"
                        color="primary"
                        size="small"
                      />
                    </Grid>
                  )}
                  {tier.carbon_offset_available && (
                    <Grid item>
                      <Chip
                        icon={<EnergySavingsLeafIcon />}
                        label="Carbon Offset"
                        color="success"
                        size="small"
                      />
                    </Grid>
                  )}
                  {tier.return_service_included && (
                    <Grid item>
                      <Chip
                        icon={<Restore />}
                        label="Return Service"
                        color="secondary"
                        size="small"
                      />
                    </Grid>
                  )}
                  {tier.priority_boarding && (
                    <Grid item>
                      <Chip
                        icon={<FlightTakeoff />}
                        label="Priority Boarding"
                        color="info"
                        size="small"
                      />
                    </Grid>
                  )}
                  {tier.saturday_delivery && (
                    <Grid item>
                      <Chip
                        icon={<Weekend />}
                        label="Saturday Delivery"
                        color="warning"
                        size="small"
                      />
                    </Grid>
                  )}
                  {tier.sunday_delivery && (
                    <Grid item>
                      <Chip
                        icon={<Weekend />}
                        label="Sunday Delivery"
                        color="error"
                        size="small"
                      />
                    </Grid>
                  )}
                </Grid>

                <Box mt={2}>
                  <Typography variant="body2">
                    Service Level: {tier.service_level}
                  </Typography>
                  <Typography variant="body2">
                    Signature Required: {tier.signature_required ? "Yes" : "No"}
                  </Typography>
                  {tier.hazardous_materials_handling && (
                    <Typography variant="body2">
                      Hazardous Materials Handling: Available
                    </Typography>
                  )}
                  {tier.white_glove_services && (
                    <Typography variant="body2">
                      White Glove Services: Available
                    </Typography>
                  )}
                  {tier.allowed_contents.length > 0 && (
                    <Typography variant="body2">
                      Allowed Contents: {tier.allowed_contents.join(", ")}
                    </Typography>
                  )}
                  {tier.restrictions.length > 0 && (
                    <Typography variant="body2">
                      Restrictions: {tier.restrictions.join(", ")}
                    </Typography>
                  )}
                  {tier.eco_friendly_options.length > 0 && (
                    <Typography variant="body2">
                      Eco-Friendly Options:{" "}
                      {tier.eco_friendly_options.join(", ")}
                    </Typography>
                  )}
                  {tier.packaging_options.length > 0 && (
                    <Typography variant="body2">
                      Packaging Options: {tier.packaging_options.join(", ")}
                    </Typography>
                  )}
                  {tier.value_added_services.length > 0 && (
                    <Typography variant="body2">
                      Value Added Services:{" "}
                      {tier.value_added_services.join(", ")}
                    </Typography>
                  )}
                </Box>

                {/* Display businesses with access if tier is restricted */}
                {tier.tier_access_level === "restricted" && (
                  <Box mt={2} p={2} bgcolor="background.paper" borderRadius={1}>
                    <Typography variant="subtitle2" gutterBottom>
                      Businesses With Access
                    </Typography>
                    <AllowedBusinessesList tierId={tier.tier_id} />
                  </Box>
                )}

                <Box mt={2} display="flex" justifyContent="flex-end">
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEdit(tier)}
                    size="small"
                    className={`${styles["icon-button"]} ${styles["edit-button"]}`}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteClick(tier)}
                    size="small"
                    className={`${styles["icon-button"]} ${styles["delete-button"]}`}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </div>
            )}
          </CardContent>
        </Card>
        <DeleteConfirmationDialog
          open={deleteDialog.open}
          onClose={() => setDeleteDialog((prev) => ({ ...prev, open: false }))}
          onConfirm={() => handleDelete(deleteDialog.tier)}
          title={deleteDialog.title}
          message={deleteDialog.message}
          type={deleteDialog.type}
        />
      </>
    );
  };

  return (
    <div className="business-page business-analytics-page">
      <main className="business-page-content">
        <h1 className={styles.title}>Manage Shipping Tiers</h1>

        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            variant="outlined"
            sx={{ mb: 2 }}
            onClose={() => setAlertMessage(null)}
            action={
              (alertMessage.showFinancesLink || alertMessage.finances_url) && (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    window.open(
                      alertMessage.finances_url ||
                        `/business/${businessId}/finances`,
                      "_blank"
                    )
                  }
                  startIcon={<CreditCardIcon />}
                >
                  Add Payment Method
                </Button>
              )
            }
          >
            {alertMessage.message}
          </Alert>
        )}
        {isLoading ? (
          <div className={styles["loading-container"]}>
            <CircularProgress />
          </div>
        ) : (
          <div className={styles["shipping-tiers-grid"]}>
            <Card
              key="add-new-tier"
              className={`${styles["shipping-tier-card"]} ${styles["service-add-card"]}`}
              onClick={handleAddNewTier}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "2rem",
                textAlign: "center",

                "&:hover": {
                  backgroundColor: "#e6f3ff",
                  transform: "translateY(-5px)",
                  boxShadow: "0 15px 30px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardContent>
                <AddCircleIcon
                  style={{ fontSize: "4rem", marginBottom: "1rem" }}
                />
                <Typography variant="h6" component="h3">
                  Add New Shipping Tier
                </Typography>
              </CardContent>
            </Card>
            {newTier && renderShippingTierCard({ ...newTier, tier_id: "new" })}
            {shippingTiers.map((tier) => (
              <div key={tier.tier_id}>{renderShippingTierCard(tier)}</div>
            ))}
          </div>
        )}
        <InsuranceManagement businessId={businessId} />
      </main>
    </div>
  );
};

export default BusinessServicesPage;
