import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const PerformanceMetricsCard = ({ title, value, subtext, icon, status }) => {
  const getStatusColor = () => {
    switch(status) {
      case 'success':
        return 'rgb(46, 125, 50)';
      case 'warning':
        return 'rgb(237, 108, 2)';
      case 'error':
        return 'rgb(211, 47, 47)';
      default:
        return 'inherit';
    }
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h6" color="text.secondary">
            {title}
          </Typography>
          {icon && (
            <Box color={getStatusColor()}>
              {icon}
            </Box>
          )}
        </Box>
        <Typography 
          variant="h4" 
          component="div" 
          color={status ? getStatusColor() : 'text.primary'}
          sx={{ fontWeight: 'bold' }}
        >
          {value}
          {subtext && (
            <Typography component="span" variant="subtitle1">
              {subtext}
            </Typography>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PerformanceMetricsCard;
