import React, { useContext, useEffect, useState, useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import Cookies from "js-cookie";
import LoadingModal from "../Icon/LoadingModal";
import ParallaxBackground from "../Background/ParallaxBackground/ParallaxBackground";

const PasswordProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const [isVerified, setIsVerified] = useState(null);
  const [isCheckingVerification, setIsCheckingVerification] = useState(true);
  const location = useLocation();

  const checkVerification = useCallback(() => {
    const timestamp = Cookies.get("securityAccessTimestamp");
    if (!timestamp) {
      return false;
    }
    const elapsedTime = Date.now() - parseInt(timestamp);
    return elapsedTime <= 15 * 60 * 1000; // 15 minutes
  }, []);

  useEffect(() => {
    if (user) {
      const initialCheck = checkVerification();
      setIsVerified(initialCheck);
      setIsCheckingVerification(false);

      const intervalId = setInterval(() => {
        const isStillVerified = checkVerification();
        if (isStillVerified !== isVerified) {
          setIsVerified(isStillVerified);
        }
      }, 5000);

      return () => clearInterval(intervalId);
    } else {
      setIsVerified(false);
      setIsCheckingVerification(false);
    }
  }, [user, checkVerification, isVerified]);

  if (loading || isCheckingVerification) {
    return (
      <>
        {/* <ParallaxBackground /> */}
        <LoadingModal open={true} />
      </>
    );
  }

  if (!user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  if (!isVerified) {
    return (
      <Navigate to="/verify-password" state={{ from: location }} replace />
    );
  }

  return children;
};

export default PasswordProtectedRoute;
