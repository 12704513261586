import React from "react";
// Header and Footer are now provided by PublicLayout
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import "./AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-page" id="wrapper">
      <h1 className="about-title">About Us</h1>
      <div className="about-grid">
        <div className="about-card mission">
          <h2>Our Mission</h2>
          <p>
            We empower commerce and foster interconnectedness in every
            corner—bridging digital innovation with local economies. Our
            solutions are designed not to displace but to uplift, enabling
            communities and businesses to thrive together.
          </p>
        </div>
        <div className="about-card vision">
          <h2>Our Vision</h2>
          <p>
            Our vision is a world where technology and commerce work in harmony
            to create lasting connections. We strive to be the catalyst for a
            globally connected marketplace that enhances local strengths and
            promotes sustainable growth.
          </p>
        </div>
        <div className="about-card values">
          <h2>Our Values</h2>
          <ul>
            <li>Empowerment</li>
            <li>Interconnectedness</li>
            <li>Innovation</li>
            <li>Collaboration</li>
            <li>Sustainability</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
