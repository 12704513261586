import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Paper,
  Divider,
  Stack,
  Switch,
  FormControlLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Home as HomeIcon,
  Business as BusinessIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../components/Auth/AuthContext";
import CustomerAddressDialog from "./CustomerAddressDialog";

const ManageAddressesDialog = ({ open, onClose, customerId, businessId }) => {
  const { api } = useContext(AuthContext);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddEditOpen, setIsAddEditOpen] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    if (open && customerId) {
      fetchAddresses();
    }
  }, [open, customerId]);

  const fetchAddresses = async () => {
    try {
      const response = await api.get(`/customers/addresses/${customerId}`, {
        params: {
          business_id: businessId,
        },
      });
      setAddresses(response.data.addresses);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      showAlert("Error loading addresses", "error");
    }
  };

  const handleAddNew = () => {
    const defaultState = {
      is_default: addresses.length === 0,
    };
    setSelectedAddress(defaultState);
    setIsNewAddress(true);
    setIsAddEditOpen(true);
  };

  const handleEdit = (address) => {
    const addressToEdit = {
      ...address,
      is_default: Boolean(address.is_default),
    };
    setSelectedAddress(addressToEdit);
    setIsNewAddress(false);
    setIsAddEditOpen(true);
  };

  const handleDelete = async (addressId) => {
    try {
      await api.delete(`/customers/addresses/${addressId}`);
      showAlert("Address deleted successfully", "success");
      fetchAddresses();
    } catch (error) {
      console.error("Error deleting address:", error);
      showAlert(error.response?.data?.msg || "Error deleting address", "error");
    }
  };

  const handleSetDefault = async (address) => {
    try {
      const isUnsetting = address.is_default;
      const addressType = address.address_type;

      // Get all relevant addresses based on type
      const relatedAddresses = addresses.filter(
        (a) =>
          // If we're dealing with a 'both' address, include shipping and billing
          // If we're dealing with shipping/billing, include 'both' addresses too
          a.address_type === addressType ||
          a.address_type === "both" ||
          (addressType === "both" &&
            (a.address_type === "shipping" || a.address_type === "billing"))
      );

      // Count how many defaults we currently have for this type
      const defaultCount = relatedAddresses.filter((a) => a.is_default).length;

      // Prevent unsetting if it's the only default address
      if (isUnsetting && defaultCount === 1) {
        showAlert(
          `Cannot unset the only default address for ${addressType} type`,
          "error"
        );
        return;
      }

      // If setting a new default:
      if (!isUnsetting) {
        // Remove default from other addresses of same type and 'both' type
        const updatedAddresses = addresses.map((a) => {
          if (a.address_id === address.address_id) {
            return { ...a, is_default: true };
          }
          // Remove default from related types
          if (
            a.address_type === addressType ||
            a.address_type === "both" ||
            (addressType === "both" &&
              (a.address_type === "shipping" || a.address_type === "billing"))
          ) {
            return { ...a, is_default: false };
          }
          return a;
        });
        setAddresses(updatedAddresses);
      } else {
        // If unsetting:
        const updatedAddresses = addresses.map((a) => {
          if (a.address_id === address.address_id) {
            return { ...a, is_default: false };
          }
          return a;
        });
        setAddresses(updatedAddresses);
      }

      // Make the API call
      await api.put(`/customers/addresses/${address.address_id}/set-default`, {
        is_default: !isUnsetting,
        address_type: addressType,
      });

      showAlert(
        `Address ${
          isUnsetting ? "removed as" : "set as"
        } default ${addressType} address`,
        "success"
      );

      // // Refresh to ensure we have the latest state
      // fetchAddresses();
    } catch (error) {
      console.error("Error updating default address:", error);
      showAlert(
        error.response?.data?.msg || "Error updating default address",
        "error"
      );
      // Revert changes on error
      fetchAddresses();
    }
  };

  const handleSaveAddress = async (addressData) => {
    try {
      const data = {
        ...addressData,
        customer_id: customerId,
        business_id: businessId,
      };

      if (isNewAddress) {
        await api.post("/customers/addresses", data);
        showAlert("Address added successfully", "success");
      } else {
        await api.put(
          `/customers/addresses/${selectedAddress.address_id}`,
          data
        );
        showAlert("Address updated successfully", "success");
      }
      setIsAddEditOpen(false);
      fetchAddresses();
    } catch (error) {
      console.error("Error saving address:", error);
      showAlert(error.response?.data?.msg || "Error saving address", "error");
    }
  };

  const handleCloseAddressDialog = () => {
    setIsAddEditOpen(false);
    setSelectedAddress(null);
  };

  const showAlert = (message, severity) => {
    setAlert({ open: true, message, severity });
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleDialogClose = () => {
    setAddresses([]);
    setSelectedAddress(null);
    onClose();
  };

  const getAddressType = (type) => {
    switch (type) {
      case "shipping":
        return <HomeIcon color="primary" />;
      case "billing":
        return <BusinessIcon color="secondary" />;
      case "both":
        return (
          <Stack direction="row" spacing={1}>
            <HomeIcon color="primary" />
            <BusinessIcon color="secondary" />
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Manage Addresses</Typography>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddNew}
            >
              Add New Address
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <List>
            {addresses.map((address) => (
              <Paper
                key={address.address_id}
                elevation={1}
                sx={{ mb: 2, p: 2 }}
              >
                <ListItem
                  disablePadding
                  secondaryAction={
                    <Stack direction="row" spacing={1}>
                      <IconButton onClick={() => handleEdit(address)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(address.address_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" gap={1}>
                        {getAddressType(address.address_type)}
                        <Typography variant="subtitle1">
                          {address.address_line1}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <>
                        {address.address_line2 && (
                          <Typography variant="body2">
                            {address.address_line2}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          {`${address.city}, ${address.state} ${address.postal_code}`}
                        </Typography>
                        <Typography variant="body2">
                          {address.country}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                <Divider sx={{ my: 1 }} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={address.is_default}
                        onChange={() => handleSetDefault(address)}
                      />
                    }
                    label={`Default ${address.address_type} address`}
                  />
                  {address.delivery_instructions && (
                    <Typography variant="body2" color="text.secondary">
                      Note: {address.delivery_instructions}
                    </Typography>
                  )}
                </Box>
              </Paper>
            ))}
            {addresses.length === 0 && (
              <Alert severity="info">
                No addresses found. Click "Add New Address" to add your first
                address.
              </Alert>
            )}
          </List>
        </DialogContent>
      </Dialog>

      <CustomerAddressDialog
        open={isAddEditOpen}
        onClose={handleCloseAddressDialog}
        onSave={handleSaveAddress}
        address={selectedAddress}
        isNew={isNewAddress}
      />

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageAddressesDialog;
