import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Divider,
  CircularProgress,
  Alert,
  Box,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { AuthContext } from '../../../../components/Auth/AuthContext';

const ReceiptDialog = ({ open, onClose, collaboration, businessInfo }) => {
  const { api } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [receiptData, setReceiptData] = useState({
    payment_amount: collaboration?.total_cost || 0,
    payment_method: 'Bank Transfer',
    notes: ''
  });
  const [receipt, setReceipt] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReceiptData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleGenerateReceipt = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    
    try {
      const response = await api.post(
        `/shipping-collaboration/collaboration/${collaboration.collaboration_id}/receipt`,
        receiptData
      );
      
      setReceipt(response.data);
      setSuccess('Receipt generated successfully');
    } catch (err) {
      console.error('Error generating receipt:', err);
      setError('Failed to generate receipt. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Generate Collaboration Receipt</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        
        {receipt ? (
          <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={3}>
              <Box>
                <Typography variant="h5">RECEIPT</Typography>
                <Typography variant="body2" color="text.secondary">#{receipt.receipt_number}</Typography>
              </Box>
              <Box textAlign="right">
                <Typography variant="body2">Date: {formatDate(receipt.payment_details.payment_date)}</Typography>
              </Box>
            </Box>
            
            <Divider sx={{ mb: 3 }} />
            
            <Grid container spacing={2} mb={3}>
              <Grid item xs={6}>
                <Typography variant="subtitle2">From:</Typography>
                <Typography variant="body2">{collaboration.CollaboratingBusiness?.business_name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">To:</Typography>
                <Typography variant="body2">{collaboration.OriginatingBusiness?.business_name}</Typography>
              </Grid>
            </Grid>
            
            <Typography variant="subtitle1" gutterBottom>Collaboration Details</Typography>
            <List dense>
              <ListItem>
                <ListItemText 
                  primary="Collaboration ID" 
                  secondary={collaboration.collaboration_id} 
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Order ID" 
                  secondary={collaboration.shipping_order_id} 
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Collaboration Type" 
                  secondary={collaboration.collaboration_type} 
                />
              </ListItem>
            </List>
            
            <Typography variant="subtitle1" gutterBottom>Payment Details</Typography>
            <List dense>
              <ListItem>
                <ListItemText 
                  primary="Payment Method" 
                  secondary={receipt.payment_details.payment_method} 
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Payment Date" 
                  secondary={formatDate(receipt.payment_details.payment_date)} 
                />
              </ListItem>
              {receipt.payment_details.notes && (
                <ListItem>
                  <ListItemText 
                    primary="Notes" 
                    secondary={receipt.payment_details.notes} 
                  />
                </ListItem>
              )}
            </List>
            
            <Box sx={{ mt: 3, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom>Payment Summary</Typography>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography>Agreed Compensation:</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography>{formatCurrency(collaboration.agreed_compensation)}</Typography>
                </Grid>
                
                {collaboration.additional_charges && collaboration.additional_charges.length > 0 && (
                  <>
                    {collaboration.additional_charges.map((charge, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={8}>
                          <Typography variant="body2">{charge.description}:</Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <Typography variant="body2">{formatCurrency(charge.amount)}</Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </>
                )}
                
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
                
                <Grid item xs={8}>
                  <Typography variant="subtitle1">Total Amount:</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <Typography variant="subtitle1">{formatCurrency(receipt.payment_details.payment_amount)}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Collaboration Information
              </Typography>
              <Typography variant="body2" gutterBottom>
                ID: {collaboration.collaboration_id}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Status: {collaboration.status}
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="payment_amount"
                label="Payment Amount"
                type="number"
                value={receiptData.payment_amount}
                onChange={handleChange}
                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                disabled={loading}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Payment Method</InputLabel>
                <Select
                  name="payment_method"
                  value={receiptData.payment_method}
                  onChange={handleChange}
                  disabled={loading}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Credit Card">Credit Card</MenuItem>
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                  <MenuItem value="Check">Check</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="notes"
                label="Notes"
                multiline
                rows={3}
                value={receiptData.notes}
                onChange={handleChange}
                disabled={loading}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Payment Summary
              </Typography>
              <Box sx={{ p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Typography>Base Amount:</Typography>
                  </Grid>
                  <Grid item xs={4} textAlign="right">
                    <Typography>{formatCurrency(collaboration.agreed_compensation)}</Typography>
                  </Grid>
                  
                  {collaboration.additional_charges && collaboration.additional_charges.length > 0 && (
                    <>
                      {collaboration.additional_charges.map((charge, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={8}>
                            <Typography variant="body2">{charge.description}:</Typography>
                          </Grid>
                          <Grid item xs={4} textAlign="right">
                            <Typography variant="body2">{formatCurrency(charge.amount)}</Typography>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                  
                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                  </Grid>
                  
                  <Grid item xs={8}>
                    <Typography variant="subtitle1">Total Amount:</Typography>
                  </Grid>
                  <Grid item xs={4} textAlign="right">
                    <Typography variant="subtitle1">{formatCurrency(collaboration.total_cost)}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Close
        </Button>
        {!receipt && (
          <Button 
            onClick={handleGenerateReceipt} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Generate Receipt'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReceiptDialog;