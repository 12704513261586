// src/utils/printSettings.js

const defaultPrintSettings = {
  receipts: {
    size: "4x6",
    qrCodeSize: 120,
    includeDetails: true,
    fontSizes: {
      title: 16,
      subtitle: 14,
      details: 12,
    },
    orientation: "portrait",
  },
  shippingLabels: {
    size: "4x6",
    qrCodeSize: 120,
    includeDetails: true,
    fontSizes: {
      title: 16,
      subtitle: 14,
      details: 12,
    },
    orientation: "portrait",
  },
  shippingManifests: {
    size: "8.5x11",
    qrCodeSize: 120,
    includeDetails: true,
    fontSizes: {
      title: 16,
      subtitle: 14,
      details: 12,
    },
    orientation: "portrait",
  },
  shippingDocuments: {
    size: "8.5x11",
    qrCodeSize: 120,
    includeDetails: true,
    fontSizes: {
      title: 16,
      subtitle: 14,
      details: 12,
    },
    orientation: "portrait",
  },
  individualItemLabels: {
    size: "2x2",
    qrCodeSize: 120,
    includeDetails: true,
    fontSizes: {
      title: 16,
      subtitle: 14,
      details: 12,
    },
    orientation: "portrait",
  },
};

function mergeSettings(defaults, saved) {
  const merged = { ...defaults };
  for (const key in defaults) {
    merged[key] = {
      ...defaults[key],
      ...(saved[key] || {}),
    };
    if (defaults[key].fontSizes) {
      merged[key].fontSizes = {
        ...defaults[key].fontSizes,
        ...(saved[key]?.fontSizes || {}),
      };
    }
  }
  return merged;
}

export function loadPrintSettings(businessId) {
  if (!businessId) return defaultPrintSettings;

  try {
    const savedSettings = JSON.parse(
      localStorage.getItem(`printSettings_${businessId}`) || "{}"
    );
    return mergeSettings(defaultPrintSettings, savedSettings);
  } catch (error) {
    console.error("Error loading print settings:", error);
    return defaultPrintSettings;
  }
}

export function getCategorySettings(businessId, category) {
  const settings = loadPrintSettings(businessId);
  return settings[category];
}

export function updatePrintSettings(businessId, category, newValues) {
  if (!businessId) return defaultPrintSettings;

  try {
    const currentSettings = loadPrintSettings(businessId);
    const updated = {
      ...currentSettings,
      [category]: {
        ...currentSettings[category],
        ...newValues,
      },
    };

    localStorage.setItem(
      `printSettings_${businessId}`,
      JSON.stringify(updated)
    );
    return updated;
  } catch (error) {
    console.error("Error updating print settings:", error);
    return defaultPrintSettings;
  }
}

export function savePrintSettings(businessId, category, newValues) {
  const updated = updatePrintSettings(businessId, category, newValues);
  alert(`${category} settings saved successfully.`);
  return updated;
}

export function getPrinterConfig(businessId, category) {
  try {
    const printers = JSON.parse(
      localStorage.getItem(`printers_${businessId}`) || "{}"
    );
    return printers[category] || null;
  } catch (error) {
    console.error("Error getting printer config:", error);
    return null;
  }
}
