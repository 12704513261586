import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { AuthContext } from "../../../components/Auth/AuthContext";

const libraries = ["places"];

const CustomerAddressDialog = ({
  open,
  onClose,
  onSave,
  address = null,
  isNew = true,
}) => {
  const { api } = useContext(AuthContext);
  const [formData, setFormData] = useState(() => ({
    address_type: "both",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    delivery_instructions: "",
    is_default: false,
    ...address,
  }));

  useEffect(() => {
    if (open) {
      setFormData({
        address_type: "both",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        delivery_instructions: "",
        is_default: false,
        ...address,
      });
    }
  }, [open, address]);

  const [errors, setErrors] = useState({});
  const [searchAddress, setSearchAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const getAddressComponent = (addressComponents, types) => {
    return (
      addressComponents.find((component) =>
        component.types.some((t) => types.includes(t))
      )?.long_name || ""
    );
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;

      // Extract street parts
      const streetNumber = getAddressComponent(addressComponents, [
        "street_number",
      ]);
      const route = getAddressComponent(addressComponents, ["route"]);
      const subpremise = getAddressComponent(addressComponents, ["subpremise"]);

      // Combine, then trim
      const addressLine1 = [streetNumber, route]
        .filter(Boolean)
        .join(" ")
        .trim();

      setFormData((prev) => ({
        ...prev,
        address_line1: addressLine1,
        address_line2: subpremise, // If there's a subpremise, put it in line2
        city: getAddressComponent(addressComponents, ["locality"]) || "",
        state:
          getAddressComponent(addressComponents, [
            "administrative_area_level_1",
          ]) || "",
        postal_code:
          getAddressComponent(addressComponents, ["postal_code"]) || "",
        country: getAddressComponent(addressComponents, ["country"]) || "",
        latitude: latLng.lat,
        longitude: latLng.lng,
      }));

      setSearchAddress(selectedAddress);
      setErrors({});
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.address_line1)
      newErrors.address_line1 = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.postal_code)
      newErrors.postal_code = "Postal code is required";
    if (!formData.country) newErrors.country = "Country is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        await onSave(formData);
        // clear search address after save
        setSearchAddress("");
      } catch (error) {
        console.error("Error saving address:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]:
        field === "is_default" ? event.target.checked : event.target.value,
    }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["us", "ca"] },
  };

  if (loadError) {
    return <Alert severity="error">Error loading Google Maps API</Alert>;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isNew ? "Add New Address" : "Edit Address"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Address Type</InputLabel>
              <Select
                value={formData.address_type}
                onChange={handleChange("address_type")}
                label="Address Type"
              >
                <MenuItem value="shipping">Shipping</MenuItem>
                <MenuItem value="billing">Billing</MenuItem>
                <MenuItem value="both">Both</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_default}
                  onChange={handleChange("is_default")}
                  color="primary"
                />
              }
              label="Set as Default"
            />
          </Grid>
          <Grid item xs={12}>
            {isLoaded && (
              <PlacesAutocomplete
                value={searchAddress}
                onChange={setSearchAddress}
                onSelect={handleSelect}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextField
                      fullWidth
                      {...getInputProps({
                        label: "Search Address",
                        placeholder: "Start typing your address...",
                      })}
                    />
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 1000,
                        backgroundColor: "white",
                        width: "100%",
                        boxShadow: suggestions.length
                          ? "0 2px 4px rgba(0,0,0,0.2)"
                          : "none",
                      }}
                    >
                      {loading && (
                        <div style={{ padding: "8px" }}>Loading...</div>
                      )}
                      {suggestions.map((suggestion) => {
                        const style = {
                          padding: "8px",
                          cursor: "pointer",
                          backgroundColor: suggestion.active
                            ? "#f5f5f5"
                            : "#fff",
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, { style })}
                            key={suggestion.placeId}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 1"
              value={formData.address_line1}
              onChange={handleChange("address_line1")}
              error={!!errors.address_line1}
              helperText={errors.address_line1}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              value={formData.address_line2}
              onChange={handleChange("address_line2")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              value={formData.city}
              onChange={handleChange("city")}
              error={!!errors.city}
              helperText={errors.city}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="State/Province"
              value={formData.state}
              onChange={handleChange("state")}
              error={!!errors.state}
              helperText={errors.state}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Postal Code"
              value={formData.postal_code}
              onChange={handleChange("postal_code")}
              error={!!errors.postal_code}
              helperText={errors.postal_code}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              value={formData.country}
              onChange={handleChange("country")}
              error={!!errors.country}
              helperText={errors.country}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Delivery Instructions"
              value={formData.delivery_instructions}
              onChange={handleChange("delivery_instructions")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button onClick={onClose} color="secondary" startIcon={<CancelIcon />}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerAddressDialog;
