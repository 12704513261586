import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
  Slider,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  Skeleton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Stack,
  Button,
} from "@mui/material";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Refresh as RefreshIcon,
  Store,
  Home,
  Send as SendIcon,
  MyLocation as MyLocationIcon,
  AccessTime as TimeIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import styles from "./OrderLocationMap.module.css";
import { useParams } from "react-router-dom";

// ─────────────────────────────────────────────────────────────────────────────
// 1) LEAFLET Imports & CSS
// ─────────────────────────────────────────────────────────────────────────────
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Import your RouteSegments component
import RouteSegments from "./RouteSegments";

// Helper function to validate coordinates
const isValidCoordinate = (lat, lng) => {
  return (
    lat !== undefined &&
    lng !== undefined &&
    !isNaN(Number(lat)) &&
    !isNaN(Number(lng)) &&
    Math.abs(Number(lat)) <= 90 &&
    Math.abs(Number(lng)) <= 180
  );
};

// 2) Define the default Leaflet marker icon (blue) and shadow
const defaultIcon = L.icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = defaultIcon; // Set as the default icon

const OrderLocationMap = () => {
  const { api } = useContext(AuthContext);

  // ────────────────────────────────────────────────────────────────────────────
  // 3) State
  // ────────────────────────────────────────────────────────────────────────────
  const [showControls, setShowControls] = useState(true);

  // Map info
  const [mapCenter, setMapCenter] = useState({ lat: 22.0964, lng: -79.9542 });
  const [mapZoom, setMapZoom] = useState(12);

  // Locations
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationHistory, setLocationHistory] = useState([]);
  const [startLocation, setStartLocation] = useState(null);
  const [senderLocation, setSenderLocation] = useState(null);
  const [recipientLocation, setRecipientLocation] = useState(null);

  // Marker / Segment selections
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedSegmentId, setSelectedSegmentId] = useState(null);

  // Order ID & Business ID
  const { orderId, businessId } = useParams();

  // Segments
  const [segments, setSegments] = useState([]);

  // Loading & error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Map settings
  const [mapSettings, setMapSettings] = useState({
    showHistoryPath: true,
    showAllLocations: true,
    pathOpacity: 0.7,
    mapType: "roadmap", // We'll handle multiple tile layers based on this
    refreshInterval: 30,
  });

  // ────────────────────────────────────────────────────────────────────────────
  // 4) Utility to calculate expected times (similar to RouteSegments)
  // ────────────────────────────────────────────────────────────────────────────
  const calculateExpectedTimes = (segmentsData) => {
    if (!segmentsData?.length) return [];
    let currentTime = new Date();

    return segmentsData.map((segment, index) => {
      const startTime =
        index === 0
          ? currentTime
          : new Date(
              currentTime.getTime() + (segment.estimated_duration || 0) * 60000
            );

      const endTime = new Date(
        startTime.getTime() + (segment.estimated_duration || 0) * 60000
      );

      currentTime = endTime; // Advance for the next iteration
      return {
        ...segment,
        expected_start: startTime,
        expected_end: endTime,
      };
    });
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 5) Fetch location data
  // ────────────────────────────────────────────────────────────────────────────
  const fetchLocationData = async () => {
    try {
      const response = await api.get(
        `/shipping-orders/location-tracking/${businessId}/orders/${orderId}`
      );
      if (response.data.success) {
        const { current_location, location_history, addresses } =
          response.data.data;

        // Current location
        if (current_location) {
          setCurrentLocation({
            ...current_location,
            lat: Number(current_location.latitude),
            lng: Number(current_location.longitude),
            type: "current",
            name: "Current Location",
          });
        }

        // Location history
        setLocationHistory(
          location_history.map((loc) => ({
            lat: Number(loc.latitude),
            lng: Number(loc.longitude),
            timestamp: new Date(loc.recorded_at),
          }))
        );

        // Start
        if (addresses?.origin) {
          setStartLocation({
            lat: Number(addresses.origin.latitude),
            lng: Number(addresses.origin.longitude),
            name: addresses.origin.name,
            address: formatAddress(addresses.origin),
            type: "origin",
          });
        }

        // Sender
        if (addresses?.sender) {
          setSenderLocation({
            lat: Number(addresses.sender.latitude),
            lng: Number(addresses.sender.longitude),
            name: addresses.sender.name,
            address: formatAddress(addresses.sender),
            type: "sender",
          });
        }

        // Recipient
        if (addresses?.recipient) {
          setRecipientLocation({
            lat: Number(addresses.recipient.latitude),
            lng: Number(addresses.recipient.longitude),
            name: addresses.recipient.name,
            address: formatAddress(addresses.recipient),
            type: "recipient",
          });
        }

        // Auto-center logic
        if (current_location) {
          setMapCenter({
            lat: Number(current_location.latitude),
            lng: Number(current_location.longitude),
          });
        } else if (addresses?.origin && addresses?.recipient) {
          setMapCenter({
            lat:
              (Number(addresses.origin.latitude) +
                Number(addresses.recipient.latitude)) /
              2,
            lng:
              (Number(addresses.origin.longitude) +
                Number(addresses.recipient.longitude)) /
              2,
          });
        }

        setLoading(false);
      }
    } catch (err) {
      console.error("[OrderLocationMap] Error:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Add this helper function at the top of the file, near the isValidCoordinate function
  const getCoordinates = (location) => {
    if (!location) return null;

    // Handle business address format
    if (
      location.business_address_latitude &&
      location.business_address_longitude
    ) {
      return {
        lat: Number(location.business_address_latitude),
        lng: Number(location.business_address_longitude),
      };
    }

    // Handle customer address format
    if (location.latitude && location.longitude) {
      return {
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      };
    }

    // If neither format matches, return null
    console.warn("Could not extract coordinates from location:", location);
    return null;
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 6) Fetch segments
  // ────────────────────────────────────────────────────────────────────────────
  const fetchSegments = async () => {
    try {
      const response = await api.get(`/route-segments/${orderId}/segments`);
      console.log("API response for segments:", response.data);
      const modifiedSegments = calculateExpectedTimes(response.data);
      console.log("Modified segments after processing:", modifiedSegments);
      setSegments(modifiedSegments);
    } catch (err) {
      console.error("Error fetching segments:", err);
      setError(err.message);
    }
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 7) useEffect to load / refresh data
  // ────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    // If either prop is missing, skip fetches
    if (!orderId || !businessId) return;

    Promise.all([fetchLocationData(), fetchSegments()]);

    const interval = setInterval(() => {
      Promise.all([fetchLocationData(), fetchSegments()]);
    }, mapSettings.refreshInterval * 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, businessId, mapSettings.refreshInterval]);

  // ────────────────────────────────────────────────────────────────────────────
  // 8) Helpers
  // ────────────────────────────────────────────────────────────────────────────
  const formatAddress = (address) =>
    [
      address.address_line1,
      address.address_line2,
      address.city,
      address.state_province,
      address.postal_code,
      address.country,
    ]
      .filter(Boolean)
      .join(", ");

  const formatDateTime = (timestamp) =>
    timestamp ? new Date(timestamp).toLocaleString() : "";

  const getPolylinePositions = (loc1, loc2) => {
    if (!loc1 || !loc2) return [];

    // Validate coordinates before returning positions
    if (
      isNaN(loc1.lat) ||
      isNaN(loc1.lng) ||
      isNaN(loc2.lat) ||
      isNaN(loc2.lng)
    ) {
      console.warn("Invalid coordinates detected:", loc1, loc2);
      return [];
    }

    return [
      [loc1.lat, loc1.lng],
      [loc2.lat, loc2.lng],
    ];
  };

  const getSegmentColor = (status) => {
    switch (status) {
      case "completed":
        return "#4CAF50";
      case "in_progress":
        return "#2196F3";
      default:
        return "#757575";
    }
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 8.1) Function to choose tile layer based on "mapType"
  // ────────────────────────────────────────────────────────────────────────────
  const getTileLayerUrl = () => {
    switch (mapSettings.mapType) {
      case "satellite":
        // Example: Esri Satellite
        return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      case "hybrid":
        // Hybrid is often satellite imagery with labels. We'll reuse the same Esri Satellite.
        return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      case "terrain":
        // Example: OpenTopoMap
        return "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png";
      default:
        // 'roadmap' or fallback
        return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    }
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 9) Render Markers
  // ────────────────────────────────────────────────────────────────────────────
  const renderMarkers = () => {
    const markers = [];

    if (
      startLocation &&
      isValidCoordinate(startLocation.lat, startLocation.lng)
    ) {
      markers.push(
        <Marker
          key="startLocation"
          position={[startLocation.lat, startLocation.lng]}
          eventHandlers={{
            click: (e) => {
              e.originalEvent.stopPropagation();
              setSelectedMarker(startLocation);
              setSelectedSegmentId(null);
            },
          }}
        />
      );
    }

    if (
      senderLocation &&
      isValidCoordinate(senderLocation.lat, senderLocation.lng)
    ) {
      markers.push(
        <Marker
          key="senderLocation"
          position={[senderLocation.lat, senderLocation.lng]}
          eventHandlers={{
            click: (e) => {
              e.originalEvent.stopPropagation();
              setSelectedMarker(senderLocation);
              setSelectedSegmentId(null);
            },
          }}
        />
      );
    }

    if (
      recipientLocation &&
      isValidCoordinate(recipientLocation.lat, recipientLocation.lng)
    ) {
      markers.push(
        <Marker
          key="recipientLocation"
          position={[recipientLocation.lat, recipientLocation.lng]}
          eventHandlers={{
            click: (e) => {
              e.originalEvent.stopPropagation();
              setSelectedMarker(recipientLocation);
              setSelectedSegmentId(null);
            },
          }}
        />
      );
    }

    return markers;
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 10) Render Polylines (Route Segments)
  // ────────────────────────────────────────────────────────────────────────────
  const renderSegmentPaths = () => {
    // Log the entire segments data once, outside the map function
    console.log("Segments data:", JSON.stringify(segments, null, 2));

    return segments.map((segment, index) => {
      if (!segment.startLocation || !segment.endLocation) return null;

      // Extract coordinates properly based on location type
      const startPos = getCoordinates(segment.startLocation);
      const endPos = getCoordinates(segment.endLocation);

      // If either coordinate extraction failed, skip this segment
      if (!startPos || !endPos) {
        console.warn(
          "Invalid coordinates detected in segment:",
          segment.route_segment_id,
          {
            startLocation: segment.startLocation
              ? segment.startLocation.business_address_id
                ? "business"
                : "customer"
              : "missing",
            endLocation: segment.endLocation
              ? segment.endLocation.business_address_id
                ? "business"
                : "customer"
              : "missing",
          }
        );
        return null;
      }

      const positions = getPolylinePositions(startPos, endPos);
      if (positions.length === 0) {
        console.warn(
          "Empty positions array for segment:",
          segment.route_segment_id
        );
        return null;
      }

      const isSelected = selectedSegmentId === segment.route_segment_id;

      return (
        <React.Fragment key={segment.route_segment_id || `segment-${index}`}>
          <Polyline
            pathOptions={{
              color: isSelected ? "#FF4081" : getSegmentColor(segment.status),
              opacity: isSelected ? 1 : mapSettings.pathOpacity,
              weight: isSelected ? 5 : 3,
            }}
            positions={positions}
            eventHandlers={{
              click: (e) => {
                e.originalEvent.stopPropagation();
                handleSegmentClick(segment);
              },
            }}
          />
          {isSelected && (
            <>
              <Marker
                position={[startPos.lat, startPos.lng]}
                icon={L.divIcon({
                  className: styles.selectedSegmentMarker,
                  html: "●",
                  iconSize: [20, 20],
                })}
              />
              <Marker
                position={[endPos.lat, endPos.lng]}
                icon={L.divIcon({
                  className: styles.selectedSegmentMarker,
                  html: "●",
                  iconSize: [20, 20],
                })}
              />
            </>
          )}
        </React.Fragment>
      );
    });
  };

  const handleSegmentClick = (segment) => {
    if (!segment?.startLocation || !segment?.endLocation) return;

    const startPos = getCoordinates(segment.startLocation);
    const endPos = getCoordinates(segment.endLocation);

    if (!startPos || !endPos) {
      console.warn("Cannot process segment click due to invalid coordinates");
      return;
    }

    setSelectedSegmentId(segment.route_segment_id);

    const endLocationName = segment.is_last_segment
      ? segment.endLocation.address_line1
      : segment.endLocation.name || segment.endLocation.business_address_name;

    setSelectedMarker({
      ...startPos,
      name: "Route Segment",
      description: `${
        segment.startLocation.business_address_name ||
        segment.startLocation.address_line1
      } → ${endLocationName}`,
      status: segment.status,
      expectedTime:
        segment.expected_start && segment.expected_end
          ? `${formatDateTime(segment.expected_start)} - ${formatDateTime(
              segment.expected_end
            )}`
          : null,
    });

    if (mapRef.current) {
      const bounds = L.latLngBounds(
        [startPos.lat, startPos.lng],
        [endPos.lat, endPos.lng]
      );
      mapRef.current.fitBounds(bounds.pad(0.2));
    }
  };

  const handleSegmentSelect = (segmentId) => {
    setSelectedSegmentId(segmentId);

    const segment = segments.find((s) => s.route_segment_id === segmentId);
    if (!segment) return;

    const startLoc = getCoordinates(segment.startLocation);
    const endLoc = getCoordinates(segment.endLocation);

    if (!startLoc || !endLoc) {
      console.warn("Cannot select segment due to invalid coordinates");
      return;
    }

    // Calculate bounds
    const bounds = L.latLngBounds(
      [startLoc.lat, startLoc.lng],
      [endLoc.lat, endLoc.lng]
    );

    // Add padding to bounds
    const paddedBounds = bounds.pad(0.2);

    // Get map instance and fit bounds
    const map = mapRef.current;
    if (map) {
      map.fitBounds(paddedBounds);
    }
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 11) Location Info
  // ────────────────────────────────────────────────────────────────────────────
  const LocationInfo = ({ location, icon: Icon, color }) => {
    if (!location) return null;

    const handleLocationClick = () => {
      setSelectedMarker(location);
      setSelectedSegmentId(null);

      if (mapRef.current && location.lat && location.lng) {
        mapRef.current.setView([location.lat, location.lng], 15);
      }
    };

    return (
      <ListItem
        disableGutters
        button={true}
        onClick={handleLocationClick}
        sx={{
          cursor: "pointer",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
        }}
      >
        <ListItemIcon>
          <Icon sx={{ color }} />
        </ListItemIcon>
        <ListItemText
          primary={location.name}
          secondary={
            <Stack spacing={1}>
              <div>
                <Typography variant="body2" color="text.secondary">
                  {location.address}
                </Typography>
              </div>
              {location.type === "current" && location.recorded_at && (
                <Chip
                  size="small"
                  icon={<TimeIcon />}
                  label={`Updated: ${formatDateTime(location.recorded_at)}`}
                />
              )}
            </Stack>
          }
        />
      </ListItem>
    );
  };

  // ────────────────────────────────────────────────────────────────────────────
  // 12) Map Controls Panel (Collapsible)
  // ────────────────────────────────────────────────────────────────────────────
  const MapControls = () => (
    <Paper
      sx={{
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        width: "350px",
        transform: `translateX(${showControls ? "0" : "100%"})`,
        transition: "transform 0.3s ease-in-out",
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        backdropFilter: "blur(10px)",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        overflowY: "auto",
        zIndex: 999,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Location Information
      </Typography>
      <Divider />

      <List dense>
        <LocationInfo location={startLocation} icon={Store} color="#4CAF50" />
        <LocationInfo
          location={senderLocation}
          icon={SendIcon}
          color="#2196F3"
        />
        <LocationInfo
          location={recipientLocation}
          icon={Home}
          color="#FF5722"
        />
        <LocationInfo
          location={currentLocation}
          icon={MyLocationIcon}
          color="#9C27B0"
        />
      </List>

      <Divider />
      <Typography variant="h6" gutterBottom>
        Map Controls
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={mapSettings.showHistoryPath}
            onChange={(e) =>
              setMapSettings((prev) => ({
                ...prev,
                showHistoryPath: e.target.checked,
              }))
            }
          />
        }
        label="Show History Path"
      />

      <FormControlLabel
        control={
          <Switch
            checked={mapSettings.showAllLocations}
            onChange={(e) =>
              setMapSettings((prev) => ({
                ...prev,
                showAllLocations: e.target.checked,
              }))
            }
          />
        }
        label="Show All Locations"
      />

      <Box>
        <Typography gutterBottom>Path Opacity</Typography>
        <Slider
          value={mapSettings.pathOpacity}
          min={0.1}
          max={1}
          step={0.1}
          onChange={(_, value) =>
            setMapSettings((prev) => ({ ...prev, pathOpacity: value }))
          }
        />
      </Box>

      {/* The user wants "map types", so we keep the logic and switch tiles in getTileLayerUrl */}
      <FormControl fullWidth>
        <InputLabel>Map Type</InputLabel>
        <Select
          value={mapSettings.mapType}
          onChange={(e) =>
            setMapSettings((prev) => ({
              ...prev,
              mapType: e.target.value,
            }))
          }
        >
          <MenuItem value="roadmap">Road Map</MenuItem>
          <MenuItem value="satellite">Satellite</MenuItem>
          <MenuItem value="hybrid">Hybrid</MenuItem>
          <MenuItem value="terrain">Terrain</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>Refresh Interval</InputLabel>
        <Select
          value={mapSettings.refreshInterval}
          onChange={(e) =>
            setMapSettings((prev) => ({
              ...prev,
              refreshInterval: e.target.value,
            }))
          }
        >
          <MenuItem value={10}>10 seconds</MenuItem>
          <MenuItem value={30}>30 seconds</MenuItem>
          <MenuItem value={60}>1 minute</MenuItem>
          <MenuItem value={300}>5 minutes</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: "auto" }}>
        <Button
          startIcon={<RefreshIcon />}
          onClick={fetchLocationData}
          variant="outlined"
          fullWidth
        >
          Refresh Data
        </Button>
      </Box>
    </Paper>
  );

  // ────────────────────────────────────────────────────────────────────────────
  // 13) Render
  // ────────────────────────────────────────────────────────────────────────────
  const mapRef = useRef(null);

  if (loading) return <Skeleton variant="rectangular" height={400} />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ position: "relative", height: 500, mb: 2 }}>
          {/* The Leaflet map container */}
          <MapContainer
            ref={mapRef}
            center={[mapCenter.lat, mapCenter.lng]}
            zoom={mapZoom}
            style={{ width: "100%", height: "100%" }}
            scrollWheelZoom
          >
            <TileLayer
              url={getTileLayerUrl()} // Dynamically choose the URL based on mapSettings.mapType
              // You can also dynamically set "attribution" if needed
            />

            {/* Markers */}
            {mapSettings.showAllLocations && renderMarkers()}

            {/* Current Location Marker */}
            {currentLocation &&
              isValidCoordinate(currentLocation.lat, currentLocation.lng) && (
                <Marker
                  position={[currentLocation.lat, currentLocation.lng]}
                  eventHandlers={{
                    click: (e) => {
                      e.originalEvent.stopPropagation();
                      setSelectedMarker(currentLocation);
                      setSelectedSegmentId(null);
                    },
                  }}
                />
              )}

            {/* Polylines for each route segment */}
            {renderSegmentPaths()}

            {/* If a marker is selected (including route segment midpoint), show a Popup */}
            {selectedMarker &&
              isValidCoordinate(selectedMarker.lat, selectedMarker.lng) && (
                <Marker
                  // Invisible marker to anchor the popup at the correct coordinates
                  position={[selectedMarker.lat, selectedMarker.lng]}
                  opacity={0}
                >
                  <Popup autoPan onClose={() => setSelectedMarker(null)}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="subtitle2" component="div">
                        {selectedMarker.name}
                      </Typography>
                      {selectedMarker.description && (
                        <Typography variant="body2" component="div">
                          {selectedMarker.description}
                        </Typography>
                      )}
                      {selectedMarker.startType && (
                        <Typography variant="caption" display="block">
                          From: {selectedMarker.startType.replace("_", " ")}
                        </Typography>
                      )}
                      {selectedMarker.endType && (
                        <Typography variant="caption" display="block">
                          To: {selectedMarker.endType.replace("_", " ")}
                        </Typography>
                      )}
                      {selectedMarker.status && (
                        <Typography variant="caption" display="block">
                          Status: {selectedMarker.status}
                        </Typography>
                      )}
                      {selectedMarker.expectedTime && (
                        <Typography variant="caption" display="block">
                          Expected: {selectedMarker.expectedTime}
                        </Typography>
                      )}
                      {selectedMarker.recorded_at && (
                        <Typography variant="caption" display="block">
                          Updated: {formatDateTime(selectedMarker.recorded_at)}
                        </Typography>
                      )}
                    </Box>
                  </Popup>
                </Marker>
              )}

            {/* Optional location history polyline */}
            {mapSettings.showHistoryPath && locationHistory.length > 1 && (
              <Polyline
                pathOptions={{
                  color: "#9C27B0",
                  opacity: mapSettings.pathOpacity,
                  weight: 3,
                }}
                positions={locationHistory.map((loc) => [loc.lat, loc.lng])}
              />
            )}
          </MapContainer>

          {/* Slide-out controls on the right */}
          <MapControls />

          {/* Collapse button for the side panel */}
          <Paper
            sx={{
              position: "absolute",
              right: showControls ? "350px" : 0, // shift by panel width
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1000,
            }}
          >
            <IconButton onClick={() => setShowControls(!showControls)}>
              {showControls ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            </IconButton>
          </Paper>
        </Box>

        {/* Route Segments below */}
        <RouteSegments
          segments={segments}
          selectedSegmentId={selectedSegmentId}
          onSegmentSelect={handleSegmentSelect}
        />
      </CardContent>
    </Card>
  );
};

export default OrderLocationMap;
