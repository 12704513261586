import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./DashboardHeader.css";
import { AuthContext } from "../Auth/AuthContext";

const DashboardHeader = ({ toggleSidebar }) => {
  const { user, logout } = useContext(AuthContext);

  return (
    <header className="dashboard-header">
      <div className="user-info">
        <span>Welcome, {user ? user.user_first_name : "User"}!</span>
        <Link to="/profile" className="profile-link">
          Profile
        </Link>
        <button className="logout-button" onClick={async () => await logout()}>
          Logout
        </button>
      </div>
    </header>
  );
};

export default DashboardHeader;
