import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PanToolIcon from "@mui/icons-material/PanTool";
import SaveIcon from "@mui/icons-material/Save";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import RouteMapManager from "./RouteMapManager";
import RouteBuilderSidebar from "./RouteBuilderSidebar";
import ValidationChecklist from "./ValidationChecklist";
import {
  validateRoute,
  canCreateConnection,
  getOrderedEdgePath,
} from "./RouteLogic";
import { useParams } from "react-router-dom";
import StartLocationSelector from "../../../../../components/StartLocationSelector";

const RouteBuilder = ({ open, onClose, orderId, onSubmit }) => {
  const { api } = useContext(AuthContext);

  // Core state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState("edge");
  const [showStartLocationSelector, setShowStartLocationSelector] =
    useState(false);
  const [orderData, setOrderData] = useState(null);
  const { businessId } = useParams();

  // Data state
  const [locations, setLocations] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [routePath, setRoutePath] = useState([]);
  const [startLocationId, setStartLocationId] = useState(null);

  // Selection state
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);

  // Validation state
  const [validationState, setValidationState] = useState({
    isValid: false,
    conditions: [],
    errors: [],
    missingCourierEdges: [],
  });

  // Validate route whenever it changes
  useEffect(() => {
    const validation = validateRoute(routePath, locations);
    setValidationState(validation);
  }, [routePath, locations]);

  // Handle route updates
  const handleRouteUpdate = (updatedPath) => {
    // Ensure we're working with an array
    if (typeof updatedPath === "function") {
      updatedPath = updatedPath(routePath);
    }

    if (!Array.isArray(updatedPath)) {
      console.error("Invalid route path format");
      return;
    }

    // Normalize the path data
    const normalizedPath = updatedPath.map((edge) => ({
      id: edge.id || `${edge.from}-${edge.to}`,
      from: edge.from,
      to: edge.to,
      courier: edge.courier || null,
    }));

    // Order the path and update state
    const orderedPath = getOrderedEdgePath(normalizedPath, locations);
    setRoutePath(orderedPath);
  };

  // Handle courier assignment
  const handleCourierAssign = (edgeId, courierId) => {
    const updatedPath = routePath.map((edge) => {
      if (edge.id === edgeId) {
        return {
          ...edge,
          courier: couriers.find((c) => c.employee_id === courierId),
        };
      }
      return edge;
    });
    setRoutePath(updatedPath);
  };

  // Handle edge deletion
  const handleEdgeDelete = (edgeId) => {
    setRoutePath(routePath.filter((edge) => edge.id !== edgeId));
    setSelectedEdge(null);
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validationState.isValid) return;

    try {
      const segments = routePath.map((edge, index) => {
        const fromNode = locations.find((loc) => loc.id === edge.from);
        const toNode = locations.find((loc) => loc.id === edge.to);

        return {
          start_location_id: edge.from,
          start_location_type: fromNode.type,
          end_location_id: edge.to,
          end_location_type: toNode.type,
          courier_id: edge.courier?.employee_id,
          type: "courier",
          segment_order: index + 1,
        };
      });

      await api.put(`/route-segments/${orderId}/segments`, { segments });
      onSubmit?.();
      handleClose();
    } catch (error) {
      console.error("Error saving route:", error);
      setError("Failed to save route. Please try again.");
    }
  };

  // Fetch initial data
  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const orderResponse = await api.get(`/shipping-orders/${orderId}`);
      const order = orderResponse.data;
      console.log("Order data:", order);
      setOrderData(order);

      // Check if order has a valid start location
      if (!order.start_location_id) {
        console.log("No start location set for order, showing selector");
        setShowStartLocationSelector(true);
        setLoading(false);
        return;
      }

      const [
        locationsResponse,
        couriersResponse,
        startLocationResponse,
        addressResponse,
        existingSegmentsResponse,
      ] = await Promise.all([
        api.get(`/business/${businessId}/addresses`),
        api.get(`/employees/couriers/${businessId}`),
        // Get the address of the order's start location, it may not belong to our business so we need to fetch it separately
        api.get(`/business/addresses/${order.start_location_id}`),
        api.get(`/customers/address/${order.recipient_address_id}`),
        api
          .get(`/route-segments/${orderId}/segments`)
          .catch(() => ({ data: [] })),
      ]);

      setStartLocationId(order.start_location_id);
      setCouriers(couriersResponse.data);

      // Filter business addresses to get shipping locations only
      const shippingAddresses = locationsResponse.data.filter(
        (addr) =>
          addr.is_shipping_location ||
          ["warehouse", "dropoff_point", "pickup_point", "store"].includes(
            (
              addr.shipping_location_type || addr.business_address_type
            ).toLowerCase()
          )
      );

      // Check if the start location exists in the locations array
      const startLocationExists = shippingAddresses.some(
        (loc) => loc.business_address_id === order.start_location_id
      );

      // Create a locations array, ensuring we include the start location
      let locationsArray = [...shippingAddresses];

      // If start location isn't already in the list and we have start location data, add it
      if (!startLocationExists && startLocationResponse.data) {
        locationsArray.push(startLocationResponse.data);
      }

      // Transform locations into nodes
      const locationNodes = [
        ...locationsArray.map((loc) => ({
          id: loc.business_address_id,
          type:
            loc.business_address_id === order.start_location_id
              ? "start"
              : (
                  loc.shipping_location_type || loc.business_address_type
                ).toLowerCase(),
          label: loc.business_address_name,
          details: {
            ...loc,
          },
        })),
        {
          id: addressResponse.data.address.address_id,
          type: "delivery",
          label: "Delivery",
          details: {
            ...addressResponse.data.address,
            Customer: addressResponse.data.address.Customer,
          },
        },
      ];

      setLocations(locationNodes);

      // Transform segments into routes
      const segments = existingSegmentsResponse.data || [];
      const routes = segments.map((segment) => ({
        id: `${segment.start_location_id}-${segment.end_location_id}`,
        from: segment.start_location_id,
        to: segment.end_location_id,
        courier: couriersResponse.data.find(
          (c) => c.employee_id === segment.courier_id
        ),
      }));

      setRoutePath(routes);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load route data. Please try again.");
      setLoading(false);
    }
  };

  // Handle mode changes
  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setEditMode(newMode);
    }
  };

  // Reset and close
  const handleClose = () => {
    setSelectedNode(null);
    setSelectedEdge(null);
    setValidationState({
      isValid: false,
      conditions: [],
      errors: [],
      missingCourierEdges: [],
    });
    onClose();
  };

  // Handle start location selection
  const handleStartLocationSelect = async (locationId) => {
    setStartLocationId(locationId);
    setShowStartLocationSelector(false);
    setLoading(true);

    try {
      // Update the order's start location in our state
      if (orderData) {
        orderData.start_location_id = locationId;
        setOrderData({ ...orderData });
      }

      // Continue with data loading
      await fetchData();
    } catch (error) {
      console.error("Error after setting start location:", error);
      setError("Failed to load data after setting start location");
      setLoading(false);
    }
  };

  // Fetch data when dialog opens
  useEffect(() => {
    if (open && orderId) {
      fetchData();
    }

    // Cleanup when dialog closes
    return () => {
      setLocations([]);
      setRoutePath([]);
      setSelectedNode(null);
      setSelectedEdge(null);
      setError(null);
      setLoading(true);
    };
  }, [open, orderId]);

  // Get label for a node
  const getNodeLabel = (nodeId) => {
    const node = locations.find((loc) => loc.id === nodeId);
    return node ? node.label : "Unknown Location";
  };

  const validateNewConnection = (sourceId, targetId) => {
    return canCreateConnection(sourceId, targetId, locations, routePath);
  };

  const handleEdgeSelect = (edge) => {
    // Clear node selection when selecting an edge
    setSelectedNode(null);

    // If we're clicking an already selected edge, deselect it
    if (selectedEdge && selectedEdge.id === edge.id) {
      setSelectedEdge(null);
      return;
    }

    // Find the complete edge data from routePath
    const selectedPathEntry = routePath.find(
      (path) =>
        path.id === edge.id || (path.from === edge.from && path.to === edge.to)
    );

    if (selectedPathEntry) {
      setSelectedEdge(selectedPathEntry);
    }
  };

  const closeStartLocationSelector = () => {
    setShowStartLocationSelector(false);
    // close the dialog
    onClose();
  };

  return (
    <>
      <StartLocationSelector
        open={showStartLocationSelector}
        onClose={closeStartLocationSelector}
        onSelect={handleStartLocationSelect}
        businessId={businessId}
        orderId={orderId}
        title="Select Shipment Starting Location"
      />

      <Dialog
        open={open && !showStartLocationSelector}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ sx: { height: "90vh" } }}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Route Builder</Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <ToggleButtonGroup
                value={editMode}
                exclusive
                onChange={handleModeChange}
                size="small"
              >
                <ToggleButton value="edge" aria-label="edge creation">
                  <EditIcon />
                </ToggleButton>
                <ToggleButton value="move" aria-label="move nodes">
                  <PanToolIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ m: 2 }}>
              {error}
            </Alert>
          ) : (
            <>
              <Paper sx={{ p: 2, mb: 2, bgcolor: "#f5f5f5" }}>
                <Typography variant="body2">
                  • Yellow: <strong>Start Location</strong>
                  <br />
                  • Green: Shipping Locations | Red: Delivery
                  <br />• Click and drag between locations to create connections
                </Typography>
              </Paper>

              <Box display="flex" gap={2} height="calc(100% - 80px)">
                <Box flex={1}>
                  <RouteMapManager
                    editMode={editMode}
                    markers={locations}
                    routePath={routePath}
                    onMarkerSelect={setSelectedNode}
                    onEdgeSelect={handleEdgeSelect}
                    onRoutePathUpdate={handleRouteUpdate}
                    startLocationId={startLocationId}
                    validateConnection={validateNewConnection}
                    selectedEdge={selectedEdge}
                  />
                </Box>

                <Box width={300}>
                  <RouteBuilderSidebar
                    selectedNode={selectedNode}
                    selectedEdge={selectedEdge}
                    couriers={couriers}
                    routePath={routePath}
                    validationErrors={validationState.errors}
                    missingCourierEdges={validationState.missingCourierEdges}
                    onCourierAssign={handleCourierAssign}
                    onEdgeDelete={handleEdgeDelete}
                    getNodeLabel={getNodeLabel}
                  />
                </Box>
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
            variant="contained"
            disabled={loading || Boolean(error) || !validationState.isValid}
          >
            Save Route
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RouteBuilder;
