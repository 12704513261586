import { QueryClient } from '@tanstack/react-query';

// Create a client with sensible defaults for our application
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Default stale time - data will be considered fresh for 5 minutes
      staleTime: 5 * 60 * 1000,
      // Default cache time - data will remain in cache for 1 hour
      cacheTime: 60 * 60 * 1000,
      // Retry failed queries 1 time before showing error
      retry: 1,
      // Refetch on window focus (but respect staleTime)
      refetchOnWindowFocus: true,
      // Don't refetch on component mount if data isn't stale
      refetchOnMount: true,
      // Don't automatically refetch when component reconnects
      refetchOnReconnect: false,
      // Suspense mode disabled by default (enable for specific queries only)
      suspense: false,
      // Don't fetch data automatically on mount
      // This is useful for mutations or expensive operations
      enabled: true,
      // Use query key for structuredClone when available or JSON.stringify for older browsers
      structuralSharing: true,
    },
    mutations: {
      // Retry failed mutations once before showing error
      retry: 1,
    },
  },
});