export function formatNewEdge(edge, selectedEdgeId, couriers = []) {
  // Example color/style definitions:
  const EDGE_STYLES = {
    default: {
      stroke: "#999",
      strokeWidth: 2,
      opacity: 0.7,
    },
    assigned: {
      stroke: "#2196f3",
      strokeWidth: 2.5,
      opacity: 1,
    },
    selected: {
      stroke: "#4caf50",
      strokeWidth: 3,
      opacity: 1,
    },
    multiOrder: {
      stroke: "#673AB7",
      strokeWidth: 3,
      opacity: 0.9,
    },
  };

  // Basic order count
  const orderCount = Array.isArray(edge.data.orders)
    ? edge.data.orders.length
    : 0;

  // Build the label
  const assignedCourierName =
    edge.data?.courier?.employee_first_name || "Unassigned";
  const label = `${assignedCourierName} • ${orderCount} orders`;

  // Decide which style to use
  let style = { ...EDGE_STYLES.default };
  if (edge.id === selectedEdgeId) {
    style = { ...EDGE_STYLES.selected };
  } else if (edge.data?.courier) {
    style = { ...EDGE_STYLES.assigned };
  }
  // If multi-order edge, override style:
  if (orderCount > 1) {
    style = { ...EDGE_STYLES.multiOrder };
  }

  return {
    ...edge,
    label,
    style,
  };
}

////////////////////////////////////////////////////////////////////////
// 1) Finding the node that is the "delivery address" for an order
////////////////////////////////////////////////////////////////////////

export function findOrderDestinationNodeId(order, allNodes) {
  // Find matching delivery node
  const matchingNode = allNodes.find((n) => {
    if (n.data?.type !== "delivery") return false;

    // Case 1: Regular single delivery node
    if (n.data?.details?.order?.shipping_order_id === order.shipping_order_id) {
      return true;
    }

    // Case 2: Shared delivery node - check all orders
    if (n.data?.isSharedDelivery && n.data.details?.orders) {
      return n.data.details.orders.some(
        (o) => o.shipping_order_id === order.shipping_order_id
      );
    }

    // Case 3: Consolidated node - check all child nodes
    if (n.data?.isConsolidated && n.data.details?.childNodes) {
      return n.data.details.childNodes.some((child) => {
        if (child.details?.isSharedDelivery) {
          // Shared delivery child node
          return child.details.orders?.some(
            (o) => o.shipping_order_id === order.shipping_order_id
          );
        } else {
          // Regular child node
          return (
            child.details?.order?.shipping_order_id === order.shipping_order_id
          );
        }
      });
    }

    return false;
  });

  return matchingNode?.id || null;
}

////////////////////////////////////////////////////////////////////////
// 2) BFS to find a path from startNode -> finalDeliveryNode for that order
////////////////////////////////////////////////////////////////////////

/**
 * BFS to find *one path* from `startNodeId` to `endNodeId`.
 * - You store edges in e.g. { source, target, data: { orders: [...] } }
 * - The adjacency can be built from edges => a map of nodeId -> array of nodeId children.
 */
export function findPathBFS(startNodeId, endNodeId, edges) {
  if (!startNodeId || !endNodeId || startNodeId === endNodeId) return null;

  // Build adjacency for quick lookups
  const adjacency = new Map();
  edges.forEach((edge) => {
    // if an edge goes from e.source -> e.target, add to adjacency
    if (!adjacency.has(edge.source)) adjacency.set(edge.source, []);
    adjacency.get(edge.source).push(edge.target);
  });

  // BFS with queue
  const queue = [[startNodeId]]; // each element is a path array
  const visited = new Set([startNodeId]);

  while (queue.length) {
    const path = queue.shift(); // path is an array of nodeIds
    const lastNode = path[path.length - 1];
    if (lastNode === endNodeId) {
      return path; // found a path
    }
    const neighbors = adjacency.get(lastNode) || [];
    for (const nb of neighbors) {
      if (!visited.has(nb)) {
        visited.add(nb);
        queue.push([...path, nb]);
      }
    }
  }

  return null; // no path found
}

////////////////////////////////////////////////////////////////////////
// 3) getEdgesForPath
////////////////////////////////////////////////////////////////////////

/**
 * Once you have a path [n1, n2, n3, ..., nK], get the edges that connect them:
 * [ (n1->n2), (n2->n3), ... (n_{K-1}->nK) ]
 * so you can modify them easily.
 */
export function getEdgesForPath(nodePath, edges) {
  const pathEdges = [];
  for (let i = 0; i < nodePath.length - 1; i++) {
    const src = nodePath[i];
    const tgt = nodePath[i + 1];
    const e = edges.find((ed) => ed.source === src && ed.target === tgt);
    if (e) pathEdges.push(e);
  }
  return pathEdges;
}

/**
 * getAvailableSharedOrders:
 *  1) Gathers the shared node's "target" orderIds (from .startLocationGroups).
 *  2) Finds which of those are actually at sourceId
 *     (either arrived via incoming edges or start at sourceId).
 *  3) Excludes orders already used in other outgoing edges.
 */
export function getAvailableSharedOrders({ sourceId, targetNode, edges }) {
  // 1) All order IDs from all groups
  const targetOrderIds = targetNode.data.details.startLocationGroups
    .flatMap((group) => group.orders)
    .map((o) => o.shipping_order_id)
    .filter(Boolean);

  // 2) Figure out which have arrived at the source
  const ordersArrived = new Set();

  // incoming edges to sourceId
  const incomingEdges = edges.filter((e) => e.target === sourceId);
  incomingEdges.forEach((incEdge) => {
    (incEdge.data.orders || []).forEach((oid) => {
      if (targetOrderIds.includes(oid)) {
        ordersArrived.add(oid);
      }
    });
  });

  // plus any that start at this source node
  // see which group is for sourceId
  const groupForSource = targetNode.data.details.startLocationGroups.find(
    (g) => g.startLocationId === sourceId
  );
  if (groupForSource) {
    groupForSource.orders.forEach((orderObj) => {
      ordersArrived.add(orderObj.shipping_order_id);
    });
  }

  // 3) Exclude any already in outgoing edges from same source
  const outgoingEdges = edges.filter((e) => e.source === sourceId);
  outgoingEdges.forEach((outEdge) => {
    (outEdge.data.orders || []).forEach((oid) => {
      if (ordersArrived.has(oid)) {
        ordersArrived.delete(oid);
      }
    });
  });

  return [...ordersArrived];
}

/**
 * canOrderArriveAtSource:
 *  Logic for a single “would-be-assigned” order ID. We check if the order is
 *  either:
 *   (1) Arriving on an incoming edge to sourceId, OR
 *   (2) The order’s start_location_id matches sourceId.
 */
export function canOrderArriveAtSource({
  orderId,
  sourceId,
  edges,
  startLocationId,
}) {
  // If the order literally starts at sourceId
  if (startLocationId === sourceId) return true;

  // Otherwise, see if any incoming edge to sourceId has this order
  const incomingEdges = edges.filter((e) => e.target === sourceId);
  for (let incEdge of incomingEdges) {
    if (incEdge.data.orders && incEdge.data.orders.includes(orderId)) {
      return true;
    }
  }
  return false;
}
