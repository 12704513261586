import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  styled,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import "./SignUpForm.css";

const CustomButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #38E7FF, #6B8BFF)",
  color: theme.palette.common.white,
  margin: "10px 0",
  padding: "15px 30px",
  fontSize: "16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  boxShadow: "5px 5px 0 var(--dark-gray)",
  transition: "all 0.3s ease",
  "&:hover": {
    background:
      "linear-gradient(45deg, var(--emerald-green), var(--soft-blue))",
    transform: "translate(-2px, -2px)",
    boxShadow: "7px 7px 0 var(--dark-gray)",
  },
  "&:active": {
    transform: "translate(0, 0)",
    boxShadow: "5px 5px 0 var(--dark-gray)",
  },
}));

const ErrorMessageContainer = styled("div")({
  color: "#856404",
  backgroundColor: "#fff3cd",
  padding: "15px",
  borderRadius: "5px",
  border: "1px solid #ffeeba",
  marginTop: "15px",
  marginBottom: "15px",
  textAlign: "left",
  width: "100%",
  boxSizing: "border-box",
});

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { api } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = [];
    const { password, confirmPassword, email } = formData;

    // Check if email is valid
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.push("Please enter a valid email address.");
    }
    
    // Password validation
    if (password !== confirmPassword) {
      errors.push("Passwords do not match.");
    }
    if (password.length < 12) {
      errors.push("Password must be at least 12 characters long.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password must contain at least one number.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must contain at least one special character.");
    }
    if (
      [
        "password",
        "12345",
        "qwerty",
        "admin",
        "login",
        "welcome",
        "letmein",
        "abc123",
      ].some((pattern) => password.toLowerCase().includes(pattern))
    ) {
      errors.push("Password contains common patterns that are easily guessed.");
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      return false;
    }

    setErrorMessages([]);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setErrorMessages([]);

    try {
      await api.post("/auth/register", {
        user_name: formData.email.split("@")[0],
        user_email: formData.email,
        user_password: formData.password,
      });
      setIsEmailSent(true);
    } catch (error) {
      console.error("There was an error registering!", error);
      let errors = [];
      
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        console.log('Response data:', responseData);
        
        if (responseData.passwordErrors && responseData.passwordErrors.length > 0) {
          // Add password-specific errors
          errors = responseData.passwordErrors.map(err => err.msg);
        } else if (responseData.emailErrors && responseData.emailErrors.length > 0) {
          // Add email-specific errors
          errors = responseData.emailErrors.map(err => err.msg);
        } else if (responseData.nameErrors && responseData.nameErrors.length > 0) {
          // Add username-specific errors 
          errors = responseData.nameErrors.map(err => err.msg);
        } else if (responseData.errors && responseData.errors.length > 0) {
          // If no specific category matches, use all password-related errors
          const passwordRelatedErrors = responseData.errors.filter(err => 
            err.path === 'user_password'
          );
          
          if (passwordRelatedErrors.length > 0) {
            errors = passwordRelatedErrors.map(err => err.msg);
          } else {
            // Fallback to all errors
            errors = responseData.errors.map(err => err.msg);
          }
        } else if (responseData.error) {
          // Handle string error format
          errors = Array.isArray(responseData.error) 
            ? responseData.error 
            : [responseData.error];
        }
      } 
      
      if (errors.length === 0) {
        errors.push(error.message || "An error occurred during registration");
      }
      
      setErrorMessages(errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" className="signup-form" onSubmit={handleSubmit}>
      {isEmailSent ? (
        <div>
          <Typography variant="h5">Check your email</Typography>
          <Typography variant="body1">
            We have sent a confirmation link to your email. Please check your
            inbox and follow the instructions to complete your registration.
          </Typography>
        </div>
      ) : (
        <>
          {errorMessages.length > 0 && (
            <ErrorMessageContainer>
              <Typography variant="subtitle1" gutterBottom>
                Please fix the following issues:
              </Typography>
              <List dense>
                {errorMessages.map((msg, idx) => (
                  <ListItem key={idx} disableGutters>
                    <ListItemText primary={`• ${msg}`} />
                  </ListItem>
                ))}
              </List>
            </ErrorMessageContainer>
          )}
          <TextField
            name="email"
            variant="outlined"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            variant="outlined"
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            name="confirmPassword"
            variant="outlined"
            type="password"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <CustomButton
            type="submit"
            variant="contained"
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Sign Up"}
          </CustomButton>
        </>
      )}
    </Box>
  );
};

export default SignUpForm;
