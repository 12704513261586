import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Chip,
  Card,
  CardContent,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  CheckCircle,
  Block,
  Close,
  Help,
  HourglassEmpty,
} from "@mui/icons-material";
import EmployeeBusinessesList from "./EmployeeBusinessesList";

const defaultProfilePicture = "/images/background-image.jpg";
const defaultBannerPicture = "/images/background-image.jpg";

const BusinessesPage = () => {
  const { user } = useContext(AuthContext);
  const [businesses, setBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/business/get-all-businesses", {});

      if (response.data && Array.isArray(response.data.businesses)) {
        setBusinesses(response.data.businesses);
      } else {
        console.error("Unexpected data structure:", response.data);
        setAlertMessage({
          type: "error",
          message: "Received unexpected data structure from server.",
        });
        setBusinesses([]);
      }
    } catch (error) {
      console.error("Error fetching businesses:", error);
      let errorMessage = "Failed to fetch businesses. Please try again.";
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      } else if (error.request) {
        errorMessage = "No response received from server. Please try again.";
      }
      setAlertMessage({
        type: "error",
        message: errorMessage,
      });
      setBusinesses([]);
    }
    setIsLoading(false);
  };

  const handleNavigateToRegister = () => {
    navigate("/register-business");
  };

  const renderBusinessCard = (business) => {
    const profilePictureSrc =
      business.business_profile_picture_url || defaultProfilePicture;
    const bannerPictureSrc =
      business.business_banner_url || defaultBannerPicture;

    return (
      <Card
        key={business.business_id}
        className="business-card"
        onClick={() => navigate(`/business/${business.business_id}/dashboard`)}
      >
        <div className="bannerContainer">
          <img
            src={
              new URL(bannerPictureSrc, process.env.REACT_APP_API_BASE_URL).href
            }
            alt="Business Banner"
            className="bannerImage"
            onError={(e) => {
              e.target.src = defaultBannerPicture;
            }}
          />
        </div>
        <CardContent
          sx={{ p: 2, flex: 1, display: "flex", flexDirection: "column" }}
        >
          <div className="profileImageContainer">
            <img
              src={
                new URL(profilePictureSrc, process.env.REACT_APP_API_BASE_URL)
                  .href
              }
              alt="Business Profile"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onError={(e) => {
                e.target.src = defaultProfilePicture;
              }}
            />
          </div>
          <Typography variant="h6" className="businessName">
            {business.business_name}
          </Typography>
          <Chip
            icon={
              business.business_status === "active" ? (
                <CheckCircle />
              ) : business.business_status === "suspended" ? (
                <Block />
              ) : business.business_status === "pending" ? (
                <HourglassEmpty />
              ) : business.business_status === "closed" ? (
                <Close />
              ) : (
                <Help />
              )
            }
            label={
              business.business_status.charAt(0).toUpperCase() +
              business.business_status.slice(1)
            }
            size="small"
            className="statusChip"
            color={
              business.business_status === "active"
                ? "success"
                : business.business_status === "suspended"
                ? "error"
                : business.business_status === "pending"
                ? "warning"
                : business.business_status === "closed"
                ? "error"
                : "info"
            }
            sx={{ alignSelf: "center", mb: 1 }}
          />
          <Typography sx={{ fontSize: "0.9rem", mb: 0.5, textAlign: "center" }}>
            Email: {business.business_email}
          </Typography>
          <Typography sx={{ fontSize: "0.9rem", mb: 0.5, textAlign: "center" }}>
            Phone: {business.business_phone}
          </Typography>
          {business.addresses && business.addresses.length > 0 && (
            <div style={{ mt: 1, textAlign: "center" }}>
              <Typography variant="subtitle2">Primary Address:</Typography>
              <Typography variant="body2">
                {business.addresses[0].business_address_street_address_1}
                {business.addresses[0].business_address_street_address_2 &&
                  `, ${business.addresses[0].business_address_street_address_2}`}
              </Typography>
              <Typography variant="body2">
                {business.addresses[0].business_address_city},{" "}
                {business.addresses[0].business_address_state_province}{" "}
                {business.addresses[0].business_address_postal_code}
              </Typography>
              <Typography variant="body2">
                {business.addresses[0].business_address_country}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="user-page-wrapper">
      <div className="user-page-content">
        <EmployeeBusinessesList />
        <h1 className="user-page-title">Manage Businesses</h1>
        {alertMessage && (
          <Alert
            severity={alertMessage.type}
            variant="outlined"
            sx={{ mb: 2, width: "100%" }}
            onClose={() => setAlertMessage(null)}
          >
            {alertMessage.message}
          </Alert>
        )}

        {isLoading ? (
          <div className="user-loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="businesses-list">
            <Card
              className="business-card add-card"
              onClick={handleNavigateToRegister}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <AddBusinessIcon
                  style={{
                    fontSize: "4rem",
                    marginBottom: "1rem",
                    color: "var(--soft-blue)",
                  }}
                />
                <Typography variant="h6">Register New Business</Typography>
              </CardContent>
            </Card>
            {businesses.map((business) => renderBusinessCard(business))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessesPage;
