import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PanToolIcon from "@mui/icons-material/PanTool";
import SaveIcon from "@mui/icons-material/Save";
import { useParams } from "react-router-dom";
import StartLocationSelector from "../../../../../components/StartLocationSelector";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import { useOrders } from "../OrdersContext";
import RouteMapManager from "../../AssetsPage/RouteBuilder/RouteMapManager";
import RouteBuilderSidebar from "../../AssetsPage/RouteBuilder/RouteBuilderSidebar";
import {
  validateRoute,
  canCreateConnection,
  getOrderedEdgePath,
} from "../../AssetsPage/RouteBuilder/RouteLogic";

const OrderRouteBuilder = () => {
  const { api } = useContext(AuthContext);
  const {
    orders,
    locations,
    couriers,
    fetchLocations,
    fetchCouriers,
    fetchOrders,
  } = useOrders();
  const { orderId, businessId } = useParams();

  // Memoized values
  const currentOrder = useMemo(
    () => orders.find((order) => order.shipping_order_id === orderId),
    [orders, orderId]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [editMode, setEditMode] = useState("edge");
  const [routePath, setRoutePath] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [error, setError] = useState(null);
  const [showStartLocationSelector, setShowStartLocationSelector] =
    useState(false);
  const [startLocation, setStartLocation] = useState(null); // Add state for start location

  // Add this near your other memoized values
  const hasRequiredData = useMemo(() => {
    const isDataPresent = Boolean(
      locations.length && couriers.length && orders.length && currentOrder
    );

    if (!isDataPresent) return false;

    // Additional validation for nested data
    const hasRecipientData = Boolean(
      currentOrder.recipient &&
        currentOrder.recipient.Addresses &&
        currentOrder.recipient.Addresses.length > 0
    );

    const hasRequiredLocationData = Boolean(
      currentOrder.start_location_id && currentOrder.recipient_address_id
    );

    return isDataPresent && hasRecipientData && hasRequiredLocationData;
  }, [locations.length, couriers.length, orders.length, currentOrder]);

  // The simplified loading check should work with the start location selector
  const isLoading = useMemo(() => {
    // Never show loading when we need to show the start location selector
    if (currentOrder && !currentOrder.start_location_id) {
      return false;
    }

    return !hasRequiredData || isRefreshing;
  }, [currentOrder, hasRequiredData, isRefreshing]);

  const startLocationId = useMemo(
    () => currentOrder?.start_location_id,
    [currentOrder]
  );

  // Update locationNodes to include the fetched start location
  const locationNodes = useMemo(() => {
    if (!hasRequiredData) return [];

    // Create base locations from business locations
    const baseLocations = locations.map((loc) => ({
      id: loc.business_address_id,
      type:
        loc.business_address_id === currentOrder.start_location_id
          ? "start"
          : (
              loc.shipping_location_type || loc.business_address_type
            ).toLowerCase(),
      label: loc.business_address_name,
      details: {
        ...loc,
      },
    }));

    // Check if start location exists in locations
    const startLocationExists = baseLocations.some(
      (loc) => loc.id === currentOrder.start_location_id
    );

    // Add start location separately if it doesn't exist in our business locations
    const locationsWithStart = [...baseLocations];
    if (
      !startLocationExists &&
      startLocation &&
      currentOrder.start_location_id
    ) {
      locationsWithStart.push({
        id: startLocation.business_address_id,
        type: "start",
        label: startLocation.business_address_name || "Start Location",
        details: {
          ...startLocation,
        },
      });
    }

    // Add delivery location
    return [
      ...locationsWithStart,
      {
        id: currentOrder.recipient_address_id,
        type: "delivery",
        label: "Delivery",
        details: {
          ...currentOrder.recipient.Addresses[0],
          Customer: currentOrder.recipient,
        },
      },
    ];
  }, [hasRequiredData, currentOrder, locations, startLocation]);

  // Initialize route path once when data is available
  useEffect(() => {
    if (currentOrder?.RouteSegments?.length && couriers.length) {
      console.log("Initializing route segments:", {
        segments: currentOrder.RouteSegments,
        couriers: couriers.length,
      });

      const routes = currentOrder.RouteSegments.map((segment) => ({
        id: `${segment.start_location_id}-${segment.end_location_id}`,
        from: segment.start_location_id,
        to: segment.end_location_id,
        courier: couriers.find((c) => c.employee_id === segment.courier_id),
      }));
      console.log("Mapped route segments:", routes);
      setRoutePath(routes);
    }
  }, [currentOrder, couriers]);

  const validationState = useMemo(
    () => validateRoute(routePath, locationNodes),
    [routePath, locationNodes]
  );

  // Add a function to fetch start location
  const fetchStartLocation = useCallback(
    async (startLocationId) => {
      if (!startLocationId) return null;

      try {
        // Check if location already exists in locations
        const existingLocation = locations.find(
          (loc) => loc.business_address_id === startLocationId
        );

        if (existingLocation) return existingLocation;

        // Otherwise fetch it from API
        console.log(
          "Fetching start location that isn't in our business:",
          startLocationId
        );
        const response = await api.get(
          `/business/addresses/${startLocationId}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching start location:", error);
        return null;
      }
    },
    [api, locations]
  );

  // Handlers
  const handleRouteUpdate = useCallback(
    (updatedPath) => {
      const pathToUpdate =
        typeof updatedPath === "function"
          ? updatedPath(routePath)
          : updatedPath;

      if (!Array.isArray(pathToUpdate)) {
        console.error("Invalid route path format");
        return;
      }

      const normalizedPath = pathToUpdate.map((edge) => ({
        id: edge.id || `${edge.from}-${edge.to}`,
        from: edge.from,
        to: edge.to,
        courier: edge.courier || null,
      }));

      setRoutePath(getOrderedEdgePath(normalizedPath, locationNodes));
    },
    [routePath, locationNodes]
  );

  const handleCourierAssign = useCallback(
    (edgeId, courierId) => {
      setRoutePath((prevPath) =>
        prevPath.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                courier: couriers.find((c) => c.employee_id === courierId),
              }
            : edge
        )
      );
    },
    [couriers]
  );

  const handleEdgeDelete = useCallback((edgeId) => {
    setRoutePath((prev) => prev.filter((edge) => edge.id !== edgeId));
    setSelectedEdge(null);
  }, []);

  const handleSubmit = async () => {
    if (!validationState.isValid) return;

    try {
      const segments = routePath.map((edge, index) => ({
        start_location_id: edge.from,
        start_location_type: locationNodes.find((loc) => loc.id === edge.from)
          ?.type,
        end_location_id: edge.to,
        end_location_type: locationNodes.find((loc) => loc.id === edge.to)
          ?.type,
        courier_id: edge.courier?.employee_id,
        type: "courier",
        segment_order: index + 1,
      }));

      console.log("Saving route segments:", {
        orderId,
        segments,
        originalPath: routePath,
      });

      await api.put(`/route-segments/${orderId}/segments`, { segments });
      console.log("Route segments saved successfully");

      // Trigger a refresh to ensure context is updated
      await refreshData();
    } catch (error) {
      console.error("Save route failed:", error);
      setError("Failed to save route. Please try again.");
    }
  };

  const handleEdgeSelect = useCallback(
    (edge) => {
      setSelectedNode(null);
      setSelectedEdge((prev) =>
        prev?.id === edge.id
          ? null
          : routePath.find(
              (p) =>
                p.id === edge.id || (p.from === edge.from && p.to === edge.to)
            )
      );
    },
    [routePath]
  );

  const getNodeLabel = useCallback(
    (nodeId) =>
      locationNodes.find((loc) => loc.id === nodeId)?.label ||
      "Unknown Location",
    [locationNodes]
  );

  const validateNewConnection = useCallback(
    (sourceId, targetId) =>
      canCreateConnection(sourceId, targetId, locationNodes, routePath),
    [locationNodes, routePath]
  );

  // Refresh handler with guard to prevent recursive calls
  const refreshData = useCallback(async () => {
    if (!businessId) {
      setError("Business ID is required");
      return;
    }

    // Prevent concurrent refreshes
    if (isRefreshing) {
      console.log("Refresh already in progress, skipping");
      return;
    }

    setIsRefreshing(true);
    setError(null);

    console.log("Starting data refresh");

    try {
      // Use Promise.all for parallel fetching
      const [locationsResult, couriersResult, ordersResult] = await Promise.all(
        [
          fetchLocations(businessId),
          fetchCouriers(businessId),
          fetchOrders(businessId, true),
        ]
      );

      // Find the current order after refresh
      const refreshedOrder = ordersResult.find(
        (order) => order.shipping_order_id === orderId
      );

      // Fetch start location if it exists and not in business locations
      if (refreshedOrder && refreshedOrder.start_location_id) {
        const startLocationExists = locationsResult.some(
          (loc) => loc.business_address_id === refreshedOrder.start_location_id
        );

        if (!startLocationExists) {
          const startLoc = await fetchStartLocation(
            refreshedOrder.start_location_id
          );
          setStartLocation(startLoc);
        }
      }

      console.log("Refresh completed successfully");
    } catch (err) {
      const errorMessage = err.message || "An unexpected error occurred";
      setError(errorMessage);
      console.error("Data refresh failed:", err);
    } finally {
      setIsRefreshing(false);
    }
  }, [
    businessId,
    orderId,
    isRefreshing,
    fetchLocations,
    fetchCouriers,
    fetchOrders,
    fetchStartLocation,
  ]);

  // Simplified useEffect for initial data loading
  useEffect(() => {
    const initialLoad = async () => {
      // Don't proceed if already refreshing
      if (isRefreshing) return;

      console.log("Initial data load check", {
        hasLocations: locations.length > 0,
        hasCouriers: couriers.length > 0,
        hasOrders: orders.length > 0,
      });

      // Check if we need to load initial data
      if (!locations.length || !couriers.length || !orders.length) {
        console.log("Missing basic data, loading...");
        await refreshData();
        return;
      }

      // Check if we have the correct order
      if (!currentOrder) {
        console.log("Order not found after data load");
        setError("Specified order not found");
        return;
      }

      // Check if we need to fetch the start location
      if (currentOrder.start_location_id) {
        const startLocationExists = locations.some(
          (loc) => loc.business_address_id === currentOrder.start_location_id
        );

        if (!startLocationExists && !startLocation) {
          console.log("Fetching external start location");
          const startLoc = await fetchStartLocation(
            currentOrder.start_location_id
          );
          setStartLocation(startLoc);
        }
      }

      console.log("Initial data load complete");
    };

    initialLoad();
  }, [
    businessId,
    orderId,
    isRefreshing,
    refreshData,
    locations,
    couriers,
    orders,
    currentOrder,
    fetchStartLocation,
    startLocation,
  ]);

  // Separate useEffect just for the start location check
  useEffect(() => {
    // Skip if we don't have an order yet or we're refreshing
    if (!currentOrder || isRefreshing) {
      return;
    }

    // Order exists but has no start location
    if (!currentOrder.start_location_id) {
      console.log(
        "Order has no start location, showing selector",
        currentOrder
      );
      setShowStartLocationSelector(true);
    } else {
      // Make sure the selector is closed if order has a start location
      setShowStartLocationSelector(false);
    }
  }, [currentOrder, isRefreshing]);

  // Handle start location selection
  const handleStartLocationSelect = async (locationId) => {
    try {
      // Update the order's start location on the server
      await api.put(`/shipping-orders/${orderId}/start-location`, {
        start_location_id: locationId,
      });

      // Then refresh data to get the updated order
      setShowStartLocationSelector(false);
      await refreshData();
    } catch (error) {
      console.error("Error setting start location:", error);
      setError("Failed to update start location. Please try again.");
    }
  };

  // Handle cancel from start location selector
  const handleStartLocationCancel = () => {
    setShowStartLocationSelector(false);
    // If we don't have a start location, we can't continue, so show error
    setError("Cannot proceed without selecting a start location.");
  };

  // Early return for missing start location to ensure selector shows
  if (showStartLocationSelector) {
    return (
      <StartLocationSelector
        open={true}
        onClose={handleStartLocationCancel}
        onSelect={handleStartLocationSelect}
        businessId={businessId}
        orderId={orderId}
        title="Select Shipment Starting Location"
      />
    );
  }

  // Early return for loading state
  if (isLoading) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress sx={{ mb: 2 }} />
          <Typography variant="body1" color="textSecondary">
            Loading route builder...
          </Typography>
        </Box>
      </Box>
    );
  }

  // Early return for error state
  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={refreshData}>
              Retry
            </Button>
          }
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Typography variant="h6">Route Assignment</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <ToggleButtonGroup
            value={editMode}
            exclusive
            onChange={(event, newMode) => newMode && setEditMode(newMode)}
            size="small"
          >
            <ToggleButton value="edge" aria-label="edge creation">
              <EditIcon />
            </ToggleButton>
            <ToggleButton value="move" aria-label="move nodes">
              <PanToolIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      <Box sx={{ flex: 1, p: 2, overflow: "hidden" }}>
        <Paper sx={{ p: 2, mb: 2, bgcolor: "#f5f5f5" }}>
          <Typography variant="body2">
            • Yellow: <strong>Start Location</strong>
            <br />
            • Green: Shipping Locations | Red: Delivery
            <br />• Click and drag between locations to create connections
          </Typography>
        </Paper>

        <Box display="flex" gap={2} height="calc(100% - 100px)">
          <Box flex={1}>
            <RouteMapManager
              editMode={editMode}
              markers={locationNodes}
              routePath={routePath}
              onMarkerSelect={setSelectedNode}
              onEdgeSelect={handleEdgeSelect}
              onRoutePathUpdate={handleRouteUpdate}
              startLocationId={startLocationId}
              validateConnection={validateNewConnection}
              selectedEdge={selectedEdge}
            />
          </Box>

          <Box width={300}>
            <RouteBuilderSidebar
              selectedNode={selectedNode}
              selectedEdge={selectedEdge}
              couriers={couriers}
              routePath={routePath}
              validationErrors={validationState.errors}
              missingCourierEdges={validationState.missingCourierEdges}
              onCourierAssign={handleCourierAssign}
              onEdgeDelete={handleEdgeDelete}
              getNodeLabel={getNodeLabel}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
        <Button
          startIcon={<SaveIcon />}
          onClick={handleSubmit}
          variant="contained"
          disabled={!validationState.isValid}
          sx={{ float: "right" }}
        >
          Save Route
        </Button>
      </Box>
    </Box>
  );
};

export default OrderRouteBuilder;
