/**
 * Complete route validation and manipulation utilities
 */

/**
 * Gets the start node from the nodes array
 */
const getStartNode = (nodes) => nodes.find((node) => node.type === "start");

/**
 * Gets all nodes that can be reached from the start node
 */
const getReachableNodes = (edges, nodes) => {
  const startNode = getStartNode(nodes);
  if (!startNode) return new Set();

  const graph = {};
  edges.forEach((edge) => {
    if (!graph[edge.from]) graph[edge.from] = [];
    graph[edge.from].push(edge.to);
  });

  const reachable = new Set();
  const stack = [startNode.id];

  while (stack.length > 0) {
    const currentNode = stack.pop();
    if (reachable.has(currentNode)) continue;

    reachable.add(currentNode);
    const neighbors = graph[currentNode] || [];
    stack.push(...neighbors);
  }

  return reachable;
};

/**
 * Validates if creating a connection would create a cycle
 */
const wouldCreateCycle = (edges, newEdge) => {
  const allEdges = [...edges, newEdge];
  const visited = new Set();
  const recursionStack = new Set();

  const hasCycle = (node) => {
    if (recursionStack.has(node)) return true;
    if (visited.has(node)) return false;

    visited.add(node);
    recursionStack.add(node);

    const neighbors = allEdges
      .filter((edge) => edge.from === node)
      .map((edge) => edge.to);

    for (const neighbor of neighbors) {
      if (hasCycle(neighbor)) return true;
    }

    recursionStack.delete(node);
    return false;
  };

  return hasCycle(newEdge.from);
};

/**
 * Check if a path exists between two nodes
 */
const hasPath = (from, to, edges) => {
  if (!from || !to) return false;
  const visited = new Set();
  const stack = [from];

  while (stack.length > 0) {
    const current = stack.pop();
    if (current === to) return true;
    if (visited.has(current)) continue;
    visited.add(current);

    edges
      .filter((edge) => edge.from === current)
      .forEach((edge) => stack.push(edge.to));
  }

  return false;
};

/**
 * Validates whether a new connection can be created between nodes
 */
export const canCreateConnection = (
  sourceId,
  targetId,
  nodes,
  existingEdges
) => {
  console.log(
    "[canCreateConnection] sourceId:",
    sourceId,
    "targetId:",
    targetId
  );
  console.log("[canCreateConnection] existingEdges:", existingEdges);

  const sourceNode = nodes.find((node) => node.id == sourceId);
  const targetNode = nodes.find((node) => node.id == targetId);
  console.log(
    "[canCreateConnection] sourceNode:",
    sourceNode,
    "targetNode:",
    targetNode
  );

  if (!sourceNode || !targetNode) {
    console.log("[canCreateConnection] FAIL => Invalid connection attempt");
    return { valid: false, message: "Invalid connection attempt" };
  }

  if (sourceNode.id === targetNode.id) {
    console.log("[canCreateConnection] FAIL => Self-connection not allowed");
    return { valid: false, message: "Cannot connect a location to itself" };
  }

  if (targetNode.type === "start") {
    console.log("[canCreateConnection] FAIL => Cannot connect to start");
    return { valid: false, message: "Cannot connect to start location" };
  }

  if (sourceNode.type === "delivery") {
    console.log("[canCreateConnection] FAIL => Cannot connect from delivery");
    return {
      valid: false,
      message: "Cannot create connections from delivery location",
    };
  }

  // Outgoing edge limit check
  const hasExistingOutgoing = existingEdges.some(
    (edge) => edge.from == sourceNode.id
  );
  console.log(
    "[canCreateConnection] hasExistingOutgoing:",
    hasExistingOutgoing
  );

  if (hasExistingOutgoing) {
    console.log(
      "[canCreateConnection] FAIL => Location already has an outgoing connection"
    );
    return {
      valid: false,
      message: "Location already has an outgoing connection",
    };
  }

  // Incoming edge limit check
  const hasExistingIncoming = existingEdges.some(
    (edge) => edge.to == targetNode.id
  );
  console.log(
    "[canCreateConnection] hasExistingIncoming:",
    hasExistingIncoming
  );

  if (hasExistingIncoming) {
    console.log(
      "[canCreateConnection] FAIL => Location already has an incoming connection"
    );
    return {
      valid: false,
      message: "Location already has an incoming connection",
    };
  }

  console.log("[canCreateConnection] PASS => Valid new connection");
  return { valid: true };
};

/**
 * Validates the complete route and returns detailed condition statuses
 */
export const validateRoute = (edges, nodes) => {
  const startNode = nodes.find((node) => node.type === "start");
  const deliveryNode = nodes.find((node) => node.type === "delivery");
  const conditions = [];

  // Validate path to delivery location
  if (startNode && deliveryNode) {
    const hasDeliveryPath = hasPath(startNode.id, deliveryNode.id, edges);
    conditions.push({
      id: "deliveryPath",
      description: "Connect route to Delivery Location",
      isMet: hasDeliveryPath,
      message: "Create a path to the delivery location",
    });
  }

  // Check courier assignments
  const hasAllCouriers = edges.every((edge) => edge.courier);
  conditions.push({
    id: "courierAssignment",
    description: "Assign couriers to all connections",
    isMet: hasAllCouriers,
    message: hasAllCouriers ? null : "Assign couriers to all connections",
  });

  return {
    isValid: conditions.every((c) => c.isMet),
    conditions,
    errors: conditions.filter((c) => !c.isMet).map((c) => c.message),
  };
};

/**
 * Helper function to validate edge counts for each node
 */
const validateEdgeCounts = (edges) => {
  const incomingCounts = {};
  const outgoingCounts = {};
  const invalidNodes = new Set();

  // Count edges
  edges.forEach((edge) => {
    outgoingCounts[edge.from] = (outgoingCounts[edge.from] || 0) + 1;
    incomingCounts[edge.to] = (incomingCounts[edge.to] || 0) + 1;

    if (outgoingCounts[edge.from] > 1) invalidNodes.add(edge.from);
    if (incomingCounts[edge.to] > 1) invalidNodes.add(edge.to);
  });

  return {
    hasValidEdgeCounts: invalidNodes.size === 0,
    invalidNodes: Array.from(invalidNodes),
  };
};

/**
 * Gets edges ordered from start location to end
 */
export const getOrderedEdgePath = (edges, nodes) => {
  const startNode = getStartNode(nodes);
  if (!startNode || !edges.length) return [];

  const result = [];
  const visited = new Set();
  let currentNode = startNode.id;

  while (true) {
    // Look for an edge that starts from our current node
    const nextEdge = edges.find(
      (edge) =>
        (edge.from === currentNode || edge.from === currentNode.toString()) &&
        !visited.has(edge.from)
    );

    if (!nextEdge || visited.has(nextEdge.from)) break;

    result.push({
      ...nextEdge,
      id: nextEdge.id || `${nextEdge.from}-${nextEdge.to}`,
    });

    visited.add(nextEdge.from);
    currentNode = nextEdge.to;
  }

  return result;
};

/**
 * Calculates estimated duration between two points
 */
export const calculateEstimatedDuration = (sourceNode, targetNode) => {
  const AVERAGE_SPEED = 30; // km/h
  const distance = calculateDistance(sourceNode.details, targetNode.details);
  return Math.round((distance / AVERAGE_SPEED) * 60); // Convert to minutes
};

/**
 * Calculate distance between two points using Haversine formula
 */
const calculateDistance = (point1, point2) => {
  if (!point1 || !point2) return 0;

  const lat1 = Number(point1.latitude || point1.lat);
  const lon1 = Number(point1.longitude || point1.lng);
  const lat2 = Number(point2.latitude || point2.lat);
  const lon2 = Number(point2.longitude || point2.lng);

  const R = 6371; // Earth's radius in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};
