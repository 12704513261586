export const LOCATION_THRESHOLD_METERS = 100; // or your chosen distance

function calculateCenterPosition(nodes) {
  if (!nodes.length) return { x: 0, y: 0 };
  const xSum = nodes.reduce((acc, node) => acc + (node.position?.x || 0), 0);
  const ySum = nodes.reduce((acc, node) => acc + (node.position?.y || 0), 0);
  return {
    x: xSum / nodes.length,
    y: ySum / nodes.length,
  };
}

function validateThreshold(threshold) {
  const parsed = parseFloat(threshold);
  if (isNaN(parsed) || parsed <= 0) {
    return LOCATION_THRESHOLD_METERS;
  }
  return parsed;
}

/**
 * We define a helper that returns ( courierId, originId ) for a node:
 *   - courierId: from the edge that leads to this node
 *   - originId:  from the edge that leads to this node
 * If the node has multiple different couriers or origins, we do not unify it at all.
 */
function getNodeCourierAndOrigin(nodeId, edges) {
  // find all incoming edges
  const incoming = edges.filter((e) => e.target === nodeId);
  if (!incoming.length) {
    return { courier: "unassigned" };
  }

  // Check if they have multiple couriers
  const couriers = new Set(
    incoming.map((e) => e.data?.courier?.employee_id || "unassigned")
  );
  // If multiple different couriers => skip
  if (couriers.size > 1) {
    return null;
  }

  // There's exactly 1 unique courier
  const [onlyCourier] = [...couriers];
  return { courier: onlyCourier };
}

/**
 * BFS/DFS style grouping of non-consolidated delivery nodes within threshold distance
 * BUT now we also require that nodes share the *same (courier, origin)* pair
 * to be eligible for grouping.
 */
function findNodeGroupsByCourierAndOrigin(nodes, edges, threshold) {
  const groups = [];
  const visited = new Set();

  // 1) Sort nodes so that unassigned come first (or hasEdge vs. noEdge, etc.)
  const sortedNodes = [...nodes].sort((a, b) => {
    const aHasEdge = edges.some((e) => e.target === a.id);
    const bHasEdge = edges.some((e) => e.target === b.id);
    return bHasEdge - aHasEdge;
  });

  for (let i = 0; i < sortedNodes.length; i++) {
    const nodeA = sortedNodes[i];
    if (visited.has(nodeA.id)) continue;

    // get (courier, origin) for nodeA
    const nodeAInfo = getNodeCourierAndOrigin(nodeA.id, edges);
    // If null => means multiple incoming with different pairs => do not unify
    if (!nodeAInfo) {
      visited.add(nodeA.id);
      // stands alone => no group
      continue;
    }

    // Start a group with nodeA
    const group = [nodeA];
    visited.add(nodeA.id);

    // check if we can add more nodes that have the same (courier,origin) AND are within threshold
    for (let j = i + 1; j < sortedNodes.length; j++) {
      const nodeB = sortedNodes[j];
      if (visited.has(nodeB.id)) continue;

      // must share same pair
      const nodeBInfo = getNodeCourierAndOrigin(nodeB.id, edges);
      if (!nodeBInfo) continue; // multiple incoming => skip

      // same courier & origin
      if (
        nodeAInfo.courier === nodeBInfo.courier &&
        nodeAInfo.origin === nodeBInfo.origin
      ) {
        // check distance
        const dist = calculateDistance(
          nodeA.data?.details,
          nodeB.data?.details
        );
        if (dist <= threshold) {
          group.push(nodeB);
          visited.add(nodeB.id);
        }
      }
    }

    if (group.length > 1) {
      groups.push(group);
    }
  }

  return groups;
}

/**
 * Safely calculate distance (meters) between two points with { latitude, longitude }.
 * Returns Infinity if coordinates are missing/invalid.
 */
export function calculateDistance(point1 = {}, point2 = {}) {
  console.log("Calculating distance between points:", {
    point1: {
      lat: point1.latitude,
      lon: point1.longitude,
    },
    point2: {
      lat: point2.latitude,
      lon: point2.longitude,
    },
  });

  const lat1 = parseFloat(point1.latitude);
  const lon1 = parseFloat(point1.longitude);
  const lat2 = parseFloat(point2.latitude);
  const lon2 = parseFloat(point2.longitude);

  console.log("Parsed coordinates:", {
    lat1,
    lon1,
    lat2,
    lon2,
  });

  if (isNaN(lat1) || isNaN(lon1) || isNaN(lat2) || isNaN(lon2)) {
    console.log("Invalid coordinates detected, returning Infinity");
    return Infinity;
  }

  const R = 6371e3; // Earth radius in meters
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  console.log("Calculated values:", {
    "φ1 (rad)": φ1,
    "φ2 (rad)": φ2,
    "Δφ (rad)": Δφ,
    "Δλ (rad)": Δλ,
  });

  const a =
    Math.sin(Δφ / 2) ** 2 + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  console.log("Haversine formula components:", {
    a,
    c,
    "final distance (meters)": distance,
  });

  return distance;
}

/**
 * createConsolidatedNode:
 * Used by BFS grouping in `consolidateNodes`.
 * We also set `isChildNode=true` inside each child's details.
 *
 * IMPORTANT: if a child node was "shared delivery", it may have `details.orders`
 * (array of multiple orders). We want to include *all* of those in the final
 * consolidated node's `orders`.
 */
function createConsolidatedNode(group) {
  if (!Array.isArray(group) || group.length < 2) {
    throw new Error("Invalid group for consolidation");
  }

  // Build childNodes array
  const childNodes = group.map((node) => ({
    id: node.id,
    details: {
      ...node.data?.details,
      // Mark that this is a child node of a consolidated group
      isChildNode: true,
      // If it was shared
      isSharedDelivery: node.data?.isSharedDelivery === true,
    },
    position: { ...node.position },
  }));

  // Collect all orders from child nodes:
  //   1) if child is sharedDelivery => child.details.orders is an array
  //   2) otherwise if child has `details.order`, it’s a single
  const allOrders = childNodes.reduce((acc, child) => {
    if (
      child.details?.isSharedDelivery &&
      Array.isArray(child.details.orders)
    ) {
      // It's a shared node => gather all child.details.orders
      child.details.orders.forEach((ord) => {
        const soId =
          typeof ord.shipping_order_id === "string"
            ? ord.shipping_order_id
            : ord.shipping_order_id?.toString();
        acc.push({
          ...ord,
          shipping_order_id: soId,
        });
      });
    } else if (child.details?.order) {
      // Normal single-order node
      const singleOrder = child.details.order;
      const soId =
        typeof singleOrder.shipping_order_id === "string"
          ? singleOrder.shipping_order_id
          : singleOrder.shipping_order_id?.toString();
      acc.push({
        ...singleOrder,
        shipping_order_id: soId,
      });
    }
    return acc;
  }, []);

  // Calculate center lat/lon
  const latSum = childNodes.reduce(
    (acc, ch) => acc + (parseFloat(ch.details?.latitude) || 0),
    0
  );
  const lngSum = childNodes.reduce(
    (acc, ch) => acc + (parseFloat(ch.details?.longitude) || 0),
    0
  );
  const centerLat = childNodes.length ? latSum / childNodes.length : 0;
  const centerLng = childNodes.length ? lngSum / childNodes.length : 0;

  // Use average position of group nodes
  const centerPosition = calculateCenterPosition(group);

  // Build a stable ID from child node IDs
  const sortedIds = childNodes.map((c) => c.id).sort();
  const consolidatedId = `consolidated-${sortedIds.join("-")}`;

  return {
    id: consolidatedId,
    type: "locationNode",
    position: centerPosition,
    data: {
      type: "delivery",
      isConsolidated: true,
      label: `Consolidated Deliveries (${childNodes.length})`,
      details: {
        // This final node’s “orders” includes everything from child nodes
        orders: allOrders,
        latitude: centerLat,
        longitude: centerLng,
        childNodes,
      },
    },
  };
}

/**
 * BFS or DFS style grouping
 */
function findNodeGroups(nodes, edges, threshold) {
  const getNodeCourier = (nodeId) => {
    const edge = edges.find((e) => e.target === nodeId);
    return edge?.data?.courier?.employee_id || "unassigned";
  };

  // Sort nodes (with edges first)
  const sortedNodes = [...nodes].sort((a, b) => {
    const aHasEdge = edges.some((e) => e.target === a.id);
    const bHasEdge = edges.some((e) => e.target === b.id);
    return bHasEdge - aHasEdge;
  });

  // Separate unassigned and assigned nodes
  const unassignedNodes = sortedNodes.filter(
    (node) => getNodeCourier(node.id) === "unassigned"
  );
  const assignedNodes = sortedNodes.filter(
    (node) => getNodeCourier(node.id) !== "unassigned"
  );

  // Process unassigned nodes first
  const unassignedGroups = processNodeSet(unassignedNodes, threshold);

  // Process assigned nodes by courier
  const courierGroups = [];
  const courierMap = new Map();

  // Group nodes by courier
  assignedNodes.forEach((node) => {
    const courier = getNodeCourier(node.id);
    if (!courierMap.has(courier)) {
      courierMap.set(courier, []);
    }
    courierMap.get(courier).push(node);
  });

  // Process each courier's nodes separately
  courierMap.forEach((courierNodes, courier) => {
    if (courier !== "unassigned") {
      const groups = processNodeSet(courierNodes, threshold);
      courierGroups.push(...groups);
    }
  });

  return [...unassignedGroups, ...courierGroups];
}

function processNodeSet(nodes, threshold) {
  if (nodes.length < 2) return [];

  // Create distance matrix
  const distances = [];
  nodes.forEach((nodeA, i) => {
    distances[i] = [];
    nodes.forEach((nodeB, j) => {
      if (i === j) {
        distances[i][j] = 0;
      } else if (i < j) {
        distances[i][j] = calculateDistance(
          nodeA.data?.details,
          nodeB.data?.details
        );
      } else {
        distances[i][j] = distances[j][i];
      }
    });
  });

  // Initialize clusters
  const clusters = nodes.map((node, index) => ({
    nodes: [node],
    indices: new Set([index]),
  }));

  // Merge clusters within threshold
  let mergeHappened;
  do {
    mergeHappened = false;
    let minDist = Infinity;
    let mergeI = -1;
    let mergeJ = -1;

    for (let i = 0; i < clusters.length; i++) {
      for (let j = i + 1; j < clusters.length; j++) {
        let clusterDist = Infinity;
        clusters[i].indices.forEach((nodeI) => {
          clusters[j].indices.forEach((nodeJ) => {
            clusterDist = Math.min(clusterDist, distances[nodeI][nodeJ]);
          });
        });

        if (clusterDist <= threshold && clusterDist < minDist) {
          minDist = clusterDist;
          mergeI = i;
          mergeJ = j;
        }
      }
    }

    if (mergeI !== -1 && mergeJ !== -1) {
      mergeHappened = true;
      clusters[mergeI].nodes.push(...clusters[mergeJ].nodes);
      clusters[mergeI].indices = new Set([
        ...clusters[mergeI].indices,
        ...clusters[mergeJ].indices,
      ]);
      clusters.splice(mergeJ, 1);
    }
  } while (mergeHappened);

  return clusters
    .filter((cluster) => cluster.nodes.length > 1)
    .map((cluster) => cluster.nodes);
}

/**
 * consolidateNodes:
 * distance-based grouping of "delivery" nodes
 * ignoring already-consolidated nodes
 */
export function consolidateNodes(nodes, edges, threshold) {
  const validThreshold = validateThreshold(threshold);

  // separate out *already* consolidated if you don't want to re-merge them
  const alreadyConsolidated = nodes.filter(
    (n) => n.data?.type === "delivery" && n.data?.isConsolidated
  );
  const nonDelivery = nodes.filter((n) => n.data?.type !== "delivery");
  const toBeConsolidated = nodes.filter(
    (n) => n.data?.type === "delivery" && !n.data?.isConsolidated
  );

  // BFS/DFS groups
  const groups = findNodeGroups(toBeConsolidated, edges, validThreshold);

  // build new consolidated nodes
  const consolidatedNodes = groups.map((grp) => createConsolidatedNode(grp));

  const groupedIds = new Set(groups.flat().map((n) => n.id));
  const leftoverDeliveries = toBeConsolidated.filter(
    (n) => !groupedIds.has(n.id)
  );

  const individualNodes = [...leftoverDeliveries, ...alreadyConsolidated];

  // remove edges pointing to grouped nodes
  let updatedEdges = edges.filter((edge) => !groupedIds.has(edge.target));

  // 5) For each new consolidated node, build *all* incoming edges from any node in the group
  groups.forEach((group, idx) => {
    const consolidatedNode = consolidatedNodes[idx];
    // collect ALL incoming edges to any node in the group
    const allIncoming = edges.filter((e) =>
      group.some((n) => n.id === e.target)
    );

    // group them by (source, courier, status, etc.) so we can merge orders
    const edgeMap = new Map();
    allIncoming.forEach((incEdge) => {
      // identify unique key for merging
      const courierId = incEdge.data?.courier?.employee_id || "unassigned";
      const status = incEdge.data?.status || "pending";
      const key = `${incEdge.source}--${courierId}--${status}`;

      if (!edgeMap.has(key)) {
        edgeMap.set(key, {
          source: incEdge.source,
          courier: incEdge.data?.courier || null,
          status: incEdge.data?.status,
          estimatedDuration: incEdge.data?.estimatedDuration,
          isLastSegment: incEdge.data?.isLastSegment,
          orders: new Set(incEdge.data?.orders || []),
        });
      } else {
        const existing = edgeMap.get(key);
        (incEdge.data?.orders || []).forEach((o) => existing.orders.add(o));
      }
    });

    // Now create new edges from each (source,courier) => consolidatedNode
    edgeMap.forEach((val) => {
      updatedEdges.push({
        id: `consolidated-${consolidatedNode.id}-${val.source}-${Date.now()}`,
        source: val.source,
        target: consolidatedNode.id,
        type: "default",
        data: {
          orders: [...val.orders],
          courier: val.courier,
          status: val.status,
          isDeliveryEdge: true,
          isConsolidated: true,
          estimatedDuration: val.estimatedDuration,
          isLastSegment: val.isLastSegment,
        },
      });
    });
  });

  // remove duplicates
  updatedEdges = removeRedundantEdges(updatedEdges);

  return {
    consolidatedNodes,
    individualNodes,
    nonDeliveryNodes: nonDelivery,
    updatedEdges,
  };
}

function removeRedundantEdges(edges) {
  const seen = new Map();
  return edges.filter((edge) => {
    const key = `${edge.source}-${edge.target}`;
    if (seen.has(key)) {
      return false;
    }
    seen.set(key, edge);
    return true;
  });
}

/**
 * splitConsolidatedNode:
 * - Splits a consolidated node back into child nodes
 * - Preserves `isSharedDelivery` for child if it was originally shared
 */
export function splitConsolidatedNode(consolidatedNode, existingEdges) {
  console.log("Splitting consolidated node:", consolidatedNode.id);

  if (!consolidatedNode.data?.isConsolidated) {
    console.error("Invalid consolidated node (not actually consolidated).");
    return { childNodes: [], updatedEdges: existingEdges };
  }

  // 1) Rebuild child nodes
  const childNodes = consolidatedNode.data.details.childNodes.map((child) => {
    const wasShared =
      child.details?.isSharedDelivery === true ||
      child.details?.startLocationGroups; // or any other condition

    if (wasShared) {
      return {
        id: child.id,
        type: "locationNode",
        position: child.position || consolidatedNode.position || { x: 0, y: 0 },
        data: {
          type: "delivery",
          isSharedDelivery: true,
          isConsolidated: false,
          label: child.details?.label || "Shared Delivery Node",
          details: {
            ...child.details,
            // Keep track that we split out
            isChildNode: true,
          },
        },
      };
    } else {
      // Single
      return {
        id: child.id,
        type: "locationNode",
        position: child.position || consolidatedNode.position || { x: 0, y: 0 },
        data: {
          type: "delivery",
          isConsolidated: false,
          label: child.details?.order
            ? `Delivery for ${
                child.details.order.recipient?.first_name || ""
              } ${child.details.order.recipient?.last_name || ""}`
            : "Delivery",
          details: {
            ...child.details,
            isChildNode: true,
          },
        },
      };
    }
  });

  // 2) Remove edges pointing to the old consolidated node
  const updatedEdges = existingEdges.filter(
    (edge) => edge.target !== consolidatedNode.id
  );

  // 3) For each “consolidated edge” that pointed to the consolidatedNode,
  //    create edges to the new child nodes. But only include the subset
  //    of orders that actually appear in cEdge.data.orders.
  const consolidatedEdges = existingEdges.filter(
    (edge) => edge.target === consolidatedNode.id
  );

  consolidatedEdges.forEach((cEdge) => {
    // The set of orders that “really traveled” on that consolidated edge
    const cEdgeOrders = new Set(cEdge.data?.orders || []);

    childNodes.forEach((child) => {
      // Identify the child's own orders
      let childOrderIds = [];

      // If the child was “shared delivery,” it probably has .details.orders (array)
      if (
        child.data?.isSharedDelivery &&
        Array.isArray(child.data.details?.orders)
      ) {
        childOrderIds = child.data.details.orders.map((o) =>
          String(o.shipping_order_id)
        );
      }
      // Otherwise, single delivery => we have a single order
      else if (child.data?.details?.order?.shipping_order_id) {
        childOrderIds = [String(child.data.details.order.shipping_order_id)];
      }

      // Intersect with cEdgeOrders so we only keep the orders actually on that parent edge
      const relevantOrders = childOrderIds.filter((oid) =>
        cEdgeOrders.has(oid)
      );

      // If there's no overlap, skip creating an edge
      if (!relevantOrders.length) {
        return;
      }

      // Otherwise, push a new edge from cEdge.source -> child.id with only these relevant orders
      updatedEdges.push({
        id: `${cEdge.source}-${child.id}-${Date.now()}`,
        source: cEdge.source,
        target: child.id,
        type: cEdge.type || "default",
        data: {
          orders: relevantOrders,
          courier: cEdge.data.courier,
          status: cEdge.data.status,
          isDeliveryEdge: true,
          isConsolidated: false,
          estimatedDuration: cEdge.data.estimatedDuration,
          isLastSegment: cEdge.data.isLastSegment,
        },
      });
    });
  });

  return {
    childNodes,
    updatedEdges,
  };
}
