import React from "react";
import { useNavigate } from "react-router-dom";
// Header and Footer are now provided by PublicLayout
import CustomButton from "../../components/Button/Button";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import "./HomePage.css";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="home" id="wrapper">
      <div id="hero-content" className="hero-content">
          <div className="hero-text">
            <h1>Welcome to Cexpress</h1>
            <p>
              Streamline your workflow, enhance productivity, and collaborate
              seamlessly.
            </p>
            <div className="auth-options">
              <CustomButton
                text="Log In"
                className="login-button"
                onClick={() => {
                  console.log("Log In clicked");
                  navigate("/auth", { state: { activeTab: "login" } });
                }}
              />
              <CustomButton
                text="Sign Up"
                className="signup-button"
                onClick={() => {
                  console.log("Sign Up clicked");
                  navigate("/auth", { state: { activeTab: "signup" } });
                }}
              />
            </div>
            <div className="forgot-password">
              <a
                onClick={() => {
                  console.log("Reset clicked");
                  navigate("/auth", { state: { activeTab: "reset" } });
                }}
              >
                Forgot Password?
              </a>
            </div>
          </div>
        </div>
    </div>
  );
};

export default HomePage;
