import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useParams, useLocation, Routes, Route } from "react-router-dom";
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useBusiness } from "../../../../components/Auth/BusinessContext";
import { AssetsProvider, useAssets } from "./AssetsContext";
import AssetsDashboardOverview from "./AssetsDashboardOverview";
import AssetsContent from "./AssetsContent";
import "./AssetsPage.css";

const AssetsPage = () => {
  const { businessId } = useParams();

  return (
    <AssetsProvider>
      <Routes>
        <Route path="/" element={<AssetsContent />} />
        {/* Add more routes as needed */}
      </Routes>
    </AssetsProvider>
  );
};

export default AssetsPage;
