import html2pdf from "html2pdf.js";

// Only patch the html2pdf Worker methods that need fixing
// Don't patch the global Array.prototype.forEach

// Specifically patch the hyperlinks.js component which is causing the issue
const originalToContainer = html2pdf.Worker.prototype.toContainer;
html2pdf.Worker.prototype.toContainer = function toContainer() {
  return originalToContainer.call(this).then(function toContainer_hyperlink() {
    // Safely handle hyperlink processing
    if (this.opt && this.opt.enableLinks) {
      // Find all anchor tags and get the container's bounds for reference.
      var container = this.prop.container;
      if (!container) return;

      var links = container.querySelectorAll("a");
      if (!links || links.length === 0) return;

      var containerRect = container.getBoundingClientRect();
      if (!containerRect) return;

      // Convert container rectangle
      var pageSize = this.prop.pageSize;
      if (!pageSize || !pageSize.k) return;

      try {
        // Only set linkInfo if we haven't already
        if (!this.linkInfo) this.linkInfo = [];

        // Safely process each link
        Array.from(links).forEach(function (link) {
          if (!link) return;

          try {
            // Treat each client rect as a separate link (for text-wrapping).
            var clientRects = link.getClientRects();
            if (!clientRects || clientRects.length === 0) return;

            for (var i = 0; i < clientRects.length; i++) {
              var rect = clientRects[i];
              if (!rect) continue;

              // Create a safe copy of the client rect
              var clientRect = {
                left: rect.left - containerRect.left,
                top: rect.top - containerRect.top,
                width: rect.width,
                height: rect.height,
              };

              var page =
                Math.floor(clientRect.top / this.prop.pageSize.inner.height) +
                1;
              var top =
                this.opt.margin[0] +
                (clientRect.top % this.prop.pageSize.inner.height);
              var left = this.opt.margin[1] + clientRect.left;

              this.linkInfo.push({
                page: page,
                top: top,
                left: left,
                clientRect: clientRect,
                link: link,
              });
            }
          } catch (err) {
            console.warn("Error processing link", err);
          }
        }, this);
      } catch (err) {
        console.warn("Error in hyperlinks module", err);
      }
    }
  });
};

// Also patch toPdf method to prevent issues with linkInfo
const originalToPdf = html2pdf.Worker.prototype.toPdf;
html2pdf.Worker.prototype.toPdf = function toPdf() {
  return originalToPdf.call(this).then(function toPdf_hyperlink() {
    // Add hyperlinks if the option is enabled.
    if (
      this.opt &&
      this.opt.enableLinks &&
      this.linkInfo &&
      Array.isArray(this.linkInfo)
    ) {
      try {
        // Safely attach each anchor tag
        this.linkInfo.forEach(function (l) {
          if (!l || !this.prop.pdf) return;

          this.prop.pdf.setPage(l.page);
          this.prop.pdf.link(
            l.left,
            l.top,
            l.clientRect.width,
            l.clientRect.height,
            {
              url: l.link.href,
            }
          );
        }, this);

        // Reset the active page of the PDF to the final page.
        if (this.prop.pdf) {
          var nPages = this.prop.pdf.internal.getNumberOfPages();
          this.prop.pdf.setPage(nPages);
        }
      } catch (err) {
        console.warn("Error adding hyperlinks to PDF", err);
      }
    }
  });
};

// A safer wrapper for html2pdf
const safeHtml2pdf = (element, options = {}) => {
  // Disable hyperlinks by default unless explicitly enabled
  const safeOptions = {
    ...options,
    enableLinks: options.enableLinks === true,
  };

  try {
    return html2pdf(element, safeOptions);
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw error;
  }
};

// Export the patched version
export default safeHtml2pdf;
