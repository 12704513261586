import React from "react";
import ScrollHeader from "../components/Header/ScrollHeader";
import ScrollFooter from "../components/Footer/ScrollFooter";
import "./styles/DownloadLayout.css";

/**
 * DownloadLayout - A specialized layout for the download page
 * Uses scroll-away header and footer
 */
const DownloadLayout = ({ children }) => {
  return (
    <div className="layout-container download-layout">
      <ScrollHeader />
      <main className="download-content">
        <div className="page-transition-wrapper content-centered">
          {children}
        </div>
      </main>
      <ScrollFooter />
    </div>
  );
};

export default DownloadLayout;