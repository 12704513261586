import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  applyNodeChanges,
  applyEdgeChanges,
  Panel,
} from "reactflow";
import "reactflow/dist/style.css";

import {
  Box,
  Alert,
  Snackbar,
  Typography,
  Button,
  Slider,
} from "@mui/material";

import debounce from "lodash.debounce";

import BatchLocationNode from "./BatchLocationNode";
import { LOCATION_THRESHOLD_METERS } from "./NodeConsolidation";
import { nodeTypes } from "./NodeTypes";
import FlowContext from "./FlowContext";
import { useFlowContext } from "./FlowContext";
import { formatNewEdge } from "./edgeUtils";

const BatchRouteMapManager = ({
  editMode = "edge",
  onEdgeCreate,
  onConsolidate,
  validateConnection,
}) => {
  // read from context
  const {
    flowData,
    selectedNode,
    setSelectedNode,
    selectedEdge,
    setSelectedEdge,
    orders,
    couriers,
    updateFlowData,
  } = useFlowContext();
  const { nodes, edges } = flowData;

  // Local UI state
  const [validationMessage, setValidationMessage] = useState(null);
  const [dragSourceId, setDragSourceId] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [activeNode, setActiveNode] = useState(null);
  const [localThreshold, setLocalThreshold] = useState(
    LOCATION_THRESHOLD_METERS
  );

  // We only call the parent on a small delay. The parent re-consolidates nodes/edges.
  const debouncedConsolidate = useCallback(
    debounce((value) => {
      onConsolidate?.(value);
    }, 300),
    [onConsolidate]
  );

  // Connection handlers
  const onConnectStart = useCallback((_, { nodeId }) => {
    setDragSourceId(nodeId);
  }, []);

  const onConnectEnd = useCallback(() => {
    setDragSourceId(null);
  }, []);

  const handleConnect = useCallback(
    (params) => {
      if (editMode !== "edge") return;

      const { source, target } = params;
      if (!source || !target) return;

      // const validation = validateConnection
      //   ? validateConnection(source, target, nodes, edges)
      //   : { valid: true };

      // if (!validation.valid) {
      //   setValidationMessage({
      //     message: validation.message || "Invalid connection",
      //     severity: "error",
      //   });
      //   return;
      // }

      onEdgeCreate?.(source, target);
    },
    [editMode, validateConnection, nodes, edges, onEdgeCreate]
  );

  // Click handlers
  const handleNodeClick = useCallback(
    (_, node) => {
      if (editMode === "move") {
        // maybe multiple selection if you want
      }
      setActiveNode(node);
      setSelectedNode(node); // update context
      setSelectedEdge(null); // clear edge
    },
    [editMode, setSelectedNode, setSelectedEdge]
  );

  const handleEdgeClick = useCallback(
    (evt, clickedEdge) => {
      // 1) Save this edge as selected
      setSelectedNode(null);
      setSelectedEdge(clickedEdge);

      // 2) Re-format all edges to highlight the new selection
      //    We'll re-run formatNewEdge on each edge using the new selected ID
      const reFormattedEdges = edges.map((edge) =>
        formatNewEdge(edge, clickedEdge.id, couriers)
      );

      // 3) Update the flow data in context
      updateFlowData(nodes, reFormattedEdges);
    },
    [edges, nodes, couriers, setSelectedEdge, setSelectedNode, updateFlowData]
  );

  // Threshold change handler
  const debouncedThresholdChange = useCallback(
    debounce((newThreshold) => {
      onConsolidate?.(newThreshold);
    }, 300),
    [onConsolidate]
  );

  useEffect(() => {
    return () => {
      debouncedThresholdChange.cancel();
    };
  }, [debouncedThresholdChange]);

  const handleThresholdChange = useCallback(
    (event, value) => {
      setLocalThreshold(value);
      debouncedConsolidate(value);
    },
    [debouncedConsolidate]
  );

  // Target validation
  const getValidTargetsForNode = useCallback(
    (sourceId) => {
      console.log("Getting valid targets for source:", sourceId);
      if (!sourceId || !nodes) return new Set();

      const sourceNode = nodes.find((n) => n.id === sourceId);
      console.log("Source node:", sourceNode);

      if (!sourceNode || sourceNode.data.type === "delivery") {
        console.log("Invalid source node or is delivery type");
        return new Set();
      }

      // Get available orders at this source node
      const availableOrders = new Set();
      orders.forEach((order) => {
        // Orders that start here
        if (order.start_location_id === sourceId) {
          if (
            !edges.some(
              (e) =>
                e.source === sourceId &&
                e.data.orders?.includes(order.shipping_order_id)
            )
          ) {
            console.log(
              "Adding start order to available:",
              order.shipping_order_id
            );
            availableOrders.add(order.shipping_order_id);
          }
        }

        // Orders passing through
        const hasIncoming = edges.some(
          (e) =>
            e.target === sourceId &&
            e.data.orders?.includes(order.shipping_order_id)
        );
        const hasOutgoing = edges.some(
          (e) =>
            e.source === sourceId &&
            e.data.orders?.includes(order.shipping_order_id)
        );

        if (hasIncoming && !hasOutgoing) {
          console.log(
            "Adding passing through order to available:",
            order.shipping_order_id
          );
          availableOrders.add(order.shipping_order_id);
        }
      });

      console.log("Available orders at source:", Array.from(availableOrders));

      const validTargets = new Set(
        nodes
          .filter((node) => {
            console.log("Checking node:", node.id, node.data);

            // Allow connections to non-delivery nodes
            if (node.data.type !== "delivery") {
              console.log("Non-delivery node, valid target:", node.id);
              return true;
            }

            // Handle consolidated nodes
            if (node.data.isConsolidated) {
              console.log("Checking consolidated node:", node.id);
              return node.data.details.childNodes.some((childNode) => {
                // Check if child is a shared delivery node
                if (childNode.details?.isSharedDelivery) {
                  return childNode.details.startLocationGroups.some((group) => {
                    return (
                      group.startLocationId === sourceId &&
                      group.orders.some((order) => {
                        const isAvailable = availableOrders.has(
                          order.shipping_order_id
                        );
                        const isNotRouted = !edges.some(
                          (e) =>
                            e.target === node.id &&
                            e.data.orders?.includes(order.shipping_order_id)
                        );
                        console.log("Consolidated shared node order check:", {
                          orderId: order.shipping_order_id,
                          isAvailable,
                          isNotRouted,
                        });
                        return isAvailable && isNotRouted;
                      })
                    );
                  });
                }

                // Check if child is a regular delivery node
                const childOrder = childNode.details?.order?.shipping_order_id;
                const isAvailable =
                  childOrder && availableOrders.has(childOrder);
                const isNotRouted = !edges.some(
                  (e) =>
                    e.target === node.id && e.data.orders?.includes(childOrder)
                );

                console.log("Consolidated regular node check:", {
                  childNodeId: childNode.id,
                  childOrder,
                  isAvailable,
                  isNotRouted,
                });

                return isAvailable && isNotRouted;
              });
            }

            // Handle shared delivery nodes
            if (node.data.isSharedDelivery) {
              console.log("Checking shared delivery node:", node.id);
              return node.data.details.startLocationGroups.some((group) => {
                return (
                  group.startLocationId === sourceId &&
                  group.orders.some((order) => {
                    const isAvailable = availableOrders.has(
                      order.shipping_order_id
                    );
                    const isNotRouted = !edges.some(
                      (e) =>
                        e.target === node.id &&
                        e.data.orders?.includes(order.shipping_order_id)
                    );
                    console.log("Order check:", {
                      orderId: order.shipping_order_id,
                      isAvailable,
                      isNotRouted,
                    });
                    return isAvailable && isNotRouted;
                  })
                );
              });
            }

            // For regular delivery nodes
            const nodeOrder = node.data.details?.order?.shipping_order_id;
            const isValid =
              nodeOrder &&
              availableOrders.has(nodeOrder) &&
              !edges.some(
                (e) =>
                  e.target === node.id && e.data.orders?.includes(nodeOrder)
              );

            console.log("Regular delivery node valid:", isValid);
            return isValid;
          })
          .map((n) => n.id)
      );

      console.log("Final valid targets:", Array.from(validTargets));
      return validTargets;
    },
    [nodes, edges, orders]
  );

  const contextValue = useMemo(
    () => ({
      edges: edges,
      orders,
      dragSourceId,
      getValidTargetsForNode,
    }),
    [edges, orders, dragSourceId, getValidTargetsForNode]
  );

  // Clean up the debounce on unmount
  useEffect(() => {
    return () => {
      debouncedConsolidate.cancel();
    };
  }, [debouncedConsolidate]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        border: "1px solid #ddd",
        borderRadius: 1,
        contain: "paint size layout",
      }}
    >
      <FlowContext.Provider value={contextValue}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onConnect={handleConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onNodeClick={handleNodeClick}
            onEdgeClick={handleEdgeClick}
            nodeTypes={nodeTypes}
            fitView
            fitViewOptions={{ padding: 0.2 }}
            defaultEdgeOptions={{
              type: "default",
              animated: true,
              style: { stroke: "#999", strokeWidth: 2 },
              markerEnd: { type: "arrowclosed" },
            }}
            connectionMode="loose"
            snapToGrid={false}
            nodesDraggable={editMode === "move"}
            nodesConnectable={editMode === "edge"}
            selectNodesOnDrag={false}
            minZoom={0.1}
            maxZoom={2}
            edgeUpdaterRadius={50} // Add this to control edge update radius
            connectionRadius={20} // Add this to control connection radius
          >
            <Background color="#aaa" gap={16} />
            <Controls />

            <Panel position="top-left">
              <Box
                sx={{
                  bgcolor: "background.paper",
                  p: 1.5,
                  borderRadius: 1,
                  boxShadow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body2">
                  <strong>Mode:</strong>{" "}
                  {editMode === "edge"
                    ? "Create connections by dragging between nodes"
                    : "View and move nodes"}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" gutterBottom>
                    Grouping Distance Threshold:{" "}
                    {(localThreshold / 1000).toFixed(1)} km
                  </Typography>
                  <Slider
                    value={localThreshold}
                    onChange={handleThresholdChange}
                    min={1000}
                    max={1000000}
                    step={100}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(val) => `${(val / 1000).toFixed(1)} km`}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Box>
            </Panel>
          </ReactFlow>
        </ReactFlowProvider>
      </FlowContext.Provider>

      <Snackbar
        open={!!validationMessage}
        autoHideDuration={3000}
        onClose={() => setValidationMessage(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {validationMessage && (
          <Alert
            severity={validationMessage.severity}
            onClose={() => setValidationMessage(null)}
          >
            {validationMessage.message}
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
};

export default BatchRouteMapManager;
