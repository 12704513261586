import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Chip,
  TextField,
  IconButton,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Alert,
  Menu,
  ListItemIcon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  Pagination,
  InputAdornment,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  PersonAdd as PersonAddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  Call as CallIcon,
  Info as InfoIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  LocationOn as LocationIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { AuthContext } from "../Auth/AuthContext";
import { useEmployeePermissions } from "../Auth/EmployeePermissions";
import ManageAddressesDialog from "../../pages/business/customers/ManageAddressesDialog";
import styles from "./UnifiedCustomerManager.module.css";

// Generate random avatar colors
const getAvatarColor = (name) => {
  const colors = [
    "#1976d2",
    "#388e3c",
    "#f57c00",
    "#d32f2f",
    "#7b1fa2",
    "#c2185b",
    "#0097a7",
    "#fbc02d",
  ];
  const charCode = name?.charCodeAt(0) || 0;
  return colors[charCode % colors.length];
};

// Format date for display
const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const UnifiedCustomerManager = ({
  businessId,
  isEmployeePortal = false,
  onAddCustomerButtonClick = null,
  refreshTrigger = 0,
  hideAddButton = false,
}) => {
  const { api } = useContext(AuthContext);
  const navigate = useNavigate();

  // State
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [tabValue, setTabValue] = useState(0);

  // Dialog states
  const [isAddCustomerDialogOpen, setIsAddCustomerDialogOpen] = useState(false);
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);
  const [deleteCustomer, setDeleteCustomer] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Alert messages
  const [alertMessage, setAlertMessage] = useState(null);
  const [newCustomerAlertMessage, setNewCustomerAlertMessage] = useState(null);
  const [existingCustomerAlertMessage, setExistingCustomerAlertMessage] =
    useState(null);

  // Customer action menu state
  const [anchorEl, setAnchorEl] = useState(null);

  // Form states
  const [newCustomer, setNewCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({});

  // Call hooks unconditionally
  const employeePermissions = useEmployeePermissions();

  // Get permissions based on whether it's employee portal
  const permissions = isEmployeePortal
    ? employeePermissions
    : {
        canView: true,
        canEdit: true,
        canDelete: true,
        canManageCustomers: true,
      };

  // Determine which API endpoint to use
  const apiEndpoint = isEmployeePortal
    ? `/employee-portal/customers/${businessId}`
    : "/customers";

  // Define fetchData function that works for both business and employee views
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (debouncedSearchQuery !== searchQuery) {
        setIsSearching(true);
      }

      // Determine status filter based on tab
      let status = "";
      if (tabValue === 1) status = "active";
      if (tabValue === 2) status = "inactive";

      // Common parameters
      const params = {
        page: page + 1, // API uses 1-based indexing
        limit: rowsPerPage,
        status: status || undefined,
        // We'll handle sorting client-side, so no need to pass these parameters
        // sort: orderBy,
        // order: order,
      };

      // Only add search param if there's an actual query
      if (debouncedSearchQuery && debouncedSearchQuery.trim() !== "") {
        params.search = debouncedSearchQuery.trim();
      }

      // Add business_id param if in business portal
      if (!isEmployeePortal) {
        params.business_id = businessId;
      }

      // Fetch customers
      console.log("Fetching customers with params:", params);
      const response = await api.get(apiEndpoint, { params });

      // Handle potentially different response structures
      let customersData = [];
      let totalCount = 0;

      if (response.data) {
        if (response.data.customers) {
          customersData = response.data.customers;
          totalCount = response.data.total || customersData.length;
        } else if (Array.isArray(response.data)) {
          customersData = response.data;
          totalCount = customersData.length;
        }
      }

      setCustomers(customersData);
      setTotalCustomers(totalCount);
      setError(null);
    } catch (err) {
      console.error("Error fetching customers data:", err);
      setError(err.response?.data?.error || "Failed to load customers");
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  }, [
    api,
    apiEndpoint,
    businessId,
    isEmployeePortal,
    page,
    rowsPerPage,
    tabValue,
  ]);

  // Debounce search query for API requests
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500); // 500ms delay

    return () => {
      clearTimeout(timerId);
    };
  }, [searchQuery]);

  // When debounced search query changes, fetch data
  useEffect(() => {
    if (businessId) {
      fetchData();
    }
  }, [businessId, fetchData]);

  // Action menu handlers
  const handleMenuOpen = (event, customer) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCustomer(customer);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCustomer(null);
  };

  // Validate customer fields
  const validateFields = (customer) => {
    const errors = {};
    if (!customer.first_name?.trim())
      errors.first_name = "First Name is required";
    if (!customer.last_name?.trim()) errors.last_name = "Last Name is required";
    if (!customer.email && !customer.phone) {
      errors.email = "Either email or phone is required";
      errors.phone = "Either email or phone is required";
    }
    if (
      customer.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customer.email)
    ) {
      errors.email = "Email is not valid";
    }
    return errors;
  };

  // Customer action handlers
  const handleEditCustomer = (customer) => {
    if (isEmployeePortal) {
      handleMenuClose();
      navigate(
        `/employee/${businessId}/customers/${customer.customer_id}/edit`
      );
    } else {
      setEditCustomer(customer);
      setIsDialogOpen(true);
    }
  };

  const handleDeleteCustomer = (customer) => {
    if (isEmployeePortal) {
      handleMenuClose();
    }
    setDeleteCustomer(customer);
    setIsDialogOpen(true);
  };

  const handleViewCustomer = (customer) => {
    handleMenuClose();
    const basePath = isEmployeePortal
      ? `/employee/${businessId}/customers/`
      : `/business/${businessId}/customers/`;
    navigate(`${basePath}${customer.customer_id}`);
  };

  const handleEmailCustomer = (customer) => {
    handleMenuClose();
    window.open(`mailto:${customer.customer_email}`);
  };

  const handleCallCustomer = (customer) => {
    handleMenuClose();
    window.open(`tel:${customer.customer_phone}`);
  };

  const handleManageAddresses = (customer, event) => {
    if (event) event.stopPropagation();
    setSelectedCustomer(customer);
    setIsAddressDialogOpen(true);
  };

  // Common handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    setPage(0);
    // We're handling this client-side now, so we don't need to rely on debounced query
    // for client-side filtering
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    // No need to set page to 0 or trigger a refetch since we'll sort client-side
  };

  const handleTabChange = (event) => {
    setTabValue(event.target.value);
    setPage(0);
  };

  const handleCustomerClick = (customerId) => {
    const basePath = isEmployeePortal
      ? `/employee/${businessId}/customers/`
      : `/business/${businessId}/customers/`;
    navigate(`${basePath}${customerId}`);
  };

  const handleAddCustomer = () => {
    if (onAddCustomerButtonClick) {
      onAddCustomerButtonClick();
    } else {
      setIsAddCustomerDialogOpen(true);
    }
  };

  // Dialog handlers
  const handleCloseDialog = () => {
    setExistingCustomerAlertMessage(null);
    setNewCustomerAlertMessage(null);
    setIsDialogOpen(false);
    setEditCustomer(null);
    setDeleteCustomer(null);
  };

  const handleCloseAddCustomerDialog = () => {
    setNewCustomerAlertMessage(null);
    setIsAddCustomerDialogOpen(false);
    setNewCustomer({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    });
    setFormErrors({});
  };

  // CRUD operations
  const handleSaveCustomer = async () => {
    setExistingCustomerAlertMessage(null);
    const errors = validateFields(editCustomer);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.put("/customers/update", {
        business_id: businessId,
        customer_id: editCustomer.customer_id,
        ...editCustomer,
      });
      setAlertMessage({
        type: "success",
        message: "Customer updated successfully.",
      });
      fetchData();
      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setExistingCustomerAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to update customer. Please try again.",
        });
      }
    }
  };

  const handleSaveNewCustomer = async () => {
    const errors = validateFields(newCustomer);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await api.post("/customers/add", {
        business_id: businessId,
        ...newCustomer,
      });
      setAlertMessage({
        type: "success",
        message: "Customer added successfully.",
      });
      fetchData();
      handleCloseAddCustomerDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setNewCustomerAlertMessage({
          type: "error",
          message: error.response.data.msg,
        });
      } else {
        setAlertMessage({
          type: "error",
          message: "Failed to add customer. Please try again.",
        });
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete("/customers/delete", {
        params: {
          business_id: businessId,
          customer_id: deleteCustomer.customer_id,
        },
      });
      setAlertMessage({
        type: "success",
        message: "Customer deleted successfully.",
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting customer:", error);
      setAlertMessage({
        type: "error",
        message: "Failed to delete customer. Please try again.",
      });
    }
    handleCloseDialog();
  };

  const handleFieldChange = (field, value, setCustomer) => {
    setCustomer((prev) => ({
      ...prev,
      [field]: value,
    }));
    setFormErrors((prev) => ({ ...prev, [field]: "" }));
  };

  // Prepare data for display
  const adaptCustomerData = (customer) => {
    // Handle different data structures between business and employee API endpoints
    const customerData = {
      customer_id: customer.customer_id,
      first_name: customer.first_name || customer.first_name || "",
      last_name: customer.last_name || customer.last_name || "",
      email: customer.email || customer.customer_email || "",
      phone: customer.phone || customer.customer_phone || "",
      address: customer.address || customer.customer_address || "",
      status: customer.status || customer.customer_status || "new",
      order_count: customer.order_count || 0,
      createdAt:
        customer.createdAt || customer.created_at || new Date().toISOString(),
      addresses: customer.Addresses || [],
    };

    return customerData;
  };

  // Render table headers
  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableSortLabel
            active={orderBy === "first_name" || orderBy === "first_name"}
            direction={
              orderBy === "first_name" || orderBy === "first_name"
                ? order
                : "asc"
            }
            onClick={() =>
              handleRequestSort(isEmployeePortal ? "first_name" : "first_name")
            }
          >
            Name
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === "email" || orderBy === "customer_email"}
            direction={
              orderBy === "email" || orderBy === "customer_email"
                ? order
                : "asc"
            }
            onClick={() =>
              handleRequestSort(isEmployeePortal ? "customer_email" : "email")
            }
          >
            Email
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === "phone" || orderBy === "customer_phone"}
            direction={
              orderBy === "phone" || orderBy === "customer_phone"
                ? order
                : "asc"
            }
            onClick={() =>
              handleRequestSort(isEmployeePortal ? "customer_phone" : "phone")
            }
          >
            Phone
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === "address" || orderBy === "customer_address"}
            direction={
              orderBy === "address" || orderBy === "customer_address"
                ? order
                : "asc"
            }
            onClick={() =>
              handleRequestSort(
                isEmployeePortal ? "customer_address" : "address"
              )
            }
          >
            Address
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === "createdAt"}
            direction={orderBy === "createdAt" ? order : "asc"}
            onClick={() => handleRequestSort("createdAt")}
          >
            Joined
          </TableSortLabel>
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            active={orderBy === "order_count"}
            direction={orderBy === "order_count" ? order : "asc"}
            onClick={() => handleRequestSort("order_count")}
          >
            Orders
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === "status" || orderBy === "customer_status"}
            direction={
              orderBy === "status" || orderBy === "customer_status"
                ? order
                : "asc"
            }
            onClick={() =>
              handleRequestSort(isEmployeePortal ? "customer_status" : "status")
            }
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );

  // Filter, sort, and paginate customers
  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) => {
      if (!searchQuery) return true;

      const query = searchQuery.toLowerCase();
      const fields = [
        customer.first_name || customer.customer_first_name || "",
        customer.last_name || customer.customer_last_name || "",
        customer.email || customer.customer_email || "",
        customer.phone || customer.customer_phone || "",
        customer.address || customer.customer_address || "",
      ];

      return fields.some((field) =>
        String(field).toLowerCase().includes(query)
      );
    });
  }, [customers, searchQuery]);

  // Client-side sorting
  const sortedCustomers = useMemo(() => {
    return [...filteredCustomers].sort((a, b) => {
      // Handle different field names between employee and customer APIs
      let fieldA, fieldB;

      if (orderBy === "first_name" || orderBy === "first_name") {
        fieldA = a.first_name || a.first_name || "";
        fieldB = b.first_name || b.first_name || "";
      } else if (orderBy === "last_name" || orderBy === "last_name") {
        fieldA = a.last_name || a.last_name || "";
        fieldB = b.last_name || b.last_name || "";
      } else if (orderBy === "email" || orderBy === "customer_email") {
        fieldA = a.email || a.customer_email || "";
        fieldB = b.email || b.customer_email || "";
      } else if (orderBy === "phone" || orderBy === "customer_phone") {
        fieldA = a.phone || a.customer_phone || "";
        fieldB = b.phone || b.customer_phone || "";
      } else if (orderBy === "status" || orderBy === "customer_status") {
        fieldA = a.status || a.customer_status || "";
        fieldB = b.status || b.customer_status || "";
      } else if (orderBy === "address" || orderBy === "customer_address") {
        fieldA = a.address || a.customer_address || "";
        fieldB = b.address || b.customer_address || "";
      } else {
        fieldA = a[orderBy] || "";
        fieldB = b[orderBy] || "";
      }

      if (fieldA < fieldB) return order === "asc" ? -1 : 1;
      if (fieldA > fieldB) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredCustomers, orderBy, order]);

  // Paginate sorted customers
  const paginatedCustomers = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedCustomers.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedCustomers, page, rowsPerPage]);

  // Render table rows
  const renderTableBody = () => (
    <TableBody>
      {paginatedCustomers.map((originalCustomer) => {
        const customer = adaptCustomerData(originalCustomer);
        return (
          <TableRow
            key={customer.customer_id}
            hover
            onClick={() => handleCustomerClick(customer.customer_id)}
            sx={{ cursor: "pointer" }}
          >
            <TableCell>
              <Box display="flex" alignItems="center">
                <Avatar
                  sx={{
                    bgcolor: getAvatarColor(
                      `${customer.first_name} ${customer.last_name}`
                    ),
                    mr: 1,
                    width: 32,
                    height: 32,
                    fontSize: "0.875rem",
                  }}
                >
                  {customer.first_name?.charAt(0) || ""}
                  {customer.last_name?.charAt(0) || ""}
                </Avatar>
                <Typography variant="body2">
                  {customer.first_name} {customer.last_name}
                </Typography>
              </Box>
            </TableCell>
            <TableCell>{customer.email || "N/A"}</TableCell>
            <TableCell>{customer.phone || "N/A"}</TableCell>
            <TableCell>
              {customer.addresses && customer.addresses.length > 0 ? (
                <Button
                  variant="text"
                  startIcon={<LocationIcon />}
                  onClick={(e) => handleManageAddresses(originalCustomer, e)}
                  size="small"
                >
                  Addresses ({customer.addresses.length})
                </Button>
              ) : (
                customer.address?.substring(0, 20) || "N/A"
              )}
            </TableCell>
            <TableCell>{formatDate(customer.createdAt)}</TableCell>
            <TableCell align="right">{customer.order_count || 0}</TableCell>
            <TableCell>
              <Chip
                label={customer.status || "New"}
                color={customer.status === "active" ? "success" : "default"}
                size="small"
                sx={{ textTransform: "capitalize" }}
              />
            </TableCell>
            <TableCell onClick={(e) => e.stopPropagation()}>
              {isEmployeePortal ? (
                <IconButton
                  size="small"
                  onClick={(e) => handleMenuOpen(e, originalCustomer)}
                  className={styles["icon-button"]}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              ) : (
                <Box>
                  <Tooltip title="Edit" className={styles["icon-button"]}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditCustomer(originalCustomer);
                      }}
                      className={styles["icon-button"]}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Delete"
                    className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteCustomer(originalCustomer);
                      }}
                      className={`${styles["icon-button"]} ${styles["cancel-button"]}`}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </TableCell>
          </TableRow>
        );
      })}
      {customers.length === 0 && !loading && (
        <TableRow>
          <TableCell colSpan={8} align="center" sx={{ py: 3 }}>
            <Typography variant="body1" color="text.secondary">
              No customers found
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );

  // If employee doesn't have permission to view customers
  if (isEmployeePortal && !permissions.canManageCustomers) {
    return (
      <Alert severity="warning">
        You don't have permission to access customer management.
      </Alert>
    );
  }

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          variant="outlined"
          sx={{ mb: 2 }}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage.message}
        </Alert>
      )}

      {/* Filters and controls */}
      {/* Only show Add Customer button if not in employee portal or if no external handler is provided */}
      {(!isEmployeePortal || !onAddCustomerButtonClick) && (
        <Button
          variant="outlined"
          startIcon={<PersonAddIcon />}
          onClick={handleAddCustomer}
          sx={{
            display: "block",
            mt: 4,
            mb: 4,
            color: "midnightblue",
            borderColor: "midnightblue",
            "&:hover": {
              color: "var(--soft-blue)",
              borderColor: "var(--soft-blue)",
            },
            cursor: "pointer",
          }}
        >
          Add Customer
        </Button>
      )}

      <div className={styles["filter-search"]}>
        <TextField
          label="Search customers by name, email, or phone"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          className={styles["search-field"]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isSearching ? (
                  <CircularProgress size={20} />
                ) : searchQuery ? (
                  <IconButton
                    aria-label="clear search"
                    onClick={handleClearSearch}
                    size="small"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />

        <FormControl className={styles["filter-control"]}>
          <InputLabel>Status</InputLabel>
          <Select value={tabValue} onChange={handleTabChange} label="Status">
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={2}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={styles["items-per-page"]}>
          <InputLabel id="items-per-page-label">Items Per Page</InputLabel>
          <Select
            labelId="items-per-page-label"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            label="Items Per Page"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* Tabs removed as they are now handled by the Status filter dropdown */}

      {/* Customer list */}
      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          width: "90%",
        }}
      >
        <Table size="medium">
          {renderTableHeader()}
          {renderTableBody()}
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(sortedCustomers.length / rowsPerPage)}
        page={page + 1}
        onChange={(e, value) => handleChangePage(e, value - 1)}
        className={styles.pagination}
      />

      {/* Customer Action Menu (for employee portal) */}
      {isEmployeePortal && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() =>
              selectedCustomer && handleViewCustomer(selectedCustomer)
            }
          >
            <ListItemIcon>
              <InfoIcon fontSize="small" />
            </ListItemIcon>
            View Details
          </MenuItem>
          {permissions.canEdit && (
            <MenuItem
              onClick={() =>
                selectedCustomer && handleEditCustomer(selectedCustomer)
              }
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              Edit Customer
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              selectedCustomer && handleEmailCustomer(selectedCustomer)
            }
          >
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            Send Email
          </MenuItem>
          {selectedCustomer && selectedCustomer.phone && (
            <MenuItem
              onClick={() =>
                selectedCustomer && handleCallCustomer(selectedCustomer)
              }
            >
              <ListItemIcon>
                <CallIcon fontSize="small" />
              </ListItemIcon>
              Call Customer
            </MenuItem>
          )}
          {permissions.canDelete && (
            <MenuItem
              onClick={() => {
                handleDeleteCustomer(selectedCustomer);
              }}
              sx={{ color: "error.main" }}
            >
              <ListItemIcon sx={{ color: "error.main" }}>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              Delete Customer
            </MenuItem>
          )}
        </Menu>
      )}

      {/* Edit/Delete Customer Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        {editCustomer && (
          <>
            <DialogTitle>Edit Customer</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="First Name"
                    fullWidth
                    required
                    value={editCustomer.first_name}
                    error={!!formErrors.first_name}
                    helperText={formErrors.first_name}
                    onChange={(e) =>
                      handleFieldChange(
                        "first_name",
                        e.target.value,
                        setEditCustomer
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Last Name"
                    fullWidth
                    required
                    value={editCustomer.last_name}
                    error={!!formErrors.last_name}
                    helperText={formErrors.last_name}
                    onChange={(e) =>
                      handleFieldChange(
                        "last_name",
                        e.target.value,
                        setEditCustomer
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    value={editCustomer.email}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    onChange={(e) =>
                      handleFieldChange(
                        "email",
                        e.target.value,
                        setEditCustomer
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiTelInput
                    margin="dense"
                    label="Phone"
                    fullWidth
                    value={editCustomer.phone}
                    error={!!formErrors.phone}
                    helperText={formErrors.phone}
                    onChange={(value) =>
                      handleFieldChange("phone", value, setEditCustomer)
                    }
                  />
                </Grid>
              </Grid>
              {existingCustomerAlertMessage && (
                <Alert
                  severity={existingCustomerAlertMessage.type}
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClose={() => setExistingCustomerAlertMessage(null)}
                >
                  {existingCustomerAlertMessage.message}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSaveCustomer} startIcon={<SaveIcon />}>
                Save
              </Button>
              <Button onClick={handleCloseDialog} startIcon={<CancelIcon />}>
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
        {deleteCustomer && (
          <>
            <DialogTitle>Delete Customer</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete{" "}
                {deleteCustomer.first_name || deleteCustomer.first_name}{" "}
                {deleteCustomer.last_name || deleteCustomer.last_name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmDelete}
                color="error"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
              <Button onClick={handleCloseDialog} startIcon={<CancelIcon />}>
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Add New Customer Dialog */}
      <Dialog
        open={isAddCustomerDialogOpen}
        onClose={handleCloseAddCustomerDialog}
      >
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={newCustomer.first_name}
                error={!!formErrors.first_name}
                helperText={formErrors.first_name}
                onChange={(e) =>
                  handleFieldChange(
                    "first_name",
                    e.target.value,
                    setNewCustomer
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={newCustomer.last_name}
                error={!!formErrors.last_name}
                helperText={formErrors.last_name}
                onChange={(e) =>
                  handleFieldChange("last_name", e.target.value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={newCustomer.email}
                error={!!formErrors.email}
                helperText={formErrors.email}
                onChange={(e) =>
                  handleFieldChange("email", e.target.value, setNewCustomer)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                margin="dense"
                label="Phone"
                fullWidth
                value={newCustomer.phone}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
                onChange={(value) =>
                  handleFieldChange("phone", value, setNewCustomer)
                }
              />
            </Grid>
          </Grid>
          {newCustomerAlertMessage && (
            <Alert
              severity={newCustomerAlertMessage.type}
              variant="outlined"
              sx={{ mt: 2 }}
              onClose={() => setNewCustomerAlertMessage(null)}
            >
              {newCustomerAlertMessage.message}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveNewCustomer} startIcon={<SaveIcon />}>
            Save
          </Button>
          <Button
            onClick={handleCloseAddCustomerDialog}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Customer Address Dialog */}
      {selectedCustomer && (
        <ManageAddressesDialog
          open={isAddressDialogOpen}
          onClose={() => {
            setIsAddressDialogOpen(false);
            fetchData(); // Refresh customer data after managing addresses
          }}
          customerId={selectedCustomer.customer_id}
          businessId={businessId}
        />
      )}
    </>
  );
};

export default UnifiedCustomerManager;
