import React, { useState } from "react";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Visibility as PreviewIcon } from "@mui/icons-material";
import { Print as PrintIcon } from "@mui/icons-material";
import { QRCodeSVG } from "qrcode.react";
import { useParams } from "react-router-dom";
import html2pdf from "../../../../utils/patchedHtml2pdf";
import qz from "qz-tray"; // Ensure qz-tray is installed and configured
import {
  getCategorySettings,
  updatePrintSettings,
  savePrintSettings,
} from "./PrintSettings";

const formatDimensions = (dimensions) => {
  if (!dimensions) return "N/A";
  return `${dimensions.length} x ${dimensions.width} x ${dimensions.height}`;
};

const formatWeight = (weight) => {
  return weight ? `${parseFloat(weight).toFixed(2)} lbs` : "N/A";
};

const PackagePreviewButton = ({ pkg, order }) => {
  const [open, setOpen] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { businessId } = useParams();

  // Load shipping label settings from localStorage or use defaults.

  const [labelSettings, setLabelSettings] = useState(() =>
    getCategorySettings(businessId, "shippingLabels")
  );

  const handlePrintError = (error, businessId) => {
    if (error.message.includes("QZ Tray")) {
      return "Error connecting to printer. Please ensure QZ Tray is installed and running.";
    }
    if (error.message.includes("settings")) {
      return `Printer settings not found. Please configure your settings at /business/${businessId}/settings/printing-settings`;
    }
    return error.message;
  };

  const handleLabelSettingsFieldChange = (field, value) => {
    const updated = updatePrintSettings(businessId, "shippingLabels", {
      ...labelSettings,
      [field]: value,
    });
    setLabelSettings(updated.shippingLabels);
  };

  const handleLabelFontSizeChange = (fontType, value) => {
    const updated = updatePrintSettings(businessId, "shippingLabels", {
      ...labelSettings,
      fontSizes: {
        ...labelSettings.fontSizes,
        [fontType]: Number(value),
      },
    });
    setLabelSettings(updated.shippingLabels);
  };

  const onSaveLabelSettings = () => {
    try {
      const updated = savePrintSettings(
        businessId,
        "shippingLabels",
        labelSettings
      );
      setLabelSettings(updated.shippingLabels);
    } catch (error) {
      setError("Failed to save label settings");
      setShowSnackbar(true);
    }
  };

  // Format sender and recipient information from order.
  const formatSenderInfo = () => {
    const sender = order?.sender;
    const senderAddress = sender?.Addresses?.[0];
    return {
      name: sender ? `${sender.first_name} ${sender.last_name}` : "",
      address: senderAddress?.formatted_address || "",
      phone: sender?.phone || "",
    };
  };

  const formatRecipientInfo = () => {
    const recipient = order?.recipient;
    const recipientAddress = recipient?.Addresses?.[0];
    return {
      name: recipient ? `${recipient.first_name} ${recipient.last_name}` : "",
      address: recipientAddress?.formatted_address || "",
      phone: recipient?.phone || "",
    };
  };

  const senderInfo = formatSenderInfo();
  const recipientInfo = formatRecipientInfo();

  // LabelPreview uses the current labelSettings for dimensions, fonts, orientation, etc.
  const LabelPreview = () => {
    const getDimensions = () => {
      const [width, height] = labelSettings.size.split("x").map(Number);
      const DPI = 96;
      return {
        width: width * DPI,
        height: height * DPI,
      };
    };

    const dimensions = getDimensions();
    const maxPreviewWidth = 600;
    const scale =
      dimensions.width > maxPreviewWidth
        ? maxPreviewWidth / dimensions.width
        : 1;

    return (
      <Box
        id="printable-label"
        sx={{
          border: "1px solid black",
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          backgroundColor: "white",
          color: "black",
          transform: `${
            labelSettings.orientation === "landscape" ? "rotate(90deg)" : "none"
          } scale(${scale})`,
          transformOrigin: "center center",
          overflow: "visible",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Box sx={{ px: 2 }}>
          {" "}
          {/* Add padding container for content */}
          {/* Header */}
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mt: 1,
              mb: 1,
              color: "black",
            }}
          >
            CEXPRESS
          </Typography>
          {/* Current Date */}
          <Typography
            variant="body2"
            sx={{ textAlign: "center", mb: 2, color: "black" }}
          >
            Date: {new Date().toLocaleDateString()}
          </Typography>
          {/* Sender and Recipient (Side-by-Side) */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                SHIP FROM
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  color: "black",
                }}
              >
                {senderInfo.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  color: "black",
                }}
              >
                {senderInfo.address}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  color: "black",
                }}
              >
                {senderInfo.phone}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                SHIP TO
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  color: "black",
                }}
              >
                {recipientInfo.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  color: "black",
                }}
              >
                {recipientInfo.address}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  color: "black",
                }}
              >
                {recipientInfo.phone}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, borderColor: "black" }} />
          {/* Shipment Details */}
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontSize: labelSettings.fontSizes.details, color: "black" }}
            >
              Shipment Method: {pkg.shipment_method || "Standard"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: labelSettings.fontSizes.details, color: "black" }}
            >
              Package Weight: {formatWeight(pkg.weight)}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: labelSettings.fontSizes.details, color: "black" }}
            >
              Dimensions: {formatDimensions(pkg.dimensions)}
            </Typography>
            <Box sx={{ mt: 1 }}>
              {pkg.fragile && (
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: labelSettings.fontSizes.details,
                    color: "red",
                  }}
                >
                  Handle with Care: Fragile
                </Typography>
              )}
              {pkg.hazardous && (
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: labelSettings.fontSizes.details,
                    color: "red",
                  }}
                >
                  Hazardous Materials
                </Typography>
              )}
              {pkg.temperature_control && (
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: labelSettings.fontSizes.details,
                    color: "red",
                  }}
                >
                  Temperature Controlled
                </Typography>
              )}
            </Box>
          </Box>
          <Divider sx={{ my: 2, borderColor: "black" }} />
          {/* QR Code & Tracking Data */}
          <Box sx={{ textAlign: "center", mt: 2, mb: 2 }}>
            <QRCodeSVG
              value={pkg.package_id || pkg.id}
              size={labelSettings.qrCodeSize}
              bgColor="#ffffff"
              fgColor="#000000"
              level="Q"
              includeMargin={false}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: labelSettings.fontSizes.details,
                mt: 1,
                color: "black",
              }}
            >
              Package ID: {pkg.package_id || pkg.id}
            </Typography>
            {pkg.tracking_number && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: labelSettings.fontSizes.details,
                  mt: 0.5,
                  color: "black",
                }}
              >
                Tracking Number: {pkg.tracking_number}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const getPrinterSettings = async () => {
    try {
      const printers = localStorage.getItem(`printers_${businessId}`);
      if (!printers) {
        throw new Error("No printer settings found.");
      }
      return {
        printer: JSON.parse(printers).shippingLabels,
        settings: labelSettings, // Use context settings directly
      };
    } catch (err) {
      console.error("Error getting printer settings:", err);
      throw new Error("Failed to get printer settings.");
    }
  };

  // Print handler: generates a PDF via html2pdf, opens a print window (using the provided printWindow calls),
  // and then sends the PDF via QZ Tray.
  const handlePrint = async () => {
    try {
      setIsPrinting?.(true);
      setError?.(null);

      // Get printer settings
      const { printer, settings } = await getPrinterSettings();
      const printSettings = labelSettings;

      if (!printSettings) {
        throw new Error(
          "Printer settings not configured. Please configure your printer settings first."
        );
      }
      if (!printer) {
        throw new Error(
          "No printer configured. Please configure printer settings first."
        );
      }

      // Get the preview element
      const previewElement = document.getElementById("printable-label");
      if (!previewElement) {
        throw new Error("Preview element not found");
      }

      // Create temporary wrapper
      const wrapper = document.createElement("div");
      const [inWidth, inHeight] = printSettings.size.split("x").map(Number);
      const DPI = 96;
      const width = inWidth * DPI;
      const height = inHeight * DPI;

      wrapper.style.width = `${width}px`;
      wrapper.style.height = `${height}px`;
      wrapper.style.background = "white";
      wrapper.style.position = "relative";
      wrapper.style.overflow = "hidden";

      const previewClone = previewElement.cloneNode(true);
      // Unified styling for clone
      previewClone.style.position = "absolute";
      previewClone.style.visibility = "visible";
      previewClone.style.display = "block";
      previewClone.style.background = "white";
      previewClone.style.color = "black";
      previewClone.style.width = "100%";
      previewClone.style.height = "100%";
      previewClone.style.margin = "0";
      previewClone.style.padding = "0";
      wrapper.appendChild(previewClone);
      document.body.appendChild(wrapper);

      let pdfOutput;
      try {
        // Generate PDF using html2pdf with unified settings
        pdfOutput = await html2pdf()
          .set({
            margin: 0,
            filename: "package_label.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 4,
              backgroundColor: "#FFFFFF",
              width: wrapper.offsetWidth,
              height: wrapper.offsetHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "in",
              format: [width / DPI, height / DPI],
              orientation: printSettings.orientation || "portrait",
              compress: false,
            },
          })
          .from(wrapper)
          .outputPdf("blob");

        // Create URL for preview
        const pdfUrl = URL.createObjectURL(pdfOutput);
        const printWindow = window.open(pdfUrl, "_blank");
        console.log("PDF Generation successful");
      } catch (pdfError) {
        console.error("PDF generation error: ", pdfError);
        throw new Error("Failed to generate PDF for printing.");
      }

      document.body.removeChild(wrapper);

      // QZ Tray printing
      if (!qz.websocket.isActive()) {
        await qz.websocket.connect();
      }

      // Convert PDF to base64
      const pdfBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(pdfOutput);
      });

      // Unified QZ Tray config
      const config = qz.configs.create(printer, {
        size: { width: inWidth, height: inHeight },
        units: "in",
        orientation: printSettings.orientation,
        density: "best",
        interpolation: "nearest-neighbor",
        margins: { top: 0, right: 0, bottom: 0, left: 0 },
        rasterize: "false",
      });

      console.log("Printing configuration:", config);

      // Print using QZ Tray
      await qz.print(config, [
        {
          type: "pixel",
          format: "pdf",
          flavor: "base64",
          data: pdfBase64,
        },
      ]);
    } catch (err) {
      const errorMessage = handlePrintError?.(err, businessId) || err.message;
      if (setError) {
        setError(errorMessage);
        setShowSnackbar?.(true);
      } else {
        alert("Error printing: " + errorMessage);
      }
    } finally {
      setIsPrinting?.(false);
    }
  };

  return (
    <>
      <Tooltip title="Preview Package Label">
        <IconButton onClick={() => setOpen(true)} size="small">
          <PrintIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { minHeight: "80vh" } }}
      >
        <DialogTitle sx={{ color: "black" }}>Package Label Preview</DialogTitle>
        <DialogContent>
          {/* Label Print Settings Section */}
          <Box
            sx={{
              mb: 3,
              p: 2,
              border: "1px solid black",
              borderRadius: 1,
            }}
          >
            <Typography variant="h6" sx={{ color: "black", mb: 1 }}>
              Label Print Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "black" }}>Label Size</InputLabel>
                  <Select
                    value={labelSettings.size}
                    label="Label Size"
                    onChange={(e) =>
                      handleLabelSettingsFieldChange("size", e.target.value)
                    }
                    sx={{ color: "black" }}
                  >
                    {["4x6", "6x4"].map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ color: "black" }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "black" }}>Orientation</InputLabel>
                  <Select
                    value={labelSettings.orientation}
                    label="Orientation"
                    onChange={(e) =>
                      handleLabelSettingsFieldChange(
                        "orientation",
                        e.target.value
                      )
                    }
                    sx={{ color: "black" }}
                  >
                    <MenuItem value="portrait" sx={{ color: "black" }}>
                      Portrait
                    </MenuItem>
                    <MenuItem value="landscape" sx={{ color: "black" }}>
                      Landscape
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Title Font Size"
                  type="number"
                  value={labelSettings.fontSizes.title}
                  onChange={(e) =>
                    handleLabelFontSizeChange("title", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 10, max: 32 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Subtitle Font Size"
                  type="number"
                  value={labelSettings.fontSizes.subtitle}
                  onChange={(e) =>
                    handleLabelFontSizeChange("subtitle", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 24 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Details Font Size"
                  type="number"
                  value={labelSettings.fontSizes.details}
                  onChange={(e) =>
                    handleLabelFontSizeChange("details", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 20 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="QR Code Size"
                  type="number"
                  value={labelSettings.qrCodeSize}
                  onChange={(e) =>
                    handleLabelSettingsFieldChange("qrCodeSize", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 80, max: 200 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={onSaveLabelSettings}
                sx={{ color: "black" }}
              >
                Save Label Settings
              </Button>
            </Box>
          </Box>

          {/* Label Preview Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "60vh",
            }}
          >
            <LabelPreview />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} sx={{ color: "black" }}>
            Close
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              window.open(
                `/business/${businessId}/settings/printing-settings`,
                "_blank"
              )
            }
          >
            Label Settings Page
          </Button>
          <Button
            onClick={handlePrint}
            variant="contained"
            startIcon={
              isPrinting ? <CircularProgress size={20} /> : <PrintIcon />
            }
            disabled={isPrinting || !pkg.package_id}
            sx={{ backgroundColor: "black", color: "white" }}
          >
            {isPrinting ? "Printing..." : "Print Package Label"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PackagePreviewButton;
