// Import necessary components
import React from "react";
import EmployeeEmployeesPage from "./pages/employee/EmployeeEmployeesPage";
import EmployeeCustomerDetailPage from "./pages/employee/customers/EmployeeCustomerDetailPage";
import { SidebarProvider } from "./components/Sidebar/SidebarContext";
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
  Outlet,
} from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./utils/queryClient";
import { AuthProvider } from "./components/Auth/AuthContext";
import { BusinessProvider } from "./components/Auth/BusinessContext";
import ParallaxBackground from "./components/Background/ParallaxBackground/ParallaxBackground";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/auth/LoginPage";
import ContactPage from "./pages/contact/ContactPage";
import AboutPage from "./pages/about/AboutPage";
import DownloadPage from "./pages/download/DownloadPage";
import DownloadLayout from "./layouts/DownloadLayout";
import UserDashboardPage from "./pages/dashboard/UserDashboardPage";
import UserProfilePage from "./pages/profile/UserProfilePage";
import EmailVerification from "./pages/verification/EmailVerification";
import BusinessEmailVerification from "./pages/verification/BusinessEmailVerification";
import EmailChangeVerification from "./pages/verification/EmailChangeVerification";
import CompleteProfilePopup from "./pages/profile/CompleteProfilePopup";
import PrivateRoute from "./components/Auth/PrivateRoute";
import PublicRoute from "./components/Auth/PublicRoute";
import PasswordProtectedRoute from "./components/Auth/PasswordProtectedRoute";
import CompleteProfileRoute from "./components/Auth/CompleteProfileRoute";
import EditProfilePage from "./pages/profile/EditProfilePage";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import PasswordVerificationPage from "./pages/security/PasswordVerificationPage";
import LoginSecurity from "./pages/security/LoginSecurity";
import PaymentsPage from "./pages/payments/PaymentsPage";
import BusinessPaymentsPage from "./pages/business/payments/BusinessPaymentsPage";
import AddressesPage from "./pages/addresses/AddressesPage";
import OrdersPage from "./pages/orders/OrdersPage";
import BusinessesPage from "./pages/business/BusinessesPage";
import BusinessRegistrationPage from "./pages/business/BusinessRegistrationPage";
import BusinessDashboardPage from "./pages/business/dashboard/BusinessDashboardPage";
import BusinessAnalyticsPage from "./pages/business/analytics/BusinessAnalyticsPage";
import ShipmentsPage from "./pages/business/shipping/ShipmentsPage/ShipmentsPage";
import BusinessProfilePage from "./pages/business/profile/BusinessProfilePage";
import BusinessServicesPage from "./pages/business/service/BusinessServicePage";
import EditBusinessProfilePage from "./pages/business/profile/EditBusinessProfilePage";
import BusinessCustomersPage from "./pages/business/customers/BusinessCustomersPage";
import BusinessAddressesPage from "./pages/business/address/BusinessAddressesPage";
import EmployeeManagement from "./pages/business/employees/EmployeeManagement";
import EmployeeOnboarding from "./pages/business/employees/EmployeeOnboarding";
import EmployeeDashboard from "./pages/employee/EmployeeDashboard";
import BusinessSelector from "./pages/employee/BusinessSelector";
import EmployeeOrdersPage from "./pages/employee/EmployeeOrdersPage";
import EmployeeCustomersPage from "./pages/employee/EmployeeCustomersPage";
import EmployeeReportsPage from "./pages/employee/EmployeeReportsPage";
import EmployeeSettingsPage from "./pages/employee/settings/SettingsPage";
import EmployeePrintingSettingsPage from "./pages/employee/settings/printer/PrintingSettingsPage";
import SettingsPage from "./pages/business/settings/SettingsPage";
import PrintingSettingsPage from "./pages/business/settings/printer/PrintingSettingsPage";
import Custom404Page from "./pages/util/Custom404Page";
import AssetsPage from "./pages/business/shipping/AssetsPage/AssetsPage";
import LocationTrackingPage from "./pages/business/shipping/TrackingPage/LocationTrackingPage";
import { LocationTrackingProvider } from "./pages/business/shipping/TrackingPage/LocationTrackingContext";
import { AssetsProvider } from "./pages/business/shipping/AssetsPage/AssetsContext";
import { OrdersProvider } from "./pages/business/shipping/ShipmentsPage/OrdersContext";
import { PrintProvider } from "./pages/business/settings/printer/PrintSettings";
import { EmployeeProvider } from "./components/Auth/EmployeeContext";
import { EmployeePermissionsProvider } from "./components/Auth/EmployeePermissions";
import UserLayout from "./layouts/UserLayout";
import BusinessLayout from "./layouts/BusinessLayout";
import EmployeeLayout from "./layouts/EmployeeLayout";
import PublicLayout from "./layouts/PublicLayout";
import "./global.css";
import "./App.css";
import ServicesPage from "./pages/services/ServicesPage";
import { stripePromise } from "./utils/stripe";

// Root component with providers
const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BusinessProvider>
          <SidebarProvider>
            <div className="app">
              {/* Persistent background across all routes */}
              <ParallaxBackground />
              <Outlet />
              <ScrollRestoration
                getKey={(location) => {
                  // Only restore scroll for the order list path
                  if (location.pathname.endsWith("/shipments")) {
                    return location.pathname;
                  }
                  // Reset scroll for other paths
                  return location.pathname + location.search;
                }}
              />
            </div>
          </SidebarProvider>
        </BusinessProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <PublicLayout />,
        children: [
          { index: true, element: <HomePage /> },
          { path: "home", element: <HomePage /> },
          { path: "about", element: <AboutPage /> },
          { path: "services", element: <ServicesPage /> },
          { path: "contact", element: <ContactPage /> },
          {
            path: "confirm-email-change",
            element: <EmailChangeVerification />,
          },
          {
            path: "download",
            element: (
              <DownloadLayout>
                <DownloadPage />
              </DownloadLayout>
            ),
          },
          {
            path: "auth",
            element: (
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            ),
          },
          {
            path: "verify",
            element: (
              <PublicRoute>
                <EmailVerification />
              </PublicRoute>
            ),
          },
          {
            path: "reset-password",
            element: (
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            ),
          },
        ],
      },
      // USER ROUTES - Using the persistent layout for all user-related pages
      {
        path: "dashboard/*",
        element: (
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        ),
        children: [{ index: true, element: <UserDashboardPage /> }],
      },
      {
        path: "profile",
        element: (
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        ),
        children: [
          { index: true, element: <UserProfilePage /> },
          { path: "edit", element: <EditProfilePage /> },
          {
            path: "security",
            element: (
              <PasswordProtectedRoute>
                <LoginSecurity />
              </PasswordProtectedRoute>
            ),
          },
          {
            path: "payments",
            element: (
              <Elements stripe={stripePromise}>
                <PaymentsPage />
              </Elements>
            ),
          },
          { path: "addresses", element: <AddressesPage /> },
          { path: "orders", element: <OrdersPage /> },
          { path: "businesses", element: <BusinessesPage /> },
        ],
      },
      {
        path: "verify-password",
        element: (
          <PrivateRoute>
            <UserLayout />
          </PrivateRoute>
        ),
        children: [{ index: true, element: <PasswordVerificationPage /> }],
      },
      { path: "verify-business", element: <BusinessEmailVerification /> },
      {
        path: "complete-profile",
        element: (
          <CompleteProfileRoute>
            <CompleteProfilePopup />
          </CompleteProfileRoute>
        ),
      },
      {
        path: "register-business",
        element: (
          <PrivateRoute>
            <BusinessRegistrationPage />
          </PrivateRoute>
        ),
      },
      // BUSINESS ROUTES - Using the persistent layout
      {
        path: "business/:businessId",
        element: (
          <PrivateRoute>
            <BusinessLayout />
          </PrivateRoute>
        ),
        children: [
          {
            path: "dashboard",
            element: <BusinessDashboardPage />,
          },
          {
            path: "analytics",
            element: <BusinessAnalyticsPage />,
          },
          {
            path: "profile",
            element: <BusinessProfilePage />,
          },
          {
            path: "services",
            element: <BusinessServicesPage />,
          },
          {
            path: "edit",
            element: <EditBusinessProfilePage />,
          },
          {
            path: "addresses",
            element: <BusinessAddressesPage />,
          },
          {
            path: "customers",
            element: <BusinessCustomersPage />,
          },
          {
            path: "employees",
            element: <EmployeeManagement />,
          },
          {
            path: "shipments/*",
            element: (
              <OrdersProvider>
                <ShipmentsPage />
              </OrdersProvider>
            ),
          },
          {
            path: "assets/*",
            element: (
              <OrdersProvider>
                <AssetsProvider>
                  <AssetsPage />
                </AssetsProvider>
              </OrdersProvider>
            ),
          },
          {
            path: "finances",
            element: (
              <Elements stripe={stripePromise}>
                <BusinessPaymentsPage />
              </Elements>
            ),
          },
          {
            path: "location-tracking/*",
            element: (
              <LocationTrackingProvider>
                <LocationTrackingPage />
              </LocationTrackingProvider>
            ),
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
          {
            path: "settings/printing-settings",
            element: <PrintingSettingsPage />,
          },
        ],
      },
      {
        path: "employee-onboarding/:token",
        element: <EmployeeOnboarding />,
      },
      {
        path: "employee",
        element: (
          <PrivateRoute>
            <BusinessSelector />
          </PrivateRoute>
        ),
      },
      // EMPLOYEE ROUTES - Using the persistent layout
      {
        path: "employee/:businessId",
        element: (
          <PrivateRoute>
            <EmployeeLayout />
          </PrivateRoute>
        ),
        children: [
          {
            path: "dashboard",
            element: <EmployeeDashboard />,
          },
          {
            path: "orders/*",
            element: (
              <OrdersProvider>
                <EmployeeOrdersPage />
              </OrdersProvider>
            ),
          },
          {
            path: "customers",
            element: <EmployeeCustomersPage />,
          },
          {
            path: "customers/:customerId",
            element: <EmployeeCustomerDetailPage />,
          },
          {
            path: "employees",
            element: <EmployeeEmployeesPage />,
          },
          {
            path: "reports",
            element: <EmployeeReportsPage />,
          },
          {
            path: "settings",
            element: <EmployeeSettingsPage />,
          },
          {
            path: "settings/printing-settings",
            element: <EmployeePrintingSettingsPage />,
          },
        ],
      },
      { path: "*", element: <Custom404Page /> },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
