import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Alert,
  Box,
  Button,
  Skeleton,
  Avatar,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { AuthContext } from "../Auth/AuthContext";
import {
  AttachMoney as MoneyIcon,
  ShoppingCart as CartIcon,
  ThumbUp as ThumbUpIcon,
  Done as DoneIcon,
  HourglassEmpty,
  Autorenew,
  LocalShipping,
} from "@mui/icons-material";

const MetricCard = ({ title, value, icon, color }) => (
  <Card>
    <CardHeader title={title} />
    <CardContent>
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: color, mr: 2 }}>{icon}</Avatar>
        <Box>
          <Typography variant="h5" color="textPrimary">
            {value}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

/* Loading skeleton for the overview dashboard */
const OverviewSkeleton = () => (
  <Box sx={{ width: "100%", maxWidth: "100%", overflow: "hidden" }}>
    <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />

    <Grid container spacing={3} sx={{ mb: 3 }}>
      {[...Array(4)].map((_, i) => (
        <Grid item xs={12} sm={6} md={3} key={i}>
          <Skeleton variant="rectangular" height={100} width="100%" />
        </Grid>
      ))}
    </Grid>

    <Skeleton variant="rectangular" height={300} width="100%" sx={{ mb: 3 }} />
    <Skeleton variant="rectangular" height={120} width="100%" />
  </Box>
);

const BusinessDashboardOverview = ({ business, actionItems }) => {
  const { api } = useContext(AuthContext);
  const [overviewData, setOverviewData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        if (!business?.business_id) {
          console.log("No business ID found, cannot fetch overview data");
          return;
        }

        console.log(
          `Fetching overview data for business ID: ${business.business_id}`
        );
        const response = await api.get(
          `/business-actions/${business.business_id}/overview`
        );

        if (response.data.success) {
          console.log(
            "Successfully fetched overview data:",
            response.data.data
          );
          setOverviewData(response.data.data);
        } else {
          console.warn("API returned success:false", response.data);
          setError(response.data.message || "Failed to fetch overview data");
        }
      } catch (err) {
        console.error("Error fetching overview data:", err);
        setError(
          err.response?.data?.message || "Failed to fetch overview data"
        );
      }
    };

    fetchOverviewData();

    // Set up a refresh interval to keep dashboard data up-to-date
    const refreshInterval = setInterval(fetchOverviewData, 60000); // Refresh every minute

    return () => clearInterval(refreshInterval);
  }, [business?.business_id, api]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  // Show skeleton when data is loading
  if (!overviewData) {
    return <OverviewSkeleton />;
  }

  return (
    <div className="dashboard-overview">
      <div className="business-page-header">
        <Typography variant="h4" gutterBottom>
          Welcome to {business?.business_name || "Your"} Dashboard
          <Button
            variant="outlined"
            size="small"
            sx={{ ml: 2 }}
            onClick={() =>
              (window.location.href = `/business/${business?.business_id}/analytics`)
            }
          >
            View Full Analytics
          </Button>
        </Typography>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Total Revenue"
            value={overviewData ? `$${overviewData.totalRevenue}` : "$0"}
            icon={<MoneyIcon />}
            color="#1976d2"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="New Orders (24h)"
            value={overviewData?.newOrders || 0}
            icon={<CartIcon />}
            color="#388e3c"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Customer Satisfaction"
            value={overviewData?.customerSatisfaction || "N/A"}
            icon={<ThumbUpIcon />}
            color="#f57c00"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Completion Rate"
            value={overviewData?.completionRate || "N/A"}
            icon={<DoneIcon />}
            color="#d32f2f"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Sales Overview (Last 30 Days)" />
            <CardContent>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  {Array.isArray(overviewData?.salesData) &&
                  overviewData.salesData.length > 0 ? (
                    <LineChart data={overviewData.salesData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        angle={-45}
                        textAnchor="end"
                        height={70}
                      />
                      <YAxis yAxisId="left" />
                      <YAxis yAxisId="right" orientation="right" />
                      <Tooltip />
                      <Legend />
                      <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="sales"
                        name="Sales ($)"
                        stroke="#8884d8"
                        dot={false}
                      />
                      <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="orders"
                        name="Orders"
                        stroke="#82ca9d"
                        dot={false}
                      />
                    </LineChart>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height={300}
                    >
                      <Typography variant="body1" color="textSecondary">
                        No sales data available for the last 30 days
                      </Typography>
                    </Box>
                  )}
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title="Order Metrics" />
            <CardContent>
              {overviewData?.orderMetrics ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      borderRadius="4px"
                      bgcolor="#f5f5f5"
                    >
                      <Avatar sx={{ bgcolor: "#1976d2", mr: 2 }}>
                        <CartIcon fontSize="small" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          Total Orders
                        </Typography>
                        <Typography variant="h6">
                          {overviewData.orderMetrics.total || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      borderRadius="4px"
                      bgcolor="#f5f5f5"
                    >
                      <Avatar sx={{ bgcolor: "#f57c00", mr: 2 }}>
                        <HourglassEmpty fontSize="small" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          Pending
                        </Typography>
                        <Typography variant="h6">
                          {overviewData.orderMetrics.pending || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      borderRadius="4px"
                      bgcolor="#f5f5f5"
                    >
                      <Avatar sx={{ bgcolor: "#388e3c", mr: 2 }}>
                        <Autorenew fontSize="small" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          Processing
                        </Typography>
                        <Typography variant="h6">
                          {overviewData.orderMetrics.processing || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      display="flex"
                      alignItems="center"
                      p={1}
                      borderRadius="4px"
                      bgcolor="#f5f5f5"
                    >
                      <Avatar sx={{ bgcolor: "#d32f2f", mr: 2 }}>
                        <LocalShipping fontSize="small" />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2" color="textSecondary">
                          Delivered
                        </Typography>
                        <Typography variant="h6">
                          {overviewData.orderMetrics.delivered || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  Order metrics loading or not available
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessDashboardOverview;
