// nodeTypes.jsx
import React from "react";
import BatchLocationNode from "./BatchLocationNode";
import { useFlowContext } from "./FlowContext";

const LocationNode = (props) => {
  // Get all dynamic data from context
  const { edges, orders, dragSourceId, getValidTargetsForNode } =
    useFlowContext();

  const isValidTarget =
    !dragSourceId || getValidTargetsForNode(dragSourceId).has(props.id);

  return (
    <BatchLocationNode
      {...props}
      edges={edges}
      orders={orders}
      isValidTarget={isValidTarget}
      isDragging={!!dragSourceId}
    />
  );
};

// Define nodeTypes as a static object
export const nodeTypes = {
  locationNode: React.memo(LocationNode),
};
