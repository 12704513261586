import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context for sidebar state
const SidebarContext = createContext();

// Provider component to wrap around the app
export const SidebarProvider = ({ children }) => {
  // Initialize from localStorage with a fallback to true
  const [isBusinessSidebarActive, setIsBusinessSidebarActive] = useState(() => {
    const saved = localStorage.getItem("businessSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  
  const [isEmployeeSidebarActive, setIsEmployeeSidebarActive] = useState(() => {
    const saved = localStorage.getItem("employeeSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  const [isUserSidebarActive, setIsUserSidebarActive] = useState(() => {
    const saved = localStorage.getItem("userSidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem("businessSidebarActive", JSON.stringify(isBusinessSidebarActive));
  }, [isBusinessSidebarActive]);
  
  useEffect(() => {
    localStorage.setItem("employeeSidebarActive", JSON.stringify(isEmployeeSidebarActive));
  }, [isEmployeeSidebarActive]);

  useEffect(() => {
    localStorage.setItem("userSidebarActive", JSON.stringify(isUserSidebarActive));
  }, [isUserSidebarActive]);

  const toggleBusinessSidebar = () => {
    setIsBusinessSidebarActive(prev => !prev);
  };
  
  const toggleEmployeeSidebar = () => {
    setIsEmployeeSidebarActive(prev => !prev);
  };

  const toggleUserSidebar = () => {
    setIsUserSidebarActive(prev => !prev);
  };

  const value = {
    isBusinessSidebarActive,
    setIsBusinessSidebarActive,
    toggleBusinessSidebar,
    isEmployeeSidebarActive,
    setIsEmployeeSidebarActive,
    toggleEmployeeSidebar,
    isUserSidebarActive,
    setIsUserSidebarActive,
    toggleUserSidebar
  };

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
};

// Custom hook to use the sidebar context
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
