import React, { createContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { resetStripe } from "../../utils/stripe";

/**
 * Authentication status enum to track user auth state
 */
export const AUTH_STATUS = {
  UNKNOWN: "unknown", // Initial state, haven't checked auth yet
  CHECKING: "checking", // Actively checking authentication
  AUTHENTICATED: "authenticated", // User is authenticated
  UNAUTHENTICATED: "unauthenticated", // User is not authenticated
};

/**
 * Authentication states to track initialization process
 */
export const AUTH_STATES = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  FAILED: "failed",
  TIMED_OUT: "timed_out",
};

/**
 * Token configuration constants - kept in sync with backend
 */
const TOKEN_CONFIG = {
  ACCESS: {
    expiresIn: "4h",
    cookieMaxAge: 4 * 60 * 60 * 1000, // 4 hours in milliseconds
    refreshBuffer: 10 * 60 * 1000, // 10 minutes before expiry for more safety margin
  },
  REFRESH: {
    expiresIn: "7d",
    cookieMaxAge: 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
  },
  REMEMBER_ME: {
    expiresIn: "30d",
    cookieMaxAge: 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
  },
  COOKIE_OPTIONS: {
    path: "/",
    sameSite: "Strict",
    secure: process.env.NODE_ENV === "production", // Only use secure in production (requires HTTPS)
    httpOnly: false, // Client-side cookies cannot be httpOnly
  },
};

// Create the context
const AuthContext = createContext();

// Configure axios instance with default settings
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * The AuthProvider component manages authentication state and provides
 * authentication-related functionality to the entire application.
 */
const AuthProvider = ({ children }) => {
  // State management
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.UNKNOWN);

  // References for tracking initialization state
  const loginOccurredRef = useRef(false);
  const authTimeoutRef = useRef(null);

  // Simplified authentication state - a user exists or doesn't
  const isAuthenticated = !!user;

  // References to manage async processes and timers
  const navigate = useNavigate();
  const broadcastChannelRef = useRef(null);
  const refreshTokenPromise = useRef(null);
  const refreshTimeout = useRef(null);
  const cookieCheckInterval = useRef(null);
  const refreshQueue = useRef([]);
  const isLoggingOut = useRef(false);

  /**
   * Utility: Check if a given JWT token is still valid
   * @param {string} token The JWT token to validate
   * @param {number} bufferMs Buffer time in milliseconds before expiry to return false
   * @returns {boolean} True if token is valid and not about to expire
   */
  const isTokenValid = (token, bufferMs = 10000) => {
    if (!token) return false;
    try {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      return decoded.exp * 1000 > Date.now() + bufferMs;
    } catch (error) {
      console.error("Token validation error:", error);
      return false;
    }
  };

  /**
   * Decode a JWT token and extract its payload
   * @param {string} token The JWT token to decode
   * @returns {Object|null} The decoded token payload or null if invalid
   */
  const decodeToken = (token) => {
    if (!token) return null;
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (error) {
      console.error("Token decode error:", error);
      return null;
    }
  };

  /**
   * Check if a token is about to expire
   * @param {string} token The JWT token to check
   * @param {number} bufferMs Time in milliseconds before expiry to trigger refresh
   * @returns {boolean} True if token is expiring soon
   */
  const isTokenExpiring = (
    token,
    bufferMs = TOKEN_CONFIG.ACCESS.refreshBuffer
  ) => {
    if (!token) return true;

    const decoded = decodeToken(token);
    if (!decoded || !decoded.exp) return true;

    const expiryTime = decoded.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    const timeRemaining = expiryTime - currentTime;

    return timeRemaining < bufferMs;
  };

  /**
   * Check if all necessary authentication cookies are present
   * @returns {boolean} True if HTTP-only cookies likely exist
   */
  const hasCookies = () => {
    // Since HTTP-only cookies can't be directly accessed by JavaScript,
    // we check if the user is authenticated through other means
    return !!user;
  };

  /**
   * Resolve or reject all pending refresh calls in the queue
   * @param {Error|null} error Error object if refresh failed
   * @param {string|null} token New token if refresh succeeded
   */
  const processQueue = (error, token = null) => {
    refreshQueue.current.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    refreshQueue.current = [];
  };

  /**
   * Log out the current user and clean up authentication state
   * @param {boolean} skipApiCall If true, skip the API call to /auth/logout
   * @returns {Promise<void>}
   */
  const logout = async (skipApiCall = false) => {
    if (isLoggingOut.current) return;
    isLoggingOut.current = true;

    // Clear auth timeout if it exists
    if (authTimeoutRef.current) {
      clearTimeout(authTimeoutRef.current);
      authTimeoutRef.current = null;
    }

    try {
      if (!skipApiCall) {
        await api.post("/auth/logout");
      }
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      resetStripe();

      // Clear access token from memory
      setAccessToken(null);

      // Clear Authorization header
      delete api.defaults.headers.common["Authorization"];
      Cookies.remove("securityAccessTimestamp", { path: "/" });

      setUser(null);
      setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);

      // Clear timers
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
        refreshTimeout.current = null;
      }
      refreshTokenPromise.current = null;

      // Clear cookie check interval
      if (cookieCheckInterval.current) {
        clearInterval(cookieCheckInterval.current);
        cookieCheckInterval.current = null;
      }

      // Broadcast logout to other tabs
      try {
        if (!broadcastChannelRef.current) {
          broadcastChannelRef.current = new BroadcastChannel("auth");
        }
        broadcastChannelRef.current.postMessage("logout");
      } catch (error) {
        console.error("Error broadcasting logout:", error);
        try {
          if (broadcastChannelRef.current) {
            broadcastChannelRef.current.close();
          }
          broadcastChannelRef.current = new BroadcastChannel("auth");
        } catch (channelError) {
          console.error("Failed to recreate broadcast channel:", channelError);
        }
      }

      // Navigate to auth page
      navigate("/auth", { replace: true });
      isLoggingOut.current = false;
    }
  };

  /**
   * Schedule the next token refresh
   * @param {string} token The access token to schedule refresh for
   */
  const scheduleTokenRefresh = (token) => {
    if (!token) return;

    const decoded = decodeToken(token);
    if (!decoded || !decoded.exp) return;

    const expiryTime = decoded.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    const timeToRefresh =
      expiryTime - currentTime - TOKEN_CONFIG.ACCESS.refreshBuffer;

    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
    }

    // Use a minimum time to prevent too frequent refreshes
    const minimumRefreshInterval = 30 * 1000; // 30 seconds minimum

    if (timeToRefresh > 0) {
      console.debug(
        `Scheduling token refresh in ${Math.round(
          timeToRefresh / 1000
        )} seconds`
      );

      // Schedule main refresh before expiry, but not too frequently
      refreshTimeout.current = setTimeout(
        () => refreshAccessToken(),
        Math.max(timeToRefresh, minimumRefreshInterval)
      );
    } else if (
      timeToRefresh <= 0 &&
      timeToRefresh > -TOKEN_CONFIG.ACCESS.refreshBuffer
    ) {
      // Only refresh immediately if we're within the buffer but not too far past it
      console.debug("Token already close to expiry, refreshing now");
      refreshAccessToken();
    }
  };

  /**
   * Refresh the access token using refresh token
   * If refresh fails, try remember-me or finally logout
   * @returns {Promise<string>} The new access token
   */
  const refreshAccessToken = async () => {
    // Clear existing refresh timeout to avoid multiple refresh attempts
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
      refreshTimeout.current = null;
    }
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshQueue.current.push({ resolve, reject });
      });
    }

    setIsRefreshing(true);
    try {
      console.log("Attempting to refresh access token");

      // Make sure we're explicitly including credentials with this critical request
      const { data } = await api.post(
        "/auth/refresh-token",
        {},
        {
          withCredentials: true,
        }
      );

      if (!data.accessToken || !isTokenValid(data.accessToken)) {
        throw new Error("Received invalid token during refresh");
      }

      // Store token in memory
      setAccessToken(data.accessToken);

      // Update Authorization header
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.accessToken}`;

      // Schedule the next refresh before token expires
      scheduleTokenRefresh(data.accessToken);

      processQueue(null, data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error("Token refresh failed:", error);
      processQueue(error, null);

      try {
        // If refresh token fails, try remember-me token
        return await rememberMeLogin();
      } catch (rememberMeError) {
        // If both fail, log out
        await logout(true);
        throw new Error("Authentication failed");
      }
    } finally {
      setIsRefreshing(false);
    }
  };

  /**
   * Attempt to log in using the remember-me token
   * @returns {Promise<string>} The new access token
   */
  const rememberMeLogin = async () => {
    try {
      const { data } = await api.post("/auth/remember-me");
      if (!isTokenValid(data.accessToken)) {
        throw new Error("Invalid token during remember me login");
      }

      // Store token in memory
      setAccessToken(data.accessToken);

      // Update Authorization header
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.accessToken}`;

      // Schedule the next refresh
      scheduleTokenRefresh(data.accessToken);

      return data.accessToken;
    } catch (error) {
      console.error("Error with remember me login:", error);
      throw error;
    }
  };

  /**
   * Returns a promise that ensures only one refresh is happening at a time
   * @returns {Promise<string>} The refreshed token
   */
  const getRefreshedToken = async () => {
    // If we're logging out, don't try to refresh
    if (isLoggingOut.current) {
      throw new Error("Cannot refresh during logout");
    }

    // Create a last refresh timestamp if it doesn't exist
    if (!getRefreshedToken.lastRefreshTime) {
      getRefreshedToken.lastRefreshTime = 0;
    }

    // Prevent refreshing too frequently (no more than once every 10 seconds)
    const now = Date.now();
    const timeSinceLastRefresh = now - getRefreshedToken.lastRefreshTime;
    if (timeSinceLastRefresh < 10000) {
      // 10 seconds
      console.log(
        `Skipping refresh - last refresh was ${timeSinceLastRefresh}ms ago`
      );
      if (accessToken) {
        return accessToken;
      }
    }

    if (!refreshTokenPromise.current) {
      console.log("Starting new token refresh");
      getRefreshedToken.lastRefreshTime = now;

      refreshTokenPromise.current = refreshAccessToken().finally(() => {
        console.log("Token refresh completed");
        // Add a small delay before clearing the promise reference
        // This prevents rapid consecutive calls from triggering multiple refreshes
        setTimeout(() => {
          refreshTokenPromise.current = null;
        }, 500); // Increased to 500ms to further reduce race conditions
      });
    } else {
      console.log("Using existing token refresh promise");
    }
    return refreshTokenPromise.current;
  };

  /**
   * Set up axios interceptors to handle authentication
   * - Request interceptor adds Authorization header with token
   * - Response interceptor handles 401 errors and token refresh
   */
  useEffect(() => {
    let retryCount = 0;
    const MAX_RETRIES = 3;

    // Request interceptor - adds auth header to outgoing requests
    const requestInterceptor = api.interceptors.request.use(
      async (config) => {
        // Skip token refresh for ALL auth endpoints to prevent recursion
        const isAuthEndpoint =
          config.url &&
          (config.url.includes("/auth/") || config.url.startsWith("/auth"));

        // Add a timestamp to all requests to prevent stale responses
        // This helps with cache busting in some environments
        if (!isAuthEndpoint && config.params) {
          config.params = { ...config.params, _t: Date.now() };
        } else if (!isAuthEndpoint) {
          config.params = { _t: Date.now() };
        }

        if (!isAuthEndpoint) {
          // If we have an access token in memory, use it
          if (accessToken) {
            config.headers["Authorization"] = `Bearer ${accessToken}`;

            // If token is expired or about to expire, refresh it
            // Using a longer buffer to ensure we're not cutting it too close
            if (
              isTokenExpiring(accessToken, TOKEN_CONFIG.ACCESS.refreshBuffer)
            ) {
              try {
                console.log("Token is expiring, refreshing before request");
                const newToken = await getRefreshedToken();
                config.headers["Authorization"] = `Bearer ${newToken}`;
              } catch (error) {
                console.error("Token refresh failed during request:", error);
                // If refresh fails on a non-auth endpoint, consider redirecting to login
                // by setting auth status to unauthenticated
                if (authStatus === AUTH_STATUS.AUTHENTICATED) {
                  console.warn("Auth failure detected, logging out");
                  logout(true).catch(console.error);
                }
              }
            }
          } else {
            // Try to refresh the token if no access token in memory
            try {
              const newToken = await getRefreshedToken();
              if (newToken) {
                config.headers["Authorization"] = `Bearer ${newToken}`;
              } else {
                // If we didn't get a token and we should be authenticated, something's wrong
                if (authStatus === AUTH_STATUS.AUTHENTICATED) {
                  console.warn("Expected token but got none, logging out");
                  logout(true).catch(console.error);
                }
              }
            } catch (error) {
              // If refresh fails, we'll proceed without a token
              // The server will respond with 401 if needed and we'll handle it in the response interceptor
              console.debug("No token available for request:", config.url);
            }
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response interceptor - handles 401 errors and token refresh
    const responseInterceptor = api.interceptors.response.use(
      (response) => {
        // Check for refresh header from server
        if (response.headers["x-should-refresh-token"]) {
          // Don't await this call to prevent blocking
          getRefreshedToken().catch(console.error);
        }
        retryCount = 0;
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        // Debug logging for API errors
        console.debug("API error:", {
          status: error.response?.status,
          url: originalRequest?.url,
          retryCount,
          hasRetryFlag: !!originalRequest?._retry,
          errorCode: error.response?.data?.code,
        });

        // Handle 401 Unauthorized errors
        if (error.response?.status === 401) {
          // Check error code for token expired
          const isTokenExpired =
            error.response?.data?.code === "TOKEN_EXPIRED" ||
            error.response?.data?.message === "Unauthorized: Invalid token";

          // If max retries reached or explicitly told token expired, log out
          if (retryCount >= MAX_RETRIES || isTokenExpired) {
            console.warn(
              isTokenExpired ? "Token expired" : "Max retries reached",
              "logging out"
            );
            await logout(true);
            return Promise.reject(new Error("Authentication failed"));
          }

          // Retry request with new token
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            retryCount += 1;
            try {
              const newToken = await getRefreshedToken();

              // Skip retry for auth endpoints to prevent loops
              if (
                originalRequest.url &&
                (originalRequest.url.includes("/auth/refresh-token") ||
                  originalRequest.url.includes("/auth/remember-me"))
              ) {
                return Promise.reject(new Error("Auth endpoint failed"));
              }

              // Update authorization header and retry request
              originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
              return api(originalRequest);
            } catch (refreshError) {
              console.error(
                "Token refresh failed in response interceptor:",
                refreshError
              );
              await logout(true);
              return Promise.reject(new Error("Authentication failed"));
            }
          }
        }

        return Promise.reject(error);
      }
    );

    // Clean up interceptors on unmount
    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, [accessToken]); // Re-establish interceptors when accessToken changes

  // Verify API base URL is configured properly
  useEffect(() => {
    if (!process.env.REACT_APP_API_BASE_URL) {
      console.error("API_BASE_URL environment variable is not configured");
    }
  }, []);

  /**
   * Initialize authentication on component mount
   */
  useEffect(() => {
    // Reference to track if component is mounted
    let isMounted = true;
    // Clear any existing timeout first
    if (authTimeoutRef.current) {
      clearTimeout(authTimeoutRef.current);
      authTimeoutRef.current = null;
    }

    const initializeAuth = async () => {
      if (!isMounted) return;
      setAuthStatus(AUTH_STATUS.CHECKING);
      setLoading(true);

      try {
        // Try to refresh the token and get user data
        const newToken = await refreshAccessToken(); // Get the returned token

        // Skip the rest if a login occurred during this process or component unmounted
        if (!isMounted || loginOccurredRef.current) {
          console.log(
            "Skipping auth initialization completion - login occurred or component unmounted"
          );
          return;
        }

        // Use the actual returned token value from refreshAccessToken, not the state variable
        if (newToken) {
          try {
            const response = await api.get("/auth/verify-token");
            if (!isMounted || loginOccurredRef.current) return;
            setUser(response.data.user);
            setAuthStatus(AUTH_STATUS.AUTHENTICATED);
          } catch (verifyError) {
            console.error("Error verifying token:", verifyError);
            if (!isMounted || loginOccurredRef.current) return;
            setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
          }
        } else {
          // If no token after refresh, user is not authenticated
          if (!isMounted || loginOccurredRef.current) return;
          setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
        }
      } catch (error) {
        // If refresh fails, user is not authenticated
        if (!isMounted || loginOccurredRef.current) return;
        console.debug(
          "Auth initialization: No valid authentication found",
          error.message
        );
        setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
      } finally {
        if (isMounted && !loginOccurredRef.current) {
          // Clear any timeout since we've completed authentication
          if (authTimeoutRef.current) {
            clearTimeout(authTimeoutRef.current);
            authTimeoutRef.current = null;
          }

          setLoading(false);
          setAuthInitialized(true);
        }
      }
    };

    // Start auth initialization
    initializeAuth();

    // Fallback timeout to prevent hanging if API calls never return
    authTimeoutRef.current = setTimeout(() => {
      if (
        isMounted &&
        !loginOccurredRef.current &&
        (authStatus === AUTH_STATUS.CHECKING ||
          authStatus === AUTH_STATUS.UNKNOWN)
      ) {
        console.warn("Auth check timed out - assuming unauthenticated");
        setAuthStatus(AUTH_STATUS.UNAUTHENTICATED);
        setLoading(false);
        setAuthInitialized(true);
      }
    }, 8000); // 8 seconds to allow more time for slow connections

    return () => {
      isMounted = false;
      if (authTimeoutRef.current) {
        clearTimeout(authTimeoutRef.current);
        authTimeoutRef.current = null;
      }
    };
  }, []);

  /**
   * Set up cross-tab communication for logout
   * Uses BroadcastChannel API to notify other tabs when user logs out
   */
  useEffect(() => {
    try {
      // Initialize broadcast channel for cross-tab communication
      if (!broadcastChannelRef.current) {
        broadcastChannelRef.current = new BroadcastChannel("auth");
      }

      // Handle logout messages from other tabs
      const handleBroadcastMessage = (event) => {
        if (event.data === "logout") {
          setUser(null);
          setAccessToken(null);
          delete api.defaults.headers.common["Authorization"];
        }
      };

      broadcastChannelRef.current.addEventListener(
        "message",
        handleBroadcastMessage
      );

      // Clean up listener on unmount
      return () => {
        try {
          if (broadcastChannelRef.current) {
            broadcastChannelRef.current.removeEventListener(
              "message",
              handleBroadcastMessage
            );
            broadcastChannelRef.current.close();
            broadcastChannelRef.current = null;
          }
        } catch (error) {
          console.error("Error cleaning up broadcast channel:", error);
        }
      };
    } catch (error) {
      console.error("Failed to initialize broadcast channel:", error);
      return () => {}; // Empty cleanup function if initialization fails
    }
  }, []);

  /**
   * Verify user password for sensitive operations
   * @param {string} password The password to verify
   * @returns {Promise<Object>} Verification result
   */
  const verifyPassword = async (password) => {
    if (!accessToken) {
      throw new Error("No access token found");
    }

    const response = await api.post("/auth/verify-password", { password });

    if (response.data.verified) {
      try {
        // Set timestamp for security access (for sensitive operations)
        Cookies.set("securityAccessTimestamp", Date.now().toString(), {
          expires: 1 / 96, // 15 minutes
          path: "/",
          secure: TOKEN_CONFIG.COOKIE_OPTIONS.secure,
          sameSite: TOKEN_CONFIG.COOKIE_OPTIONS.sameSite,
        });
      } catch (error) {
        console.error("Error setting security access timestamp cookie:", error);
      }
    }

    return response.data;
  };

  /**
   * Handle user login
   * @param {string} email User email
   * @param {string} password User password
   * @param {boolean} rememberMe Whether to enable remember-me functionality
   * @returns {Promise<void>}
   */
  const login = async (email, password, rememberMe) => {
    try {
      console.log("AuthContext: Starting login process");

      // Mark that login has occurred to prevent initialization from overriding
      loginOccurredRef.current = true;

      const response = await api.post("/auth/login", {
        user_email: email,
        user_password: password,
        remember_me: rememberMe,
      });

      const newToken = response.data.accessToken;
      if (!isTokenValid(newToken)) {
        throw new Error("Received invalid token during login");
      }

      // Store token in memory
      setAccessToken(newToken);

      // Set Authorization header
      api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;

      // Fetch user data
      const userResponse = await api.get("/auth/verify-token");

      // Clear any auth timeout since we're now authenticated
      if (authTimeoutRef.current) {
        clearTimeout(authTimeoutRef.current);
        authTimeoutRef.current = null;
      }

      // Update all auth state at once for consistency
      setUser(userResponse.data.user);
      setAuthStatus(AUTH_STATUS.AUTHENTICATED);
      setAuthInitialized(true); // Mark auth as initialized after successful login
      setLoading(false);

      // Schedule token refresh
      scheduleTokenRefresh(newToken);
    } catch (error) {
      console.error("AuthContext: Error during login:", error);

      // Reset login flag to allow initialization to complete
      loginOccurredRef.current = false;
      throw error;
    }
  };

  /**
   * Update user context with fresh data from server
   * @returns {Promise<void>}
   */
  const updateUserContext = async () => {
    try {
      const response = await api.get("/auth/verify-token");
      setUser(response.data.user);
    } catch (error) {
      console.error("Error updating user context:", error);
      logout();
    }
  };

  /**
   * Check if user profile is complete with required fields
   * @param {Object} u User object to check
   * @returns {boolean} True if profile is complete
   */
  const isProfileComplete = (u) => {
    return !!(u?.user_first_name && u?.user_last_name && u?.user_date_of_birth);
  };

  // Provide auth context to children components
  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        logout,
        refreshAccessToken,
        isProfileComplete,
        updateUserContext,
        verifyPassword,
        rememberMeLogin,
        api,
        authStatus,
        isAuthenticated,
        accessToken, // Expose the current access token for debugging
        authInitialized, // Expose whether auth initialization is complete
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider, TOKEN_CONFIG };
