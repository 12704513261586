import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Badge,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Skeleton,
  Divider,
  Chip,
  Box,
} from "@mui/material";
import {
  Warning,
  ErrorOutline,
  CheckCircleOutline,
  LocalShipping,
  Refresh,
  ExpandMore,
  ExpandLess,
  Print,
  TrendingDown,
  Inventory,
  TrendingUp,
  Person,
  Payment,
  CreditCard,
} from "@mui/icons-material";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import "./BusinessActionItems.css";

const ActionItemsSkeleton = () => (
  <div className="action-items-skeleton">
    <Skeleton variant="rectangular" height={48} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={120} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={200} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={160} />
      </Grid>
    </Grid>
  </div>
);

const BusinessActionItems = ({ businessId, actionItems, business }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [urgentActions, setUrgentActions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [printerConfig, setPrinterConfig] = useState(null);
  const { api } = useContext(AuthContext);

  // Client-side function to check printer configuration
  const checkPrinterConfiguration = useCallback(() => {
    try {
      // Define required printer types
      const requiredPrinters = [
        "receipts",
        "shippingLabels",
        "shippingManifests",
        "shippingDocuments",
        "individualItemLabels",
      ];

      // Check localStorage for printer configurations
      const printerConfigs = JSON.parse(
        localStorage.getItem(`printers_${businessId}`) || "{}"
      );

      // Determine which printers are configured (have a value assigned)
      const configuredPrinters = Object.keys(printerConfigs).filter(
        (type) => printerConfigs[type] && printerConfigs[type].trim() !== ""
      );

      // Find missing printers (required but not configured)
      const missingPrinters = requiredPrinters.filter(
        (type) => !configuredPrinters.includes(type)
      );

      // Determine if all required printers are configured
      const isConfigured = missingPrinters.length === 0;

      const config = {
        isConfigured,
        configuredPrinters,
        missingPrinters,
      };

      console.log("Printer configuration check:", config);
      setPrinterConfig(config);
      return config;
    } catch (error) {
      console.error("Error checking printer configuration:", error);
      const config = {
        isConfigured: false,
        configuredPrinters: [],
        missingPrinters: [
          "receipts",
          "shippingLabels",
          "shippingManifests",
          "shippingDocuments",
          "individualItemLabels",
        ],
      };
      setPrinterConfig(config);
      return config;
    }
  }, [businessId]);

  // Check printer configuration on component mount
  useEffect(() => {
    checkPrinterConfiguration();
  }, [checkPrinterConfiguration]);

  useEffect(() => {
    const fetchUrgentActions = async () => {
      if (!expanded) return;

      setLoading(true);
      try {
        const response = await api.get(
          `/business-actions/${businessId}/urgent-actions`
        );

        if (response.data.success) {
          setUrgentActions(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching urgent actions:", error);
        setError(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (expanded) {
      fetchUrgentActions();
    }
  }, [expanded, businessId, api]);

  const handleCreateShippingTier = () => {
    navigate(`/business/${businessId}/services`);
  };

  const handleViewPendingOrders = () => {
    navigate(`/business/${businessId}/shipments`, {
      state: {
        activeTab: 0, // This will select the Order List tab
        searchQuery: "", // Clear any existing search
        filter: "pending", // Add the pending filter
      },
    });
  };

  const handleSetupPrinters = () => {
    navigate(`/business/${businessId}/settings/printing-settings`);
  };

  const handleAssignEmployees = () => {
    navigate(`/business/${businessId}/employees`, {
      state: {
        showUnassignedOrders: true,
      },
    });
  };

  const handleManageInventory = () => {
    navigate(`/business/${businessId}/inventory`);
  };

  const handleViewPerformanceMetrics = () => {
    navigate(`/business/${businessId}/reports/performance`);
  };

  const handleReviewRates = () => {
    navigate(`/business/${businessId}/services`);
  };

  const handleSetupPaymentMethods = () => {
    navigate(`/business/${businessId}/payments`);
  };

  const handleUpdateExpiredCard = () => {
    navigate(`/business/${businessId}/payments`);
  };

  if (!actionItems) {
    return (
      <div className="dashboard-overview">
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Card className="business-action-items">
              <CardContent>
                <ActionItemsSkeleton />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className="dashboard-overview">
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Card className="business-action-items">
            {/* max width 100% */}
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                maxWidth: "100%",
              }}
            >
              <div className="action-items-header">
                <Typography variant="h6" component="h2">
                  Action Items
                </Typography>
                <Badge
                  badgeContent={actionItems.action_items_count}
                  color="error"
                  max={99}
                >
                  <IconButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Badge>
              </div>

              <Collapse in={expanded}>
                {loading ? (
                  <ActionItemsSkeleton />
                ) : (
                  <Grid container spacing={2}>
                    {/* Missing Shipping Tier Warning */}
                    {actionItems.needs_shipping_tier && (
                      <Grid item xs={12}>
                        <Card variant="outlined" className="warning-card">
                          <CardContent>
                            <Typography color="error" gutterBottom>
                              <Warning /> No Shipping Tiers Configured
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              You need to set up shipping tiers to start
                              accepting orders.
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleCreateShippingTier}
                              sx={{ mt: 1 }}
                            >
                              Create Shipping Tier
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Printer Setup Warning */}
                    {(actionItems.needs_printer_setup ||
                      (printerConfig && !printerConfig.isConfigured)) && (
                      <Grid item xs={12}>
                        <Card variant="outlined" className="warning-card">
                          <CardContent>
                            <Typography color="warning.main" gutterBottom>
                              <Print /> Printers Not Configured
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Configure your printers to print shipping labels,
                              receipts, and other documents.
                            </Typography>
                            {printerConfig &&
                              printerConfig.missingPrinters.length > 0 && (
                                <Box sx={{ mt: 1, mb: 1 }}>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    Missing printer configurations:
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                      mt: 0.5,
                                    }}
                                  >
                                    {printerConfig.missingPrinters.map(
                                      (printer) => (
                                        <Chip
                                          key={printer}
                                          label={printer}
                                          size="small"
                                          color="warning"
                                          variant="outlined"
                                        />
                                      )
                                    )}
                                  </Box>
                                </Box>
                              )}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSetupPrinters}
                              sx={{ mt: 1 }}
                            >
                              Configure Printers
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Unassigned Orders */}
                    {actionItems.unassigned_orders_count > 0 && (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography color="warning.main" gutterBottom>
                              <Person /> {actionItems.unassigned_orders_count}{" "}
                              Unassigned Orders
                            </Typography>
                            <Typography variant="body2">
                              These orders need to be assigned to an employee
                              for processing.
                            </Typography>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleAssignEmployees}
                              sx={{ mt: 1 }}
                            >
                              Assign Employees
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Performance Metrics Alerts */}
                    {Array.isArray(actionItems.performance_alerts) &&
                      actionItems.performance_alerts.length > 0 && (
                        <Grid item xs={12}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography color="warning.main" gutterBottom>
                                <TrendingDown /> Performance Alerts
                              </Typography>
                              <List dense>
                                {actionItems.performance_alerts.map(
                                  (alert, index) => (
                                    <ListItem key={`performance-${index}`}>
                                      <ListItemIcon>
                                        <ErrorOutline color="warning" />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={alert.message}
                                        secondary={`Current: ${alert.value}%, Target: ${alert.threshold}%`}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleViewPerformanceMetrics}
                              >
                                View Performance Metrics
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}

                    {/* Low Inventory Alerts */}
                    {actionItems.low_inventory_count > 0 && (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography color="warning.main" gutterBottom>
                              <Inventory /> Low Inventory Alert
                            </Typography>
                            <List dense>
                              {Array.isArray(actionItems.low_inventory_items) &&
                                actionItems.low_inventory_items.map((item) => (
                                  <ListItem key={item.item_id}>
                                    <ListItemIcon>
                                      <ErrorOutline color="warning" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={item.name}
                                      secondary={`Quantity: ${item.current_quantity}/${item.threshold}`}
                                    />
                                  </ListItem>
                                ))}
                            </List>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleManageInventory}
                            >
                              Manage Inventory
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Payment Method Warnings */}
                    {actionItems.needs_payment_method && (
                      <Grid item xs={12}>
                        <Card variant="outlined" className="warning-card">
                          <CardContent>
                            <Typography color="error" gutterBottom>
                              <Payment /> No Payment Methods Configured
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              You need to add a payment method to your business
                              account for platform fees and subscriptions.
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSetupPaymentMethods}
                              sx={{ mt: 1 }}
                            >
                              Add Payment Method
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Payment Method Expiring */}
                    {actionItems.expiring_payment_method && (
                      <Grid item xs={12}>
                        <Card variant="outlined" className="warning-card">
                          <CardContent>
                            <Typography color="warning.main" gutterBottom>
                              <CreditCard /> Payment Method Expiring Soon
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Your default payment method will expire soon.
                              Please update your card information.
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleUpdateExpiredCard}
                              sx={{ mt: 1 }}
                            >
                              Update Payment Method
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Shipping Rate Alerts */}
                    {Array.isArray(actionItems.shipping_rate_alerts) &&
                      actionItems.shipping_rate_alerts.length > 0 && (
                        <Grid item xs={12}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography color="warning.main" gutterBottom>
                                <TrendingUp /> Shipping Rate Changes
                              </Typography>
                              <List dense>
                                {actionItems.shipping_rate_alerts.map(
                                  (alert) => (
                                    <ListItem key={alert.tier_id}>
                                      <ListItemIcon>
                                        <ErrorOutline color="warning" />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={`${
                                          alert.tier_name
                                        } rates increased by ${alert.percent_change.toFixed(
                                          1
                                        )}%`}
                                        secondary={`From ${alert.previous_rate.toFixed(
                                          2
                                        )} to ${alert.current_rate.toFixed(2)}`}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleReviewRates}
                              >
                                Review Shipping Rates
                              </Button>
                            </CardContent>
                          </Card>
                        </Grid>
                      )}

                    {/* Pending Orders */}
                    {actionItems.pending_orders_count > 0 && (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography color="primary" gutterBottom>
                              <LocalShipping /> Pending Orders
                            </Typography>
                            <Typography variant="body2">
                              You have {actionItems.pending_orders_count} orders
                              pending action.
                            </Typography>
                            <List>
                              {Array.isArray(actionItems.pending_orders) &&
                              actionItems.pending_orders.length > 0 ? (
                                actionItems.pending_orders.map((order) => (
                                  <ListItem
                                    key={
                                      order.shipping_order_id ||
                                      order.id ||
                                      `pending-${Math.random()}`
                                    }
                                  >
                                    <ListItemIcon>
                                      <ErrorOutline color="warning" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={`Order #${
                                        order.tracking_number || "Pending"
                                      }`}
                                      secondary={`Created: ${new Date(
                                        order.createdAt || Date.now()
                                      ).toLocaleDateString()}`}
                                    />
                                  </ListItem>
                                ))
                              ) : (
                                <ListItem>
                                  <ListItemText primary="Loading order details..." />
                                </ListItem>
                              )}
                            </List>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleViewPendingOrders}
                            >
                              View All Pending Orders
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    {/* Recent Updates */}
                    {Array.isArray(actionItems.recent_updates) &&
                    actionItems.recent_updates.length > 0 ? (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography color="textPrimary" gutterBottom>
                              <Refresh /> Recent Updates
                            </Typography>
                            <List>
                              {actionItems.recent_updates.map((update) => (
                                <ListItem
                                  key={
                                    update.shipping_order_id ||
                                    update.id ||
                                    `update-${Math.random()}`
                                  }
                                >
                                  <ListItemIcon>
                                    <CheckCircleOutline color="success" />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={`Order #${
                                      update.tracking_number || "N/A"
                                    }`}
                                    secondary={`Status: ${
                                      update.order_status || "Unknown"
                                    } - ${new Date(
                                      update.updatedAt || Date.now()
                                    ).toLocaleString()}`}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}

                    {error && (
                      <Grid item xs={12}>
                        <Typography color="error" variant="body2">
                          {error}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Collapse>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessActionItems;
