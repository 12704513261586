import React from "react";
// Header and Footer are now provided by PublicLayout
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import ContactForm from "../../components/Form/ContactForm/ContactForm";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <div className="contact-page" id="wrapper">
        <div className="form-content">
          <h1>Contact Us</h1>
          <ContactForm />
        </div>
    </div>
  );
};

export default ContactPage;
