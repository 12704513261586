import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  Grid,
  CircularProgress,
  Alert,
  Typography,
  FormHelperText,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Pagination,
  TableSortLabel,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

// For Google Maps Autocomplete:
const libraries = ["places"];

// Days of the week for operating_hours.
// The backend uses { Monday: { is_open, shifts: [...] }, ... }
const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const LocationList = ({
  locations = [],
  onAddLocation,
  onUpdateLocation,
  onDeleteLocation,
  businessId,
}) => {
  const navigate = useNavigate();

  // Dialog states
  const [open, setOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);

  // Track which location is being edited or viewed
  const [editingLocation, setEditingLocation] = useState(null);
  const [viewLocation, setViewLocation] = useState(null);

  // Alerts, errors, loading
  const [alertMessage, setAlertMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Pagination & filtering
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    field: "business_address_name",
    direction: "asc",
  });
  const [filters, setFilters] = useState({
    type: "",
    isShippingLocation: "all",
  });

  // Google Maps
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [mapCenter, setMapCenter] = useState({ lat: 40.7128, lng: -74.006 }); // default
  const [markerPosition, setMarkerPosition] = useState(null);

  useEffect(() => {
    console.log("LocationList: ", locations);
  }, [locations]);

  // Default form data
  // Notice operating_hours now defaults to { day: { is_open, shifts: [{ open, close }] } }
  // with a single shift per day. This matches your backend structure more closely.
  const defaultFormData = {
    business_address_type: "",
    is_shipping_location: false,
    shipping_location_type: "",
    business_address_name: "",
    business_address_street_address_1: "",
    business_address_street_address_2: "",
    business_address_city: "",
    business_address_state_province: "",
    business_address_postal_code: "",
    business_address_country: "",
    business_address_phone: "",
    contact_email: "",
    shipping_status: "active",
    business_address_latitude: "",
    business_address_longitude: "",
    geocoding_provider: "",

    // Operating hours structure from the backend:
    operating_hours: {
      Monday: {
        is_open: false,
        shifts: [],
      },
      Tuesday: {
        is_open: false,
        shifts: [],
      },
      Wednesday: {
        is_open: false,
        shifts: [],
      },
      Thursday: {
        is_open: false,
        shifts: [],
      },
      Friday: {
        is_open: false,
        shifts: [],
      },
      Saturday: {
        is_open: false,
        shifts: [],
      },
      Sunday: {
        is_open: false,
        shifts: [],
      },
    },

    // capacity fields
    storage_capacity: "",
    capacity_unit: "sqft",
    daily_order_capacity: "",
    max_weight_capacity: "",
    weight_capacity_unit: "lb",
    max_dimensions: { length: "", width: "", height: "" },
    dimension_unit: "in",

    // features and metadata
    features: [],
    metadata: {
      customFields: [],
      tags: [],
    },
    routing_priority: 0,
  };
  const [formData, setFormData] = useState(defaultFormData);

  // Optional: open add form if custom event is emitted
  useEffect(() => {
    const handleOpenAddForm = (e) => {
      if (e.detail.type === "location") handleOpen();
    };
    document.addEventListener("openAddAssetForm", handleOpenAddForm);
    return () => {
      document.removeEventListener("openAddAssetForm", handleOpenAddForm);
    };
  }, []);

  // -------------------------------
  // Pagination, Search, Filters
  // -------------------------------
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  const handleSortChange = (field) => {
    setSortConfig((prev) => ({
      field,
      direction:
        prev.field === field && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const filteredLocations = React.useMemo(() => {
    let result = [...locations];

    // Search by name, address, city, etc.
    if (searchQuery) {
      const q = searchQuery.toLowerCase();
      result = result.filter((loc) =>
        [
          loc.business_address_name,
          loc.business_address_street_address_1,
          loc.business_address_city,
          loc.business_address_state_province,
          loc.business_address_country,
        ]
          .filter(Boolean)
          .some((value) => value.toLowerCase().includes(q))
      );
    }

    // Filter by location type
    if (filters.type) {
      result = result.filter(
        (loc) => loc.business_address_type === filters.type
      );
    }

    // Filter is_shipping_location
    if (filters.isShippingLocation !== "all") {
      const isShip = filters.isShippingLocation === "true";
      result = result.filter(
        (loc) => Boolean(loc.is_shipping_location) === isShip
      );
    }

    // Sort
    result.sort((a, b) => {
      let aVal = a[sortConfig.field] || "";
      let bVal = b[sortConfig.field] || "";
      if (typeof aVal === "string") aVal = aVal.toLowerCase();
      if (typeof bVal === "string") bVal = bVal.toLowerCase();

      if (sortConfig.direction === "asc") return aVal > bVal ? 1 : -1;
      return bVal > aVal ? 1 : -1;
    });

    return result;
  }, [locations, filters, searchQuery, sortConfig]);

  const paginatedLocations = React.useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return filteredLocations.slice(start, start + itemsPerPage);
  }, [filteredLocations, currentPage, itemsPerPage]);

  // --------------------
  // Dialog Handlers
  // --------------------
  const handleOpen = (location = null) => {
    if (!location) {
      // New
      setEditingLocation(null);
      setFormData(defaultFormData);
      setMarkerPosition(null);
    } else {
      // Editing existing
      setEditingLocation(location);

      // Convert the backend's operating_hours to a simpler front-end structure
      // We'll assume there is at most one shift per day for the UI below
      const transformedHours = {};
      DAYS_OF_WEEK.forEach((day) => {
        const backendDay = location.operating_hours?.[day];
        if (!backendDay) {
          transformedHours[day] = {
            is_open: false,
            shifts: [],
          };
        } else {
          const firstShift = backendDay.shifts && backendDay.shifts[0];
          transformedHours[day] = {
            is_open: backendDay.is_open,
            shifts: firstShift
              ? [{ open: firstShift.open, close: firstShift.close }]
              : [],
          };
        }
      });

      setFormData({
        business_address_type: location.business_address_type || "",
        is_shipping_location: location.is_shipping_location || false,
        shipping_location_type: location.shipping_location_type || "",
        business_address_name: location.business_address_name || "",
        business_address_street_address_1:
          location.business_address_street_address_1 || "",
        business_address_street_address_2:
          location.business_address_street_address_2 || "",
        business_address_city: location.business_address_city || "",
        business_address_state_province:
          location.business_address_state_province || "",
        business_address_postal_code:
          location.business_address_postal_code || "",
        business_address_country: location.business_address_country || "",
        business_address_phone: location.business_address_phone || "",
        contact_email: location.contact_email || "",
        shipping_status: location.shipping_status || "active",
        business_address_latitude: location.business_address_latitude || "",
        business_address_longitude: location.business_address_longitude || "",
        geocoding_provider: location.geocoding_provider || "",
        operating_hours: transformedHours,
        storage_capacity: location.storage_capacity ?? "",
        capacity_unit: location.capacity_unit || "sqft",
        daily_order_capacity: location.daily_order_capacity ?? "",
        max_weight_capacity: location.max_weight_capacity ?? "",
        weight_capacity_unit: location.weight_capacity_unit || "lb",
        max_dimensions: location.max_dimensions || {
          length: "",
          width: "",
          height: "",
        },
        dimension_unit: location.dimension_unit || "in",
        features: location.features || [],
        metadata: location.metadata || { customFields: [], tags: [] },
        routing_priority: location.routing_priority || 0,
      });

      // Marker position on the map
      if (
        location.business_address_latitude &&
        location.business_address_longitude
      ) {
        const lat = parseFloat(location.business_address_latitude);
        const lng = parseFloat(location.business_address_longitude);
        setMapCenter({ lat, lng });
        setMarkerPosition({ lat, lng });
      } else {
        setMarkerPosition(null);
      }
    }
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setEditingLocation(null);
    setErrors({});
  }, []);

  // View
  const handleView = (loc) => {
    setViewLocation(loc);
    setViewDialogOpen(true);
  };
  const handleCloseView = () => {
    setViewDialogOpen(false);
    setViewLocation(null);
  };

  // --------------
  // Deletion
  // --------------
  const handleDelete = async (addressId) => {
    if (window.confirm("Are you sure you want to delete this location?")) {
      setIsLoading(true);
      try {
        await onDeleteLocation(addressId);
        setAlertMessage({ type: "success", message: "Deleted successfully" });
      } catch (err) {
        setAlertMessage({
          type: "error",
          message: err.message || "Failed to delete",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  // --------------
  // Form Handling
  // --------------
  const handleChange = (e) => {
    const { name } = e.target;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // If toggling shipping
    if (name === "is_shipping_location") {
      value = !!value; // ensure bool
    }
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleDimensionsChange = (dimField, val) => {
    setFormData((prev) => ({
      ...prev,
      max_dimensions: {
        ...prev.max_dimensions,
        [dimField]: val,
      },
    }));
  };

  // --------------
  // Validation
  // --------------
  const validateForm = () => {
    const newErrors = {};

    if (!formData.business_address_type) {
      newErrors.business_address_type = "Required";
    }
    if (!formData.business_address_name) {
      newErrors.business_address_name = "Required";
    }
    if (!formData.business_address_street_address_1) {
      newErrors.business_address_street_address_1 = "Required";
    }
    if (!formData.business_address_city) {
      newErrors.business_address_city = "Required";
    }
    if (!formData.business_address_state_province) {
      newErrors.business_address_state_province = "Required";
    }
    if (!formData.business_address_postal_code) {
      newErrors.business_address_postal_code = "Required";
    }
    if (!formData.business_address_country) {
      newErrors.business_address_country = "Required";
    }
    if (!formData.business_address_phone) {
      newErrors.business_address_phone = "Required";
    } else if (!matchIsValidTel(formData.business_address_phone)) {
      newErrors.business_address_phone = "Invalid phone format";
    }
    if (
      formData.contact_email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.contact_email)
    ) {
      newErrors.contact_email = "Invalid email format";
    }

    if (formData.is_shipping_location && !formData.shipping_location_type) {
      newErrors.shipping_location_type =
        "Required if this is a shipping location";
    }

    // Operating hours
    // We only handle a single shift per day in the UI
    DAYS_OF_WEEK.forEach((day) => {
      const dayData = formData.operating_hours[day];
      if (dayData.is_open) {
        // must have a shift with open + close
        if (
          !dayData.shifts[0] ||
          !dayData.shifts[0].open ||
          !dayData.shifts[0].close
        ) {
          newErrors[
            `operating_hours_${day}`
          ] = `Open/close times required when day is open`;
        }
      }
    });

    // If shipping
    if (formData.is_shipping_location) {
      if (
        formData.storage_capacity &&
        isNaN(Number(formData.storage_capacity))
      ) {
        newErrors.storage_capacity = "Must be numeric";
      }
      if (
        formData.daily_order_capacity &&
        isNaN(Number(formData.daily_order_capacity))
      ) {
        newErrors.daily_order_capacity = "Must be numeric";
      }
      if (
        formData.max_weight_capacity &&
        isNaN(Number(formData.max_weight_capacity))
      ) {
        newErrors.max_weight_capacity = "Must be numeric";
      }
      // Dimensions
      const { length, width, height } = formData.max_dimensions;
      // If the user entered partial dimension data:
      const dimensionFields = [length, width, height].filter(Boolean);
      if (dimensionFields.length > 0 && dimensionFields.length < 3) {
        newErrors.max_dimensions = "Fill all dimensions or leave all blank";
      } else if (
        dimensionFields.length === 3 &&
        (isNaN(Number(length)) || isNaN(Number(width)) || isNaN(Number(height)))
      ) {
        newErrors.max_dimensions = "Dimensions must be numbers";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // --------------
  // Submit
  // --------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsLoading(true);

    try {
      // Transform operating_hours from the front-end single shift approach
      // to the backend format { day: { is_open, shifts: [{ open, close }] } }
      const transformedHours = {};
      DAYS_OF_WEEK.forEach((day) => {
        const dayData = formData.operating_hours[day];
        const is_open = !!dayData.is_open;
        let shifts = [];
        if (is_open && dayData.shifts?.[0]) {
          const openVal = dayData.shifts[0].open || "";
          const closeVal = dayData.shifts[0].close || "";
          shifts = [{ open: openVal, close: closeVal }];
        }
        transformedHours[day] = { is_open, shifts };
      });

      // Prepare the data for the backend
      const payload = {
        business_address_type: formData.business_address_type,
        is_shipping_location: formData.is_shipping_location,
        shipping_location_type: formData.is_shipping_location
          ? formData.shipping_location_type
          : null,
        business_address_name: formData.business_address_name,
        business_address_street_address_1:
          formData.business_address_street_address_1,
        business_address_street_address_2:
          formData.business_address_street_address_2,
        business_address_city: formData.business_address_city,
        business_address_state_province:
          formData.business_address_state_province,
        business_address_postal_code: formData.business_address_postal_code,
        business_address_country: formData.business_address_country,
        business_address_phone: formData.business_address_phone,
        contact_email: formData.contact_email,
        shipping_status: formData.shipping_status,
        business_address_latitude: markerPosition
          ? String(markerPosition.lat)
          : "",
        business_address_longitude: markerPosition
          ? String(markerPosition.lng)
          : "",
        geocoding_provider: formData.geocoding_provider || "",
        operating_hours: transformedHours,
        storage_capacity: formData.storage_capacity
          ? Number(formData.storage_capacity)
          : null,
        capacity_unit: formData.capacity_unit,
        daily_order_capacity: formData.daily_order_capacity
          ? Number(formData.daily_order_capacity)
          : null,
        max_weight_capacity: formData.max_weight_capacity
          ? Number(formData.max_weight_capacity)
          : null,
        weight_capacity_unit: formData.weight_capacity_unit,
        max_dimensions:
          formData.max_dimensions.length ||
          formData.max_dimensions.width ||
          formData.max_dimensions.height
            ? formData.max_dimensions
            : null,
        dimension_unit: formData.dimension_unit,
        features: formData.features,
        metadata: formData.metadata,
        routing_priority: Number(formData.routing_priority) || 0,
      };

      if (editingLocation) {
        // Updating
        await onUpdateLocation(editingLocation.business_address_id, payload);
        setAlertMessage({ type: "success", message: "Location updated!" });
      } else {
        // Adding
        await onAddLocation(payload);
        setAlertMessage({ type: "success", message: "Location added!" });
      }
      handleClose();
    } catch (err) {
      setAlertMessage({
        type: "error",
        message: err.message || "Failed to save location",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // --------------
  // Map
  // --------------
  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setMarkerPosition({ lat, lng });

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        const comp = results[0].address_components;
        const getComp = (types) =>
          comp.find((c) => c.types.some((t) => types.includes(t)))?.long_name ||
          "";

        const streetNum = getComp(["street_number"]);
        const route = getComp(["route"]);
        const address1 = [streetNum, route].filter(Boolean).join(" ");

        setFormData((prev) => ({
          ...prev,
          business_address_street_address_1: address1,
          business_address_city: getComp(["locality"]),
          business_address_state_province: getComp([
            "administrative_area_level_1",
          ]),
          business_address_postal_code: getComp(["postal_code"]),
          business_address_country: getComp(["country"]),
          business_address_latitude: String(lat),
          business_address_longitude: String(lng),
        }));
      }
    });
  };

  const handlePlaceSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setMapCenter(latLng);
      setMarkerPosition(latLng);

      const comp = results[0].address_components;
      const getComp = (types) =>
        comp.find((c) => c.types.some((t) => types.includes(t)))?.long_name ||
        "";

      const streetNum = getComp(["street_number"]);
      const route = getComp(["route"]);
      const address1 = [streetNum, route].filter(Boolean).join(" ");

      setFormData((prev) => ({
        ...prev,
        business_address_street_address_1: address1,
        business_address_city: getComp(["locality"]),
        business_address_state_province: getComp([
          "administrative_area_level_1",
        ]),
        business_address_postal_code: getComp(["postal_code"]),
        business_address_country: getComp(["country"]),
        business_address_latitude: String(latLng.lat),
        business_address_longitude: String(latLng.lng),
        geocoding_provider: "google",
      }));
    } catch (err) {
      setAlertMessage({ type: "error", message: "Failed to process address" });
    }
  };

  // --------------
  // View Dialog
  // --------------
  const LocationViewDialog = ({ location, open, onClose }) => {
    if (!location) return null;

    // We only show the first shift for demonstration
    const renderOperatingHours = () => {
      if (!location.operating_hours) return null;
      return DAYS_OF_WEEK.map((day) => {
        const dayData = location.operating_hours[day];
        if (!dayData) {
          return (
            <Typography variant="body2" key={day}>
              <strong>{day}:</strong> Closed
            </Typography>
          );
        }
        if (!dayData.is_open || !dayData.shifts?.length) {
          return (
            <Typography variant="body2" key={day}>
              <strong>{day}:</strong> Closed
            </Typography>
          );
        }
        const { open: openTime, close: closeTime } = dayData.shifts[0];
        return (
          <Typography variant="body2" key={day}>
            <strong>{day}:</strong> {openTime} - {closeTime}
          </Typography>
        );
      });
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>Location Details</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Type</Typography>
                <Typography variant="body1">
                  {location.business_address_type || "N/A"}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Name</Typography>
                <Typography variant="body1">
                  {location.business_address_name || ""}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Address</Typography>
                <Typography variant="body1">
                  {location.business_address_street_address_1}
                </Typography>
                {location.business_address_street_address_2 && (
                  <Typography variant="body1">
                    {location.business_address_street_address_2}
                  </Typography>
                )}
                <Typography variant="body1">
                  {location.business_address_city},{" "}
                  {location.business_address_state_province}{" "}
                  {location.business_address_postal_code}
                </Typography>
                <Typography variant="body1">
                  {location.business_address_country}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Contact</Typography>
                <Typography variant="body1">
                  Phone: {location.business_address_phone}
                </Typography>
                {location.contact_email && (
                  <Typography variant="body1">
                    Email: {location.contact_email}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Shipping Status</Typography>
                <Chip
                  label={location.shipping_status || "active"}
                  size="small"
                  color={
                    location.shipping_status === "inactive"
                      ? "default"
                      : location.shipping_status === "suspended"
                      ? "error"
                      : "success"
                  }
                />
                {location.is_shipping_location && (
                  <Box mt={1}>
                    <Chip
                      label="Shipping Location"
                      size="small"
                      variant="outlined"
                      color="secondary"
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Hours of Operation
                </Typography>
                <Box mt={1}>{renderOperatingHours()}</Box>
              </Grid>

              {/* Features */}
              {location.features && location.features.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Features
                  </Typography>
                  <Box
                    sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}
                  >
                    {location.features.map((feat) => (
                      <Chip
                        key={feat}
                        label={feat.replace(/_/g, " ")}
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Grid>
              )}

              {/* Metadata */}
              {location.metadata?.tags?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Tags
                  </Typography>
                  <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                    {location.metadata.tags.map((tag) => (
                      <Chip key={tag} label={tag} size="small" />
                    ))}
                  </Box>
                </Grid>
              )}

              {/* Map */}
              {location.business_address_latitude &&
                location.business_address_longitude && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" sx={{ mt: 1 }}>
                      Map
                    </Typography>
                    <Box sx={{ mt: 1, width: "100%", height: 200 }}>
                      {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={{ width: "100%", height: "100%" }}
                          center={{
                            lat: parseFloat(location.business_address_latitude),
                            lng: parseFloat(
                              location.business_address_longitude
                            ),
                          }}
                          zoom={14}
                        >
                          <Marker
                            position={{
                              lat: parseFloat(
                                location.business_address_latitude
                              ),
                              lng: parseFloat(
                                location.business_address_longitude
                              ),
                            }}
                          />
                        </GoogleMap>
                      ) : (
                        <CircularProgress />
                      )}
                    </Box>
                  </Grid>
                )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button
            onClick={() => {
              onClose();
              handleOpen(location);
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // -----------
  // Render actions in table
  // -----------
  const renderLocationActions = (loc) => (
    <>
      <IconButton onClick={() => handleView(loc)} size="small" title="View">
        <VisibilityIcon />
      </IconButton>
      <IconButton onClick={() => handleOpen(loc)} size="small" title="Edit">
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => handleDelete(loc.business_address_id)}
        size="small"
        title="Delete"
      >
        <DeleteIcon />
      </IconButton>
    </>
  );

  if (loadError) {
    return <Alert severity="error">Error loading Maps</Alert>;
  }

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertMessage.type}
          onClose={() => setAlertMessage(null)}
          sx={{ mb: 2 }}
        >
          {alertMessage.message}
        </Alert>
      )}

      <Card>
        <CardHeader title="Locations" />
        <CardContent>
          {/* Search + Filters */}
          <Grid container spacing={2} alignItems="center" mb={2}>
            {/* Search */}
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                label="Search"
                placeholder="Name, address..."
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      fontSize="small"
                      sx={{ mr: 1, color: "text.secondary" }}
                    />
                  ),
                }}
              />
            </Grid>

            {/* business_address_type filter */}
            <Grid item xs={12} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  label="Type"
                  value={filters.type}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="">All</MenuItem>
                  <ListSubheader>Business Address Types</ListSubheader>
                  <MenuItem value="primary">primary</MenuItem>
                  <MenuItem value="billing">billing</MenuItem>
                  <MenuItem value="administrative">administrative</MenuItem>
                  <MenuItem value="store">store</MenuItem>
                  <MenuItem value="service">service</MenuItem>
                  <MenuItem value="virtual">virtual</MenuItem>
                  <MenuItem value="customs">customs</MenuItem>
                  <MenuItem value="medical">medical</MenuItem>
                  <MenuItem value="other">other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Shipping filter */}
            <Grid item xs={12} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Shipping Location</InputLabel>
                <Select
                  name="isShippingLocation"
                  label="Shipping Location"
                  value={filters.isShippingLocation}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="true">Shipping Only</MenuItem>
                  <MenuItem value="false">Non-shipping</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Items per page */}
            <Grid item xs={12} md={3}>
              <FormControl size="small" fullWidth>
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Filter chips */}
          {(filters.type ||
            filters.isShippingLocation !== "all" ||
            searchQuery) && (
            <Box mb={2}>
              <Typography variant="body2" color="text.secondary" mb={1}>
                Active Filters:
              </Typography>
              <Box display="flex" gap={1} flexWrap="wrap">
                {filters.type && (
                  <Chip
                    label={`Type: ${filters.type}`}
                    onDelete={() => setFilters({ ...filters, type: "" })}
                    size="small"
                  />
                )}
                {filters.isShippingLocation !== "all" && (
                  <Chip
                    label={
                      filters.isShippingLocation === "true"
                        ? "Shipping only"
                        : "Non-shipping"
                    }
                    onDelete={() =>
                      setFilters({ ...filters, isShippingLocation: "all" })
                    }
                    size="small"
                  />
                )}
                {searchQuery && (
                  <Chip
                    label={`Search: ${searchQuery}`}
                    onDelete={() => setSearchQuery("")}
                    size="small"
                  />
                )}
                {(filters.type ||
                  filters.isShippingLocation !== "all" ||
                  searchQuery) && (
                  <Chip
                    label="Clear All"
                    onClick={() => {
                      setFilters({ type: "", isShippingLocation: "all" });
                      setSearchQuery("");
                    }}
                    size="small"
                    color="secondary"
                  />
                )}
              </Box>
            </Box>
          )}

          {/* Table */}
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={
                        sortConfig.field === "business_address_type"
                          ? sortConfig.direction
                          : false
                      }
                    >
                      <TableSortLabel
                        active={sortConfig.field === "business_address_type"}
                        direction={
                          sortConfig.field === "business_address_type"
                            ? sortConfig.direction
                            : "asc"
                        }
                        onClick={() =>
                          handleSortChange("business_address_type")
                        }
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        sortConfig.field === "business_address_name"
                          ? sortConfig.direction
                          : false
                      }
                    >
                      <TableSortLabel
                        active={sortConfig.field === "business_address_name"}
                        direction={sortConfig.direction}
                        onClick={() =>
                          handleSortChange("business_address_name")
                        }
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell
                      sortDirection={
                        sortConfig.field === "business_address_city"
                          ? sortConfig.direction
                          : false
                      }
                    >
                      <TableSortLabel
                        active={sortConfig.field === "business_address_city"}
                        direction={sortConfig.direction}
                        onClick={() =>
                          handleSortChange("business_address_city")
                        }
                      >
                        City
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedLocations.length > 0 ? (
                    paginatedLocations.map((loc) => (
                      <TableRow key={loc.business_address_id}>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            <Chip
                              label={loc.business_address_type || "N/A"}
                              size="small"
                              color="default"
                            />
                            {loc.is_shipping_location && (
                              <Tooltip title="Shipping Location">
                                <Chip
                                  label="Shipping"
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                />
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>{loc.business_address_name}</TableCell>
                        <TableCell>
                          {loc.business_address_street_address_1}
                        </TableCell>
                        <TableCell>{loc.business_address_city}</TableCell>
                        <TableCell>
                          {loc.business_address_state_province}
                        </TableCell>
                        <TableCell>{loc.business_address_country}</TableCell>
                        <TableCell>{loc.business_address_phone}</TableCell>
                        <TableCell>{renderLocationActions(loc)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No locations found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination */}
          {!!filteredLocations.length && (
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                count={Math.ceil(filteredLocations.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
              />
            </Box>
          )}

          {/* Add Button */}
          <Box mt={2} textAlign="right">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleOpen(null)}
            >
              Add Location
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingLocation ? "Edit Location" : "Add Location"}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              {/* Basic Info */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.business_address_type}>
                  <InputLabel>Address Type</InputLabel>
                  <Select
                    name="business_address_type"
                    label="Address Type"
                    value={formData.business_address_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="primary">primary</MenuItem>
                    <MenuItem value="billing">billing</MenuItem>
                    <MenuItem value="administrative">administrative</MenuItem>
                    <MenuItem value="store">store</MenuItem>
                    <MenuItem value="service">service</MenuItem>
                    <MenuItem value="virtual">virtual</MenuItem>
                    <MenuItem value="customs">customs</MenuItem>
                    <MenuItem value="medical">medical</MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </Select>
                  {errors.business_address_type && (
                    <FormHelperText>
                      {errors.business_address_type}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="business_address_name"
                  label="Location Name"
                  fullWidth
                  value={formData.business_address_name}
                  onChange={handleChange}
                  error={!!errors.business_address_name}
                  helperText={errors.business_address_name}
                />
              </Grid>

              {/* is_shipping_location */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.is_shipping_location}
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: "is_shipping_location",
                            value: e.target.checked,
                          },
                        })
                      }
                    />
                  }
                  label="Is Shipping Location?"
                />
              </Grid>

              {/* shipping_location_type if is_shipping_location */}
              {formData.is_shipping_location && (
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={!!errors.shipping_location_type}
                    sx={{ mb: 1 }}
                  >
                    <InputLabel>Shipping Location Type</InputLabel>
                    <Select
                      name="shipping_location_type"
                      value={formData.shipping_location_type}
                      onChange={handleChange}
                      label="Shipping Location Type"
                    >
                      <ListSubheader>Common Types</ListSubheader>
                      <MenuItem value="warehouse">warehouse</MenuItem>
                      <MenuItem value="distribution_center">
                        distribution_center
                      </MenuItem>
                      <MenuItem value="fulfillment_center">
                        fulfillment_center
                      </MenuItem>
                      <MenuItem value="dropoff_point">dropoff_point</MenuItem>
                      <MenuItem value="pickup_point">pickup_point</MenuItem>
                      <MenuItem value="locker">locker</MenuItem>
                      <MenuItem value="service_point">service_point</MenuItem>
                      <MenuItem value="store">store</MenuItem>
                      <ListSubheader>Less Common</ListSubheader>
                      <MenuItem value="crossdock_facility">
                        crossdock_facility
                      </MenuItem>
                      <MenuItem value="sorting_center">sorting_center</MenuItem>
                      <MenuItem value="cold_storage">cold_storage</MenuItem>
                      <MenuItem value="customs_warehouse">
                        customs_warehouse
                      </MenuItem>
                      <MenuItem value="bonded_warehouse">
                        bonded_warehouse
                      </MenuItem>
                      <MenuItem value="medical_storage">
                        medical_storage
                      </MenuItem>
                      <MenuItem value="lastmile_facility">
                        lastmile_facility
                      </MenuItem>
                      <MenuItem value="other">other</MenuItem>
                    </Select>
                    {errors.shipping_location_type && (
                      <FormHelperText>
                        {errors.shipping_location_type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              {/* Address (PlacesAutocomplete) */}
              <Grid item xs={12}>
                <PlacesAutocomplete
                  value={formData.business_address_street_address_1}
                  onChange={(val) =>
                    handleChange({
                      target: {
                        name: "business_address_street_address_1",
                        value: val,
                      },
                    })
                  }
                  onSelect={handlePlaceSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...getInputProps({
                          label: "Street Address",
                          name: "business_address_street_address_1",
                          fullWidth: true,
                          error: !!errors.business_address_street_address_1,
                          helperText: errors.business_address_street_address_1,
                        })}
                      />
                      {loading && <div>Loading...</div>}
                      {suggestions?.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            zIndex: 999,
                            width: "100%",
                          }}
                        >
                          {suggestions.map((sug) => (
                            <div
                              {...getSuggestionItemProps(sug)}
                              key={sug.placeId}
                              style={{
                                padding: 8,
                                cursor: "pointer",
                                background: sug.active ? "#f0f0f0" : "#fff",
                              }}
                            >
                              {sug.description}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>

              {/* Address line 2 */}
              <Grid item xs={12}>
                <TextField
                  label="Address Line 2"
                  name="business_address_street_address_2"
                  fullWidth
                  value={formData.business_address_street_address_2}
                  onChange={handleChange}
                />
              </Grid>

              {/* city, state, postal */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="City"
                  name="business_address_city"
                  fullWidth
                  value={formData.business_address_city}
                  onChange={handleChange}
                  error={!!errors.business_address_city}
                  helperText={errors.business_address_city}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="State/Province"
                  name="business_address_state_province"
                  fullWidth
                  value={formData.business_address_state_province}
                  onChange={handleChange}
                  error={!!errors.business_address_state_province}
                  helperText={errors.business_address_state_province}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Postal Code"
                  name="business_address_postal_code"
                  fullWidth
                  value={formData.business_address_postal_code}
                  onChange={handleChange}
                  error={!!errors.business_address_postal_code}
                  helperText={errors.business_address_postal_code}
                />
              </Grid>

              {/* country */}
              <Grid item xs={12}>
                <TextField
                  label="Country"
                  name="business_address_country"
                  fullWidth
                  value={formData.business_address_country}
                  onChange={handleChange}
                  error={!!errors.business_address_country}
                  helperText={errors.business_address_country}
                />
              </Grid>

              {/* phone, email */}
              <Grid item xs={12} sm={6}>
                <MuiTelInput
                  label="Phone"
                  name="business_address_phone"
                  fullWidth
                  value={formData.business_address_phone}
                  onChange={(val) =>
                    handleChange({
                      target: { name: "business_address_phone", value: val },
                    })
                  }
                  error={!!errors.business_address_phone}
                  helperText={errors.business_address_phone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="contact_email"
                  type="email"
                  fullWidth
                  value={formData.contact_email}
                  onChange={handleChange}
                  error={!!errors.contact_email}
                  helperText={errors.contact_email}
                />
              </Grid>

              {/* shipping status */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Shipping Status</InputLabel>
                  <Select
                    name="shipping_status"
                    label="Shipping Status"
                    value={formData.shipping_status}
                    onChange={handleChange}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                    <MenuItem value="suspended">Suspended</MenuItem>
                    <MenuItem value="seasonal">Seasonal</MenuItem>
                    <MenuItem value="under_construction">
                      Under Construction
                    </MenuItem>
                    <MenuItem value="coming_soon">Coming Soon</MenuItem>
                    <MenuItem value="maintenance">Under Maintenance</MenuItem>
                    <MenuItem value="disaster_recovery">
                      Disaster Recovery
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Map */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Map
                </Typography>
                {isLoaded ? (
                  <Box sx={{ height: 300, width: "100%", mb: 1 }}>
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      center={mapCenter}
                      zoom={12}
                      onClick={handleMapClick}
                    >
                      {markerPosition && <Marker position={markerPosition} />}
                    </GoogleMap>
                    <Typography variant="caption" color="text.secondary">
                      Click on the map to set location
                    </Typography>
                  </Box>
                ) : (
                  <CircularProgress />
                )}
              </Grid>

              {/* Shipping capacity if is_shipping_location */}
              {formData.is_shipping_location && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Capacity & Dimensions
                    </Typography>
                    <Alert severity="info" sx={{ mb: 2 }}>
                      Provide shipping capacity details
                    </Alert>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Storage Capacity"
                      name="storage_capacity"
                      type="number"
                      fullWidth
                      value={formData.storage_capacity}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <FormControl variant="standard">
                            <Select
                              value={formData.capacity_unit}
                              onChange={(e) =>
                                handleChange({
                                  target: {
                                    name: "capacity_unit",
                                    value: e.target.value,
                                  },
                                })
                              }
                            >
                              <MenuItem value="sqft">sq.ft</MenuItem>
                              <MenuItem value="sqm">sq.m</MenuItem>
                            </Select>
                          </FormControl>
                        ),
                      }}
                      error={!!errors.storage_capacity}
                      helperText={errors.storage_capacity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Daily Order Capacity"
                      name="daily_order_capacity"
                      type="number"
                      fullWidth
                      value={formData.daily_order_capacity}
                      onChange={handleChange}
                      error={!!errors.daily_order_capacity}
                      helperText={errors.daily_order_capacity}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Max Weight Capacity"
                      name="max_weight_capacity"
                      type="number"
                      fullWidth
                      value={formData.max_weight_capacity}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <FormControl variant="standard">
                            <Select
                              value={formData.weight_capacity_unit}
                              onChange={(e) =>
                                handleChange({
                                  target: {
                                    name: "weight_capacity_unit",
                                    value: e.target.value,
                                  },
                                })
                              }
                            >
                              <MenuItem value="lb">lb</MenuItem>
                              <MenuItem value="kg">kg</MenuItem>
                            </Select>
                          </FormControl>
                        ),
                      }}
                      error={!!errors.max_weight_capacity}
                      helperText={errors.max_weight_capacity}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">Max Dimensions</Typography>
                    <Box display="flex" gap={1}>
                      <TextField
                        label="L"
                        size="small"
                        type="number"
                        value={formData.max_dimensions.length}
                        onChange={(e) =>
                          handleDimensionsChange("length", e.target.value)
                        }
                      />
                      <TextField
                        label="W"
                        size="small"
                        type="number"
                        value={formData.max_dimensions.width}
                        onChange={(e) =>
                          handleDimensionsChange("width", e.target.value)
                        }
                      />
                      <TextField
                        label="H"
                        size="small"
                        type="number"
                        value={formData.max_dimensions.height}
                        onChange={(e) =>
                          handleDimensionsChange("height", e.target.value)
                        }
                      />
                      <FormControl variant="standard">
                        <Select
                          value={formData.dimension_unit}
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: "dimension_unit",
                                value: e.target.value,
                              },
                            })
                          }
                        >
                          <MenuItem value="in">in</MenuItem>
                          <MenuItem value="cm">cm</MenuItem>
                          <MenuItem value="m">m</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    {errors.max_dimensions && (
                      <FormHelperText error>
                        {errors.max_dimensions}
                      </FormHelperText>
                    )}
                  </Grid>

                  {/* Features */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Features & Services
                    </Typography>
                    <Alert severity="info" sx={{ mb: 2 }}>
                      Select facility features or services offered
                    </Alert>
                    {/* Example: You can add checkboxes for each possible feature
                        or any dynamic approach. For brevity, leaving it out. */}
                  </Grid>
                </>
              )}

              {/* Operating hours */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Hours of Operation
                </Typography>
                <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                  Set open/close times or mark closed. One shift per day here.
                </Alert>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {DAYS_OF_WEEK.map((day) => {
                  const dayData = formData.operating_hours[day];
                  const dayErrorKey = `operating_hours_${day}`;

                  // Parse time string (HH:MM) into a Date for MUI pickers:
                  const parseTime = (t) => {
                    if (!t) return null;
                    const [hh, mm] = t.split(":");
                    const d = new Date();
                    d.setHours(Number(hh));
                    d.setMinutes(Number(mm));
                    return d;
                  };

                  const shift = dayData.shifts[0] || { open: "", close: "" };

                  return (
                    <Grid item xs={12} key={day}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                          p: 1,
                          bgcolor: !dayData.is_open ? "grey.100" : "inherit",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={dayData.is_open}
                                  onChange={(ev) => {
                                    const updated = {
                                      ...formData.operating_hours,
                                      [day]: {
                                        ...dayData,
                                        is_open: ev.target.checked,
                                        shifts: ev.target.checked
                                          ? [{ open: "", close: "" }]
                                          : [],
                                      },
                                    };
                                    setFormData((prev) => ({
                                      ...prev,
                                      operating_hours: updated,
                                    }));
                                    setErrors((prev) => {
                                      const newE = { ...prev };
                                      delete newE[dayErrorKey];
                                      return newE;
                                    });
                                  }}
                                />
                              }
                              label={day}
                            />
                          </Grid>
                          {dayData.is_open && (
                            <>
                              <Grid item xs={12} sm={4}>
                                <DesktopTimePicker
                                  label="Open"
                                  value={parseTime(shift.open)}
                                  onChange={(val) => {
                                    if (!val) return;
                                    const hhmm = val
                                      .toTimeString()
                                      .split(" ")[0]
                                      .substring(0, 5);
                                    const updated = {
                                      ...formData.operating_hours,
                                      [day]: {
                                        ...dayData,
                                        is_open: true,
                                        shifts: [
                                          { open: hhmm, close: shift.close },
                                        ],
                                      },
                                    };
                                    setFormData((prev) => ({
                                      ...prev,
                                      operating_hours: updated,
                                    }));
                                    setErrors((prev) => {
                                      const newE = { ...prev };
                                      delete newE[dayErrorKey];
                                      return newE;
                                    });
                                  }}
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      size: "small",
                                      error: !!errors[dayErrorKey],
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <DesktopTimePicker
                                  label="Close"
                                  value={parseTime(shift.close)}
                                  onChange={(val) => {
                                    if (!val) return;
                                    const hhmm = val
                                      .toTimeString()
                                      .split(" ")[0]
                                      .substring(0, 5);
                                    const updated = {
                                      ...formData.operating_hours,
                                      [day]: {
                                        ...dayData,
                                        is_open: true,
                                        shifts: [
                                          { open: shift.open, close: hhmm },
                                        ],
                                      },
                                    };
                                    setFormData((prev) => ({
                                      ...prev,
                                      operating_hours: updated,
                                    }));
                                    setErrors((prev) => {
                                      const newE = { ...prev };
                                      delete newE[dayErrorKey];
                                      return newE;
                                    });
                                  }}
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      size: "small",
                                      error: !!errors[dayErrorKey],
                                    },
                                  }}
                                />
                              </Grid>
                              {errors[dayErrorKey] && (
                                <Grid item xs={12}>
                                  <Typography variant="caption" color="error">
                                    {errors[dayErrorKey]}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </LocalizationProvider>

              {/* Metadata */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Additional Info
                </Typography>
                <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                  Add custom fields or tags
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      const cf = [...(formData.metadata.customFields || [])];
                      cf.push({ key: "", value: "" });
                      setFormData((prev) => ({
                        ...prev,
                        metadata: { ...prev.metadata, customFields: cf },
                      }));
                    }}
                  >
                    Add Custom Field
                  </Button>
                </Box>
                {(formData.metadata.customFields || []).map((field, i) => (
                  <Box
                    key={i}
                    display="flex"
                    alignItems="center"
                    gap={1}
                    mb={1}
                  >
                    <TextField
                      label="Field Key"
                      size="small"
                      value={field.key}
                      onChange={(e) => {
                        const cf = [...formData.metadata.customFields];
                        cf[i].key = e.target.value;
                        setFormData((prev) => ({
                          ...prev,
                          metadata: { ...prev.metadata, customFields: cf },
                        }));
                      }}
                    />
                    <TextField
                      label="Field Value"
                      size="small"
                      value={field.value}
                      onChange={(e) => {
                        const cf = [...formData.metadata.customFields];
                        cf[i].value = e.target.value;
                        setFormData((prev) => ({
                          ...prev,
                          metadata: { ...prev.metadata, customFields: cf },
                        }));
                      }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => {
                        const cf = [...formData.metadata.customFields];
                        cf.splice(i, 1);
                        setFormData((prev) => ({
                          ...prev,
                          metadata: { ...prev.metadata, customFields: cf },
                        }));
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tags"
                  placeholder="Comma-separated"
                  value={(formData.metadata.tags || []).join(", ")}
                  onChange={(e) => {
                    const tagsArr = e.target.value
                      .split(",")
                      .map((t) => t.trim())
                      .filter(Boolean);
                    setFormData((prev) => ({
                      ...prev,
                      metadata: { ...prev.metadata, tags: tagsArr },
                    }));
                  }}
                />
              </Grid>

              {/* Routing priority, etc. */}
              <Grid item xs={12}>
                <TextField
                  label="Routing Priority"
                  name="routing_priority"
                  type="number"
                  fullWidth
                  value={formData.routing_priority}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : editingLocation ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* View Dialog */}
      <LocationViewDialog
        location={viewLocation}
        open={viewDialogOpen}
        onClose={handleCloseView}
      />
    </>
  );
};

export default LocationList;
