import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Divider,
} from "@mui/material";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { AuthContext } from "../Auth/AuthContext";
import { getStripePromise } from "../../utils/stripe";
import { useBusiness } from "../Auth/BusinessContext"; // Import business context

// Wrapper component with Stripe Elements
export const CollaborationPaymentDialog = ({
  open,
  onClose,
  collaboration,
  onPaymentComplete,
}) => {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { api } = useContext(AuthContext);
  const { currentBusiness, getBusinessById } = useBusiness(); // Get business context
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  // Get business from collaboration if available
  const businessId =
    collaboration?.originating_business_id ||
    (currentBusiness ? currentBusiness.business_id : null);

  // Fetch saved payment methods
  useEffect(() => {
    if (open && collaboration && businessId) {
      const fetchPaymentMethods = async () => {
        try {
          setLoading(true);
          // Use the correct business payment methods endpoint
          const response = await api.get(
            `/payments/business-payment-methods?businessId=${businessId}`
          );

          // Update to match the business payment methods response structure
          if (response.data) {
            setPaymentMethods(response.data.paymentMethods || []);

            // Set default payment method if available
            if (response.data.defaultPaymentMethodId) {
              setSelectedPaymentMethod(response.data.defaultPaymentMethodId);
            } else if (response.data.paymentMethods?.length > 0) {
              setSelectedPaymentMethod(response.data.paymentMethods[0].id);
            }
          }
        } catch (error) {
          console.error("Error fetching payment methods:", error);
          setError("Failed to load payment methods. Please try again.");
        } finally {
          setLoading(false);
        }
      };

      fetchPaymentMethods();
    }
  }, [open, collaboration, api, businessId]);

  const handleInitiatePayment = async () => {
    if (!selectedPaymentMethod) {
      setError("Please select a payment method.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await api.post(
        `/payments/collaboration/${collaboration.collaboration_id}/payment/initiate`,
        {
          amount: collaboration.payment_amount || collaboration.total_cost,
          currency: "USD",
          payment_method_id: selectedPaymentMethod,
        }
      );

      if (response.data.success) {
        setClientSecret(response.data.data.clientSecret);
        // Store payment ID for later confirmation
        const paymentId = response.data.data.paymentId;
        localStorage.setItem('currentPaymentId', paymentId);
      } else {
        setError(response.data.message || "Failed to initiate payment.");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setError(
        error.response?.data?.message ||
          "Failed to initiate payment. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  // Reset dialog state when closed
  useEffect(() => {
    if (!open) {
      setClientSecret("");
      setError(null);
      // Clear stored payment ID when dialog is closed
      localStorage.removeItem('currentPaymentId');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionProps={{
        onExited: () => {
          setClientSecret("");
          setError(null);
          setSelectedPaymentMethod("");
          localStorage.removeItem('currentPaymentId');
        },
      }}
    >
      <DialogTitle>Process Payment</DialogTitle>
      <DialogContent>
        {collaboration && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1">
              Payment for collaboration with{" "}
              {collaboration.CollaboratingBusiness?.business_name ||
                "Collaborating Business"}
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }}>
              Amount: $
              {parseFloat(
                collaboration.payment_amount || collaboration.total_cost || 0
              ).toFixed(2)}
            </Typography>
            <Divider sx={{ my: 2 }} />
          </Box>
        )}

        {!clientSecret ? (
          <>
            {loading ? (
              <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ mb: 3 }}>
                {paymentMethods.length > 0 ? (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="payment-method-label">
                      Payment Method
                    </InputLabel>
                    <Select
                      labelId="payment-method-label"
                      value={selectedPaymentMethod || ""}
                      onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                      label="Payment Method"
                    >
                      {paymentMethods.map((method) => (
                        <MenuItem key={method.id} value={method.id}>
                          {method.card?.brand?.toUpperCase() || "Card"} ••••{" "}
                          {method.card?.last4} (expires {method.card?.exp_month}
                          /{method.card?.exp_year})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    No saved payment methods found. Please add a payment method
                    in the Payment Methods tab.
                  </Alert>
                )}

                {error && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                  </Alert>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={!selectedPaymentMethod || loading}
                  onClick={handleInitiatePayment}
                >
                  Continue to Payment
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Elements stripe={getStripePromise()} options={{ clientSecret }}>
            <PaymentForm
              collaboration={collaboration}
              onPaymentComplete={onPaymentComplete}
              onClose={onClose}
              clientSecret={clientSecret}
              selectedPaymentMethod={selectedPaymentMethod}
            />
          </Elements>
        )}
      </DialogContent>

      {!clientSecret && (
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

// Inner form component with Stripe Elements
const PaymentForm = ({
  collaboration,
  onPaymentComplete,
  onClose,
  clientSecret,
  selectedPaymentMethod,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const { api } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Get the stored payment ID
      const paymentId = localStorage.getItem('currentPaymentId');
      if (!paymentId) {
        setError("Payment session expired or invalid. Please try again.");
        setLoading(false);
        return;
      }

      // Instead of using elements, directly confirm the payment with the client secret
      // since we already have the payment method selected
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedPaymentMethod, // Use the already selected payment method
      });

      if (result.error) {
        setError(result.error.message);
        setLoading(false);
        return;
      }

      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        // Use the stored payment ID from our database instead of the Stripe payment intent ID
        await confirmPaymentOnServer(paymentId);
      } else {
        setError("Payment failed with unknown error. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      setError(error.message || "Payment failed. Please try again.");
      setLoading(false);
    }
  };

  const confirmPaymentOnServer = async (paymentId) => {
    try {
      const response = await api.post(
        `/payments/collaboration/payment/${paymentId}/confirm`
      );

      if (response.data.success) {
        setPaymentSuccess(true);
        setPaymentId(response.data.data.paymentId);
        if (onPaymentComplete) onPaymentComplete(response.data.data);
      } else {
        setError("Failed to process payment. Please try again.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "Failed to confirm payment on server."
      );
    } finally {
      setLoading(false);
    }
  };

  if (paymentSuccess) {
    return (
      <Box textAlign="center" py={3}>
        <Typography variant="h5" color="primary" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="body1">
          Your payment has been processed successfully.
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Payment ID: {paymentId}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Using your saved payment method
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Your card will be charged ${parseFloat(
            collaboration?.payment_amount || collaboration?.total_cost || 0
          ).toFixed(2)}
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}

      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button onClick={onClose} disabled={loading} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !stripe}
        >
          {loading ? <CircularProgress size={24} /> : "Pay Now"}
        </Button>
      </Box>
    </form>
  );
};

export default CollaborationPaymentDialog;
