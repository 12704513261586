import React, { useState, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
  Alert,
  Paper,
  Chip,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useFlowContext } from "./FlowContext";

const getOrderPathsToNode = (nodeId, edges, orders) => {
  const orderPaths = new Map();

  // First add orders that start at this node
  orders.forEach((order) => {
    if (order.start_location_id === nodeId) {
      orderPaths.set(order.shipping_order_id, []);
    }
  });

  // Then find all orders that reach this node through edges
  edges.forEach((edge) => {
    if (edge.target === nodeId && edge.data?.orders) {
      edge.data.orders.forEach((orderId) => {
        const shippingOrderId =
          typeof orderId === "string" ? orderId : orderId.shipping_order_id;
        if (!orderPaths.has(shippingOrderId)) {
          orderPaths.set(shippingOrderId, []);
        }
        orderPaths.get(shippingOrderId).push(edge);
      });
    }
  });

  return orderPaths;
};

/* 
  LOCATION DETAILS
  Used to display info about a single node, 
  including consolidated node children or standard location details
*/
const LocationDetails = ({ node, edges, orders, getNodeLabel }) => {
  const { data } = node;
  if (!data) return null;

  const isConsolidated = data.isConsolidated;
  const isSharedDelivery = data.isSharedDelivery;
  const details = data.details || {};

  // Get order paths for this node
  const orderPaths = getOrderPathsToNode(node.id, edges, orders);

  function SharedChildDetails({ child }) {
    const { details } = child;
    const startLocationGroups = details.startLocationGroups || [];
    const childOrders = details.orders || [];

    return (
      <>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Shared Delivery Node ({childOrders.length} total orders)
        </Typography>

        {/* Show groups, addresses, etc. exactly like your normal shared node */}
        {startLocationGroups.map((group) => (
          <Accordion key={group.startLocationId}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                From {group.startLocationId} ({group.orders.length} orders)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {group.orders.map((o) => (
                <Box key={o.shipping_order_id} sx={{ mb: 1 }}>
                  <Typography variant="subtitle2">
                    Order: {o.shipping_order_id}
                  </Typography>
                  <Typography variant="body2">
                    Recipient: {o.recipient?.first_name}{" "}
                    {o.recipient?.last_name}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}

        {/* Possibly show address fields if you store them */}
        {details.address_line1 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Address
            </Typography>
            <Typography>
              {details.address_line1}
              {details.address_line2 && (
                <>
                  <br />
                  {details.address_line2}
                </>
              )}
              <br />
              {details.city}, {details.state || details.state_province}{" "}
              {details.postal_code}
            </Typography>
          </>
        )}
      </>
    );
  }

  function SingleDeliveryChildDetails({ child }) {
    const { details } = child;
    const order = details?.order;
    console.log("Shared Delivery Child Details", child);

    return (
      <>
        {/* Order Info */}
        <Typography variant="subtitle2">Order ID</Typography>
        <Typography gutterBottom>
          {order?.shipping_order_id || "(No order ID)"}
        </Typography>

        {/* Address */}
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Delivery Address
        </Typography>
        <Typography>
          {details?.address_line1}
          {details?.address_line2 && (
            <>
              <br />
              {details.address_line2}
            </>
          )}
          <br />
          {details?.city}, {details?.state || details?.state_province}{" "}
          {details?.postal_code}
        </Typography>

        {/* Recipient Info */}
        {order?.recipient && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Contact Information
            </Typography>
            {order.recipient.email && (
              <Typography variant="body2">
                Email: {order.recipient.email}
              </Typography>
            )}
            {order.recipient.phone && (
              <Typography variant="body2">
                Phone: {order.recipient.phone}
              </Typography>
            )}
            {order.recipient.notes && (
              <>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Notes
                </Typography>
                <Typography variant="body2">{order.recipient.notes}</Typography>
              </>
            )}
          </>
        )}

        {/* Delivery Instructions */}
        {details?.delivery_instructions && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Delivery Instructions
            </Typography>
            <Typography variant="body2">
              {details.delivery_instructions}
            </Typography>
          </>
        )}
      </>
    );
  }

  // Render order paths section
  const renderOrderPaths = () => (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Orders at this Location
      </Typography>
      {orderPaths.size > 0 ? (
        Array.from(orderPaths.entries()).map(([orderId, paths]) => (
          <Accordion key={orderId} sx={{ mt: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Order: {orderId}
                {paths.length === 0 && " (Starts here)"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle2">Path to Here:</Typography>
              {paths.length > 0 ? (
                paths.map((edge, index) => (
                  <Box key={edge.id} sx={{ ml: 2, mt: 1 }}>
                    <Typography variant="body2">
                      {index + 1}. {getNodeLabel(edge.source)} →{" "}
                      {getNodeLabel(edge.target)}
                      {edge.data?.courier && (
                        <Typography component="span" color="primary">
                          {" "}
                          (Courier: {edge.data.courier.employee_first_name})
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 2, mt: 1 }}
                >
                  This is the starting location for this order
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Alert severity="info" sx={{ mt: 1 }}>
          No orders start at or pass through this location
        </Alert>
      )}
    </Box>
  );

  // For shared delivery nodes
  if (isSharedDelivery) {
    const startLocationGroups = details.startLocationGroups || [];

    return (
      <>
        <Typography variant="h6" gutterBottom>
          Shared Delivery Location
        </Typography>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          {details.address_line1}
          {details.address_line2 && (
            <>
              <br />
              {details.address_line2}
            </>
          )}
          <br />
          {details.city}, {details.state || details.state_province}{" "}
          {details.postal_code}
        </Typography>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Delivery Groups
        </Typography>

        {startLocationGroups.map((group) => (
          <Accordion key={group.startLocationId} sx={{ mt: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                From{" "}
                {getNodeLabel?.(group.startLocationId) || group.startLocationId}{" "}
                ({group.orders.length} orders)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {group.orders.map((order) => (
                <Box key={order.shipping_order_id} sx={{ mb: 1 }}>
                  <Typography variant="subtitle2">
                    Order: {order.shipping_order_id}
                  </Typography>
                  <Typography variant="body2">
                    Recipient: {order.recipient.first_name}{" "}
                    {order.recipient.last_name}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}

        {typeof details.latitude === "number" &&
          typeof details.longitude === "number" && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Location
              </Typography>
              <Typography>
                {details.latitude}, {details.longitude}
              </Typography>
            </>
          )}
      </>
    );
  }

  // Consolidated node
  // 1) if consolidated =>
  if (isConsolidated && details.childNodes) {
    console.log("Total orders", details.childNodes);
    const totalOrderCount = details.childNodes.reduce((total, child) => {
      if (child.details?.isSharedDelivery) {
        console.log("Shared Delivery Child", child);
        // For shared delivery nodes, look at orders array directly
        return total + (child.details?.orders?.length || 0);
      } else {
        // Single delivery node - add 1 if it has an order
        return total + (child.details?.order ? 1 : 0);
      }
    }, 0);

    return (
      <>
        <Typography variant="h6" gutterBottom>
          Consolidated Delivery Location
        </Typography>

        {/* Show both the count of childNodes and total orders */}
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Contains {details.childNodes.length} Deliver
          {details.childNodes.length !== 1 ? "ies" : "y"} • {totalOrderCount}{" "}
          orders total
        </Typography>

        {details.childNodes.map((child) => {
          const isChildShared = !!child.details?.isSharedDelivery;
          console.log("Child", child);

          return (
            <Accordion key={child.id} sx={{ mt: 1 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {isChildShared
                    ? `Delivery for ${child.details?.orders[0]?.recipient?.first_name} ${child.details?.orders[0]?.recipient?.last_name}`
                    : child.details?.order?.recipient
                    ? `Delivery for ${child.details.order.recipient.first_name} ${child.details.order.recipient.last_name}`
                    : "Delivery for Unknown Recipient"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {isChildShared ? (
                  <SharedChildDetails child={child} />
                ) : (
                  <SingleDeliveryChildDetails child={child} />
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}

        {renderOrderPaths()}

        {/* If you want to show lat/lon center for the consolidated node: */}
        {typeof details.latitude === "number" &&
          typeof details.longitude === "number" && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Center Location
              </Typography>
              <Typography>
                {details.latitude}, {details.longitude}
              </Typography>
            </>
          )}
      </>
    );
  }

  // Non-consolidated node
  const isDelivery = data.type === "delivery";
  const isStart = data.isStart;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {isDelivery
          ? "Delivery Location"
          : isStart
          ? "Start Location"
          : "Shipping Location"}
      </Typography>
      {details.name && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Name
          </Typography>
          <Typography>{details.name}</Typography>
        </>
      )}
      {isDelivery && details.order && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Assigned Order
          </Typography>
          <Typography>{details.order.shipping_order_id}</Typography>
        </>
      )}
      {details.address_line1 && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Address
          </Typography>
          <Typography>
            {details.address_line1}
            {details.address_line2 && (
              <>
                <br />
                {details.address_line2}
              </>
            )}
            <br />
            {details.city}, {details.state || details.state_province}{" "}
            {details.postal_code}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {details.country}
          </Typography>
        </>
      )}
      {/* add recipient information */}
      {isDelivery && details.order.recipient && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Recipient
          </Typography>
          <Typography>
            {details.order.recipient.first_name}{" "}
            {details.order.recipient.last_name}
          </Typography>
          {/* email and or phone and notes */}
          {details.order.recipient.email && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Email
              </Typography>
              <Typography>{details.order.recipient.email}</Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Phone
              </Typography>
              <Typography>{details.order.recipient.phone}</Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Notes
              </Typography>
              <Typography>{details.order.recipient.notes}</Typography>
            </>
          )}
        </>
      )}

      {isDelivery && details.delivery_instructions && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Instructions
          </Typography>
          <Typography>{details.delivery_instructions}</Typography>
        </>
      )}
      {renderOrderPaths()}
      {/* Additional fields like contact_phone, operating_hours, etc. */}
    </>
  );
};

/* 
  EDGE DETAILS
  Used to display info about a single edge, 
  including courier assignment and order assignment
*/
const EdgeDetails = ({
  edge,
  orders = [],
  couriers = [],
  onCourierAssign,
  onOrderAssign,
  onOrderRemove,
  onEdgeDelete,
  getNodeLabel,
  nodes,
  edges,
}) => {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  // Get available orders for this edge using the same logic
  const getAvailableOrders = useCallback(() => {
    const availableOrders = new Set();
    const sourceId = edge.source;
    const sourceNode = nodes.find((n) => n.id === sourceId);

    // Get current edge's orders
    const currentEdgeOrders = new Set(edge.data.orders || []);

    if (!sourceNode || sourceNode.data.type === "delivery") {
      return [];
    }

    orders.forEach((order) => {
      // Skip if order is already assigned to this edge
      if (currentEdgeOrders.has(order.shipping_order_id)) {
        return;
      }

      // Case 1: Orders that start at this node
      if (order.start_location_id === sourceId) {
        if (
          !edges.some(
            (e) =>
              e.source === sourceId &&
              e.data.orders?.includes(order.shipping_order_id) &&
              e.id !== edge.id
          )
        ) {
          availableOrders.add(order.shipping_order_id);
        }
      }

      // Case 2: Orders that have incoming but no outgoing edges
      const hasIncoming = edges.some(
        (e) =>
          e.target === sourceId &&
          e.data.orders?.includes(order.shipping_order_id)
      );
      const hasOutgoing = edges.some(
        (e) =>
          e.source === sourceId &&
          e.data.orders?.includes(order.shipping_order_id) &&
          e.id !== edge.id
      );

      if (hasIncoming && !hasOutgoing) {
        availableOrders.add(order.shipping_order_id);
      }
    });

    return orders.filter((order) =>
      availableOrders.has(order.shipping_order_id)
    );
  }, [edge, nodes, edges, orders]);

  const availableOrders = useMemo(
    () => getAvailableOrders(),
    [getAvailableOrders]
  );

  const handleCourierChange = useCallback(
    (e) => {
      onCourierAssign?.(edge.id, e.target.value || null);
    },
    [edge, onCourierAssign]
  );

  // Add handler for removing orders
  const handleRemoveOrder = useCallback(
    (orderIdToRemove) => {
      onOrderRemove?.(edge.id, orderIdToRemove);
    },
    [edge, onOrderAssign]
  );

  if (!edge) return null;

  const { data = {}, source, target } = edge;
  const {
    courier,
    orders: edgeOrders = [],
    isDeliveryEdge,
    isConsolidated,
  } = data;

  const fromLabel = getNodeLabel ? getNodeLabel(source) : source;
  const toLabel = getNodeLabel ? getNodeLabel(target) : target;

  const renderOrderChip = (order) => {
    const orderId = typeof order === "string" ? order : order.shipping_order_id;
    return (
      <Chip
        key={orderId}
        label={orderId}
        size="small"
        sx={{ m: 0.5 }}
        onDelete={
          !isDeliveryEdge ? () => handleRemoveOrder(orderId) : undefined
        }
      />
    );
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {isConsolidated ? "Consolidated Connection" : "Connection"} Details
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 1 }}>
        From → To
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {fromLabel} → {toLabel}
      </Typography>

      {/* Courier Assignment */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Assign Courier
      </Typography>
      <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel>Courier</InputLabel>
        <Select
          value={courier?.employee_id || ""}
          onChange={handleCourierChange}
          label="Courier"
        >
          <MenuItem value="">(No Courier)</MenuItem>
          {couriers.map((c) => (
            <MenuItem key={c.employee_id} value={c.employee_id}>
              {c.employee_first_name} {c.employee_last_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {courier && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Courier: {courier.employee_first_name} {courier.employee_last_name}
        </Typography>
      )}

      {/* Orders Section */}
      <Box sx={{ mt: 2 }}>
        {isDeliveryEdge ? (
          <>
            <Alert severity="info">
              {isConsolidated
                ? "This is a consolidated connection. Orders are grouped together."
                : "This is a delivery connection. Orders are automatically assigned."}
            </Alert>
            {edgeOrders.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2">
                  {isConsolidated ? "Consolidated Orders:" : "Assigned Orders:"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    mt: 1,
                    maxHeight: "200px",
                    overflowY: "auto",
                    p: 1,
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: 1,
                  }}
                >
                  {edgeOrders.map((order) => {
                    // Handle both cases where order might be an ID string or an object
                    const orderId =
                      typeof order === "string"
                        ? order
                        : order.shipping_order_id;
                    return (
                      <Chip
                        key={orderId}
                        label={orderId}
                        size="small"
                        sx={{ m: 0.5 }}
                      />
                    );
                  })}
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  Total Orders: {edgeOrders.length}
                </Typography>
              </Box>
            )}
          </>
        ) : (
          // Regular edge order assignment UI remains the same
          <Box>
            <Alert severity="info">
              {isConsolidated
                ? "This is a consolidated connection. Orders are grouped together."
                : "This is a shipping connection. Assign orders to this connection."}
            </Alert>
            <Typography variant="subtitle2">Assign Orders</Typography>
            <Button
              variant="contained"
              sx={{ mt: 1 }}
              disabled={selectedOrderIds.length === 0}
              onClick={() => {
                onOrderAssign?.(edge.id, selectedOrderIds);
                setSelectedOrderIds([]);
              }}
            >
              Assign Selected Orders
            </Button>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Orders</InputLabel>
              <Select
                multiple
                value={selectedOrderIds}
                onChange={(event) => {
                  const value = event.target.value;
                  setSelectedOrderIds(
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                label="Orders"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </Box>
                )}
              >
                {availableOrders.map((order) => (
                  <MenuItem
                    key={order.shipping_order_id}
                    value={order.shipping_order_id}
                  >
                    <ListItemText primary={order.shipping_order_id} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                mt: 1,
                maxHeight: "200px",
                overflowY: "auto",
                p: 1,
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: 1,
              }}
            >
              {edgeOrders.map(renderOrderChip)}
            </Box>
          </Box>
        )}
      </Box>

      {/* Delete Edge Button */}
      <Box mt={2}>
        <Button
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="error"
          fullWidth
          onClick={() => onEdgeDelete?.(edge.id)}
        >
          Delete Connection
        </Button>
      </Box>
    </Box>
  );
};

/* 
  Default fallback if neither edge nor node is selected
*/
const DefaultView = () => (
  <Box>
    <Typography color="text.secondary" gutterBottom>
      Select a location or connection to view details
    </Typography>
    <Divider sx={{ my: 2 }} />
    <Typography variant="body2" color="text.secondary">
      Tips:
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      component="ul"
      sx={{ pl: 2 }}
    >
      <li>
        Start routes from <strong>Start Location (yellow)</strong>
      </li>
      <li>Click on locations to view details</li>
      <li>
        Click + drag between locations to create connections (in Edge mode)
      </li>
      <li>Select a connection to assign a courier or an order</li>
      <li>Use mouse wheel to zoom in/out on the map</li>
      <li>Click + drag to pan the map view</li>
    </Typography>
  </Box>
);

/* 
  Main BatchRouteSidebar
  Decides whether to show EdgeDetails, LocationDetails, or a default message.
*/
const BatchRouteSidebar = ({
  onCourierAssign,
  onOrderAssign,
  onOrderRemove,
  onEdgeDelete,
  getNodeLabel,
  validationErrors,
}) => {
  const {
    flowData: { nodes, edges },
    orders,
    couriers,
    selectedNode,
    selectedEdge,
  } = useFlowContext();

  let content = <DefaultView />;

  if (selectedEdge) {
    // 1) find the up-to-date version of this edge in flowData.edges
    const freshEdge = edges.find((e) => e.id === selectedEdge.id);

    // 2) Pass THAT to EdgeDetails
    if (freshEdge) {
      content = (
        <EdgeDetails
          edge={freshEdge}
          orders={orders}
          couriers={couriers}
          nodes={nodes}
          edges={edges}
          onCourierAssign={onCourierAssign}
          onOrderAssign={onOrderAssign}
          onOrderRemove={onOrderRemove}
          onEdgeDelete={onEdgeDelete}
          getNodeLabel={getNodeLabel}
        />
      );
    }
  } else if (selectedNode) {
    content = (
      <LocationDetails
        node={selectedNode}
        edges={edges}
        orders={orders}
        getNodeLabel={getNodeLabel}
      />
    );
  }
  return (
    <Paper
      sx={{
        width: 300,
        p: 2,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {content}

      {/* Example: show validation errors at the bottom */}
      {validationErrors?.length > 0 && (
        <Box mt={2}>
          <Alert severity="error">
            <Typography variant="subtitle2">Validation Errors:</Typography>
            <ul>
              {validationErrors.map((err, idx) => (
                <li key={idx}>{err}</li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </Paper>
  );
};

export default BatchRouteSidebar;
