/**
 * Utility functions for map coordinate transformation and node positioning
 */

// Constants for node spacing and scaling
export const MIN_NODE_SPACING = 150;
export const PADDING_RATIO = 0.15;
export const BASE_SCALE = 400;

/**
 * Calculates average geographical distance between markers
 */
export const calculateAverageDistance = (points) => {
  const n = points.length;
  if (n < 2) return 0;

  let totalDistance = 0;
  let count = 0;

  for (let i = 0; i < n; i++) {
    for (let j = i + 1; j < n; j++) {
      totalDistance += calculateHaversineDistance(
        points[i].lat,
        points[i].lng,
        points[j].lat,
        points[j].lng
      );
      count++;
    }
  }

  return count === 0 ? 0 : totalDistance / count;
};

/**
 * Creates a distance matrix between all points
 */
export const createDistanceMatrix = (points) => {
  const matrix = Array(points.length)
    .fill()
    .map(() => Array(points.length).fill(0));

  for (let i = 0; i < points.length; i++) {
    for (let j = 0; j < points.length; j++) {
      if (i !== j) {
        matrix[i][j] = calculateHaversineDistance(
          points[i].lat,
          points[i].lng,
          points[j].lat,
          points[j].lng
        );
      }
    }
  }

  return matrix;
};

/**
 * Calculates Haversine distance between two geographical points
 */
export const calculateHaversineDistance = (lat1, lon1, lat2, lon2) => {
  const toRad = (value) => (value * Math.PI) / 180;
  const R = 6371; // Earth's radius in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

/**
 * Normalizes distances to ensure minimum spacing between nodes
 */
export const normalizeDistances = (matrix, minDistance) => {
  const maxDist = Math.max(...matrix.flat());
  return matrix.map((row) =>
    row.map((dist) =>
      dist === 0 ? 0 : Math.max(minDistance, (dist / maxDist) * BASE_SCALE)
    )
  );
};

/**
 * Calculates optimal node positions using force-directed placement
 */
export const calculateNodePositions = (
  normalizedMatrix,
  width,
  height,
  padding
) => {
  const n = normalizedMatrix.length;
  const positions = [];
  const iterations = 100;
  const k = Math.sqrt((width * height) / n);

  // Initialize random positions
  for (let i = 0; i < n; i++) {
    positions[i] = {
      x: padding + Math.random() * (width - 2 * padding),
      y: padding + Math.random() * (height - 2 * padding),
    };
  }

  // Apply force-directed algorithm
  for (let iter = 0; iter < iterations; iter++) {
    // Calculate repulsive forces
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        if (i !== j) {
          const dx = positions[j].x - positions[i].x;
          const dy = positions[j].y - positions[i].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < MIN_NODE_SPACING) {
            const force = MIN_NODE_SPACING - distance;
            const angle = Math.atan2(dy, dx);
            positions[j].x += Math.cos(angle) * force * 0.5;
            positions[j].y += Math.sin(angle) * force * 0.5;
            positions[i].x -= Math.cos(angle) * force * 0.5;
            positions[i].y -= Math.sin(angle) * force * 0.5;
          }
        }
      }
    }

    // Keep nodes within bounds
    for (let i = 0; i < n; i++) {
      positions[i].x = clamp(positions[i].x, padding, width - padding);
      positions[i].y = clamp(positions[i].y, padding, height - padding);
    }
  }

  return positions;
};

/**
 * Transforms geographical coordinates to display coordinates
 */
export const transformCoordinates = (points, width, height) => {
  if (!points?.length) return { transformedPoints: [], scale: 1 };

  const lats = points.map((p) => p.lat);
  const lngs = points.map((p) => p.lng);
  const bounds = {
    minLat: Math.min(...lats),
    maxLat: Math.max(...lats),
    minLng: Math.min(...lngs),
    maxLng: Math.max(...lngs),
  };

  const center = {
    lat: (bounds.minLat + bounds.maxLat) / 2,
    lng: (bounds.minLng + bounds.maxLng) / 2,
  };

  const spans = {
    lat: Math.max(bounds.maxLat - bounds.minLat, 0.001),
    lng: Math.max(bounds.maxLng - bounds.minLng, 0.001),
  };

  const padding = Math.min(width, height) * PADDING_RATIO;
  const usableSpace = {
    width: width - 2 * padding,
    height: height - 2 * padding,
  };

  // Calculate and adjust scale
  const scale = Math.min(
    usableSpace.width / spans.lng,
    usableSpace.height / spans.lat
  );
  const adjustedScale = Math.max(
    scale,
    (MIN_NODE_SPACING * points.length) / Math.max(spans.lat, spans.lng)
  );

  // Transform points
  const transformedPoints = points.map((point) => ({
    ...point,
    x:
      width / 2 +
      ((point.lng - center.lng) / (spans.lng / 2)) * (usableSpace.width / 2),
    y:
      height / 2 -
      ((point.lat - center.lat) / (spans.lat / 2)) * (usableSpace.height / 2),
    originalLat: point.lat,
    originalLng: point.lng,
  }));

  // Adjust spacing
  return {
    transformedPoints: adjustSpacing(
      transformedPoints,
      MIN_NODE_SPACING,
      width,
      height,
      padding
    ),
    scale: adjustedScale,
    padding,
    width,
    height,
    center,
  };
};

/**
 * Adjusts node positions to maintain minimum spacing
 */
export const adjustSpacing = (points, minSpacing, width, height, padding) => {
  const adjusted = [...points];
  const maxIterations = 50;
  let iteration = 0;
  let hasOverlap = true;

  while (hasOverlap && iteration < maxIterations) {
    hasOverlap = false;

    for (let i = 0; i < adjusted.length; i++) {
      for (let j = i + 1; j < adjusted.length; j++) {
        const dx = adjusted[j].x - adjusted[i].x;
        const dy = adjusted[j].y - adjusted[i].y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < minSpacing) {
          hasOverlap = true;
          const angle = Math.atan2(dy, dx);
          const adjustment = (minSpacing - distance) / 2;

          // Move points apart while keeping within bounds
          ["x", "y"].forEach((coord, idx) => {
            const move = (idx === 0 ? Math.cos : Math.sin)(angle) * adjustment;
            adjusted[i][coord] = clamp(
              adjusted[i][coord] - move,
              padding,
              (idx === 0 ? width : height) - padding
            );
            adjusted[j][coord] = clamp(
              adjusted[j][coord] + move,
              padding,
              (idx === 0 ? width : height) - padding
            );
          });
        }
      }
    }

    iteration++;
  }

  return adjusted;
};

/**
 * Clamps a value between min and max
 */
const clamp = (value, min, max) => Math.max(min, Math.min(max, value));

/**
 * Calculates estimated travel duration between points
 */
export const calculateTravelDuration = (point1, point2) => {
  const AVERAGE_SPEED = 30; // km/h
  const distance = calculateHaversineDistance(
    point1.lat,
    point1.lng,
    point2.lat,
    point2.lng
  );
  return Math.round((distance / AVERAGE_SPEED) * 60); // Convert to minutes
};
