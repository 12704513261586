import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../../../components/Auth/AuthContext";

const CollaborationComponent = ({
  open,
  onClose,
  selectedOrders,
  businessId,
  onCollaborationUpdate,
}) => {
  const { api } = useContext(AuthContext);
  const [collaborators, setCollaborators] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [collaborationDetails, setCollaborationDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [collaborationResults, setCollaborationResults] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const theme = useTheme();

  const defaultProfilePicture = "/images/background-image.jpg";

  // Helper function to determine the correct profile picture URL
  const getProfilePicture = (url) => {
    if (!url || url === "null") {
      return defaultProfilePicture;
    }
    try {
      return new URL(url, process.env.REACT_APP_API_BASE_URL).href;
    } catch (error) {
      return defaultProfilePicture;
    }
  };

  useEffect(() => {
    setError(null);
    if (open && selectedOrders?.length > 0) {
      fetchCollaborators();
      setSelectedOrderIds(selectedOrders);
      setHasChanges(false);
    }
    if (!open) {
      setSelectedCollaborator(null);
      setSelectedTier(null);
      setCollaborationDetails("");
      setCollaborationResults(null);
      setError(null);
      setHasChanges(false);
    }
  }, [open, businessId, selectedOrders]);

  const renderTierListItem = (tier, provider) => {
    const isSelected = selectedTier && selectedTier.tier_id === tier.tier_id;

    return (
      <ListItem
        key={tier.tier_id}
        button
        onClick={() => {
          setSelectedCollaborator(provider);
          setSelectedTier(tier);
          setHasChanges(true);
        }}
        sx={{
          cursor: "pointer",
          borderRadius: 1,
          mb: 1,
          backgroundColor: isSelected
            ? `${theme.palette.primary.main}15`
            : "transparent",
          "&:hover": {
            backgroundColor: isSelected
              ? `${theme.palette.primary.main}25`
              : `${theme.palette.action.hover}`,
          },
          border: isSelected
            ? `1px solid ${theme.palette.primary.main}`
            : "1px solid transparent",
          transition: "all 0.2s ease-in-out",
        }}
      >
        <ListItemText
          primary={
            <Typography
              variant="subtitle1"
              sx={{
                color: isSelected ? theme.palette.primary.main : "inherit",
                fontWeight: isSelected ? 600 : 400,
              }}
            >
              {tier.tier_name}
            </Typography>
          }
          secondary={
            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  Service Level: {tier.service_level}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  Estimated Delivery: {tier.estimated_delivery_time.min}-
                  {tier.estimated_delivery_time.max}{" "}
                  {tier.estimated_delivery_time.unit}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  sx={{
                    color: isSelected
                      ? theme.palette.primary.main
                      : "text.secondary",
                    fontWeight: isSelected ? 600 : 400,
                  }}
                >
                  Price: $
                  {(
                    parseFloat(tier.base_price_flat_rate) +
                    parseFloat(tier.base_price_weight)
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </ListItem>
    );
  };

  const fetchCollaborators = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/shipping-collaboration/search-collaborators?business_id=${businessId}`,
        {
          params: {
            selectedOrders,
          },
        }
      );
      console.log("Collaborators response:", response.data);
      setCollaborators(response.data);
    } catch (err) {
      console.error("Error fetching collaborators:", err);
      setError("Failed to fetch collaborators. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setError(null);
      setCollaborationResults(null);

      const collaborationData = {
        business_id: businessId,
        shipping_order_ids: selectedOrderIds,
        // Use selectedCollaborator.business_id directly
        collaborating_business_id: selectedCollaborator.business_id,
        shipping_tier_id: selectedTier.tier_id,
        collaboration_type: "full_handling",
        collaboration_details: { notes: collaborationDetails },
        additional_charges: [],
        special_instructions: collaborationDetails,
      };

      const response = await api.post(
        "/shipping-collaboration/collaboration-request",
        collaborationData
      );

      setCollaborationResults(response.data);
      setHasChanges(true);

      if (onCollaborationUpdate) {
        await onCollaborationUpdate();
      }

      setTimeout(() => {
        onClose(true);
        setSelectedCollaborator(null);
        setSelectedTier(null);
        setCollaborationDetails("");
        setSelectedOrderIds([]);
        setCollaborationResults(null);
      }, 1500);
    } catch (err) {
      console.error("Error sending collaboration request:", err);
      setError("Failed to send collaboration request. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleOrderSelection = (orderId) => {
    setHasChanges(true);
    setSelectedOrderIds((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handleClose = () => {
    if (!submitting) {
      onClose(hasChanges);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Set Up Collaboration</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {collaborationResults && (
            <Grid item xs={12}>
              <Alert severity="success">
                Successfully created {collaborationResults.successful.length}{" "}
                collaborations.
                {collaborationResults.failed.length > 0 && (
                  <>
                    {" "}
                    {collaborationResults.failed.length} collaborations failed.
                  </>
                )}
              </Alert>
            </Grid>
          )}
          {loading ? (
            <Grid item xs={12} display="flex" justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Select Orders for Collaboration
                </Typography>
                <List>
                  {selectedOrders.map((orderId) => (
                    <ListItem key={orderId}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedOrderIds.includes(orderId)}
                            onChange={() => handleOrderSelection(orderId)}
                          />
                        }
                        label={`Order ID: ${orderId}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Select a Collaborator</Typography>
              </Grid>
              {collaborators
                .filter((provider) => provider) // filter out any undefined/null entries
                .map((provider) => (
                  <Grid item xs={12} key={provider.business_id}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <CardMedia
                              component="img"
                              height="140"
                              image={getProfilePicture(
                                provider?.business_profile_picture_url
                              )}
                              alt={provider?.business_name}
                              onError={(e) => {
                                e.target.src = defaultProfilePicture;
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="h6">
                              {provider?.business_name}
                            </Typography>
                            <Typography variant="body2">
                              Email: {provider?.business_email}
                            </Typography>
                            <Typography variant="body2">
                              Phone: {provider?.business_phone}
                            </Typography>
                            <Typography variant="body2">
                              Address:{" "}
                              {
                                provider.BusinessAddresses?.[0]
                                  ?.business_address_city
                              }
                              ,{" "}
                              {
                                provider.BusinessAddresses?.[0]
                                  ?.business_address_country
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="subtitle1" mt={2}>
                          Available Tiers:
                        </Typography>
                        <List>
                          {provider?.shippingTiers?.map((tier) =>
                            renderTierListItem(tier, provider)
                          )}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              {selectedCollaborator && selectedTier && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Collaboration Details"
                    multiline
                    rows={4}
                    value={collaborationDetails}
                    onChange={(e) => {
                      setCollaborationDetails(e.target.value);
                      setHasChanges(true);
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={
            !selectedCollaborator ||
            !selectedTier ||
            selectedOrderIds.length === 0 ||
            loading ||
            submitting
          }
          variant="contained"
        >
          {submitting ? (
            <>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              Sending Request...
            </>
          ) : (
            "Send Collaboration Request"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CollaborationComponent;
