import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
  Alert,
  AlertTitle,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Snackbar,
  Tab,
  Tabs,
  Chip,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  LocationOn as LocationIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
  CompareArrows as CompareArrowsIcon,
  Description as DescriptionIcon,
  History as HistoryIcon,
  Print as PrintIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import PackageDetails from "./PackageDetails";
import CustomerInfo from "./CustomerInfo";
import OrderLocationMap from "./OrderLocationMap";
import OrderTimeline from "./components/OrderTimeline"; // Timeline component for order history
import { useOrders } from "../OrdersContext";
import _, { min } from "lodash";
import OrderRouteBuilder from "./OrderRouteBuilder";
import AuditLogTimeline from "./AuditLogTimeline";
import OrderSummaryCard from "./OrderSummaryCard";
import PrintDialog from "./PrintDialog";

const STATUS_TRANSITIONS = {
  pending: ["processing", "cancelled"],
  processing: ["shipped", "cancelled"],
  shipped: ["out_for_delivery", "delivered", "cancelled"],
  out_for_delivery: ["delivered", "cancelled"],
  delivered: [], // Terminal state
  cancelled: [], // Terminal state
};

const OrderEditPage = () => {
  const navigate = useNavigate();
  const { businessId, orderId } = useParams();
  const [editedOrder, setEditedOrder] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [originalOrder, setOriginalOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    orderDetails: null,
    packages: null,
  });
  const [loadError, setLoadError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const location = useLocation();
  const topRef = useRef(null);
  const { orders, fetchSingleOrder, updateOrder, saveCustomerInfo } =
    useOrders();
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);

  const clearError = (section) => {
    setErrors((prev) => ({ ...prev, [section]: null }));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const loadOrder = async () => {
      try {
        setLoading(true);

        // First try to get the order from context
        const existingOrder = orders.find(
          (o) => o.shipping_order_id === orderId
        );

        if (existingOrder) {
          console.log("Found order in context:", existingOrder);
          setEditedOrder(existingOrder);
          setOriginalOrder(_.cloneDeep(existingOrder));
          setErrors({
            orderDetails: null,
            packages: null,
          });
        } else {
          // If not in context, fetch from backend
          console.log("Fetching order from backend");
          const orderData = await fetchSingleOrder(orderId);
          setEditedOrder(orderData);
          setOriginalOrder(_.cloneDeep(orderData));
          setErrors({
            orderDetails: null,
            packages: null,
          });
        }
      } catch (err) {
        console.error("Error loading order:", err);
        setLoadError("Failed to fetch order details: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      loadOrder();
    }
  }, [orderId, orders, fetchSingleOrder]);

  useEffect(() => {
    if (!loading && orderId) {
      const updatedOrder = orders.find((o) => o.shipping_order_id === orderId);
      if (updatedOrder && !_.isEqual(updatedOrder, editedOrder)) {
        console.log("Order updated in context, refreshing local state");
        setEditedOrder(updatedOrder);
        setOriginalOrder(_.cloneDeep(updatedOrder));
      }
    }
  }, [orders, orderId, loading]);

  useLayoutEffect(() => {
    console.log("useLayoutEffect triggered");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    console.log("Scroll position after force scroll:", window.pageYOffset);
  }, [location.pathname]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      [name]: checked,
    }));
  };

  // Update handleSaveOrderDetails
  const handleSaveOrderDetails = async () => {
    try {
      clearError("orderDetails");
      const orderData = {
        order_status: editedOrder.order_status,
        tracking_number: editedOrder.tracking_number,
        signature_required: editedOrder.signature_required,
        saturday_delivery: editedOrder.saturday_delivery,
        residential_delivery: editedOrder.residential_delivery,
        special_instructions: editedOrder.special_instructions,
        Packages: editedOrder.Packages, // Include existing packages
        business_id: businessId, // Make sure business_id is included
      };

      console.log("Saving order details:", orderData);

      // Update both edited and original order states with the response
      // setEditedOrder(orderData);
      setOriginalOrder(_.cloneDeep(orderData));

      // Update the order
      const updatedOrder = await updateOrder(orderId, orderData, businessId);

      setSaveSuccess(true);
    } catch (err) {
      console.error("Error saving order details:", err);
      setErrors((prev) => ({
        ...prev,
        orderDetails: "Failed to update order details: " + err.message,
      }));
    }
  };

  // Update the useEffect that watches for order changes
  useEffect(() => {
    if (!loading && orderId) {
      const updatedOrder = orders.find((o) => o.shipping_order_id === orderId);
      if (updatedOrder && !_.isEqual(updatedOrder, editedOrder)) {
        console.log("Order updated in context, refreshing local state");
        const newOrder = _.cloneDeep(updatedOrder);
        setEditedOrder(newOrder);
        setOriginalOrder(newOrder); // Update original order as well
      }
    }
  }, [orders, orderId, loading]);

  // Add validation helper
  const validateStatusTransition = (currentStatus, newStatus) => {
    const allowedTransitions = STATUS_TRANSITIONS[currentStatus] || [];
    return allowedTransitions.includes(newStatus);
  };

  // Update handleChange function
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "order_status") {
      if (!validateStatusTransition(editedOrder.order_status, value)) {
        setErrors((prev) => ({
          ...prev,
          orderDetails: `Invalid status transition from ${editedOrder.order_status} to ${value}`,
        }));
        return;
      }
      clearError("orderDetails");
    }

    setEditedOrder((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Update isOrderDetailsChanged to handle deep comparison
  const isOrderDetailsChanged = () => {
    const relevantFields = [
      "order_status",
      "tracking_number",
      "signature_required",
      "saturday_delivery",
      "residential_delivery",
      "special_instructions",
    ];

    // Safety check
    if (!editedOrder || !originalOrder) return false;

    const getFieldValue = (obj, field) => {
      const value = obj[field];
      if (value === undefined) return null;
      if (typeof value === "object") return _.cloneDeep(value);
      return value;
    };

    return relevantFields.some((field) => {
      const editedValue = getFieldValue(editedOrder, field);
      const originalValue = getFieldValue(originalOrder, field);
      return !_.isEqual(editedValue, originalValue);
    });
  };

  const handleResetOrderDetails = () => {
    setEditedOrder((prevOrder) => ({
      ...prevOrder,
      order_status: originalOrder.order_status,
      tracking_number: originalOrder.tracking_number,
      signature_required: originalOrder.signature_required,
      saturday_delivery: originalOrder.saturday_delivery,
      residential_delivery: originalOrder.residential_delivery,
      special_instructions: originalOrder.special_instructions,
    }));
  };

  // Check if this is a collaboration where we are the requester (originator)
  const isCollaborationOriginator = useMemo(() => {
    if (!editedOrder?.ShippingCollaborations?.length) return false;

    // Get the latest active collaboration
    const activeCollaboration = editedOrder.ShippingCollaborations.filter(
      (collab) =>
        ["proposed", "accepted", "counter_offered"].includes(collab.status)
    ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

    return activeCollaboration?.originating_business_id === businessId;
  }, [editedOrder, businessId]);

  // Determine if user has edit permissions
  const hasEditPermissions = useMemo(() => {
    // If there's no order data yet, default to false
    if (!editedOrder) return false;

    // If there's no collaboration, business owner can edit
    if (!editedOrder.ShippingCollaborations?.length) return true;

    // Get the latest active collaboration
    const activeCollaboration = editedOrder.ShippingCollaborations.filter(
      (collab) =>
        ["proposed", "accepted", "counter_offered"].includes(collab.status)
    ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0];

    if (!activeCollaboration) return true;

    // Only the originating business can edit
    return activeCollaboration.originating_business_id === businessId;
  }, [editedOrder, businessId]);

  // Check if this is a collaborative order but we're not the originator
  const isCollaboratorReadOnly = useMemo(() => {
    return (
      editedOrder?.ShippingCollaborations?.length > 0 &&
      !isCollaborationOriginator
    );
  }, [editedOrder, isCollaborationOriginator]);

  const renderRouteAssignment = () => (
    <Grid container spacing={3}>
      {isCollaborationOriginator ? (
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
            <Alert severity="info">
              <Typography variant="h6">Route Assignment Unavailable</Typography>
              <Typography>
                You requested collaboration for this order. The route assignment
                will be handled by the collaborating business.
              </Typography>
            </Alert>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              marginRight: 2,
              height: "80vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <OrderRouteBuilder
              orderId={orderId}
              onSubmit={() => {
                fetchSingleOrder(orderId);
              }}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );

  const renderAuditLog = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
          <AuditLogTimeline />
        </Paper>
      </Grid>
    </Grid>
  );

  const renderOrderForm = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="order_status"
                  value={editedOrder.order_status}
                  onChange={handleChange}
                  disabled={isCollaboratorReadOnly}
                >
                  {Object.keys(STATUS_TRANSITIONS).map((status) => (
                    <MenuItem
                      key={status}
                      value={status}
                      disabled={
                        !validateStatusTransition(
                          editedOrder.order_status,
                          status
                        ) || isCollaboratorReadOnly
                      }
                    >
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tracking Number"
                name="tracking_number"
                value={editedOrder.tracking_number || ""}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Signature Required</InputLabel>
                <Select
                  name="signature_required"
                  value={editedOrder.signature_required}
                  onChange={handleChange}
                  disabled={isCollaboratorReadOnly}
                >
                  <MenuItem value="no_signature">No Signature</MenuItem>
                  <MenuItem value="signature_required">
                    Signature Required
                  </MenuItem>
                  <MenuItem value="adult_signature">Adult Signature</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedOrder.saturday_delivery}
                    onChange={handleCheckboxChange}
                    name="saturday_delivery"
                    disabled={isCollaboratorReadOnly}
                  />
                }
                label="Saturday Delivery"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedOrder.residential_delivery}
                    onChange={handleCheckboxChange}
                    name="residential_delivery"
                    disabled={isCollaboratorReadOnly}
                  />
                }
                label="Residential Delivery"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Special Instructions"
                name="special_instructions"
                value={editedOrder.special_instructions || ""}
                onChange={handleChange}
                disabled={isCollaboratorReadOnly}
              />
            </Grid>
          </Grid>
          {errors.orderDetails && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
              onClose={() => clearError("orderDetails")}
            >
              {errors.orderDetails}
            </Alert>
          )}
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveOrderDetails}
              disabled={!isOrderDetailsChanged() || isCollaboratorReadOnly}
            >
              Save Order Details
            </Button>
            <Button
              variant="outlined"
              startIcon={<UndoIcon />}
              onClick={handleResetOrderDetails}
              disabled={!isOrderDetailsChanged() || isCollaboratorReadOnly}
            >
              Reset
            </Button>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
          <CustomerInfo
            customer={{
              ...editedOrder.sender,
              address_id: editedOrder.sender_address_id, // Pass the address ID from the order
            }}
            orderId={orderId}
            title="Sender Information"
            role="sender"
            businessId={businessId}
            isReadOnly={isCollaboratorReadOnly}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
          <CustomerInfo
            customer={{
              ...editedOrder.recipient,
              address_id: editedOrder.recipient_address_id, // Pass the address ID from the order
            }}
            orderId={orderId}
            role="recipient"
            title="Recipient Information"
            businessId={businessId}
            isReadOnly={isCollaboratorReadOnly}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
          <Typography variant="h6" gutterBottom>
            Packages
          </Typography>
          <PackageDetails isReadOnly={isCollaboratorReadOnly} />
          {errors.packages && (
            <Alert
              severity="error"
              sx={{ mt: 2 }}
              onClose={() => clearError("packages")}
            >
              <div style={{ whiteSpace: "pre-line" }}>{errors.packages}</div>
            </Alert>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <OrderSummaryCard
          order={editedOrder}
          packages={editedOrder?.Packages || []}
        />
      </Grid>
    </Grid>
  );

  const renderLocationTracking = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OrderLocationMap orderId={orderId} businessId={businessId} />
      </Grid>
      <Grid item xs={12}>
        <OrderTimeline orderId={orderId} businessId={businessId} />
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return renderOrderForm();
      case 1:
        return renderLocationTracking();
      case 2:
        return renderRouteAssignment();
      case 3:
        return renderAuditLog();
      default:
        return renderOrderForm();
    }
  };

  const renderSkeletonLoading = () => (
    <Box sx={{ p: 3 }}>
      <Skeleton variant="text" width="50%" height={40} />
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {[...Array(6)].map((_, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Skeleton variant="rectangular" height={56} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={100} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={120} height={36} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div className="business-page business-analytics-page">
      <main className="business-page-content">
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1" sx={{ ml: 2 }}>
            {loading
              ? "Loading..."
              : `${isCollaboratorReadOnly ? "View" : "Edit"} Order: ${
                  editedOrder?.shipping_order_id
                }`}
            {!loading && editedOrder?.ShippingCollaborations?.length > 0 && (
              <Box
                component="span"
                ml={2}
                display="inline-flex"
                alignItems="center"
              >
                <Chip
                  color="primary"
                  variant="outlined"
                  label={
                    isCollaborationOriginator
                      ? "Collaboration Requested"
                      : "Collaboration Received"
                  }
                  icon={<CompareArrowsIcon />}
                />
              </Box>
            )}
          </Typography>
        </Box>

        {/* Read-only banner for collaborating businesses */}
        {isCollaboratorReadOnly && !loading && (
          <Alert severity="info" sx={{ mb: 2 }} icon={<InfoIcon />}>
            <AlertTitle>Read-only mode</AlertTitle>
            You are viewing this order as a collaborating business. You can view
            all details but cannot make changes.
          </Alert>
        )}

        {loading ? (
          renderSkeletonLoading()
        ) : (
          <>
            {loadError && <Alert severity="error">{loadError}</Alert>}
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab
                label="Order Details"
                icon={<DescriptionIcon />}
                iconPosition="start"
              />
              <Tab
                label="Location Tracking"
                icon={<LocationIcon />}
                iconPosition="start"
              />
              <Tab
                label="Route Assignment"
                icon={<CompareArrowsIcon />}
                iconPosition="start"
              />
              <Tab
                label="Audit Log"
                icon={<HistoryIcon />}
                iconPosition="start"
              />
            </Tabs>

            <Divider sx={{ mb: 2 }} />
            {editedOrder ? (
              renderContent()
            ) : (
              <Alert severity="error">Order not found</Alert>
            )}
          </>
        )}
      </main>
      <Snackbar
        open={saveSuccess}
        autoHideDuration={2000}
        onClose={() => setSaveSuccess(false)}
        message="Changes saved successfully"
      />
    </div>
  );
};

export default OrderEditPage;
