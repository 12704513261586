import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";

const defaultProfilePicture = "/images/background-image.jpg";

const UserProfilePage = () => {
  const { user } = useContext(AuthContext);

  const profilePictureSrc = user?.user_profile_picture_url
    ? `${process.env.REACT_APP_API_BASE_URL}${user.user_profile_picture_url}`
    : defaultProfilePicture;

  return (
    <div className="user-page-wrapper">
      <div className="user-page-content-narrow">
        <h1 className="user-page-title">User Profile</h1>
        {user ? (
          <div className="profile-section">
            <div className="profile-picture">
              <img
                src={profilePictureSrc}
                alt="Profile"
                crossOrigin="anonymous"
                onError={(e) => {
                  console.error("Profile image load error:", e);
                  e.target.src = defaultProfilePicture;
                }}
              />
            </div>
            <div className="profile-details">
              <h2>{`${user.user_first_name} ${user.user_last_name}`}</h2>
              <p>Email: {user.user_email}</p>
              <p>Phone: {user.user_phone || "Not provided"}</p>
              <p>
                Member since: {new Date(user.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        ) : (
          <div className="user-loading">Loading...</div>
        )}

        <div className="user-grid">
          <Link to="/profile/edit" className="user-card interactive">
            <h3>Edit Profile</h3>
            <p>Update your information</p>
          </Link>
          <Link to="/profile/security" className="user-card interactive">
            <h3>Login & Security</h3>
            <p>Manage security settings</p>
          </Link>
          <Link to="/profile/payments" className="user-card interactive">
            <h3>Payments</h3>
            <p>Manage payment methods</p>
          </Link>
          <Link to="/profile/addresses" className="user-card interactive">
            <h3>Addresses</h3>
            <p>Manage your addresses</p>
          </Link>
          <Link to="/profile/businesses" className="user-card interactive">
            <h3>Business Account</h3>
            <p>Manage your business profile</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;