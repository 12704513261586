import React from "react";
// Header and Footer are now provided by PublicLayout
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { LocalShipping, Speed, Assessment } from "@mui/icons-material";
import "./ServicesPage.css";

const ServicesPage = () => {
  const services = [
    {
      title: "Smart Package Tracking",
      icon: <Assessment />,
      description:
        "Our advanced tracking solution provides real-time insights and predictive analytics, seamlessly connecting local commerce with digital transparency. This ensures that every transaction is monitored, empowering communities through enhanced trust and efficiency.",
    },
    {
      title: "Fleet Management",
      icon: <LocalShipping />,
      description:
        "Optimize your delivery operations while supporting local drivers and small logistics businesses. Our fleet management platform creates an interconnected network that drives efficiency, all while empowering existing economies rather than displacing them.",
    },
    {
      title: "Express Delivery",
      icon: <Speed />,
      description:
        "Our express delivery service bridges global demand with local capacity. Enjoy fast, reliable shipping that not only meets urgent needs but also strengthens community commerce and interconnectivity—empowering local markets to flourish.",
    },
  ];

  return (
    <div className="services-page" id="wrapper">
        <h1 className="services-title">Our Services</h1>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="services-card" key={index}>
              <div className="service-icon">{service.icon}</div>
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
    </div>
  );
};

export default ServicesPage;
