import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  LocalShipping as PickupIcon,
  FlightTakeoff as InTransitIcon,
  Home as DeliveredIcon,
  Assignment as LabelIcon,
  Cancel as CancelIcon,
  Visibility as ViewIcon,
  Receipt as InvoiceIcon,
  Undo as ReturnIcon,
  Refresh as ReprocessIcon,
  NoteAdd as AddNoteIcon,
  Email as EmailIcon,
  Print as PrintIcon,
  CheckCircle as CompleteIcon,
  Print,
} from "@mui/icons-material";
import OrderNotesComponent from "../../pages/business/shipping/ShipmentsPage/OrderNoteComponent";
import SendReceiptDialog from "../../pages/business/shipping/ShipmentsPage/SendReceiptComponent";
import PrintDialog from "../../pages/business/shipping/ShipmentsPage/OrderEditPage/PrintDialog";

const useOrderActions = (setOrders, onDeleteDraftOrder, onCompleteDraft) => {
  const navigate = useNavigate();
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handlePrint = (order) => {
    setSelectedOrder(order);
    setIsPrintDialogOpen(true);
  };

  const handleClosePrintDialog = () => {
    setIsPrintDialogOpen(false);
    setSelectedOrder(null);
  };

  const handleAddNote = (order) => {
    setSelectedOrder(order);
    setIsNotesDialogOpen(true);
  };

  const handleCloseNotesDialog = () => {
    setIsNotesDialogOpen(false);
    setSelectedOrder(null);
  };

  const handleSaveNotes = (updatedNotes) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.shipping_order_id === selectedOrder.shipping_order_id
          ? { ...order, notes: updatedNotes }
          : order
      )
    );
  };

  const updateOrderStatus = (orderId, newStatus) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.shipping_order_id === orderId
          ? { ...order, status: newStatus }
          : order
      )
    );
  };

  const handleUpdateLocation = (order) => {
    console.log("Updating location for order:", order.shipping_order_id);
    // Implement update location logic
  };

  const handleGenerateInvoice = (order) => {
    console.log("Generating invoice for order:", order.shipping_order_id);
    // Implement generate invoice logic
  };

  const handleProcessReturn = (order) => {
    console.log("Processing return for order:", order.shipping_order_id);
    // Implement process return logic
  };

  const handleSendEmailReceipt = (order) => {
    setSelectedOrder(order);
    setIsReceiptDialogOpen(true);
  };

  const handleCloseReceiptDialog = () => {
    setIsReceiptDialogOpen(false);
    setSelectedOrder(null);
  };

  const handleSendDeliveryNotification = (order) => {
    console.log(
      "Sending delivery notification for order:",
      order.shipping_order_id
    );
    // Implement send delivery notification logic
  };

  const handleSendMessageToSender = (order) => {
    console.log(
      "Sending message to sender for order:",
      order.shipping_order_id
    );
    // Implement send message to sender logic
  };

  const handleAction = (action, order) => {
    switch (action) {
      case "edit":
        navigate(`/edit-order/${order.shipping_order_id}`);
        break;
      case "delete":
        onDeleteDraftOrder(order.shipping_order_id);
        break;
      case "complete":
        onCompleteDraft(order.shipping_order_id);
        break;
      case "print":
        handlePrint(order);
        break;
      case "pickup":
        updateOrderStatus(order.shipping_order_id, "In Transit");
        break;
      case "updateLocation":
        handleUpdateLocation(order);
        break;
      case "outForDelivery":
        updateOrderStatus(order.shipping_order_id, "Out for Delivery");
        break;
      case "deliver":
        updateOrderStatus(order.shipping_order_id, "Delivered");
        break;
      case "cancel":
        updateOrderStatus(order.shipping_order_id, "Cancelled");
        break;
      case "reprocess":
        updateOrderStatus(order.shipping_order_id, "Awaiting Pickup");
        break;
      case "addNote":
        handleAddNote(order);
        break;
      case "view":
        navigate(`/view-order/${order.shipping_order_id}`);
        break;
      case "invoice":
        handleGenerateInvoice(order);
        break;
      case "return":
        handleProcessReturn(order);
        break;
      case "sendEmailReceipt":
        handleSendEmailReceipt(order);
        break;
      case "sendDeliveryNotification":
        handleSendDeliveryNotification(order);
        break;
      case "sendMessageToSender":
        handleSendMessageToSender(order);
        break;
      default:
        break;
    }
  };

  const getMenuItems = (order) => {
    const { status, shipping_order_id } = order;
    const isDraft = shipping_order_id.startsWith("draft-");

    if (isDraft) {
      return [
        { icon: <CompleteIcon />, label: "Complete", action: "complete" },
        { icon: <DeleteIcon />, label: "Delete", action: "delete" },
      ];
    }

    const commonItems = [
      { icon: <LabelIcon />, label: "Print", action: "print" },
      { icon: <AddNoteIcon />, label: "Add Note", action: "addNote" },
      {
        icon: <EmailIcon />,
        label: "Send Email Receipt",
        action: "sendEmailReceipt",
      },
    ];

    switch (status) {
      case "Awaiting Pickup":
        return [
          ...commonItems,
          { icon: <PickupIcon />, label: "Mark Picked Up", action: "pickup" },
          { icon: <EditIcon />, label: "Edit", action: "edit" },
          { icon: <CancelIcon />, label: "Cancel", action: "cancel" },
        ];
      case "In Transit":
        return [
          ...commonItems,
          {
            icon: <InTransitIcon />,
            label: "Update Location",
            action: "updateLocation",
          },
          {
            icon: <DeliveredIcon />,
            label: "Mark Out for Delivery",
            action: "outForDelivery",
          },
          {
            icon: <DeliveredIcon />,
            label: "Mark Delivered",
            action: "deliver",
          },
        ];
      case "Out for Delivery":
        return [
          ...commonItems,
          {
            icon: <InTransitIcon />,
            label: "Update Location",
            action: "updateLocation",
          },
          {
            icon: <DeliveredIcon />,
            label: "Mark Delivered",
            action: "deliver",
          },
          {
            icon: <EmailIcon />,
            label: "Send Delivery Notification",
            action: "sendDeliveryNotification",
          },
        ];
      case "Delivered":
        return [
          ...commonItems,
          { icon: <ViewIcon />, label: "View Details", action: "view" },
          {
            icon: <InvoiceIcon />,
            label: "Generate Invoice",
            action: "invoice",
          },
          { icon: <ReturnIcon />, label: "Process Return", action: "return" },
        ];
      case "Cancelled":
        return [
          ...commonItems,
          { icon: <ViewIcon />, label: "View Details", action: "view" },
          { icon: <ReprocessIcon />, label: "Reprocess", action: "reprocess" },
        ];
      default:
        return commonItems;
    }
  };

  return {
    handleAction,
    getMenuItems,
    isPrintDialogOpen,
    isNotesDialogOpen,
    isReceiptDialogOpen,
    selectedOrder,
    handleClosePrintDialog,
    handleCloseNotesDialog,
    handleCloseReceiptDialog,
    handleSaveNotes,
  };
};

const OrderActionMenu = ({
  order,
  onAction,
  onCompleteDraft,
  onDeleteDraftOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    getMenuItems,
    handleAction,
    isPrintDialogOpen,
    isNotesDialogOpen,
    isReceiptDialogOpen,
    selectedOrder,
    handleClosePrintDialog,
    handleCloseNotesDialog,
    handleCloseReceiptDialog,
    handleSaveNotes,
  } = useOrderActions(() => {}, onDeleteDraftOrder, onCompleteDraft);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleAction(action, order);
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {getMenuItems(order).map((item) => (
          <MenuItem
            key={item.action}
            onClick={() => handleMenuItemClick(item.action)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
      {/* New Print Options Dialog */}
      {selectedOrder && (
        <PrintDialog
          orderData={selectedOrder}
          open={isPrintDialogOpen} // This is correct
          onClose={handleClosePrintDialog}
        />
      )}
      {selectedOrder && (
        <OrderNotesComponent
          order={selectedOrder}
          isOpen={isNotesDialogOpen}
          onClose={handleCloseNotesDialog}
          onSave={handleSaveNotes}
        />
      )}
      {selectedOrder && (
        <SendReceiptDialog
          order={selectedOrder}
          isOpen={isReceiptDialogOpen}
          onClose={handleCloseReceiptDialog}
        />
      )}
    </>
  );
};

export { useOrderActions, OrderActionMenu };
