import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useEmployeePermissions } from "../../../components/Auth/EmployeePermissions";
import { EmployeePageLayout } from "../../../components/Employee/PageComponents";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Avatar,
  Divider,
  Button,
  Chip,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  LocalShipping as ShippingIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

// Generate random avatar colors
const getAvatarColor = (name) => {
  const colors = [
    "#1976d2",
    "#388e3c",
    "#f57c00",
    "#d32f2f",
    "#7b1fa2",
    "#c2185b",
    "#0097a7",
    "#fbc02d",
  ];
  const charCode = name?.charCodeAt(0) || 0;
  return colors[charCode % colors.length];
};

const EmployeeCustomerDetailPage = () => {
  const { api } = useContext(AuthContext);
  const { businessId, customerId } = useParams();
  const navigate = useNavigate();
  const {
    dashboardData,
    loading: layoutLoading,
    error: layoutError,
  } = useOutletContext();
  const permissions = useEmployeePermissions();

  const [customer, setCustomer] = useState(null);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        setLoading(true);

        // Fetch customer details
        const customerResponse = await api.get(
          `/employee-portal/customers/${businessId}/${customerId}`
        );
        setCustomer(customerResponse.data);

        // Fetch customer orders
        const ordersResponse = await api.get(
          `/employee-portal/customers/${businessId}/${customerId}/orders`
        );
        setCustomerOrders(ordersResponse.data.orders || []);

        setError(null);
      } catch (err) {
        console.error("Error fetching customer data:", err);
        setError(
          err.response?.data?.error || "Failed to load customer details"
        );
      } finally {
        setLoading(false);
      }
    };

    if (businessId && customerId) {
      fetchCustomerData();
    }
  }, [api, businessId, customerId]);

  const handleEmailCustomer = () => {
    window.open(`mailto:${customer?.customer_email}`);
  };

  const handleCallCustomer = () => {
    window.open(`tel:${customer?.customer_phone}`);
  };

  const handleEditCustomer = () => {
    navigate(`/employee/${businessId}/customers/${customerId}/edit`);
  };

  const handleGoBack = () => {
    navigate(`/employee/${businessId}/customers`);
  };

  const handleViewOrder = (orderId) => {
    navigate(`/employee/${businessId}/orders/${orderId}`);
  };

  // If the employee doesn't have permission to manage customers
  if (!permissions.canManageCustomers) {
    return (
      <EmployeePageLayout title="Customer Details" maxWidth="standard">
        <Alert severity="warning">
          You don't have permission to view customer details.
        </Alert>
      </EmployeePageLayout>
    );
  }

  // If loading
  if (loading || layoutLoading) {
    return (
      <EmployeePageLayout title="Customer Details" maxWidth="standard">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
        >
          <CircularProgress />
        </Box>
      </EmployeePageLayout>
    );
  }

  // If error
  if (error || layoutError) {
    return (
      <EmployeePageLayout
        title="Customer Details"
        maxWidth="standard"
        error={error || layoutError}
      >
        <Box mt={2}>
          <Button
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            onClick={handleGoBack}
          >
            Back to Customers
          </Button>
        </Box>
      </EmployeePageLayout>
    );
  }

  return (
    <EmployeePageLayout
      title={`Customer: ${customer?.first_name} ${customer?.last_name}`}
      maxWidth="standard"
    >
      <Box mb={3}>
        <Button
          startIcon={<ArrowBackIcon />}
          variant="outlined"
          onClick={handleGoBack}
        >
          Back to Customers
        </Button>
      </Box>

      <Grid container spacing={3}>
        {/* Customer Profile Card */}
        <Grid item xs={12} md={4}>
          <Paper elevation={2} sx={{ p: 3, borderRadius: "12px" }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2}
            >
              <Avatar
                sx={{
                  bgcolor: getAvatarColor(
                    `${customer?.first_name} ${customer?.last_name}`
                  ),
                  width: 80,
                  height: 80,
                  fontSize: "2rem",
                  mb: 2,
                }}
              >
                {customer?.first_name?.charAt(0) || ""}
                {customer?.last_name?.charAt(0) || ""}
              </Avatar>

              <Typography variant="h5" gutterBottom>
                {customer?.first_name} {customer?.last_name}
              </Typography>

              <Chip
                label={customer?.customer_status || "New"}
                color={
                  customer?.customer_status === "active" ? "success" : "default"
                }
                size="small"
                sx={{ mb: 2 }}
              />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <List dense>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Email"
                  secondary={customer?.customer_email || "Not provided"}
                />
                <IconButton
                  edge="end"
                  onClick={handleEmailCustomer}
                  disabled={!customer?.customer_email}
                >
                  <EmailIcon fontSize="small" />
                </IconButton>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Phone"
                  secondary={customer?.customer_phone || "Not provided"}
                />
                <IconButton
                  edge="end"
                  onClick={handleCallCustomer}
                  disabled={!customer?.customer_phone}
                >
                  <PhoneIcon fontSize="small" />
                </IconButton>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ShippingIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Total Orders"
                  secondary={customerOrders.length}
                />
              </ListItem>
            </List>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="center" mt={2}>
              {permissions.canEdit && (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={handleEditCustomer}
                  fullWidth
                >
                  Edit Customer
                </Button>
              )}
            </Box>
          </Paper>
        </Grid>

        {/* Customer Addresses */}
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ p: 3, borderRadius: "12px", mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Customer Addresses
            </Typography>

            <Divider sx={{ mb: 2 }} />

            {customer?.Addresses && customer.Addresses.length > 0 ? (
              <Grid container spacing={2}>
                {customer.Addresses.map((address) => (
                  <Grid item xs={12} md={6} key={address.address_id}>
                    <Paper
                      elevation={1}
                      sx={{
                        p: 2,
                        borderRadius: "8px",
                        border: address.is_default
                          ? "2px solid #3498db"
                          : "none",
                      }}
                    >
                      {address.is_default && (
                        <Chip
                          label="Default"
                          color="primary"
                          size="small"
                          sx={{ mb: 1 }}
                        />
                      )}

                      <Box display="flex" alignItems="flex-start">
                        <LocationIcon sx={{ mr: 1, mt: 0.5 }} color="action" />
                        <Box>
                          <Typography variant="body2">
                            {address.street_address}
                            {address.street_address_2 &&
                              `, ${address.street_address_2}`}
                          </Typography>
                          <Typography variant="body2">
                            {address.city}, {address.state}{" "}
                            {address.postal_code}
                          </Typography>
                          <Typography variant="body2">
                            {address.country}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography color="textSecondary">
                No addresses found for this customer.
              </Typography>
            )}
          </Paper>

          {/* Customer Orders */}
          <Paper elevation={2} sx={{ p: 3, borderRadius: "12px" }}>
            <Typography variant="h6" gutterBottom>
              Recent Orders
            </Typography>

            <Divider sx={{ mb: 2 }} />

            {customerOrders && customerOrders.length > 0 ? (
              <Box>
                {customerOrders.slice(0, 5).map((order) => (
                  <Box
                    key={order.order_id}
                    sx={{
                      p: 2,
                      mb: 2,
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                    onClick={() => handleViewOrder(order.order_id)}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2}>
                        <ShippingIcon fontSize="large" color="primary" />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Order #{order.order_id.substring(0, 8)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {new Date(order.created_at).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} textAlign="right">
                        <Chip
                          label={order.status}
                          color={
                            order.status === "delivered"
                              ? "success"
                              : order.status === "shipped"
                              ? "primary"
                              : order.status === "cancelled"
                              ? "error"
                              : "default"
                          }
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                {customerOrders.length > 5 && (
                  <Box textAlign="center" mt={2}>
                    <Button
                      variant="text"
                      onClick={() =>
                        navigate(
                          `/employee/${businessId}/orders?customer=${customerId}`
                        )
                      }
                    >
                      View All Orders ({customerOrders.length})
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Typography color="textSecondary">
                No orders found for this customer.
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </EmployeePageLayout>
  );
};

export default EmployeeCustomerDetailPage;
