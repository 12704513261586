import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Tabs,
  Tab,
  Paper,
  Alert,
  Button,
  Box,
  CircularProgress,
  Skeleton,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import {
  Refresh as RefreshIcon,
  AddCircle as AddIcon,
  NoteAdd as NoteAddIcon,
  Person as PersonIcon,
  DirectionsCar as CarIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";
import { useAssets } from "./AssetsContext";
import AssetsDashboardOverview from "./AssetsDashboardOverview";
import CourierList from "./CourierList";
import VehicleList from "./VehicleList";
import LocationList from "./LocationList";

const AssetsContent = () => {
  const [activeTab, setActiveTab] = useState(() => {
    // Initialize activeTab from localStorage, default to 0 if not found
    return parseInt(localStorage.getItem("assetsActiveTab") || "0", 10);
  });
  const [alertMessage, setAlertMessage] = useState(null);
  const { businessId } = useParams();
  const contentRef = useRef(null);
  const {
    couriers,
    vehicles,
    locations,
    orders,
    groups,
    loading,
    error,
    fetchCouriers,
    fetchVehicles,
    fetchLocations,
    fetchOrders,
    fetchGroups,
    addCourier,
    updateCourier,
    deleteCourier,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    unassignVehicle,
    createVehicleAssignment,
    addLocation,
    updateLocation,
    deleteLocation,
    assignOrder,
    assignOrderToCourier,
  } = useAssets();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    fetchCouriers(businessId);
    fetchVehicles(businessId);
    fetchLocations(businessId);
    fetchOrders(businessId);
    fetchGroups(businessId);
  }, [
    businessId,
    fetchCouriers,
    fetchVehicles,
    fetchLocations,
    fetchOrders,
    fetchGroups,
  ]);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
    // Store the new active tab in localStorage
    localStorage.setItem("assetsActiveTab", newValue.toString());
  }, []);

  const handleRefresh = useCallback(() => {
    fetchCouriers(businessId);
    fetchVehicles(businessId);
    fetchLocations(businessId);
    fetchOrders(businessId);
  }, [businessId, fetchCouriers, fetchVehicles, fetchLocations, fetchOrders]);

  // Get the appropriate button text and icon based on the active tab
  const getAddButtonProps = () => {
    switch (activeTab) {
      case 0: // Couriers
        return {
          text: "Add Courier",
          icon: <PersonIcon />,
          action: () =>
            document.dispatchEvent(
              new CustomEvent("openAddAssetForm", {
                detail: { type: "courier" },
              })
            ),
        };
      case 1: // Vehicles
        return {
          text: "Add Vehicle",
          icon: <CarIcon />,
          action: () =>
            document.dispatchEvent(
              new CustomEvent("openAddAssetForm", {
                detail: { type: "vehicle" },
              })
            ),
        };
      case 2: // Locations
        return {
          text: "Add Location",
          icon: <LocationIcon />,
          action: () =>
            document.dispatchEvent(
              new CustomEvent("openAddAssetForm", {
                detail: { type: "location" },
              })
            ),
        };
      default:
        return {
          text: "Add Asset",
          icon: <AddIcon />,
          action: null,
        };
    }
  };

  // Get button properties for the Add button
  const addButtonProps = getAddButtonProps();

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <div className="business-page business-analytics-page">
      <main className="business-page-content">
        <div className="business-page-header">
          <Typography variant="h4" gutterBottom>
            Assets Management
          </Typography>
          <Box>
            <Button
              variant="contained"
              startIcon={addButtonProps.icon}
              onClick={addButtonProps.action}
              sx={{
                mr: 2,
                fontSize: "1rem",
                padding: "12px 24px",
                borderRadius: "60px",
                fontWeight: "bold",
              }}
            >
              {addButtonProps.text}
            </Button>
          </Box>
        </div>

        <AssetsDashboardOverview
          employeesCount={couriers.length}
          vehiclesCount={vehicles.length}
          locationsCount={locations.length}
          ordersCount={orders.length}
        />

        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Couriers" />
          <Tab label="Vehicles" />
          <Tab label="Locations" />
        </Tabs>

        <div className="tab-content">
          {alertMessage && (
            <Alert
              severity={alertMessage.type}
              onClose={() => setAlertMessage(null)}
              sx={{ mb: 2, mt: 2 }}
            >
              {alertMessage.message}
            </Alert>
          )}

          {activeTab === 0 && (
            <CourierList
              couriers={couriers}
              onCourierAdded={(data) => addCourier(data, businessId)}
              onCourierUpdated={(id, data) =>
                updateCourier(id, data, businessId)
              }
              onCourierDeleted={(id) => deleteCourier(id, businessId)}
            />
          )}
          {activeTab === 1 && (
            <VehicleList
              vehicles={vehicles}
              couriers={couriers}
              setVehicles={fetchVehicles}
              onAddVehicle={(data) => addVehicle(data, businessId)}
              onUpdateVehicle={(id, data) =>
                updateVehicle(id, data, businessId)
              }
              onDeleteVehicle={(id) => deleteVehicle(id, businessId)}
              createVehicleAssignment={(assignmentData) =>
                createVehicleAssignment(businessId, assignmentData)
              }
              unassignVehicle={(id) => unassignVehicle(businessId, id)}
              businessId={businessId}
            />
          )}
          {activeTab === 2 && (
            <LocationList
              locations={locations}
              onAddLocation={(data) => addLocation(data, businessId)}
              onUpdateLocation={(id, data) =>
                updateLocation(id, data, businessId)
              }
              onDeleteLocation={(id) => deleteLocation(id, businessId)}
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default AssetsContent;
