// PrivateRoute.jsx
import React, { useContext, useState, useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext, AUTH_STATUS } from "./AuthContext";
import LoadingModal from "../Icon/LoadingModal";
import { resetStripe } from "../../utils/stripe";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { authStatus, user, isProfileComplete, authInitialized } = useContext(AuthContext);
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const verificationTimeoutRef = useRef(null);

  /**
   * Effect to handle auth state changes and provide a safety timeout
   * as a last resort if AuthContext initialization or state changes hang
   */
  useEffect(() => {
    let isMounted = true;
    
    // Helper function to complete verification
    const completeVerification = () => {
      if (isMounted) {
        setIsAuthCheckComplete(true);
        setIsVerifying(false);
      }
      // Always clear timeout when verification completes
      if (verificationTimeoutRef.current) {
        clearTimeout(verificationTimeoutRef.current);
        verificationTimeoutRef.current = null;
      }
    };
    
    // Clear any existing timeout
    if (verificationTimeoutRef.current) {
      clearTimeout(verificationTimeoutRef.current);
      verificationTimeoutRef.current = null;
    }
    
    // If auth has status determined or is initialized, mark verification as complete
    if (authInitialized || authStatus === AUTH_STATUS.AUTHENTICATED || authStatus === AUTH_STATUS.UNAUTHENTICATED) {
      console.log(`Auth state determined: authInitialized=${authInitialized}, authStatus=${authStatus}`);
      completeVerification();
      return; // No need for safety timeout
    }
    
    // Only set safety timeout if we're still checking or unknown
    if (authStatus === AUTH_STATUS.CHECKING || authStatus === AUTH_STATUS.UNKNOWN) {
      // Only log once when setting timeout
      if (!verificationTimeoutRef.current) {
        console.log("Setting safety timeout for auth verification");
      }
      
      // Set a safety timeout to prevent hanging indefinitely
      verificationTimeoutRef.current = setTimeout(() => {
        console.warn("Authentication verification safety timeout triggered");
        completeVerification();
      }, 5000); // 5 seconds as a safety measure
    }
    
    return () => {
      isMounted = false;
      if (verificationTimeoutRef.current) {
        clearTimeout(verificationTimeoutRef.current);
        verificationTimeoutRef.current = null;
      }
    };
  }, [authStatus, authInitialized]);

  // Show loading while authentication verification is in progress
  if ((authStatus === AUTH_STATUS.CHECKING || authStatus === AUTH_STATUS.UNKNOWN) && 
      (!authInitialized && !isAuthCheckComplete)) {
    return <LoadingModal open={true} message="Verifying authentication..." />;
  }

  // Only redirect after auth status is determined
  if (authStatus === AUTH_STATUS.UNAUTHENTICATED && (isAuthCheckComplete || authInitialized)) {
    // Reset Stripe on auth failure
    resetStripe();
    // Store the intended location for redirect after login
    return <Navigate to="/auth" state={{ from: location.pathname }} replace />;
  }

  // If profile is incomplete, redirect to complete profile page
  if (authStatus === AUTH_STATUS.AUTHENTICATED && !isProfileComplete(user)) {
    return (
      <Navigate
        to="/complete-profile"
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  // Otherwise, render the protected page
  return children;
};

export default PrivateRoute;
