import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../components/Auth/AuthContext";
import ParallaxBackground from "../Background/ParallaxBackground/ParallaxBackground";
import LoadingModal from "../Icon/LoadingModal";

const CompleteProfileRoute = ({ children }) => {
  const { user, isProfileComplete, initializationComplete, loading } =
    useContext(AuthContext);
  const location = useLocation();

  if (!initializationComplete || loading) {
    return (
      <>
        {/* <ParallaxBackground /> */}
        <LoadingModal open={true} />
      </>
    );
  }

  if (!user) {
    return (
      <Navigate
        to="/auth"
        state={{ from: location.state?.from || "/dashboard" }}
      />
    );
  }

  if (isProfileComplete(user)) {
    return <Navigate to={location.state?.from || "/dashboard"} replace />;
  }

  return children;
};

export default CompleteProfileRoute;
