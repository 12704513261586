import React from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useEmployeePermissions } from "../Auth/EmployeePermissions";

/**
 * Standard layout component for employee pages
 */
export const EmployeePageLayout = ({
  title,
  titleActions,
  children,
  loading,
  error,
  onRetry,
  maxWidth = "standard",
}) => {
  // Determine the appropriate CSS class based on maxWidth
  const widthClass =
    maxWidth === "wide"
      ? "wide"
      : maxWidth === "narrow"
      ? "narrow"
      : "standard";

  return (
    <div
      className={`employee-content-section ${widthClass}`}
      style={{ width: "100%" }}
    >
      {/* Page header */}
      <Box
        className="employee-page-header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mb: 3,
        }}
      >
        <Typography variant="h4" component="h1">
          {title}
        </Typography>

        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          {onRetry && (
            <Tooltip title="Refresh data">
              <IconButton
                onClick={onRetry}
                color="primary"
                size="small"
                sx={{ mr: 1 }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          {titleActions}
        </Box>
      </Box>

      <Divider sx={{ width: "100%", mb: 3 }} />

      {/* Loading state */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Error state */}
      {error && !loading && (
        <Alert
          severity="error"
          action={
            onRetry && (
              <Button color="inherit" size="small" onClick={onRetry}>
                Retry
              </Button>
            )
          }
          sx={{ mb: 3, width: "100%" }}
        >
          {error}
        </Alert>
      )}

      {/* Content */}
      {!loading && !error && children}
    </div>
  );
};

/**
 * Permission-aware action button
 */
export const PermissionActionButton = ({
  permission,
  icon: Icon,
  label,
  onClick,
  color = "primary",
  ...props
}) => {
  const permissions = useEmployeePermissions();

  // Don't render if the user doesn't have permission
  if (!permissions[permission]) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color={color}
      startIcon={Icon && <Icon />}
      onClick={onClick}
      {...props}
      sx={{
        borderRadius: "60px",
        fontWeight: "bold",
        padding: "10px 20px",
        ...props.sx,
      }}
    >
      {label}
    </Button>
  );
};

/**
 * Standard data grid wrapper for employee pages
 */
export const EmployeeDataGrid = ({
  title,
  children,
  actions,
  noDataMessage = "No data available",
  hasData = true,
}) => {
  return (
    <Box sx={{ mt: 3, mb: 3, width: "100%" }}>
      {title && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">{title}</Typography>
          {actions}
        </Box>
      )}

      {!hasData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Typography color="textSecondary">{noDataMessage}</Typography>
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
