import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Avatar,
  Divider,
  Paper,
} from "@mui/material";
import {
  ShoppingCart as CartIcon,
  HourglassEmpty as PendingIcon,
  LocalShipping as ShippedIcon,
  DirectionsRun as DeliveryIcon,
  CheckCircle as DeliveredIcon,
  Cancel as CancelledIcon,
  Assignment as ProcessingIcon,
  MonetizationOn as RevenueIcon,
  StarRate as RatingIcon,
  MonetizationOn,
  CheckCircle,
  Assignment,
} from "@mui/icons-material";

const MetricCard = ({ title, value, icon, color }) => (
  <Card elevation={3}>
    <CardHeader title={title} />
    <CardContent>
      <Box display="flex" alignItems="center">
        <Avatar sx={{ bgcolor: color, mr: 2, width: 56, height: 56 }}>
          {icon}
        </Avatar>
        <Box>
          <Typography variant="h5" color="textPrimary">
            {value}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const DashboardOverview = ({ orders }) => {
  const totalOrders = orders.length;

  // Order status metrics
  const pendingOrders = orders.filter(
    (order) => order.order_status === "pending"
  ).length;
  const processingOrders = orders.filter(
    (order) => order.order_status === "processing"
  ).length;
  const shippedOrders = orders.filter(
    (order) => order.order_status === "shipped"
  ).length;
  const outForDeliveryOrders = orders.filter(
    (order) => order.order_status === "out_for_delivery"
  ).length;
  const deliveredOrders = orders.filter(
    (order) => order.order_status === "delivered"
  ).length;
  const cancelledOrders = orders.filter(
    (order) => order.order_status === "cancelled"
  ).length;

  // Financial metrics
  const totalRevenue = orders
    .reduce((sum, order) => sum + parseFloat(order.total_cost || 0), 0)
    .toFixed(2);
  const avgOrderValue =
    totalOrders > 0
      ? (
          orders.reduce(
            (sum, order) => sum + parseFloat(order.total_cost || 0),
            0
          ) / totalOrders
        ).toFixed(2)
      : "0.00";

  // Customer satisfaction metrics
  const ratedOrders = orders.filter((order) => order.customer_rating !== null);
  const avgRating =
    ratedOrders.length > 0
      ? (
          ratedOrders.reduce(
            (sum, order) => sum + parseFloat(order.customer_rating || 0),
            0
          ) / ratedOrders.length
        ).toFixed(1)
      : "N/A";

  return (
    <div className="dashboard-overview-wrapper">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MetricCard
            title="Total Orders"
            value={totalOrders}
            icon={<CartIcon />}
            color="#1976d2"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MetricCard
            title="Pending"
            value={pendingOrders}
            icon={<PendingIcon />}
            color="#f57c00"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MetricCard
            title="Processing"
            value={processingOrders}
            icon={<ProcessingIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MetricCard
            title="Shipped"
            value={shippedOrders}
            icon={<ShippedIcon />}
            color="#009688"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MetricCard
            title="Out for Delivery"
            value={outForDeliveryOrders}
            icon={<DeliveryIcon />}
            color="#03a9f4"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <MetricCard
            title="Delivered"
            value={deliveredOrders}
            icon={<DeliveredIcon />}
            color="#4caf50"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardOverview;
