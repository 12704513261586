import React, { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { AuthContext } from "../components/Auth/AuthContext";
import DashboardHeader from "../components/Header/DashboardHeader";
import DashboardSidebar from "../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../components/Footer/DashboardFooter";
import { useSidebar } from "../components/Sidebar/SidebarContext";
import "./styles/LayoutStyles.css";
import "./styles/spinner.css";
import "./styles/UserPagesStandard.css"; // Import standardized user page styles
import "./styles/UserPagesExtended.css"; // Import extended user page styles

const UserLayout = () => {
  const { currentUser } = useContext(AuthContext);
  const { isUserSidebarActive } = useSidebar();
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Set up user data for the header and profile section
  useEffect(() => {
    if (currentUser) {
      setUserData({
        ...currentUser,
        fullName: `${currentUser.firstName || ""} ${
          currentUser.lastName || ""
        }`.trim(),
        profileImageUrl: currentUser.profileImageUrl || "",
      });
    }
  }, [currentUser]);

  return (
    <div className="layout-container dashboard-page" style={{ backgroundColor: 'transparent' }}>
      <DashboardHeader user={userData} />
      <div className="dashboard-main-content" style={{ backgroundColor: 'transparent' }}>
        {/* The sidebar is now only rendered once at the layout level */}
        <DashboardSidebar currentPath={location.pathname} user={userData} />
        {loading ? (
          <div className="layout-loading">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="dashboard-container">
            <main
              className={`dashboard-content ${
                isUserSidebarActive ? "sidebar-active" : ""
              }`}
            >
              {/* This is where nested routes will be rendered */}
              <div className="page-transition-wrapper content-centered">
                <Outlet context={{ userData }} />
              </div>
            </main>
          </div>
        )}
      </div>
      <DashboardFooter />
    </div>
  );
};

export default UserLayout;
