import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Link,
  TextField,
} from "@mui/material";
import { Print as PrintIcon } from "@mui/icons-material";
import qz from "qz-tray";
import useQzTray from "../../../../../hooks/useQzTray";
import { useBusiness } from "../../../../../components/Auth/BusinessContext";
import html2pdf from "../../../../../utils/patchedHtml2pdf";
import {
  getCategorySettings,
  updatePrintSettings,
  savePrintSettings,
  getPrinterConfig,
} from "../../../settings/printer/PrintSettings";

// Helper to format currency
const formatCurrency = (value) => parseFloat(value || 0).toFixed(2);

// Handles printer-related errors
const handlePrintError = (error, businessId) => {
  console.error("Print error:", error);
  const errorMessage = error.message.toLowerCase();
  if (errorMessage.includes("printer") || errorMessage.includes("configure")) {
    return (
      <Box>
        No printer configured. Configure your printer in{" "}
        <Link
          href={`/business/${businessId}/settings/printing-settings`}
          target="_blank"
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "black",
            "&:hover": { color: "rgba(0, 0, 0, 0.8)" },
          }}
        >
          Printing Settings
        </Link>
      </Box>
    );
  }
  return error.message;
};

const PrintDialog = ({ open, onClose, orderData }) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [error, setError] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { getBusinessById } = useBusiness();
  const [showSnackbar, setShowSnackbar] = useState(false);
  
  // Call useQzTray hook at the component level (not inside another function)
  const qzTrayManager = useQzTray();

  const [receiptSettings, setReceiptSettings] = useState(() =>
    getCategorySettings(orderData?.business_id, "receipts")
  );

  // Retrieve business data
  const businessData = getBusinessById(orderData?.business_id);

  // This helper reads printer settings (for receipts) from localStorage.
  const getPrinterSettings = () => {
    return {
      settings: receiptSettings,
      printer: getPrinterConfig(orderData?.business_id, "receipts"),
    };
  };

  useEffect(() => {
    console.log("Order data:", orderData);
    console.log("Business data:", businessData);
  }, [orderData, businessData]);

  // Helper to format a business address
  const formatBusinessAddress = (address) => {
    const parts = [
      address.business_address_street_address_1,
      address.business_address_street_address_2,
      address.business_address_city,
      address.business_address_state_province,
      address.business_address_postal_code,
      address.business_address_country,
    ].filter(Boolean);
    return parts.join(", ");
  };

  // Format receipt data and calculate total weight from all packages
  const formatReceiptData = () => {
    // Use the updated receiptSettings from state.
    const settings = receiptSettings;
    const {
      sender,
      recipient,
      Packages,
      total_cost,
      tracking_number,
      createdAt,
      shipping_cost,
      insurance_cost,
      additional_charges,
    } = orderData;

    // Calculate total additional charges
    const totalAdditional = (additional_charges || []).reduce(
      (sum, charge) => sum + parseFloat(charge.amount || 0),
      0
    );
    // Calculate subtotal
    const subtotal =
      parseFloat(total_cost) -
      (parseFloat(shipping_cost) +
        parseFloat(insurance_cost) +
        totalAdditional);

    // Calculate total order weight by summing each package's weight (assumed to be in lbs)
    const totalWeight = (Packages || []).reduce(
      (sum, pkg) => sum + parseFloat(pkg.weight || 0),
      0
    );

    return {
      storeName: businessData?.business_name || "Your Business Name",
      storeAddress: selectedAddress,
      receiptId: tracking_number,
      date: new Date(createdAt).toLocaleDateString(),
      customerName: recipient
        ? `${recipient.first_name} ${recipient.last_name}`
        : "",
      customerAddress: recipient?.Addresses?.[0]?.formatted_address || "",
      phoneNumber: businessData?.business_phone || "",
      email: businessData?.business_email || "",
      items:
        Packages?.flatMap((pkg) =>
          (pkg.PackageItems || []).map((item) => ({
            name: item.name,
            quantity: item.quantity || 1,
            unitPrice: item.value || 0,
            totalPrice: (item.value || 0) * (item.quantity || 1),
          }))
        ) || [],
      subtotal,
      tax: 0, // Add tax calculation if available
      shipping: parseFloat(shipping_cost) || 0,
      insurance: parseFloat(insurance_cost) || 0,
      additionalCharges: additional_charges || [],
      total: parseFloat(total_cost) || 0,
      totalWeight, // new field: total weight of order
      thankYouMessage: "Thank you for choosing our services!",
      paymentMethod: "Not Specified", // update as needed
      settings: settings || {},
    };
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
    setError(null);
  };

  // Use receiptSettings when calculating the preview dimensions.
  const calculatePreviewDimensions = () => {
    const sizeString = receiptSettings?.size || "4x6";
    const [width, height] = sizeString.split("x").map(Number);
    const DPI = 96;
    return {
      width: width * DPI,
      height: height * DPI,
    };
  };

  // --- HANDLERS FOR UPDATING PRINT SETTINGS ---

  // Updates top-level fields (size, orientation)
  const handleSettingsFieldChange = (field, value) => {
    const updated = updatePrintSettings(orderData.business_id, "receipts", {
      ...receiptSettings,
      [field]: value,
    });
    setReceiptSettings(updated.receipts);
  };

  const handleFontSizeChange = (fontType, value) => {
    const updated = updatePrintSettings(orderData.business_id, "receipts", {
      ...receiptSettings,
      fontSizes: {
        ...receiptSettings.fontSizes,
        [fontType]: Number(value),
      },
    });
    setReceiptSettings(updated.receipts);
  };

  const handleSaveSettings = () => {
    try {
      const updated = savePrintSettings(
        orderData.business_id,
        "receipts",
        receiptSettings
      );
      setReceiptSettings(updated.receipts);
    } catch (error) {
      setError("Failed to save receipt settings");
      setShowSnackbar(true);
    }
  };

  // --- PRINT HANDLER ---
  const handlePrint = async () => {
    try {
      setIsPrinting(true);
      setError(null);

      // Get the printer information (we continue to use getPrinterSettings for the printer)
      const { printer } = getPrinterSettings();
      if (!receiptSettings) {
        throw new Error(
          "Receipt printer settings not configured. Please configure your printer settings first."
        );
      }
      if (!printer) {
        throw new Error(
          "No printer configured for receipts. Please configure printer settings first."
        );
      }

      // Format the receipt data using updated receiptSettings.
      const receiptData = formatReceiptData();

      // Get the preview element.
      const previewElement = document.getElementById("receipt-preview");
      if (!previewElement) {
        throw new Error("Receipt preview element not found");
      }

      // Create a temporary wrapper for PDF generation.
      const wrapper = document.createElement("div");
      const [inWidth, inHeight] = receiptSettings.size.split("x").map(Number);
      console.log("Parsed dimensions:", { inWidth, inHeight });
      const DPI = 96;
      const width = inWidth * DPI;
      const height = inHeight * DPI;

      wrapper.style.width = `${width}px`;
      wrapper.style.height = `${height}px`;
      wrapper.style.background = "white";
      wrapper.style.position = "relative";
      wrapper.style.overflow = "hidden";

      const previewClone = previewElement.cloneNode(true);
      // Ensure the clone has a white background and black text.
      previewClone.style.position = "absolute";
      previewClone.style.visibility = "visible";
      previewClone.style.display = "block";
      previewClone.style.background = "white";
      previewClone.style.color = "black";
      previewClone.style.width = "100%";
      previewClone.style.height = "100%";
      previewClone.style.margin = "0";
      previewClone.style.padding = "0";
      wrapper.appendChild(previewClone);
      document.body.appendChild(wrapper);

      let pdfOutput;
      try {
        // Generate PDF using html2pdf.
        pdfOutput = await html2pdf()
          .set({
            margin: 0,
            filename: "receipt-preview.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 4,
              backgroundColor: "#FFFFFF",
              width: wrapper.offsetWidth,
              height: wrapper.offsetHeight,
              useCORS: true,
            },
            jsPDF: {
              unit: "in",
              format: [width / DPI, height / DPI],
              orientation: receiptSettings.orientation || "portrait",
              compress: false,
            },
          })
          .from(wrapper)
          .outputPdf("blob");

        // Create a URL for the generated PDF and open it in a new window.
        const pdfUrl = URL.createObjectURL(pdfOutput);
        window.open(pdfUrl, "_blank");
        console.log("PDF Generation Successful");
      } catch (pdfError) {
        console.error("PDF generation or window opening error: ", pdfError);
        throw new Error("Failed to generate PDF or open preview window.");
      }

      // Clean up the temporary wrapper.
      document.body.removeChild(wrapper);

      // Convert the PDF blob to a base64 string for printing via QZ Tray.
      const pdfBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(pdfOutput);
      });
      
      const printConfig = {
        size: { width: inWidth, height: inHeight },
        units: "in",
        orientation: receiptSettings.orientation,
        density: "best",
        interpolation: "nearest-neighbor",
        margins: { top: 0, right: 0, bottom: 0, left: 0 },
        rasterize: "false",
      };
      
      const printData = [
        {
          type: "pixel",
          format: "pdf",
          flavor: "base64",
          data: pdfBase64,
        },
      ];

      console.log("Printing configuration:", printConfig);

      // Use the QZ Tray manager stored in the state variable, not calling the hook again
      if (!qzTrayManager.isConnected) {
        await qzTrayManager.connect();
      }
      
      await qzTrayManager.print(printer, printConfig, printData);
      onClose();
    } catch (err) {
      setError(handlePrintError(err, orderData.business_id));
      setShowSnackbar(true);
    } finally {
      setIsPrinting(false);
    }
  };

  const receiptData = formatReceiptData();

  return (
    <>
      <Dialog
        open={open}
        onClose={() => !isPrinting && onClose()}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            display: "flex",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" sx={{ color: "black" }}>
            Print Order Receipt
          </Typography>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {/* Business Location Section */}
          <Box sx={{ mb: 3 }}>
            <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
              <InputLabel sx={{ color: "black" }}>Business Location</InputLabel>
              <Select
                value={selectedAddress}
                onChange={handleAddressChange}
                label="Business Location"
                sx={{ color: "black" }}
              >
                {businessData?.BusinessAddresses?.map((address) => (
                  <MenuItem
                    key={address.business_address_id}
                    value={formatBusinessAddress(address)}
                    sx={{ color: "black" }}
                  >
                    {address.business_address_name} -{" "}
                    {formatBusinessAddress(address)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* New Print Settings Section */}
          <Box sx={{ mb: 3, p: 2, border: "1px solid #ccc", borderRadius: 1 }}>
            <Typography variant="h6" sx={{ color: "black", mb: 1 }}>
              Print Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "black" }}>Receipt Size</InputLabel>
                  <Select
                    value={receiptSettings.size}
                    label="Receipt Size"
                    onChange={(e) =>
                      handleSettingsFieldChange("size", e.target.value)
                    }
                    sx={{ color: "black" }}
                  >
                    {["4x6", "6x4"].map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ color: "black" }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: "black" }}>Orientation</InputLabel>
                  <Select
                    value={receiptSettings.orientation}
                    label="Orientation"
                    onChange={(e) =>
                      handleSettingsFieldChange("orientation", e.target.value)
                    }
                    sx={{ color: "black" }}
                  >
                    <MenuItem value="portrait" sx={{ color: "black" }}>
                      Portrait
                    </MenuItem>
                    <MenuItem value="landscape" sx={{ color: "black" }}>
                      Landscape
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Font Size Inputs */}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Title Font Size"
                  type="number"
                  value={receiptSettings.fontSizes.title}
                  onChange={(e) =>
                    handleFontSizeChange("title", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 10, max: 32 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Subtitle Font Size"
                  type="number"
                  value={receiptSettings.fontSizes.subtitle}
                  onChange={(e) =>
                    handleFontSizeChange("subtitle", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 24 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Details Font Size"
                  type="number"
                  value={receiptSettings.fontSizes.details}
                  onChange={(e) =>
                    handleFontSizeChange("details", e.target.value)
                  }
                  InputProps={{ inputProps: { min: 8, max: 20 } }}
                  sx={{ color: "black" }}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={handleSaveSettings}
                sx={{ color: "black" }}
              >
                Save Print Settings
              </Button>
            </Box>
          </Box>
          {/* Receipt Preview Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ color: "black" }}>
              Receipt Preview
            </Typography>
            <Box
              id="receipt-preview"
              sx={{
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                color: "black",
                width: `${calculatePreviewDimensions().width}px`,
                height: `${calculatePreviewDimensions().height}px`,
                transform:
                  receiptSettings.orientation === "landscape"
                    ? "rotate(90deg)"
                    : "none",
                transformOrigin: "center center",
                overflow: "visible",
                margin: "0 auto",
              }}
            >
              {/* Preview header */}
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  fontSize: receiptSettings.fontSizes.title,
                  mt: 1,
                  mb: 1,
                  color: "inherit",
                }}
              >
                {receiptData.storeName}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  fontSize: receiptSettings.fontSizes.subtitle,
                  color: "inherit",
                }}
              >
                {receiptData.storeAddress}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  fontSize: receiptSettings.fontSizes.subtitle,
                  mb: 2,
                  color: "inherit",
                }}
              >
                Tel: {receiptData.phoneNumber} | Email: {receiptData.email}
              </Typography>
              <Divider sx={{ my: 2 }} />
              {/* Items Table */}
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: receiptSettings.fontSizes.details,
                          fontWeight: "bold",
                          color: "inherit",
                        }}
                      >
                        Item
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: receiptSettings.fontSizes.details,
                          fontWeight: "bold",
                          color: "inherit",
                        }}
                      >
                        Quantity
                      </TableCell>
                      {/* <TableCell
                        align="right"
                        sx={{
                          fontSize: receiptSettings.fontSizes.details,
                          fontWeight: "bold",
                          color: "inherit",
                        }}
                      >
                        Unit Price
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: receiptSettings.fontSizes.details,
                          fontWeight: "bold",
                          color: "inherit",
                        }}
                      >
                        Total
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receiptData.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: receiptSettings.fontSizes.details,
                            color: "inherit",
                          }}
                        >
                          {item.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: receiptSettings.fontSizes.details,
                            color: "inherit",
                          }}
                        >
                          {item.quantity}
                        </TableCell>
                        {/* <TableCell
                          align="right"
                          sx={{
                            fontSize: receiptSettings.fontSizes.details,
                            color: "inherit",
                          }}
                        >
                          ${formatCurrency(item.unitPrice)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: receiptSettings.fontSizes.details,
                            color: "inherit",
                          }}
                        >
                          ${formatCurrency(item.totalPrice)}
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider sx={{ my: 2 }} />
              {/* Totals Section */}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "50%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        color: "inherit",
                      }}
                    >
                      Subtotal:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        color: "inherit",
                      }}
                    >
                      ${formatCurrency(receiptData.subtotal)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        color: "inherit",
                      }}
                    >
                      Total Weight:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        color: "inherit",
                      }}
                    >
                      {receiptData.totalWeight.toFixed(2)} lbs
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        color: "inherit",
                      }}
                    >
                      Shipping:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        color: "inherit",
                      }}
                    >
                      ${formatCurrency(receiptData.shipping)}
                    </Typography>
                  </Box>
                  {receiptData.additionalCharges.map((charge, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: receiptSettings.fontSizes.details,
                          color: "inherit",
                        }}
                      >
                        {charge.description}:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: receiptSettings.fontSizes.details,
                          color: "inherit",
                        }}
                      >
                        ${formatCurrency(charge.amount)}
                      </Typography>
                    </Box>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                      pt: 1,
                      borderTop: "1px solid #ccc",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        fontWeight: "bold",
                        color: "inherit",
                      }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: receiptSettings.fontSizes.details,
                        fontWeight: "bold",
                        color: "inherit",
                      }}
                    >
                      ${formatCurrency(receiptData.total)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ textAlign: "center", mt: 4 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: receiptSettings.fontSizes.details,
                    color: "inherit",
                  }}
                >
                  {receiptData.thankYouMessage}
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => !isPrinting && onClose()}
            disabled={isPrinting}
            sx={{ color: "black" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handlePrint}
            variant="contained"
            startIcon={
              isPrinting ? <CircularProgress size={20} /> : <PrintIcon />
            }
            disabled={isPrinting || !selectedAddress}
            sx={{ backgroundColor: "black", color: "white" }}
          >
            {isPrinting ? "Printing..." : "Print Receipt"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%", backgroundColor: "black", color: "white" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PrintDialog;