import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Switch,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  Divider,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material";
import { AuthContext } from "../Auth/AuthContext";

const BusinessPaymentSettings = ({ businessId }) => {
  const { api } = useContext(AuthContext);
  const [settings, setSettings] = useState({
    auto_pay_collaborations: false,
    payment_schedule: "immediate",
    payment_day_of_week: 1,
    payment_day_of_month: 1,
    default_payment_method_id: "",
    require_approval: true,
    notification_settings: {
      email_notifications: true,
      payment_reminders: true,
      payment_receipts: true,
    },
    payment_thresholds: {
      minimum_payment_amount: 1.0,
      maximum_auto_payment_amount: 500.0,
    },
  });

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  // Function to fetch payment methods
  const fetchPaymentMethods = async () => {
    try {
      const response = await api.get(
        `/payments/business-payment-methods?businessId=${businessId}`
      );
      setPaymentMethods(response.data.paymentMethods || []);
      
      // Set the default payment method id in the settings
      if (response.data.defaultPaymentMethodId) {
        setSettings(prev => ({
          ...prev,
          default_payment_method_id: response.data.defaultPaymentMethodId
        }));
      }
    } catch (error) {
      console.error("Error loading payment methods:", error);
    }
  };

  // Function to fetch settings
  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/payments/collaboration/business/${businessId}/payment-settings`
      );
      if (response.data.success) {
        setSettings(response.data.data);
      }
    } catch (error) {
      console.error("Error loading payment settings:", error);
      setError("Failed to load payment settings");
    } finally {
      setLoading(false);
    }
  };

  // Initial data loading
  useEffect(() => {
    if (businessId) {
      fetchSettings();
      fetchPaymentMethods();
    }
  }, [businessId, api]);

  // Additional effect to refresh payment methods when needed
  useEffect(() => {
    if (businessId && !loading) {
      // This ensures we have the latest payment methods reflected in the dropdown
      fetchPaymentMethods();
    }
  }, [businessId, loading]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name.includes(".")) {
      // Handle nested properties
      const [parent, child] = name.split(".");
      setSettings((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: e.target.type === "checkbox" ? checked : value,
        },
      }));
    } else {
      // Handle top-level properties
      setSettings((prev) => ({
        ...prev,
        [name]: e.target.type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    setSaveSuccess(false);

    try {
      const response = await api.put(
        `/payments/collaboration/business/${businessId}/payment-settings`,
        settings
      );

      if (response.data.success) {
        setSaveSuccess(true);
        setSettings(response.data.data);
        
        // Refresh payment methods after saving to ensure consistency
        fetchPaymentMethods();
      } else {
        setError("Failed to save settings");
      }
    } catch (error) {
      console.error("Error saving payment settings:", error);
      setError(error.response?.data?.message || "Failed to save settings");
    } finally {
      setSaving(false);

      // Auto-hide success message after 5 seconds
      if (saveSuccess) {
        setTimeout(() => {
          setSaveSuccess(false);
        }, 5000);
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <CardHeader title="Payment Settings" />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Automated Payments Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Automated Payments</Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.auto_pay_collaborations}
                    onChange={handleChange}
                    name="auto_pay_collaborations"
                    color="primary"
                  />
                }
                label="Enable Automated Payments"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                disabled={!settings.auto_pay_collaborations}
              >
                <InputLabel>Payment Schedule</InputLabel>
                <Select
                  name="payment_schedule"
                  value={settings.payment_schedule}
                  onChange={handleChange}
                  label="Payment Schedule"
                >
                  <MenuItem value="immediate">Immediate</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="biweekly">Bi-weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {settings.payment_schedule === "weekly" && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Payment Day</InputLabel>
                  <Select
                    name="payment_day_of_week"
                    value={settings.payment_day_of_week}
                    onChange={handleChange}
                    label="Payment Day"
                  >
                    <MenuItem value={0}>Sunday</MenuItem>
                    <MenuItem value={1}>Monday</MenuItem>
                    <MenuItem value={2}>Tuesday</MenuItem>
                    <MenuItem value={3}>Wednesday</MenuItem>
                    <MenuItem value={4}>Thursday</MenuItem>
                    <MenuItem value={5}>Friday</MenuItem>
                    <MenuItem value={6}>Saturday</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {settings.payment_schedule === "monthly" && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Payment Day of Month"
                  name="payment_day_of_month"
                  type="number"
                  InputProps={{
                    inputProps: { min: 1, max: 31 },
                  }}
                  value={settings.payment_day_of_month}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.require_approval}
                    onChange={handleChange}
                    name="require_approval"
                    color="primary"
                  />
                }
                label="Require Approval for Automated Payments"
              />
            </Grid>

            {/* Payment Method Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Payment Method
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Default Payment Method</InputLabel>
                <Select
                  name="default_payment_method_id"
                  value={settings.default_payment_method_id || ""}
                  onChange={handleChange}
                  label="Default Payment Method"
                >
                  <MenuItem value="">None</MenuItem>
                  {paymentMethods.map((method) => (
                    <MenuItem key={method.id} value={method.id}>
                      {method.card?.brand?.toUpperCase() || "Card"} ••••{" "}
                      {method.card?.last4} (expires {method.card?.exp_month}/
                      {method.card?.exp_year})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {paymentMethods.length === 0 && (
                <Alert severity="info" sx={{ mt: 1 }}>
                  No payment methods available. Please add a payment method in
                  your account settings.
                </Alert>
              )}
            </Grid>

            {/* Thresholds Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Payment Thresholds
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Minimum Payment Amount ($)"
                name="payment_thresholds.minimum_payment_amount"
                type="number"
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                }}
                value={settings.payment_thresholds.minimum_payment_amount}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Maximum Auto Payment Amount ($)"
                name="payment_thresholds.maximum_auto_payment_amount"
                type="number"
                InputProps={{
                  inputProps: { min: 0, step: 0.01 },
                }}
                value={settings.payment_thresholds.maximum_auto_payment_amount}
                onChange={handleChange}
              />
            </Grid>

            {/* Notification Settings */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Notification Settings
              </Typography>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.notification_settings.email_notifications}
                    onChange={handleChange}
                    name="notification_settings.email_notifications"
                    color="primary"
                  />
                }
                label="Email Notifications"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.notification_settings.payment_reminders}
                    onChange={handleChange}
                    name="notification_settings.payment_reminders"
                    color="primary"
                  />
                }
                label="Payment Reminders"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.notification_settings.payment_receipts}
                    onChange={handleChange}
                    name="notification_settings.payment_receipts"
                    color="primary"
                  />
                }
                label="Payment Receipts"
              />
            </Grid>

            {/* Submit Section */}
            <Grid item xs={12}>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              {saveSuccess && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  Settings saved successfully!
                </Alert>
              )}

              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                >
                  {saving ? <CircularProgress size={24} /> : "Save Settings"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default BusinessPaymentSettings;