import React, { useState, useEffect, useContext } from "react";
import { 
  Typography, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Chip, 
  IconButton,
  CircularProgress,
  Alert
} from "@mui/material";
import { 
  Visibility as VisibilityIcon,
  ReceiptLong as ReceiptIcon
} from "@mui/icons-material";
import { AuthContext } from "../../components/Auth/AuthContext";

const OrdersPage = () => {
  const { api } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await api.get("/orders/user");
        setOrders(response.data);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Failed to load orders. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [api]);

  const getStatusChipProps = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return { color: "warning", className: "status-pending" };
      case "shipped":
        return { color: "success", className: "status-shipped" };
      case "delivered":
        return { color: "info", className: "status-delivered" };
      case "cancelled":
        return { color: "error", className: "status-cancelled" };
      default:
        return { color: "default" };
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="user-page-wrapper">
      <div className="user-page-content">
        <h1 className="user-page-title">My Orders</h1>
        
        {loading ? (
          <div className="user-loading">
            <CircularProgress />
          </div>
        ) : error ? (
          <Alert severity="error" className="user-error">
            {error}
          </Alert>
        ) : orders.length === 0 ? (
          <div className="empty-state">
            <ReceiptIcon fontSize="large" className="empty-state-icon" />
            <Typography className="empty-state-text">
              You don't have any orders yet.
            </Typography>
          </div>
        ) : (
          <TableContainer component={Paper} className="orders-list">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Order ID</strong></TableCell>
                  <TableCell><strong>Date</strong></TableCell>
                  <TableCell><strong>Status</strong></TableCell>
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell><strong>Items</strong></TableCell>
                  <TableCell align="center"><strong>Actions</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => {
                  const { color, className } = getStatusChipProps(order.status);
                  return (
                    <TableRow 
                      key={order.orderId} 
                      className="order-item"
                      hover
                    >
                      <TableCell>#{order.orderId.slice(-6)}</TableCell>
                      <TableCell>{formatDate(order.orderDate)}</TableCell>
                      <TableCell>
                        <Chip 
                          label={order.status || "Processing"} 
                          color={color}
                          size="small"
                          className={`order-status ${className}`}
                        />
                      </TableCell>
                      <TableCell>${order.total?.toFixed(2) || "0.00"}</TableCell>
                      <TableCell>{order.items?.length || 0}</TableCell>
                      <TableCell align="center">
                        <IconButton 
                          size="small"
                          color="primary"
                          title="View Order Details"
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default OrdersPage;