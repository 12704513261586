import React from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
  Alert,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const LocationDetails = ({ node }) => {
  const details = node.details;
  const isDelivery = node.type === "customer_address" || node.type === "delivery";
  console.log("LocationDetails -> isDelivery", isDelivery);

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {isDelivery
          ? "Delivery Location"
          : node.type === "start"
          ? "Start Location"
          : "Shipping Location"}
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Name
      </Typography>
      <Typography>
        {isDelivery ? details.Customer?.first_name : details.business_address_name}
      </Typography>

      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Address
      </Typography>
      <Typography>
        {details.business_address_street_address_1 || details.address_line1}
        {(details.business_address_street_address_2 || details.address_line2) && (
          <>
            <br />
            {details.business_address_street_address_2 || details.address_line2}
          </>
        )}
        <br />
        {details.business_address_city || details.city}, {details.business_address_state_province || details.state_province}{" "}
        {details.business_address_postal_code || details.postal_code}
      </Typography>

      {isDelivery && details.delivery_instructions && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Instructions
          </Typography>
          <Typography>{details.delivery_instructions}</Typography>
        </>
      )}

      {!isDelivery && node.type !== "start" && (
        <>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Location Type
          </Typography>
          <Typography>{details.shipping_location_type || details.business_address_type || 'Standard'}</Typography>

          {(details.business_address_phone || details.phone) && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Contact
              </Typography>
              <Typography>{details.business_address_phone || details.phone}</Typography>
              {(details.contact_email || details.email) && (
                <Typography>{details.contact_email || details.email}</Typography>
              )}
            </>
          )}

          {details.operating_hours && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Hours
              </Typography>
              <Typography>
                {typeof details.operating_hours === "string"
                  ? details.operating_hours
                  : JSON.stringify(details.operating_hours, null, 2)}
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
};

const RoutePathEditor = ({
  routePath,
  selectedEdge,
  couriers,
  onCourierAssign,
  onEdgeDelete,
  getNodeLabel,
}) => {
  return routePath.map((edge, index) => {
    const fromName = getNodeLabel(edge.from);
    const toName = getNodeLabel(edge.to);
    const edgeId = edge.id || `${edge.from}-${edge.to}`;

    return (
      <Box key={edgeId} sx={{ mb: 3 }}>
        <Typography variant="subtitle2" color="text.secondary">
          Step {index + 1}
        </Typography>

        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          From
        </Typography>
        <Typography>{fromName}</Typography>

        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          To
        </Typography>
        <Typography>{toName}</Typography>

        <FormControl fullWidth sx={{ mt: 1 }} error={!edge.courier}>
          <InputLabel>Courier</InputLabel>
          <Select
            value={edge.courier?.employee_id || ""}
            onChange={(e) => onCourierAssign(edgeId, e.target.value)}
            label="Courier"
          >
            <MenuItem value="">Select a courier</MenuItem>
            {couriers.map((courier) => (
              <MenuItem key={courier.employee_id} value={courier.employee_id}>
                {`${courier.employee_first_name} ${courier.employee_last_name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedEdge?.id === edgeId && (
          <Button
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => onEdgeDelete(edgeId)}
            sx={{ mt: 1 }}
          >
            Delete Connection
          </Button>
        )}

        {index < routePath.length - 1 && <Divider sx={{ mt: 2 }} />}
      </Box>
    );
  });
};

const DefaultView = () => (
  <Box>
    <Typography color="text.secondary" gutterBottom>
      Select a location or connection to view details
    </Typography>
    <Divider sx={{ my: 2 }} />
    <Typography variant="body2" color="text.secondary">
      Tips:
    </Typography>
    <Typography
      variant="body2"
      color="text.secondary"
      component="ul"
      sx={{ pl: 2 }}
    >
      <li>
        Start the route from the <strong>Start Location (yellow)</strong>
      </li>
      <li>Click on locations to view details</li>
      <li>
        Hold Ctrl/Cmd + Click and drag between locations to create connections
      </li>
      <li>Select a connection to assign a courier</li>
      <li>Use mouse wheel to zoom in/out on the map</li>
      <li>Click and drag to pan the map view</li>
    </Typography>
  </Box>
);

const RouteBuilderSidebar = ({
  selectedNode,
  selectedEdge,
  couriers = [], // Add default value
  routePath = [], // Add default value
  validationErrors = [],
  missingCourierEdges = [],
  onCourierAssign,
  onEdgeDelete,
  getNodeLabel,
}) => {
  // Add loading state check
  if (!couriers.length) {
    return null; // Or a loading placeholder
  }

  return (
    <Paper
      sx={{
        width: 300,
        p: 2,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {selectedNode ? (
        <LocationDetails node={selectedNode} />
      ) : routePath.length > 0 ? (
        <>
          <Typography variant="h6" gutterBottom>
            Route Path
          </Typography>
          <RoutePathEditor
            routePath={routePath}
            selectedEdge={selectedEdge}
            couriers={couriers}
            onCourierAssign={onCourierAssign}
            onEdgeDelete={onEdgeDelete}
            getNodeLabel={getNodeLabel}
          />
        </>
      ) : (
        <DefaultView />
      )}
    </Paper>
  );
};

export default RouteBuilderSidebar;