import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { useBusiness } from "../../../components/Auth/BusinessContext";

import BusinessActionItems from "./BusinessActionItems";
import BusinessDashboardOverview from "../../../components/Actions/BusinessDashboardOverview";

import "./BusinessDashboardPage.css";

const BusinessDashboardPage = () => {
  const { api } = useContext(AuthContext);

  // The businessId from the route parameter
  const { businessId } = useParams();

  // Bring in methods from BusinessContext
  const { getBusinessById, fetchBusinesses } = useBusiness();

  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [actionItems, setActionItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // We fetch or retrieve business data from the context (and optionally the server),
    // then fetch action items from your API endpoint.
    const fetchBusinessData = async () => {
      try {
        // 1) Ensure we have the latest business list from the server or cache
        await fetchBusinesses();

        // 2) Retrieve the current business from the context by ID
        const business = getBusinessById(businessId);
        setCurrentBusiness(business);

        // 3) Fetch any "action items" for this business
        const response = await api.get(
          `/business-actions/${businessId}/actions`
        );
        if (response.data.success) {
          setActionItems(response.data.data);
        }
      } catch (err) {
        console.error("Error fetching business data:", err);
        setError(err.response?.data?.message || err.message);
      }
    };

    if (businessId) {
      fetchBusinessData();
    }
  }, [api, businessId]);

  return (
    <div className="business-page business-dashboard-page">
      <main className="business-page-content">
        {error ? (
          <div className="error-container">
            <Typography color="error" variant="h6">
              {error}
            </Typography>
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          </div>
        ) : (
          <>
            {/* High-level overview (stats, summary info, etc.) */}
            <BusinessDashboardOverview
              business={currentBusiness}
              actionItems={actionItems}
            />

            {/* List or grid of action items/tasks */}
            <BusinessActionItems
              businessId={businessId}
              actionItems={actionItems}
              business={currentBusiness}
            />
          </>
        )}
      </main>
    </div>
  );
};

export default BusinessDashboardPage;
