import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  TextField,
  CircularProgress,
  Box,
  Modal,
  styled,
  Alert,
} from "@mui/material";
import DashboardHeader from "../../components/Header/DashboardHeader";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import DashboardFooter from "../../components/Footer/DashboardFooter";
import ParallaxBackground from "../../components/Background/ParallaxBackground/ParallaxBackground";
import { AuthContext } from "../../components/Auth/AuthContext";
import CustomButton from "../../components/Button/Button";
import styles from "./EditProfilePage.module.css";
import { resizeImage } from "browser-image-resizer";
import { FaTrash } from "react-icons/fa";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const imageConfig = {
  quality: 0.8,
  maxWidth: 150,
  maxHeight: 150,
  autoRotate: true,
  debug: true,
};

const defaultProfilePicture = "/images/background-image.jpg"; // Default profile picture path

const EditProfilePage = () => {
  const { user, updateUserContext } = useContext(AuthContext);
  const [sidebarActive, setSidebarActive] = useState(() => {
    const saved = localStorage.getItem("sidebarActive");
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [formData, setFormData] = useState({
    user_name: "",
    user_first_name: "",
    user_last_name: "",
    user_date_of_birth: "",
    user_profile_picture: null,
  });
  const [originalData, setOriginalData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [previewImage, setPreviewImage] = useState(defaultProfilePicture);
  const { api } = useContext(AuthContext);

  // Update initial data loading
  useEffect(() => {
    if (user) {
      const userData = {
        user_name: user.user_name || "",
        user_first_name: user.user_first_name || "",
        user_last_name: user.user_last_name || "",
        user_date_of_birth: user.user_date_of_birth
          ? new Date(user.user_date_of_birth).toISOString().split("T")[0]
          : "",
        user_profile_picture_url: user.user_profile_picture_url || null,
      };

      setFormData(userData);
      setOriginalData(userData);

      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      setPreviewImage(
        user.user_profile_picture_url
          ? `${baseUrl}${user.user_profile_picture_url}`
          : defaultProfilePicture
      );
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem("sidebarActive", JSON.stringify(sidebarActive));
  }, [sidebarActive]);

  useEffect(() => {
    return () => {
      if (previewImage && previewImage.startsWith("blob:")) {
        URL.revokeObjectURL(previewImage);
      }
    };
  }, []);

  const handleSidebarStateChange = useCallback((isActive) => {
    setSidebarActive(isActive);
  }, []);

  const validateField = (name, value) => {
    if (!value.trim()) {
      return `${name.replace("user_", "").replace("_", " ")} is required`;
    }
    if (value.trim().length < 2) {
      return `${name
        .replace("user_", "")
        .replace("_", " ")} must be at least 2 characters long`;
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "user_date_of_birth" && value) {
      formattedValue = new Date(value).toISOString().split("T")[0];
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
    setAlertMessage(null);

    // Validate the changed field
    const fieldError = validateField(name, formattedValue);
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError,
    }));
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image")) {
        setAlertMessage({
          type: "error",
          message: "Only image files are allowed.",
        });
        return;
      }

      try {
        let processedFile = file;
        if (file.size > MAX_FILE_SIZE) {
          processedFile = await resizeImage(file, imageConfig);
        }

        const previewUrl = URL.createObjectURL(processedFile);
        setPreviewImage(previewUrl);
        setFormData((prev) => ({
          ...prev,
          newProfilePicture: processedFile,
        }));
      } catch (error) {
        console.error("Error processing image:", error);
        setAlertMessage({
          type: "error",
          message: "Failed to process profile picture.",
        });
      }
    }
  };

  // Update remove image handler
  const removeSelectedImage = () => {
    setPreviewImage(defaultProfilePicture);
    setFormData((prev) => ({
      ...prev,
      newProfilePicture: null,
      user_profile_picture_url: null,
    }));
  };

  const isFormChanged = () => {
    return JSON.stringify(formData) !== JSON.stringify(originalData);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    ["user_name", "user_first_name", "user_last_name"].forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      setAlertMessage({
        type: "error",
        message: "Please correct the errors in the form.",
      });
    }

    return isValid;
  };

  const updateUser = async (formData) => {
    const response = await api.put("/users/update-profile", formData, {});
    return response.data.user;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setAlertMessage(null);

    try {
      const formDataToSend = new FormData();

      // Add regular form fields
      Object.keys(formData).forEach((key) => {
        if (key !== "newProfilePicture") {
          formDataToSend.append(key, formData[key]);
        }
      });

      // Add profile picture if exists
      if (formData.newProfilePicture) {
        formDataToSend.append(
          "user_profile_picture",
          formData.newProfilePicture
        );
      }

      // Add flag for removing profile picture
      if (!formData.newProfilePicture && !formData.user_profile_picture_url) {
        formDataToSend.append("remove_profile_picture", "true");
      }

      const response = await api.put("/users/update-profile", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const updatedUser = response.data.user;

      // Update state with new data
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      setFormData({
        ...updatedUser,
        user_date_of_birth: updatedUser.user_date_of_birth
          ? new Date(updatedUser.user_date_of_birth).toISOString().split("T")[0]
          : "",
      });
      setOriginalData(updatedUser);
      setPreviewImage(
        updatedUser.user_profile_picture_url
          ? `${baseUrl}${updatedUser.user_profile_picture_url}`
          : defaultProfilePicture
      );

      updateUserContext(updatedUser);
      setAlertMessage({
        type: "success",
        message: "Profile updated successfully!",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      setAlertMessage({
        type: "error",
        message: error.response?.data?.error || "Failed to update profile",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles["edit-profile-page"]}>
      <main className={`${styles["edit-profile-content"]} `}>
        <h1 className={styles.title}>Edit Profile</h1>
        <form onSubmit={handleSubmit} className={styles["edit-form"]}>
          {alertMessage && (
            <Alert
              severity={alertMessage.type}
              variant="outlined"
              sx={{ mb: 2 }}
            >
              {alertMessage.message}
            </Alert>
          )}
          <div className={styles["profile-picture"]}>
            <img src={previewImage} alt="Profile" />
            <label
              htmlFor="user_profile_picture"
              className={styles["upload-button"]}
            >
              Change Profile Picture
            </label>
            <input
              type="file"
              id="user_profile_picture"
              name="user_profile_picture"
              onChange={handleFileChange}
              accept="image/*"
            />
            {previewImage !== defaultProfilePicture && (
              <button
                type="button"
                onClick={removeSelectedImage}
                className={styles["remove-button"]}
              >
                <FaTrash />
              </button>
            )}
          </div>
          <TextField
            name="user_name"
            label="Username"
            value={formData.user_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.user_name}
            helperText={errors.user_name}
            required
          />
          <TextField
            name="user_first_name"
            label="First Name"
            value={formData.user_first_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.user_first_name}
            helperText={errors.user_first_name}
            required
          />
          <TextField
            name="user_last_name"
            label="Last Name"
            value={formData.user_last_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.user_last_name}
            helperText={errors.user_last_name}
            required
          />
          <TextField
            name="user_date_of_birth"
            label="Date of Birth"
            type="date"
            value={formData.user_date_of_birth || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
          />
          <div className={styles.submitButton}>
            <CustomButton
              type="submit"
              variant="contained"
              disabled={isLoading || !isFormChanged()}
              text={isLoading ? "Updating..." : "Update Profile"}
            />
          </div>
        </form>
      </main>
      <Modal
        open={isLoading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box className={styles.loadingModal}>
          <CircularProgress />
          <p>Updating profile...</p>
        </Box>
      </Modal>
    </div>
  );
};

export default EditProfilePage;
