import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from "react";
import {
  useParams,
  useLocation,
  Routes,
  Route,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Tabs, Tab, Paper, Alert, Box } from "@mui/material";
import { AuthContext } from "../../components/Auth/AuthContext";
import { useOrders } from "../business/shipping/ShipmentsPage/OrdersContext";
import { useEmployeePermissions } from "../../components/Auth/EmployeePermissions";
import {
  EmployeePageLayout,
  PermissionActionButton,
} from "../../components/Employee/PageComponents";
import DashboardOverview from "../business/shipping/ShipmentsPage/DashboardOverview";
import QuickAddOrder from "../business/shipping/ShipmentsPage/QuickAddOrder";
import BulkImport from "../business/shipping/ShipmentsPage/BulkImport";
import OrderList from "../business/shipping/ShipmentsPage/OrderList";
import OrderEditPage from "../business/shipping/ShipmentsPage/OrderEditPage/OrderEditPage";
import CollaborationManagementScreen from "../business/shipping/ShipmentsPage/CollaborationManagementScreen";
import OrderAssignment from "../business/shipping/ShipmentsPage/OrderAssignment";
import "../business/shipping/ShipmentsPage/ShipmentsPage.css";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";

const EmployeeOrdersContent = () => {
  const { api } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialFilter, setInitialFilter] = useState("");
  const [completingOrder, setCompletingOrder] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const scrollableRef = useRef(null);
  const { businessId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const prevLocationRef = useRef(null);
  const [quickAddOpen, setQuickAddOpen] = useState(false);

  // Using outlet context from layout to get common data
  const {
    currentBusiness,
    dashboardData,
    loading: layoutLoading,
    error: layoutError,
  } = useOutletContext();

  // Use our centralized permissions system
  const permissions = useEmployeePermissions();

  const {
    orders,
    groups,
    locations,
    couriers,
    loading,
    error,
    customers,
    customersError,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    fetchLocations,
    fetchCouriers,
    addOrder,
    deleteOrderFromLocalStorage,
  } = useOrders();

  // Get employee roles from dashboard data
  const employeeRoles = dashboardData?.employee?.roles || [];

  // Modified handleRefresh to be more robust
  const handleRefresh = useCallback(async () => {
    setRefreshCount((prev) => prev + 1);

    try {
      await Promise.all([
        fetchOrders(businessId, true),
        fetchCustomers(businessId),
        fetchGroups(businessId),
        fetchLocations(businessId),
        fetchCouriers(businessId),
      ]);
    } catch (error) {
      console.error("Error during refresh:", error);
    }
  }, [
    businessId,
    fetchOrders,
    fetchCustomers,
    fetchGroups,
    fetchLocations,
    fetchCouriers,
  ]);

  // Data fetching logic
  useEffect(() => {
    const fetchData = async () => {
      if (!businessId) return;

      try {
        // Check if we need to fetch any data
        const needsFetch =
          !orders.length ||
          !customers.length ||
          !locations.length ||
          !couriers.length;
        const isNestedRoute = location.pathname.split("/").length > 4;

        // Always fetch if data is missing or if we're in a nested route
        if (needsFetch || isNestedRoute) {
          await Promise.all([
            fetchOrders(businessId),
            fetchCustomers(businessId),
            fetchGroups(businessId),
            fetchLocations(businessId),
            fetchCouriers(businessId),
          ]);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, [
    businessId,
    location.pathname,
    orders.length,
    customers.length,
    locations.length,
    couriers.length,
  ]);

  // Scroll position restoration
  useLayoutEffect(() => {
    if (prevLocationRef.current !== location.pathname) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      prevLocationRef.current = location.pathname;
    }
  }, [location.pathname]);

  // Memoized data
  const memoizedOrders = useMemo(() => orders, [orders]);
  const memoizedCustomers = useMemo(() => customers, [customers]);
  const memoizedGroups = useMemo(() => groups, [groups]);

  // Handlers
  const handleOrderClick = useCallback(
    (orderId) => {
      navigate(`/employee/${businessId}/orders/${orderId}`);
    },
    [businessId, navigate]
  );

  const handleCompleteOrder = useCallback((order) => {
    setCompletingOrder(order);
    setActiveTab(1);
  }, []);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  // Create filtered tabs based on employee permissions
  const availableTabs = useMemo(() => {
    const tabs = [];

    // Order List tab (always available if canView is true)
    if (permissions.canView) {
      tabs.push({ id: 0, label: "Order List" });
    }

    // Order Assignment tab
    if (permissions.canAssignOrders) {
      tabs.push({ id: 1, label: "Order Assignment" });
    }

    // Collaboration Management tab
    if (permissions.canManageCollaborations) {
      tabs.push({ id: 2, label: "Collaboration Management" });
    }

    // Bulk Import tab
    if (permissions.canImportBulk) {
      tabs.push({ id: 3, label: "Bulk Import" });
    }

    return tabs;
  }, [permissions]);

  // Update filtered groups when groups change
  useEffect(() => {
    if (memoizedGroups) {
      setFilteredGroups(memoizedGroups);
    }
  }, [memoizedGroups]);

  // If employee doesn't have permission to view orders
  if (!permissions.canView) {
    return (
      <Alert severity="warning">
        You don't have permission to access order management.
      </Alert>
    );
  }

  // Map tab index to actual tab content based on permissions
  const getTabContent = (tabIndex) => {
    const tabId = availableTabs[tabIndex]?.id;

    switch (tabId) {
      case 0: // Order List
        return (
          <OrderList
            onCompleteOrder={
              permissions.canEdit ? handleCompleteOrder : undefined
            }
            onDeleteDraftOrder={
              permissions.canDelete ? deleteOrderFromLocalStorage : undefined
            }
            initialSearchQuery={searchQuery}
            initialFilter={initialFilter}
            disabled={!permissions.canEdit}
          />
        );
      case 1: // Order Assignment
        return (
          <OrderAssignment
            orders={memoizedOrders}
            fetchOrders={() => fetchOrders(businessId)}
            groups={memoizedGroups}
            couriers={couriers}
            vehicles={[]}
            locations={locations}
            loading={loading}
          />
        );
      case 2: // Collaboration Management
        return (
          <CollaborationManagementScreen
            businessId={businessId}
            orders={memoizedOrders}
            onRefresh={handleRefresh}
          />
        );
      case 3: // Bulk Import
        return (
          <BulkImport
            onOrdersImport={(importedOrders) =>
              importedOrders.forEach(addOrder)
            }
          />
        );
      default:
        return null;
    }
  };

  // Get page title based on employee role
  const getPageTitle = () => {
    if (employeeRoles.includes("Courier")) {
      return "My Deliveries";
    } else if (employeeRoles.includes("Customer Service")) {
      return "Customer Orders";
    }
    return "Orders Management";
  };

  // New Order button
  const titleActions = (
    <PermissionActionButton
      permission="canAddOrders"
      icon={NoteAddIcon}
      label="New Order"
      onClick={() => setQuickAddOpen(true)}
    />
  );

  return (
    <EmployeePageLayout
      title={getPageTitle()}
      titleActions={titleActions}
      loading={layoutLoading || loading}
      error={layoutError || error || customersError}
      onRetry={handleRefresh}
      maxWidth="wide"
    >
      <DashboardOverview orders={memoizedOrders} />

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant={availableTabs.length > 3 ? "scrollable" : "standard"}
        scrollButtons="auto"
      >
        {availableTabs.map((tab, index) => (
          <Tab key={tab.id} label={tab.label} />
        ))}
      </Tabs>

      <div className="tab-content">
        {getTabContent(activeTab)}

        {permissions.canAddOrders && (
          <QuickAddOrder
            open={quickAddOpen}
            onClose={() => setQuickAddOpen(false)}
            existingCustomers={memoizedCustomers}
            onOrderCreate={(order) => {
              addOrder(order);
            }}
            initialOrder={completingOrder}
          />
        )}
      </div>
    </EmployeePageLayout>
  );
};

const EmployeeOrdersPage = () => {
  // OrdersProvider is already used in App.js to wrap the route
  return (
    <Routes>
      <Route path="/" element={<EmployeeOrdersContent />} />
      <Route path="/:orderId" element={<OrderEditPage />} />
    </Routes>
  );
};

export default EmployeeOrdersPage;
