import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Card,
  CardContent,
  Radio,
  Button,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Add as AddIcon } from "@mui/icons-material";
import CustomerAddressDialog from "../../../customers/CustomerAddressDialog";
import { AuthContext } from "../../../../../components/Auth/AuthContext";
import { isValidPhoneNumber } from "libphonenumber-js";
import isEmail from "validator/lib/isEmail";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import _ from "lodash";
import { useOrders } from "../OrdersContext";

const CustomerInfo = ({
  customer = {},
  title,
  businessId,
  orderId,
  role,
  isReadOnly = false,
}) => {
  const { api } = useContext(AuthContext);
  const { saveCustomerInfo, updateOrderAddress } = useOrders();
  const [currentCustomer, setCurrentCustomer] = useState(customer);
  const [originalCustomer, setOriginalCustomer] = useState(customer);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(
    customer?.address_id
  );
  const [errors, setErrors] = useState({});
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [isAddressCreating, setIsAddressCreating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Initialize component with customer data when it changes
  useEffect(() => {
    if (customer?.customer_id) {
      setCurrentCustomer(customer);
      setOriginalCustomer(customer);
      if (customer?.address_id) {
        setSelectedAddressId(customer.address_id);
      }
    }
  }, [customer]);

  const handleFieldChange = (field, value) => {
    validateField(field, value);
    setCurrentCustomer((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      // Validate all fields before saving
      const fieldsToValidate = [
        "first_name",
        "last_name",
        "email",
        "phone",
        "address",
      ];

      let isValid = true;
      fieldsToValidate.forEach((field) => {
        const value =
          field === "address" ? selectedAddressId : currentCustomer[field];
        const valid = validateField(field, value);
        if (!valid) isValid = false;
      });

      if (!isValid) {
        throw new Error("Please fix the errors before saving.");
      }

      // Save Customer Information
      const updatedCustomer = await saveCustomerInfo(
        currentCustomer.customer_id,
        currentCustomer,
        businessId
      );
      console.log("Customer updated:", updatedCustomer);

      // Update Order's Address if it has changed
      const addressField =
        role === "sender" ? "sender_address_id" : "recipient_address_id";

      if (selectedAddressId !== originalCustomer.address_id) {
        await updateOrderAddress(orderId, role, selectedAddressId, businessId);
        console.log("Order address updated successfully.");

        // Update original order state to reflect changes
        setOriginalCustomer((prev) => ({
          ...prev,
          address_id: selectedAddressId,
        }));
      }

      // Update original customer state
      setCurrentCustomer(updatedCustomer);
      setOriginalCustomer(updatedCustomer);
      setErrors({});
    } catch (error) {
      console.error("Error saving customer info:", error);
      setErrors((prev) => ({
        ...prev,
        save: error.message,
      }));
    }
  };

  const handleReset = () => {
    // Reset to original customer values
    setCurrentCustomer(originalCustomer);
    setSelectedAddressId(originalCustomer.address_id);

    // Optional: Fetch addresses but don't reset the selected address
    if (!isReadOnly) {
      fetchAddresses(false);
    }
  };

  const isChanged = () => {
    return !_.isEqual(currentCustomer, originalCustomer);
  };

  // Keep existing address handling code but update to use currentCustomer
  const handleAddressSelect = (address) => {
    setSelectedAddressId(address.address_id);
    setCurrentCustomer((prev) => ({
      ...prev,
      address_id: address.address_id,
    }));
  };

  // Implement fetchAddresses function with useCallback to be reusable
  const fetchAddresses = useCallback(async (resetSelected = true) => {
    if (currentCustomer?.customer_id) {
      try {
        // In read-only mode, we might already have the specific address we need
        if (isReadOnly && addresses.length > 0 && selectedAddressId && dataLoaded) {
          // No need to fetch all addresses in read-only mode
          return;
        }

        const response = await api.get(
          `/customers/addresses/${currentCustomer.customer_id}`,
          {
            params: { business_id: businessId },
          }
        );
        setAddresses(response.data.addresses);
        setDataLoaded(true);

        // Reset selected address only if resetSelected is true
        if (resetSelected) {
          if (currentCustomer?.address_id) {
            setSelectedAddressId(currentCustomer.address_id);
          } else if (response.data.addresses.length > 0) {
            const defaultAddress =
              response.data.addresses.find((addr) => addr.is_default) ||
              response.data.addresses[0];
            setSelectedAddressId(defaultAddress.address_id);
            setCurrentCustomer((prev) => ({
              ...prev,
              address_id: defaultAddress.address_id,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    }
  }, [api, businessId, currentCustomer?.customer_id, currentCustomer?.address_id, isReadOnly, addresses, selectedAddressId, dataLoaded]);

  const handleSaveAddress = async (addressData) => {
    setIsAddressCreating(true);
    try {
      // Create the address
      const response = await api.post(`/customers/addresses`, {
        customer_id: currentCustomer.customer_id,
        business_id: businessId,
        ...addressData,
      });

      console.log("Address created:", response.data);

      const newAddressId = response.data.address.address_id;

      // Get fresh data
      const addressResponse = await api.get(
        `/customers/addresses/${currentCustomer.customer_id}`,
        {
          params: { business_id: businessId },
        }
      );

      // Update addresses list
      setAddresses(addressResponse.data.addresses);

      // Explicitly select the new address
      setSelectedAddressId(newAddressId);

      handleCloseAddressDialog();
    } catch (error) {
      console.error("Error saving address:", error);
      setErrors((prev) => ({
        ...prev,
        address: "Failed to save address",
      }));
    } finally {
      setIsAddressCreating(false);
    }
  };

  // Initialize the component - fetch addresses for active editing
  useEffect(() => {
    // Always fetch all addresses for consistent display in edit mode
    if (!isReadOnly && currentCustomer?.customer_id) {
      fetchAddresses();
    }
  }, [
    currentCustomer?.customer_id,
    businessId,
    fetchAddresses,
    isReadOnly
  ]);

  // Load customer and specific address for read-only mode
  useEffect(() => {
    const fetchCustomerWithSpecificAddress = async () => {
      if (isReadOnly && customer?.customer_id && customer?.address_id && !dataLoaded) {
        try {
          // Make the API call using the same endpoint, but include order_id if available
          const params = orderId
            ? { business_id: businessId, order_id: orderId }
            : { business_id: businessId };
            
          const response = await api.get(
            `/customers/customer-specific-address/${customer.customer_id}/${customer.address_id}`,
            { params }
          );
          
          if (response.data?.customer) {
            // Set the customer data and the specific address
            setCurrentCustomer(response.data.customer);
            setOriginalCustomer(response.data.customer);
            
            // Set addresses from the response
            if (response.data.customer.Addresses && response.data.customer.Addresses.length > 0) {
              setAddresses(response.data.customer.Addresses);
              setSelectedAddressId(customer.address_id);
              setDataLoaded(true);
            }
          }
        } catch (error) {
          console.error("Error fetching customer with specific address:", error);
          // Only try fallback if we're not using order_id (not a collaborator)
          if (!orderId) {
            fetchAddresses();
          }
        }
      }
    };

    fetchCustomerWithSpecificAddress();
  }, [isReadOnly, customer?.customer_id, customer?.address_id, api, businessId, orderId, fetchAddresses, dataLoaded]);

  // Update selected address when customer's address_id changes
  useEffect(() => {
    if (currentCustomer?.address_id) {
      setSelectedAddressId(currentCustomer.address_id);
    }
  }, [currentCustomer?.address_id]);

  const validateField = (field, value) => {
    let error = null;
    switch (field) {
      case "first_name":
      case "last_name":
        if (!value?.trim()) {
          error = `${field.replace("_", " ")} is required`;
        }
        break;
      case "email":
        if (value && !isEmail(value)) {
          error = "Invalid email format";
        }
        break;
      case "phone":
        if (!value) {
          error = "Phone is required";
        } else if (!isValidPhoneNumber(value)) {
          error = "Invalid phone number";
        }
        break;
      case "address_line1":
      case "city":
      case "state":
      case "postal_code":
      case "country":
        if (!currentCustomer?.customer_id && !value?.trim()) {
          error = `${field.replace("_", " ")} is required`;
        }
        break;
      case "address":
        if (!selectedAddressId) {
          error = "Address is required";
        }
      default:
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));

    return !error;
  };

  const handleAddNewAddress = () => {
    setAddressDialogOpen(true);
  };

  const handleCloseAddressDialog = () => {
    setAddressDialogOpen(false);
  };

  const renderAddressSection = () => {
    if (currentCustomer?.customer_id) {
      // Show address selection for consistency in both edit and read-only modes
      return (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Select Address
          </Typography>
          {addresses.map((address) => (
            <Card
              key={address.address_id}
              variant="outlined"
              sx={{
                mb: 1,
                cursor: isReadOnly ? "default" : "pointer",
                bgcolor:
                  selectedAddressId === address.address_id
                    ? "primary.light"
                    : "background.paper",
              }}
              onClick={() => !isReadOnly && handleAddressSelect(address)}
            >
              <CardContent>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <Typography variant="body1">
                      {address.address_line1}
                      {address.address_line2 && `, ${address.address_line2}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {address.city}, {address.state} {address.postal_code}
                      {address.is_default && " (Default)"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Radio
                      checked={selectedAddressId === address.address_id}
                      onChange={() => !isReadOnly && handleAddressSelect(address)}
                      disabled={isReadOnly}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
          <Tooltip title={isReadOnly ? "Cannot add addresses in read-only mode" : "Add a new address"}>
            <span>  {/* Span wrapper for disabled button tooltip */}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddNewAddress}
                variant="outlined"
                fullWidth
                sx={{ mt: 1 }}
                disabled={isReadOnly}
              >
                Add New Address
              </Button>
            </span>
          </Tooltip>
          {errors.address && (
            <FormHelperText error>{errors.address}</FormHelperText>
          )}

          <CustomerAddressDialog
            open={addressDialogOpen}
            onClose={handleCloseAddressDialog}
            onSave={handleSaveAddress}
            address={null}
            isNew={true}
          />
        </Box>
      );
    }

    // For new customer or fallback, show address form
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Address Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Address Line 1"
              value={currentCustomer?.address_line1 || ""}
              onChange={(e) =>
                handleFieldChange("address_line1", e.target.value)
              }
              error={!!errors.address_line1}
              helperText={errors.address_line1}
              onBlur={(e) => validateField("address_line1", e.target.value)}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              value={currentCustomer?.address_line2 || ""}
              onChange={(e) =>
                handleFieldChange("address_line2", e.target.value)
              }
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="City"
              value={currentCustomer?.city || ""}
              onChange={(e) => handleFieldChange("city", e.target.value)}
              error={!!errors.city}
              helperText={errors.city}
              onBlur={(e) => validateField("city", e.target.value)}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="State"
              value={currentCustomer?.state || ""}
              onChange={(e) => handleFieldChange("state", e.target.value)}
              error={!!errors.state}
              helperText={errors.state}
              onBlur={(e) => validateField("state", e.target.value)}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Postal Code"
              value={currentCustomer?.postal_code || ""}
              onChange={(e) => handleFieldChange("postal_code", e.target.value)}
              error={!!errors.postal_code}
              helperText={errors.postal_code}
              onBlur={(e) => validateField("postal_code", e.target.value)}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Country"
              value={currentCustomer?.country || ""}
              onChange={(e) => handleFieldChange("country", e.target.value)}
              error={!!errors.country}
              helperText={errors.country}
              onBlur={(e) => validateField("country", e.target.value)}
              disabled={isReadOnly}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Left column: first name, last name, email, phone */}

      <Grid item xs={12} sm={6}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Customer Information
          </Typography>
          <TextField
            fullWidth
            label="First Name"
            value={currentCustomer?.first_name || ""}
            onChange={(e) => handleFieldChange("first_name", e.target.value)}
            error={!!errors.first_name}
            helperText={errors.first_name}
            required
            onBlur={(e) => validateField("first_name", e.target.value)}
            sx={{ mb: 2 }}
            disabled={isReadOnly}
          />

          <TextField
            fullWidth
            label="Last Name"
            value={currentCustomer?.last_name || ""}
            onChange={(e) => handleFieldChange("last_name", e.target.value)}
            error={!!errors.last_name}
            helperText={errors.last_name}
            required
            onBlur={(e) => validateField("last_name", e.target.value)}
            sx={{ mb: 2 }}
            disabled={isReadOnly}
          />

          <TextField
            fullWidth
            label="Email"
            value={currentCustomer?.email || ""}
            onChange={(e) => handleFieldChange("email", e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            onBlur={(e) => validateField("email", e.target.value)}
            sx={{ mb: 2 }}
            disabled={isReadOnly}
          />
          <MuiTelInput
            fullWidth
            label="Phone"
            value={currentCustomer?.phone || ""}
            onChange={(value) => handleFieldChange("phone", value)}
            error={!!errors.phone}
            helperText={errors.phone}
            required
            onBlur={(e) => validateField("phone", e.target.value)}
            disabled={isReadOnly}
          />
        </Box>
      </Grid>

      {/* Right column: address section */}
      <Grid item xs={12} sm={6}>
        {renderAddressSection()}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Tooltip title={isReadOnly ? "Cannot save in read-only mode" : "Save changes"}>
            <span>  {/* Span wrapper for disabled button tooltip */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSave}
                disabled={
                  !isChanged() || Object.values(errors).some((e) => e) || isReadOnly
                }
              >
                Save {title}
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={isReadOnly ? "Cannot reset in read-only mode" : "Reset changes"}>
            <span>  {/* Span wrapper for disabled button tooltip */}
              <Button
                variant="outlined"
                startIcon={<UndoIcon />}
                onClick={handleReset}
                disabled={!isChanged() || isReadOnly}
              >
                Reset
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Grid>
      {errors.save && (
        <FormHelperText error sx={{ mt: 1 }}>
          {errors.save}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default CustomerInfo;
