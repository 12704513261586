import React from "react";
import { useParams } from "react-router-dom";
import UnifiedCustomerManager from "../../../components/Customer/UnifiedCustomerManager";
import styles from "./BusinessCustomersPage.module.css";

const BusinessCustomersPage = ({ isEmployeePortal = false, customerId }) => {
  const { businessId } = useParams();

  return (
    <div className="business-page">
      <main className="business-page-content">
        <h1 className={styles.title}>Manage Customers</h1>
        <UnifiedCustomerManager
          businessId={businessId}
          isEmployeePortal={isEmployeePortal}
        />
      </main>
    </div>
  );
};

export default BusinessCustomersPage;
