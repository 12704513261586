import React, { useState, useEffect, useContext } from "react";
import { CollaborationPaymentDialog } from "../../../../components/Payments";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Box,
  Tabs,
  Tab,
  Divider,
  Alert,
  Card,
  CardContent,
  Avatar,
  CircularProgress,
} from "@mui/material";
import {
  CompareArrows as CompareArrowsIcon,
  Check as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  History as HistoryIcon,
  Business as BusinessIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../components/Auth/AuthContext";
import CollaborationActions from "./CollaborationActions";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import PaymentIcon from "@mui/icons-material/Payment";
import CollaborationComponent from "./CollaborationComponent";

const CollaborationDetailsDialog = ({
  open,
  onClose,
  collaborations,
  businessId,
  orderStatus,
  onActionComplete,
  onStartAgain,
}) => {
  const { api } = useContext(AuthContext);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCollaborationOpen, setIsCollaborationOpen] = useState(false);
  const [selectedCollaborationForNew, setSelectedCollaborationForNew] = useState(null);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [selectedCollaborationForPayment, setSelectedCollaborationForPayment] = useState(null);

  const handleStartAgain = (collaboration) => {
    setSelectedCollaborationForNew(collaboration);
    setIsCollaborationOpen(true);
  };

  const handleCollaborationClose = () => {
    setIsCollaborationOpen(false);
    setSelectedCollaborationForNew(null);
  };

  const handleCollaborationComplete = async () => {
    if (onActionComplete) {
      await onActionComplete();
    }
    handleCollaborationClose();
  };

  // Format currency helper
  const formatCurrency = (value) => {
    if (!value) return "$0.00";

    // If it's already a string with a $ sign, return it
    if (typeof value === "string" && value.startsWith("$")) return value;

    // Parse the value to a float and format it
    const amount = parseFloat(value);
    if (isNaN(amount)) return "$0.00";

    return `$${amount.toFixed(2)}`;
  };

  // Process collaborations data
  useEffect(() => {
    console.log("Collaborations received: ", collaborations);

    if (
      !collaborations ||
      (Array.isArray(collaborations) && collaborations.length === 0)
    ) {
      console.log("No collaborations to process");
      return;
    }

    // Handle both single collaboration and array of collaborations
    const collabArray = Array.isArray(collaborations)
      ? collaborations.filter((c) => c !== null && c !== undefined)
      : [collaborations].filter((c) => c !== null && c !== undefined);

    // Remove duplicates if any
    const uniqueCollabs = collabArray.filter(
      (collab, index, self) =>
        collab &&
        collab.collaboration_id &&
        index ===
          self.findIndex(
            (c) => c && c.collaboration_id === collab.collaboration_id
          )
    );

    console.log("Processed collaborations: ", uniqueCollabs);
  }, [collaborations]);

  // Sort collaborations by date (newest first) when the component mounts or collaborations change
  useEffect(() => {
    console.log("Collaborations changed:", collaborations);
    if (collaborations?.length > 0) {
      // Filter out any null or undefined collaborations
      const validCollaborations = collaborations.filter(
        (collab) => collab !== null && collab !== undefined
      );

      // Sort valid collaborations
      const sortedCollaborations = [...validCollaborations].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setSelectedTab(0); // Reset to first tab when collaborations change
      console.log("Sorted collaborations:", sortedCollaborations);
      console.log("Selected collaboration:", sortedCollaborations[0]);

      // Fetch business details for the latest collaboration
      if (sortedCollaborations[0]?.collaborating_business_id) {
        fetchBusinessDetails(sortedCollaborations[0].collaborating_business_id);
      }
    }
  }, [collaborations]);

  // FIX: Pass `business_ids` in snake case to match server-side.
  const fetchBusinessDetails = async (collaboratingBusinessId) => {
    if (!collaboratingBusinessId) return;

    setLoading(true);
    try {
      const response = await api.get("/shipping-collaboration/businesses", {
        params: { business_ids: [collaboratingBusinessId] },
      });
      setBusinessDetails(response.data[0]);
    } catch (error) {
      console.error("Error fetching business details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (collaborations[newValue]?.collaborating_business_id) {
      fetchBusinessDetails(collaborations[newValue].collaborating_business_id);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "proposed":
        return "warning";
      case "counter_offered":
        return "info";
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      case "cancelled":
        return "error";
      case "completed":
        return "success";
      default:
        return "default";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "proposed":
        return <CompareArrowsIcon />;
      case "counter_offered":
        return <EditIcon />;
      case "accepted":
        return <CheckCircleIcon />;
      case "rejected":
        return <CancelIcon />;
      case "cancelled":
        return <CancelIcon />;
      case "completed":
        return <CheckCircleIcon />;
      default:
        return <HistoryIcon />;
    }
  };

  const renderCollaborationTimeline = (collaborationHistory) => {
    if (!collaborationHistory?.length) return null;

    const renderShippingTier = (tier) => {
      if (typeof tier === "string") {
        try {
          tier = JSON.parse(tier);
        } catch (e) {
          console.error("Error parsing shipping tier:", e);
          return (
            <Typography variant="body2">
              Error parsing shipping tier data
            </Typography>
          );
        }
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">Base Price: {tier.base_price}</Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body2">
              Weight-based:{" "}
              {formatCurrency(tier.pricing_details?.base_price_weight)}
            </Typography>
            <Typography variant="body2">
              Dimensions-based:{" "}
              {formatCurrency(tier.pricing_details?.base_price_dimensions)}
            </Typography>
            <Typography variant="body2">
              Value-based:{" "}
              {formatCurrency(tier.pricing_details?.base_price_value)}
            </Typography>
          </Box>
        </Box>
      );
    };

    const renderOrderDetails = (details) => {
      if (typeof details === "string") {
        try {
          details = JSON.parse(details);
        } catch (e) {
          console.error("Error parsing order details:", e);
          return (
            <Typography variant="body2">Error parsing order details</Typography>
          );
        }
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">Order ID: {details.order_id}</Typography>
          <Typography variant="body2">
            Package Count: {details.package_count}
          </Typography>
          <Typography variant="body2">
            Total Weight: {details.total_weight} lb
          </Typography>
        </Box>
      );
    };

    const renderFinancialDetails = (details) => {
      if (typeof details === "string") {
        try {
          details = JSON.parse(details);
        } catch (e) {
          console.error("Error parsing financial details:", e);
          return (
            <Typography variant="body2">
              Error parsing financial details
            </Typography>
          );
        }
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">
            Calculated Price: {details.calculated_price}
          </Typography>
          <Typography variant="body2">
            Agreed Compensation: {details.agreed_compensation}
          </Typography>
          <Typography variant="body2">
            Total Cost: {details.total_cost}
          </Typography>
          {details.additional_charges?.length > 0 && (
            <Box sx={{ ml: 2 }}>
              <Typography variant="body2">Additional Charges:</Typography>
              {details.additional_charges.map((charge, idx) => (
                <Typography key={idx} variant="body2">
                  - {charge.description}: {formatCurrency(charge.amount)}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      );
    };

    const renderCollaborationSpecifics = (specifics) => {
      if (typeof specifics === "string") {
        try {
          specifics = JSON.parse(specifics);
        } catch (e) {
          console.error("Error parsing collaboration specifics:", e);
          return (
            <Typography variant="body2">
              Error parsing collaboration details
            </Typography>
          );
        }
      }
      return (
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2">Type: {specifics.type}</Typography>
          {specifics.details?.notes && (
            <Typography variant="body2">
              Notes: {specifics.details.notes}
            </Typography>
          )}
          <Typography variant="body2">
            Special Instructions:{" "}
            {specifics.special_instructions || "None provided"}
          </Typography>
        </Box>
      );
    };

    const renderChanges = (changes) => {
      return Object.entries(changes).map(([key, value]) => {
        const formattedKey = key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase());

        // Handle additional charges changes
        if (key === "additional_charges") {
          const oldTotal =
            typeof value.old === "string"
              ? JSON.parse(value.old).total
              : value.old.total;
          const newTotal =
            typeof value.new === "string"
              ? JSON.parse(value.new).total
              : value.new.total;
          return (
            <Typography key={key} variant="body2" sx={{ ml: 2 }}>
              Additional Charges: {oldTotal} → {newTotal}
            </Typography>
          );
        }

        return (
          <Typography key={key} variant="body2" sx={{ ml: 2 }}>
            {formattedKey}: {value.old} → {value.new}
          </Typography>
        );
      });
    };

    return (
      <Timeline>
        {collaborationHistory.map((entry, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent color="text.secondary">
              {formatDate(entry.timestamp)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={getStatusColor(entry.action)}>
                {getStatusIcon(entry.action)}
              </TimelineDot>
              {index < collaborationHistory.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">
                  {entry.action.charAt(0).toUpperCase() +
                    entry.action.slice(1).toLowerCase().replace("_", " ")}{" "}
                  by{" "}
                  {entry.actor_type === "originator"
                    ? "Originator"
                    : "Collaborator"}
                </Typography>

                {entry.details && (
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Additional Details:
                    </Typography>
                    {entry.details.shipping_tier && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Shipping Tier:
                        </Typography>
                        {renderShippingTier(entry.details.shipping_tier)}
                      </>
                    )}
                    {entry.details.order_details && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Order Details:
                        </Typography>
                        {renderOrderDetails(entry.details.order_details)}
                      </>
                    )}
                    {entry.details.financial_details && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Financial Details:
                        </Typography>
                        {renderFinancialDetails(
                          entry.details.financial_details
                        )}
                      </>
                    )}
                    {entry.details.collaboration_specifics && (
                      <>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Collaboration Specifics:
                        </Typography>
                        {renderCollaborationSpecifics(
                          entry.details.collaboration_specifics
                        )}
                      </>
                    )}
                  </Box>
                )}

                {entry.changes && Object.keys(entry.changes).length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Changes:
                    </Typography>
                    {renderChanges(entry.changes)}
                  </Box>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  const renderBusinessDetails = (currentCollaboration) => {
    if (loading) {
      return (
        <Box>
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="text" width="80%" sx={{ mt: 1 }} />
          <Skeleton variant="text" width="70%" />
          <Skeleton variant="text" width="60%" />
        </Box>
      );
    }

    if (!businessDetails) {
      return (
        <Alert severity="error" sx={{ mt: 1 }}>
          Failed to load business details
        </Alert>
      );
    }

    return (
      <Card variant="outlined" sx={{ mt: 1 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            {businessDetails.business_profile_picture ? (
              <Avatar
                src={businessDetails.business_profile_picture}
                alt={businessDetails.business_name}
                sx={{ width: 56, height: 56, mr: 2 }}
              />
            ) : (
              <Avatar sx={{ width: 56, height: 56, mr: 2 }}>
                <BusinessIcon />
              </Avatar>
            )}
            <Box>
              <Typography variant="h6" component="div">
                {businessDetails.business_name}
              </Typography>
              <Chip
                size="small"
                label={
                  currentCollaboration.originating_business_id === businessId
                    ? "Collaborator"
                    : "Requester"
                }
                color={
                  currentCollaboration.originating_business_id === businessId
                    ? "secondary"
                    : "primary"
                }
                sx={{ mt: 0.5 }}
              />
            </Box>
          </Box>

          <List dense>
            <ListItem>
              <EmailIcon sx={{ mr: 2, color: "action.active" }} />
              <ListItemText
                primary="Email"
                secondary={businessDetails.business_email}
              />
            </ListItem>

            <ListItem>
              <PhoneIcon sx={{ mr: 2, color: "action.active" }} />
              <ListItemText
                primary="Phone"
                secondary={businessDetails.business_phone}
              />
            </ListItem>

            {businessDetails.BusinessAddresses?.[0] && (
              <ListItem>
                <LocationIcon sx={{ mr: 2, color: "action.active" }} />
                <ListItemText
                  primary="Location"
                  secondary={`${businessDetails.BusinessAddresses[0].business_address_city}, ${businessDetails.BusinessAddresses[0].business_address_country}`}
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    );
  };

  const handleActionComplete = async (updatedCollaboration) => {
    try {
      // Fetch the updated order to get latest collaboration status
      const response = await api.get(
        `/shipping-orders/${updatedCollaboration.shipping_order_id}`
      );

      // Dispatch the ordersUpdated event with the updated order
      window.dispatchEvent(
        new CustomEvent("ordersUpdated", {
          detail: [response.data],
        })
      );

      // Call original onActionComplete if provided
      if (onActionComplete) {
        onActionComplete();
      }

      // Close the dialog
      onClose();
    } catch (error) {
      console.error("Error fetching updated order:", error);
    }
  };

  const renderCollaborationDetails = (selectedCollaboration) => {
    if (!selectedCollaboration) {
      return (
        <Box sx={{ p: 2 }}>
          <Alert severity="info">No collaboration details available</Alert>
        </Box>
      );
    }

    try {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                Collaboration ID: {selectedCollaboration.collaboration_id}
              </Typography>
              <Box>
                {/* Display collaboration role */}
                <Chip
                  label={
                    selectedCollaboration.originating_business_id === businessId
                      ? "Sent"
                      : "Received"
                  }
                  color={
                    selectedCollaboration.originating_business_id === businessId
                      ? "primary"
                      : "secondary"
                  }
                  size="small"
                  sx={{ mr: 1 }}
                />
                <Chip
                  label={selectedCollaboration.status}
                  color={getStatusColor(selectedCollaboration.status)}
                />
              </Box>
            </Box>
          </Grid>

          {/* Business Details */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">
              {selectedCollaboration.originating_business_id === businessId
                ? "Collaborating With"
                : "Collaboration From"}
            </Typography>
            {renderBusinessDetails(selectedCollaboration)}
          </Grid>

          {/* Financial Details */}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Financial Details</Typography>
            <Card variant="outlined" sx={{ mt: 1 }}>
              <CardContent>
                <List dense>
                  {/* Payment Status Indicator */}
                  <ListItem>
                  <ListItemText
                  primary="Payment Status"
                  secondary={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Chip
                    size="small"
                  label={
                      selectedCollaboration.payment_status || "unpaid"
                    }
                  color={
                  selectedCollaboration.payment_status === "paid"
                    ? "success"
                  : selectedCollaboration.payment_status ===
                      "disputed"
                    ? "error"
                  : selectedCollaboration.payment_status ===
                      "partial"
                    ? "warning"
                        : "default"
                    }
                    sx={{ mt: 0.5, mr: 1 }}
                  icon={
                  selectedCollaboration.payment_status === "paid" ? (
                      <CheckCircleIcon />
                  ) : (
                      <PaymentIcon />
                      )
                      }
                      />
                        {/* Add Payment Button for unpaid collaborations when current business is the payer */}
                          {(selectedCollaboration.payment_status !== "paid" && 
                               selectedCollaboration.status === "accepted" &&
                               selectedCollaboration.originating_business_id === businessId) && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<PaymentIcon />}
                                  onClick={() => {
                                    setSelectedCollaborationForPayment(selectedCollaboration);
                                    setIsPaymentDialogOpen(true);
                                  }}
                                >
                                  Pay Now
                                </Button>
                              )}
                            </Box>
                          }
                        />
                      </ListItem>

                  {selectedCollaboration.agreed_compensation && (
                    <ListItem>
                      <ListItemText
                        primary="Agreed Compensation"
                        secondary={formatCurrency(
                          selectedCollaboration.agreed_compensation
                        )}
                      />
                    </ListItem>
                  )}
                  {selectedCollaboration.calculated_price && (
                    <ListItem>
                      <ListItemText
                        primary="Calculated Price"
                        secondary={formatCurrency(
                          selectedCollaboration.calculated_price
                        )}
                      />
                    </ListItem>
                  )}
                  {selectedCollaboration.total_cost && (
                    <ListItem>
                      <ListItemText
                        primary="Total Cost"
                        secondary={formatCurrency(
                          selectedCollaboration.total_cost
                        )}
                      />
                    </ListItem>
                  )}
                  {selectedCollaboration.additional_charges?.length > 0 && (
                    <ListItem>
                      <ListItemText
                        primary="Additional Charges"
                        secondary={
                          <Box component="div" sx={{ mt: 1 }}>
                            {selectedCollaboration.additional_charges.map(
                              (charge, index) => (
                                <Typography key={index} variant="body2">
                                  {charge.description}:{" "}
                                  {formatCurrency(charge.amount)}
                                </Typography>
                              )
                            )}
                          </Box>
                        }
                      />
                    </ListItem>
                  )}

                  {/* Display receipt information if available */}
                  {selectedCollaboration.receipt_number && (
                    <ListItem>
                      <ListItemText
                        primary="Receipt Information"
                        secondary={
                          <Box component="div" sx={{ mt: 1 }}>
                            <Typography variant="body2">
                              Receipt #: {selectedCollaboration.receipt_number}
                            </Typography>
                            {selectedCollaboration.payment_details
                              ?.payment_date && (
                              <Typography variant="body2">
                                Date:{" "}
                                {new Date(
                                  selectedCollaboration.payment_details.payment_date
                                ).toLocaleDateString()}
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          {/* Additional Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Additional Information</Typography>
            <Card variant="outlined" sx={{ mt: 1 }}>
              <CardContent>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary="Collaboration Details"
                      secondary={
                        selectedCollaboration.collaboration_details?.notes ||
                        "No notes provided"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Special Instructions"
                      secondary={
                        selectedCollaboration.special_instructions ||
                        "No special instructions"
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>

          {/* History Timeline */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Collaboration History</Typography>
            <Card variant="outlined" sx={{ mt: 1, mb: 2 }}>
              <CardContent>
                {renderCollaborationTimeline(
                  selectedCollaboration.collaboration_history
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Actions */}
          <Grid item xs={12}>
            <Grid item xs={12}>
              <CollaborationActions
                collaboration={selectedCollaboration}
                businessId={businessId}
                orderStatus={orderStatus}
                onActionComplete={handleActionComplete}
                onStartAgain={handleStartAgain}
                allCollaborations={collaborations}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    } catch (error) {
      console.error("Error rendering collaboration details:", error);
      return (
        <Box sx={{ p: 2 }}>
          <Alert severity="error">
            An error occurred while displaying collaboration details. Please try
            again.
          </Alert>
        </Box>
      );
    }
  };

  const sortedCollaborations =
    collaborations && collaborations.length > 0
      ? [...collaborations]
          .filter((c) => c !== null && c !== undefined) // Filter out null/undefined
          .sort((a, b) => {
            // Ensure both items have createdAt before sorting
            if (!a || !b || !a.createdAt || !b.createdAt) return 0;
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
      : [];

  const handlePaymentComplete = async (paymentData) => {
    // Close the payment dialog
    setIsPaymentDialogOpen(false);
    setSelectedCollaborationForPayment(null);
    
    // Refresh collaboration data
    if (onActionComplete) {
      await onActionComplete();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        TransitionProps={{
          onExited: () => {
            setSelectedTab(0);
            setBusinessDetails(null);
          },
        }}
      >
        <DialogTitle>
          {Array.isArray(collaborations) && collaborations.length > 1
            ? `Multiple Collaborations (${collaborations.length})`
            : "Collaboration Details"}
        </DialogTitle>
        <DialogContent>
          {sortedCollaborations.length > 0 ? (
            <>
              <Tabs
                value={
                  selectedTab < sortedCollaborations.length ? selectedTab : 0
                }
                onChange={handleTabChange}
                sx={{ mb: 2 }}
                variant={
                  sortedCollaborations.length > 3 ? "scrollable" : "standard"
                }
                scrollButtons="auto"
              >
                {sortedCollaborations.map((collab, index) => {
                  if (!collab) return null; // Skip if collaboration is null or undefined

                  const isOriginatingBusiness =
                    collab.originating_business_id === businessId;

                  // Get partner name with better fallbacks
                  let partnerName = "Unknown";
                  let partnerId = "";

                  if (isOriginatingBusiness) {
                    // We are the originator, so show the collaborating business
                    if (collab.CollaboratingBusiness?.business_name) {
                      partnerName = collab.CollaboratingBusiness.business_name;
                    } else if (collab.collaborating_business_id) {
                      partnerName = "Unknown";
                    }
                  } else {
                    // We are the collaborator, so show the originating business
                    if (collab.OriginatingBusiness?.business_name) {
                      partnerName = collab.OriginatingBusiness.business_name;
                    } else if (collab.originating_business_id) {
                      partnerName = "Unknown";
                    }
                  }

                  const role = isOriginatingBusiness ? "Sent" : "Received";

                  return (
                    <Tab
                      key={collab.collaboration_id || `collab-${index}`}
                      label={
                        sortedCollaborations.length > 1
                          ? `${role}: ${partnerName?.substring(0, 15)}${
                              partnerName?.length > 15 ? "..." : ""
                            }`
                          : `${role} Collaboration`
                      }
                      icon={getStatusIcon(collab.status)}
                      iconPosition="start"
                    />
                  );
                })}
              </Tabs>
              <Divider sx={{ mb: 2 }} />
              {selectedTab < sortedCollaborations.length &&
                renderCollaborationDetails(sortedCollaborations[selectedTab])}
            </>
          ) : (
            <Box sx={{ p: 2 }}>
              <Alert severity="info">No collaboration details available</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CollaborationComponent
        open={isCollaborationOpen}
        onClose={handleCollaborationClose}
        selectedOrders={
          selectedCollaborationForNew
            ? [selectedCollaborationForNew.shipping_order_id]
            : []
        }
        businessId={businessId}
        onCollaborationUpdate={handleCollaborationComplete}
      />
      
      {/* Payment Dialog */}
      {selectedCollaborationForPayment && (
        <CollaborationPaymentDialog
          open={isPaymentDialogOpen}
          onClose={() => setIsPaymentDialogOpen(false)}
          collaboration={selectedCollaborationForPayment}
          onPaymentComplete={handlePaymentComplete}
        />
      )}
    </>
  );
};

export default CollaborationDetailsDialog;
