import React, { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import "./ScrollHeader.css";
import logo from "../../assets/logo-plain.svg";

const ScrollHeader = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
      
      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <header className={`scroll-header ${visible ? 'visible' : 'hidden'}`}>
      <div className="logo-container">
        <a href="/">
          <img src={logo} alt="Your App Logo" className="logo" />
        </a>
      </div>
      <div className="navigation-container">
        <Navigation />
      </div>
    </header>
  );
};

export default ScrollHeader;