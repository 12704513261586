import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
  Box,
  IconButton,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  useTheme,
} from "@mui/material";
import {
  Receipt as ReceiptIcon,
  Schedule as ScheduleIcon,
  Payment as PaymentIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Refresh as RefreshIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  ViewList as ViewListIcon,
} from "@mui/icons-material";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isEqual,
  isSameDay,
  addMonths,
  subMonths,
  addDays,
  isBefore,
} from "date-fns";
import { AuthContext } from "../Auth/AuthContext";
import CollaborationPaymentDialog from "./CollaborationPaymentDialog";

// Calendar component for scheduled payments
const PaymentCalendar = ({ scheduledPayments, onSelectPayment }) => {
  const theme = useTheme();
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const getPaymentsForDay = (day) => {
    return scheduledPayments.filter(
      (payment) =>
        payment.scheduled_date &&
        isSameDay(new Date(payment.scheduled_date), day)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button onClick={handlePrevMonth}>&lt; Previous</Button>
        <Typography variant="h6">
          {format(currentMonth, "MMMM yyyy")}
        </Typography>
        <Button onClick={handleNextMonth}>Next &gt;</Button>
      </Box>

      <Grid container spacing={1}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <Grid item xs={12 / 7} key={day}>
            <Box
              sx={{
                p: 1,
                textAlign: "center",
                fontWeight: "bold",
                bgcolor: theme.palette.grey[100],
                borderRadius: "4px 4px 0 0",
              }}
            >
              {day}
            </Box>
          </Grid>
        ))}

        {daysInMonth.map((day) => {
          const paymentsForDay = getPaymentsForDay(day);
          return (
            <Grid item xs={12 / 7} key={day.toString()}>
              <Box
                sx={{
                  height: 80,
                  p: 1,
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: 1,
                  position: "relative",
                  bgcolor:
                    paymentsForDay.length > 0
                      ? theme.palette.info.light
                      : "inherit",
                  "&:hover": {
                    bgcolor:
                      paymentsForDay.length > 0
                        ? theme.palette.info.light
                        : theme.palette.grey[100],
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    fontWeight: paymentsForDay.length > 0 ? "bold" : "regular",
                  }}
                >
                  {format(day, "d")}
                </Typography>

                {paymentsForDay.length > 0 && (
                  <Box sx={{ mt: 4, textAlign: "center" }}>
                    <Chip
                      size="small"
                      label={`${paymentsForDay.length} payment${
                        paymentsForDay.length > 1 ? "s" : ""
                      }`}
                      color="primary"
                      onClick={() => {
                        // If there's only one payment, select it directly
                        if (paymentsForDay.length === 1) {
                          onSelectPayment(paymentsForDay[0]);
                        } else {
                          // TODO: Show a list of payments for this day
                          alert(
                            `${
                              paymentsForDay.length
                            } payments scheduled for ${format(
                              day,
                              "MMM d, yyyy"
                            )}`
                          );
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const PaymentStatusChip = ({ status, escrowStatus }) => {
  // Function to determine chip color and icon based on payment status
  const getConfig = () => {
    // Handle escrow statuses first
    if (status === "in_escrow") {
      switch (escrowStatus) {
        case "held":
          return {
            color: "info",
            icon: <InfoIcon fontSize="small" />,
            label: "In Escrow",
          };
        case "released":
          return {
            color: "success",
            icon: <CheckCircleIcon fontSize="small" />,
            label: "Released",
          };
        case "cancelled":
          return {
            color: "error",
            icon: <ErrorIcon fontSize="small" />,
            label: "Cancelled",
          };
        default:
          return {
            color: "info",
            icon: <InfoIcon fontSize="small" />,
            label: "In Escrow",
          };
      }
    }

    // Handle other payment statuses
    switch (status) {
      case "completed":
        return {
          color: "success",
          icon: <CheckCircleIcon fontSize="small" />,
          label: "Completed",
        };
      case "pending":
        return {
          color: "warning",
          icon: <WarningIcon fontSize="small" />,
          label: "Pending",
        };
      case "processing":
        return {
          color: "info",
          icon: <InfoIcon fontSize="small" />,
          label: "Processing",
        };
      case "failed":
        return {
          color: "error",
          icon: <ErrorIcon fontSize="small" />,
          label: "Failed",
        };
      case "refunded":
        return {
          color: "secondary",
          icon: <ReceiptIcon fontSize="small" />,
          label: "Refunded",
        };
      case "disputed":
        return {
          color: "error",
          icon: <ErrorIcon fontSize="small" />,
          label: "Disputed",
        };
      default:
        return { color: "default", icon: null, label: status };
    }
  };

  const config = getConfig();

  return (
    <Chip
      label={config.label}
      color={config.color}
      size="small"
      icon={config.icon}
    />
  );
};

const ReceiptDialog = ({ open, onClose, receipt }) => {
  if (!receipt) return null;

  // Get escrow info if available
  const escrowStatus =
    receipt.status === "in_escrow"
      ? receipt.escrow_status || receipt.metadata?.escrow_status
      : null;
  const releaseHistory = receipt.release_history || [];

  // Calculate expected release dates for escrow payments
  const getExpectedReleaseInfo = () => {
    if (receipt.status !== "in_escrow" || escrowStatus !== "held") {
      return null;
    }

    // Get delivery date from order if available
    const deliveryDate =
      receipt.delivery_confirmation_date ||
      receipt.metadata?.delivery_date ||
      receipt.collaboration?.ShippingOrder?.delivery_date;

    if (!deliveryDate) return null;

    const initialRelease = addDays(new Date(deliveryDate), 3); // 3-day initial hold
    const finalRelease = addDays(new Date(deliveryDate), 14); // 14-day final hold

    return {
      initialRelease,
      finalRelease,
      isInitialReleaseEligible: isBefore(initialRelease, new Date()),
      isFinalReleaseEligible: isBefore(finalRelease, new Date()),
    };
  };

  const releaseInfo = getExpectedReleaseInfo();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Payment Receipt</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Receipt #{receipt.receipt_id || receipt.payment_id}
          </Typography>
          <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Payment Date
                  </TableCell>
                  <TableCell>
                    {receipt.payment_date
                      ? format(new Date(receipt.payment_date), "PPP")
                      : "Not processed"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Payment ID
                  </TableCell>
                  <TableCell>{receipt.payment_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Amount
                  </TableCell>
                  <TableCell>
                    ${parseFloat(receipt.amount).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>
                    <PaymentStatusChip
                      status={receipt.status}
                      escrowStatus={escrowStatus}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Payment Method
                  </TableCell>
                  <TableCell>
                    {receipt.payment_details?.card_brand ||
                      receipt.metadata?.card_brand ||
                      "Card"}{" "}
                    ••••{" "}
                    {receipt.payment_details?.card_last4 ||
                      receipt.metadata?.card_last4 ||
                      "****"}
                  </TableCell>
                </TableRow>

                {/* Escrow-specific information */}
                {receipt.status === "in_escrow" && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Escrow Status
                      </TableCell>
                      <TableCell>
                        {escrowStatus === "held"
                          ? "Funds Held in Escrow"
                          : escrowStatus === "released"
                          ? "Funds Released"
                          : escrowStatus === "cancelled"
                          ? "Escrow Cancelled"
                          : "Unknown"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Escrow Date
                      </TableCell>
                      <TableCell>
                        {receipt.escrow_date || receipt.metadata?.escrow_date
                          ? format(
                              new Date(
                                receipt.escrow_date ||
                                  receipt.metadata?.escrow_date
                              ),
                              "PPP"
                            )
                          : "N/A"}
                      </TableCell>
                    </TableRow>

                    {/* Display expected release dates if available */}
                    {releaseInfo && releaseInfo.initialRelease && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Expected Initial Release
                        </TableCell>
                        <TableCell>
                          {format(releaseInfo.initialRelease, "PPP")}
                          {releaseInfo.isInitialReleaseEligible && (
                            <Chip
                              size="small"
                              color="success"
                              label="Eligible"
                              sx={{ ml: 1 }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                    {releaseInfo && releaseInfo.finalRelease && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Expected Final Release
                        </TableCell>
                        <TableCell>
                          {format(releaseInfo.finalRelease, "PPP")}
                          {releaseInfo.isFinalReleaseEligible && (
                            <Chip
                              size="small"
                              color="success"
                              label="Eligible"
                              sx={{ ml: 1 }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                    {escrowStatus === "released" && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Release Date
                        </TableCell>
                        <TableCell>
                          {receipt.metadata?.release_date
                            ? format(
                                new Date(receipt.metadata.release_date),
                                "PPP"
                              )
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    )}
                    {escrowStatus === "cancelled" && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Cancel Date
                        </TableCell>
                        <TableCell>
                          {receipt.metadata?.cancel_date
                            ? format(
                                new Date(receipt.metadata.cancel_date),
                                "PPP"
                              )
                            : "N/A"}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Release history if available */}
          {releaseHistory && releaseHistory.length > 0 && (
            <>
              <Typography variant="h6" gutterBottom>
                Release History
              </Typography>
              <TableContainer
                component={Paper}
                variant="outlined"
                sx={{ mb: 3 }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {releaseHistory.map((release, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {format(new Date(release.date), "MMM d, yyyy")}
                        </TableCell>
                        <TableCell>{release.type}</TableCell>
                        <TableCell>{release.reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          <Typography variant="h6" gutterBottom>
            Business Details
          </Typography>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    From
                  </TableCell>
                  <TableCell>
                    {receipt.payerBusiness?.business_name || "Your Business"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    To
                  </TableCell>
                  <TableCell>
                    {receipt.payeeBusiness?.business_name ||
                      "Collaborating Business"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Collaboration
                  </TableCell>
                  <TableCell>
                    {receipt.collaboration?.collaboration_id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Order Number
                  </TableCell>
                  <TableCell>
                    {receipt.collaboration?.ShippingOrder?.order_number ||
                      receipt.collaboration?.ShippingOrder?.shipping_order_id ||
                      "N/A"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReceiptIcon />}
          onClick={() => {
            // Implement print functionality
            window.print();
          }}
        >
          Print Receipt
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Main Dashboard Component
const BusinessPaymentsDashboard = ({ businessId }) => {
  const { api } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState(0);
  const [payments, setPayments] = useState({
    all: [],
    outgoing: [],
    incoming: [],
    scheduled: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [viewType, setViewType] = useState("table"); // 'table' or 'calendar'

  const fetchPayments = async () => {
    setLoading(true);
    setError(null);

    try {
      // Create API request promises
      const requests = [
        // All Payments
        api.get(`/payments/collaboration/business/${businessId}/payments`, {
          params: { role: "all" },
        }),

        // Outgoing
        api.get(`/payments/collaboration/business/${businessId}/payments`, {
          params: { role: "payer" },
        }),

        // Incoming
        api.get(`/payments/collaboration/business/${businessId}/payments`, {
          params: { role: "payee" },
        }),

        // Scheduled
        api.get(`/payments/collaboration/business/${businessId}/payments`, {
          params: { payment_type: "scheduled" },
        }),
      ];

      // Execute all requests in parallel
      const [
        allResponse,
        outgoingResponse,
        incomingResponse,
        scheduledResponse,
      ] = await Promise.all(requests);

      // Enhance payments with direction property
      const enhancePayments = (payments) => {
        return payments.map((payment) => {
          const direction =
            payment.payer_business_id === businessId ? "outgoing" : "incoming";
          return { ...payment, direction };
        });
      };

      setPayments({
        all: enhancePayments(
          allResponse.data.success ? allResponse.data.data : []
        ),
        outgoing: enhancePayments(
          outgoingResponse.data.success ? outgoingResponse.data.data : []
        ),
        incoming: enhancePayments(
          incomingResponse.data.success ? incomingResponse.data.data : []
        ),
        scheduled: enhancePayments(
          scheduledResponse.data.success ? scheduledResponse.data.data : []
        ),
      });
    } catch (error) {
      console.error("Error loading payment data:", error);
      setError("Failed to load payment data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (businessId) {
      fetchPayments();
    }
  }, [businessId, api]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Reset to table view when switching tabs (except for scheduled tab)
    if (newValue !== 3) {
      // Scheduled tab is now index 3
      setViewType("table");
    } else {
      setViewType("calendar"); // Default to calendar for scheduled tab
    }
  };

  const getActivePayments = () => {
    const tabData = {
      0: payments.all,
      1: payments.outgoing,
      2: payments.incoming,
      3: payments.scheduled,
    };

    const data = tabData[activeTab] || [];

    if (!searchTerm) return data;

    return data.filter(
      (payment) =>
        payment.collaboration?.ShippingOrder?.order_number
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        payment.collaboration?.ShippingOrder?.shipping_order_id
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        payment.payerBusiness?.business_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        payment.payeeBusiness?.business_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        payment.amount?.toString().includes(searchTerm)
    );
  };

  const handleProcessPayment = (payment) => {
    setSelectedPayment(payment);
    setIsPaymentDialogOpen(true);
  };

  const handleViewReceipt = (payment) => {
    setSelectedReceipt(payment);
    setIsReceiptDialogOpen(true);
  };

  const handlePaymentComplete = async () => {
    setIsPaymentDialogOpen(false);
    await fetchPayments();
  };

  const handleViewTypeToggle = () => {
    setViewType(viewType === "table" ? "calendar" : "table");
  };

  const handleCalendarPaymentSelect = (payment) => {
    handleViewReceipt(payment);
  };

  const getEscrowStatus = (payment) => {
    // Try to get escrow status from different locations in the payment object
    return (
      payment.escrow_status ||
      (payment.metadata && payment.metadata.escrow_status) ||
      (payment.status === "in_escrow" ? "held" : null)
    );
  };

  // Status and release dates helpers
  const getReleaseDate = (payment) => {
    // Check for release date in various locations
    return (
      payment.metadata?.release_date ||
      payment.release_date ||
      (payment.release_history && payment.release_history.length > 0
        ? payment.release_history[payment.release_history.length - 1].date
        : null)
    );
  };

  // Show loading indicator only for initial load
  if (loading && Object.values(payments).every((arr) => arr.length === 0)) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Card>
        <CardHeader
          title="Payments Dashboard"
          action={
            <Box display="flex" alignItems="center">
              {activeTab === 3 && ( // Scheduled tab is now index 3
                <Button
                  onClick={handleViewTypeToggle}
                  startIcon={
                    viewType === "table" ? <ScheduleIcon /> : <ViewListIcon />
                  }
                  sx={{ mr: 2 }}
                >
                  {viewType === "table" ? "Calendar View" : "Table View"}
                </Button>
              )}
              <IconButton onClick={fetchPayments} aria-label="refresh">
                <RefreshIcon />
              </IconButton>
            </Box>
          }
        />
        <CardContent>
          <Box mb={3} display="flex" justifyContent="space-between">
            <TextField
              placeholder="Search payments..."
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton aria-label="filter">
              <FilterListIcon />
            </IconButton>
          </Box>

          <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab
              label="All Payments"
              icon={<PaymentIcon />}
              iconPosition="start"
              sx={{ minWidth: 150 }}
            />
            <Tab
              label="Outgoing"
              icon={<ArrowUpwardIcon />}
              iconPosition="start"
              sx={{ minWidth: 150 }}
            />
            <Tab
              label="Incoming"
              icon={<ArrowDownwardIcon />}
              iconPosition="start"
              sx={{ minWidth: 150 }}
            />
            <Tab
              label="Scheduled"
              icon={<ScheduleIcon />}
              iconPosition="start"
              sx={{ minWidth: 150 }}
            />
          </Tabs>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {/* Loading indicator for refreshes */}
          {loading && (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress size={24} />
            </Box>
          )}

          {/* Calendar View for Scheduled Payments */}
          {activeTab === 3 && viewType === "calendar" && (
            <PaymentCalendar
              scheduledPayments={getActivePayments()}
              onSelectPayment={handleCalendarPaymentSelect}
            />
          )}

          {/* Table View for all payment types */}
          {(activeTab !== 3 || viewType === "table") && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order #</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getActivePayments().length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="body1" sx={{ py: 3 }}>
                          No payments found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    getActivePayments().map((payment) => (
                      <TableRow key={payment.payment_id}>
                        <TableCell>
                          {payment.collaboration?.ShippingOrder?.order_number ||
                            payment.collaboration?.ShippingOrder
                              ?.shipping_order_id ||
                            "N/A"}
                        </TableCell>
                        <TableCell>
                          {payment.payerBusiness?.business_name || "N/A"}
                        </TableCell>
                        <TableCell>
                          {payment.payeeBusiness?.business_name || "N/A"}
                        </TableCell>
                        <TableCell>
                          ${parseFloat(payment.amount).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <PaymentStatusChip
                            status={payment.status}
                            escrowStatus={getEscrowStatus(payment)}
                          />
                        </TableCell>
                        <TableCell>
                          {payment.payment_date
                            ? format(
                                new Date(payment.payment_date),
                                "MMM d, yyyy"
                              )
                            : payment.scheduled_date
                            ? format(
                                new Date(payment.scheduled_date),
                                "MMM d, yyyy"
                              )
                            : "Not processed"}
                        </TableCell>
                        <TableCell>
                          {payment.status === "pending" &&
                            payment.direction === "outgoing" && (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => handleProcessPayment(payment)}
                              >
                                Process
                              </Button>
                            )}

                          {/* Receipts for completed and in-escrow payments */}
                          {(payment.status === "completed" ||
                            payment.status === "in_escrow") && (
                            <Button
                              variant="outlined"
                              size="small"
                              startIcon={<ReceiptIcon />}
                              onClick={() => handleViewReceipt(payment)}
                              sx={{ ml: payment.status === "pending" ? 1 : 0 }}
                            >
                              Receipt
                            </Button>
                          )}

                          {/* Cancel scheduled payments */}
                          {activeTab === 3 && payment.status === "pending" && (
                            <Button
                              variant="outlined"
                              size="small"
                              color="secondary"
                              onClick={() => {
                                // Cancel scheduled payment functionality
                                alert(
                                  "Cancel scheduled payment functionality is not yet implemented"
                                );
                              }}
                              sx={{ ml: 1 }}
                            >
                              Cancel
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
      </Card>

      {/* Payment Dialog */}
      {selectedPayment && (
        <CollaborationPaymentDialog
          open={isPaymentDialogOpen}
          onClose={() => setIsPaymentDialogOpen(false)}
          collaboration={selectedPayment.collaboration}
          onPaymentComplete={handlePaymentComplete}
        />
      )}

      {/* Receipt/Details Dialog */}
      <ReceiptDialog
        open={isReceiptDialogOpen}
        onClose={() => setIsReceiptDialogOpen(false)}
        receipt={selectedReceipt}
      />
    </>
  );
};

export default BusinessPaymentsDashboard;
