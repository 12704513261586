import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Paper,
  Alert,
  Skeleton,
  Chip,
  Stack,
  IconButton,
  Tooltip,
  ImageList,
  ImageListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import {
  LocationOn as LocationIcon,
  VerifiedUser as VerifiedUserIcon,
  Person as PersonIcon,
  MyLocation as MyLocationIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  QrCode as QrCodeIcon,
  DevicesOther as DeviceIcon,
  Refresh as RefreshIcon,
  ZoomIn as ZoomInIcon,
  LocalShipping as LocalShippingIcon,
  WhereToVote as DeliveryPointIcon,
  DeliveryDining as DeliveryIcon,
  Flag as FlagIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../../../../../components/Auth/AuthContext";

const OrderTimeline = ({ orderId, businessId }) => {
  const { api } = useContext(AuthContext);
  const baseUrl = process.env.REACT_APP_API_BASE_URL || "";

  // Helper function to convert Azure blob URL to our API image route
  const getImageUrl = (proofUrl) => {
    // If no URL is provided
    if (!proofUrl) return "";

    try {
      // If it's already a relative URL starting with /api or has been processed
      if (proofUrl.startsWith("/api/") || proofUrl.includes("/api/images/")) {
        // Make sure to add the base URL if it's a relative path
        return `${baseUrl}${proofUrl}`;
      }

      // If it's a full Azure URL, extract the container and blob name
      if (proofUrl.includes("blob.core.windows.net")) {
        // URL format: https://<account>.blob.core.windows.net/<container>/<blobname>
        const url = new URL(proofUrl);
        const pathParts = url.pathname.split("/");

        // Extract container (should be delivery-proofs)
        const container = pathParts[1]; // Remove leading slash

        // Extract blob name (everything after the container in the path)
        const blobName = pathParts.slice(2).join("/");

        // Construct the image API URL with base URL
        console.log("Extracted container and blob:", container, blobName);
        console.log(
          "Constructed image URL:",
          `${baseUrl}/api/images/${container}/${blobName}`
        );
        return `${baseUrl}/api/images/${container}/${blobName}`;
      }

      // If it's a blob name without the full URL
      if (!proofUrl.startsWith("http")) {
        // Assume it's in the delivery-proofs container
        console.log("Assuming delivery-proofs container:", proofUrl);
        console.log(
          "Constructed image URL for blob name:",
          `${baseUrl}/api/images/delivery-proofs/${proofUrl}`
        );
        return `${baseUrl}/api/images/delivery-proofs/${proofUrl}`;
      }

      console.log("Unknown URL format:", proofUrl);

      // Default: return the original URL
      return proofUrl;
    } catch (error) {
      console.error("Error converting URL:", error, proofUrl);
      return proofUrl;
    }
  };

  // Tab State
  const [activeTab, setActiveTab] = useState(0);

  // Histories
  const [locationHistory, setLocationHistory] = useState([]);
  const [verificationHistory, setVerificationHistory] = useState([]);
  const [routeSegments, setRouteSegments] = useState([]);

  // Loading / Error
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Image Preview
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  // Safely get employee's name
  const getEmployeeName = (employee) => {
    if (!employee) return "Unknown";
    const firstName = employee.employee_first_name || employee.first_name || "";
    const lastName = employee.employee_last_name || employee.last_name || "";
    return `${firstName} ${lastName}`.trim() || "Unknown";
  };

  // Image dialog
  const handleImageClick = (imageUrl) => {
    console.log("Opening image dialog:", imageUrl);

    setSelectedImage(imageUrl);
    setImageDialogOpen(true);
  };

  const handleCloseImageDialog = () => {
    setImageDialogOpen(false);
    setSelectedImage(null);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // ---------- FETCH CALLS ---------- //

  // Fetch location history
  const fetchLocationHistory = async () => {
    try {
      const response = await api.get(
        `/verify-package/location-history/${businessId}/orders/${orderId}`
      );
      if (response.data.success) {
        const formattedLocations = response.data.data.map((location) => ({
          ...location,
          lat: Number(location.latitude),
          lng: Number(location.longitude),
          timestamp: new Date(location.recorded_at),
          type: "location",
          recordedBy: getEmployeeName(location.recorder),
          // Ensure deliveryProofs is always an array
          deliveryProofs: Array.isArray(location.deliveryProofs)
            ? location.deliveryProofs
            : [],
        }));
        // Sort by newest
        formattedLocations.sort((a, b) => b.timestamp - a.timestamp);
        setLocationHistory(formattedLocations);
      }
    } catch (err) {
      console.error("[OrderTimeline] Error fetching location history:", err);
      setError("Failed to load location history: " + err.message);
    }
  };

  // Fetch verification history
  const fetchVerificationHistory = async () => {
    try {
      const response = await api.get(
        `/verify-package/verification-history/${businessId}/orders/${orderId}`
      );
      if (response.data.success) {
        const formattedVerifications = response.data.data.map(
          (verification) => ({
            ...verification,
            timestamp: new Date(verification.createdAt),
            type: "verification",
            verifiedBy: getEmployeeName(
              verification.VerifiedBy || verification.verifier
            ),
          })
        );
        // Sort by newest
        formattedVerifications.sort((a, b) => b.timestamp - a.timestamp);
        setVerificationHistory(formattedVerifications);
      }
    } catch (err) {
      console.error(
        "[OrderTimeline] Error fetching verification history:",
        err
      );
      setError("Failed to load verification history: " + err.message);
    }
  };

  // Fetch route segments
  const fetchRouteSegments = async () => {
    try {
      const response = await api.get(
        `/verify-package/route-segments/${businessId}/orders/${orderId}`
      );
      if (response.data.success) {
        const segments = response.data.data;
        setRouteSegments(segments);
      }
    } catch (err) {
      console.error("[OrderTimeline] Error fetching route segments:", err);
      setError("Failed to load route segments: " + err.message);
    }
  };

  // Add this new fetch function to OrderTimeline.js
  const fetchAllProofs = async () => {
    try {
      const response = await api.get(
        `/verify-package/all-proofs/${businessId}/orders/${orderId}`
      );
      if (response.data.success) {
        console.log("All proofs summary:", response.data.summary);

        // If we have segments but no proofs in the normal route, use these proofs
        if (
          routeSegments.length > 0 &&
          !routeSegments.some(
            (segment) =>
              segment.deliveryProofs && segment.deliveryProofs.length > 0
          )
        ) {
          // Update the route segments with the proofs
          const updatedSegments = [...routeSegments];
          Object.entries(response.data.proofsBySegment).forEach(
            ([segmentId, proofs]) => {
              const segmentIndex = updatedSegments.findIndex(
                (s) => s.segment_id === segmentId
              );
              if (segmentIndex >= 0) {
                updatedSegments[segmentIndex].deliveryProofs = proofs;
              }
            }
          );

          setRouteSegments(updatedSegments);
        }
      }
    } catch (err) {
      console.error("[OrderTimeline] Error fetching all proofs:", err);
    }
  };

  // Full data refresh function
  // Update the loadAllData function to include fetchAllProofs
  const loadAllData = async () => {
    setLoading(true);
    setError(null);

    try {
      // First load route segments which will include delivery proofs
      await fetchRouteSegments();

      // Also fetch all proofs directly as a backup/enhancement
      await fetchAllProofs();

      // Then get verification history
      await fetchVerificationHistory();

      // Finally get location history
      await fetchLocationHistory();
    } catch (error) {
      console.error("[OrderTimeline] Error loading data:", error);
      setError(
        "Failed to load order data: " + (error.message || "Unknown error")
      );
    } finally {
      setLoading(false);
    }
  };

  // ---------- LOAD ALL ON MOUNT ---------- //
  useEffect(() => {
    if (!orderId || !businessId) return;
    loadAllData();
  }, [orderId, businessId]);

  // Refresh button
  const handleRefresh = () => {
    loadAllData();
  };

  // ---------- HELPER FUNCTIONS ---------- //
  const formatDateTime = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const formatAddress = (lat, lng) => {
    return `${lat.toFixed(6)}, ${lng.toFixed(6)}`;
  };

  const getLocationSourceIcon = (source) => {
    switch (source?.toLowerCase()) {
      case "gps":
        return <MyLocationIcon fontSize="small" />;
      case "manual":
        return <PersonIcon fontSize="small" />;
      default:
        return <LocationIcon fontSize="small" />;
    }
  };

  const getVerificationStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "success":
        return "success";
      case "failed":
        return "error";
      default:
        return "default";
    }
  };

  const getVerificationMethodIcon = (method) => {
    switch (method?.toLowerCase()) {
      case "qr-scan":
        return <QrCodeIcon fontSize="small" />;
      case "manual":
        return <PersonIcon fontSize="small" />;
      default:
        return <VerifiedUserIcon fontSize="small" />;
    }
  };

  // ---------- DELIVERY PROOFS RENDER ---------- //
  // In OrderTimeline.js, update the renderDeliveryProofs function
  const renderDeliveryProofs = (proofs) => {
    if (!proofs || proofs.length === 0) {
      return null;
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ display: "flex", alignItems: "center" }}
        >
          <DeliveryIcon fontSize="small" sx={{ mr: 1 }} />
          Delivery Proof ({proofs.length})
        </Typography>
        <ImageList
          sx={{ width: "100%", maxHeight: 200 }}
          cols={3}
          rowHeight={120}
        >
          {proofs.map((proof, index) => {
            // Get the proof URL from any of several possible properties
            const rawUrl =
              proof.proof_url || proof.url || proof.image_url || "";

            // Process it through our getImageUrl helper
            const proofUrl = getImageUrl(rawUrl);

            // Check if this is a signature proof
            const isSignature = proof.proof_type === "signature";

            return (
              <ImageListItem
                key={
                  proof.delivery_proof_id || proof.proof_id || `proof-${index}`
                }
                sx={{
                  cursor: "pointer",
                  // Add special styling for signatures
                  ...(isSignature && {
                    backgroundColor: "#f5f5f5",
                    border: "1px dashed #1976d2",
                  }),
                }}
                onClick={() => handleImageClick(proofUrl)}
              >
                <img
                  src={proofUrl}
                  alt={
                    isSignature ? "Signature" : `Delivery Proof ${index + 1}`
                  }
                  style={{
                    height: "100%",
                    objectFit: isSignature ? "contain" : "cover", // Use contain for signatures
                    borderRadius: "4px",
                    backgroundColor: isSignature ? "#ffffff" : "transparent", // White background for signatures
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: isSignature
                      ? "rgba(25,118,210,0.7)"
                      : "rgba(0,0,0,0.5)", // Blue background for signatures
                    padding: "4px 8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      fontWeight: isSignature ? "bold" : "normal",
                    }}
                  >
                    {isSignature
                      ? "Signature"
                      : proof.proof_type
                      ? proof.proof_type.charAt(0).toUpperCase() +
                        proof.proof_type.slice(1)
                      : "Proof"}{" "}
                    {isSignature ? "" : index + 1}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{ color: "white", p: 0.5 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageClick(proofUrl);
                    }}
                  >
                    <ZoomInIcon fontSize="small" />
                  </IconButton>
                </Box>
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  };

  // ---------- UNIFIED TIMELINE RENDER ---------- //

  // Transform route segments into timeline events
  const routeSegmentEvents = routeSegments.flatMap((segment) => {
    const events = [];
    if (segment.actual_start_time) {
      events.push({
        type: "route_segment_start",
        timestamp: new Date(segment.actual_start_time),
        segment,
      });
    }
    if (segment.actual_end_time) {
      events.push({
        type: "route_segment_end",
        timestamp: new Date(segment.actual_end_time),
        segment,
        // Add delivery proofs to the segment end event if they exist
        deliveryProofs: segment.deliveryProofs || [],
      });
    }
    return events;
  });

  // Combine & sort
  const combinedHistory = [
    ...locationHistory,
    ...verificationHistory,
    ...routeSegmentEvents,
  ];
  combinedHistory.sort((a, b) => b.timestamp - a.timestamp);

  const renderUnifiedTimeline = () => {
    if (combinedHistory.length === 0) {
      return (
        <Alert severity="info" sx={{ mt: 2 }}>
          No history available for this order.
        </Alert>
      );
    }

    return (
      <Timeline position="alternate">
        {combinedHistory.map((event, index) => {
          const isLocation = event.type === "location";
          const isVerification = event.type === "verification";
          const isSegmentStart = event.type === "route_segment_start";
          const isSegmentEnd = event.type === "route_segment_end";

          return (
            <TimelineItem
              key={
                // Use whatever unique ID or fallback index
                event.location_id ||
                event.verification_id ||
                event.segment?.segment_id + event.type ||
                index
              }
            >
              <TimelineOppositeContent color="text.secondary">
                {formatDateTime(event.timestamp)}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot
                  color={
                    isLocation
                      ? event.isDeliveryPoint
                        ? "success"
                        : "primary"
                      : isVerification
                      ? getVerificationStatusColor(event.verification_status)
                      : "secondary" // For route-segment events
                  }
                  sx={{ boxShadow: 3 }}
                >
                  {isLocation ? (
                    event.isDeliveryPoint ? (
                      <DeliveryPointIcon />
                    ) : (
                      getLocationSourceIcon(event.location_source)
                    )
                  ) : isVerification ? (
                    event.verification_status === "success" ? (
                      <CheckIcon />
                    ) : (
                      <CloseIcon />
                    )
                  ) : (
                    // For route-segment start/end
                    <LocalShippingIcon />
                  )}
                </TimelineDot>
                {index < combinedHistory.length - 1 && <TimelineConnector />}
              </TimelineSeparator>

              <TimelineContent>
                <Paper
                  elevation={3}
                  sx={{
                    p: 1,
                    mb: 2,
                    "&:hover": { boxShadow: 6 },
                    transition: "all 0.3s",
                  }}
                >
                  {/* LOCATION EVENT */}
                  {isLocation && (
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" component="div">
                        {event.isDeliveryPoint
                          ? `Delivery Point - ${
                              event.routeSegment?.destination ||
                              "Location Update"
                            }`
                          : "Location Update"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {formatAddress(event.lat, event.lng)}
                      </Typography>
                      {event.recordedBy && (
                        <Typography variant="body2" color="text.secondary">
                          Recorded by: {event.recordedBy}
                        </Typography>
                      )}
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        <Chip
                          size="small"
                          icon={getLocationSourceIcon(event.location_source)}
                          label={event.location_source || "Unknown Source"}
                        />
                        {event.accuracy && (
                          <Chip
                            size="small"
                            icon={<InfoIcon />}
                            label={`Accuracy: ${event.accuracy}m`}
                          />
                        )}
                        {event.isDeliveryPoint && (
                          <Chip
                            size="small"
                            icon={<DeliveryPointIcon />}
                            label="Delivery Completed"
                            color="success"
                          />
                        )}
                      </Stack>
                      {renderDeliveryProofs(event.deliveryProofs)}
                    </Box>
                  )}

                  {/* VERIFICATION EVENT */}
                  {isVerification && (
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" component="div">
                        {event.verification_type === "package"
                          ? "Package Verification"
                          : "Item Verification"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {event.verifiedBy
                          ? `Verified by: ${event.verifiedBy}`
                          : ""}
                        {event.verification_status === "success"
                          ? " - Verification successful"
                          : ` - Verification failed: ${
                              event.failure_reason || "Unknown reason"
                            }`}
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        <Chip
                          size="small"
                          color={getVerificationStatusColor(
                            event.verification_status
                          )}
                          label={event.verification_status}
                        />
                        <Chip
                          size="small"
                          icon={getVerificationMethodIcon(
                            event.verification_method
                          )}
                          label={event.verification_method}
                        />
                        {event.device_info && (
                          <Tooltip
                            title={JSON.stringify(event.device_info, null, 2)}
                          >
                            <Chip
                              size="small"
                              icon={<DeviceIcon />}
                              label="Device Info"
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    </Box>
                  )}

                  {/* ROUTE SEGMENT EVENT */}
                  {(isSegmentStart || isSegmentEnd) && (
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        {isSegmentStart
                          ? `Route Segment #${event.segment.segment_order} Started`
                          : `Route Segment #${event.segment.segment_order} Completed`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Status:</strong> {event.segment.status}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Type:</strong> {event.segment.type}
                      </Typography>
                      {event.segment.courier_id && (
                        <Typography variant="body2" color="text.secondary">
                          <strong>Courier ID:</strong>{" "}
                          {event.segment.courier_id}
                        </Typography>
                      )}

                      {/* Display delivery proofs for completed segments */}
                      {isSegmentEnd &&
                        event.deliveryProofs &&
                        event.deliveryProofs.length > 0 &&
                        renderDeliveryProofs(event.deliveryProofs)}
                    </Box>
                  )}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };

  // ---------- RENDER ---------- //
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <Skeleton variant="text" height={40} width="50%" />
        <Skeleton variant="rectangular" height={400} sx={{ mt: 2 }} />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Paper elevation={3} sx={{ p: 2, marginRight: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Order Timeline
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Chip
              icon={<LocationIcon />}
              label="Location Events"
              size="small"
              color="primary"
              variant="outlined"
            />
            <Chip
              icon={<DeliveryPointIcon />}
              label="Delivery Points"
              size="small"
              color="success"
              variant="outlined"
            />
            <Chip
              icon={<VerifiedUserIcon />}
              label="Verification Events"
              size="small"
              color="success"
              variant="outlined"
            />
            <Chip
              icon={<LocalShippingIcon />}
              label="Route Segments"
              size="small"
              color="secondary"
              variant="outlined"
            />
          </Box>
          <Tooltip title="Refresh data">
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {renderUnifiedTimeline()}

      {/* Image Preview Dialog */}
      <Dialog
        open={imageDialogOpen}
        onClose={handleCloseImageDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Check if we're displaying a signature */}
          {selectedImage && selectedImage.includes("signature")
            ? "Delivery Signature"
            : "Delivery Proof"}
          <IconButton onClick={handleCloseImageDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: selectedImage.includes("signature")
                  ? "#ffffff"
                  : "transparent",
                padding: selectedImage.includes("signature") ? 2 : 0,
                borderRadius: 2,
              }}
            >
              <img
                src={selectedImage}
                alt={
                  selectedImage.includes("signature")
                    ? "Signature"
                    : "Delivery Proof"
                }
                style={{
                  maxWidth: "100%",
                  maxHeight: "calc(100vh - 200px)",
                  objectFit: selectedImage.includes("signature")
                    ? "contain"
                    : "cover",
                }}
              />
              <Button
                component="a"
                href={selectedImage}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ mt: 2 }}
                variant="outlined"
              >
                Open Original
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default OrderTimeline;
